import { Check } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
// import { Link } from 'react-router-dom';
import TableCmp from 'src/components/TableCmp';
import { STATUS } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { getMapService } from 'src/services/IntegrationService';
import {
  capitalizeFirstLetter,
  getLabelByValue,
} from 'src/utils/CommonFunctions';

import Address from '../../AddressDetails/Address';
import { scriptId } from '../../AddressDetails/Address.interface';

import QuoteDetailSubRow from './QuoteDetailSubRow';
import QuoteDetailTableLoadingRow from './QuoteDetailTableLoadingRow';
import QuoteDetailTableRow from './QuoteDetailTableRow';

const initAction = {
  isEdit: false,
  mode: '',
  data: {
    name: '',
    value: '',
  },
  fullAddress: {},
};

const QuoteDetailTable = ({
  isLoading,
  dimensions,
  services,
  addressDetails,
  totalWeight,
  totalUnit,
  setIsGetRate,
  sortActiveCarrierRates,
  handleRateGathering,
  setAddressDetails,
}: any) => {
  const [quoteDetailData, setQuoteDetailData] = useState<any[]>([]);
  const [action, setAction] = useState(initAction);
  const { setMapService, googleKey, setGoogleKey, setTrimbleKey } =
    useContext(BasicContext);
  const [isDisplayDimensionsDetail, setIsDisplayDimensionsDetail] =
    useState(false);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  useEffect(() => {
    if (!isLoading) setQuoteDetailData([addressDetails]);
  }, [addressDetails]);

  const weightMeasurement = dimensions?.[0]?.weightMeasurement
    ? getLabelByValue(dimensions[0].weightMeasurement)?.toLowerCase()
    : 'N/A';
  const lengthMeasurement = dimensions?.[0]?.lengthMeasurement ?? 'N/A';

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  useEffect(() => {
    getMapService().then((response: any) => {
      if (response?.data) {
        if (response.data?.slug) {
          setMapService(response.data?.slug);

          if (response.data.configurations?.length) {
            let configuration = response.data.configurations[0];

            if (configuration.value) {
              if (response.data.slug === 'trimble_map') {
                setTrimbleKey(configuration.value);
              } else {
                setGoogleKey(configuration.value);
              }
            }
          }
        }
      }
    });
  }, []);

  const headCells = useMemo(
    () => [
      {
        id: 'firtname',
        name: 'User',
        sortable: true,
        rowClassName: 'min-w-[18%] max-w-[1px] w-[18%]',
      },
      // {
      //   id: 'serviceType',
      //   name: 'Service Type',
      //   rowClassName: 'min-w-[18%] max-w-[1px] w-[18%]',
      // },
      {
        id: 'order_id',
        name: 'OrderID',
        sortable: true,
        rowClassName: 'min-w-[18%] max-w-[1px] w-[18%]',
        visible: addressDetails.status === STATUS.WON,
      },
      {
        id: 'customer',
        name: 'Customer',
        rowClassName: 'min-w-[16%] max-w-[1px] w-[16%]',
      },
      {
        id: 'shipperFullAddress',
        name: 'From',
        rowClassName: 'min-w-[20%] max-w-[1px] w-[20%]',
      },
      {
        id: 'consigneeFullAddress',
        name: 'To',
        rowClassName: 'min-w-[20%] max-w-[1px] w-[20%]',
      },
      {
        id: 'pickupDate',
        name: 'Pickup Date',
        sortable: true,
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },
      {
        id: 'total_weight',
        name: 'Total Weight',
        sortable: true,
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },
      {
        id: 'linearFootage',
        name: 'Linear Footage',
        sortable: true,
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },
      {
        id: 'equipmentType',
        name: 'Equipment Type',
        sortable: true,
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },

      // {
      //   id: 'total_unit',
      //   name: 'Total № of HU',
      //   sortable: true,
      //   rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      // },
      {
        id: 'action',
        rowClassName: 'min-w-[65px] w-[65px] max-w-[65px]',
      },
    ],
    [addressDetails]
  );

  const subHeadCells = useMemo(
    () => [
      {
        id: 'no_hu',
        name: '№ of HU',
        rowClassName: 'min-w-[16%] max-w-[1px] w-[16%]',
      },
      {
        id: 'handling_unit',
        name: 'Handling unit',
        rowClassName: 'min-w-[18%] max-w-[1px] w-[18%]',
      },
      {
        id: 'weight_per_unit',
        name: `Weight per unit (${capitalizeFirstLetter(
          weightMeasurement?.toLowerCase()
        )})`,
        rowClassName: 'min-w-[20%] max-w-[1px] w-[20%]',
      },
      {
        id: 'freight_class',
        name: 'Freight Class',
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },
      {
        id: 'item_class',
        name: 'Item Class',
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },
      {
        id: 'Dimensions',
        name: `Dimensions ${
          lengthMeasurement !== 'N/A'
            ? `(${capitalizeFirstLetter(lengthMeasurement?.toLowerCase())})`
            : ''
        }`,
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },
      {
        id: 'description',
        name: 'Description',
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },
      {
        id: 'action',
        rowClassName: 'min-w-[65px] w-[65px] max-w-[65px]',
      },
    ],
    [weightMeasurement, lengthMeasurement, dimensions]
  );

  const handleDrawerClose = () => {
    setAction((old: any) => ({
      ...old, // Spread the previous state
      mode: '', // Update the mode field
    }));
  };

  window.initMap = () => {
    setStatus(true);
  };

  const handleAddressSelection = (value: any) => {
    setAction((old: any) => {
      console.log('Previous state (old):', old);

      return {
        ...old,
        ...{
          data: {
            name: old.mode, // Use old.mode instead of action.mode
            value: value?.fullAddress,
          },
          fullAddress: { ...value },
        },
      };
    });

    // if (action.shipper) {
    //   setShipperAddress(value.fullAddress);
    //   setFormData((old) => ({ ...old, shipperAddress: value }));
    //   setIsValidAddress((old) => ({ ...old, shipperAddress: true }));
    // } else if (action.consignee) {
    //   setConsigneeAddress(value.fullAddress);
    //   setFormData((old) => ({ ...old, consigneeAddress: value }));
    //   setIsValidAddress((old) => ({ ...old, consigneeAddress: true }));
    // }
  };

  // const serviceHeadCells = useMemo(
  //   () => [
  //     {
  //       id: 'additonal_services',
  //       name: 'Additonal Services',
  //       rowClassName: 'min-w-[18%] max-w-[1px] w-[18%]',
  //     },
  //     {
  //       id: '',
  //       name: '',
  //       rowClassName: 'min-w-[18%] max-w-[1px] w-[18%]',
  //     },
  //     {
  //       id: '',
  //       name: '',
  //       rowClassName: 'min-w-[18%] max-w-[1px] w-[18%]',
  //     },
  //     {
  //       id: '',
  //       name: '',
  //       rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
  //     },
  //     {
  //       id: '',
  //       name: '',
  //       rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
  //     },
  //     {
  //       id: '',
  //       name: '',
  //       rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
  //     },
  //     {
  //       id: '',
  //       rowClassName: 'min-w-[65px] w-[65px] max-w-[65px]',
  //     },
  //   ],
  //   []
  // );

  return (
    <>
      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col overflow-hidden">
        {/* {addressDetails.status === STATUS.WON && (
          <div className="table-top-header ">
            <div className="table-left-wrap ">
              <div className={`table-title-wrap`}>
                <h5 className="table-title">Order ID:</h5>
                <Link
                  to={ROSE_ROCKET.QUOTE_URL + addressDetails.rossRocketId}
                  className="underline text-primary text-base font-medium"
                  onClick={(e: any) => e.preventDefault()}
                >
                  #{addressDetails.rossRocketPublicId}
                </Link>
              </div>
            </div>
          </div>
        )} */}
        <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
          <TableCmp
            headCells={headCells}
            tableDataArr={quoteDetailData}
            TableLoaderRowCmp={QuoteDetailTableLoadingRow}
            TableRowCmp={QuoteDetailTableRow}
            tableRowCmpProps={{
              dimensions: dimensions,
              action: action,
              handleRateGathering: handleRateGathering,
              isDisplayDimensionsDetail: isDisplayDimensionsDetail,
              setIsDisplayDimensionsDetail: setIsDisplayDimensionsDetail,
              setQuoteDetailData: setQuoteDetailData,
              setAddressDetails: setAddressDetails,
              sortActiveCarrierRates: sortActiveCarrierRates,
              setAction: setAction,
              setIsGetRate: setIsGetRate,
              totalUnit: totalUnit,
              totalWeight: totalWeight,
              addressDetails: addressDetails,
            }}
            isTableDataLoading={isLoading}
            onRowClick={() =>
              setIsDisplayDimensionsDetail(!isDisplayDimensionsDetail)
            }
            numberOfSkeletonRows={1}
            isTableRowClickable={true}
          />
          {isDisplayDimensionsDetail && (
            <div className="p-5 empty:hidden">
              {dimensions.length ? (
                <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col overflow-hidden">
                  <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
                    <TableCmp
                      headCells={subHeadCells}
                      tableDataArr={dimensions}
                      TableRowCmp={QuoteDetailSubRow}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              {services.length ? (
                <>
                  <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col overflow-hidden mt-5">
                    <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
                      <div className="text-xs text-grayLight600 font-medium bg-gray50 ">
                        <div className="border-b border-utilityGray200">
                          <div className="px-5 py-3 font-medium">
                            <div className="flex items-center">
                              <span>Additional Services</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap gap-x-2 -mx-5 px-5 py-4">
                        {services.map((val: any) => (
                          <div
                            key={val?.serviceName}
                            className="flex gap-2 text-grayLight900 truncate px-5 py-2.5"
                          >
                            <Check className="w-4 h-4 text-primary flex-none" />
                            <h6 className="truncate text-xs">
                              {' '}
                              {val.serviceName}
                            </h6>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
      <Address
        isOpen={
          action.mode === 'shipperAddress' || action.mode === 'consigneeAddress'
        }
        mode={action.mode}
        handleClose={handleDrawerClose}
        setAddressData={handleAddressSelection}
        addressType={action}
        addressData={action.fullAddress}
        status={status}
      />

      {/* ACCORDION TABLE DESIGN */}
    </>
  );
};

export default QuoteDetailTable;
