import React, { useContext, useEffect, useMemo, useState } from 'react';
import TableCmp from 'src/components/TableCmp';

import { OrderContext } from '../..';
import OrderDetailsRow from '../../OrderDetails/OrderDetailsRow';

interface IProps {
  type: string;
}

const PickupAndDeliveryTab = ({ type }: IProps) => {
  const [dimensions, setDimensions] = useState([]);

  const { order, handlingUnitOptions, handleTabSwitch } =
    useContext<any>(OrderContext);

  useEffect(() => {
    if (order?.order_dimensions?.length > 0) {
      setDimensions(order?.order_dimensions);
    }
  }, [order, type]);

  const headCells = useMemo(
    () => [
      {
        id: 'quantity',
        name: 'Quantity',
      },
      {
        id: 'handlingUnit',
        name: 'Handling Unit',
      },
      {
        id: 'pickupLegId',
        name: 'Pickup Leg ID',
      },
      {
        id: 'pickup',
        name: 'Pickup',
      },
      {
        id: 'dropLegId',
        name: 'Dropoff Leg ID',
      },
      {
        id: 'dropoff',
        name: 'Dropoff',
      },
      {
        id: 'totalWeight',
        name: 'Total weight (lbs)',
      },
      {
        id: 'refNumber',
        name: 'REF Number',
      },
    ],
    []
  );

  return (
    <div className="p-4 bg-gray25 border-b border-utilityGray200 last:border-b-0">
      <div className="overflow-x-auto custom-scrollbar scrollbar-hide -my-3">
        <TableCmp
          tableWrapClass="table-with-spacing table-box"
          headCells={headCells}
          tableDataArr={dimensions}
          TableRowCmp={OrderDetailsRow}
          tableRowCmpProps={{
            handlingUnitOptions,
            handleTabSwitch,
          }}
          numberOfSkeletonRows={2}
        />
      </div>
    </div>
  );
};

export default PickupAndDeliveryTab;
