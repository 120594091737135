import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Link } from 'react-router-dom';
import { filterServicesByType } from 'src/app/Orders/order.constant';
import AccordionCmp from 'src/components/AccordionCmp';

import AdditionServiceSection from '../AdditionServiceSection';

interface IProps {
  ref: any;
  isLoading: boolean;
  currentLegData: Record<string, any>;
  dataKey: 'shipper' | 'consignee';
  title: string;
  serviceList: Array<any>;
  getFormatPhoneWithPlus: (phone: string) => string;
  onAccordionClick: () => void;
  order: any;
  isShowOrderAddress: any;
}

const RenderAddressSection = forwardRef<any, IProps>(
  (
    {
      isLoading,
      currentLegData,
      dataKey,
      title,
      serviceList,
      getFormatPhoneWithPlus,
      onAccordionClick,
      order,
      isShowOrderAddress,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(true);

    useImperativeHandle(ref, () => ({
      toggle: () => {
        setIsOpen((prev) => !prev);
      },
    }));

    const additionalServices =
      currentLegData?.additionalServices?.serviceId || [];
    const pickupAdditionalServices = filterServicesByType(
      serviceList,
      additionalServices,
      'PICKUP'
    );
    const deliveryAdditionalServices = filterServicesByType(
      serviceList,
      additionalServices,
      'DELIVERY'
    );

    const addressData = currentLegData?.[dataKey] || {};
    const companyName = isShowOrderAddress
      ? order?.[`${dataKey}Address`]?.companyName || 'N/A'
      : addressData?.companyName || 'N/A';
    const fullAddress = isShowOrderAddress
      ? order?.[`${dataKey}Address`]?.fullAddress || 'N/A'
      : addressData[`${dataKey}Address`]?.fullAddress || 'N/A';
    const contactName = isShowOrderAddress
      ? order?.[`${dataKey}Address`]?.contactName || 'N/A'
      : addressData?.contactName || 'N/A';
    const phoneNumber = isShowOrderAddress
      ? order?.[`${dataKey}Address`]?.contactNumber || ''
      : addressData?.phoneNumber || '';
    const email = isShowOrderAddress
      ? order?.[`${dataKey}Address`]?.email || 'N/A'
      : addressData?.email || 'N/A';

    return (
      <AccordionCmp
        className="!p-0 !border-0 !bg-transparent"
        contentParentClass="border-t-0 !pt-0"
        contentClassName="space-y-3 !bg-transparent"
        title={
          <div className="flex items-center justify-between gap-x-1.5 flex-1">
            <h6 className="text-grayLight900 text-sm font-medium uppercase">
              {title}
            </h6>
            <p className="text-grayLight600 text-xs leading-[1.5] uppercase flex-1">
              ({dataKey === 'shipper' ? 'From' : 'To'})
            </p>
            <p className="text-primary text-xs leading-[1.5] font-semibold">
              View Details
            </p>
          </div>
        }
        AccordionIconClass="text-primary"
        isOpen={isOpen}
        handleClick={onAccordionClick}
      >
        <>
          <ul className="rounded-lg border border-utilityGray200 bg-white">
            <li className="last:border-b-0 border-b border-utilityGray200 p-3 space-y-1">
              <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                Name & Address
              </h6>
              <p
                className={`text-textSecondary text-sm font-medium ${
                  isLoading ? 'custom-loading' : ''
                } `}
              >
                {companyName}
              </p>
              <p
                className={`text-textSecondary text-xs font-medium line-clamp-1 ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                {fullAddress}
              </p>
            </li>

            <li className="last:border-b-0 border-b border-utilityGray200 p-3 flex">
              <div className="w-1/2 space-y-1">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Contact Name
                </h6>
                <p
                  className={`text-textSecondary text-sm font-medium ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {!isLoading ? contactName : 'Test Customer'}
                </p>
              </div>
              <div className="w-1/2 space-y-1">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Phone Number
                </h6>
                <p
                  className={`text-textSecondary text-sm font-medium ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  <Link to={`tel:${phoneNumber}`}>
                    {!isLoading
                      ? getFormatPhoneWithPlus(phoneNumber) || 'N/A'
                      : '(123) 123-1231'}
                  </Link>
                </p>
              </div>
            </li>
            <li className="last:border-b-0 border-b border-utilityGray200 p-3 space-y-1">
              <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                Contact Email
              </h6>
              <p
                className={`text-textSecondary text-sm font-medium ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                <Link to={`mailto:${email}`}>
                  {!isLoading ? email : 'Test@gmail.com'}
                </Link>
              </p>
            </li>
          </ul>

          {dataKey === 'shipper'
            ? pickupAdditionalServices?.length > 0 && (
                <AdditionServiceSection
                  additionalService={pickupAdditionalServices}
                  type={'Pickup Additional Services'}
                />
              )
            : deliveryAdditionalServices?.length > 0 && (
                <AdditionServiceSection
                  additionalService={deliveryAdditionalServices}
                  type={'Delivery Additional Services'}
                />
              )}
        </>
      </AccordionCmp>
    );
  }
);

export default RenderAddressSection;
