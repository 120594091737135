import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
// import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import { AuthContext } from 'src/context/AuthContext';
import { ThreadList as ThreadListService } from 'src/services/CallCenterService';
import { getShortName, usePhone } from 'src/utils/CommonFunctions';

import defaultImage from '../../../assets/img/default-image.jpg';

const initParams: any = {
  search: '',
  page: 1,
  limit: 50,
};

const ThreadList = ({ onThreadRowClick }: any) => {
  const { currentUser } = useContext(AuthContext);
  const [threads, setThreads] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [threadListParams, setThreadListParams] = useState<any>(initParams);
  const [selectedThreadId, setSelectedThreadId] = useState<string | null>(null);
  const { getFormatPhoneWithPlus } = usePhone();

  const getThreadsData = (signal?: AbortSignal) => {
    setThreads([]);
    setIsLoading(true);

    ThreadListService(threadListParams, signal)
      .then((response: any) => {
        if (response.data) {
          setThreads(response.data);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setThreads([]);
        setIsLoading(false);
      });
  };

  const handleThreadRowClick = (threadsData?: any) => {
    setSelectedThreadId(threadsData?.contactId);
    onThreadRowClick(threadsData);
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getThreadsData(signal);

    return () => {
      controller.abort();
    };
  }, [threadListParams]);

  return (
    <div className="border-r border-utilityGray200 flex-col justify-start items-start flex transition-all duration-[0.5s] max-xlm:absolute max-xlm:bg-white max-xlm:z-[1] max-sm:w-full h-full xlm:w-[23%] sm:w-[320px]">
      <div className="px-3 py-2.5 border-b border-utilityGray200 w-full items-center flex min-h-[65px] ">
        <div className="flex flex-col mr-2 truncate flex-1">
          <h5 className="text-grayLight900 text-base font-semibold">Threads</h5>
          <p className="text-grayLight600 text-xs font-normal mt-0.5">
            {getFormatPhoneWithPlus(currentUser?.phone)}
          </p>
        </div>
        {/* <div className="flex gap-3">
          <ButtonCmp
            icon={<Phone className="w-4 h-4" />}
            className="btn_secondary_black lg:px-[9px] px-2"
          >
            <></>
          </ButtonCmp>
          <ButtonCmp
            icon={<Mail02 className="w-4 h-4" />}
            className="btn_secondary_black lg:px-[9px] px-2"
          >
            <></>
          </ButtonCmp>
        </div> */}
      </div>

      <InputText
        inputName="search"
        placeholder="Search"
        className="cursor-pointer bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
        icon={
          <SearchLg className="cursor-pointer absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
        }
        value={threadListParams.search}
        isClearable={true}
        inputType="text"
        parentClassName="w-full border-b border-utilityGray200 p-3 "
        onChangeFunc={(e) => {
          setThreadListParams((old: any) => ({
            ...old,
            ...{ search: e.target.value, page: 1 },
          }));
        }}
        inputWrapClass="!justify-start [&>svg:nth-child(3)]:transition-all [&>svg:nth-child(3)]:duration-[0.5s]"
      />

      {isLoading ? (
        <ul className="w-full flex-1 overflow-auto custom-scrollbar-v3 overflow-x-hidden">
          {[...Array(9)].map((_, index: number) => (
            <li
              className="py-4 px-3 flex gap-x-3 last:border-b-0 border-b border-utilityGray200 cursor-pointer"
              key={index}
            >
              <div>
                <div className="w-9 h-9 rounded-full custom-loading"></div>
              </div>
              <div className="flex-1">
                <h6 className="text-grayLight900 text-sm font-medium custom-loading mb-1">
                  Polaris Transportation
                </h6>
                <p className="text-textSecondary text-xs font-normal line-clamp-1 custom-loading">
                  Lorem Ipsum is simply dummy text
                </p>
              </div>
              <p className="text-gray500 text-xs font-normal custom-loading h-5">
                9:24 AM
              </p>
            </li>
          ))}
        </ul>
      ) : (
        <ul
          className={`w-full flex-1 flex flex-col  ${
            !threads.length
              ? ''
              : 'overflow-x-hidden overflow-auto custom-scrollbar-v3'
          }`}
        >
          {!threads.length ? (
            <NotFoundUI
              title="No Threads Found"
              desc="There are no threads found."
              containerClassName="!h-[unset] flex-1"
            />
          ) : (
            threads.map((thread: any, index: number) => (
              <li
                className={`py-4 px-3 flex gap-x-3 last:border-b-0 border-b border-utilityGray200 cursor-pointer ${
                  selectedThreadId === thread?.contactId
                    ? 'bg-utilityGray100'
                    : ''
                }`}
                key={index}
                onClick={() => handleThreadRowClick(thread)}
              >
                <div className="relative w-9 h-9 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none  text-primary text-sm shadow-lg overflow-hidden">
                  {thread.carrierImage || thread.customerImage ? (
                    <img
                      src={
                        thread.carrierImageUrl + thread.carrierImage ||
                        thread.customerImageUrl + thread.customerImage ||
                        defaultImage
                      }
                      className="w-full h-full"
                      alt="Profile"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center uppercase bg-utilityGray100 rounded-full">
                      {getShortName(
                        thread.carrierName || thread.customerName || ''
                      )}
                    </div>
                  )}
                  {/* <p className=" w-1.5 h-1.5 bg-primary500 rounded-full -right-[0px] top-0.5 absolute"></p> */}
                </div>
                <div className="flex-1">
                  <h6 className="text-sm text-grayLight900 line-clamp-1 font-normal">
                    {thread.carrierName || thread.customerName || 'Unknown'}
                  </h6>
                  <p className="text-xs leading-[18px] text-gray500 font-normal line-clamp-1 ">
                    Hello , what is my order status ?
                  </p>
                </div>
                <p className="flex-none text-xs text-grayLight900 transition-all duration-[0.5s] font-normal">
                  {moment(thread.lastCallCreatedAt).format('h:mm A')}
                </p>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default ThreadList;
