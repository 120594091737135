import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface IProps {
  isLoading: boolean;
  data: {
    graph: {
      labels: string[];
      value: {
        // hit: number[];
        requests: number[];
        orders: number[];
        quotes: number[];
      };
    };
  };
}

const TotalQuotesGraph = ({ data, isLoading }: IProps) => {
  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'area',
      events: {
        mouseMove: function () {
          document.querySelectorAll('.apexcharts-marker').forEach((el: any) => {
            el.style.opacity = '0'; // Hide markers on hover
          });
        },
        mouseLeave: function () {
          document.querySelectorAll('.apexcharts-marker').forEach((el: any) => {
            el.style.opacity = '1'; // Show markers when not hovering
          });
        },
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    markers: {
      size: 0,
    },
    grid: {
      show: true,
      borderColor: '#e0e0e0',
      row: {
        colors: ['#f9f9f9', 'transparent'],
        opacity: 0.5,
      },
    },
    colors: ['#3538CD', '#444CE7', '#8098F9', '#A4BCFD'],
    yaxis: {
      min: 0,
      forceNiceScale: true,
      labels: {
        formatter: (val: number) => val.toString(),
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.2,
        opacityTo: 0.5,
        stops: [0, 50, 90, 100],
      },
    },
    xaxis: {
      categories: data.graph.labels,
      tickPlacement: 'on',
      tickAmount: data.graph.labels.length,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        rotate: -45,
      },
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme: 'light',
      followCursor: true,
      // custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      //   const value = series[seriesIndex][dataPointIndex];
      //   const total = w.globals.seriesTotals[seriesIndex];
      //   const percentage =
      //     value > 0 ? ((value / total) * 100).toFixed(1) : '0.00';

      //   return `
      //     <div style="padding: 10px; border-radius: 5px; background: white; box-shadow: 0 2px 6px rgba(0,0,0,0.15);">
      //       <strong>Category: </strong>${w.globals.labels[dataPointIndex]}<br/>
      //       <strong>: </strong>${value}<br/>
      //       <strong>Percentage: </strong>${percentage}%
      //     </div>
      //   `;
      // },
      y: {
        formatter: (val: number) => `${val}`,
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 300,
          },
        },
      },
    ],
  };

  const series = [
    // {
    //   name: 'Hits',
    //   data: data.graph.value.hit,
    // },
    {
      name: 'Requests',
      data: data.graph.value.requests,
    },
    {
      name: 'Quotes',
      data: data.graph.value.quotes,
    },
    {
      name: 'Orders',
      data: data.graph.value.orders,
    },
  ];

  const initialSeries = [
    // {
    //   name: 'Hits',
    //   data: [
    //     42, 56, 16, 50, 38, 32, 44, 10, 24, 20, 28, 52, 14, 18, 46, 40, 34, 48,
    //     30, 36, 54, 60, 75, 95,
    //   ],
    // },
    {
      name: 'Requests',
      data: [
        69, 84, 24, 36, 30, 75, 27, 66, 18, 42, 48, 81, 51, 63, 72, 78, 54, 15,
        39, 60, 33, 45, 57, 21,
      ],
    },
    // {
    //   name: 'Quotes',
    //   data: [
    //     14, 10, 30, 12, 18, 28, 20, 21, 19, 27, 15, 7, 23, 8, 26, 16, 25, 29,
    //     24, 13, 9, 17, 22, 11,
    //   ],
    // },
    // {
    //   name: 'Orders',
    //   data: [
    //     68, 35, 47, 62, 86, 53, 38, 26, 80, 77, 20, 44, 56, 29, 41, 17, 74, 50,
    //     71, 83, 23, 32, 59, 65,
    //   ],
    // },
  ];

  return (
    <div className="w-full bg-white p-5 rounded-xl border border-utilityGray200 shadow flex flex-col">
      <div className="w-full flex justify-between items-center mb-2.5">
        <div className="">
          <p className="text-grayLight900 text-lg font-semibold">
            Total Quotes
          </p>
        </div>
        <div className="flex gap-x-5">
          {series.map((item: any, index: number) => (
            <div key={index} className="flex items-center gap-x-1.5">
              <p
                className="w-2 h-2 rounded-full"
                style={{
                  backgroundColor: options.colors
                    ? options.colors[index]
                    : '#000', // Fallback color if options.colors is undefined
                }}
              ></p>
              <div className="text-textSecondary text-xs font-normal leading-none">
                {item.name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        id="chart"
        className={`analytics_totalquotes_tooltip ${
          isLoading ? 'apex-line-chart custom-loading graph-loading' : ''
        }`}
      >
        <ReactApexChart
          options={options}
          series={isLoading ? initialSeries : series}
          type="area"
          height={350}
        />
      </div>
    </div>
  );
};

export default TotalQuotesGraph;
