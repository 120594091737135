import { MessageChatCircle, XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import DeleteModal from 'src/components/DeleteModal';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import Header from 'src/components/PageSectionLayout/Header/Header';
import TooltipCmp from 'src/components/TooltipCmp';
import { TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { BadgeType } from 'src/interface/common';
import { cancelOrder, deleteOrder } from 'src/services/OrderService';
import WalToast from 'src/utils/WalToast';

import defaultimage from '../../../assets/img/default-image.jpg';

import RecurringOrderModal from './RecurringOrder/RecurringOrderModal';

interface IProps {
  id: number;
  isLoading: boolean;
  order: any;
  navigate: any;
  isDiscardButton: boolean;
  isShowActionButton: boolean;
  setIsShowChatSideBar: any;
}

const OrderHeader = ({
  id,
  isLoading,
  order,
  navigate,
  isDiscardButton,
  isShowActionButton,
  setIsShowChatSideBar,
}: IProps) => {
  const location = useLocation();
  const timeFilter = location.state?.timeFilter;

  const [isShowOrderActionsDropDown, setIsShowOrderActionsDropDown] =
    useState(false);
  const [isOrderCanceling, setIsOrderCanceling] = useState(false);
  const [isOrderDiscardModal, setIsOrderDiscardModal] = useState(false);
  const [isOrderRecurringModal, setIsOrderRecurringModal] = useState(false);
  const [deletOrderId, setDeletOrderId] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isOrderDelete, setIsOrderDelete] = useState(false);

  const cancelCurrentOrder = () => {
    setIsOrderCanceling(true);
    const param = { masterOrderId: id };

    cancelOrder(param)
      .then((response) => {
        if (response) {
          localStorage.setItem(
            TABLE_IDS.ORDER_LIST,
            JSON.stringify({ orderType: 'new-order', timeFilter: timeFilter })
          );

          navigate(`${ROUTES.ORDERS}`);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsOrderCanceling(false);
      });
  };

  const handleDiscardModalClose = useCallback(
    (status: boolean) => () => {
      if (status) {
        const isDraftOrder = order?.orderStatus === 'draft';
        const shouldNavigateBack = history.length > 3;

        if (isDraftOrder || !shouldNavigateBack) {
          const navigateToOrders = () => {
            const orderListData = {
              orderType: 'new-order',
              timeFilter: timeFilter,
            };

            localStorage.setItem(
              TABLE_IDS.ORDER_LIST,
              JSON.stringify(orderListData)
            );

            navigate(ROUTES.ORDERS);
          };

          navigateToOrders();
        } else {
          navigate(-1);
        }
      } else {
        setIsOrderDiscardModal(false);
      }
    },
    [order, timeFilter]
  );

  const getBadgeType = (status: any) => {
    let type: BadgeType = 'success';

    if (
      status === 'draft' ||
      status === 'cancelled' ||
      status === 'dispatched'
    ) {
      type = 'pink';
    } else if (status === 'booked' || status === 'delivered') {
      type = 'lightblue';
    } else if (status === 'in-transit') {
      type = 'error';
    } else if (status === 'completed') {
      type = 'gray';
    } else {
      type = 'red';
    }

    return type;
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      if (status && deletOrderId) {
        setIsDeleteLoading(true);

        deleteOrder({ orderId: deletOrderId })
          .then(() => {
            setIsOrderDelete(false);
            setDeletOrderId(null);

            const orderListData = {
              orderType: 'new-order',
              timeFilter: timeFilter,
            };

            localStorage.setItem(
              TABLE_IDS.ORDER_LIST,
              JSON.stringify(orderListData)
            );

            navigate(`${ROUTES.ORDERS}`);
          })
          .catch((e) => {
            WalToast.error(
              e?.response?.data?.message ??
                'Something went wrong while deleting file.'
            );
          })
          .finally(() => {
            setIsDeleteLoading(false);
          });
      } else {
        setIsOrderDelete(false);
        setDeletOrderId(null);
      }
    },
    [isOrderDelete]
  );

  const memorizedBadge = useMemo(() => {
    const badgeType =
      id && order?.orderStatus ? getBadgeType(order.orderStatus) : 'success';
    const badgeText =
      !isLoading && order?.orderStatus ? order.orderStatus : 'Opened';
    const mainClassName = `rounded-md ${isLoading ? 'custom-loading' : ''}`;

    return (
      <BadgeCmp style="modern" mainClassName={mainClassName} type={badgeType}>
        {badgeText}
      </BadgeCmp>
    );
  }, [id, order, isLoading]);

  return (
    <>
      <Header
        leftClassName="!w-auto"
        title={
          <div className="flex gap-x-2.5 gap-y-1 flex-wrap items-center">
            {id ? 'Order:' : 'New Order'}
            {id && (
              <>
                <span className={`${isLoading ? 'custom-loading' : ''}`}>
                  #{!isLoading && order ? order.orderId : 'CL-AOI1-141'}
                </span>
                <div className="flex items-center [&>div:not(:first-child)>div>div]:border-white [&>div:not(:first-child)>div>div]:border-[1.5px] [&>div:not(:first-child)]:-ml-2">
                  {order?.salesRepFirstName && (
                    <TooltipCmp
                      message={`${order?.salesRepFirstName} ${order?.salesRepLastName}`}
                    >
                      {order?.salesImage ? (
                        <div className="rounded-full bg-white">
                          <img
                            src={order?.salesImageUrl + order?.salesImage}
                            className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                          />
                        </div>
                      ) : (
                        <div className="rounded-full bg-white">
                          <img
                            src={defaultimage}
                            className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                          />
                        </div>
                      )}
                    </TooltipCmp>
                  )}
                </div>
              </>
            )}
            {memorizedBadge}
          </div>
        }
        desc={`Detailed review of items, prices, and order status`}
        rightSideContent={
          <>
            {isShowActionButton && (
              <OutsideClickHandler
                onOutsideClick={() => setIsShowOrderActionsDropDown(false)}
                containerClassName="relative"
              >
                <ButtonCmp
                  className="btn-outline-primary"
                  onClick={() => setIsShowOrderActionsDropDown((prev) => !prev)}
                  loading={isOrderCanceling}
                  disabled={isOrderCanceling}
                >
                  Order Actions
                </ButtonCmp>
                <ul
                  className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[150px] right-0 absolute ${
                    isShowOrderActionsDropDown ? '' : 'hidden'
                  } `}
                >
                  <li
                    className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                    onClick={() => {
                      setIsShowOrderActionsDropDown(false);
                      navigate(`${PATH.ORDER_DETAILS}/${order?.id}`);
                    }}
                  >
                    Edit
                  </li>
                  <li
                    className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                    onClick={() => {
                      setIsShowOrderActionsDropDown(false);
                      cancelCurrentOrder();
                    }}
                  >
                    Cancel
                  </li>
                  <li
                    className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                    onClick={() => {
                      setIsShowOrderActionsDropDown(false);
                      setDeletOrderId(order?.id);
                      setIsOrderDelete(true);
                    }}
                  >
                    Delete
                  </li>
                  {/* <li
                    className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                    onClick={() => {
                      setIsShowOrderActionsDropDown(false);
                      setIsOrderRecurringModal(true);
                    }}
                  >
                    Recurring
                  </li> */}
                </ul>
              </OutsideClickHandler>
            )}

            <ButtonCmp
              className="btn_secondary_black"
              onClick={() => {
                setIsShowChatSideBar((old: boolean) => !old);
              }}
              icon={<MessageChatCircle className="w-4 h-4" />}
            >
              Messages
            </ButtonCmp>

            <TooltipCmp message={isDiscardButton ? 'Discard Changes' : null}>
              <ButtonCmp
                className="btn_secondary_black lg:!px-[9px] !px-2"
                onClick={() => {
                  if (isDiscardButton) {
                    setIsOrderDiscardModal(true);
                  } else {
                    localStorage.setItem(
                      TABLE_IDS.ORDER_LIST,
                      JSON.stringify({
                        orderType: 'new-order',
                        timeFilter: timeFilter,
                      })
                    );

                    navigate(`${ROUTES.ORDERS}`);
                  }
                }}
              >
                <XClose className="w-4 h-4" />
              </ButtonCmp>
            </TooltipCmp>
          </>
        }
      />

      {isOrderDiscardModal && (
        <DeleteModal
          moduleTitle={'Discard Changes'}
          moduleTitleDesc={`Are you sure you want to discard the changes of order details?`}
          handleClose={handleDiscardModalClose}
          dangerBtnText={'Discard'}
        />
      )}

      {isOrderDelete && (
        <DeleteModal
          moduleName={`order`}
          handleClose={handleModalClose}
          isDeleteLoading={isDeleteLoading}
        />
      )}

      {isOrderRecurringModal && (
        <RecurringOrderModal
          handleClose={() => setIsOrderRecurringModal(false)}
          order={order}
        />
      )}
    </>
  );
};

export default OrderHeader;
