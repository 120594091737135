import moment from 'moment';
import React, { useState, useEffect } from 'react';

const CountDownTimer = ({ endDate }: any) => {
  const calculateTimeDifference = () => {
    const now = moment.utc();
    const duration = moment.duration(moment.utc(endDate).diff(now));
    const days = Math.floor(duration.asDays());
    const hours = String(duration.hours()).padStart(2, '0');
    const minutes = String(duration.minutes()).padStart(2, '0');
    const seconds = String(duration.seconds()).padStart(2, '0');

    if (days > 0) {
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    } else {
      return `${hours}h ${minutes}m ${seconds}s`;
    }
  };

  const [timeDifference, setTimeDifference] = useState(
    calculateTimeDifference()
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeDifference(calculateTimeDifference());
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate]);

  return <>{timeDifference}</>;
};

export default CountDownTimer;
