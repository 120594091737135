/* eslint-disable max-lines-per-function */
import {
  AlertCircle,
  AlertHexagon,
  Cube03,
  Package,
  PackagePlus,
  SearchLg,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import { groupBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AccordionCmp from 'src/components/AccordionCmp';
import NotFoundUI from 'src/components/NotFoundUI';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import TabButton from 'src/components/TabButton';

import ButtonCmp from '../../../../components/ButtonCmp';
import CheckBox from '../../../../components/CheckBox';
import CommonModal from '../../../../components/CommonModal';
import ConfirmModal from '../../../../components/ConfirmModal';
import InputText from '../../../../components/InputText/InputText';
import PageSectionLayout from '../../../../components/PageSectionLayout';
import Header from '../../../../components/PageSectionLayout/Header/Header';
import Radio from '../../../../components/Radio/Radio';
import SelectBox from '../../../../components/SelectBox/SelectBox';
import StepBar from '../../../../components/StepBar/StepBar';
import TooltipCmp from '../../../../components/TooltipCmp';
import {
  FOOT,
  LBS,
  KGS,
  HANDLING_UNIT_NUMBER,
  MEASUREMENT,
  STATUS,
  CURRENCY,
} from '../../../../constants/common';
import { PATH } from '../../../../constants/path';
import { ROUTES } from '../../../../constants/routes';
import { BasicContext } from '../../../../context/BasicContext';
import {
  getPricingRate,
  listHandlingUnit,
  listService,
} from '../../../../services/CommonService';
import {
  createUpdateDimension,
  createUpdateService,
  getQuote,
  getFreightClass,
} from '../../../../services/QuoteService';
import { getSelectBoxOptions } from '../../../../utils/CommonFunctions';
import WalToast from '../../../../utils/WalToast';
import { STEPS } from '../SmartQuote.interface';

import Items from './Items';

const calculateLinearFootage = (
  items: any[],
  truckWidth = 98, // inches
  truckHeight = 102, // inches
  truckLength = 53 * 12 // inches (53 feet converted to inches)
) => {
  if (!items || !items.length) return 0;

  // Sort items by height descending to optimize stacking
  const sortedItems = [...items].sort(
    (a, b) => (Number(b.height) || 0) - (Number(a.height) || 0)
  );

  let totalLinearFootage = 0;
  let currentSection = {
    length: 0,
    usedWidth: 0,
    usedHeight: 0,
    items: [] as any[],
  };

  sortedItems.forEach((item) => {
    if (
      !item.handlingUnitNo ||
      !item.width ||
      !item.height ||
      !item.itemLength
    ) {
      return;
    }

    const units = item.handlingUnitNo;
    const itemWidth = Number(item.width);
    const itemHeight = Number(item.height);
    const itemLength = Number(item.itemLength);

    // Skip invalid dimensions
    if (
      itemWidth <= 0 ||
      itemHeight <= 0 ||
      itemLength <= 0 ||
      itemWidth > truckWidth ||
      itemHeight > truckHeight
    ) {
      return;
    }

    for (let i = 0; i < units; i++) {
      let placed = false;

      // Try to stack on existing items if stackable
      if (!item.isStack) {
        for (let existingItem of currentSection.items) {
          if (
            existingItem.width >= itemWidth &&
            existingItem.stackedHeight + itemHeight <= truckHeight &&
            existingItem.isStack
          ) {
            existingItem.stackedHeight += itemHeight;
            placed = true;
            break;
          }
        }
      }

      // If couldn't stack, try to place beside existing items
      if (!placed) {
        if (currentSection.usedWidth + itemWidth <= truckWidth) {
          currentSection.items.push({
            width: itemWidth,
            height: itemHeight,
            length: itemLength,
            stackedHeight: itemHeight,
            isStack: !item.isStack,
          });
          currentSection.usedWidth += itemWidth;
          currentSection.length = Math.max(currentSection.length, itemLength);
          currentSection.usedHeight = Math.max(
            currentSection.usedHeight,
            itemHeight
          );
          placed = true;
        }
      }

      // If couldn't place in current section, start new section
      if (!placed) {
        totalLinearFootage += currentSection.length;
        currentSection = {
          length: itemLength,
          usedWidth: itemWidth,
          usedHeight: itemHeight,
          items: [
            {
              width: itemWidth,
              height: itemHeight,
              length: itemLength,
              stackedHeight: itemHeight,
              isStack: !item.isStack,
            },
          ],
        };
      }

      // If exceeding truck length, start new row
      if (totalLinearFootage + currentSection.length > truckLength) {
        totalLinearFootage =
          Math.ceil(totalLinearFootage / truckLength) * truckLength;
        currentSection = {
          length: itemLength,
          usedWidth: itemWidth,
          usedHeight: itemHeight,
          items: [
            {
              width: itemWidth,
              height: itemHeight,
              length: itemLength,
              stackedHeight: itemHeight,
              isStack: !item.isStack,
            },
          ],
        };
      }
    }
  });

  // Add final section
  totalLinearFootage += currentSection.length;

  // Convert to feet and round to 2 decimal places
  return Math.round((totalLinearFootage / 12) * 100) / 100;
};

interface IFormData {
  id: number | null;
  handlingUnit: string | null;
  handlingUnitNo: number;
  weight: number;
  itemLength: number;
  width: number;
  height: number;
  totalWeight: number;
  freightClass: number;
  isCommodity: boolean;
  isStack: boolean;
  weightMeasurement: string;
  commodityId: string | null;
  commodityName: string | null;
  sub: string | null;
  description: string | null;
}

const initFormData: IFormData = {
  id: null,
  handlingUnit: '',
  // handlingUnitNo: 1,
  handlingUnitNo: 0,
  weight: 0,
  itemLength: 0,
  width: 0,
  height: 0,
  totalWeight: 0,
  freightClass: 0,
  isCommodity: false,
  isStack: true,
  weightMeasurement: MEASUREMENT.WEIGHT1.value,
  commodityId: null,
  commodityName: null,
  sub: null,
  description: null,
};

const initValidForm = {
  handlingUnit: {
    required: true,
    valid: true,
  },
  handlingUnitNo: {
    required: true,
    valid: true,
  },
  weight: {
    required: true,
    valid: true,
  },
  itemLength: {
    required: true,
    valid: true,
  },
  freightClass: {
    required: true,
    valid: true,
  },
  width: {
    required: true,
    valid: true,
  },
  height: {
    required: true,
    valid: true,
  },
  totalWeight: {
    required: true,
    valid: true,
  },
  isCommodity: {
    required: true,
    valid: true,
  },
  isStack: {
    required: true,
    valid: true,
  },
  commodityId: {
    required: true,
    valid: true,
  },
  commodityName: {
    required: true,
    valid: true,
  },
  sub: {
    required: true,
    valid: true,
  },
  description: {
    required: true,
    valid: true,
  },
};

const initServiceValidForm = {
  limitedAccessOther: true,
  inbondName: true,
  inbondAddress: true,
  inbondContactValue: true,
  unitedNationsNumber: true,
  isEmailValid: true,
  isPhoneValid: true,
  goodsClass: true,
  description: true,
  emergencyContactName: true,
  emergencyContactNumber: true,
  isEmergencyPhoneValid: true,
  declaredValue: true,
};

const initTotal = {
  totalWeight: 0,
  totalUnit: 0,
};

const initAction = {
  tailgate: false,
  unusual: false,
};

const initValidateMeasurement = {
  weight: LBS.WEIGHT,
  length: LBS.LENGTH,
  width: LBS.WIDTH,
  height: LBS.HEIGHT,
};

const limitedAccessList = [
  { name: 'Construction Site', value: 'construction-site' },
  { name: 'Individual (Mini) Storage Unit', value: 'mini-storage-unit' },
  { name: 'Fair/Amusement Park', value: 'fair' },
  { name: 'Place of Worship', value: 'place-of-worship' },
  { name: 'Farm/Country Club/Estate', value: 'farm' },
  {
    name: 'Secured Locations Delivery - prisons, military bases, airport',
    value: 'secured-location',
  },
  { name: 'School/University', value: 'school' },
  { name: 'Plaza/Mall with only parking lot/Street access', value: 'mall' },
  { name: 'Airport', value: 'airport' },
  { name: 'Cemetery', value: 'cemetery' },
  { name: 'Church', value: 'church' },
  { name: 'Convention Center', value: 'convention-center' },
  { name: 'Funeral Home', value: 'funeral-home' },
  { name: 'Golf Course', value: 'golf-course' },
  { name: 'Grocery Warehouse', value: 'grocery-warehouse' },
  { name: 'Hospital/Medical Facility', value: 'hospital-medical-facility' },
  { name: 'Hotel', value: 'hotel' },
  { name: 'Inspection Site', value: 'inspection-site' },
  { name: 'Marina', value: 'marina' },
  { name: 'Military Base', value: 'military-base' },
  { name: 'Mine Site', value: 'mine-site' },
  { name: 'No Dock/Dock obstruction', value: 'no-dock-dock-obstruction' },
  { name: 'Nursing Home', value: 'nursing-home' },
  { name: 'Park Forest/Preserve', value: 'park-forest-preserve' },
  { name: 'Piers and Wharves', value: 'piers-and-wharves' },
  { name: 'Prison', value: 'prison' },
  { name: 'Ranch', value: 'ranch' },
  {
    name: 'Recreational/athletic Facility',
    value: 'recreational-athletic-facility',
  },
  { name: 'Remote Government', value: 'remote-government' },
  { name: 'Reservation', value: 'reservation' },
  { name: 'Restaurant', value: 'restaurant' },
  { name: 'Service Station', value: 'service-station' },
  { name: 'Social Club', value: 'social-club' },
  { name: 'Steel Mill', value: 'steel-mill' },
  { name: 'Unspecified', value: 'unspecified' },
  { name: 'Utility Site', value: 'utility-site' },
  { name: 'Winery/Brewery/Distillery', value: 'winery-brewery-distillery' },
  { name: 'Other', value: 'other' },
];

const inbondTypeList = [
  {
    name: '(IT) Immediate Transportation Bond',
    value: 'immediate-exportation',
  },
  {
    name: '(T&E) Transportation & Export Bond',
    value: 'transportation-and-exportation',
  },
];

const inbondContactMethodList = [
  { name: 'Email', value: 'email-address' },
  { name: 'Phone', value: 'phone-number' },
  { name: 'Fax Number', value: 'fax-number' },
];

const dangerTypeList = [
  { name: 'Limited Quantity', value: 'limited-quantity' },
  { name: '500 kg Exemption', value: 'exemption-500-kg' },
  { name: 'Fully Regulated', value: 'fully-regulated' },
];

const packagingGroupOptions = [
  { label: 'PG I', value: 'PGI' },
  { label: 'PG II', value: 'PGII' },
  { label: 'PG III', value: 'PGIII' },
];

const requiredFields = [
  'handlingUnit',
  'handlingUnitNo',
  'weight',
  'itemLength',
  'width',
  'height',
  'freightClass',
  'commodityId',
  'sub',
  'description',
];

const Dimensions = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<IFormData[]>([initFormData]);
  const [validForm, setValidForm] = useState([initValidForm]);
  const [validServicesForm, setServicesValidForm] =
    useState(initServiceValidForm);
  const [serviceList, setServiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDimensionLoading, setIsDimensionLoading] = useState(true);
  const [serviceTypes, setServiceTypes] = useState<any>();
  const [selectedServices, setSelectedServices] = useState<number[]>([]);

  const [isTailgate, setIsTailgate] = useState<any>([]);
  const [isTotalWeightOver, setIsTotalWeightOver] = useState<boolean>(false);
  const [isItemLengthOver, setIsItemLengthOver] = useState<boolean>(false);

  const [isAccessTypeModalOpen, setIsAccessTypeModalOpen] =
    useState<boolean>(false);
  const [isLimitedMore, setIsLimitedMore] = useState<boolean>(false);
  const [limitedAccessTypeList, setLimitedAccessTypeList] =
    useState<any>(limitedAccessList);
  const [limitedAccessSearch, setLimitedAccessSearch] = useState<string>('');
  const [limitedAccessType, setLimitedAccessType] = useState<string>(
    limitedAccessTypeList?.[0].value
  );
  const [limitedAccessOther, setLimitedAccessOther] = useState<string>('');

  const [isInbondMoreModalOpen, setIsInbondMoreModalOpen] =
    useState<boolean>(false);
  const [isInbondMore, setIsInbondMore] = useState<boolean>(false);
  const [inbondType, setInbondType] = useState<string>(inbondTypeList[0].value);
  const [inbondName, setInbondName] = useState<string>('');
  const [inbondAddress, setInbondAddress] = useState<string>('');
  const [inbondContactMethod, setInbondContactMethod] = useState<string>(
    inbondContactMethodList[0].value
  );
  const [inbondContactValue, setInbondContactValue] = useState<string>('');

  const [isDeclaredValueMore, setIsDeclaredValueMore] =
    useState<boolean>(false);
  const [declaredValue, setDeclaredValue] = useState<any>(0);
  const [rates, setRates] = useState<any>({});
  const [isDangerousMoreModalOpen, setIsDangerousMoreModalOpen] =
    useState<boolean>(false);
  const [isExcessValuationModalOpen, setIsExcessValuationModalOpen] =
    useState<boolean>(false);
  const [isDangerousMore, setIsDangerousMore] = useState<boolean>(false);
  const [dangerType, setDangerType] = useState<string>(dangerTypeList[0].value);
  const [packagingGroup, setPackagingGroup] = useState<string>(
    packagingGroupOptions[0].value
  );
  const [unitedNationsNumber, setUnitedNationsNumber] = useState<string>('');
  const [goodsClass, setGoodsClass] = useState<string>('');
  const [emergencyContactName, setEmergencyContactName] = useState<string>('');
  const [emergencyContactNumber, setEmergencyContactNumber] =
    useState<string>('');
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState<string>('');
  const [isDangerousFormSubmitted, setIsDangerousFormSubmitted] =
    useState<boolean>(false);
  const [emergencyContactExt, setEmergencyContactExt] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [total, setTotal] = useState(initTotal);
  const [handlingUnitOptions, setHandlingUnitOptions] = useState<any>([]);
  const [isHandlingUnitLoading, setIsHandlingUnitLoading] = useState(true);
  const [weightMeasurement, setWeightMeasurement] = useState(
    MEASUREMENT.WEIGHT1.value
  );
  const [lengthMeasurement, setLengthMeasurement] = useState(
    MEASUREMENT.LENGTH1.value
  );
  const [validateMeasurement, setValidateMeasurement] = useState(
    initValidateMeasurement
  );
  const [action, setAction] = useState(initAction);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [isQuoteDetailChange, setIsQuoteDetailChange] =
    useState<boolean>(false);
  const [originalQuoteDetail, setOriginalQuoteDetail] = useState<any>({});
  const [isModifyingQuote, setIsModifyingQuote] = useState<boolean>(false);
  const [quoteDetails, setQuoteDetails] = useState<any>({});
  const [allowHandleChecked, setAllowHandleChecked] = useState<any>(true);
  const { currency } = useContext(BasicContext);
  const [declaredValueUnit, setDeclaredValueUnit] = useState(currency);
  const [declaredValueWithUnit, setDeclaredValueWithUnit] = useState({
    declaredValueCAD: 0,
    declaredValueUSD: 0,
  });
  const [serviceSearch, setServiceSearch] = useState<any>({
    pickup: '',
    delivery: '',
    other: '',
  });

  const filterArr: any = [
    {
      value: CURRENCY.CAD,
      name: 'CAD',
      tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
    },
    {
      value: CURRENCY.USD,
      name: 'USD',
      tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
    },
  ];
  useEffect(() => {
    if (!allowHandleChecked && !isLoading) {
      setTimeout(() => {
        setAllowHandleChecked(true);
      }, 1500);
    }
  }, [allowHandleChecked, isLoading]);

  const getRate = () => {
    getPricingRate()
      .then((response: any) => {
        setRates(response?.data);
      })
      .catch(() => {
        WalToast.error('Something went wrong while fetching rate.');
        console.error('Pricing rates error');
      });
  };

  useEffect(() => {
    getRate();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('isModifyQuoteFromQuote')) {
      setPageTitle(`Modify Quote: #${localStorage.getItem('modifyQuoteCode')}`);
      setIsModifyingQuote(true);

      const modifyQuoteId = parseInt(
        localStorage.getItem('isModifyQuoteFromQuote') || ''
      );
      getQuote(modifyQuoteId)
        .then((response: any) => {
          if (response && response.data) {
            setOriginalQuoteDetail(response.data);
          } else {
            navigate(ROUTES.QUOTING_DASHBOARD);
          }
        })
        .catch(console.error);
    } else {
      setPageTitle('New Quote');
    }
    listHandlingUnit()
      .then((result: any) => {
        if (result.data && result.data.length) {
          const handlingUnitSelectBoxOptions = getSelectBoxOptions(
            result.data,
            'id',
            'name'
          );
          setHandlingUnitOptions(handlingUnitSelectBoxOptions);
          const newDataArray = [...formData];
          newDataArray[0].handlingUnit =
            handlingUnitSelectBoxOptions.length > 0
              ? handlingUnitSelectBoxOptions[0].value
              : '';
          setFormData(newDataArray);
        }
      })
      .finally(() => setIsHandlingUnitLoading(false))
      .catch(console.error);
  }, []);

  const compareArrays = (
    originalArray: any,
    modifiedArray: any,
    compareKey: any[] = []
  ) => {
    if (originalArray.length !== modifiedArray.length) {
      return false;
    }

    for (let i = 0; i < originalArray.length; i++) {
      const originalObj = originalArray[i];
      const modifiedObj = modifiedArray[i];

      for (let key in originalObj) {
        if (compareKey.includes(key) && originalObj[key] !== modifiedObj[key]) {
          return false;
        }
      }
    }

    return true;
  };

  useEffect(() => {
    if (isModifyingQuote) {
      if (quoteDetails && originalQuoteDetail?.addressDetails) {
        if (
          quoteDetails.pickupDate &&
          originalQuoteDetail?.addressDetails?.pickupDate &&
          moment(quoteDetails.pickupDate, 'YYYY-MM-DD HH:mm:ss').format(
            'YYYY-MM-DD'
          ) !==
            moment(
              originalQuoteDetail.addressDetails.pickupDate,
              'YYYY-MM-DD HH:mm:ss'
            ).format('YYYY-MM-DD')
        ) {
          setIsQuoteDetailChange(true);

          return;
        }

        if (
          quoteDetails.consigneeFullAddress !==
            originalQuoteDetail.addressDetails.consigneeFullAddress ||
          quoteDetails.shipperFullAddress !==
            originalQuoteDetail.addressDetails.shipperFullAddress ||
          quoteDetails.customerId !== originalQuoteDetail.customerId
        ) {
          setIsQuoteDetailChange(true);

          return;
        }
      }

      if (selectedServices?.length) {
        if (originalQuoteDetail.additionalService?.length) {
          if (
            selectedServices.length !==
            originalQuoteDetail.additionalService.length
          ) {
            setIsQuoteDetailChange(true);

            return;
          }

          originalQuoteDetail.additionalService.forEach((element: any) => {
            if (!selectedServices.includes(element.serviceId)) {
              setIsQuoteDetailChange(true);

              return;
            }
          });
        } else {
          setIsQuoteDetailChange(true);

          return;
        }
      } else if (originalQuoteDetail.additionalService?.length) {
        setIsQuoteDetailChange(true);

        return;
      }

      if (originalQuoteDetail.dimensions?.length !== formData?.length) {
        setIsQuoteDetailChange(true);

        return;
      }

      if (originalQuoteDetail.dimensions?.length && formData?.length) {
        const isSameArray = compareArrays(
          originalQuoteDetail.dimensions,
          formData,
          [
            'handlingUnitNo',
            'height',
            'width',
            'itemLength',
            'weight',
            'freightClass',
          ]
        );
        setIsQuoteDetailChange(!isSameArray);

        return;
      }

      setIsQuoteDetailChange(false);
    }
  }, [originalQuoteDetail, quoteDetails, selectedServices, formData]);

  useEffect(() => {
    if (isLimitedMore && allowHandleChecked) {
      setIsAccessTypeModalOpen(true);
    }
  }, [isLimitedMore]);

  useEffect(() => {
    if (isInbondMore && allowHandleChecked) {
      setIsInbondMoreModalOpen(true);
    }
  }, [isInbondMore]);

  useEffect(() => {
    if (isDangerousMore && allowHandleChecked) {
      setIsDangerousMoreModalOpen(true);
    }
  }, [isDangerousMore]);

  useEffect(() => {
    if (isDeclaredValueMore && allowHandleChecked) {
      setIsExcessValuationModalOpen(true);
    }
  }, [isDeclaredValueMore]);

  useEffect(() => {
    if (declaredValueUnit === CURRENCY.CAD) {
      if (declaredValueWithUnit.declaredValueCAD)
        setDeclaredValue(declaredValueWithUnit.declaredValueCAD);
    } else if (declaredValueUnit === CURRENCY.USD) {
      if (declaredValueWithUnit.declaredValueUSD)
        setDeclaredValue(declaredValueWithUnit.declaredValueUSD);
    }
  }, [declaredValueUnit]);

  const getListService = () => {
    listService({})
      .then((response: any) => {
        setServiceList(response.data);

        if (response.data.length) {
          let serviceByGroup: any = groupBy(response.data, (val) => val.type);
          setServiceTypes(serviceByGroup);
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(console.log);
  };

  useEffect(() => {
    let filterServices = serviceList.filter((service: any) => {
      if (selectedServices.includes(service.id)) {
        return true;
      }
      let matchService = true;

      if (service.type === 'PICKUP' && serviceSearch.pickup) {
        matchService = service.name
          .toLowerCase()
          .includes(serviceSearch.pickup.toLowerCase());
      }

      if (service.type === 'DELIVERY' && serviceSearch.delivery) {
        matchService = service.name
          .toLowerCase()
          .includes(serviceSearch.delivery.toLowerCase());
      }

      if (service.type === 'OTHER' && serviceSearch.other) {
        matchService = service.name
          .toLowerCase()
          .includes(serviceSearch.other.toLowerCase());
      }

      return matchService;
    });
    let serviceByGroup: any = groupBy(filterServices, (val: any) => val.type);
    setServiceTypes(serviceByGroup);
  }, [serviceSearch]);

  const getQuoteInfo = (quoteId: any) => {
    setAllowHandleChecked(false);
    setIsDimensionLoading(true);
    getQuote(quoteId)
      .then((response: any) => {
        if (response && response.data) {
          const { dimensions, addressDetails, additionalService } =
            response.data;
          setQuoteDetails(addressDetails);

          if (addressDetails.status !== STATUS.PENDING) {
            navigate(ROUTES.QUOTING_DASHBOARD);
          }

          if (additionalService) {
            let savedServices = additionalService.map((val: any) => {
              if (
                val.slug === 'tailgate_pickup' ||
                val.slug === 'tailgate_delivery'
              ) {
                setIsTailgate((old: any) => [...old, val.serviceId]);
              }

              if (val.slug === 'limited_access_delivery') {
                setIsLimitedMore(true);
                setLimitedAccessType(val.limitedAccessType);
                setLimitedAccessOther(val.limitedAccessOther);
              }

              if (val.slug === 'in-bond') {
                setIsInbondMore(true);
                setInbondType(val.inbondType);
                setInbondName(val.inbondName);
                setInbondAddress(val.inbondAddress);
                setInbondContactMethod(val.inbondContactMethod);
                setInbondContactValue(val.inbondContactValue);
              }

              if (val.slug === 'excess_valuation') {
                setIsDeclaredValueMore(true);
                setDeclaredValueWithUnit({
                  ...declaredValueWithUnit,
                  ...{
                    declaredValueCAD: val.declaredValueCAD,
                    declaredValueUSD: val.declaredValueUSD,
                  },
                });

                setDeclaredValueUnit(val.declaredValueUnit);

                if (val.declaredValueUnit === CURRENCY.CAD) {
                  setDeclaredValue(val.declaredValueCAD);
                } else if (val.declaredValueUnit === CURRENCY.USD) {
                  setDeclaredValue(val.declaredValueUSD);
                }
              }

              if (val.slug === 'dangerous_goods') {
                setIsDangerousMore(true);
                setDangerType(val.dangerGoodsType);
                setPackagingGroup(val.packagingGroup);
                setUnitedNationsNumber(val.unitedNationsNumber);
                setGoodsClass(val.goodsClass);
                setEmergencyContactName(val.emergencyContactName);
                setEmergencyContactNumber(val.emergencyContactNumber);
                setEmergencyContactExt(val.emergencyContactExt);
                setDescription(val.description);
              }

              return val.serviceId;
            });
            setSelectedServices(savedServices);
          }

          if (dimensions) {
            let totalWeight = 0;
            setWeightMeasurement(dimensions[0].weightMeasurement);
            dimensions.forEach((dimension: any) => {
              totalWeight += dimension.totalWeight;
              const maxLengthOver =
                dimensions[0].weightMeasurement === MEASUREMENT.WEIGHT1
                  ? LBS.ITEM_LENGTH_OVER
                  : KGS.ITEM_LENGTH_OVER;

              if (dimension.itemLength >= maxLengthOver) {
                // 5 feet over
                setIsItemLengthOver(true);
              }
            });
            const isTotalWeightOverBool =
              dimensions[0].weightMeasurement === MEASUREMENT.WEIGHT1
                ? totalWeight >= LBS.MAX_WEIGHT
                : totalWeight >= KGS.MAX_WEIGHT;
            setIsTotalWeightOver(isTotalWeightOverBool);
          }
          // setIsDimensionLoading(false);
        } else {
          setIsDimensionLoading(false);
          navigate(ROUTES.QUOTING_DASHBOARD);
        }
      })
      .catch((e: any) => {
        console.error(e);
        setIsDimensionLoading(false);
      });
  };

  useEffect(() => {
    if (!isHandlingUnitLoading) {
      if (id) {
        setIsDimensionLoading(true);
        getListService();
        getQuoteInfo(id);
        getQuote(id)
          .then(async (response: any) => {
            if (response && response.data) {
              const { dimensions, addressDetails } = response.data;

              if (addressDetails.status !== STATUS.PENDING) {
                navigate(ROUTES.QUOTING_DASHBOARD);
              }

              if (dimensions) {
                let weightMeasurementValue = MEASUREMENT.WEIGHT1.value;
                let lengthMeasurementValue = MEASUREMENT.LENGTH1.value;

                const data: any = [];

                for (const dimension of dimensions) {
                  setValidForm((old: any) => [...old, initValidForm]);
                  weightMeasurementValue = dimension.weightMeasurement;
                  lengthMeasurementValue = dimension.lengthMeasurement;
                  delete dimension.createdAt;
                  delete dimension.updatedAt;

                  let freightClass = dimension.freightClass;
                  let itemClass = dimension.itemClass;

                  if (!freightClass || !itemClass) {
                    const freightData = await getFreightClass({
                      itemLength: dimension.itemLength,
                      width: dimension.width,
                      height: dimension.height,
                      weight: dimension.weight,
                      lengthUnit: dimension.lengthMeasurement,
                      weightUnit: dimension.weightMeasurement,
                    })
                      .then((dataRes: any) => dataRes?.data || 0)
                      .catch(() => 0);

                    dimension.freightClass = freightData?.freightClass;
                    dimension.itemClassForDayross =
                      freightData?.itemClassForDayross;
                  }

                  data.push(dimension);
                }

                setFormData(data);
                setWeightMeasurement(weightMeasurementValue);
                setLengthMeasurement(lengthMeasurementValue);
              }
              setIsDimensionLoading(false);
            } else {
              setIsDimensionLoading(false);
              navigate(ROUTES.QUOTING_DASHBOARD);
            }
          })
          .catch((e: any) => {
            console.error(e);
            setIsDimensionLoading(false);
          });
      } else {
        setIsDimensionLoading(false);
        navigate(ROUTES.QUOTING_DASHBOARD);
      }
    }
  }, [isHandlingUnitLoading]);

  useEffect(() => {
    let totalUnit = 0;
    let totalWeight: number | string = 0;
    let weightMeasurementValue = '';
    formData.forEach((form: any) => {
      totalUnit += form.handlingUnitNo || 0;
      totalWeight += form.totalWeight;
      weightMeasurementValue = form.weightMeasurement;

      const maxLengthOver =
        formData?.[0].weightMeasurement === MEASUREMENT.WEIGHT1.value
          ? LBS.ITEM_LENGTH_OVER
          : KGS.ITEM_LENGTH_OVER;

      if (form.itemLength >= maxLengthOver) {
        // 5 feet over
        setIsItemLengthOver(true);
      }
    });
    totalWeight = totalWeight.toFixed();
    setTotal((old: any) => ({ ...old, totalUnit, totalWeight }));

    const isTotalWeightOverBool =
      weightMeasurementValue === MEASUREMENT.WEIGHT1.value
        ? parseFloat(totalWeight) >= LBS.MAX_WEIGHT
        : parseFloat(totalWeight) >= KGS.MAX_WEIGHT;
    setIsTotalWeightOver(isTotalWeightOverBool);
  }, [formData]);

  useEffect(() => {
    const form = formData.map((formDataField: any) => {
      formDataField.quoteId = id;
      formDataField.weightMeasurement = weightMeasurement;

      return formDataField;
    });
    setFormData(form);
    const validateMeasurementValue = {
      weight:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.WEIGHT
          : LBS.WEIGHT,
      length:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.LENGTH
          : LBS.LENGTH,
      width:
        weightMeasurement === MEASUREMENT.WEIGHT2.value ? KGS.WIDTH : LBS.WIDTH,
      height:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.HEIGHT
          : LBS.HEIGHT,
    };
    setValidateMeasurement((old) => ({ ...old, ...validateMeasurementValue }));
  }, [weightMeasurement]);

  const handleChange = (e: any) => {
    let { value, checked } = e.target;
    value = parseInt(value);

    if (checked) {
      setSelectedServices((old: any) => [...old, value]);

      if (
        e.target.name === 'tailgate_pickup' ||
        e.target.name === 'tailgate_delivery'
      ) {
        setIsTailgate((old: any) => [...old, value]);
      }

      if (e.target.name === 'limited_access_delivery') {
        setIsLimitedMore(true);
      }

      if (e.target.name === 'in-bond') {
        setIsInbondMore(true);
      }

      if (e.target.name === 'excess_valuation') {
        setIsDeclaredValueMore(true);
      }

      if (e.target.name === 'dangerous_goods') {
        setIsDangerousMore(true);
      }
    } else {
      let arr = selectedServices.filter((item: any) => item !== value);
      setSelectedServices(arr);
      setIsTailgate((current: any) =>
        current.filter((item: any) => item !== value)
      );

      if (e.target.name === 'limited_access_delivery') {
        setIsLimitedMore(false);
        setLimitedAccessType(limitedAccessTypeList[0].value);
      }

      if (e.target.name === 'in-bond') {
        setIsInbondMore(false);
        // setInbondContactMethod(inbondContactMethodList[0].value);
        setInbondType(inbondTypeList[0].value);
      }

      if (e.target.name === 'dangerous_goods') {
        setIsDangerousMore(false);
      }

      if (e.target.name === 'excess_valuation') {
        setIsDeclaredValueMore(false);
      }
    }
  };

  const getList = (data: any) => (
    <div className="flex sm:flex-row flex-col xlm:[&>div]:min-w-[25%] xlm:[&>div:nth-child(4n+1)]:min-w-[25%] xlm:[&>div:nth-child(4n+3)]:min-w-[25%] xlm:[&>div:nth-child(4n)]:min-w-[25%] xl:[&>div]:min-w-[22%] xl:[&>div:nth-child(4n+1)]:min-w-[22%] xl:[&>div:nth-child(4n+3)]:min-w-[25%] xl:[&>div:nth-child(4n)]:min-w-[30%] sm:[&>div]:min-w-[50%] sm:[&>div:nth-child(4n)]:min-w-[50%] flex-wrap grid-cols-3 overflow-hidden before:[&>div]:content-[''] before:[&>div]:h-px before:[&>div]:w-[90vw] before:[&>div]:bg-utilityGray200 before:[&>div]:top-0 before:[&>div]:left-0 before:[&>div]:absolute before:[&>div]:hidden xl:before:[&>div:nth-child(5n)]:block sm:before:[&>div:nth-child(2n)]:block before:[&>div:nth-child(n)]:block">
      {data.map((val: any) => (
        <div
          className="flex md:py-6 md:px-4 py-5 px-2 relative"
          key={`service_${val.id}`}
        >
          <CheckBox
            id={`service_${val.id}`}
            onChangeFunc={handleChange}
            name={val.slug}
            value={val.id}
            checked={selectedServices.includes(val.id)}
            parentClassName="mt-0.5"
            disabled={val.isOnlyForFTL}
          />
          <div className="flex leading-4">
            {val.description ? (
              <TooltipCmp message={val.description} parentClassName="max-w-80">
                <label
                  htmlFor={`service_${val.id}`}
                  className={`md:ml-2 ml-1.5 text-sm leading-4 text-textSecondary font-medium xxl:me-2.5 me-1.5 ${
                    val.isOnlyForFTL ? 'line-through' : 'cursor-pointer'
                  }`}
                >
                  {val.name}
                </label>
              </TooltipCmp>
            ) : (
              <label
                htmlFor={`service_${val.id}`}
                className={`md:ml-2 ml-1.5 text-sm leading-4 text-textSecondary font-medium xxl:me-2.5 me-1.5 ${
                  val.isOnlyForFTL ? 'line-through' : 'cursor-pointer'
                }`}
              >
                {val.name}
              </label>
            )}

            {/* {["limited_access_delivery", "dangerous_goods", "in-bond"].includes(val.slug) && (
                            <TooltipCmp message={""} parentClassName="max-w-80">
                                <InfoCircle className="w-4 h-4 mt-0.5" />
                            </TooltipCmp>
                        )} */}
          </div>
        </div>
      ))}
    </div>
  );

  const getName = (contactMethod: string) => {
    if (contactMethod === 'fax-number') {
      return 'Fax';
    } else if (contactMethod === 'phone-number') {
      return 'Phone';
    } else {
      return 'Email';
    }
  };

  const parsedPhoneNumber = inbondContactValue
    ? parsePhoneNumberFromString(inbondContactValue)
    : null;
  const defaultValues = {
    phone: parsedPhoneNumber?.nationalNumber || '',
    phone_country_code: parsedPhoneNumber?.country || '',
  };

  const emergencyContactNo = emergencyContactNumber
    ? parsePhoneNumberFromString(emergencyContactNumber)
    : null;
  const defaultEmergencyPhoneValues = {
    phone: emergencyContactNo?.nationalNumber || '',
    phone_country_code: emergencyContactNo?.country || '',
  };

  const isInBondFormValid = () => {
    let valid = true;
    const validFormData = {
      inbondName: true,
      inbondAddress: true,
      inbondContactValue: true,
      unitedNationsNumber: true,
      isEmailValid: false,
      isPhoneValid: true,
    };

    if (isInbondMore) {
      if (inbondName === '' || inbondName === null) {
        validFormData.inbondName = false;
        valid = false;
      }

      if (inbondAddress === '' || inbondAddress === null) {
        validFormData.inbondAddress = false;
        valid = false;
      }

      if (inbondContactValue === '' || inbondContactValue === null) {
        validFormData.inbondContactValue = false;
        valid = false;
      } else {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
        validFormData.inbondContactValue = true;

        if (getName(inbondContactMethod) === 'Email') {
          if (emailRegex.test(inbondContactValue)) {
            validFormData.isEmailValid = true;
          } else {
            validFormData.isEmailValid = false;
            valid = false;
          }
        } else {
          validFormData.isEmailValid = true;
        }

        if (getName(inbondContactMethod) === 'Phone') {
          if (!validServicesForm.isPhoneValid) {
            validFormData.isPhoneValid = false;
            valid = false;
          }

          if (!validServicesForm.inbondContactValue) {
            validFormData.inbondContactValue = false;
            valid = false;
          }
        }
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const isAccessTypeFormValid = () => {
    let valid = true;
    const validFormData = {
      limitedAccessOther: true,
    };

    if (limitedAccessType === 'other') {
      if (limitedAccessOther === '' || limitedAccessOther === null) {
        validFormData.limitedAccessOther = false;
        valid = false;
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const handleChangeRadio = (e: any) => {
    let { value, name } = e.target;
    setInbondContactValue('');

    if (name === 'contactMethod') {
      isInBondFormValid();

      setInbondContactMethod(value);
    }

    if (name === 'inbondName') {
      setInbondType(value);
    }

    if (name === 'limitedAccessType') {
      setLimitedAccessType(value);
    }

    if (name === 'dangerType') {
      setDangerType(value);
    }
  };

  const submitService = () => {
    if (id) {
      const formDataCurrent: any = {
        quoteId: parseInt(id),
        serviceId: selectedServices.join(','),
        limitedAccess: isLimitedMore,
        inbond: isInbondMore,
        dangerousGoods: isDangerousMore,
        modifyQuoteId: localStorage.getItem('isModifyQuoteFromQuote'),
      };

      if (isLimitedMore) {
        formDataCurrent.limitedAccessType = limitedAccessType;
        formDataCurrent.limitedAccessOther = limitedAccessOther;
      }

      if (isInbondMore) {
        formDataCurrent.inbondType = inbondType;
        formDataCurrent.inbondName = inbondName;
        formDataCurrent.inbondAddress = inbondAddress;
        formDataCurrent.inbondContactMethod = inbondContactMethod;
        formDataCurrent.inbondContactValue = inbondContactValue;
      }

      if (isDangerousMore) {
        formDataCurrent.dangerGoodsType = dangerType;
        formDataCurrent.goodsClass = goodsClass;
        formDataCurrent.description = description;
        formDataCurrent.unitedNationsNumber = unitedNationsNumber;
        formDataCurrent.packagingGroup = packagingGroup;
        formDataCurrent.emergencyContactName = emergencyContactName;
        formDataCurrent.emergencyContactNumber = emergencyContactNumber;
        formDataCurrent.emergencyContactExt = emergencyContactExt;
      }

      if (isDeclaredValueMore) {
        formDataCurrent.declaredValue = declaredValue;
        formDataCurrent.isDeclaredValue = isDeclaredValueMore;
        formDataCurrent.currency = declaredValueUnit;
      }
      createUpdateService(formDataCurrent)
        .then(() => {
          let redirectUrl = `${PATH.MY_QUOTE}/${
            localStorage.getItem('isModifyQuoteFromQuote') ?? id
          }`;

          // if (quoteDetails?.emailContentId) {
          //   redirectUrl = `/`;
          // }

          navigate(redirectUrl);
        })
        .finally(() => setIsLoading(false))
        .catch(() =>
          WalToast.error('Additional services have not been updated', '')
        );
    }
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);

      if (status) {
        submitService();
        // if (action.unusual) {
        //     submitService();
        // } else {
        //     // handleActionType('unusual');
        //     submitService();
        // }
      }
    },
    [action]
  );

  const isFormValid = () => {
    const validFormData: any = [];
    formData.forEach((form: any) => {
      const validation = {
        ...initValidForm,
        handlingUnit: {
          required: Boolean(form.handlingUnit),
          valid: Boolean(form.handlingUnit),
        },
        weight: {
          required: Boolean(form.weight),
          valid: Boolean(form.weight),
        },
        handlingUnitNo: {
          required: Boolean(form.handlingUnitNo),
          valid: form.handlingUnitNo <= HANDLING_UNIT_NUMBER,
        },
        itemLength: {
          required: Boolean(form.itemLength),
          valid: form.itemLength <= validateMeasurement.length,
        },
        width: {
          required: Boolean(form.width),
          valid: form.width <= validateMeasurement.width,
        },
        height: {
          required: Boolean(form.height),
          valid: form.height <= validateMeasurement.height,
        },
        freightClass: {
          required: Boolean(form.freightClass),
          valid: form.freightClass > 0,
        },
        commodityId: {
          required: form.isCommodity ? Boolean(form.commodityId) : true,
          valid: form.isCommodity ? Boolean(form.commodityId) : true,
        },
        sub: {
          required: form.isCommodity ? Boolean(form.sub) : true,
          valid: form.isCommodity ? Boolean(form.sub) : true,
        },
        description: {
          required: Boolean(form.description),
          valid: Boolean(form.description),
        },
      };
      validFormData.push(validation);
    });
    setValidForm(validFormData);
    let valid = validFormData.every((form: any) =>
      requiredFields.every(
        (requiredField) =>
          form[requiredField].required && form[requiredField].valid
      )
    );

    return valid;
  };

  const validation = () => {
    let valid = true;
    const validFormData = {
      limitedAccessOther: true,
      inbondName: true,
      inbondAddress: true,
      inbondContactValue: true,
      isEmailValid: true,
      isPhoneValid: true,
      unitedNationsNumber: true,
      goodsClass: true,
      description: true,
      emergencyContactName: true,
      emergencyContactNumber: true,
      isEmergencyPhoneValid: true,
      declaredValue: true,
    };

    if (isLimitedMore && limitedAccessType === 'other') {
      if (limitedAccessOther === '' || limitedAccessOther === null) {
        validFormData.limitedAccessOther = false;
        valid = false;
      }
    }

    if (isInbondMore) {
      if (inbondName === '' || inbondName === null) {
        validFormData.inbondName = false;
        valid = false;
      }

      if (inbondAddress === '' || inbondAddress === null) {
        validFormData.inbondAddress = false;
        valid = false;
      }

      if (inbondContactValue === '' || inbondContactValue === null) {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;
        validFormData.inbondContactValue = false;

        if (
          getName(inbondContactMethod) === 'Email' &&
          !emailRegex.test(inbondContactValue)
        ) {
          validFormData.isEmailValid = false;
          valid = false;
        }
      }
    }

    if (isDangerousMore) {
      if (unitedNationsNumber === '' || unitedNationsNumber === null) {
        validFormData.unitedNationsNumber = false;
        valid = false;
      }

      if (goodsClass === '' || goodsClass === null) {
        validFormData.goodsClass = false;
        valid = false;
      }

      if (description === '' || description === null) {
        validFormData.description = false;
        valid = false;
      }

      if (emergencyContactName === '' || emergencyContactName === null) {
        validFormData.emergencyContactName = false;
        valid = false;
      }

      if (emergencyContactNumber === '' || emergencyContactNumber === null) {
        validFormData.emergencyContactNumber = false;
        valid = false;
      }
    }

    if (isDeclaredValueMore) {
      if (!declaredValue) {
        validFormData.declaredValue = false;
        valid = false;
      }
    }
    setServicesValidForm(validFormData);

    return valid;
  };

  const isDangerousGoodsFormValid = () => {
    let valid = true;
    const validFormData = {
      unitedNationsNumber: true,
      goodsClass: true,
      description: true,
      emergencyContactName: true,
      emergencyContactNumber: true,
      isEmergencyPhoneValid: true,
    };

    if (isDangerousMore) {
      if (unitedNationsNumber === '' || unitedNationsNumber === null) {
        validFormData.unitedNationsNumber = false;
        valid = false;
      }

      if (goodsClass === '' || goodsClass === null) {
        validFormData.goodsClass = false;
        valid = false;
      }

      if (description === '' || description === null) {
        validFormData.description = false;
        valid = false;
      }

      if (emergencyContactName === '' || emergencyContactName === null) {
        validFormData.emergencyContactName = false;
        valid = false;
      }

      if (emergencyPhoneNumber === '' || emergencyPhoneNumber === null) {
        validFormData.emergencyContactNumber = false;
        valid = false;
      } else {
        if (!validServicesForm.isEmergencyPhoneValid) {
          validFormData.isEmergencyPhoneValid = false;
          valid = false;
        }
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const isExcessValuation = () => {
    let valid = true;
    const validFormData = {
      declaredValue: true,
    };

    if (!declaredValue) {
      validFormData.declaredValue = false;
      valid = false;
    }
    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const handleActionType = (actionKey: string) => () => {
    setAction((old) => ({ ...old, [actionKey]: true }));
  };

  const handleServiceActionType = (actionKey: string) => {
    setAction((old) => ({ ...old, [actionKey]: true }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!isFormValid() || !validation()) {
      return true;
    }

    if (isModifyingQuote && !isQuoteDetailChange) {
      navigate(
        `${PATH.MY_QUOTE}/${localStorage.getItem('isModifyQuoteFromQuote')}`
      );

      return true;
    }

    setIsLoading(true);
    createUpdateDimension({
      dimensions: formData,
      modifyQuoteId: localStorage.getItem('isModifyQuoteFromQuote'),
    })
      .then((result) => {
        if (result) {
          if (isTailgate.length && (isTotalWeightOver || isItemLengthOver)) {
            setIsLoading(false);
            handleServiceActionType('tailgate');
          } else {
            // handleActionType('unusual');
            submitService();
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        WalToast.error('Dimensions have not been updated', '');
      });
  };

  const handleMeasurement =
    (lengthMeasurementValue: string) => (event: any) => {
      const { value } = event.target;
      setWeightMeasurement(value);
      setLengthMeasurement(lengthMeasurementValue);
    };

  const handleNumberChange = (
    country: CountryCode,
    phone: string,
    code: string,
    isValid: boolean
  ) => {
    var validFormData = {
      phone: true,
      isPhoneValid: true,
      inbondContactValue: true,
    };
    defaultValues.phone_country_code = country;
    setInbondContactValue(`+${code}${phone}`);

    if (phone === '') {
      validFormData.phone = false;
      validFormData.isPhoneValid = false;
      validFormData.inbondContactValue = false;
    } else {
      validFormData.isPhoneValid = isValid;
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));
  };

  const handleDangerPhoneChange = (
    country: CountryCode,
    phone: string,
    code: string,
    isValid: boolean
  ) => {
    var validFormData = {
      emergencyContactNumber: true,
      isEmergencyPhoneValid: true,
    };
    defaultEmergencyPhoneValues.phone_country_code = country;
    setEmergencyContactNumber(`+${code}${phone}`);
    setEmergencyPhoneNumber(`${phone}`);

    if (phone === '') {
      validFormData.emergencyContactNumber = false;
      validFormData.isEmergencyPhoneValid = false;
    } else {
      validFormData.isEmergencyPhoneValid = isValid;
    }

    setServicesValidForm({ ...validServicesForm, ...validFormData });
  };

  useEffect(() => {
    let filterTypes = limitedAccessList.filter((type: any) => {
      if (type.value === limitedAccessType) {
        return true;
      }

      return type.name
        .toLowerCase()
        .includes(limitedAccessSearch.toLowerCase());
    });
    setLimitedAccessTypeList(filterTypes);
  }, [limitedAccessSearch]);

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title={pageTitle}
            desc="Create a new quote by filling out the shipment details below."
            rightSideContent={
              <div className="flex gap-5">
                <StepBar steps={STEPS} activeStep={2} />
                <div className="flex gap-x-3">
                  <ButtonCmp
                    className="btn_secondary_black min-w-[75px]"
                    onClick={() => navigate(`${PATH.ADDRESS_DETAILS}/${id}`)}
                  >
                    Back
                  </ButtonCmp>
                  <ButtonCmp
                    type="submit"
                    className="btn-outline-primary  min-w-[75px]"
                    onClick={(e: any) => handleSubmit(e)}
                    disabled={isLoading || isDimensionLoading}
                  >
                    Next
                  </ButtonCmp>
                  {localStorage.getItem('isModifyQuoteFromQuote') && (
                    <Link
                      to={`${PATH.MY_QUOTE}/${localStorage.getItem(
                        'isModifyQuoteFromQuote'
                      )}`}
                      className="btn_secondary_black !p-2 flex flex-none justify-center items-center"
                    >
                      <XClose className="w-4 h-4" />
                    </Link>
                  )}
                </div>
              </div>
            }
          />
        }
      >
        <div className="w-full justify-between xls:items-center flex xls:flex-row flex-col flex-wrap gap-3">
          <div className="mdm:flex mdm:flex-row flex-wrap xxl:gap-4 mdm:gap-3 xls:w-auto w-full xls:order-[0] order-2">
            <div className=" mdm:mb-0 mb-3 xls:flex-none flex-1 py-3 xl:px-4 px-3 flex xxl:gap-5 gap-4 items-start bg-white border border-utilityGray200 rounded-xl shadow-sm xlm:min-w-[322px] lg:min-w-52 sm:min-w-44 min-w-full">
              <div className="p-3 bg-primary50 rounded-[10px] ">
                <Package className="xl:w-6 xl:h-6 w-4 h-4 text-primary700" />
              </div>
              <div>
                <p className="text-xs font-medium text-grayLight600 mb-1">
                  Total Weight of Shipment
                </p>
                <h3 className="text-grayLight900 xl:text-2xl lg:text-xl font-semibold">
                  {total.totalWeight} {weightMeasurement?.toLowerCase()}
                </h3>
              </div>
            </div>
            <div className="mdm:mb-0 mb-3 xls:flex-none flex-1 py-3 xl:px-4 px-3 flex xxl:gap-5 gap-4 items-start bg-white border border-utilityGray200 rounded-xl shadow-sm xlm:min-w-[322px] lg:min-w-52 sm:min-w-44 min-w-full">
              <div className="p-3 bg-success50 rounded-[10px]">
                <Cube03 className="xl:w-6 xl:h-6 w-4 h-4 text-fgSuccessPrimary" />
              </div>
              <div>
                <p className="text-xs font-medium text-grayLight600 mb-1">
                  Total Handling Units
                </p>
                <h3 className="text-grayLight900 xl:text-2xl lg:text-xl font-semibold">
                  {total.totalUnit}
                </h3>
              </div>
            </div>
            {/* Made this section hidden as its for testing purpose, enable when do full integration */}
            <div className="mdm:mb-0 mb-3 xls:flex-none flex-1 py-3 xl:px-4 px-3 flex xxl:gap-5 gap-4 items-start bg-white border border-utilityGray200 rounded-xl shadow-sm xlm:min-w-[322px] lg:min-w-52 sm:min-w-44 min-w-full hidden">
              <div className="p-3 bg-primary50 rounded-[10px] ">
                <Package className="xl:w-6 xl:h-6 w-4 h-4 text-primary700" />
              </div>
              <div>
                <p className="text-xs font-medium text-grayLight600 mb-1">
                  Linear footage
                </p>
                <h3 className="text-grayLight900 xl:text-2xl lg:text-xl font-semibold">
                  {calculateLinearFootage(formData)} Ft.
                </h3>
              </div>
            </div>
            <div className="xls:flex-none flex-1 py-2 xl:px-4 px-3  bg-white border border-utilityGray200 rounded-xl shadow-sm">
              <p className="text-xs font-medium text-grayLight600 lg:mb-2 mb-1">
                Measurement Units
              </p>
              <div className="switcher xls:!w-[233px] w-full switch-custom  bg-gray50 border border-utilityGray200 !flex !rounded-[10px] !p-1">
                <input
                  type="radio"
                  name="weightMeasurement"
                  id="lbs"
                  value={MEASUREMENT.WEIGHT1.value}
                  className="switcher__input switcher__input--yin"
                  checked={weightMeasurement === MEASUREMENT.WEIGHT1.value}
                  onChange={handleMeasurement(MEASUREMENT.LENGTH1.value)}
                />
                <label
                  htmlFor="lbs"
                  className={`switcher__label !text-[11px] !leading-[28px] ${
                    weightMeasurement === MEASUREMENT.WEIGHT1.value
                      ? 'text-primary700'
                      : 'text-gray500'
                  }`}
                >
                  Imperial (lbs)
                </label>
                <input
                  type="radio"
                  name="weightMeasurement"
                  id="kgs"
                  value={MEASUREMENT.WEIGHT2.value}
                  className="switcher__input switcher__input--yang"
                  checked={weightMeasurement === MEASUREMENT.WEIGHT2.value}
                  onChange={handleMeasurement(MEASUREMENT.LENGTH2.value)}
                />
                <label
                  htmlFor="kgs"
                  className={`switcher__label !text-[11px] !leading-[28px] ${
                    weightMeasurement === MEASUREMENT.WEIGHT2.value
                      ? 'text-primary700'
                      : 'text-gray500'
                  }`}
                >
                  Metric (kgs)
                </label>
                <span className="switcher__toggle !rounded-md"></span>
              </div>
            </div>
          </div>
        </div>

        <div className=" w-full xxl:p-5 p-4 flex flex-col bg-white rounded-xl border border-utilityGray200 ">
          <h3 className="font-semibold lg:text-lg text-base text-grayLight900 mb-4">
            Dimensions
          </h3>
          {isDimensionLoading ? (
            <div className="flex justify-center mt-4">
              <div className="skeleton bg-gray50 rounded-none w-full h-[32px]"></div>
            </div>
          ) : (
            <Items
              formData={formData}
              validForm={validForm}
              initFormData={initFormData}
              initValidForm={initValidForm}
              handlingUnitOptions={handlingUnitOptions}
              validateMeasurement={validateMeasurement}
              lengthMeasurement={lengthMeasurement}
              weightMeasurement={weightMeasurement}
              setFormData={setFormData}
              isDimensionLoading={isDimensionLoading}
              setValidForm={setValidForm}
              handleActionType={handleActionType}
            />
          )}
        </div>

        <div className=" w-full xxl:p-5 p-4 flex flex-col bg-white rounded-xl border border-utilityGray200 ">
          {serviceList.length ? (
            <form onSubmit={handleSubmit}>
              <h3 className="font-semibold lg:text-lg text-base text-grayLight900 mb-4">
                Additional Services
              </h3>
              <AccordionCmp
                title={
                  <div className="flex mdm:flex-row md:flex-col sm:flex-row flex-col mdm:justify-between md:justify-normal sm:justify-between gap-2 flex-grow mdm:items-center md:items-stretch sm:items-center">
                    <div>
                      <h3 className="text-grayLight900 font-semibold text-base">
                        Pickup
                      </h3>
                      <span className="text-xs text-grayLight600">
                        Please select below listed pickup type as per your
                        requirement
                      </span>
                    </div>
                  </div>
                }
                className="shadow-sm mb-4"
                showContentOnActive={
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="mr-3 xls:mr-3"
                  >
                    <InputText
                      inputName="searchCarrier"
                      placeholder="Search"
                      className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal ignore-blur search-input"
                      icon={
                        <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                      }
                      value={serviceSearch.pickup}
                      inputType="text"
                      isClearable
                      parentClassName="lg:min-w-80 xls:mr-12"
                      onChangeFunc={(e: any) =>
                        setServiceSearch((old: any) => ({
                          ...old,
                          pickup: e.target.value,
                        }))
                      }
                    />
                  </div>
                }
                contentClassName="!p-0"
                contentParentClass="!border-0"
              >
                {serviceTypes.PICKUP?.length > 0 ? (
                  getList(serviceTypes.PICKUP)
                ) : (
                  <NotFoundUI
                    title="No services found"
                    desc={`Your search “${serviceSearch.pickup}” did not match any services. Please try again.`}
                    containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                  />
                )}
              </AccordionCmp>

              <AccordionCmp
                title={
                  <div className="flex mdm:flex-row md:flex-col sm:flex-row flex-col mdm:justify-between md:justify-normal sm:justify-between gap-2 flex-grow mdm:items-center md:items-stretch sm:items-center">
                    <div>
                      <h3 className="text-grayLight900 font-semibold text-base">
                        Delivery
                      </h3>
                      <span className="text-xs text-grayLight600">
                        Please select below listed delivery type as per your
                        requirement
                      </span>
                    </div>
                  </div>
                }
                showContentOnActive={
                  <div onClick={(e) => e.stopPropagation()} className="mr-3">
                    <InputText
                      inputName="searchCarrier"
                      placeholder="Search"
                      className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal ignore-blur search-input"
                      icon={
                        <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                      }
                      value={serviceSearch.delivery}
                      inputType="text"
                      isClearable
                      // onFocus={() => set}
                      parentClassName="lg:min-w-80 xls:mr-12"
                      onChangeFunc={(e: any) =>
                        setServiceSearch((old: any) => ({
                          ...old,
                          delivery: e.target.value,
                        }))
                      }
                    />
                  </div>
                }
                className="shadow-sm mb-4"
                contentClassName="!p-0"
                contentParentClass="!border-0"
              >
                {serviceTypes.DELIVERY?.length ? (
                  getList(serviceTypes.DELIVERY)
                ) : (
                  <NotFoundUI
                    title="No services found"
                    desc={`Your search “${serviceSearch.delivery}” did not match any services. Please try again.`}
                    containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                  />
                )}
              </AccordionCmp>
              <AccordionCmp
                title={
                  <div className="flex mdm:flex-row md:flex-col sm:flex-row flex-col mdm:justify-between md:justify-normal sm:justify-between gap-2 flex-grow mdm:items-center md:items-stretch sm:items-center">
                    <div>
                      <h3 className="text-grayLight900 font-semibold text-base">
                        Other
                      </h3>
                      <span className="text-xs text-grayLight600">
                        Please select below listed delivery type as per your
                        requirement
                      </span>
                    </div>
                  </div>
                }
                showContentOnActive={
                  <div onClick={(e) => e.stopPropagation()} className="mr-3">
                    <InputText
                      inputName="searchCarrier"
                      placeholder="Search"
                      className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal ignore-blur search-input"
                      icon={
                        <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                      }
                      value={serviceSearch.other}
                      isClearable
                      inputType="text"
                      // onFocus={() => set}
                      parentClassName="lg:min-w-80 xls:mr-12"
                      onChangeFunc={(e: any) =>
                        setServiceSearch((old: any) => ({
                          ...old,
                          other: e.target.value,
                        }))
                      }
                    />
                  </div>
                }
                className="shadow-sm"
                contentClassName="!p-0"
                contentParentClass="!border-0"
              >
                {serviceTypes.OTHER?.length ? (
                  getList(serviceTypes.OTHER)
                ) : (
                  <NotFoundUI
                    title="No services found"
                    desc={`Your search “${serviceSearch.other}” did not match any services. Please try again.`}
                    containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                  />
                )}
              </AccordionCmp>
            </form>
          ) : (
            <div className="flex justify-center mt-4">
              {isLoading ? (
                <div className="skeleton bg-gray50 rounded-none w-full h-[32px]"></div>
              ) : (
                false
              )}
            </div>
          )}

          {action.tailgate && (
            <ConfirmModal
              title="Tailgate Over Dimensions"
              description={`Tailgate is not offered for shipments that are over ${FOOT} feet in length and/or have a total weight ${
                weightMeasurement === MEASUREMENT.WEIGHT1.value
                  ? `${LBS.MAX_WEIGHT}lbs`
                  : `${KGS.MAX_WEIGHT}kgs`
              }. This may result in some carriers not quoting this load. Are you sure you want to proceed`}
              button1="Yes, I am sure"
              button2="Modify Selection"
              handleClose={handleModalClose}
            />
          )}
          {action.unusual && (
            <ConfirmModal
              title="Unusual selection"
              description="Based on the options you selected, a lot of carriers might not give a direct quote for this load. Are you sure you want to proceed?"
              button1="Yes, I am sure"
              button2="Modify Selection"
              handleClose={handleModalClose}
            />
          )}

          {isInbondMoreModalOpen && (
            <CommonModal
              title={'In-Bond Details'}
              titleDesc={'Please fill In-Bond Details'}
              handleClose={() => {
                let option = document.querySelectorAll<HTMLInputElement>(
                  'input[type="checkbox"][name="in-bond"]'
                );

                if (option?.length) {
                  option[0].click();
                }
                setServicesValidForm(initServiceValidForm);
                setIsInbondMore(false);
                setIsInbondMoreModalOpen(false);
              }}
              headerIcon={<PackagePlus />}
              size={'max-w-[688px]'}
              secondaryBtnText="Cancel"
              primaryBtnText="Save Changes"
              primaryBtnOnClick={() => {
                if (isInBondFormValid()) {
                  setIsInbondMoreModalOpen(false);
                }
              }}
              secondaryBtnOnClick={() => {
                let option = document.querySelectorAll<HTMLInputElement>(
                  'input[type="checkbox"][name="in-bond"]'
                );

                if (option?.length) {
                  option[0].click();
                }
                setServicesValidForm(initServiceValidForm);
                setIsInbondMore(false);
                setIsInbondMoreModalOpen(false);
              }}
            >
              <div className="p-5 ">
                <div className="flex sm:flex-row flex-col flex-wrap gap-5 py-[12.5px] mb-5">
                  {inbondTypeList.map((val: any, i) => (
                    <div className="flex gap-2" key={`inbond_${i}`}>
                      <Radio
                        id={`inbond_${i}`}
                        onChangeFunc={handleChangeRadio}
                        inputName={'inbondName'}
                        value={val.value}
                        checked={inbondType === val.value}
                      />

                      <label
                        htmlFor={`inbond_${i}`}
                        className="text-textSecondary text-xs cursor-pointer font-medium"
                      >
                        {val.name}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="w-full mb-4">
                  <InputText
                    inputName="inbondName"
                    required={true}
                    placeholder=""
                    className={`form_control ${
                      !validServicesForm.inbondName
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    label="Warehouse or Carrier who will cancel US Bond"
                    labelClassName="block mb-1.5"
                    value={inbondName}
                    icon={
                      !validServicesForm.inbondName ? (
                        <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                      ) : null
                    }
                    // errorText={!validServicesForm.inbondName ? 'This field is required' : null}
                    onChangeFunc={(e) => {
                      setInbondName(e.target.value);
                      setServicesValidForm((old) => ({
                        ...old,
                        [e.target.name]: true,
                      }));
                    }}
                  />
                </div>
                <div className="w-full mb-5">
                  <InputText
                    inputName="inbondAddress"
                    placeholder="123 Address"
                    required={true}
                    className={`form_control ${
                      !validServicesForm.inbondAddress
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    label="Address"
                    labelClassName="block mb-1.5"
                    icon={
                      !validServicesForm.inbondName ? (
                        <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                      ) : null
                    }
                    value={inbondAddress}
                    // errorText={!validServicesForm.inbondAddress ? 'This field is required' : null}
                    onChangeFunc={(e) => {
                      setInbondAddress(e.target.value);
                      setServicesValidForm((old) => ({
                        ...old,
                        [e.target.name]: true,
                      }));
                    }}
                  />
                </div>
                <div className="w-full mb-4">
                  {getName(inbondContactMethod) !== 'Phone' ? (
                    <InputText
                      inputName="inbondContactValue"
                      // inputType={getName(inbondContactMethod) === "Phone" ? "number" : "text"}
                      placeholder=""
                      required={true}
                      className={`form_control ${
                        !validServicesForm.inbondContactValue
                          ? 'border border-red-500 border-solid'
                          : ''
                      }`}
                      icon={
                        !validServicesForm.inbondContactValue ? (
                          <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                        ) : null
                      }
                      label={getName(inbondContactMethod)}
                      labelClassName="block mb-1.5"
                      value={inbondContactValue}
                      errorText={
                        !validServicesForm.inbondContactValue
                          ? 'This field is required'
                          : !validServicesForm.isEmailValid &&
                            getName(inbondContactMethod) === 'Email'
                          ? 'enter valid email'
                          : ''
                      }
                      onChangeFunc={(e) => {
                        setInbondContactValue(e.target.value);
                        setServicesValidForm((old) => ({
                          ...old,
                          [e.target.name]: true,
                        }));
                      }}
                    />
                  ) : (
                    <PhoneInput
                      onNumberChange={handleNumberChange}
                      name="phone"
                      errors={
                        !validServicesForm.inbondContactValue
                          ? 'Phone number is required'
                          : !validServicesForm.isPhoneValid &&
                            getName(inbondContactMethod) === 'Phone'
                          ? 'Invalid Number'
                          : null
                      }
                      value={inbondContactValue || ''}
                    />
                  )}
                </div>
                <div className="flex sm:flex-row flex-col flex-wrap gap-5 py-[12.5px] mb-5">
                  {inbondContactMethodList.map((val: any, i) => (
                    <div className="flex gap-2" key={`inbond_contact_${i}`}>
                      <Radio
                        id={`inbond_contact_${i}`}
                        onChangeFunc={handleChangeRadio}
                        inputName={'contactMethod'}
                        value={val.value}
                        checked={inbondContactMethod === val.value}
                      />
                      <label
                        htmlFor={`inbond_contact_${i}`}
                        className="text-textSecondary text-xs cursor-pointer font-medium uppercase"
                      >
                        {val.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </CommonModal>
          )}
          {isDangerousMoreModalOpen && (
            <CommonModal
              title={'Dangerous Goods'}
              titleDesc={'Please provide dangerous goods details'}
              handleClose={() => {
                let option = document.querySelectorAll<HTMLInputElement>(
                  'input[type="checkbox"][name="dangerous_goods"]'
                );

                if (option?.length) {
                  option[0].click();
                }
                setServicesValidForm(initServiceValidForm);
                setIsDangerousFormSubmitted(false);
                setUnitedNationsNumber('');
                setGoodsClass('');
                setDescription('');
                setEmergencyContactName('');
                setEmergencyContactNumber('');
                setEmergencyContactExt('');
                setIsDangerousMore(false);
                setIsDangerousMoreModalOpen(false);
              }}
              headerIcon={<AlertHexagon />}
              size={'max-w-[688px]'}
              secondaryBtnText="Cancel"
              primaryBtnText="Save Changes"
              primaryBtnOnClick={() => {
                setIsDangerousFormSubmitted(true);

                if (isDangerousGoodsFormValid()) {
                  setIsDangerousMoreModalOpen(false);
                }
              }}
              secondaryBtnOnClick={() => {
                let option = document.querySelectorAll<HTMLInputElement>(
                  'input[type="checkbox"][name="dangerous_goods"]'
                );

                if (option?.length) {
                  option[0].click();
                }
                setServicesValidForm(initServiceValidForm);
                setIsDangerousFormSubmitted(false);
                setUnitedNationsNumber('');
                setGoodsClass('');
                setDescription('');
                setEmergencyContactName('');
                setEmergencyContactNumber('');
                setEmergencyContactExt('');
                setIsDangerousMore(false);
                setIsDangerousMoreModalOpen(false);
              }}
            >
              <div className="p-5 flex flex-col md:gap-5 gap-3">
                <p className="text-grayLight600 font-normal text-xs ">
                  Please provide the Dangerous Goods details, as these details
                  will show up on the BOL. Failure to enter this data may result
                  in delayed pickups.
                </p>
                <div className="flex sm:flex-row flex-col flex-wrap xxl:gap-5 xl:gap-4 gap-3 py-[12.5px] ">
                  {dangerTypeList.map((val: any, i) => (
                    <div className="flex gap-2" key={`danger_${i}`}>
                      <Radio
                        id={`danger_${i}`}
                        onChangeFunc={handleChangeRadio}
                        inputName={'dangerType'}
                        value={val.value}
                        checked={dangerType === val.value}
                      />
                      <label
                        htmlFor={`danger_${i}`}
                        className="text-textSecondary text-xs font-medium uppercase cursor-pointer"
                      >
                        {val.name}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="grid sm:grid-cols-3 xxl:gap-5 xl:gap-4 gap-3 ">
                  <InputText
                    inputName="unitedNationsNumber"
                    placeholder="UN0000"
                    className={`form_control ${
                      !validServicesForm.unitedNationsNumber
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    required={true}
                    label="UN #"
                    labelClassName="block mb-1.5"
                    value={unitedNationsNumber}
                    icon={
                      !validServicesForm.inbondName ? (
                        <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                      ) : null
                    }
                    // errorText={!validServicesForm.unitedNationsNumber ? 'This field is required' : null}
                    onChangeFunc={(e) => {
                      setUnitedNationsNumber(e.target.value);
                      setServicesValidForm((old) => ({
                        ...old,
                        [e.target.name]: true,
                      }));
                    }}
                  />

                  <div className="content">
                    <SelectBox
                      name="packagingGroup"
                      required
                      label="Packaging Group"
                      labelClassName="form_label block mb-1.5"
                      id="packagingGroup"
                      className="form_control"
                      options={packagingGroupOptions}
                      onChangeFunc={(event: any) =>
                        setPackagingGroup(event.value)
                      }
                      value={packagingGroupOptions.find(
                        (val) => val.value === packagingGroup
                      )}
                    />
                  </div>

                  <InputText
                    inputType="number"
                    inputName="goodsClass"
                    placeholder=""
                    className={`form_control ${
                      !validServicesForm.goodsClass
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    label="Class"
                    required={true}
                    labelClassName="block mb-1.5"
                    value={goodsClass}
                    icon={
                      !validServicesForm.goodsClass ? (
                        <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                      ) : null
                    }
                    shouldFormatNumber={true}
                    // errorText={!validServicesForm.goodsClass ? 'This field is required' : null}
                    onChangeFunc={(e) => {
                      setGoodsClass(e.target.value);
                      setServicesValidForm((old) => ({
                        ...old,
                        [e.target.name]: true,
                      }));
                    }}
                  />
                </div>
                <div className="w-full">
                  <InputText
                    inputName="description"
                    placeholder=""
                    className={`form_control ${
                      !validServicesForm.description
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    label="Technical Name or Description"
                    required={true}
                    labelClassName="block mb-1.5"
                    value={description}
                    icon={
                      !validServicesForm.description ? (
                        <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                      ) : null
                    }
                    // errorText={!validServicesForm.description ? 'This field is required' : null}
                    onChangeFunc={(e) => {
                      setDescription(e.target.value);
                      setServicesValidForm((old) => ({
                        ...old,
                        [e.target.name]: true,
                      }));
                    }}
                  />
                </div>
                <div className="w-full ">
                  <InputText
                    inputName="emergencyContactName"
                    placeholder=""
                    className={`form_control ${
                      !validServicesForm.emergencyContactName
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    label="24-hr Emergency Contact Name"
                    required={true}
                    labelClassName="block mb-1.5"
                    icon={
                      !validServicesForm.emergencyContactName ? (
                        <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                      ) : null
                    }
                    value={emergencyContactName}
                    // errorText={!validServicesForm.emergencyContactName ? 'This field is required' : null}
                    onChangeFunc={(e) => {
                      setEmergencyContactName(e.target.value);
                      setServicesValidForm((old) => ({
                        ...old,
                        [e.target.name]: true,
                      }));
                    }}
                  />
                </div>
                <div className="grid lg:grid-cols-2 grid-col-1 xxl:gap-5 xl:gap-4 gap-3">
                  <div className="md:flex flex-col">
                    <label className="form_label ">Phone number*</label>
                    <PhoneInput
                      onNumberChange={handleDangerPhoneChange}
                      name="phone"
                      errors={
                        isDangerousFormSubmitted &&
                        !validServicesForm.emergencyContactNumber
                          ? 'Phone number is required'
                          : isDangerousFormSubmitted &&
                            !validServicesForm.isEmergencyPhoneValid
                          ? 'Invalid Number'
                          : null
                        // !validServicesForm.isEmergencyPhoneValid
                        //   ? 'Invalid Number'
                        //   : !validServicesForm.emergencyContactNumber
                        //   ? 'Phone number is required'
                        //   : null
                      }
                      value={emergencyContactNumber || ''}
                    />
                    {/* <InputText
                                        inputType="number"
                                        inputName="emergencyContactNumber"
                                        placeholder=""
                                        className={`form_control ${!validServicesForm.emergencyContactNumber ? "border border-red-500 border-solid" : ""}`}
                                        icon={
                                            !validServicesForm.emergencyContactNumber ? (
                                                <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                                            ) : null
                                        }
                                        label="Contact Phone Number"
                                        required={true}
                                        labelClassName="block mb-1.5"
                                        value={emergencyContactNumber}
                                        // errorText={!validServicesForm.emergencyContactNumber ? 'This field is required' : null}
                                        onChangeFunc={(e) => {
                                            setEmergencyContactNumber(e.target.value);
                                            setServicesValidForm((old) => ({ ...old, [e.target.name]: true }));
                                        }}
                                    /> */}
                  </div>
                  <InputText
                    inputName="emergencyContactExt"
                    placeholder=""
                    className="form_control"
                    label="Ext"
                    labelClassName="block mb-1.5"
                    value={emergencyContactExt}
                    onChangeFunc={(e) => setEmergencyContactExt(e.target.value)}
                  />
                </div>
              </div>
            </CommonModal>
          )}
          {isExcessValuationModalOpen && (
            <CommonModal
              title={'Excess Valuation'}
              titleDesc={'Please provide Declared Value'}
              handleClose={() => {
                let option = document.querySelectorAll<HTMLInputElement>(
                  'input[type="checkbox"][name="excess_valuation"]'
                );

                if (option?.length) {
                  option[0].click();
                }
                setServicesValidForm(initServiceValidForm);
                setIsDeclaredValueMore(false);
                setIsExcessValuationModalOpen(false);
              }}
              headerIcon={<AlertHexagon />}
              size={'max-w-[688px]'}
              secondaryBtnText="Cancel"
              primaryBtnText="Save Changes"
              primaryBtnOnClick={() => {
                if (validServicesForm && isExcessValuation()) {
                  setIsExcessValuationModalOpen(false);
                }
              }}
              secondaryBtnOnClick={() => {
                let option = document.querySelectorAll<HTMLInputElement>(
                  'input[type="checkbox"][name="excess_valuation"]'
                );

                if (option?.length) {
                  option[0].click();
                }
                setServicesValidForm(initServiceValidForm);
                setIsExcessValuationModalOpen(false);
              }}
            >
              <div className="flex flex-col justify-center py-3">
                <div className="xxl:w-full xl:w-2/5 w-full px-4">
                  <p className="text-dark m-0 p-0 font-medium pt-2 pb-2 text-xs">
                    Declared Value
                  </p>
                  <div className="flex items-center">
                    <div className="mb-4 w-full">
                      <InputText
                        inputType="number"
                        inputName="declaredValue"
                        required={true}
                        placeholder=""
                        className={`form_control ${
                          !validServicesForm.declaredValue
                            ? 'border border-red-500 border-solid'
                            : ''
                        }`}
                        icon={
                          !validServicesForm.declaredValue ? (
                            <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                          ) : null
                        }
                        value={declaredValue}
                        shouldFormatNumber={true}
                        onChangeFunc={(e) => {
                          setDeclaredValue(e.target.value);
                          setServicesValidForm((old) => ({
                            ...old,
                            [e.target.name]: true,
                          }));
                        }}
                        onFocus={() => {
                          if (declaredValue === 0) {
                            setDeclaredValue('');
                          }
                        }}
                        onBlur={() => {
                          if (declaredValue === '') {
                            setDeclaredValue(0);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full py-2 xl:px-4 px-3">
                  <p className="text-dark m-0 p-0 font-medium pb-2 text-xs">
                    Currency
                  </p>
                  <TabButton
                    tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 "
                    parentClassName="w-full currency-tab quoting-currency"
                    activeClassName="text-grayLight900 bg-utilityGray100"
                    className=""
                    isTooltip={true}
                    tabArray={filterArr}
                    handleOnClick={(e: any) => {
                      setDeclaredValueUnit(e.target.dataset.value);
                    }}
                    isActive={declaredValueUnit}
                  />
                </div>
              </div>
            </CommonModal>
          )}
          {isAccessTypeModalOpen && (
            <CommonModal
              title={'Limited access delivery'}
              titleDesc={'Please select limited access delivery type'}
              modalClassName="flex flex-col"
              handleClose={() => {
                setLimitedAccessSearch('');
                let option = document.querySelectorAll<HTMLInputElement>(
                  'input[type="checkbox"][name="limited_access_delivery"]'
                );

                if (option?.length) {
                  option[0].click();
                }
                setServicesValidForm(initServiceValidForm);
                setIsAccessTypeModalOpen(false);
              }}
              headerIcon={<PackagePlus />}
              size={'max-w-[688px]'}
              secondaryBtnText="Cancel"
              primaryBtnText="Save Changes"
              primaryBtnOnClick={() => {
                if (isAccessTypeFormValid()) {
                  setIsAccessTypeModalOpen(false);
                }
              }}
              secondaryBtnOnClick={() => {
                let option = document.querySelectorAll<HTMLInputElement>(
                  'input[type="checkbox"][name="limited_access_delivery"]'
                );

                if (option?.length) {
                  option[0].click();
                }
                setServicesValidForm(initServiceValidForm);
                setIsAccessTypeModalOpen(false);
              }}
            >
              <div className="p-5 flex-1 flex flex-col overflow-y-auto">
                <InputText
                  inputName="searchCarrier"
                  placeholder="Search"
                  className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
                  icon={
                    <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                  }
                  value={limitedAccessSearch}
                  isClearable
                  onChangeFunc={(e) => setLimitedAccessSearch(e.target.value)}
                  inputType="text"
                  parentClassName="w-full mb-4"
                />

                <div className="rounded-lg border border-borderPrimary px-3 py-1 flex-1 overflow-y-auto custom-scrollbar-v2">
                  {limitedAccessTypeList.map((val: any, i: any) => (
                    <div
                      className="flex sms:p-4 px-2 py-3 border-b border-utilityGray200 last:border-b-0"
                      key={`limited_${i}`}
                    >
                      <div className="flex items-center">
                        <div
                          className="flex items-start gap-2"
                          key={`service_${val.value}`}
                        >
                          <Radio
                            id={`service_${val.value}`}
                            onChangeFunc={handleChangeRadio}
                            inputName={'limitedAccessType'}
                            value={val.value}
                            checked={limitedAccessType === val.value}
                          />
                          <label
                            htmlFor={`service_${val.value}`}
                            className="text-xs text-textSecondary uppercase cursor-pointer font-medium"
                          >
                            {val.name}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                  {limitedAccessType === 'other' && (
                    <div className="mt-5 w-full">
                      <InputText
                        inputName="limitedAccessOther"
                        className={`form_control ${
                          !validServicesForm.limitedAccessOther
                            ? 'border border-red-500 border-solid'
                            : ''
                        }`}
                        label=""
                        value={limitedAccessOther}
                        errorText={
                          !validServicesForm.limitedAccessOther
                            ? 'This field is required'
                            : null
                        }
                        onChangeFunc={(e) => {
                          setLimitedAccessOther(e.target.value);
                          setServicesValidForm((old) => ({
                            ...old,
                            [e.target.name]: true,
                          }));
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </CommonModal>
          )}
        </div>
      </PageSectionLayout>
    </>
  );
};

export default Dimensions;
