import { yupResolver } from '@hookform/resolvers/yup';
import {
  UserPlus01,
  ImagePlus,
  UserEdit,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import CropperModal from 'src/components/CropperModal/CropperModal';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { addNewContact, updateContact } from 'src/services/CallCenterService';
import { listCarrier } from 'src/services/CarrierService';
import { CustomersAllList } from 'src/services/CustomerService';
import {
  checkFileTypeValidation,
  convertBase64ToFile,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

interface IAddNewContact {
  action: any;
  setAction: any;
  contactData?: any;
  handleClose: any;
}

const carrierParams: any = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 100,
  type: 'all',
  provider: '',
};

const accountType = [
  { label: 'Carrier', value: 'carrier' },
  { label: 'Customer', value: 'customer' },
];

const ContactModal = ({ action, handleClose, contactData }: IAddNewContact) => {
  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  const [preview, setPreview] = useState<string | null>(
    contactData?.imageUrl + contactData?.image
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [upload, setUpload] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState<any>([]);
  const [carrierList, setCarrierList] = useState<any>([]);
  const [accountOptions, setAccountOptions] = useState<any[]>([]);

  const validationSchema = yup.object().shape({
    image: yup.mixed(),

    name: yup.string().required().min(2),

    contactNumber: yup.string().required(),
    extension: yup.string(),

    accountType: yup.string().required(),
    account: yup.string().required(),

    title: yup.string().required().min(2),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: contactData?.name || '',
      contactNumber: contactData?.number || '',
      extension: contactData?.extension || '',
      accountType: contactData?.contactType || '',
      account:
        contactData?.contactType === 'carrier'
          ? contactData?.carrierId
          : contactData?.customerId || '',
      title: contactData?.role || '',
    },
  });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setError('image' as 'image', {});
    setPreview(null);
    const file = acceptedFiles[0];

    setValue('image', file, { shouldValidate: true });
    const { result, message } = checkFileTypeValidation(
      acceptedFiles,
      MAX_FILE_SIZE
    );

    if (result) {
      setIsLoading(false);
      const reader = new FileReader();

      reader.onloadstart = () => {
        setPreview('');
        setUpload(false);
      };

      reader.onloadend = () => {
        setPreview(reader.result as any);
        setUpload(true);
      };
      reader.readAsDataURL(file);
    } else {
      setError('image' as 'image', {
        type: 'manual',
        message: message,
      });
    }
  }, []);

  const imageUpload = (data: any) => {
    const appLogoImage = data;
    const fileInfo = convertBase64ToFile(appLogoImage);
    if (fileInfo) setValue('image', fileInfo?.convertedFile);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/svg+xml': ['.svg'],
    },
  });

  const onSubmit = async (formData: any) => {
    setIsLoading(true);

    const appendFormData: any = new FormData();

    appendFormData.append('name', formData.name);
    appendFormData.append('number', formData.contactNumber);
    appendFormData.append('role', formData.title);
    appendFormData.append('contactType', formData.accountType);
    appendFormData.append('extension', formData.extension);

    if (formData.accountType === 'carrier') {
      appendFormData.append('carrierId', formData.account);
    } else if (formData.accountType === 'customer') {
      appendFormData.append('customerId', formData.account);
    }

    if (formData.image) {
      appendFormData.append('image', formData.image);
    }

    if (contactData?.id) {
      updateContact(contactData?.id, appendFormData)
        .then((response: any) => {
          handleClose(response.data);
          handleClose(true);
        })
        .catch(() => WalToast.error('Contact not updated'))
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      addNewContact(appendFormData)
        .then((response: any) => {
          handleClose(response.data);
          handleClose(action);
          handleClose(true);
        })
        .catch(() => WalToast.error('Contact not added'))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const getCustomerData = () => {
    setCustomerList([]);
    setIsLoading(true);

    CustomersAllList()
      .then((response: any) => {
        if (response.data) {
          setCustomerList(response.data);
        }
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }

        setIsLoading(false);
      });
  };

  const getCarrierData = (signal?: any) => {
    setCarrierList([]);
    setIsLoading(true);

    listCarrier(carrierParams, signal)
      .then((response: any) => {
        if (response.data) {
          setCarrierList(response.data);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }

        setIsLoading(false);
      });
  };

  const handleAccountTypeChange = (accountT: string) => {
    if (accountT === 'carrier') {
      setAccountOptions(
        carrierList.map((carrier: any) => ({
          label: carrier.name,
          value: carrier.id,
        }))
      );
    } else if (accountT === 'customer') {
      setAccountOptions(
        customerList.map((customer: any) => ({
          label: customer.name,
          value: customer.id,
        }))
      );
    } else {
      setAccountOptions([]);
    }
  };

  useEffect(() => {
    if (contactData?.contactType) {
      handleAccountTypeChange(contactData?.contactType);
    }
  }, [contactData, carrierList, customerList]);

  useEffect(() => {
    getCustomerData();

    const carrierController = new AbortController();
    const carrierSignal = carrierController.signal;
    getCarrierData(carrierSignal);

    return () => {
      carrierController.abort();
    };
  }, []);

  useEffect(() => {
    if (contactData) {
      setPreview(contactData?.imageUrl + contactData?.image);
      setValue('name', contactData?.name || '');
      setValue('contactNumber', contactData?.number || '');
      setValue('extension', contactData?.extension || '');
      setValue('accountType', contactData?.contactType || '');

      if (contactData?.contactType === 'carrier') {
        setValue('account', contactData?.carrierId);
      } else if (contactData?.contactType === 'customer') {
        setValue('account', contactData?.customerId);
      }

      setValue('title', contactData?.role || '');
    }
  }, [contactData]);

  return (
    <>
      <CommonModal
        title={`${
          action.mode === 'addNewContact' ? 'Add Contact' : 'Edit Contact'
        }`}
        titleDesc={`${
          action.mode === 'addNewContact'
            ? 'Add a new contact to your list.'
            : 'Update contact details easily.'
        }`}
        handleClose={() => handleClose(false)}
        headerIcon={
          action.mode === 'addNewContact' ? <UserPlus01 /> : <UserEdit />
        }
        size={'max-w-[600px]'}
        secondaryBtnText="Cancel"
        primaryBtnText="Save Changes"
        primaryBtnLoading={false}
        primaryBtnDisabled={isLoading}
        primaryBtnOnClick={handleSubmit(onSubmit)}
        secondaryBtnOnClick={() => handleClose(false)}
      >
        <div className="flex flex-col gap-5 pt-5 px-6 pb-6">
          <div className="w-full md:flex gap-2">
            <p className="text-xs font-medium text-textSecondary mb-2 md:mb-0 w-1/4">
              Image Update
              {/* <span className="text-red-600 leading-4 text-xs">*</span> */}
            </p>

            <div className="position-relative flex-1">
              <div className="">
                <div {...getRootProps()}>
                  <label
                    className="cursor-pointer flex items-center gap-2"
                    htmlFor="fileSelect"
                  >
                    {preview ? (
                      <img
                        className="rounded-lg border-utilityBlack border-[0.75px] border-opacity-[0.08] w-14 h-14 object-cover flex-none"
                        src={preview}
                        alt=""
                        title=""
                        onError={onError}
                      />
                    ) : watch('name') ? (
                      <label className="rounded-lg bg-utilityGray100 w-14 h-14 border-utilityBlack border-[0.75px] border-opacity-[0.08] text-primary text-xl font-semibold uppercase flex items-center justify-center flex-none">
                        {getShortName(`${watch('name')}`)}
                      </label>
                    ) : (
                      <label className="w-14 h-14 border border-black/[0.08] rounded-lg object-cover flex justify-center items-center bg-utilityGray100 text-gray500 flex-none">
                        <ImagePlus />
                      </label>
                    )}
                    <div
                      className={`relative z-[1] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl border border-utilityGray200 py-3.5 px-5 text-center bg-white ${
                        errors.image
                          ? 'border-red-400'
                          : 'border-utilityGray200'
                      }`}
                    >
                      <div className="justify-center text-xs items-center gap-1.5 flex">
                        <div className="text-primary font-semibold leading-tight">
                          Click to upload
                        </div>
                        <div className="text-grayLight600 text-xs font-normal leading-tight">
                          or drag and drop
                        </div>
                      </div>
                      <div className="text-center text-grayLight600 text-xs font-normal leading-[18px]">
                        SVG, PNG or JPG
                      </div>
                    </div>
                  </label>
                  <input
                    accept=".svg, .png, .jpg, .jpeg"
                    {...getInputProps()}
                  />
                  <div className="mt-1.5">
                    {errors.image && (
                      <ErrorMsg errorText={errors.image.message} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-2">
            <div className="w-1/4">
              <label className="text-xs font-medium text-textSecondary">
                Name
                <span className="text-red-600 ms-[2px] leading-4 text-xs">
                  *
                </span>
              </label>
            </div>
            <div className="w-3/4">
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputText
                    inputName="name"
                    placeholder="Enter name"
                    value={value}
                    required={true}
                    onChangeFunc={onChange}
                    errorText={errors.name ? true : false}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex gap-2">
            <div className="w-1/4">
              <label className="text-xs font-medium text-textSecondary">
                Phone Number
                <span className="text-red-600 ms-[2px] leading-4 text-xs">
                  *
                </span>
              </label>
            </div>
            <div className="w-3/4">
              <Controller
                name="contactNumber"
                control={control}
                render={() => (
                  <PhoneInput
                    name="contactNumber"
                    value={`${watch('contactNumber')}`}
                    onNumberChange={(
                      country: any,
                      phone: any,
                      code: any,
                      isValid: boolean
                    ) => {
                      const newValue = `+${code}${phone}`;
                      setValue('contactNumber', newValue);

                      if (isValid || phone === '') {
                        clearErrors('contactNumber');
                      } else {
                        setError('contactNumber', {
                          type: 'manual',
                          message: 'Contact number is invalid.',
                        });
                      }
                    }}
                    errors={errors.contactNumber ? true : false}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex gap-2">
            <div className="w-1/4">
              <label className="text-xs font-medium text-textSecondary">
                Extension
              </label>
            </div>
            <div className="w-3/4">
              <Controller
                name="extension"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputText
                    inputName="extension"
                    placeholder="Enter Extension"
                    value={value}
                    required={true}
                    onChangeFunc={onChange}
                    errorText={errors.extension ? true : false}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex gap-2">
            <div className="w-1/4">
              <label className="text-xs font-medium text-textSecondary">
                Account type
                <span className="text-red-600 ms-[2px] leading-4 text-xs">
                  *
                </span>
              </label>
            </div>
            <div className="w-3/4">
              <Controller
                name="accountType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectBox
                    name="accountType"
                    id="accountType"
                    className="form_control"
                    size="sm"
                    placeholder="Select account type"
                    isClearable={true}
                    options={accountType}
                    onChangeFunc={(e: any) => {
                      onChange(e?.value ?? '');
                      handleAccountTypeChange(e?.value ?? '');

                      if (!e?.value) {
                        setValue('account', '');
                      }
                    }}
                    value={accountType.filter(
                      (val: any) => value == val?.value
                    )}
                    errorText={errors.accountType ? true : false}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex gap-2">
            <div className="w-1/4">
              <label className="text-xs font-medium text-textSecondary">
                Account
                <span className="text-red-600 ms-[2px] leading-4 text-xs">
                  *
                </span>
              </label>
            </div>
            <div className="w-3/4">
              <Controller
                name="account"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectBox
                    name="account"
                    id="account"
                    className="form_control"
                    size="sm"
                    placeholder="Select account"
                    isSearchable={true}
                    isClearable={true}
                    options={accountOptions}
                    onChangeFunc={(e: any) => onChange(e?.value ?? '')}
                    value={
                      accountOptions.find((val: any) => value == val?.value) ||
                      ''
                    }
                    errorText={errors.account ? true : false}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex gap-2">
            <div className="w-1/4">
              <label className="text-xs font-medium text-textSecondary">
                Title
                <span className="text-red-600 ms-[2px] leading-4 text-xs">
                  *
                </span>
              </label>
            </div>
            <div className="w-3/4">
              <Controller
                name="title"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputText
                    inputName="title"
                    placeholder="Enter title"
                    value={value}
                    required={true}
                    onChangeFunc={onChange}
                    errorText={errors.title ? true : false}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </CommonModal>
      {upload && (
        <CropperModal
          modalTitle="Edit Image"
          modalDesc="Edit Image as per your requirement"
          imageUpload={imageUpload}
          imageUrl={preview}
          setUpload={setUpload}
          setCropData={setPreview}
          defaultImage=""
          isCropType={false}
          defaultCropType="Square"
          setIsCloseCancel={() => {
            setValue('image', '');
            setPreview(contactData?.imageUrl + contactData?.image);
          }}
        />
      )}
    </>
  );
};

export default ContactModal;
