import {
  Calendar,
  CheckCircle,
  CurrencyDollar,
  InfoCircle,
  Plus,
} from '@untitled-ui/icons-react/build/cjs';
import { groupBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IFormData, initShipperAddress } from 'src/app/Orders/order.interface';
import ButtonCmp from 'src/components/ButtonCmp';
import CheckBox from 'src/components/CheckBox';
import ConfirmModal from 'src/components/ConfirmModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import DeleteModal from 'src/components/DeleteModal';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import Radio from 'src/components/Radio/Radio';
import SelectBox from 'src/components/SelectBox/SelectBox';
import Toggle from 'src/components/Toggle/Toggle';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  DATE_FORMAT,
  QUOTE_CLASSIFICATION,
  TIME_FORMAT,
  FOOT,
  HANDLING_UNIT_NUMBER,
  KGS,
  LBS,
  MEASUREMENT,
} from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { listHandlingUnit, listService } from 'src/services/CommonService';
import { CustomersAllList } from 'src/services/CustomerService';
import { getLoadLinkVehicleType } from 'src/services/LoadLinkService';
import { removeFromQuote } from 'src/services/QuoteEmailService';
import {
  createQuote,
  createUpdateDimension,
  createUpdateService,
  createUpdateSummary,
  getQuote,
  updateQuote,
} from 'src/services/QuoteService';
import {
  createShipper,
  deleteShippers,
  listShippers,
  updateShipper,
} from 'src/services/ShipperService';
import {
  getDateWithSuffixFormat,
  getFormattedPickupDate,
  getSelectBoxOptions,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import AiStarIcon from '../../../../assets/img/ai-gradient-star.svg';
import Address from '../../QuotingDashboard/AddressDetails/Address';
import {
  IMapAddress,
  initAction,
  initConsigneeAddress,
  initFormData,
  initValidForm,
} from '../../QuotingDashboard/AddressDetails/Address.interface';

import DangerousGoodsModal from './AdditionalServicesModals/DangerousGoodsModal';
import InBondDetailModal from './AdditionalServicesModals/InBondDetailModal';
import LimitedAccessDeliveryModal from './AdditionalServicesModals/LimitedAccessDeliveryModal';
import ConsigneeAddressSection from './ConsigneeAddressSection';
import CreateQuoteSectionLoading from './CreateQuoteSectionLoading';
import DimensionItems from './DimensionSection/DimensionItems';
import ShipperAddressSection from './ShipperAddressSection';

const serviceTypeArr = [
  {
    label: 'LTL',
    value: QUOTE_CLASSIFICATION.LTL,
  },
  {
    label: 'FTL',
    value: QUOTE_CLASSIFICATION.FTL,
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const currencyTypeOptions = [
  {
    label: 'USD',
    value: 'usd',
  },
  {
    label: 'CAD',
    value: 'cad',
  },
];

const urgencyTypeArr = [
  { label: 'Live', value: 'live' },
  { label: 'QFR', value: 'rfq' },
];

const initQuoteFormData = {
  ...initFormData,
  urgency: 'live',
  classification: undefined,
  linearFootage: undefined,
  totalWeight: undefined,
  isInsureLoad: false,
  insuranceCommercialValue: undefined,
  insuranceCurrencyType: 'cad',
};

const initValidQuoteForm = {
  ...initValidForm,
  insuranceCommercialValue: true,
  insuranceCurrencyType: true,
};

const areStringArraysEqual = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = arr1?.slice()?.sort() || [];
  const sortedArr2 = arr2?.slice()?.sort() || [];

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

const cleanObject = (obj: any) =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) =>
        !!key && value !== undefined && value !== null && value !== ''
    )
  );

const getAddressDetails = async (searchTerm: string): Promise<any> =>
  new Promise((resolve, reject) => {
    if (!window.google || !window.google.maps.places) {
      reject(new Error('Google Maps Places API is not loaded.'));

      return;
    }

    const autocompleteService =
      new window.google.maps.places.AutocompleteService();

    // Step 1: Get Place Predictions
    autocompleteService.getPlacePredictions(
      { input: searchTerm },
      (predictions: any[], status: string) => {
        if (
          status !== window.google.maps.places.PlacesServiceStatus.OK ||
          !predictions ||
          predictions.length === 0
        ) {
          resolve(undefined);

          return;
        }

        const placeId = predictions[0].place_id;

        const placesService = new window.google.maps.places.PlacesService(
          document.createElement('div') // Dummy div required for PlacesService
        );

        // Step 2: Get Place Details
        placesService.getDetails(
          { placeId },
          (place: any, detailsStatus: string) => {
            if (
              detailsStatus !==
                window.google.maps.places.PlacesServiceStatus.OK ||
              !place
            ) {
              reject(new Error('Failed to fetch place details.'));

              return;
            }

            // Step 3: Parse Address Components
            const addressComponents = place.address_components;
            const formattedAddress: Record<string, string | number> = {
              fullAddress: place.formatted_address || '',
              address1: '',
              city: '',
              state: '',
              country: '',
              postal: '',
              latitude: place.geometry?.location?.lat() || 0,
              longitude: place.geometry?.location?.lng() || 0,
              stateCode: '',
              countryCode: '',
            };

            addressComponents.forEach((component: any) => {
              const types = component.types;

              if (types.includes('street_number')) {
                formattedAddress.address1 =
                  `${component.long_name} ${formattedAddress.address1}`.trim();
              } else if (types.includes('route')) {
                formattedAddress.address1 += ` ${component.long_name}`.trim();
              } else if (types.includes('locality')) {
                formattedAddress.city = component.long_name;
              } else if (types.includes('administrative_area_level_1')) {
                formattedAddress.state = component.long_name;
                formattedAddress.stateCode = component.short_name;
              } else if (types.includes('country')) {
                formattedAddress.country = component.long_name;
                formattedAddress.countryCode = component.short_name;
              } else if (types.includes('postal_code')) {
                formattedAddress.postal = component.long_name;
              }
            });

            resolve(formattedAddress);
          }
        );
      }
    );
  });

const initValidateMeasurement = {
  weight: LBS.WEIGHT,
  length: LBS.LENGTH,
  width: LBS.WIDTH,
  height: LBS.HEIGHT,
};

const initServiceValidForm = {
  limitedAccessOther: true,
  inbondName: true,
  inbondAddress: true,
  inbondContactValue: true,
  unitedNationsNumber: true,
  isEmailValid: true,
  isPhoneValid: true,
  goodsClass: true,
  description: true,
  emergencyContactName: true,
  emergencyContactNumber: true,
  isEmergencyPhoneValid: true,
  declaredValue: true,
};

const requiredFields = [
  'handlingUnit',
  'handlingUnitNo',
  'weight',
  'itemLength',
  'width',
  'height',
  'freightClass',
  'commodityId',
  'sub',
];

interface IDimensionFormData {
  id: number | null;
  handlingUnit: string | number | null;
  handlingUnitNo: number;
  weight: number;
  itemLength: number;
  width: number;
  height: number;
  totalWeight: number;
  freightClass: number;
  isStack: boolean;
  weightMeasurement: string;
  commodityId: string | null;
  commodityName: string | null;
  sub: string | null;
  description: string | null;
}

const initDimensionFormData: IDimensionFormData = {
  id: null,
  handlingUnit: '',
  handlingUnitNo: 0,
  weight: 0,
  itemLength: 0,
  width: 0,
  height: 0,
  totalWeight: 0,
  freightClass: 0,
  isStack: true,
  weightMeasurement: MEASUREMENT.WEIGHT1.value,
  commodityId: null,
  commodityName: null,
  sub: null,
  description: null,
};

const providerArr = [
  { label: 'Imperial (lbs)', value: MEASUREMENT.WEIGHT1.value },
  { label: 'Metric (kgs)', value: MEASUREMENT.WEIGHT2.value },
];

const limitedAccessList = [
  { name: 'Construction Site', value: 'construction-site' },
  { name: 'Individual (Mini) Storage Unit', value: 'mini-storage-unit' },
  { name: 'Fair/Amusement Park', value: 'fair' },
  { name: 'Place of Worship', value: 'place-of-worship' },
  { name: 'Farm/Country Club/Estate', value: 'farm' },
  {
    name: 'Secured Locations Delivery - prisons, military bases, airport',
    value: 'secured-location',
  },
  { name: 'School/University', value: 'school' },
  { name: 'Plaza/Mall with only parking lot/Street access', value: 'mall' },
  { name: 'Airport', value: 'airport' },
  { name: 'Cemetery', value: 'cemetery' },
  { name: 'Church', value: 'church' },
  { name: 'Convention Center', value: 'convention-center' },
  { name: 'Funeral Home', value: 'funeral-home' },
  { name: 'Golf Course', value: 'golf-course' },
  { name: 'Grocery Warehouse', value: 'grocery-warehouse' },
  { name: 'Hospital/Medical Facility', value: 'hospital-medical-facility' },
  { name: 'Hotel', value: 'hotel' },
  { name: 'Inspection Site', value: 'inspection-site' },
  { name: 'Marina', value: 'marina' },
  { name: 'Military Base', value: 'military-base' },
  { name: 'Mine Site', value: 'mine-site' },
  { name: 'No Dock/Dock obstruction', value: 'no-dock-dock-obstruction' },
  { name: 'Nursing Home', value: 'nursing-home' },
  { name: 'Park Forest/Preserve', value: 'park-forest-preserve' },
  { name: 'Piers and Wharves', value: 'piers-and-wharves' },
  { name: 'Prison', value: 'prison' },
  { name: 'Ranch', value: 'ranch' },
  {
    name: 'Recreational/athletic Facility',
    value: 'recreational-athletic-facility',
  },
  { name: 'Remote Government', value: 'remote-government' },
  { name: 'Reservation', value: 'reservation' },
  { name: 'Restaurant', value: 'restaurant' },
  { name: 'Service Station', value: 'service-station' },
  { name: 'Social Club', value: 'social-club' },
  { name: 'Steel Mill', value: 'steel-mill' },
  { name: 'Unspecified', value: 'unspecified' },
  { name: 'Utility Site', value: 'utility-site' },
  { name: 'Winery/Brewery/Distillery', value: 'winery-brewery-distillery' },
  { name: 'Other', value: 'other' },
];

const getName = (contactMethod: string) => {
  if (contactMethod === 'fax-number') {
    return 'Fax';
  } else if (contactMethod === 'phone-number') {
    return 'Phone';
  } else {
    return 'Email';
  }
};

const initDimensionValidForm = {
  handlingUnit: {
    required: true,
    valid: true,
  },
  handlingUnitNo: {
    required: true,
    valid: true,
  },
  weight: {
    required: true,
    valid: true,
  },
  itemLength: {
    required: true,
    valid: true,
  },
  freightClass: {
    required: true,
    valid: true,
  },
  width: {
    required: true,
    valid: true,
  },
  height: {
    required: true,
    valid: true,
  },
  totalWeight: {
    required: true,
    valid: true,
  },
  isStack: {
    required: true,
    valid: true,
  },
  commodityId: {
    required: true,
    valid: true,
  },
  commodityName: {
    required: true,
    valid: true,
  },
  sub: {
    required: true,
    valid: true,
  },
  description: {
    required: true,
    valid: true,
  },
};

const initDimensionAction = {
  tailgate: false,
};

const packagingGroupOptions = [
  { label: 'PG I', value: 'PGI' },
  { label: 'PG II', value: 'PGII' },
  { label: 'PG III', value: 'PGIII' },
];

const inbondTypeList = [
  {
    name: '(IT) Immediate Transportation Bond',
    value: 'immediate-exportation',
  },
  {
    name: '(T&E) Transportation & Export Bond',
    value: 'transportation-and-exportation',
  },
];

const inbondContactMethodList = [
  { name: 'Email', value: 'email-address' },
  { name: 'Phone', value: 'phone-number' },
  { name: 'Fax Number', value: 'fax-number' },
];

const dangerTypeList = [
  { name: 'Limited Quantity', value: 'limited-quantity' },
  { name: '500 kg Exemption', value: 'exemption-500-kg' },
  { name: 'Fully Regulated', value: 'fully-regulated' },
];

interface IProps {
  selectedEmail: any;
  params: any;
  setParams: any;
  mapApiStatus: boolean;
  isEdit?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const CreateQuoteFromMailSection = ({
  selectedEmail,
  params,
  setParams,
  mapApiStatus,
  isEdit = false,
}: IProps) => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const navigate = useNavigate();
  const [dimensionsFormData, setDimensionsFormData] = useState<any[]>([]);

  const [customerOptions, setCustomerOptions] = useState<any>([]);
  const [shippers, setShippers] = useState<any>([]);
  const [consignees, setConsignees] = useState<any>([]);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [consigneeOptions, setConsigneeOptions] = useState([]);

  const [formData, setFormData] = useState<IFormData>(initQuoteFormData);

  const [shipperAddress, setShipperAddress] = useState('');
  const [consigneeAddress, setConsigneeAddress] = useState('');

  const [validForm, setValidForm] = useState(initValidQuoteForm);

  const [isConsigneeBtnDisable, setIsConsigneeBtnDisable] = useState(false);
  const [isShipperBtnDisable, setIsShipperBtnDisable] = useState(false);

  const [pickup1pmError, setPickup1pmError] = useState(false);
  const [tomorrowDayName, setTomorrowDayName] = useState('');

  // loaders
  const [isCustomerLoading, setIsCustomerLoading] = useState(true);
  const [isShipperLoading, setIsShipperLoading] = useState(true);
  const [addressData, setAddressData] = useState<IMapAddress>();
  const [isCreateUpdateQuoteLoading, setIsCreateUpdateQuoteLoading] =
    useState(false);

  const [isEquipmentTypeLoading, setIsEquipmentTypeLoading] = useState(true);
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState<any[]>([]);
  const [action, setAction] = useState(initAction);

  // AI extraction obj
  const [quoteAndShipperExtractedData, setQuoteAndShipperExtractedData] =
    useState<any>(undefined);
  const [dimensionsExtractedData, setDimensionsExtractedData] =
    useState<any>(undefined);
  const [
    isAiExtractionAndQuoteDetailLoading,
    setIsAiExtractionAndQuoteDetailLoading,
  ] = useState(false);

  // Stepper states
  const [currentStep, setCurrentStep] = useState(1);
  const [currentDimensionStep, setCurrentDimensionStep] = useState(0);

  // DIMENSIONS STATES
  const [isHandlingUnitLoading, setIsHandlingUnitLoading] = useState(true);
  const [handlingUnitOptions, setHandlingUnitOptions] = useState<any>([]);
  const [validDimensionForm, setValidDimensionForm] = useState([
    initDimensionValidForm,
  ]);
  const [lengthMeasurement, setLengthMeasurement] = useState(
    MEASUREMENT.LENGTH1.value
  );
  const [weightMeasurement, setWeightMeasurement] = useState(
    dimensionsExtractedData?.dimensions?.[0]?.weightMeasurement ===
      MEASUREMENT.WEIGHT1.value ||
      dimensionsExtractedData?.dimensions?.[0]?.weightMeasurement ===
        MEASUREMENT.WEIGHT2.value
      ? dimensionsExtractedData?.dimensions?.[0]?.weightMeasurement
      : MEASUREMENT.WEIGHT1.value
  );
  const [validateMeasurement, setValidateMeasurement] = useState(
    initValidateMeasurement
  );
  const [dimensionAction, setDimensionAction] = useState(initDimensionAction);

  const [isTailgate, setIsTailgate] = useState<any>([]);
  const [isTotalWeightOver, setIsTotalWeightOver] = useState<boolean>(false);
  const [isItemLengthOver, setIsItemLengthOver] = useState<boolean>(false);
  const [isOverWeightAndLengthConfirmed, setIsOverWeightAndLengthConfirmed] =
    useState(false);

  // ADDITIONAL SERVICES STATES
  const [validServicesForm, setValidServicesForm] =
    useState(initServiceValidForm);
  const [serviceList, setServiceList] = useState([]);
  const [isServiceLoading, setIsServiceLoading] = useState(true);
  const [serviceTypes, setServiceTypes] = useState<any>();
  const [selectedServices, setSelectedServices] = useState<number[]>([]);
  const [isAccessTypeModalOpen, setIsAccessTypeModalOpen] =
    useState<boolean>(false);
  const [isLimitedMore, setIsLimitedMore] = useState<boolean>(false);
  const [limitedAccessTypeList, setLimitedAccessTypeList] =
    useState<any>(limitedAccessList);
  const [limitedAccessSearch, setLimitedAccessSearch] = useState<string>('');
  const [limitedAccessType, setLimitedAccessType] = useState<string>(
    limitedAccessTypeList?.[0].value
  );
  const [limitedAccessOther, setLimitedAccessOther] = useState<string>('');

  const [isInbondMoreModalOpen, setIsInbondMoreModalOpen] =
    useState<boolean>(false);
  const [isInbondMore, setIsInbondMore] = useState<boolean>(false);
  const [inbondType, setInbondType] = useState<string>(inbondTypeList[0].value);
  const [inbondName, setInbondName] = useState<string>('');
  const [inbondAddress, setInbondAddress] = useState<string>('');
  const [inbondContactMethod, setInbondContactMethod] = useState<string>(
    inbondContactMethodList[0].value
  );
  const [inbondContactValue, setInbondContactValue] = useState<string>('');
  const [isDangerousMoreModalOpen, setIsDangerousMoreModalOpen] =
    useState<boolean>(false);
  const [isDangerousMore, setIsDangerousMore] = useState<boolean>(false);
  const [dangerType, setDangerType] = useState<string>(dangerTypeList[0].value);
  const [packagingGroup, setPackagingGroup] = useState<string>(
    packagingGroupOptions[0].value
  );
  const [unitedNationsNumber, setUnitedNationsNumber] = useState<string>('');
  const [goodsClass, setGoodsClass] = useState<string>('');
  const [emergencyContactName, setEmergencyContactName] = useState<string>('');
  const [emergencyContactNumber, setEmergencyContactNumber] =
    useState<string>('');

  const [emergencyContactExt, setEmergencyContactExt] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [allowHandleChecked, setAllowHandleChecked] = useState<any>(true);

  // modify quote states
  const [quoteDetail, setQuoteDetail] = useState<any>(undefined);

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  const checkPickupDate = (value: any) => {
    const pickupDate = new Date(value);
    const currentDateTime = new Date();
    pickupDate.setHours(
      currentDateTime.getHours(),
      currentDateTime.getMinutes(),
      currentDateTime.getSeconds(),
      currentDateTime.getMilliseconds()
    );

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Start of the day

    const onePM = new Date();
    onePM.setHours(13, 0, 0, 0); // 1 PM today

    const isSameDay = pickupDate.toDateString() === today.toDateString();
    const isAfterOnePM = pickupDate.getTime() > onePM.getTime();

    if (isSameDay && isAfterOnePM) {
      setPickup1pmError(true);
      const tomorrow = moment().add(1, 'days');
      const dayName = tomorrow.format('dddd');
      setTomorrowDayName(dayName);
    } else {
      setPickup1pmError(false);
    }
  };

  const mapAIExtractedDataForQuoteAndAddress = async (
    emailExtractedData: any,
    isRaw = false
  ) => {
    const extractedResponseObj: any = {
      classification: emailExtractedData?.service_type?.toLowerCase() || null,
      customerId: Number(emailExtractedData?.customer_id) || null,
      pickupDate: emailExtractedData?.pickup_date
        ? moment(emailExtractedData?.pickup_date).toDate()
        : emailExtractedData?.availability_date
        ? moment(emailExtractedData?.availability_date).toDate()
        : null,
      linearFootage: emailExtractedData?.linear_footage?.toFixed(2),
      totalWeight:
        emailExtractedData?.weight_measure?.toLowerCase() === 'kg'
          ? (emailExtractedData?.total_weight * 2.20462).toFixed(2)
          : emailExtractedData?.total_weight?.toFixed(2),
      consigneeAddress: {},
      shipperAddress: {},
    };

    if (emailExtractedData?.equipment_type?.length) {
      const equipmentTypeFullNameList: string[] = [];
      const equipmentTypeFullList: string[] = [];

      emailExtractedData?.equipment_type?.forEach((eqType: any) => {
        const matchingEqTypeOption = equipmentTypeOptions.find(
          (eqTypeOption: any) => eqTypeOption?.label === eqType
        );

        if (matchingEqTypeOption) {
          equipmentTypeFullList.push(matchingEqTypeOption?.value);
          equipmentTypeFullNameList.push(matchingEqTypeOption?.label);
        }
      });

      extractedResponseObj.primaryEquipmentType = equipmentTypeFullList?.[0];
      extractedResponseObj.primaryEquipmentTypeFullName =
        equipmentTypeFullNameList?.[0];

      extractedResponseObj.secondaryEquipmentTypes =
        equipmentTypeFullList?.slice(1);
      extractedResponseObj.secondaryEquipmentTypeFullNames =
        equipmentTypeFullNameList?.slice(1);
    }

    if (emailExtractedData?.from) {
      try {
        const fromAddressResult = await getAddressDetails(
          `${emailExtractedData?.from?.address}, ${emailExtractedData?.from?.city}, ${emailExtractedData?.from?.state} ${emailExtractedData?.from?.zipcode}, ${emailExtractedData?.from?.country}`
        );

        if (fromAddressResult && fromAddressResult?.address1) {
          extractedResponseObj.shipperAddress = fromAddressResult;
        }
      } catch (error: any) {
        console.error('Error:', error.message);
      }
    }

    if (emailExtractedData?.to) {
      try {
        const toAddressResult = await getAddressDetails(
          `${emailExtractedData?.to?.address}, ${emailExtractedData?.to?.city}, ${emailExtractedData?.to?.state} ${emailExtractedData?.to?.zipcode}, ${emailExtractedData?.to?.country}`
        );

        if (toAddressResult && toAddressResult?.address1) {
          extractedResponseObj.consigneeAddress = toAddressResult;
        }
      } catch (error: any) {
        console.error('Error:', error.message);
      }
    }

    return isRaw ? extractedResponseObj : cleanObject(extractedResponseObj);
  };

  const mapAIExtractedDataForDimensions = (emailExtractedData: any) => {
    const extractedResponseObj: any = {
      dimensions: [],
      selectedServices:
        emailExtractedData?.additional_services?.map((str: string) =>
          parseInt(str, 10)
        ) || null,
    };

    emailExtractedData?.dimension?.forEach((dim: any) => {
      extractedResponseObj.dimensions.push({
        handlingUnit: dim?.parcel ? Number(dim?.parcel) : undefined,
        handlingUnitNo: dim?.no_of_parcels,
        weight: dim?.weight,
        itemLength: dim?.length,
        width: dim?.width,
        height: dim?.height,
        totalWeight: dim?.total_weight,
        weightMeasurement: ['LBS', 'KGS'].includes(
          dim?.weight_measure?.toUpperCase()
        )
          ? dim?.weight_measure?.toUpperCase()
          : undefined,
      });
    });

    return extractedResponseObj;
  };

  useEffect(() => {
    console.log('quoteAndShipperExtractedData ', quoteAndShipperExtractedData);

    if (quoteAndShipperExtractedData?.pickupDate) {
      checkPickupDate(quoteAndShipperExtractedData?.pickupDate);
    }

    setShipperAddress(
      quoteAndShipperExtractedData?.shipperAddress?.fullAddress || ''
    );

    setConsigneeAddress(
      quoteAndShipperExtractedData?.consigneeAddress?.fullAddress || ''
    );

    setFormData({ ...initQuoteFormData, ...quoteAndShipperExtractedData });
  }, [quoteAndShipperExtractedData]);

  const extractDetailsFromEmailExtractedDetails = async (
    selectedEmailExtractedData: any
  ): Promise<void> => {
    try {
      const quoteAndShipperData = await mapAIExtractedDataForQuoteAndAddress(
        selectedEmailExtractedData
      );
      setQuoteAndShipperExtractedData(quoteAndShipperData);

      const dimensionsData = mapAIExtractedDataForDimensions(
        selectedEmailExtractedData
      );
      setDimensionsExtractedData(dimensionsData);

      setIsAiExtractionAndQuoteDetailLoading(false);

      return await Promise.resolve();
    } catch (error) {
      setIsAiExtractionAndQuoteDetailLoading(false);

      return Promise.reject(error);
    }
  };

  const handleInitializeFlow = async () => {
    if (selectedEmail?.extractDetails) {
      setIsAiExtractionAndQuoteDetailLoading(true);
      const selectedEmailExtractedData = JSON.parse(
        selectedEmail?.extractDetails
      );

      console.log('selectedEmailExtractedData ', selectedEmailExtractedData);
      await extractDetailsFromEmailExtractedDetails(selectedEmailExtractedData);
    }

    if (selectedEmail?.quoteCreatedId) {
      setIsAiExtractionAndQuoteDetailLoading(true);

      setAllowHandleChecked(false);
      await getQuote(selectedEmail.quoteCreatedId)
        .then((response: any) => {
          setQuoteDetail(response?.data);

          const responseAddressDetails = response?.data?.addressDetails;
          const responseDimensions = response?.data?.dimensions;
          const responseAdditionalService = response?.data?.additionalService;

          const quoteEditFormData = {
            customerId: responseAddressDetails?.customerId,
            shipperId: responseAddressDetails?.shipperId,
            shipperCompanyName: responseAddressDetails?.shipperCompanyName,
            shipperAddress: {
              fullAddress: responseAddressDetails?.shipperFullAddress,
              address1: responseAddressDetails?.shipperAddress1,
              city: responseAddressDetails?.shipperCity,
              state: responseAddressDetails?.shipperState,
              country: responseAddressDetails?.shipperCountry,
              postal: responseAddressDetails?.shipperPostal,
              latitude: responseAddressDetails?.shipperLatitude,
              longitude: responseAddressDetails?.shipperLongitude,
              stateCode: responseAddressDetails?.shipperStateCode,
              countryCode: responseAddressDetails?.shipperCountryCode,
            },
            pickupDate: responseAddressDetails?.pickupDate,
            consigneeId: responseAddressDetails?.consigneeId,
            consigneeCompanyName: responseAddressDetails?.consigneeCompanyName,
            consigneeAddress: {
              fullAddress: responseAddressDetails?.consigneeFullAddress,
              address1: responseAddressDetails?.consigneeAddress1,
              city: responseAddressDetails?.consigneeCity,
              state: responseAddressDetails?.consigneeState,
              country: responseAddressDetails?.consigneeCountry,
              postal: responseAddressDetails?.consigneePostal,
              latitude: responseAddressDetails?.consigneeLatitude,
              longitude: responseAddressDetails?.consigneeLongitude,
              stateCode: responseAddressDetails?.consigneeStateCode,
              countryCode: responseAddressDetails?.consigneeCountryCode,
            },
            urgency: responseAddressDetails?.urgency,
            classification: responseAddressDetails?.classification,
            linearFootage: responseAddressDetails?.linearFootage,
            totalWeight: responseAddressDetails?.totalWeight,
            primaryEquipmentType: responseAddressDetails?.primaryEquipmentType,
            primaryEquipmentTypeFullName:
              responseAddressDetails?.primaryEquipmentTypeFullName,
            secondaryEquipmentTypes:
              responseAddressDetails?.secondaryEquipmentTypes,
            secondaryEquipmentTypeFullNames:
              responseAddressDetails?.secondaryEquipmentTypeFullNames,
            isInsureLoad: !!responseAddressDetails?.insuranceCommercialValue,
            insuranceCommercialValue:
              responseAddressDetails?.insuranceCommercialValue,
            insuranceCurrencyType:
              responseAddressDetails?.insuranceCurrencyType,
          };

          if (response?.data?.addressDetails?.pickupDate) {
            checkPickupDate(response?.data?.addressDetails?.pickupDate);
          }

          setShipperAddress(
            response?.data?.addressDetails?.shipperFullAddress || ''
          );

          setConsigneeAddress(
            response?.data?.addressDetails?.consigneeFullAddress || ''
          );

          setFormData((oldData) => ({ ...oldData, ...quoteEditFormData }));

          let quoteEditDimensionsFormData: any[] = [];

          responseDimensions?.forEach((responseDimension: any) => {
            quoteEditDimensionsFormData.push({
              id: responseDimension?.id,
              handlingUnit: responseDimension?.handlingUnit,
              handlingUnitNo: responseDimension?.handlingUnitNo,
              weight: responseDimension?.weight,
              itemLength: responseDimension?.itemLength,
              width: responseDimension?.width,
              height: responseDimension?.height,
              totalWeight: responseDimension?.totalWeight,
              freightClass: responseDimension?.freightClass,
              isStack: responseDimension?.isStack,
              weightMeasurement: responseDimension?.weightMeasurement,
              commodityId: responseDimension?.commodityId,
              commodityName: responseDimension?.commodityName,
              sub: responseDimension?.sub,
              description: responseDimension?.description,
              itemClassForDayross: responseDimension?.itemClassForDayross,
            });
          });

          setDimensionsFormData(quoteEditDimensionsFormData);

          if (responseAdditionalService) {
            let savedServices = responseAdditionalService?.map((val: any) => {
              if (
                val.slug === 'tailgate_pickup' ||
                val.slug === 'tailgate_delivery'
              ) {
                setIsTailgate((old: any) => [...old, val.serviceId]);
              }

              if (val.slug === 'limited_access_delivery') {
                setIsLimitedMore(true);
                setLimitedAccessType(val.limitedAccessType);
                setLimitedAccessOther(val.limitedAccessOther);
              }

              if (val.slug === 'in-bond') {
                setIsInbondMore(true);
                setInbondType(val.inbondType);
                setInbondName(val.inbondName);
                setInbondAddress(val.inbondAddress);
                setInbondContactMethod(val.inbondContactMethod);
                setInbondContactValue(val.inbondContactValue);
              }

              if (val.slug === 'dangerous_goods') {
                setIsDangerousMore(true);
                setDangerType(val.dangerGoodsType);
                setPackagingGroup(val.packagingGroup);
                setUnitedNationsNumber(val.unitedNationsNumber);
                setGoodsClass(val.goodsClass);
                setEmergencyContactName(val.emergencyContactName);
                setEmergencyContactNumber(val.emergencyContactNumber);
                setEmergencyContactExt(val.emergencyContactExt);
                setDescription(val.description);
              }

              return val.serviceId;
            });
            setSelectedServices(savedServices);
          }
        })
        .catch((e: any) => {
          console.log('error fetching quote detail ', e);
        })
        .finally(() => {
          setIsAiExtractionAndQuoteDetailLoading(false);
        });
    }
  };

  useEffect(() => {
    setCurrentStep(1);
    setCurrentDimensionStep(0);

    handleInitializeFlow();

    // important : cleanup
    return () => {
      setQuoteDetail(undefined);
      setFormData(initQuoteFormData);
      setValidForm(initValidQuoteForm);
      setIsOverWeightAndLengthConfirmed(false);
      setSelectedServices([]);
      setDimensionsFormData([]);
      setShipperAddress('');
      setConsigneeAddress('');
      setQuoteAndShipperExtractedData(undefined);
      setDimensionsExtractedData(undefined);
    };
  }, [selectedEmail]);

  const getVehicleTypeOptions = () => {
    setIsEquipmentTypeLoading(true);

    cancelApiCall();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    getLoadLinkVehicleType(signal)
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setEquipmentTypeOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        console.log('Equipment Type Error ', e);
      })
      .finally(() => {
        setIsEquipmentTypeLoading(false);
      });
  };

  useEffect(() => {
    if (formData.pickupDate !== '' || formData.pickupDate !== null) {
      checkPickupDate(formData.pickupDate);
    }

    getVehicleTypeOptions();
  }, []);

  const getCustomer = () => {
    CustomersAllList()
      .then((result: any) => {
        if (result.data && result.data.length) {
          result.data = result.data.filter((data: any) => !data.isDeleted);

          result.data = result.data.map((data: any) => {
            if (data.isDeleted) {
              data.name = `${data.name} (Deleted Customer)`;
              data.id = '';
            }

            return data;
          });

          // setCustomers(result.data);
          const customerSelectBoxOptions = getSelectBoxOptions(
            result.data,
            'id',
            'name'
          );
          setCustomerOptions(customerSelectBoxOptions);
        }
      })
      .finally(() => setIsCustomerLoading(false))
      .catch(console.error);
  };

  const getShipper = () => {
    listShippers()
      .then((response: any) => {
        if (response) {
          let shipperOptionsValue: any = [];
          let consigneeOptionsValue: any = [];
          response.data.forEach((result: any) => {
            if (result.type === 1) {
              shipperOptionsValue.push(result);
            } else {
              consigneeOptionsValue.push(result);
            }
          });
          setShippers(shipperOptionsValue);
          setConsignees(consigneeOptionsValue);
          shipperOptionsValue = getSelectBoxOptions(
            shipperOptionsValue,
            'id',
            'companyName'
          );
          consigneeOptionsValue = getSelectBoxOptions(
            consigneeOptionsValue,
            'id',
            'companyName'
          );
          setShipperOptions(shipperOptionsValue);
          setConsigneeOptions(consigneeOptionsValue);
        }
      })
      .finally(() => setIsShipperLoading(false))
      .catch(console.error);
  };

  useEffect(() => {
    getCustomer();
    getShipper();
  }, []);

  const handleSelectChange = (name: any) => (event: any) => {
    const newData: any = {};

    if (name === 'isInsureLoad') {
      newData[name] = event?.checked;
    } else {
      newData[name] = event ? event.value : null;
    }

    if (name === 'shipperId') {
      if (event && event.value) {
        const shipper = shippers.find(
          (shipperData: any) => shipperData.id === event.value
        );
        newData.shipperCompanyName = shipper.companyName;
        newData.shipperAddress = {
          fullAddress: shipper.fullAddress,
          address1: shipper.address1,
          postal: shipper.postal,
          city: shipper.city,
          state: shipper.state,
          country: shipper.country,
          longitude: shipper.longitude,
          latitude: shipper.latitude,
          stateCode: shipper.stateCode,
          countryCode: shipper.countryCode,
        };
        // setIsShipperBtnDisable(false);
        setShipperAddress(shipper.fullAddress);
      } else {
        newData.shipperCompanyName = '';
        newData.shipperAddress = initShipperAddress;
        setShipperAddress('');
      }
    } else if (name === 'consigneeId') {
      if (event && event.value) {
        const consignee = consignees.find(
          (consigneeData: any) => consigneeData.id === event.value
        );
        newData.consigneeCompanyName = consignee.companyName;
        newData.consigneeAddress = {
          fullAddress: consignee.fullAddress,
          address1: consignee.address1,
          postal: consignee.postal,
          city: consignee.city,
          state: consignee.state,
          country: consignee.country,
          longitude: consignee.longitude,
          latitude: consignee.latitude,
          stateCode: consignee.stateCode,
          countryCode: consignee.countryCode,
        };
        // setIsConsigneeBtnDisable(false);
        setConsigneeAddress(consignee.fullAddress);
      } else {
        newData.consigneeCompanyName = '';
        newData.consigneeAddress = initConsigneeAddress;
        setConsigneeAddress('');
      }
    }
    setFormData((old) => ({ ...old, ...newData }));

    if (event?.value) {
      setValidForm({ ...validForm, [name]: true });

      if (name === 'consigneeId') {
        setValidForm({ ...validForm, consigneeAddress: true });
      }

      if (name === 'shipperId') {
        setValidForm({ ...validForm, shipperAddress: true });
      }
    }
  };

  const handleActionType =
    (actionKey: any, mode: any = null) =>
    () => {
      if (mode === 'addressUpdate' && actionKey === 'shipper') {
        setAddressData(formData.shipperAddress);
      }

      if (mode === 'addressUpdate' && actionKey === 'consignee') {
        setAddressData(formData.consigneeAddress);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const handleAddressSelection = (value: any) => {
    if (action.shipper) {
      setShipperAddress(value.fullAddress);
      setFormData((old) => ({ ...old, shipperAddress: value }));
    } else if (action.consignee) {
      setConsigneeAddress(value.fullAddress);
      setFormData((old) => ({ ...old, consigneeAddress: value }));
    }
  };

  const saveShipperDetails = (type: string) => () => {
    let idToSave;
    let address: any;
    let companyName;
    let typeId;
    let insertMsg: any;
    let updateMsg: any;

    if (type === 'shipper') {
      typeId = 1;
      idToSave = formData.shipperId;
      companyName = formData.shipperCompanyName;
      address = formData.shipperAddress;
      insertMsg = 'Shipper details added successfully';
      updateMsg = 'Shipper details updated successfully';
    } else if (type === 'consignee') {
      typeId = 2;
      idToSave = formData.consigneeId;
      companyName = formData.consigneeCompanyName;
      address = formData.consigneeAddress;
      insertMsg = 'Consignee details added successfully';
      updateMsg = 'Consignee details updated successfully';
    }
    const data: any = {
      ...address,
      companyName,
      type: typeId,
    };

    if (companyName === '') {
      WalToast.error(`Please enter ${type}'s company name`, '');

      return false;
    }

    if (address.fullAddress === '' || typeof address !== 'object') {
      WalToast.error(`Please enter ${type}'s address`, '');

      return false;
    }

    if (type === 'shipper') {
      setIsShipperBtnDisable(true);
    } else {
      setIsConsigneeBtnDisable(true);
    }

    if (idToSave) {
      updateShipper(idToSave, data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }
            WalToast.success(updateMsg, '');
          }
        })
        .catch(console.error);
    } else {
      createShipper(data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }
            WalToast.success(insertMsg, '');
          }
        })
        .catch(console.error);
    }
  };

  const handleAutocompleteChange = (name: string) => (value: any) => {
    if (value.type === 'blur') {
      return;
    }

    if (name === 'shipperAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value.postal?.length < 4
      ) {
        setShipperAddress(value?.fullAddress);
        setFormData((old) => ({ ...old, [name]: value }));

        return;
      }
      // setShipperAddress(value);
      setValidForm({ ...validForm, shipperAddress: true });
      // setIsShipperBtnDisable(false);
    } else if (name === 'consigneeAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value.postal?.length < 4
      ) {
        setConsigneeAddress(value?.fullAddress);
        setFormData((old) => ({ ...old, [name]: value }));

        return;
      }
      // setConsigneeAddress(value);
      // setIsConsigneeBtnDisable(false);
      setValidForm({ ...validForm, consigneeAddress: true });
    }
    setFormData((old) => ({ ...old, [name]: value }));
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((old: any) => ({ ...old, [name]: value }));
  };

  const selectNextDay = (val: boolean) => {
    if (val) {
      const tomorrow = moment().add(1, 'days');
      setFormData((old: any) => ({ ...old, pickupDate: tomorrow }));
      localStorage.removeItem('isPickupDateChanged');
    }
    setPickup1pmError(false);
  };

  const handleDateChange = (event: any) => {
    const { name, value } = event;
    setFormData((old: any) => ({ ...old, [name]: value }));
    localStorage.removeItem('isPickupDateChanged');
    checkPickupDate(value);
  };

  const handleSummarySubmit = async (quoteId: any) => {
    try {
      let quoteAiExtractedData: any = {};

      if (selectedEmail?.extractDetails) {
        const selectedEmailExtractedData = JSON.parse(
          selectedEmail?.extractDetails
        );
        console.log('selectedEmailExtractedData ', selectedEmailExtractedData);

        quoteAiExtractedData = await mapAIExtractedDataForQuoteAndAddress(
          selectedEmailExtractedData,
          true
        );

        const dimensionsAiData = mapAIExtractedDataForDimensions(
          selectedEmailExtractedData
        );

        quoteAiExtractedData.dimensions = dimensionsAiData.dimensions;
        quoteAiExtractedData.selectedServices =
          dimensionsAiData.selectedServices;
      }

      const summaryResponse = await createUpdateSummary({
        quoteId,
        quoteAiExtractedData: JSON.stringify(quoteAiExtractedData),
      });

      if (summaryResponse?.data?.data?.classification === 'other') {
        let redirectUrl = `${PATH.MY_QUOTE}`;
        navigate(redirectUrl);
      } else {
        let redirectUrl = `${PATH.MY_QUOTE}/${quoteId}`;
        navigate(redirectUrl);
      }
    } catch (e) {
      console.log('error : ', e);
    }
  };

  const handleDiscardEmail = async () => {
    setIsCreateUpdateQuoteLoading(true);
    removeFromQuote({ id: selectedEmail?.messageId }).then(() => {
      setIsCreateUpdateQuoteLoading(false);
      setParams((old: any) => ({
        ...old,
        page: 1,
      }));
    });
  };

  const handleModalClose = useCallback(
    (closeStatus: boolean) => () => {
      setAction(initAction);

      if (closeStatus) {
        const idToDelete = action.shipper
          ? formData.shipperId
          : formData.consigneeId;

        if (idToDelete) {
          deleteShippers(idToDelete)
            .then((result: any) => {
              if (result) {
                const newData: any = {};
                let name = '';

                if (action.shipper) {
                  newData.shipperId = null;
                  newData.shipperCompanyName = '';
                  newData.shipperAddress = initShipperAddress;
                  setShipperAddress('');
                  name = 'shipperId';
                } else if (action.consignee) {
                  newData.consigneeId = null;
                  newData.consigneeCompanyName = '';
                  newData.consigneeAddress = initConsigneeAddress;
                  setConsigneeAddress('');
                  name = 'consigneeId';
                }
                setFormData((old) => ({ ...old, ...newData, [name]: null }));
                getShipper();
                const title = action.shipper ? 'Shipper' : 'Consignee';
                WalToast.success(`${title} deleted successfully`, '');
              }
            })
            .catch(console.error);
        }
      }
    },
    [action]
  );

  // DIMENSIONS FUNCTIONS

  const handleServiceActionType = (actionKey: string) => {
    setDimensionAction((oldAction) => ({ ...oldAction, [actionKey]: true }));
  };

  useEffect(() => {
    const form = dimensionsFormData.map((formDataField: any) => {
      formDataField.weightMeasurement = weightMeasurement;

      return formDataField;
    });
    setDimensionsFormData(form);
    const validateMeasurementValue = {
      weight:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.WEIGHT
          : LBS.WEIGHT,
      length:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.LENGTH
          : LBS.LENGTH,
      width:
        weightMeasurement === MEASUREMENT.WEIGHT2.value ? KGS.WIDTH : LBS.WIDTH,
      height:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.HEIGHT
          : LBS.HEIGHT,
    };
    setValidateMeasurement((oldValidMeasurement) => ({
      ...oldValidMeasurement,
      ...validateMeasurementValue,
    }));
  }, [weightMeasurement]);

  useEffect(() => {
    listHandlingUnit()
      .then((result: any) => {
        if (result.data && result.data.length) {
          const handlingUnitSelectBoxOptions: any = getSelectBoxOptions(
            result.data,
            'id',
            'name'
          );
          setHandlingUnitOptions(handlingUnitSelectBoxOptions);
        }
      })
      .finally(() => setIsHandlingUnitLoading(false))
      .catch(console.error);
  }, []);

  const checkIsDimensionFormValid = (currentDimStep: number | null = null) => {
    if (!isOverWeightAndLengthConfirmed && currentDimStep === null) {
      if (isTailgate.length && (isTotalWeightOver || isItemLengthOver)) {
        console.log('tailgate');
        handleServiceActionType('tailgate');

        return false;
      }
    }

    const validFormData: any = [];
    dimensionsFormData.forEach((form: any) => {
      const validation = {
        ...initDimensionValidForm,
        handlingUnit: {
          required: Boolean(form?.handlingUnit),
          valid: Boolean(form?.handlingUnit),
        },
        weight: {
          required: Boolean(form.weight),
          valid: Boolean(form.weight),
        },
        handlingUnitNo: {
          required: Boolean(form.handlingUnitNo),
          valid: form.handlingUnitNo <= HANDLING_UNIT_NUMBER,
        },
        itemLength: {
          required: Boolean(form.itemLength),
          valid: form.itemLength <= validateMeasurement.length,
        },
        width: {
          required: Boolean(form.width),
          valid: form.width <= validateMeasurement.width,
        },
        height: {
          required: Boolean(form.height),
          valid: form.height <= validateMeasurement.height,
        },
        freightClass: {
          required: Boolean(form.freightClass),
          valid: form.freightClass > 0,
        },
      };
      validFormData.push(validation);
    });

    setValidDimensionForm(validFormData);

    let valid = true;

    if (currentDimStep !== null) {
      const currentDim = validFormData[currentDimStep];

      valid = requiredFields?.every(
        (requiredField) =>
          currentDim?.[requiredField].required &&
          currentDim?.[requiredField].valid
      );
    } else {
      valid = validFormData.every((form: any) =>
        requiredFields.every(
          (requiredField) =>
            form[requiredField].required && form[requiredField].valid
        )
      );
    }

    return valid;
  };

  useEffect(() => {
    let totalWeight: number | string = 0;
    let weightMeasurementValue = '';
    setIsItemLengthOver(false);
    setIsTotalWeightOver(false);

    dimensionsFormData.forEach((form: any) => {
      totalWeight += form.totalWeight;
      weightMeasurementValue = form.weightMeasurement;

      const maxLengthOver =
        dimensionsFormData?.[0].weightMeasurement === MEASUREMENT.WEIGHT1.value
          ? LBS.ITEM_LENGTH_OVER
          : KGS.ITEM_LENGTH_OVER;

      if (form.itemLength >= maxLengthOver) {
        // 5 feet over
        setIsItemLengthOver(true);
      }
    });

    totalWeight = totalWeight.toFixed();

    const isTotalWeightOverBool =
      weightMeasurementValue === MEASUREMENT.WEIGHT1.value
        ? parseFloat(totalWeight) >= LBS.MAX_WEIGHT
        : parseFloat(totalWeight) >= KGS.MAX_WEIGHT;
    setIsTotalWeightOver(isTotalWeightOverBool);

    return;
  }, [dimensionsFormData]);

  // ADDITIONAL SERVICES CODE

  useEffect(() => {
    let filterTypes = limitedAccessList.filter((type: any) => {
      if (type.value === limitedAccessType) {
        return true;
      }

      return type.name
        .toLowerCase()
        ?.includes(limitedAccessSearch.toLowerCase());
    });
    setLimitedAccessTypeList(filterTypes);
  }, [limitedAccessSearch]);

  const submitService = async (quoteId: any) => {
    const formDataCurrent: any = {
      quoteId: quoteId,
      serviceId: selectedServices?.join(','),
      limitedAccess: isLimitedMore,
      inbond: isInbondMore,
      dangerousGoods: isDangerousMore,
    };

    if (isLimitedMore) {
      formDataCurrent.limitedAccessType = limitedAccessType;
      formDataCurrent.limitedAccessOther = limitedAccessOther;
    }

    if (isInbondMore) {
      formDataCurrent.inbondType = inbondType;
      formDataCurrent.inbondName = inbondName;
      formDataCurrent.inbondAddress = inbondAddress;
      formDataCurrent.inbondContactMethod = inbondContactMethod;
      formDataCurrent.inbondContactValue = inbondContactValue;
    }

    if (isDangerousMore) {
      formDataCurrent.dangerGoodsType = dangerType;
      formDataCurrent.goodsClass = goodsClass;
      formDataCurrent.description = description;
      formDataCurrent.unitedNationsNumber = unitedNationsNumber;
      formDataCurrent.packagingGroup = packagingGroup;
      formDataCurrent.emergencyContactName = emergencyContactName;
      formDataCurrent.emergencyContactNumber = emergencyContactNumber;
      formDataCurrent.emergencyContactExt = emergencyContactExt;
    }

    try {
      await createUpdateService(formDataCurrent);
    } catch (err) {
      WalToast.error('Additional services have not been updated', '');
    }

    setIsServiceLoading(false);
    setIsCreateUpdateQuoteLoading(false);
  };

  const isInBondFormValid = () => {
    let valid = true;
    const validFormData = {
      inbondName: true,
      inbondAddress: true,
      inbondContactValue: true,
      unitedNationsNumber: true,
      isEmailValid: false,
      isPhoneValid: true,
    };

    if (isInbondMore) {
      if (inbondName === '' || inbondName === null) {
        validFormData.inbondName = false;
        valid = false;
      }

      if (inbondAddress === '' || inbondAddress === null) {
        validFormData.inbondAddress = false;
        valid = false;
      }

      if (inbondContactValue === '' || inbondContactValue === null) {
        validFormData.inbondContactValue = false;
        valid = false;
      } else {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
        validFormData.inbondContactValue = true;

        if (getName(inbondContactMethod) === 'Email') {
          if (emailRegex.test(inbondContactValue)) {
            validFormData.isEmailValid = true;
          } else {
            validFormData.isEmailValid = false;
            valid = false;
          }
        } else {
          validFormData.isEmailValid = true;
        }

        if (getName(inbondContactMethod) === 'Phone') {
          if (!validServicesForm.isPhoneValid) {
            validFormData.isPhoneValid = false;
            valid = false;
          }

          if (!validServicesForm.inbondContactValue) {
            validFormData.inbondContactValue = false;
            valid = false;
          }
        }
      }
    }

    setValidServicesForm((oldServiceValidForm) => ({
      ...oldServiceValidForm,
      ...validFormData,
    }));

    return valid;
  };

  useEffect(() => {
    if (!allowHandleChecked && !isServiceLoading) {
      setTimeout(() => {
        setAllowHandleChecked(true);
      }, 1500);
    }
  }, [allowHandleChecked, isServiceLoading]);

  useEffect(() => {
    if (isLimitedMore && allowHandleChecked) {
      setIsAccessTypeModalOpen(true);
    }
  }, [isLimitedMore]);

  useEffect(() => {
    if (isInbondMore && allowHandleChecked) {
      setIsInbondMoreModalOpen(true);
    }
  }, [isInbondMore]);

  useEffect(() => {
    if (isDangerousMore && allowHandleChecked) {
      setIsDangerousMoreModalOpen(true);
    }
  }, [isDangerousMore]);

  const getListService = () => {
    listService({})
      .then((response: any) => {
        setServiceList(response.data);

        if (response.data.length) {
          let serviceByGroup: any = groupBy(response.data, (val) => val.type);
          setServiceTypes(serviceByGroup);
        }
      })
      .finally(() => {
        setIsServiceLoading(false);
      })
      .catch(console.log);
  };

  useEffect(() => {
    getListService();
  }, []);

  const handleChange = (e: any) => {
    let { value, checked } = e.target;
    value = parseInt(value);

    if (checked) {
      setSelectedServices((oldSelectedServices: any) => [
        ...oldSelectedServices,
        value,
      ]);

      if (
        e.target.name === 'tailgate_pickup' ||
        e.target.name === 'tailgate_delivery'
      ) {
        setIsTailgate((oldIsTailgate: any) => [...oldIsTailgate, value]);
      }

      if (e.target.name === 'limited_access_delivery') {
        setIsLimitedMore(true);
      }

      if (e.target.name === 'in-bond') {
        setIsInbondMore(true);
      }

      if (e.target.name === 'dangerous_goods') {
        setIsDangerousMore(true);
      }
    } else {
      let arr = selectedServices.filter((item: any) => item !== value);
      setSelectedServices(arr);
      setIsTailgate((current: any) =>
        current.filter((item: any) => item !== value)
      );

      if (e.target.name === 'limited_access_delivery') {
        setIsLimitedMore(false);
        setLimitedAccessType(limitedAccessTypeList[0].value);
      }

      if (e.target.name === 'in-bond') {
        setIsInbondMore(false);
        setInbondType(inbondTypeList[0].value);
      }

      if (e.target.name === 'dangerous_goods') {
        setIsDangerousMore(false);
      }
    }
  };

  const handleChangeRadio = (e: any) => {
    let { value, name } = e.target;
    setInbondContactValue('');

    if (name === 'contactMethod') {
      isInBondFormValid();

      setInbondContactMethod(value);
    }

    if (name === 'inbondName') {
      setInbondType(value);
    }

    if (name === 'limitedAccessType') {
      setLimitedAccessType(value);
    }

    if (name === 'dangerType') {
      setDangerType(value);
    }
  };

  const getList = (data: any) => (
    <div className="flex flex-col gap-y-4">
      {data.map((val: any) => (
        <div className="flex gap-x-2" key={`service_${val.id}`}>
          <CheckBox
            id={`service_${val.id}`}
            onChangeFunc={handleChange}
            name={val.slug}
            value={val.id}
            checked={selectedServices?.includes(val.id)}
            parentClassName="mt-0.5"
            classes={`${
              dimensionsExtractedData?.selectedServices?.includes(val.id) &&
              '!border-primary500'
            }`}
          />

          {/* IN BELOW DIV "text-textSecondary" WILL BE REPLACED BY "text-primary" WHEN FIELD IS AI DETECTED */}
          <div
            className={`leading-[1] ${
              dimensionsExtractedData?.selectedServices?.includes(val.id)
                ? 'gradient-text '
                : 'text-textSecondary'
            }`}
          >
            <div className="inline">
              {val.description ? (
                <label
                  htmlFor={`service_${val.id}`}
                  className={`text-xs leading-4 cursor-pointer font-medium mr-1 flex `}
                >
                  {val.name}
                  {dimensionsExtractedData?.selectedServices?.includes(
                    val.id
                  ) && (
                    <TooltipCmp
                      message={'Detected with AI'}
                      parentClassName="min-w-[120px]"
                    >
                      <img
                        src={AiStarIcon}
                        className="w-3.5 h-3.5 ml-1 inline align-middle text-primary"
                      />
                    </TooltipCmp>
                  )}
                  <TooltipCmp
                    message={val.description}
                    parentClassName="min-w-80 max-w-80"
                  >
                    <InfoCircle className="w-3.5 h-3.5 text-textSecondary ml-1 cursor-pointer inline align-middle" />
                  </TooltipCmp>
                </label>
              ) : (
                <label
                  htmlFor={`service_${val.id}`}
                  className="text-xs leading-4 cursor-pointer font-medium mr-1"
                >
                  {val.name}
                  {dimensionsExtractedData?.selectedServices?.includes(
                    val.id
                  ) && (
                    <TooltipCmp
                      message={'Detected with AI'}
                      parentClassName="min-w-[120px]"
                    >
                      <img
                        src={AiStarIcon}
                        className="w-3.5 h-3.5 inline align-middle text-primary"
                      />
                    </TooltipCmp>
                  )}
                </label>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  // COMMON AND API CALL CODE HERE
  const handleDimensionsSubmit = async (quoteId: any) => {
    if (dimensionsFormData?.length) {
      const formPayload = dimensionsFormData.map((formDataField: any) => {
        formDataField.quoteId = quoteId;

        return formDataField;
      });

      try {
        const createUpdateDimensionResponse = await createUpdateDimension({
          dimensions: formPayload,
        });

        if (createUpdateDimensionResponse) {
          console.log('Calling Submit Service Function');
          await submitService(quoteId);
        }
      } catch (err) {
        setIsCreateUpdateQuoteLoading(false);
        console.log('createUpdateDimension error ', err);
        WalToast.error('Dimensions have not been updated', '');
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    console.log('dimensionsExtractedData ', dimensionsExtractedData);
    const dimensionsFormDataArr: IDimensionFormData[] = [];
    const dimensionsValidFormDataArr: any = [];

    const weightMeasurementVal =
      dimensionsExtractedData?.dimensions?.[0]?.weightMeasurement ===
        MEASUREMENT.WEIGHT1.value ||
      dimensionsExtractedData?.dimensions?.[0]?.weightMeasurement ===
        MEASUREMENT.WEIGHT2.value
        ? dimensionsExtractedData?.dimensions?.[0]?.weightMeasurement
        : MEASUREMENT.WEIGHT1.value;

    dimensionsExtractedData?.dimensions?.forEach((dimItem: any) => {
      dimensionsFormDataArr.push({
        ...initDimensionFormData,
        handlingUnit:
          handlingUnitOptions.length > 0 ? handlingUnitOptions[0].value : '',
        ...dimItem,
        weightMeasurement: weightMeasurementVal,
      });
      dimensionsValidFormDataArr.push(initDimensionValidForm);
    });

    if (dimensionsFormDataArr?.length) {
      setDimensionsFormData(dimensionsFormDataArr);
      setValidDimensionForm(dimensionsValidFormDataArr);
    }
  }, [dimensionsExtractedData, handlingUnitOptions]);

  useEffect(() => {
    console.log('dimensionsFormData ', dimensionsFormData);
  }, [dimensionsFormData]);

  useEffect(() => {
    console.log('formData ', formData);
  }, [formData]);

  const isCurrentStepValid = (currentStepNumber?: number | 'all') => {
    let valid = true;

    const validFormData = {
      customerId: true,
      shipperAddress: true,
      pickupDate: {
        valid: true,
        nextDay: true,
      },
      consigneeAddress: true,
      classification: true,
      urgency: true,
      insuranceCommercialValue: true,
      primaryEquipmentType: true,

      totalWeightMax: true,
      totalWeightMin: true,

      linearFootageMax: true,
      linearFootageMin: true,
    };

    if (currentStepNumber === 1 || currentStepNumber === 'all') {
      if (
        formData.classification === '' ||
        formData.classification === null ||
        formData.classification === undefined
      ) {
        validFormData.classification = false;
        valid = false;
      }

      if (
        formData.urgency === '' ||
        formData.urgency === null ||
        formData.urgency === undefined
      ) {
        validFormData.urgency = false;
        valid = false;
      }

      if (
        formData.customerId === '' ||
        formData.customerId === null ||
        formData.customerId === undefined
      ) {
        validFormData.customerId = false;
        valid = false;
      }

      if (
        formData.pickupDate === '' ||
        formData.pickupDate === null ||
        formData.pickupDate === undefined
      ) {
        validFormData.pickupDate.valid = false;
        valid = false;
      }
    }

    if (currentStepNumber === 2 || currentStepNumber === 'all') {
      if (formData.shipperAddress.fullAddress === undefined) {
        validFormData.shipperAddress = false;
        valid = false;
      }

      if (
        formData.shipperAddress.fullAddress === '' ||
        formData.shipperAddress.fullAddress === null
      ) {
        validFormData.shipperAddress = false;
        valid = false;
      }
    }

    if (currentStepNumber === 3 || currentStepNumber === 'all') {
      if (formData.consigneeAddress.fullAddress === undefined) {
        validFormData.consigneeAddress = false;
        valid = false;
      }

      if (
        formData.consigneeAddress.fullAddress === '' ||
        formData.consigneeAddress.fullAddress === null
      ) {
        validFormData.consigneeAddress = false;
        valid = false;
      }
    }

    if (currentStepNumber === 4 || currentStepNumber === 'all') {
      if (formData.classification === '' || formData.classification === null) {
        validFormData.classification = false;
        valid = false;
      } else if (
        formData?.classification === QUOTE_CLASSIFICATION.FTL ||
        formData?.classification === QUOTE_CLASSIFICATION.LTL
      ) {
        if (formData.totalWeight || formData.totalWeight === 0) {
          if (Number(formData.totalWeight) > 100000) {
            validFormData.totalWeightMax = false;
            valid = false;
          }

          if (Number(formData.totalWeight) <= 0) {
            validFormData.totalWeightMin = false;
            valid = false;
          }
        }

        if (formData.linearFootage || formData.linearFootage === 0) {
          if (Number(formData.linearFootage) > 100) {
            validFormData.linearFootageMax = false;
            valid = false;
          }

          if (Number(formData.linearFootage) <= 0) {
            validFormData.linearFootageMin = false;
            valid = false;
          }
        }
      }

      if (!formData.primaryEquipmentType) {
        validFormData.primaryEquipmentType = false;
        valid = false;
      }
    }

    if (currentStepNumber === 6 || currentStepNumber === 'all') {
      if (
        formData.isInsureLoad &&
        (Number(formData.insuranceCommercialValue) === 0 ||
          formData.insuranceCommercialValue === '' ||
          formData.insuranceCommercialValue === null ||
          formData.insuranceCommercialValue === undefined)
      ) {
        validFormData.insuranceCommercialValue = false;
        valid = false;
      }
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const handleSubmitCreateQuoteRequest = async (event?: any) => {
    event?.preventDefault();

    let quoteAndAddressValidationRes = isCurrentStepValid('all');
    let dimensionValidationResponse = checkIsDimensionFormValid();

    if (
      formData.classification === QUOTE_CLASSIFICATION.LTL &&
      !dimensionValidationResponse
    ) {
      return true;
    }

    if (!quoteAndAddressValidationRes) {
      return true;
    }

    const pickupDateTime = moment(new Date(formData.pickupDate)).format(
      `${DATE_FORMAT} ${TIME_FORMAT}`
    );

    const data: any = {
      customerId: formData.customerId,
      pickupDate: pickupDateTime,
      shipperId: formData.shipperId,
      shipperCompanyName: formData.shipperCompanyName,
      shipperFullAddress: formData.shipperAddress.fullAddress,
      shipperAddress1: formData.shipperAddress.address1,
      shipperCity: formData.shipperAddress.city,
      shipperState: formData.shipperAddress.state,
      shipperCountry: formData.shipperAddress.country,
      shipperPostal: formData.shipperAddress.postal,
      shipperLatitude: formData.shipperAddress.latitude,
      shipperLongitude: formData.shipperAddress.longitude,
      shipperStateCode: formData.shipperAddress.stateCode,
      shipperCountryCode: formData.shipperAddress.countryCode,
      consigneeId: formData.consigneeId,
      consigneeCompanyName: formData.consigneeCompanyName,
      consigneeFullAddress: formData.consigneeAddress.fullAddress,
      consigneeAddress1: formData.consigneeAddress.address1,
      consigneeCity: formData.consigneeAddress.city,
      consigneeState: formData.consigneeAddress.state,
      consigneeCountry: formData.consigneeAddress.country,
      consigneePostal: formData.consigneeAddress.postal,
      consigneeLatitude: formData.consigneeAddress.latitude,
      consigneeLongitude: formData.consigneeAddress.longitude,
      consigneeStateCode: formData.consigneeAddress.stateCode,
      consigneeCountryCode: formData.consigneeAddress.countryCode,

      primaryEquipmentType: formData.primaryEquipmentType?.toString(),
      primaryEquipmentTypeFullName:
        formData.primaryEquipmentTypeFullName?.toString(),
      secondaryEquipmentTypes: formData.secondaryEquipmentTypes?.toString(),
      secondaryEquipmentTypeFullNames:
        formData.secondaryEquipmentTypeFullNames?.toString(),

      linearFootage: formData.linearFootage ?? undefined,
      totalWeight: formData.totalWeight ?? undefined,
      classification: formData.classification,
      urgency: formData.urgency,
      isFromQuoteRequest: true,
      emailContentId: selectedEmail?.emailBody?.id,
      insuranceCurrencyType: formData?.insuranceCurrencyType,
      insuranceCommercialValue: formData?.insuranceCommercialValue || undefined,
    };

    try {
      setIsCreateUpdateQuoteLoading(true);
      const createQuoteResponse = await createQuote(data);

      if (createQuoteResponse) {
        if (data?.classification === 'ltl') {
          await handleDimensionsSubmit(createQuoteResponse.data?.id);
        }
        await handleSummarySubmit(createQuoteResponse?.data?.id);
      }
    } catch (error: any) {
      setIsCreateUpdateQuoteLoading(false);
      const errMsg =
        error?.response?.status === 409
          ? error?.response?.data?.message
          : 'Something went wrong! Please try again';
      WalToast.error(errMsg, '');
    }
  };

  const handleSubmitEditQuoteRequest = async (event?: any) => {
    event?.preventDefault();

    let quoteAndAddressValidationRes = isCurrentStepValid('all');
    let dimensionValidationResponse = checkIsDimensionFormValid();

    if (
      formData.classification === QUOTE_CLASSIFICATION.LTL &&
      !dimensionValidationResponse
    ) {
      return true;
    }

    if (!quoteAndAddressValidationRes) {
      return true;
    }

    const pickupDateTime = moment(new Date(formData.pickupDate)).format(
      `${DATE_FORMAT} ${TIME_FORMAT}`
    );

    const data: any = {
      customerId: formData.customerId,
      pickupDate: pickupDateTime,
      shipperId: formData.shipperId,
      shipperCompanyName: formData.shipperCompanyName,
      shipperFullAddress: formData.shipperAddress.fullAddress,
      shipperAddress1: formData.shipperAddress.address1,
      shipperCity: formData.shipperAddress.city,
      shipperState: formData.shipperAddress.state,
      shipperCountry: formData.shipperAddress.country,
      shipperPostal: formData.shipperAddress.postal,
      shipperLatitude: formData.shipperAddress.latitude,
      shipperLongitude: formData.shipperAddress.longitude,
      shipperStateCode: formData.shipperAddress.stateCode,
      shipperCountryCode: formData.shipperAddress.countryCode,
      consigneeId: formData.consigneeId,
      consigneeCompanyName: formData.consigneeCompanyName,
      consigneeFullAddress: formData.consigneeAddress.fullAddress,
      consigneeAddress1: formData.consigneeAddress.address1,
      consigneeCity: formData.consigneeAddress.city,
      consigneeState: formData.consigneeAddress.state,
      consigneeCountry: formData.consigneeAddress.country,
      consigneePostal: formData.consigneeAddress.postal,
      consigneeLatitude: formData.consigneeAddress.latitude,
      consigneeLongitude: formData.consigneeAddress.longitude,
      consigneeStateCode: formData.consigneeAddress.stateCode,
      consigneeCountryCode: formData.consigneeAddress.countryCode,

      primaryEquipmentType: formData.primaryEquipmentType?.toString(),
      primaryEquipmentTypeFullName:
        formData.primaryEquipmentTypeFullName?.toString(),
      secondaryEquipmentTypes: formData.secondaryEquipmentTypes?.toString(),
      secondaryEquipmentTypeFullNames:
        formData.secondaryEquipmentTypeFullNames?.toString(),

      linearFootage: formData.linearFootage ?? undefined,
      totalWeight: formData.totalWeight ?? undefined,
      classification: formData.classification,
      urgency: formData.urgency,
      isFromQuoteRequest: true,
      emailContentId: selectedEmail?.emailBody?.id,
      insuranceCurrencyType: formData?.insuranceCurrencyType,
      insuranceCommercialValue: formData?.insuranceCommercialValue || undefined,
    };

    try {
      setIsCreateUpdateQuoteLoading(true);
      const updateQuoteResponse = await updateQuote(
        quoteDetail?.addressDetails?.id,
        data
      );

      if (updateQuoteResponse) {
        if (data?.classification === 'ltl') {
          await handleDimensionsSubmit(updateQuoteResponse.data?.id);
        }
        await handleSummarySubmit(updateQuoteResponse?.data?.id);
      }
    } catch (error: any) {
      setIsCreateUpdateQuoteLoading(false);
      const errMsg =
        error?.response?.status === 409
          ? error?.response?.data?.message
          : 'Something went wrong! Please try again';
      WalToast.error(errMsg, '');
    }
  };

  const handleTailgateModalClose = useCallback(
    (status: boolean) => () => {
      setDimensionAction(initDimensionAction);

      if (status) {
        setIsOverWeightAndLengthConfirmed(true);

        setTimeout(() => {
          handleSubmitCreateQuoteRequest();
        }, 0);
      }
    },
    [dimensionAction]
  );

  const handleNext = () => {
    let quoteAndAddressValidationRes = isCurrentStepValid(currentStep);

    if (!quoteAndAddressValidationRes) {
      return true;
    }

    if (
      formData?.classification === QUOTE_CLASSIFICATION.LTL &&
      currentStep === 5 &&
      dimensionsFormData.length > 0
    ) {
      let dimensionValidationResponse = checkIsDimensionFormValid(
        currentDimensionStep - 1
      );

      if (!dimensionValidationResponse) {
        return true;
      }
    }

    if (
      currentStep === 1 &&
      (formData.classification === '' || formData.classification === null)
    ) {
      setValidForm({ ...validForm, classification: true });

      return;
    }

    if (
      currentStep === 5 &&
      formData?.classification === QUOTE_CLASSIFICATION.LTL
    ) {
      if (currentDimensionStep < dimensionsFormData.length) {
        setCurrentDimensionStep((prevValue) => prevValue + 1);
      } else {
        setCurrentStep((prevValue) => prevValue + 1);
      }
    } else if (
      currentStep === 4 &&
      formData?.classification === QUOTE_CLASSIFICATION.LTL
    ) {
      setCurrentStep((prevValue) => prevValue + 1);
      setCurrentDimensionStep(1);
    } else if (
      currentStep === 4 &&
      formData?.classification === QUOTE_CLASSIFICATION.FTL
    ) {
      setCurrentStep((prevValue) => prevValue + 2);
    } else {
      setCurrentStep((prevValue) => prevValue + 1);
    }
  };

  const handlePrev = () => {
    if (
      currentStep === 5 &&
      formData?.classification === QUOTE_CLASSIFICATION.LTL
    ) {
      if (currentDimensionStep > 1) {
        setCurrentDimensionStep((prevValue) => prevValue - 1);
      } else {
        setCurrentStep((prevValue) => prevValue - 1);
      }
    } else if (
      currentStep === 6 &&
      formData?.classification === QUOTE_CLASSIFICATION.LTL
    ) {
      setCurrentStep((prevValue) => prevValue - 1);
      setCurrentDimensionStep(dimensionsFormData.length);
    } else if (
      currentStep === 6 &&
      formData?.classification === QUOTE_CLASSIFICATION.FTL
    ) {
      setCurrentStep((prevValue) => prevValue - 2);
    } else {
      setCurrentStep((prevValue) => prevValue - 1);
    }
  };

  return (
    <>
      <div className="w-[393px] border-l border-utilityGray200 flex flex-col h-full">
        <div className="px-4 py-3.5 border-b border-utilityGray200">
          <h6 className="text-grayLight900 text-base font-semibold">
            {isAiExtractionAndQuoteDetailLoading ? (
              <span className="custom-loading">
                Modify Quote : #SQ-00-0000000
              </span>
            ) : isEdit ? (
              `Modify Quote : #${quoteDetail?.addressDetails?.code || ''}`
            ) : (
              `Create New Quote`
            )}
          </h6>
          <p
            className={`text-grayLight600 text-xs leading-[1.5] font-normal mt-0.5 ${
              isAiExtractionAndQuoteDetailLoading ? 'custom-loading' : ''
            }`}
          >
            Fill out the quote request details below to access quoting tools.
          </p>
        </div>

        {isAiExtractionAndQuoteDetailLoading ? (
          <CreateQuoteSectionLoading />
        ) : (
          <ul
            className={`flex-1 px-4 pt-4 flex flex-col gap-y-4 ${
              currentStep !== 1 && 'overflow-y-auto custom-scrollbar-v2'
            }`}
          >
            {/* Quote main fields section */}
            {currentStep === 1 && (
              <li>
                <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                  Add Order Details
                </h6>
                <div className="rounded-lg border border-utilityGray200 p-4 flex flex-col gap-y-4 bg-gray25">
                  <SelectBox
                    name="serviceTypeGroup"
                    id="serviceTypeGroup"
                    placeholder="Select Service Type"
                    className="form_control shadow"
                    size="sm"
                    options={serviceTypeArr}
                    onChangeFunc={handleSelectChange('classification')}
                    value={
                      serviceTypeArr.find(
                        (st) => st.value === formData?.classification
                      ) || null
                    }
                    label="Service Type"
                    labelClassName="form_label block mb-1.5"
                    inlineSelect={true}
                    errorText={!validForm.classification ? true : false}
                    required
                    isShowAiStar={
                      quoteAndShipperExtractedData?.classification &&
                      formData?.classification ===
                        quoteAndShipperExtractedData?.classification
                    }
                  />

                  {formData?.classification !== 'other' ? (
                    <>
                      <SelectBox
                        name="urgencyTypeGroup"
                        id="urgencyTypeGroup"
                        className="form_control shadow"
                        size="sm"
                        options={urgencyTypeArr}
                        inlineSelect={true}
                        onChangeFunc={handleSelectChange('urgency')}
                        value={
                          urgencyTypeArr.find(
                            (ut) => ut.value === formData?.urgency
                          ) || null
                        }
                        label="Urgency"
                        labelClassName="form_label block mb-1.5"
                        errorText={!validForm.urgency ? true : false}
                        required
                        isShowAiStar={params?.isQuoteEmail}
                      />

                      <SelectBox
                        className="form_control shadow"
                        labelClassName="form_label block mb-1.5"
                        isClearable
                        name="customerId"
                        placeholder="Select a customer"
                        required
                        label="Customer"
                        inlineSelect={true}
                        isSearchable={true}
                        isLoading={isCustomerLoading}
                        options={customerOptions}
                        value={
                          customerOptions.find(
                            (cusOptions: any) =>
                              cusOptions.value === formData.customerId
                          ) || null
                        }
                        onChangeFunc={handleSelectChange('customerId')}
                        errorText={!validForm.customerId ? true : false}
                        isShowAiStar={
                          formData?.customerId ===
                          quoteAndShipperExtractedData?.customerId
                        }
                      />

                      <div>
                        <div className="flex flex-wrap items-center gap-1 mb-1.5 relative">
                          <div className="flex">
                            <label className="form_label mb-0">
                              Pickup Date
                            </label>
                            <span className="text-red-600 ms-[2px] leading-4 text-xs">
                              *
                            </span>
                          </div>
                          <div
                            className={`form_label mb-0 w-max mdm:absolute left-[82px] flex items-center`}
                          >
                            {pickup1pmError && (
                              <>
                                <span className="text-xs text-primary font-normal flex items-center">
                                  {tomorrowDayName === 'Saturday'
                                    ? `Set to Monday`
                                    : 'Set to Tomorrow'}
                                </span>
                                <a
                                  onClick={() => selectNextDay(true)}
                                  className="text-xs cursor-pointer underline decoration-1 ml-1 mr-1 text-primary700 font-semibold"
                                >
                                  Yes
                                </a>
                                &nbsp;
                                {'/'}
                                <a
                                  onClick={() => selectNextDay(false)}
                                  className="text-xs cursor-pointer underline decoration-1 ml-1 text-primary700 font-semibold"
                                >
                                  No
                                </a>
                                <TooltipCmp
                                  message={'Pickup is unlikely after 1pm.'}
                                >
                                  <InfoCircle className="w-3.5 h-3.5 text-textSecondary ml-1 cursor-pointer" />
                                </TooltipCmp>
                              </>
                            )}
                          </div>
                        </div>

                        <DateSelect
                          inputName="pickupDate"
                          className={`form_control ${
                            !validForm.pickupDate.valid
                              ? 'border border-red-500 mb-1 border-solid'
                              : ''
                          }`}
                          placeholder={DATE_FORMAT}
                          dateFormat="dd/MM/yyyy"
                          minDate={moment().toDate()}
                          selected={
                            formData.pickupDate
                              ? moment(formData.pickupDate).toDate()
                              : null
                          }
                          value={getDateWithSuffixFormat(
                            getFormattedPickupDate(formData.pickupDate)
                          )}
                          errorText={''}
                          onChangeFunc={handleDateChange}
                          icon={<Calendar className="h-5 w-5" />}
                          parentClassName={`w-full one-month-datepicker`}
                          isShowAiStar={
                            quoteAndShipperExtractedData?.pickupDate &&
                            moment(formData?.pickupDate).isSame(
                              quoteAndShipperExtractedData?.pickupDate,
                              'day'
                            )
                          }
                        />
                      </div>
                    </>
                  ) : (
                    false
                  )}
                </div>
              </li>
            )}

            {/* Quote shipper details section */}
            {currentStep === 2 && (
              <>
                {formData?.classification !== 'other' ? (
                  <>
                    <li>
                      <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                        Shipper Details
                      </h6>
                      <div className="rounded-lg border border-utilityGray200 p-4 flex flex-col gap-y-4 bg-gray25">
                        <ShipperAddressSection
                          formData={formData}
                          shipperOptions={shipperOptions}
                          validForm={validForm}
                          handleSelectChange={handleSelectChange}
                          selectFormData={formData}
                          handleActionType={handleActionType}
                          saveShipperDetails={saveShipperDetails}
                          isShipperBtnDisable={isShipperBtnDisable}
                          handleAutocompleteChange={handleAutocompleteChange}
                          handleInputChange={handleInputChange}
                          shipperAddress={shipperAddress}
                          setShipperAddress={setShipperAddress}
                          isShipperLoading={isShipperLoading}
                          status={mapApiStatus}
                          isShowAiStarFullAddress={
                            !!shipperAddress &&
                            shipperAddress ===
                              quoteAndShipperExtractedData?.shipperAddress
                                ?.fullAddress
                          }
                        />
                      </div>
                    </li>
                    {formData?.classification === QUOTE_CLASSIFICATION.LTL && (
                      <li>
                        <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                          Select Pickup Additional Services
                        </h6>
                        <div className="rounded-lg border border-utilityGray200 p-4 flex flex-col gap-y-4 bg-gray25">
                          {serviceList.length ? (
                            getList(serviceTypes.PICKUP)
                          ) : (
                            <div className="flex justify-center mt-4">
                              {isServiceLoading ? (
                                <div className="skeleton bg-gray50 rounded-none w-full h-[32px]"></div>
                              ) : (
                                false
                              )}
                            </div>
                          )}
                        </div>
                      </li>
                    )}
                  </>
                ) : (
                  false
                )}
              </>
            )}

            {/* Quote consignee details section */}
            {currentStep === 3 && (
              <>
                {formData?.classification !== 'other' ? (
                  <>
                    <li>
                      <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                        Consignee Details
                      </h6>
                      <div className="rounded-lg border border-utilityGray200 p-4 flex flex-col gap-y-4 bg-gray25">
                        <ConsigneeAddressSection
                          formData={formData}
                          consigneeOptions={consigneeOptions}
                          validForm={validForm}
                          handleSelectChange={handleSelectChange}
                          selectFormData={formData}
                          handleActionType={handleActionType}
                          saveShipperDetails={saveShipperDetails}
                          isConsigneeBtnDisable={isConsigneeBtnDisable}
                          handleAutocompleteChange={handleAutocompleteChange}
                          handleInputChange={handleInputChange}
                          consigneeAddress={consigneeAddress}
                          setConsigneeAddress={setConsigneeAddress}
                          isShipperLoading={isShipperLoading}
                          status={mapApiStatus}
                          isShowAiStarFullAddress={
                            !!consigneeAddress &&
                            consigneeAddress ===
                              quoteAndShipperExtractedData?.consigneeAddress
                                ?.fullAddress
                          }
                        />
                      </div>
                    </li>
                    {formData?.classification === QUOTE_CLASSIFICATION.LTL && (
                      <li>
                        <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                          Select Delivery Additional Services
                        </h6>
                        <div className="rounded-lg border border-utilityGray200 p-4 flex flex-col gap-y-4 bg-gray25">
                          {serviceList.length ? (
                            getList(serviceTypes.DELIVERY)
                          ) : (
                            <div className="flex justify-center mt-4">
                              {isServiceLoading ? (
                                <div className="skeleton bg-gray50 rounded-none w-full h-[32px]"></div>
                              ) : (
                                false
                              )}
                            </div>
                          )}
                        </div>
                      </li>
                    )}
                  </>
                ) : (
                  false
                )}
              </>
            )}

            {/* Dimensions section */}
            {currentStep === 5 && (
              <>
                {formData?.classification === QUOTE_CLASSIFICATION.LTL && (
                  <li>
                    {!!dimensionsFormData?.length && (
                      <div className="flex justify-between">
                        <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                          {`Add Your `}
                          {[
                            'First',
                            'Second',
                            'Third',
                            'Fourth',
                            'Fifth',
                            'Sixth',
                            'Seventh',
                            'Eighth',
                            'Ninth',
                            'Tenth',
                          ][currentDimensionStep - 1] ||
                            `${currentDimensionStep}th`}
                          {` Line Item`}
                        </h6>
                        <h6 className="text-grayLight900 text-xs font-medium mb-1.5">
                          {currentDimensionStep}/{dimensionsFormData?.length}
                        </h6>
                      </div>
                    )}
                    <div className="rounded-lg border border-utilityGray200 p-4 flex flex-col gap-y-1.5 bg-gray25">
                      <div className="flex items-center justify-between mb-1.5">
                        <h6 className="text-grayLight900 text-xs font-medium">
                          Dimensions
                        </h6>
                        <div className="flex items-center gap-2">
                          <Radio
                            id={`measurement_radio_group`}
                            onChangeFunc={(e: any) => {
                              if (e?.target?.checked) {
                                setLengthMeasurement(MEASUREMENT.LENGTH1.value);
                                setWeightMeasurement(MEASUREMENT.WEIGHT1.value);
                              }
                            }}
                            inputName={'measurement_radio_group'}
                            radioLabelClassName={'!normal-case'}
                            labelName={providerArr?.[0]?.label}
                            value={providerArr?.[0]?.value}
                            checked={
                              weightMeasurement === providerArr?.[0]?.value
                            }
                          />
                          <Radio
                            id={`measurement_radio_group`}
                            onChangeFunc={(e: any) => {
                              if (e?.target?.checked) {
                                setLengthMeasurement(MEASUREMENT.LENGTH2.value);
                                setWeightMeasurement(MEASUREMENT.WEIGHT2.value);
                              }
                            }}
                            inputName={'measurement_radio_group'}
                            radioLabelClassName={'!normal-case'}
                            labelName={providerArr?.[1]?.label}
                            value={providerArr?.[1]?.value}
                            checked={
                              weightMeasurement === providerArr?.[1]?.value
                            }
                          />
                        </div>
                      </div>

                      {isHandlingUnitLoading ? (
                        <div className="flex justify-center mt-4">
                          <div className="skeleton bg-gray50 rounded-none w-full h-[32px]"></div>
                        </div>
                      ) : (
                        <DimensionItems
                          dimensionsFormData={dimensionsFormData}
                          validForm={validDimensionForm}
                          initFormData={initDimensionFormData}
                          initValidForm={initDimensionValidForm}
                          handlingUnitOptions={handlingUnitOptions}
                          lengthMeasurement={lengthMeasurement}
                          weightMeasurement={weightMeasurement}
                          setDimensionsFormData={setDimensionsFormData}
                          isHandlingUnitLoading={isHandlingUnitLoading}
                          setValidForm={setValidDimensionForm}
                          dimensionsExtractedData={
                            dimensionsExtractedData?.dimensions
                          }
                          currentDimensionStep={currentDimensionStep - 1}
                          setCurrentDimensionStep={setCurrentDimensionStep}
                          checkIsDimensionFormValid={checkIsDimensionFormValid}
                        />
                      )}
                    </div>
                  </li>
                )}
              </>
            )}

            {/* Quote load details section */}
            {currentStep === 4 && (
              <>
                {formData?.classification === QUOTE_CLASSIFICATION.FTL ||
                formData?.classification === QUOTE_CLASSIFICATION.LTL ? (
                  <li>
                    <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                      Select Load Details
                    </h6>
                    <div className="rounded-lg border border-utilityGray200 p-4 flex flex-col gap-y-4 bg-gray25">
                      {/* Primary Equipment Type */}
                      <SelectBox
                        name="primaryEquipmentType"
                        label="Primary Equipment Type"
                        placeholder="Select a primary equipment type"
                        labelClassName="form_label mb-1.5 block"
                        options={equipmentTypeOptions}
                        inlineSelect={true}
                        value={equipmentTypeOptions.find(
                          (item) => formData.primaryEquipmentType === item.value
                        )}
                        isLoading={isEquipmentTypeLoading}
                        onChangeFunc={(event: any) => {
                          const secondaryEquipmentTypesWithOutPrimary =
                            formData?.secondaryEquipmentTypes?.filter(
                              (set: any) => set !== event?.value
                            );
                          const secondaryEquipmentTypeFullNamesWithOutPrimary =
                            formData?.secondaryEquipmentTypeFullNames?.filter(
                              (setf: any) => setf !== event?.label
                            );
                          setValidForm({
                            ...validForm,
                            primaryEquipmentType: true,
                          });
                          setFormData((old) => ({
                            ...old,
                            primaryEquipmentType: event?.value,
                            primaryEquipmentTypeFullName: event?.label,
                            secondaryEquipmentTypes:
                              secondaryEquipmentTypesWithOutPrimary,
                            secondaryEquipmentTypeFullNames:
                              secondaryEquipmentTypeFullNamesWithOutPrimary,
                          }));
                        }}
                        isShowAiStar={
                          !!formData?.primaryEquipmentType &&
                          !!quoteAndShipperExtractedData?.primaryEquipmentType &&
                          formData?.primaryEquipmentType ===
                            quoteAndShipperExtractedData?.primaryEquipmentType
                        }
                        errorText={
                          !validForm.primaryEquipmentType ? true : false
                        }
                        required={true}
                      />

                      {/* Secondary Equipment Type */}
                      <SelectBox
                        name="secondaryEquipmentTypes"
                        label="Secondary Equipment Types"
                        placeholder="Select secondary equipment types"
                        labelClassName="form_label mb-1.5 block"
                        options={equipmentTypeOptions.filter(
                          (eto) => eto.value !== formData.primaryEquipmentType
                        )}
                        inlineSelect={true}
                        value={equipmentTypeOptions.filter((item) =>
                          formData.secondaryEquipmentTypes?.includes(item.value)
                        )}
                        isLoading={isEquipmentTypeLoading}
                        isMultipleSelection={true}
                        onChangeFunc={(event: any) => {
                          const newValue = event.map((e: any) => e.value);
                          const newLabelValue = event.map((e: any) => e.label);

                          setFormData((old) => ({
                            ...old,
                            secondaryEquipmentTypes: newValue,
                            secondaryEquipmentTypeFullNames: newLabelValue,
                          }));
                        }}
                        isShowAiStar={
                          !!formData?.secondaryEquipmentTypes?.length &&
                          !!quoteAndShipperExtractedData
                            ?.secondaryEquipmentTypes?.length &&
                          areStringArraysEqual(
                            formData?.secondaryEquipmentTypes || [],
                            quoteAndShipperExtractedData?.secondaryEquipmentTypes ||
                              []
                          )
                        }
                      />

                      <InputText
                        inputName="totalWeight"
                        inputType="number"
                        label="Total Weight"
                        placeholder={'Enter total weight of packages'}
                        labelClassName="mb-1.5 block"
                        value={formData.totalWeight}
                        onChangeFunc={(e) =>
                          handleSelectChange('totalWeight')(e?.target)
                        }
                        isShowAiStar={
                          !!formData?.totalWeight &&
                          formData?.totalWeight ==
                            quoteAndShipperExtractedData?.totalWeight
                        }
                        shouldFormatNumber={true}
                        inputValueUnitType="lbs"
                        errorText={
                          !validForm.totalWeightMin
                            ? 'Total weight should not be 0 lbs'
                            : !validForm.totalWeightMax
                            ? 'Total weight should be less than 100,000 lbs'
                            : ''
                        }
                      />

                      {formData?.classification ===
                        QUOTE_CLASSIFICATION.LTL && (
                        <InputText
                          inputName="linearFootage"
                          inputType="number"
                          label="Linear Footage"
                          placeholder={'Enter linear feet'}
                          labelClassName="mb-1.5 block"
                          className={`!pr-20 `}
                          value={formData.linearFootage}
                          onChangeFunc={(e) =>
                            handleSelectChange('linearFootage')(e?.target)
                          }
                          shouldFormatNumber={true}
                          inputValueUnitType="ft"
                          errorText={
                            !validForm.linearFootageMin
                              ? 'Linear footage should not be 0'
                              : !validForm.linearFootageMax
                              ? 'Linear footage should be less than 100 Ft.'
                              : ''
                          }
                        />
                      )}
                    </div>
                  </li>
                ) : (
                  false
                )}
              </>
            )}

            {/* Load insurance section */}
            {currentStep === 6 && (
              <>
                {formData?.classification === QUOTE_CLASSIFICATION.LTL && (
                  <li>
                    <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                      Add Other Additional Services
                    </h6>
                    <div className="rounded-lg border border-utilityGray200 p-4 flex flex-col gap-y-4 bg-gray25">
                      {serviceList.length ? (
                        getList(serviceTypes.OTHER)
                      ) : (
                        <div className="flex justify-center mt-4">
                          {isServiceLoading ? (
                            <div className="skeleton bg-gray50 rounded-none w-full h-[32px]"></div>
                          ) : (
                            false
                          )}
                        </div>
                      )}
                    </div>
                  </li>
                )}

                {formData?.classification === QUOTE_CLASSIFICATION.FTL ||
                formData?.classification === QUOTE_CLASSIFICATION.LTL ? (
                  <li>
                    <div className="rounded-lg border border-utilityGray200 p-4 flex flex-col gap-y-4 bg-gray25">
                      <Toggle
                        isChecked={formData?.isInsureLoad}
                        labelPosition="left"
                        label="Would you like to insure this load?"
                        labelTextClassName="text-textSecondary text-sm"
                        labelClassName="justify-between"
                        onChange={(e: any) =>
                          handleSelectChange('isInsureLoad')(e?.target)
                        }
                      />

                      {formData?.isInsureLoad && (
                        <div className="w-full">
                          <label className="form_label mb-1.5 block">
                            Commercial Value
                            <span className="text-red-600 leading-4">*</span>
                          </label>
                          <div
                            className={`form_control p-0 relative !h-auto ${
                              !validForm.insuranceCommercialValue
                                ? 'border-[#f04438] '
                                : ''
                            }`}
                          >
                            <InputText
                              inputName="insuranceCommercialValue"
                              inputType="number"
                              className={`!h-auto pl-8 !pr-20 border-0`}
                              placeholder={'Enter a commercial value'}
                              value={formData.insuranceCommercialValue}
                              required={true}
                              icon={
                                <CurrencyDollar className="input-currency-sign" />
                              }
                              onChangeFunc={(e) =>
                                handleSelectChange('insuranceCommercialValue')(
                                  e?.target
                                )
                              }
                              shouldFormatNumber={true}
                            />

                            <SelectBox
                              name="insuranceCurrencyType"
                              labelClassName="form_label block mb-1.5"
                              parentClassName="absolute right-px top-px w-[80px] dropdown-within-input"
                              id="insuranceCurrencyType"
                              classComp="rounded-none rounded-r-lg"
                              size="sm"
                              options={currencyTypeOptions}
                              onChangeFunc={handleSelectChange(
                                'insuranceCurrencyType'
                              )}
                              value={currencyTypeOptions.filter(
                                (val: any) =>
                                  formData.insuranceCurrencyType === val.value
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                ) : (
                  false
                )}
              </>
            )}

            {/* Add contact section */}
            {currentStep === 7 && (
              <>
                <li className="flex-1 flex flex-col">
                  <h6 className="text-grayLight900 text-sm font-medium mb-1.5">
                    Add Order Details
                  </h6>
                  <div className="flex-1">
                    <div className="rounded-lg border border-utilityGray200 p-4 flex flex-col gap-y-4 bg-gray25">
                      <div className="w-[100px] h-[100px] rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[45px] mx-auto">
                        AC
                      </div>
                      <div className="flex justify-center gap-x-2 py-3">
                        <img src={AiStarIcon} />
                        <h6 className="line-clamp-1">Maybe: Angela Campbell</h6>
                      </div>
                      <InputText
                        label={'Customer'}
                        labelClassName="mb-1.5 block"
                        placeholder={'Enter customer'}
                      />
                      <InputText
                        label={'Name'}
                        labelClassName="mb-1.5 block"
                        placeholder={'Enter contact Name'}
                      />
                      <InputText
                        label={'Role'}
                        labelClassName="mb-1.5 block"
                        placeholder={'Enter contact role'}
                      />
                      <div className="">
                        <label className="form_label block mb-1.5">
                          Phone number
                        </label>
                        <PhoneInput
                          name="phone"
                          phoneInputParentClassName={``}
                          onNumberChange={() => {}}
                          value={'phone number'}
                          errors={''}
                        />
                      </div>
                      <InputText
                        label={'Email'}
                        labelClassName="mb-1.5 block"
                        placeholder={'Enter email'}
                      />
                    </div>
                  </div>
                  <div className="ai-gradient-border border btn-outline-primary mt-4">
                    <ButtonCmp className="btn-outline-primary border-0 w-full ">
                      Save as Contact
                    </ButtonCmp>
                  </div>
                </li>
                <li className="flex-1 flex flex-col">
                  <NotFoundUI
                    title="Contact Saved"
                    desc=""
                    titleClassName="bg-white !text-base"
                    containerClassName="my-0 flex-1"
                    contentClassName="before:!bg-[url('../../assets/img/not-found-images/coming-soon.png')] min-w-full"
                    iconClassName="!hidden"
                    iconSecondaryClassName="!flex"
                    iconSecondary={
                      <CheckCircle className="w-7 h-7 text-fgSuccessPrimary" />
                    }
                  />
                </li>
              </>
            )}
          </ul>
        )}

        {formData?.classification === QUOTE_CLASSIFICATION.FTL ||
        formData?.classification === QUOTE_CLASSIFICATION.LTL ? (
          <div className="w-full gap-2 flex px-4 pb-4 pt-6">
            <div
              className={`h-[5px] rounded-[30px] flex-grow ${
                currentStep >= 1 ? 'bg-primary' : 'bg-utilityGray200'
              }`}
            />
            <div
              className={`h-[5px] rounded-[30px] flex-grow ${
                currentStep >= 2 ? 'bg-primary' : 'bg-utilityGray200'
              }`}
            />
            <div
              className={`h-[5px] rounded-[30px] flex-grow ${
                currentStep >= 3 ? 'bg-primary' : 'bg-utilityGray200'
              }`}
            />
            <div
              className={`h-[5px] rounded-[30px] flex-grow ${
                currentStep >= 4 ? 'bg-primary' : 'bg-utilityGray200'
              }`}
            />
            {formData?.classification === QUOTE_CLASSIFICATION.LTL && (
              <div
                className={`h-[5px] rounded-[30px] flex-grow ${
                  currentStep >= 5 ? 'bg-primary' : 'bg-utilityGray200'
                }`}
              />
            )}
            <div
              className={`h-[5px] rounded-[30px] flex-grow ${
                currentStep >= 6 ? 'bg-primary' : 'bg-utilityGray200'
              }`}
            />
            <div
              className={`h-[5px] rounded-[30px] flex-grow ${
                currentStep >= 7 ? 'bg-primary' : 'bg-utilityGray200'
              }`}
            />
          </div>
        ) : (
          false
        )}

        <div className="px-4 py-3 border-t border-utilityGray200 flex gap-x-2">
          {formData?.classification === 'other' ? (
            <ButtonCmp
              icon={<Plus className="w-4 h-4" />}
              className="btn-outline-primary w-full"
              onClick={handleDiscardEmail}
              loading={isCreateUpdateQuoteLoading}
              disabled={
                isCreateUpdateQuoteLoading ||
                isAiExtractionAndQuoteDetailLoading
              }
            >
              Next
            </ButtonCmp>
          ) : (
            <>
              <ButtonCmp
                className="btn_secondary_black flex-1"
                onClick={handlePrev}
                disabled={
                  isCreateUpdateQuoteLoading ||
                  currentStep === 1 ||
                  isAiExtractionAndQuoteDetailLoading
                }
              >
                Prev
              </ButtonCmp>

              <ButtonCmp
                className="btn-outline-primary flex-1"
                onClick={(e) =>
                  currentStep === 7
                    ? isEdit
                      ? handleSubmitEditQuoteRequest(e)
                      : handleSubmitCreateQuoteRequest(e)
                    : handleNext()
                }
                loading={isCreateUpdateQuoteLoading}
                disabled={
                  isCreateUpdateQuoteLoading ||
                  isAiExtractionAndQuoteDetailLoading
                }
              >
                {currentStep === 7 ? (isEdit ? 'Update' : 'Create') : 'Next'}
              </ButtonCmp>
            </>
          )}
        </div>
      </div>

      <Address
        isOpen={
          action.mode === 'addressCreate' || action.mode === 'addressUpdate'
        }
        mode={action.mode}
        handleClose={handleDrawerClose}
        setAddressData={handleAddressSelection}
        addressType={action}
        addressData={addressData}
        status={mapApiStatus}
      />

      {isInbondMoreModalOpen && (
        <InBondDetailModal
          inbondContactMethodList={inbondContactMethodList}
          initServiceValidForm={initServiceValidForm}
          setValidServicesForm={setValidServicesForm}
          setIsInbondMore={setIsInbondMore}
          setIsInbondMoreModalOpen={setIsInbondMoreModalOpen}
          isInBondFormValid={isInBondFormValid}
          inbondTypeList={inbondTypeList}
          handleChangeRadio={handleChangeRadio}
          validServicesForm={validServicesForm}
          inbondType={inbondType}
          inbondName={inbondName}
          inbondAddress={inbondAddress}
          setInbondName={setInbondName}
          setInbondAddress={setInbondAddress}
          inbondContactMethod={inbondContactMethod}
          getName={getName}
          inbondContactValue={inbondContactValue}
          setInbondContactValue={setInbondContactValue}
        />
      )}

      {isDangerousMoreModalOpen && (
        <DangerousGoodsModal
          dangerTypeList={dangerTypeList}
          initServiceValidForm={initServiceValidForm}
          setValidServicesForm={setValidServicesForm}
          setUnitedNationsNumber={setUnitedNationsNumber}
          setGoodsClass={setGoodsClass}
          setDescription={setDescription}
          setEmergencyContactName={setEmergencyContactName}
          setEmergencyContactNumber={setEmergencyContactNumber}
          setEmergencyContactExt={setEmergencyContactExt}
          setIsDangerousMore={setIsDangerousMore}
          setIsDangerousMoreModalOpen={setIsDangerousMoreModalOpen}
          emergencyContactNumber={emergencyContactNumber}
          validServicesForm={validServicesForm}
          handleChangeRadio={handleChangeRadio}
          dangerType={dangerType}
          unitedNationsNumber={unitedNationsNumber}
          packagingGroupOptions={packagingGroupOptions}
          setPackagingGroup={setPackagingGroup}
          packagingGroup={packagingGroup}
          goodsClass={goodsClass}
          description={description}
          emergencyContactName={emergencyContactName}
          emergencyContactExt={emergencyContactExt}
          isDangerousMore={isDangerousMore}
        />
      )}

      {isAccessTypeModalOpen && (
        <LimitedAccessDeliveryModal
          setLimitedAccessSearch={setLimitedAccessSearch}
          initServiceValidForm={initServiceValidForm}
          setValidServicesForm={setValidServicesForm}
          setIsAccessTypeModalOpen={setIsAccessTypeModalOpen}
          limitedAccessSearch={limitedAccessSearch}
          limitedAccessTypeList={limitedAccessTypeList}
          handleChangeRadio={handleChangeRadio}
          limitedAccessType={limitedAccessType}
          validServicesForm={validServicesForm}
          limitedAccessOther={limitedAccessOther}
          setLimitedAccessOther={setLimitedAccessOther}
        />
      )}

      {dimensionAction.tailgate && (
        <ConfirmModal
          title="Tailgate Over Dimensions"
          description={`Tailgate is not offered for shipments that are over ${FOOT} feet in length and/or have a total weight ${
            weightMeasurement === MEASUREMENT.WEIGHT1.value
              ? `${LBS.MAX_WEIGHT}lbs`
              : `${KGS.MAX_WEIGHT}kgs`
          }. This may result in some carriers not quoting this load. Are you sure you want to proceed`}
          button1="Yes, I am sure"
          button2="Modify Selection"
          handleClose={handleTailgateModalClose}
        />
      )}

      {action.mode === 'delete' && (
        <DeleteModal
          handleClose={handleModalClose}
          moduleTitle={`Remove ${
            action.consignee
              ? formData.consigneeCompanyName
              : formData.shipperCompanyName
          } From Wal HQ `}
        />
      )}
    </>
  );
};

export default CreateQuoteFromMailSection;
