import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

// TODO : CLEAN UP
// export const uploadFile = async (params: any, config: any) => {
//   const response = await axiosInterceptor.post(
//     API.SALES_DASHBOARD.UPLOAD,
//     params,
//     config
//   );

//   return response;
// };

// TODO : CLEAN UP
// export const uploadFileList = async (params: any) => {
//   const response = await axiosInterceptor.get(API.SALES_DASHBOARD.UPLOAD_LIST, {
//     params,
//   });

//   return response;
// };

// TODO : CLEAN UP
// export const deleteData = async (id: any) => {
//   const response = await axiosInterceptor.delete(
//     `${API.SALES_DASHBOARD.DELETE_DATA}/${id}`
//   );

//   return response;
// };

export const getSalesData = async (
  params: any,
  signal: any,
  isUseCache = true
) => {
  const cachedHeaders = {
    'Cache-Control': 'max-age=60,must-revalidate,public',
  };
  const noCacheHeaders = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  };
  const response = await axiosInterceptor.get(API.SALES_DASHBOARD.SALES_DATA, {
    params,
    signal,
    headers: isUseCache ? cachedHeaders : noCacheHeaders,
  });

  return response;
};

export const getSalesAnalyticsData = async (
  params: any,
  signal: any,
  isUseCache = true
) => {
  const cachedHeaders = {
    'Cache-Control': 'max-age=60,must-revalidate,public',
  };
  const noCacheHeaders = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  };

  const response = await axiosInterceptor.get(
    API.SALES_DASHBOARD.SALES_ANALYTICS_DATA,
    {
      params,
      signal,
      headers: isUseCache ? cachedHeaders : noCacheHeaders,
    }
  );

  return response;
};

export const getTotalGrossProfitChartData = async (
  params: any,
  signal: any,
  isUseCache = true
) => {
  const cachedHeaders = {
    'Cache-Control': 'max-age=60,must-revalidate,public',
  };
  const noCacheHeaders = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  };
  const response = await axiosInterceptor.get(
    API.SALES_DASHBOARD.TOTAL_GROSS_PROFIT_CHART,
    {
      params,
      signal,
      headers: isUseCache ? cachedHeaders : noCacheHeaders,
    }
  );

  return response;
};

export const getTeamPerformanceList = async (
  params: any,
  signal: any,
  isUseCache = true
) => {
  const cachedHeaders = {
    'Cache-Control': 'max-age=60,must-revalidate,public',
  };
  const noCacheHeaders = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  };
  const response = await axiosInterceptor.get(
    API.SALES_DASHBOARD.TEAM_PERFORMANCE_LIST,
    {
      params,
      signal,
      headers: isUseCache ? cachedHeaders : noCacheHeaders,
    }
  );

  return response;
};

export const getIndividualGraph = async (params: any, signal: any) => {
  const response = await axiosInterceptor.get(
    API.SALES_DASHBOARD.INDIVIDUAL_GRAPH,
    { params, signal }
  );

  return response;
};

export const getTeamList = async (params: any) => {
  const response = await axiosInterceptor.get(API.SALES_DASHBOARD.TEAM_LIST, {
    params,
  });

  return response;
};

// TODO : CLEAN UP
// export const downloadFile = async (fileName: any) => {
//   const response = await axiosInterceptor.get(
//     API.SALES_DASHBOARD.DOWNLOAD_FILE,
//     {
//       responseType: 'blob',
//       params: {
//         file: fileName,
//       },
//     }
//   );

//   return response;
// };

// TODO : CLEAN UP
// export const confirmUpload = async (id: any) => {
//   const response = await axiosInterceptor.patch(
//     `${API.SALES_DASHBOARD.CONFIRM_UPLOAD}/${id}`,
//     {}
//   );

//   return response;
// };

// TODO : CLEAN UP
// export const sendDownloadOTP = async (params: any) => {
//   const response = await axiosInterceptor.post(
//     API.SALES_DASHBOARD.SEND_DOWNLOAD_OTP,
//     params
//   );

//   return response;
// };

// TODO : CLEAN UP
// export const verifyDownloadOTP = async (params: any) => {
//   const response = await axiosInterceptor.post(
//     API.SALES_DASHBOARD.VERIFY_DOWNLOAD_OTP,
//     params
//   );

//   return response;
// };

export const lastSync = async () => {
  const response = await axiosInterceptor.get(
    API.SALES_DASHBOARD.LAST_SYNC,
    {}
  );

  return response;
};

// TODO : CLEAN UP
// export const checkPendingUpload = async () => {
//   const response = await axiosInterceptor.get(
//     API.SALES_DASHBOARD.CHECK_PENDING_UPLOAD,
//     {}
//   );

//   return response;
// };

// TODO : CLEAN UP
// export const assignSalesReps = async (params: any) => {
//   const response = await axiosInterceptor.post(
//     API.SALES_DASHBOARD.ASSIGN_SALES_REPS,
//     params
//   );

//   return response;
// };

export const getLeaderBoardNewCustomerList = async (
  params: any,
  signal?: any
) => {
  const response = await axiosInterceptor.get(
    API.SALES_DASHBOARD.LEADERBOARD_RANKING_NEW_CUSTOMERS,
    {
      params,
      signal,
    }
  );

  return response;
};

export const getLeaderBoardMyNewCustomerList = async (
  params: any,
  signal?: any
) => {
  const response = await axiosInterceptor.get(
    API.SALES_DASHBOARD.LEADERBOARD_RANKING_MY_NEW_CUSTOMERS,
    {
      params,
      signal,
    }
  );

  return response;
};

export const changeLeaderBoardNewCustomerStatus = async (
  params: any,
  signal?: any
) => {
  const response = await axiosInterceptor.post(
    API.SALES_DASHBOARD.LEADERBOARD_RANKING_NEW_CUSTOMERS_CHANGE_STATUS,
    params,
    {
      signal,
    }
  );

  return response;
};

export const getLeaderBoardData = async (params: any, signal: any) => {
  const response = await axiosInterceptor.get(
    API.SALES_DASHBOARD.LEADERBOARD_RANKING,
    {
      params,
      signal,
    }
  );

  return response;
};

export const fetchOngoingEventDetail = async (params: any, signal: any) => {
  const response = await axiosInterceptor.get(
    API.SALES_DASHBOARD.ONGOING_EVENT_DETAIL,
    {
      params,
      signal,
    }
  );

  return response;
};

export const fetchLeaderBoardActivities = async (params: any, signal: any) => {
  const response = await axiosInterceptor.get(
    API.SALES_DASHBOARD.LEADERBOARD_ACTIVITIES,
    {
      params,
      signal,
    }
  );

  return response;
};
