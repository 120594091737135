import { ChevronDown, XClose } from '@untitled-ui/icons-react/build/cjs';
import mapboxgl from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import { TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { getRadarOrderList } from 'src/services/OrderService';
import { getRadarOrderDetail } from 'src/services/OrderService';
import {
  formatAddress,
  formatLastUpdate,
  GetAppearance,
  getFormattedDate,
  isValidJSON,
  lastLocationUpdateToShowDotColor,
} from 'src/utils/CommonFunctions';

import LeftSidebar from './LeftSidebar';
// import OrderDetail from './OrderDetail';
import RadarOrderDetail from './RadarOrderDetail';
import RadarMapAboveComponent from './RadarOrderDetail/RadarMapAboveComponent';

const initParams = {
  search: '',
  sortType: 'asc',
  sortField: 'createdAt',
  filter: 'booked',
};

const Radar = () => {
  const navigate = useNavigate();
  const storedParamsString = localStorage.getItem(TABLE_IDS.RADAR_LIST);
  const storedParams = isValidJSON(storedParamsString)
    ? JSON.parse(storedParamsString!)
    : undefined;

  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const [orders, setOrders] = useState([]);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [params, setParams] = useState(
    storedParams && Object.keys(storedParams).length > 0
      ? storedParams
      : initParams
  );
  const [orderId, setOrderId] = useState<any>('');
  const [order, setOrder] = useState<any>(null);
  const [tabCount, setTabCount] = useState<any>(null);
  const [orderMarkersPin, SetOrderMarkersPin] = useState<any>([]);
  const abortControllerRef = useRef<AbortController | null>(null);
  const isAppearance = GetAppearance();
  const [isLoading, setIsLoading] = useState(true);
  const initializeMap = useCallback(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [-90, 50],
        zoom: 3,
        pitch: 0,
        bearing: 0,
        boxZoom: true,
        antialias: true,
        projection: 'mercator',
        dragRotate: false,
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl());
    }

    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, []);

  const geocodeAddress = async (
    address: string
  ): Promise<[number, number, string]> => {
    const query = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${mapboxgl.accessToken}`
    );
    const json = await query.json();

    if (!json.features || json.features.length === 0) {
      throw new Error(`Unable to geocode address: ${address}`);
    }

    const coordinates = json.features[0].center as [number, number];
    const placeId = json.features[0].id as string;

    return [coordinates[0], coordinates[1], placeId];
  };

  const removeAllMarkersFromDOM = () => {
    const markerElements = document.querySelectorAll('.mapboxgl-marker');
    markerElements.forEach((marker) => marker.remove());
    const popUpElements = document.querySelectorAll('.mapboxgl-popup');
    popUpElements.forEach((popUp) => popUp.remove());
  };

  const removeAllMarkers = () => {
    if (mapRef.current?.getLayer('route-line')) {
      mapRef.current?.removeLayer('route-line');
    }

    if (mapRef.current?.getSource('route')) {
      mapRef.current?.removeSource('route');
    }

    for (let i = 0; i < orderMarkersPin.length; i++) {
      const marker = orderMarkersPin[i];
      marker.remove();
    }
    removeAllMarkersFromDOM();
    SetOrderMarkersPin([]);
  };

  const locationPopUp = (orderObjItems: any, isHovered: boolean = false) => {
    const popupContainer = document.createElement('div');

    // Create the ReactPopupContent component
    const ReactPopupContent = () => {
      if (isHovered) {
        return (
          <div
            className="rounded-lg border border-gray800 bg-primarySolid p-3"
            onClick={() => {
              if (orderObjItems?.orderTableId) {
                setOrderId(orderObjItems?.orderTableId);
              }
            }}
          >
            <div className="flex gap-x-2 justify-between items-start">
              {/* <a
                href={`https://wal.roserocket.com/#/ops/orders/${orderObjItems?.orderId}`}
                className={`text-white text-sm font-medium underline flex-none ${
                  isOrdersLoading ? 'custom-loading' : ''
                }`}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                #{orderObjItems?.orderId}
              </a> */}
              <p
                className="text-white font-normal leading-tight truncate underline cursor-pointer"
                onClick={() => {
                  if (orderObjItems?.orderTableId) {
                    navigate(
                      `${PATH.ORDER_VIEW}/${orderObjItems?.orderTableId}`
                    );
                  }
                }}
              >
                {orderObjItems?.orderId ? `#${orderObjItems?.orderId}` : ''}
              </p>
              <div className="flex gap-x-2.5 gap-y-1 justify-end flex-wrap">
                {orderObjItems?.orderType && (
                  <BadgeCmp
                    style="modern"
                    type="success"
                    badgeTextColor="!text-utilityGray600"
                    mainClassName="!border-utilityBlack900"
                  >
                    {orderObjItems?.orderType?.toUpperCase()}
                  </BadgeCmp>
                )}
                {orderObjItems?.orderStatus && (
                  <BadgeCmp
                    style="modern"
                    type="primary"
                    badgeTextColor="!text-utilityGray600"
                    mainClassName="!border-utilityBlack900"
                  >
                    {orderObjItems?.orderStatus}
                  </BadgeCmp>
                )}
              </div>
            </div>

            <StatusDotProfileCmp
              parentClassName="mt-1.5 "
              statusLabelClassName="!text-white"
              dotColor={
                params.filter === 'booked'
                  ? 'primary'
                  : (lastLocationUpdateToShowDotColor(
                      orderObjItems?.orderLocationUpdate
                        ? orderObjItems?.orderLocationUpdate?.updatedAt
                        : orderObjItems?.createdAt
                    ) as 'primary' | 'red' | 'yellow' | 'green')
              }
              label={
                <>
                  {params.filter === 'booked'
                    ? 'Pickup Appointment:'
                    : 'Last Updated:'}
                  &nbsp;
                  <span>
                    {params.filter === 'booked'
                      ? getFormattedDate(
                          orderObjItems?.activeLegShipperAppointmentDatetime
                            ? orderObjItems.activeLegShipperAppointmentDatetime
                            : orderObjItems?.firstLegExpectedPickupTime,
                          `Do MMM YYYY`,
                          orderObjItems?.activeLegShipperAppointmentDatetime
                            ? true
                            : false,
                          true,
                          isAppearance
                        )
                      : formatLastUpdate(
                          orderObjItems?.orderLocationUpdate
                            ? orderObjItems?.orderLocationUpdate?.updatedAt
                            : orderObjItems?.createdAt
                        )}
                  </span>
                </>
              }
            />

            <ul className="mt-3.5 p-2.5 rounded-lg bg-white bg-opacity-10">
              <li
                key="0"
                className="relative flex items-start gap-x-2 pb-5 last:pb-0 overflow-hidden [&:last-child>div:after]:hidden "
              >
                <div className="mt-1 after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[13px] after:absolute flex items-center justify-center after:bg-[#85888E]">
                  <div className="w-2.5 h-2.5 border-2 border-[#85888E] rounded-full"></div>
                </div>
                <div className="flex-1 text-xs text-utilityGray700 font-normal">
                  <div className="text-white800 font-medium">
                    {orderObjItems?.shipperCompanyName ?? ''}
                  </div>

                  <div>
                    {orderObjItems?.shipperFullAddress
                      ? formatAddress(orderObjItems?.shipperFullAddress)
                      : ''}
                  </div>
                </div>
              </li>
              <li
                key="1"
                className="relative flex items-start gap-x-2 pb-2 last:pb-0 mb-1 last:mb-0 [&:last-child>div:after]:hidden"
              >
                <div>
                  <ChevronDown className="w-[18px] text-[#85888E] -mt-[13px] -ml-1" />
                </div>
                <div className="flex-1 text-xs text-utilityGray700 font-normal -mt-2.5 -ml-1">
                  <div className="text-white800 font-medium">
                    {orderObjItems?.consigneeCompanyName ?? ''}
                  </div>

                  <div>
                    {orderObjItems?.consigneeFullAddress
                      ? formatAddress(orderObjItems?.consigneeFullAddress)
                      : ''}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        );
      } else {
        return (
          <div className="relative mapbox-tooltip-dark">
            {orderObjItems?.orderId ? `#${orderObjItems?.orderId}` : ''}
          </div>
        );
      }
    };

    // Create a root for the React component and render it
    const root = createRoot(popupContainer);
    root.render(<ReactPopupContent />);

    return popupContainer;
  };

  const setOrderMarker = async () => {
    removeAllMarkers();

    let orderMarkers: mapboxgl.Marker[] = [];

    for (let i = 0; i < orders.length; i++) {
      const orderObj: any = orders[i];
      const cityCoords = await geocodeAddress(orderObj.shipperFullAddress);

      if (!cityCoords || cityCoords.length < 2) {
        console.warn(`Could not find coordinates for address`);
        continue;
      }

      // Create marker container
      const parentDiv = document.createElement('div');
      parentDiv.className = 'parent-marker';
      parentDiv.id = orderObj.id || '';

      // Create marker element
      const el = document.createElement('div');
      el.className = 'marker';
      el.id = orderObj.id || '';

      // Create customer image container
      const customerImageDiv = document.createElement('div');
      customerImageDiv.className = 'parcel-arrow';
      customerImageDiv.id = orderObj.orderId;
      customerImageDiv.innerHTML = `
        <div class="w-[22px] -mt-0.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" class="w-full">
            <path d="M12.6657 2.67916C12.2439 2.4686 11.7476 2.4686 11.3258 2.67916C10.9525 2.86552 10.7595 3.19367 10.6676 3.36083C10.5675 3.543 10.4649 3.77692 10.362 4.01162L3.05073 20.6813C2.93566 20.9436 2.82421 21.1976 2.7529 21.4084C2.69129 21.5905 2.56638 21.9837 2.70011 22.4066C2.84758 22.873 3.21319 23.2382 3.67975 23.3851C4.10282 23.5184 4.49584 23.393 4.67791 23.3312C4.88863 23.2596 5.14253 23.1478 5.4047 23.0324L11.9957 20.1324L18.5868 23.0325C18.8489 23.1478 19.1028 23.2596 19.3135 23.3312C19.4956 23.393 19.8886 23.5184 20.3117 23.3851C20.7783 23.2382 21.1439 22.873 21.2913 22.4066C21.4251 21.9837 21.3002 21.5905 21.2385 21.4084C21.1672 21.1976 21.0558 20.9436 20.9407 20.6813L13.6295 4.01167C13.5266 3.77695 13.424 3.54301 13.3238 3.36083C13.2319 3.19367 13.039 2.86552 12.6657 2.67916Z" fill="white"/>
          </svg>
        </div>`;

      parentDiv.appendChild(customerImageDiv);

      // Create and configure the Mapbox marker
      const marker = new mapboxgl.Marker(parentDiv)
        .setLngLat([cityCoords[0], cityCoords[1]])
        .addTo(mapRef.current!);
      let locationPopUpHover = false;
      const popup: any = new mapboxgl.Popup({
        offset: 25,
        closeButton: false,
        closeOnClick: false,
        maxWidth: '320px',
        // focusAfterOpen: true,
      }).setDOMContent(locationPopUp(orderObj));
      marker.setPopup(popup);
      popup.addTo(mapRef.current!);
      popup?.getElement()?.addEventListener('mouseenter', () => {
        if (!locationPopUpHover) {
          popup.setDOMContent(locationPopUp(orderObj, true));
          popup.getElement().style.zIndex = '1';
        }
        locationPopUpHover = true;
      });
      popup?.getElement()?.addEventListener('mouseleave', () => {
        if (locationPopUpHover) {
          popup.setDOMContent(locationPopUp(orderObj, false));
          popup.getElement().style.zIndex = '0';
        }
        locationPopUpHover = false;
      });

      // Add click event listener to the marker
      marker.getElement().addEventListener('click', () => {
        setOrderId(orderObj.orderTableId);
      });

      orderMarkers.push(marker);

      // Center the map and adjust zoom after placing the last marker
      if (i === orders.length - 1) {
        mapRef?.current?.setCenter([cityCoords[0], cityCoords[1]]);
        mapRef?.current?.setZoom(2);
      }
    }

    if (orders?.length === 0) {
      mapRef?.current?.setCenter([-90, 50]);
      mapRef?.current?.setZoom(3);
    }

    SetOrderMarkersPin(orderMarkers);
  };

  const getDrivingRouteCoordinates = async (
    startPoint: { lat: number; lng: number } = { lat: 10, lng: 10 },
    endPoint: { lat: number; lng: number } = { lat: 10, lng: 10 }
  ) => {
    try {
      // Cancel the previous request if it exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new abort controller for the current request
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      const response = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${startPoint?.lng},${startPoint?.lat};${endPoint?.lng},${endPoint?.lat}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
        { method: 'GET', signal }
      );
      const data = await response.json();

      if (!data.routes || data.routes.length === 0) {
        throw new Error('No route found');
      }

      const routeCoordinates = data.routes[0].geometry.coordinates;

      return routeCoordinates;
    } catch (error) {
      console.error('Error fetching route coordinates:', error);

      return [];
    }
  };

  const getOrderLegCoordinates = async (orderLegs: any) => {
    const coordinates: any = [];

    for (let i = 0; i < orderLegs?.length; i++) {
      const orderLeg: any = orderLegs[i];
      const routeCoordinates = await getDrivingRouteCoordinates(
        {
          lat: orderLeg.shipperLatitude,
          lng: orderLeg.shipperLongitude,
        },
        {
          lat: orderLeg.consigneeLatitude,
          lng: orderLeg.consigneeLongitude,
        }
      );

      if (routeCoordinates.length > 0) {
        coordinates.push(...routeCoordinates); // Merge route coordinates
      }
    }

    return coordinates;
  };

  // Function to calculate the angle between two points
  // const calculateAngle = (
  //   pointALat: number,
  //   pointALng: number,
  //   pointBLat: number,
  //   pointBLng: number
  // ) => {
  //   const angle =
  //     (Math.atan2(pointBLat - pointALat, pointBLng - pointALng) * 180) /
  //     Math.PI;
  //   console.log('angle', angle);

  //   return angle;
  // };

  const createSVGMarker = () => {
    const svgElement: any = document.createElement('div');
    svgElement.innerHTML = `
      <svg width="40" height="40" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.5" cx="58.5848" cy="58.5848" r="47.1" transform="rotate(-75.3409 58.5848 58.5848)" stroke="#079455" stroke-width="1.8"/>
        <path d="M50.4894 29.0752C66.7871 24.6041 83.6236 34.1915 88.0946 50.4893C92.5657 66.7871 82.9783 83.6235 66.6805 88.0946C50.3828 92.5656 33.5463 82.9782 29.0753 66.6804C24.6042 50.3827 34.1916 33.5462 50.4894 29.0752Z" fill="#079455"/>
        <path d="M50.4894 29.0752C66.7871 24.6041 83.6236 34.1915 88.0946 50.4893C92.5657 66.7871 82.9783 83.6235 66.6805 88.0946C50.3828 92.5656 33.5463 82.9782 29.0753 66.6804C24.6042 50.3827 34.1916 33.5462 50.4894 29.0752Z" stroke="white" stroke-width="1.2"/>
        <path d="M41.2494 51.7883C40.6049 50.995 40.2827 50.5984 40.2939 50.2976C40.3037 50.0369 40.4399 49.7974 40.659 49.6557C40.9117 49.4923 41.4173 49.5664 42.4285 49.7147L71.1267 53.9224C72.0395 54.0562 72.496 54.1232 72.6892 54.3388C72.857 54.526 72.927 54.7812 72.8783 55.0279C72.8221 55.3119 72.4637 55.6024 71.7469 56.1833L49.2131 74.4457C48.4191 75.0893 48.0221 75.411 47.7214 75.3994C47.4607 75.3894 47.2213 75.2529 47.0799 75.0336C46.9168 74.7807 46.9915 74.2752 47.141 73.2641L48.8375 61.7872C48.8678 61.582 48.883 61.4793 48.8728 61.3799C48.8638 61.2917 48.8402 61.2056 48.803 61.1252C48.761 61.0344 48.6955 60.9539 48.5647 60.7929L41.2494 51.7883Z" stroke="white" stroke-width="3.2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`;

    return svgElement;
  };

  const setOrderDetailMarker = async () => {
    removeAllMarkers();
    const coordinates: any = await getOrderLegCoordinates(order?.orderLegs);

    if (coordinates?.length > 0) {
      if (mapRef.current?.getLayer('route-line')) {
        mapRef.current?.removeLayer('route-line');
      }

      if (mapRef.current?.getSource('route')) {
        mapRef.current?.removeSource('route');
      }

      mapRef.current?.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: coordinates,
          },
          properties: {},
        },
      });

      mapRef.current?.addLayer({
        id: 'route-line',
        type: 'line',
        source: 'route',
        layout: {},
        paint: {
          'line-color': '#4264fb',
          'line-width': 4,
        },
      });
      const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);

      if (order?.orderLocationUpdate || order?.shipperAddress) {
        const currentLocation = order?.orderLocationUpdate
          ? order?.orderLocationUpdate
          : order?.shipperAddress;
        const svgMarker = createSVGMarker();
        new mapboxgl.Marker(svgMarker)
          .setLngLat([currentLocation.longitude, currentLocation.latitude])
          .addTo(mapRef.current!);
        mapRef?.current?.setCenter([
          currentLocation.longitude,
          currentLocation.latitude,
        ]);
        mapRef?.current?.setZoom(3);
      }

      coordinates.forEach((coord: any) => bounds.extend(coord));
      mapRef?.current?.fitBounds(bounds, {
        padding: { top: 50, bottom: 50, left: 50, right: 180 },
      });
    }
  };

  useEffect(() => {
    if (order) {
      setOrderDetailMarker();
    } else {
      setOrderMarker();
    }
  }, [orders, order?.orderLocationUpdate]);

  useEffect(() => {
    let activeElement = document.getElementById(orderId);

    if (activeElement) {
      activeElement.className = 'active-order';
    }
  }, [orderId]);

  const getOrderList = () => {
    setIsOrdersLoading(true);
    setOrders([]);
    localStorage.setItem(TABLE_IDS.RADAR_LIST, JSON.stringify(params));
    removeAllMarkers();

    // Cancel the previous request if it exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new abort controller for the current request
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;
    getRadarOrderList({ ...params, ...{ isPagination: true } }, signal)
      .then((response: any) => {
        setOrders(response.data);
        setTabCount(response?.count);
        setIsOrdersLoading(false);
      })
      .catch((e) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsOrdersLoading(false);
      });
  };

  useEffect(() => {
    getOrderList();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [params]);

  useEffect(() => {
    initializeMap();
  }, []);

  useEffect(() => {
    removeAllMarkers();

    if (orderId) {
      setIsLoading(true);
      setOrder(null);

      // Cancel the previous request if it exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new abort controller for the current request
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      getRadarOrderDetail(orderId, signal)
        .then(async (response: any) => {
          setOrder(response.data);
        })
        .catch((e) => {
          console.log(e);

          if (e.code === 'ERR_CANCELED') {
            return;
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [orderId]);

  return (
    <PageSectionLayout
      header={
        <Header
          title={
            <div className="flex items-center gap-x-2.5">
              {orderId ? (
                <>
                  Radar:
                  <p
                    className={`text-primary font-semibold ${
                      isLoading || !order ? 'custom-loading' : ''
                    }`}
                  >{`#${order?.orderId}`}</p>
                  {(order?.orderType || isLoading) && (
                    <BadgeCmp
                      style="modern"
                      type="success"
                      mainClassName={`${
                        isLoading || !order ? 'custom-loading' : ''
                      }`}
                    >
                      {isLoading ? 'LTL' : order?.orderType?.toUpperCase()}
                    </BadgeCmp>
                  )}
                </>
              ) : (
                'Radar'
              )}
            </div>
          }
          desc="Effortlessly monitor your orders from pickup to delivery."
          rightSideContent={
            orderId ? (
              <>
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  onClick={() => {
                    setOrderId('');
                    setOrder(null);
                    setOrders([]);
                    removeAllMarkers();
                  }}
                >
                  <XClose className="w-4 h-4" />
                </ButtonCmp>
              </>
            ) : (
              ''
            )
          }
          mainContainerClassName="!border-0"
        />
      }
      contentClassName="h-[calc(100vh_-_97px)] !p-0 border-t border-borderPrimary"
    >
      <div className="w-full flex h-full">
        {orderId ? (
          <div className="w-[50%] flex flex-col gap-y-4 [&>div]:pr-7 [&>div]:pl-5 px-px pt-4">
            <RadarOrderDetail
              order={order}
              setOrder={setOrder}
              isLoading={isLoading}
            />
            {/* <OrderDetail
              setOrderId={setOrderId}
              orderId={orderId}
              order={order}
              setOrder={setOrder}
            /> */}
          </div>
        ) : (
          <div className="w-[650px] flex flex-col gap-y-4 [&>div]:pr-7 [&>div]:pl-5 px-px pt-4">
            <LeftSidebar
              orders={orders}
              isOrdersLoading={isOrdersLoading}
              setParams={setParams}
              params={params}
              setOrderId={setOrderId}
              tabCount={tabCount}
            />
          </div>
        )}

        <div className="h-full flex-1 radar-mapbox relative border-borderPrimary border-l">
          <div
            id="map"
            ref={mapContainerRef}
            style={{ width: '100%', height: '100%' }}
          ></div>
          {orderId && (
            <RadarMapAboveComponent
              order={order}
              isLoading={isLoading}
              setOrder={setOrder}
            />
          )}
        </div>
        {/* <div className="absolute bottom-5 right-5 max-w-64 rounded-[10px] border border-borderPrimary bg-white shadow-lgc p-4 flex flex-col gap-y-1.5">
          <StatusDotProfileCmp
          label="Latest Location Update"
          dotColor="green"
          />
          <p className="text-textSecondary text-xs font-medium">
          8001 S Orange Blossom Trl, Orlando, FL 32809, USA
          </p>
          <p className="text-gray500 text-xs font-normal text-right">
          Today, 2:30 PM
          </p>
          </div> */}
      </div>
    </PageSectionLayout>
  );
};
export default Radar;
