import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

// TODO : CLEAN UP
// export const listUnassignedOrderGroupByCustomerByUploadedFileId = async (
//   params: any,
//   signal?: any
// ) => {
//   const response = await axiosInterceptor.get(
//     API.ORDERS.GET_UNASSIGNED_ORDERS_BY_FILE,
//     { params, signal }
//   );

//   return response;
// };

export const orderList = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.ORDERS.LIST, {
    params,
    signal,
  });

  return response;
};

export const getRadarOrderList = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.ORDERS.RADAR_ORDER_LIST, {
    params,
    signal,
  });

  return response;
};

export const getRadarOrderDetail = async (id: number, signal?: any) => {
  const response = await axiosInterceptor.get(
    `${API.ORDERS.RADAR_ORDER_DETAIL}/${id}`,
    { signal }
  );

  return response;
};

export const orderListCount = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.ORDERS.LIST_COUNT, {
    params,
    signal,
  });

  return response;
};

export const getOrders = async (params: any) => {
  const response = await axiosInterceptor.get(`${API.ORDERS.GET_ORDERS}`, {
    params,
  });

  return response;
};

export const createOrder = async (params: any) => {
  const response = await axiosInterceptor.post(`${API.ORDERS.CREATE}`, params);

  return response;
};

export const updateOrder = async (params: any) => {
  const response = await axiosInterceptor.post(`${API.ORDERS.UPDATE}`, params);

  return response;
};

export const saveOrderAsDraft = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.ORDER_SAVE_AS_DRAFT}`,
    params
  );

  return response;
};

export const deleteOrder = async (params: any) => {
  const response = await axiosInterceptor.post(`${API.ORDERS.DELETE}`, params);

  return response;
};

export const sendOrderEmail = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.ORDER_DRAFT_EMAIL}`,
    params
  );

  return response;
};

export const getRecentOrders = async () => {
  const response = await axiosInterceptor.get(`${API.ORDERS.GET_RECENT_ORDER}`);

  return response;
};

export const getOrder = async (params: any) => {
  const response = await axiosInterceptor.get(
    `${API.ORDERS.GET_ORDER_DETAILS}`,
    { params }
  );

  return response;
};

export const updateOrderLocation = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.UPDATE_LOCATION}`,
    params
  );

  return response;
};

export const addInterruption = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.ADD_INTERRUPTION}`,
    params
  );

  return response;
};

export const uploadDocuments = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.UPLOAD_DOCUMENTS}`,
    params
  );

  return response;
};

export const approveDocuments = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.APPROVE_DOCUMENTS}`,
    params
  );

  return response;
};

export const updateOrderStatus = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.UPDATE_ORDER_STATUS}`,
    params
  );

  return response;
};

export const cancelOrder = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.CANCEL_ORDER}`,
    params
  );

  return response;
};

export const getOrderProgress = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.GET_ORDER_PROGRESS}`,
    params
  );

  return response;
};

export const getActiveOrder = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.GET_ACTIVE_ORDER}`,
    params
  );

  return response;
};

// For create appointment

export const createOrUpdateAppointment = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.APPOINTMENT.CREATE_OR_UPDATE}`,
    params
  );

  return response;
};

export const UpdateAppointmentStatus = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.APPOINTMENT.UPDATE_APPOINTMENT_STATUS}`,
    params
  );

  return response;
};

export const deleteAppointment = async (params: any) => {
  const response = await axiosInterceptor.delete(
    `${API.ORDERS.APPOINTMENT.DELETE}`,
    { params }
  );

  return response;
};

export const getSalesPrice = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.SALES.GET}`,
    params
  );

  return response;
};

export const updateSalesPrice = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.SALES.UPDATE}`,
    params
  );

  return response;
};

export const deleteSalesPrice = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.SALES.DELETE}`,
    params
  );

  return response;
};

export const getActiveQuote = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.CARRIER.GETACTIVEQUOTE}`,
    params
  );

  return response;
};

export const getRoseRocketOrder = async (id: string) => {
  const response = await axiosInterceptor.get(
    `${API.ORDERS.GET_ROSEROCKET_ORDER}/${id}`
  );

  return response;
};

export const getNewOrderList = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.ORDERS.GET_NEW_ORDER_LIST, {
    params,
    signal,
  });

  return response;
};

export const updateOrCreateCarrierPrice = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.CARRIER.CREATE_OR_UPDATE}`,
    params
  );

  return response;
};

export const deleteCarrierPrice = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.CARRIER.DELETE}`,
    params
  );

  return response;
};

// Leg API's

export const createLegOrder = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.LEG.CREATE}`,
    params
  );

  return response;
};

export const updateLegOrder = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.LEG.UPDATE}`,
    params
  );

  return response;
};

export const deleteOrderLeg = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.LEG.DELETE}`,
    params
  );

  return response;
};

// Recurring Order API

export const createRecurringOrder = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.RECURRING.CREATE}`,
    params
  );

  return response;
};

// Add tag to order
export const addTagToOrder = async (params: any) => {
  const response = await axiosInterceptor.post(`${API.ORDERS.TAG.ADD}`, params);

  return response;
};

// Remove tag from order
export const removeTagFromOrder = async (params: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORDERS.TAG.REMOVE}`,
    params
  );

  return response;
};
