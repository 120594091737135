import {
  Clock,
  LinkExternal02,
  MessageChatCircle,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import TooltipCmp from 'src/components/TooltipCmp';
import { TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { getClaimData, updateClaimStatus } from 'src/services/ClaimService';
import {
  displayTimeDifference,
  getFormattedNumber,
  useRolePermission,
} from 'src/utils/CommonFunctions';
// import WalToast from 'src/utils/WalToast';

import TeamIcon1 from '../../../../assets/img/Avatar7.png';
import doubleCheck from '../../../../assets/img/double-check.svg';
import ActionItem from '../../ActionItem';
import { CLAIM_DISPUTE_STATUS, getBadgeType } from '../../ClaimsDisputes.const';
import FileUpload from '../../FileUpload';
import InternalChatAndNotes from '../../InternalChatAndNotes';
import ClaimResolve from '../ClaimResolve';
import EditClaimExplanation from '../EditClaimExplanation';
import ModifyClaimAmount from '../ModifyClaimAmount';

const ClaimsDetail = () => {
  const navigate = useNavigate();
  const { hasRoleV2 } = useRolePermission();
  const { reloadCounts } = useContext(EntityCountsContext);
  const { id: claimId } = useParams<{ id: any }>();

  const [claim, setClaim] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notesCount, setNotesCount] = useState<any>(null);
  const [userOptions, setUserOptions] = useState([]);
  const [isShowClaimResolveModal, setIsShowClaimResolveModal] = useState(false);
  const [isShowClaimCloseModal, setIsShowClaimCloseModal] = useState(false);
  const [isShowEditExplanationModal, setIsShowEditExplanationModal] =
    useState(false);
  const [isModifyClaimAmount, setIsModifyClaimAmount] = useState(false);
  const [isCloseClaimLoading, setIsCloseClaimLoading] = useState(false);
  const [isShowChatAndNotesSideBar, setIsShowChatAndNotesSideBar] =
    useState(true);
  const [isShowClaimActionsDropDown, setIsShowClaimActionsDropDown] =
    useState(false);
  const [outstadingTime, setOutstadingTime] = useState(null);

  const { type, clockType } = getBadgeType(
    moment.utc(claim?.createdAt).local().format('YYYY-MM-DD HH:mm:ss')
  );

  const getClaim = () => {
    setIsLoading(true);
    getClaimData(claimId)
      .then((response: any) => {
        if (response && response.data) {
          const responseData = response.data;
          setClaim(responseData);
          setUserOptions(responseData.members);
          setNotesCount(responseData.total_notes_count);
          setOutstadingTime(
            displayTimeDifference(
              moment
                .utc(responseData?.createdAt)
                .local()
                .format('YYYY-MM-DD HH:mm:ss')
            )
          );

          if (response.isReloadCount) {
            reloadCounts();
          }
        } else {
          navigate(ROUTES.HOME);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getClaim();

    return () => {
      if (localStorage.getItem(TABLE_IDS.CUSTOMER_DETAILS)) {
        localStorage.removeItem(TABLE_IDS.CUSTOMER_DETAILS);
      }
    };
  }, []);

  const handleCloseClaimConfirmationAction = (status = false) => {
    if (status) {
      setIsCloseClaimLoading(true);
      updateClaimStatus({
        claimID: claim.claimID,
        status: CLAIM_DISPUTE_STATUS.CLOSED,
      })
        .then(() => {
          // WalToast.success('Claim closed successfully.');
          setIsShowClaimCloseModal(false);
          getClaim();
        })
        .finally(() => {
          setIsCloseClaimLoading(false);
        })
        .catch(console.log);
    } else {
      setIsShowClaimCloseModal(false);
    }
  };

  useEffect(() => {
    if (claim?.createdAt) {
      const updateTime = () => {
        setOutstadingTime(
          displayTimeDifference(
            moment.utc(claim?.createdAt).local().format('YYYY-MM-DD HH:mm:ss')
          )
        );
      };

      const intervalId = setInterval(updateTime, 1000);

      return () => clearInterval(intervalId);
    }
  }, [claim]);

  const memoizedTimeBadge = useMemo(() => {
    if (outstadingTime || isLoading) {
      return (
        <BadgeCmp
          style="pill"
          type={type}
          mainClassName={`rounded-md ${isLoading ? 'custom-loading' : ''}`}
        >
          <div className="flex items-center gap-1">
            <Clock className={`w-3 h-3 mt-[1px] ${clockType}`} />
            {isLoading ? '25:04:47' : outstadingTime}
          </div>
        </BadgeCmp>
      );
    } else {
      return false;
    }
  }, [outstadingTime, type, clockType, isLoading]);

  return (
    <PageSectionLayout
      header={
        <Header
          title={
            <div className="flex gap-x-2.5 gap-y-2 items-center flex-wrap">
              Claim
              <span className={`${isLoading ? 'custom-loading ' : ''}`}>
                #{isLoading ? 'CL-AOI1-141' : claim?.claimID}
              </span>
              <div className="flex items-center [&>div:not(:first-child)>div>div]:border-white [&>div:not(:first-child)>div>div]:border-[1.5px] [&>div:not(:first-child)]:-ml-2">
                {claim?.members?.map((groupCus: any) => (
                  <TooltipCmp message={groupCus?.name} key={groupCus?.id}>
                    {groupCus.image && groupCus?.imageUrl ? (
                      <div className="rounded-full bg-white">
                        <img
                          src={`${groupCus?.imageUrl}${groupCus.image}`}
                          className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full ${
                            isLoading ? 'image-loading custom-loading' : ''
                          }`}
                        />
                      </div>
                    ) : (
                      <div className="rounded-full bg-white">
                        <img
                          src={TeamIcon1}
                          className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full ${
                            isLoading ? 'image-loading custom-loading' : ''
                          }`}
                        />
                      </div>
                    )}
                  </TooltipCmp>
                ))}
              </div>
              {(claim?.status || isLoading) && (
                <>
                  <BadgeCmp
                    style="modern"
                    mainClassName={`rounded-md ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                    type={
                      claim?.status === CLAIM_DISPUTE_STATUS.NEW
                        ? 'success'
                        : claim?.status === CLAIM_DISPUTE_STATUS.RESOLVED
                        ? 'lightblue'
                        : claim?.status === CLAIM_DISPUTE_STATUS.OPENED
                        ? 'primary'
                        : claim?.status === CLAIM_DISPUTE_STATUS.CLOSED
                        ? 'error'
                        : 'success'
                    }
                  >
                    <p data-test-id="wal-QuotingDashboard-status">
                      {isLoading ? 'opened' : claim?.status}
                    </p>
                  </BadgeCmp>
                </>
              )}
              {memoizedTimeBadge}
            </div>
          }
          desc={`Manage, segment and view your customers here.`}
          rightSideContent={
            <div className="flex flex-wrap gap-3">
              {isShowChatAndNotesSideBar ? (
                <ButtonCmp
                  className="btn_secondary_black xl:hidden"
                  onClick={() => setIsShowChatAndNotesSideBar(false)}
                  icon={<MessageChatCircle className="w-4 h-4" />}
                >
                  Messages
                </ButtonCmp>
              ) : (
                <ButtonCmp
                  className="btn_secondary_black"
                  onClick={() => setIsShowChatAndNotesSideBar(true)}
                  icon={<MessageChatCircle className="w-4 h-4" />}
                >
                  Messages
                </ButtonCmp>
              )}

              {claim?.status !== CLAIM_DISPUTE_STATUS.CLOSED && (
                <OutsideClickHandler
                  onOutsideClick={() => setIsShowClaimActionsDropDown(false)}
                  containerClassName="relative"
                >
                  <ButtonCmp
                    className="btn-outline-primary"
                    onClick={() =>
                      setIsShowClaimActionsDropDown((prev) => !prev)
                    }
                  >
                    Claim Actions
                  </ButtonCmp>

                  <ul
                    className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[200px] right-0 absolute ${
                      isShowClaimActionsDropDown ? '' : 'hidden'
                    }`}
                  >
                    {claim?.status !== CLAIM_DISPUTE_STATUS.RESOLVED && (
                      <li
                        className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer ${
                          isCloseClaimLoading || isLoading
                            ? 'custom-loading'
                            : ''
                        }`}
                        onClick={() => {
                          setIsShowClaimActionsDropDown(false);
                          setIsShowEditExplanationModal(true);
                        }}
                      >
                        Edit Claim Description
                      </li>
                    )}
                    {(hasRoleV2('admin') || hasRoleV2('finance')) &&
                      claim?.status === CLAIM_DISPUTE_STATUS.RESOLVED && (
                        <li
                          className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer ${
                            isCloseClaimLoading || isLoading
                              ? 'custom-loading'
                              : ''
                          }`}
                          onClick={() => {
                            setIsShowClaimActionsDropDown(false);
                            setIsShowClaimCloseModal(true);
                          }}
                        >
                          Close Claim
                        </li>
                      )}
                    {(hasRoleV2('admin') || hasRoleV2('finance')) &&
                      claim?.status === CLAIM_DISPUTE_STATUS.OPENED && (
                        <li
                          className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer ${
                            isLoading ? 'custom-loading' : ''
                          }`}
                          onClick={() => {
                            setIsShowClaimActionsDropDown(false);
                            setIsShowClaimResolveModal(true);
                          }}
                        >
                          Resolve Claim
                        </li>
                      )}

                    {claim?.status === CLAIM_DISPUTE_STATUS.OPENED && (
                      <li
                        className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                        onClick={() => {
                          setIsShowClaimActionsDropDown(false);
                          setIsModifyClaimAmount(true);
                        }}
                      >
                        Modify Claim Amount
                      </li>
                    )}
                  </ul>
                </OutsideClickHandler>
              )}

              <ButtonCmp
                className="btn_secondary_black lg:!px-[9px] !px-2"
                onClick={() => {
                  if (history.length > 3) {
                    navigate(-1);
                  } else {
                    navigate(PATH.CLAIMS_AND_DISPUTES_DETAILS);
                  }
                }}
              >
                <XClose className="w-4 h-4" />
              </ButtonCmp>
            </div>
          }
        />
      }
      contentClassName="h-[calc(100%_-_88px)] overflow-x-hidden !pb-0 !pt-0 relative "
    >
      <div className="flex h-full ">
        <div className="flex-1 xl:pr-8 overflow-auto custom-scrollbar-v2 pt-5 xxl:gap-4 gap-3 flex flex-col">
          {claim?.status === CLAIM_DISPUTE_STATUS.RESOLVED && (
            <div className="rounded-md border border-utilitysuccess300 bg-green25 px-3 py-2 flex mb-3">
              <div className="flex-none mr-1">
                <img src={doubleCheck} />
              </div>
              <div className="text-xs">
                <span className="text-grayLight900 text-xs font-medium">
                  Claim resolved on
                </span>
                <span className="text-xs font-semibold text-primary">
                  &nbsp;{moment(claim?.resolvedAt).format('Do MMMM, YYYY')}
                  &nbsp;
                </span>
                <span className="text-xs font-medium text-grayLight900">
                  by {claim?.resolverUserName}. Resolution Amount: $
                  {claim?.finalClaimAmount
                    ? claim?.finalClaimAmount
                    : claim?.claimAmount}
                </span>
                {claim?.resolveDescription ? (
                  <p className="text-xs text-grayLight900 font-medium mt-1">
                    Resolution Note: {claim?.resolveDescription}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
          )}

          <div className="flex flex-wrap justify-between items-start border-b border-utilityGray200 pb-4 ml-1">
            <div className=" flex-1">
              <h6 className="inline-block text-grayLight900 text-sm font-semibold">
                Overview:&nbsp;
              </h6>
              <span
                className={`text-grayLight600 text-sm font-normal  ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                {claim && claim?.claimExplanation}
              </span>
            </div>

            {/* {claim?.status !== CLAIM_DISPUTE_STATUS.RESOLVED && (
              <ButtonCmp
                className={`btn-outline-primary flex-none ${
                  isLoading ? 'custom-loading' : ''
                }`}
                onClick={() => setIsShowEditExplanationModal(true)}
              >
                Edit
              </ButtonCmp>
            )} */}
          </div>
          <div className="overflow-hidden flex-none">
            <ul className="flex xls:justify-between justify-start mb-2 flex-wrap transition-all duration-[0.5s] -mx-[18px]">
              <li className="w-1/5 px-[18px]">
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  Customer
                </h6>
                <Link
                  to={`${PATH.CUSTOMER_DETAILS}/${claim?.customerPageId}`}
                  className="text-primary700 text-sm font-medium"
                >
                  <TooltipCmp
                    message={
                      claim?.customer && claim?.customer.length > 24
                        ? claim?.customer
                        : null
                    }
                    parentClassName="max-w-90"
                  >
                    <p
                      className={`xlm:max-w-56 max-w-40 truncate ${
                        isLoading ? 'custom-loading' : ''
                      }`}
                    >
                      {isLoading ? 'Airtek Ontario Inc' : claim?.customer}
                    </p>
                  </TooltipCmp>
                </Link>
              </li>
              <li className="w-1/5 px-[18px]">
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  Order ID
                </h6>
                <a
                  href={`https://wal.roserocket.com/#/ops/orders/${claim?.roserocketOrderId}`}
                  className={`text-primary700 text-sm font-medium max-w-30 truncate block ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                  target="_blank"
                >
                  #{isLoading ? 'WAL-AOI1-21' : claim?.orderID}
                </a>
              </li>
              <li className="w-1/5 px-[18px]">
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  Dispute ID
                </h6>
                <p
                  className={`text-primary700 text-sm font-medium flex items-center ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {claim?.disputeId ? (
                    <a
                      href={`/claims-and-disputes/dispute-details/${claim.disputeIdKey}`}
                      className={`flex items-center text-primary700 cursor-pointer text-sm font-medium max-w-30 truncate ${
                        isLoading ? 'custom-loading' : ''
                      }`}
                      target="_blank"
                    >
                      Link
                      <LinkExternal02 className="w-3.5 h-3.5 ml-0.5" />
                    </a>
                  ) : (
                    '-'
                  )}
                </p>
              </li>
              <li className="w-1/5 px-[18px] relative">
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  Load Insurance
                </h6>

                <p
                  className={`text-primary700 text-sm font-medium flex items-center ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {claim?.load_insurance_id ? (
                    <a
                      href={`/load-insurance/${claim?.loadInsuranceId}`}
                      className="text-primary700 text-sm font-medium max-w-30 truncate"
                      target="_blank"
                    >
                      #{claim?.load_insurance_id}
                    </a>
                  ) : (
                    '-'
                  )}
                </p>
              </li>
              <li className="w-1/5 px-[18px]">
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  Claim Amount
                </h6>
                <div className="flex gap-1">
                  <p
                    className={`text-grayLight900 text-sm font-semibold ${
                      claim?.finalClaimAmount ? 'line-through' : ''
                    } ${isLoading ? 'custom-loading' : ''}`}
                  >
                    ${isLoading ? 255 : getFormattedNumber(claim?.claimAmount)}
                  </p>
                  {claim?.finalClaimAmount && (
                    <p
                      className={`text-grayLight900 text-sm font-semibold ${
                        isLoading ? 'custom-loading' : ''
                      }`}
                    >
                      ${getFormattedNumber(claim?.finalClaimAmount)}
                    </p>
                  )}
                </div>
              </li>
            </ul>
          </div>

          <ActionItem
            claimId={claimId}
            claim={claim}
            userOptions={userOptions}
          />

          <FileUpload claimId={claimId} />
        </div>

        <InternalChatAndNotes
          claim={claim}
          isClaim={true}
          notesCount={notesCount}
          setNotesCount={setNotesCount}
          isShowChatAndNotesSideBar={isShowChatAndNotesSideBar}
          setIsShowChatAndNotesSideBar={setIsShowChatAndNotesSideBar}
        />
      </div>

      {isShowEditExplanationModal && (
        <EditClaimExplanation
          handleClose={() => setIsShowEditExplanationModal(false)}
          claim={claim}
          title={'Edit Claim Description'}
          isEdit={true}
          getClaim={getClaim}
        />
      )}

      {isShowClaimResolveModal && (
        <ClaimResolve
          handleClose={() => {
            setIsShowClaimResolveModal(false);
            setIsModifyClaimAmount(false);
          }}
          claim={claim}
          isClaimListPage={false}
          handleModifyClaimAmount={() => setIsModifyClaimAmount(true)}
        />
      )}

      {isModifyClaimAmount && (
        <ModifyClaimAmount
          claim={claim}
          handleClose={() => setIsModifyClaimAmount(false)}
          getClaim={getClaim}
          setClaim={setClaim}
        />
      )}

      {isShowClaimCloseModal && (
        <CommonModal
          title={`Close Claim : #${claim?.claimID}`}
          titleDesc={`This action will close ${claim?.claimID} permanently.`}
          handleClose={() =>
            isCloseClaimLoading
              ? false
              : handleCloseClaimConfirmationAction(false)
          }
          secondaryBtnOnClick={() => handleCloseClaimConfirmationAction(false)}
          dangerBtnText="Close Claim"
          secondaryBtnText="Cancel"
          primaryBtnOnClick={() => handleCloseClaimConfirmationAction(true)}
          primaryBtnLoading={isCloseClaimLoading}
          primaryBtnDisabled={isCloseClaimLoading}
          secondaryBtnDisabled={isCloseClaimLoading}
          size="max-w-[400px]"
          modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
          modalHeaderTextClass="!flex-col"
          modalFooterClass="border-t-0 pt-0"
        >
          &nbsp;
        </CommonModal>
      )}
    </PageSectionLayout>
  );
};

export default ClaimsDetail;
