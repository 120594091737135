import { search } from 'node-emoji';
import React, { useState } from 'react';

const EmojiPicker = ({ onSelect }: { onSelect: (emoji: string) => void }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredEmojis = search(searchTerm).slice(0, 50);

  return (
    <div className="my-1.5 z-[1] left-0 w-[250px] absolute custom-emoji-picker p-2 border border-gray-300 rounded-md bg-white shadow-lg">
      <input
        type="text"
        placeholder="Search emojis..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full p-1 mb-2 border border-gray-200 rounded"
      />
      <div className="grid grid-cols-5 gap-2">
        {filteredEmojis.map(({ emoji, name }) => (
          <button
            key={name}
            onClick={() => onSelect(emoji)}
            className="text-lg hover:bg-gray-100 rounded"
          >
            {emoji}
          </button>
        ))}
      </div>
    </div>
  );
};

export default EmojiPicker;
