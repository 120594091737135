import { PhoneCall01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import { BasicContext } from 'src/context/BasicContext';

import MakeACall from '../CallLogs/MakeACall';
import ContactModal from '../Contacts/ContactModal';

import CallTranScript from './CallTranScript';
import ContactDetails from './ContactDetails';
import ThreadCommunication from './ThreadCommunication';
import ThreadList from './ThreadList';
// import ThreadCommunicationLoader from './ThreadLoader/ThreadCommunicationLoader';

interface ShowRightPanelState {
  name: string;
  id: string;
  type?: string; // Make 'type' optional
}

type ActionType = {
  mode: string | null;
  data: any;
};

const Threads = () => {
  const { isMakeCall, setIsMakeCall } = useContext(BasicContext);
  const [threads, setThreads] = useState<any[]>([]);
  const [transcriptThread, setTranscriptThread] = useState<any[]>([]);
  const [isShowRightPanel, setIsShowRightPanel] = useState<ShowRightPanelState>(
    {
      name: '',
      id: '',
      type: '',
    }
  );
  const [selectedThreads, setSelectedThreads] = useState<any[]>([]);
  const [newContactDialAction, setNewContactDialAction] = useState<ActionType>({
    mode: '',
    data: {},
  });
  const [action, setAction] = useState<ActionType>({ mode: '', data: {} });

  const handleThreadCommunication = (threadsData?: any) => {
    setThreads(threadsData);
    setSelectedThreads(threadsData);
    setIsShowRightPanel({ name: '', id: '' });
  };

  const handleThread = (thread: any) => {
    setIsShowRightPanel({
      name: 'transcript',
      id: thread.recordingId,
    });
    setTranscriptThread(thread);
  };

  const handleOutsideClick = () => {
    setIsMakeCall(false);
  };

  const handleModalClose = (resetAction?: any) => {
    if (resetAction?.mode === 'addNewContactDial') {
      setNewContactDialAction({
        mode: 'newContactDial',
        data: resetAction?.data,
      });
      setIsMakeCall(true);
    }

    if (resetAction) {
      setAction({ mode: null, data: null });
    } else {
      setAction({ mode: '', data: {} });
    }
  };

  const handleCloseContactDetails = () => {
    setSelectedThreads([]);
  };

  const handleContactDetailsid = (contactId: any, type: any) => {
    setIsShowRightPanel({
      name: 'contact',
      id: contactId,
      type: type,
    });
    setSelectedThreads([]);
  };

  useEffect(() => {
    console.log('Threads data has changed:', threads);
  }, [threads]);

  useEffect(() => {
    setNewContactDialAction({ mode: '', data: {} });
  }, [isMakeCall]);

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Threads"
            desc="Track and manage ongoing conversations with your contacts for effective support."
            isShowNotificationBox
            rightClassName="sm:w-auto"
            rightSideContent={
              <div className="relative">
                <ButtonCmp
                  className="btn-outline-primary"
                  onClick={() => {
                    setIsMakeCall(true);
                  }}
                >
                  <PhoneCall01 className="text-primary w-4 h-4" />
                  Make a Call
                </ButtonCmp>
                {isMakeCall && (
                  <MakeACall
                    onOutsideClick={handleOutsideClick}
                    setIsMakeCall={setIsMakeCall}
                    setAction={setAction}
                    action={action}
                    newContactDial={newContactDialAction}
                  />
                )}
              </div>
            }
          />
        }
        contentClassName="h-[calc(100vh_-_97px)]"
      >
        <div className="h-full w-full relative bg-white border border-gray-200 rounded-xl flex flex-wrap overflow-hidden">
          <ThreadList onThreadRowClick={handleThreadCommunication} />
          {threads.length !== 0 && (
            <div className="flex-1 flex flex-col h-full">
              <ThreadCommunication
                setIsShowRightPanel={setIsShowRightPanel}
                threads={threads}
                onHandleThread={handleThread}
                contactDetailsId={handleContactDetailsid}
              />
            </div>
          )}

          {/* {isShowRightPanel?.name === 'contact' ? (
        <ThreadCommunication setIsShowRightPanel={setIsShowRightPanel} /> */}

          {isShowRightPanel?.name === 'contact' ? (
            <div className="w-[350px] flex flex-col h-full border-l border-utilityGray200">
              <ContactDetails
                setIsShowRightPanel={setIsShowRightPanel}
                isShowRightPanel={isShowRightPanel}
              />
            </div>
          ) : selectedThreads.length !== 0 &&
            isShowRightPanel?.name != 'transcript' ? (
            <div className="w-[350px] flex flex-col h-full border-l border-utilityGray200">
              <ContactDetails
                setIsShowRightPanel={handleCloseContactDetails}
                isShowRightPanel={isShowRightPanel}
                threads={threads}
              />
            </div>
          ) : null}

          {isShowRightPanel?.name === 'transcript' && (
            <div className="w-[350px] flex flex-col h-full border-l border-utilityGray200">
              <CallTranScript
                setIsShowRightPanel={setIsShowRightPanel}
                isShowRightPanel={isShowRightPanel}
                transcriptThread={transcriptThread}
              />
            </div>
          )}
        </div>
      </PageSectionLayout>
      {action.mode === 'addNewContactDial' && (
        <ContactModal
          action={action}
          setAction={setAction}
          contactData={action?.data}
          handleClose={handleModalClose}
        />
      )}
    </>
  );
};

export default Threads;
