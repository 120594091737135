import {
  Edit05,
  Trash01,
  Settings01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import CustomPagination from 'src/components/CustomPagination';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import {
  setIsSocketConnected,
  setSocketIo,
} from 'src/redux/SocketConnection.slice';
import {
  updateRateGathering,
  getLoadLinkPostLeads,
  deleteLoadLinkPosting,
  getLoadLinkVehicleType,
} from 'src/services/LoadLinkService';
import { fetchJsFromCDN } from 'src/utils/CommonFunctions';

import aiStarGif from '../../../../../assets/img/aiStarGif.gif';
import WalToast from '../../../../../utils/WalToast';

import CreateLoadPostingFrom from './CreateLoadPostingFrom';
import {
  IProps,
  ITransportLead,
  LoadBoardCellId,
  QuoteType,
} from './LoadBoard.interface';
import LoadBoardRateRow from './LoadBoardRateRow';
import LoadBoardRatesLoader from './LoadBoardRatesLoader';
import LoadBoardRow from './LoadBoardRow';
import LoadBoardRowLoader from './LoadBoardRowLoader';
import TruckFindingAi from './TruckFindingAi';

const initParams = {
  sortType: 'desc',
  sortField: LoadBoardCellId.Availability,
  page: 1,
  limit: 50,
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const PostToLoadBoard = ({
  activePostIndex,
  setActivePostIndex,
  loadLinkPosts,
  setLoadLinkPosts,
  loadLinkPostingDetail,
  setLoadLinkPostingDetail,
  addressDetails,
  validatePostToLoadBoardForm,
  setValidatePostToLoadBoardForm,
  setIsPostCreateLoading,
  syncLiveData,
  setSyncLiveData,
  isUpdateLoadPost,
  setIsUpdateLoadPost,
  isCreateLoadPost,
  setIsCreateLoadPost,
  setIsRefresh,
  isRefresh,
  rateGatheringWithAi,
  vehicleSizeOptions,
  setCurrentTab,
}: IProps) => {
  const [leadsData, setLeadsData] = useState<ITransportLead[]>([]);
  const [leadCount, setLeadCount] = useState<number>(0);
  const [rateCount, setRateCount] = useState<number>(0);
  const [inboundInprogressCount, setInboundInprogressCount] =
    useState<number>(0);
  const [outboundInprogressCount, setOutboundInprogressCount] =
    useState<number>(0);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<any[]>([]);
  const [timerActiveIndex, setTimerActiveIndex] = useState<string>(
    loadLinkPostingDetail?.isCovered
      ? loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
          ?.enableOutboundTruckFinding
        ? 'inprogressOutboundCalls'
        : 'rateCollected'
      : loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
          ?.enableInboundTruckFinding
      ? 'inprogressInboundCalls'
      : 'rateCollected'
  );

  const [truckFindingAiDetail, setTruckFindingAiDetail] = useState<any>({});
  const [callStats, setCallStats] = useState<any>({});

  const [isSyncingLiveData, setIsSyncingLiveData] = useState<boolean>(false);
  const [params, setParams] = useState<any>(initParams);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showTimerLoader, setShowTimerLoader] = useState<boolean>(false);
  const [activeLeadType, setActiveLeadType] = useState<string>('leads');
  const [
    isShowLoadLinkPostingDeleteConfirmation,
    setIsShowLoadLinkPostingDeleteConfirmation,
  ] = useState(false);
  const [isLoadLinkPostingDeleteLoading, setIsLoadLinkPostingDeleteLoading] =
    useState(false);
  const [filterByPostOptions, setFilterByPostOptions] = useState<any>([]);
  const [isShowTruckFindingAiModal, setIsShowTruckFindingAiModal] =
    useState(false);
  const [isRateUpdateLoading, setIsRateUpdateLoading] = useState(false);
  const [newTimerActiveIndex, setNewTimerActiveIndex] = useState('');
  const dispatch = useDispatch();
  const abortControllerRef = useRef<AbortController | null>(null);
  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  const setCurrentIndex = (loadLinkPosting: any) => {
    let remainingSeconds = 0;
    let minutes = 0;
    let seconds = 0;

    if (
      !loadLinkPosting?.isCovered &&
      truckFindingAiDetail?.acceptInboundCallDuration > 0
    ) {
      const updatedAt = moment.utc(
        truckFindingAiDetail.updatedAt,
        'YYYY-MM-DD HH:mm:ss'
      );
      const currentGMT = moment().tz('GMT');
      const elapsedSeconds = currentGMT.diff(updatedAt, 'seconds');
      const totalSeconds = truckFindingAiDetail.acceptInboundCallDuration * 60;
      remainingSeconds = totalSeconds - elapsedSeconds;

      if (remainingSeconds > 0) {
        minutes = Math.floor(remainingSeconds / 60);
        seconds = remainingSeconds % 60;
        setNewTimerActiveIndex(`inprogressInboundCalls_${minutes}_${seconds}`);

        return setTimerActiveIndex(
          `inprogressInboundCalls_${minutes}_${seconds}`
        );
      }
    }

    setTimeout(() => {
      const allowedKeys = loadLinkPosting?.isCovered
        ? loadLinkPosting?.loadLinkTruckFindingAis?.[0]
            ?.enableOutboundTruckFinding
          ? ['inprogressOutboundCalls', 'rateCollected']
          : ['rateCollected']
        : loadLinkPosting?.loadLinkTruckFindingAis?.[0]
            ?.enableInboundTruckFinding
        ? [`inprogressInboundCalls_${minutes}_${seconds}`, 'rateCollected']
        : ['rateCollected'];

      const updateActiveIndex = (prevIndex: string): string => {
        const mainIndex = prevIndex.split('_')[0];

        if (!allowedKeys.includes(mainIndex)) {
          return allowedKeys[0];
        }

        if (allowedKeys.length > 1) {
          return mainIndex === allowedKeys[0] ? allowedKeys[1] : allowedKeys[0];
        } else {
          return allowedKeys[0];
        }
      };

      setNewTimerActiveIndex(updateActiveIndex);
      setTimerActiveIndex(updateActiveIndex);
    }, 1);
  };

  const setCallStatsResponse = (res: any) => {
    const defaultStats = {
      rateCollected: {
        count: 0,
        text: 'Rates Gathered',
      },
      ...(loadLinkPostingDetail?.isCovered
        ? loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
            ?.enableOutboundTruckFinding
          ? {
              inprogressOutboundCalls: {
                count: 0,
                text: 'Outbound Calls In Progress',
              },
            }
          : {}
        : loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
            ?.enableInboundTruckFinding
        ? {
            inprogressInboundCalls: {
              count: 0,
              text: 'Inbound Calls In Progress',
            },
          }
        : {}),
    };

    setCallStats((prevStats: any) => {
      const keys = Object.keys(defaultStats);

      return keys.reduce((acc: any, stat: string) => {
        let text = '';

        if (stat === 'inprogressInboundCalls') {
          text =
            res[stat] && res[stat] === 1
              ? 'Inbound Call In Progress'
              : 'Inbound Calls In Progress';
        } else if (stat === 'inprogressOutboundCalls') {
          text =
            res[stat] && res[stat] === 1
              ? 'Outbound Call In Progress'
              : 'Outbound Calls In Progress';
        } else if (stat === 'rateCollected') {
          text =
            res[stat] && res[stat] === 1 ? ' Rate Gathered' : ' Rates Gathered';
        }

        acc[stat] = {
          count: res[stat] ?? prevStats[stat] ?? 0,
          text,
        };

        return acc;
      }, {});
    });
  };

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  const getVehicleType = () => {
    cancelApiCall();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    getLoadLinkVehicleType(signal)
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleTypeOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
      });
  };

  const getLead = (loading = true) => {
    if (loadLinkPostingDetail?.id) {
      let signal;

      if (loading) {
        setIsLoading(loading);
        cancelApiCall();

        abortControllerRef.current = new AbortController();
        signal = abortControllerRef.current.signal;
      }

      getLoadLinkPostLeads(
        { ...params, isRefresh: isSyncingLiveData, activeLeadType },
        loadLinkPostingDetail.id,
        signal
      )
        .then((res: any) => {
          if (vehicleTypeOptions.length == 0) {
            getVehicleType();
          }
          setLeadsData(res.data);

          if (loading) {
            setLoadLinkPostingDetail(res.loadLinkPostingDetail);
            setTruckFindingAiDetail(
              res.loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
            );
          }
          setLeadCount(res.leadCount);
          setRateCount(res.rateCount);
          setInboundInprogressCount(res.inprogressInboundCalls);
          setOutboundInprogressCount(res.inprogressOutboundCalls);
          setCallStatsResponse(res);
          setTimeout(() => {
            setCurrentIndex(res.loadLinkPostingDetail);
            setTimeout(() => {
              setShowTimerLoader(false);
            }, 1000);
          }, 100);
        })
        .finally(() => {
          setIsSyncingLiveData(false);
          setIsLoading(false);
          setSyncLiveData(false);
        })
        .catch((e) => {
          if (e.code === 'ERR_CANCELED') {
            return;
          }
        });
    } else {
      setIsSyncingLiveData(false);
      setIsLoading(false);
      setSyncLiveData(false);
    }
  };

  useEffect(() => {
    const defaultStats = {
      rateCollected: {
        count: rateCount,
        text: `Rate${rateCount == 1 ? '' : 's'} Gathered`,
      },
      ...(loadLinkPostingDetail?.isCovered
        ? loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
            ?.enableOutboundTruckFinding
          ? {
              inprogressOutboundCalls: {
                count: outboundInprogressCount,
                text: `Outbound Call${
                  outboundInprogressCount == 1 ? '' : 's'
                } In Progress`,
              },
            }
          : {}
        : loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
            ?.enableInboundTruckFinding
        ? {
            inprogressInboundCalls: {
              count: inboundInprogressCount,
              text: `Inbound Call${
                inboundInprogressCount == 1 ? '' : 's'
              } In Progress`,
            },
          }
        : {}),
    };

    setCallStats(defaultStats);

    if (loadLinkPostingDetail) {
      setCurrentIndex(loadLinkPostingDetail);
    }
  }, [
    loadLinkPostingDetail,
    loadLinkPostingDetail?.isCovered,
    outboundInprogressCount,
    rateCount,
    inboundInprogressCount,
  ]);

  useEffect(() => {
    if (syncLiveData) {
      setLeadsData([]);
      setLeadCount(0);
      setRateCount(0);
      setIsLoading(true);
      setIsSyncingLiveData(syncLiveData);
      setIsUpdateLoadPost(false);
      setIsCreateLoadPost(false);
    }
  }, [syncLiveData]);

  const handlePagination = (page: number) => {
    setParams((old: any) => ({ ...old, page }));
  };

  useEffect(() => {
    if (
      vehicleTypeOptions.length > 0 &&
      leadsData.length > 0 &&
      activeLeadType == 'leads'
    ) {
      const leads = leadsData;

      for (let i = 0; i < leads.length; i++) {
        const vehicleTypes = leads[i].vehicleType.split('');
        const attachedVehicleTypeName = vehicleTypeOptions
          .filter((vehType) => vehicleTypes.includes(vehType.value))
          .map((vehType) => {
            vehType.label = vehType.label === 'Van' ? 'Dry Van' : vehType.label;

            return vehType.label;
          });

        leads[i].vehicleType = attachedVehicleTypeName.join(', ');
      }
      setLeadsData(leads);
    }
  }, [vehicleTypeOptions, leadsData]);

  useEffect(() => {
    setTruckFindingAiDetail(
      loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
    );

    setIsSyncingLiveData(true);

    const intervalId = setInterval(() => {
      setIsSyncingLiveData(true);
    }, 180000); // 3 minutes in milliseconds

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const setLeadRate = (response: any) => {
    if (activeLeadType == 'rates') {
      setLeadsData(response.rates);
    }
    setRateCount(response.rates.length);
    setInboundInprogressCount(response.inprogressInboundCalls);
    setOutboundInprogressCount(response.inprogressOutboundCalls);
    setCallStatsResponse(response);
    setTimeout(() => {
      setCurrentIndex(loadLinkPostingDetail);
      setTimeout(() => {
        setShowTimerLoader(false);
      }, 1000);
    }, 100);
  };

  const refreshPost = (response: any) => {
    loadLinkPosts[activePostIndex] = response.loadLinkPostingDetail;
    setLoadLinkPosts(loadLinkPosts);
    setLoadLinkPostingDetail(response.loadLinkPostingDetail);
    const truckFindingAi =
      response.loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0];
    setTruckFindingAiDetail(truckFindingAi);
    setInboundInprogressCount(response.inprogressInboundCalls);
    setOutboundInprogressCount(response.inprogressOutboundCalls);
    setCallStatsResponse(response);
    setTimeout(() => {
      setCurrentIndex(response.loadLinkPostingDetail);
      setTimeout(() => {
        setShowTimerLoader(false);
      }, 1000);
    }, 100);
  };

  useEffect(() => {
    if (loadLinkPostingDetail?.id) {
      getVehicleType();
    }

    if (!loadLinkPostingDetail?.id) return;

    try {
      if (isSocketConnected && !!socketIO?.on) {
        socketIO.removeAllListeners();

        socketIO.on(
          `lead_rate_${loadLinkPostingDetail.id}`,
          (response: any) => {
            console.log('lead_rate_', response);
            setLeadRate(response);
          }
        );

        socketIO.on(
          `refresh_post_${loadLinkPostingDetail.id}`,
          (response: any) => {
            console.log('refresh_post_ response', response);
            refreshPost(response);
          }
        );
      } else {
        fetchJsFromCDN(
          'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
          ['io']
        ).then(([io]: any) => {
          io.sails.url = window.SERVER_URL;
          io.socket.on('connect', () => {
            dispatch(setIsSocketConnected(true));
            dispatch(setSocketIo(io.socket));

            io.socket.get(`/subscribe/globalRoom`, (_data: any, jwr: any) => {
              if (jwr.error) {
                return;
              }
            });
          });

          io.socket.on(
            `lead_rate_${loadLinkPostingDetail.id}`,
            (response: any) => {
              console.log('lead_rate_', response);
              setLeadRate(response);
            }
          );

          io.socket.on(
            `refresh_post_${loadLinkPostingDetail.id}`,
            (response: any) => {
              console.log('refresh_post_ response', response);
              refreshPost(response);
            }
          );
        });
      }
    } catch (error) {
      console.error('Failed to load Sails socket library:', error);
    }
  }, [loadLinkPostingDetail?.id]);

  useEffect(() => {
    let inboundInterval: NodeJS.Timeout | null = null;
    let rateCollectedInterval: NodeJS.Timeout | null = null;
    let currentTimerIndex = timerActiveIndex;

    if (newTimerActiveIndex) {
      currentTimerIndex = newTimerActiveIndex;
    }

    const manageRateCollectedInterval = () => {
      setTimerActiveIndex((prevIndex: string): string => {
        const mainIndex = prevIndex.split('_')[0];
        const allowedKeys = loadLinkPostingDetail?.isCovered
          ? loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
              ?.enableOutboundTruckFinding
            ? ['inprogressOutboundCalls', 'rateCollected']
            : ['rateCollected']
          : loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
              ?.enableInboundTruckFinding
          ? ['inprogressInboundCalls', 'rateCollected']
          : ['rateCollected'];

        if (allowedKeys.length > 1) {
          return (currentTimerIndex =
            mainIndex === allowedKeys[0] ? allowedKeys[1] : allowedKeys[0]);
        } else {
          return (currentTimerIndex = allowedKeys[0]);
        }
      });
    };

    const startRateCollectedInterval = () => {
      rateCollectedInterval = setInterval(() => {
        manageRateCollectedInterval();
      }, 5000);
    };

    const manageInboundInterval = () => {
      if (
        !loadLinkPostingDetail?.isCovered &&
        truckFindingAiDetail?.acceptInboundCallDuration > 0
      ) {
        setTimeout(() => {
          const updatedAt = moment.utc(
            truckFindingAiDetail?.updatedAt,
            'YYYY-MM-DD HH:mm:ss'
          );
          const currentGMT = moment().tz('GMT');
          const elapsedSeconds = currentGMT.diff(updatedAt, 'seconds');
          const totalSeconds =
            truckFindingAiDetail?.acceptInboundCallDuration * 60;
          const remainingSeconds = totalSeconds - elapsedSeconds;

          if (remainingSeconds > 0) {
            const minutes = Math.floor(remainingSeconds / 60);
            const seconds = remainingSeconds % 60;

            if (currentTimerIndex != 'rateCollected') {
              setTimerActiveIndex(
                `inprogressInboundCalls_${minutes}_${seconds}`
              );
            }
          } else {
            if (inboundInterval) {
              clearInterval(inboundInterval);
              inboundInterval = null;

              if (rateCollectedInterval) {
                clearInterval(rateCollectedInterval);
                rateCollectedInterval = null;

                if (loadLinkPostingDetail) {
                  loadLinkPostingDetail.isCovered = 1;
                  setLoadLinkPostingDetail(loadLinkPostingDetail);
                }
                setCallStatsResponse({
                  inprogressInboundCalls: inboundInprogressCount,
                  inprogressOutboundCalls: outboundInprogressCount,
                  rateCollected: rateCount,
                });
                startRateCollectedInterval();
              }
            }
          }
        }, 30);
      }
    };

    if (
      !loadLinkPostingDetail?.isCovered &&
      truckFindingAiDetail?.acceptInboundCallDuration > 0
    ) {
      if (!inboundInterval) {
        inboundInterval = setInterval(() => {
          manageInboundInterval();
        }, 1000);
      }
    } else {
      setTimeout(() => {
        setShowTimerLoader(false);
      }, 500);
    }

    if (!rateCollectedInterval) {
      startRateCollectedInterval();
    }

    return () => {
      if (inboundInterval) clearInterval(inboundInterval);
      if (rateCollectedInterval) clearInterval(rateCollectedInterval);
    };
  }, [
    loadLinkPostingDetail,
    truckFindingAiDetail?.acceptInboundCallDuration,
    truckFindingAiDetail?.updatedAt,
    newTimerActiveIndex,
  ]);

  useEffect(() => {
    if (!isSyncingLiveData) {
      setLeadsData([]);
      setLeadCount(0);
      setRateCount(0);
      getLead();
    }
  }, [params, isRefresh, activePostIndex]);

  useEffect(() => {
    if (isSyncingLiveData) {
      getLead(false);
    }
  }, [isSyncingLiveData]);

  useEffect(() => {
    if (loadLinkPosts && loadLinkPosts.length > 0) {
      const selectBoxOptions = loadLinkPosts.map((loadLink: any) => ({
        value: loadLink.id,
        label: `#${loadLink.refNumber}`,
        customLabel: `REF: `,
        datetime: `${moment(loadLink.createdAt).format('MMM D, YYYY h:mm A')}`,
      }));

      setFilterByPostOptions(selectBoxOptions);
    }
  }, [loadLinkPosts]);

  const LoadBoardLeadsCells = useMemo(
    () => [
      {
        id: LoadBoardCellId.Carrier,
        name: 'Carrier',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Origin,
        name: 'Origin',
        sortable: true,
      },
      {
        id: LoadBoardCellId.DFO,
        name: 'DFO',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Destination,
        name: 'Destination',
        sortable: true,
      },
      {
        id: LoadBoardCellId.DFD,
        name: 'DFD',
        sortable: true,
      },
      {
        id: LoadBoardCellId.EquipmentType,
        name: 'Equipment Type',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Comment,
        name: 'Comments',
        sortable: true,
      },
    ],
    []
  );

  const LoadBoardRatesCells = useMemo(
    () => [
      {
        id: LoadBoardCellId.Carrier,
        name: 'Carrier',
        sortable: true,
      },
      {
        id: LoadBoardCellId.CallStatus,
        name: 'Call Status',
        sortable: true,
      },
      {
        id: LoadBoardCellId.OutreachMethod,
        name: 'Outreach Method',
        sortable: true,
      },

      {
        id: LoadBoardCellId.EquipmentType,
        name: 'Equipment Type',
        sortable: true,
      },
      {
        id: LoadBoardCellId.EstDeliveryDate,
        name: 'Est. Delivery Date',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Rate,
        name: 'Rate',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Notes,
        name: 'Additional Notes',
        sortable: true,
      },
    ],
    []
  );

  const onTruckFindingAiModalSubmit = (formDataPayload: any) => {
    loadLinkPostingDetail.truckFindingAiData = formDataPayload;
    const truckFindingAiData = formDataPayload.truckFindingAiData;
    truckFindingAiData.loadLinkPostingId = loadLinkPostingDetail.id;
    truckFindingAiData.acceptInboundCallDuration =
      truckFindingAiData.acceptInboundCallDuration
        ? truckFindingAiData.acceptInboundCallDuration
            .replace(/,|minutes?/g, '')
            .trim()
        : null;
    truckFindingAiData.outgoingCallStartAfter =
      truckFindingAiData.outgoingCallStartAfter
        ? truckFindingAiData.outgoingCallStartAfter
            .replace(/,|minutes?/g, '')
            .trim()
        : null;
    truckFindingAiData.totalRates = truckFindingAiData.totalRates
      ? truckFindingAiData.totalRates.replace(/,|rates?/g, '').trim()
      : null;
    truckFindingAiData.budgetCost = truckFindingAiData.budgetCost
      ? truckFindingAiData.budgetCost.replace(/[$,\s]/g, '')
      : null;

    setIsShowTruckFindingAiModal(false);
    setIsRateUpdateLoading(true);

    updateRateGathering(truckFindingAiData)
      .then((response: any) => {
        WalToast.success(response?.message);
      })
      .finally(() => {
        setShowTimerLoader(true);
        setIsRateUpdateLoading(false);
      })
      .catch((e: any) => {
        WalToast.error('Something went wrong while creating load link post.');
        console.log(e);
      });
  };

  const handleLoadPostingDelete = (status: boolean) => {
    if (status) {
      setIsLoadLinkPostingDeleteLoading(true);
      deleteLoadLinkPosting(loadLinkPostingDetail?.id)
        .then(() => {
          WalToast.success(
            `Load link posting was removed from WALHQ & Load Link`
          );
          setShowTimerLoader(true);
          setLoadLinkPostingDetail(undefined);
          setCurrentTab(QuoteType.LOAD_BOARD);
          setIsRefresh(true);
        })
        .catch((e) => {
          WalToast.error(
            e?.response?.data?.message ??
              'Something went wrong while deleting Load Link Posting.'
          );
        })
        .finally(() => {
          setIsLoadLinkPostingDeleteLoading(false);
          setIsShowLoadLinkPostingDeleteConfirmation(false);
        });
    } else {
      setIsShowLoadLinkPostingDeleteConfirmation(false);
    }
  };

  const leadClassificationArray = [
    {
      value: 'leads',
      name: (
        <div data-value="leads">
          Leads
          <BadgeCmp
            type="primary"
            style="modern"
            dataValue="leads"
            isHidePillDot
            mainClassName={`bg-white ml-2 ${isLoading ? 'custom-loading' : ''}`}
          >
            {leadCount}
          </BadgeCmp>
        </div>
      ),
    },
    {
      value: 'rates',
      name: (
        <div data-value="rates">
          Rates
          <BadgeCmp
            type="primary"
            style="modern"
            dataValue="rates"
            isHidePillDot
            mainClassName={`bg-white ml-2 ${isLoading ? 'custom-loading' : ''}`}
          >
            {rateCount}
          </BadgeCmp>
        </div>
      ),
    },
  ];

  return (
    <>
      {(isCreateLoadPost && !loadLinkPostingDetail) ||
      (isUpdateLoadPost && loadLinkPostingDetail) ? (
        <CreateLoadPostingFrom
          addressDetails={addressDetails}
          validatePostToLoadBoardForm={validatePostToLoadBoardForm}
          setValidatePostToLoadBoardForm={setValidatePostToLoadBoardForm}
          setIsPostCreateLoading={setIsPostCreateLoading}
          setIsRefresh={setIsRefresh}
          loadLinkPostingDetail={loadLinkPostingDetail}
          isUpdateLoadPost={isUpdateLoadPost}
          setIsUpdateLoadPost={setIsUpdateLoadPost}
          setIsCreateLoadPost={setIsCreateLoadPost}
          rateGatheringWithAi={rateGatheringWithAi}
          setShowTimerLoader={setShowTimerLoader}
          setCurrentTab={setCurrentTab}
          vehicleSizeOptions={vehicleSizeOptions}
        />
      ) : (
        <>
          <div className="table-bottom-header table-tab-wrap border-b border-utilityGray200 items-center">
            <div className="table-header-bottom-left">
              <TabButton
                className="min-w-32"
                tabArray={leadClassificationArray}
                isTab={true}
                isActive={activeLeadType}
                handleOnClick={(e: any) => {
                  setActiveLeadType(e?.target?.dataset?.value);
                  handlePagination(1);
                }}
              />
              <div
                className={`w-fit bg-white text-textSecondary items-center text-center rounded-md border border-borderPrimary text-xs whitespace-nowrap font-semibold sm:flex hidden ${
                  isLoading ? 'custom-loading' : ''
                }`}
              ></div>
            </div>

            {truckFindingAiDetail?.enableTruckFinding && (
              <div
                className={`rounded-lg border border-borderPrimary flex gap-2 items-center px-2 lg:py-[9px] py-2 w-[250px] ${
                  showTimerLoader ? 'custom-loading' : ''
                }`}
              >
                <img src={aiStarGif} className="w-4 h-4 text-primary" />
                <div className="flex items-center flex-1">
                  <p className="text-grayLight600 text-xs font-semibold line-clamp-1">
                    {callStats && callStats[timerActiveIndex.split('_')[0]]
                      ? (() => {
                          const callStat =
                            callStats[timerActiveIndex.split('_')[0]];
                          const hasTotalRates =
                            truckFindingAiDetail &&
                            'totalRates' in truckFindingAiDetail;
                          const rateMismatch =
                            hasTotalRates &&
                            callStat.count > 0 &&
                            timerActiveIndex.split('_')[0] ===
                              'rateCollected' &&
                            truckFindingAiDetail.totalRates > callStat.count;
                          const rateText = rateMismatch
                            ? `${callStat.count}/${truckFindingAiDetail.totalRates}`
                            : `${callStat.count}`;

                          const minutes = timerActiveIndex.split('_')[1];
                          const seconds = timerActiveIndex.split('_')[2];
                          let timeDisplay = '';

                          if (minutes && seconds) {
                            const totalMinutes = parseInt(minutes, 10);
                            const displaySeconds = seconds.padStart(2, '0');

                            if (totalMinutes >= 60) {
                              const hours = Math.floor(totalMinutes / 60);
                              const remainingMinutes = totalMinutes % 60;
                              timeDisplay = ` ${hours
                                .toString()
                                .padStart(2, '0')}:${remainingMinutes
                                .toString()
                                .padStart(2, '0')}:${displaySeconds}`;
                            } else {
                              timeDisplay = ` ${totalMinutes
                                .toString()
                                .padStart(2, '0')}:${displaySeconds}`;
                            }
                          }

                          return `${rateText} ${callStat.text}${timeDisplay}`;
                        })()
                      : ''}
                  </p>
                </div>
                {((loadLinkPostingDetail?.isCovered &&
                  callStats.inprogressOutboundCalls?.count > 0) ||
                  (!loadLinkPostingDetail?.isCovered &&
                    callStats.inprogressInboundCalls?.count > 0)) &&
                  timerActiveIndex != 'rateCollected' && (
                    <div className="relative">
                      <div className="w-4 h-4 rounded-full border-2 border-utilityGray100"></div>
                      <div className="w-4 h-4 rounded-full animate-spin absolute top-0 border-2 border-primary border-t-transparent"></div>
                    </div>
                  )}
              </div>
            )}

            <div className="min-w-[150px]">
              <SelectBox
                className="form_control"
                size="md"
                placeholder="Filter by post"
                isClearable={false}
                isSearchable={false}
                options={filterByPostOptions}
                onChangeFunc={(event: any) => {
                  const index = filterByPostOptions.findIndex(
                    (option: any) => event?.value === option.value
                  );
                  setTruckFindingAiDetail(
                    loadLinkPosts[index]?.loadLinkTruckFindingAis?.[0]
                  );
                  setLoadLinkPostingDetail(loadLinkPosts[index]);
                  setActivePostIndex(index);
                }}
                value={filterByPostOptions.filter(
                  (option: any) =>
                    loadLinkPosts.length > 0 &&
                    loadLinkPosts[activePostIndex].id === option.value
                )}
              />
            </div>

            <ButtonCmp
              className="btn_secondary_black lg:!px-[9px] !px-2"
              onClick={() => setIsUpdateLoadPost(true)}
              disabled={isUpdateLoadPost}
            >
              <Edit05 className="w-4 h-4" />
            </ButtonCmp>
            <ButtonCmp
              className="btn_secondary_black lg:!px-[9px] !px-2"
              onClick={() => setIsShowLoadLinkPostingDeleteConfirmation(true)}
              disabled={isLoadLinkPostingDeleteLoading}
              loading={isLoadLinkPostingDeleteLoading}
            >
              <Trash01 className="w-4 h-4" />
            </ButtonCmp>

            {loadLinkPostingDetail?.isExpired ? (
              <ActionTooltip
                message={'Post is expired'}
                openOnHover={true}
                isOpenChildTopPosOnTop={true}
                type="dark"
                tooltipPosition="center"
                parentClassName="w-max"
              >
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  disabled={true}
                >
                  <Settings01 className="w-4 h-4" />
                </ButtonCmp>
              </ActionTooltip>
            ) : (
              <ButtonCmp
                className="btn_secondary_black lg:!px-[9px] !px-2"
                loading={isRateUpdateLoading}
                disabled={isRateUpdateLoading}
                onClick={() => {
                  setIsShowTruckFindingAiModal(true);
                }}
              >
                {!isRateUpdateLoading && <Settings01 className="w-4 h-4" />}
              </ButtonCmp>
            )}

            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>

          <div
            className={`w-full ${
              !leadsData?.length ? 'flex flex-col flex-1' : ''
            }`}
          >
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={
                  activeLeadType == 'leads'
                    ? LoadBoardLeadsCells
                    : LoadBoardRatesCells
                }
                tableDataArr={leadsData}
                TableLoaderRowCmp={
                  activeLeadType == 'leads'
                    ? LoadBoardRowLoader
                    : LoadBoardRatesLoader
                }
                TableRowCmp={
                  activeLeadType == 'leads' ? LoadBoardRow : LoadBoardRateRow
                }
                params={params}
                setParams={setParams}
                isTableDataLoading={isLoading}
              />
            </div>

            {!leadsData?.length && !isLoading && (
              <NotFoundUI
                title={`No ${
                  activeLeadType.charAt(0).toUpperCase() +
                  activeLeadType.slice(1)
                } Found`}
                desc={`There are no ${activeLeadType} available for this load.`}
                containerClassName="flex-1 !h-auto"
              />
            )}
          </div>
          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={activeLeadType == 'leads' ? leadCount : rateCount}
            currentPage={params.page}
            handlePagination={handlePagination}
          />
        </>
      )}

      {isShowLoadLinkPostingDeleteConfirmation && (
        <CommonModal
          title="Remove Load Link Posting"
          titleDesc="This action will remove load link posting from WAL HQ and Load Link."
          handleClose={() =>
            isLoadLinkPostingDeleteLoading
              ? false
              : handleLoadPostingDelete(false)
          }
          secondaryBtnOnClick={() => handleLoadPostingDelete(false)}
          dangerBtnText="Remove"
          secondaryBtnText="Cancel"
          primaryBtnOnClick={() => handleLoadPostingDelete(true)}
          primaryBtnLoading={isLoadLinkPostingDeleteLoading}
          primaryBtnDisabled={isLoadLinkPostingDeleteLoading}
          secondaryBtnDisabled={isLoadLinkPostingDeleteLoading}
          size="max-w-[400px]"
          modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
          modalHeaderTextClass="!flex-col"
          modalFooterClass="border-t-0 pt-0"
        >
          &nbsp;
        </CommonModal>
      )}

      {isShowTruckFindingAiModal && (
        <TruckFindingAi
          title={`Edit AI Rate Gathering Settings`}
          postCreatePayload={{}}
          truckFindingAiDetail={truckFindingAiDetail}
          rateGatheringWithAi={rateGatheringWithAi}
          handleClose={() => {
            setIsShowTruckFindingAiModal(false);
          }}
          handleModalSubmit={onTruckFindingAiModalSubmit}
        />
      )}
    </>
  );
};

export default PostToLoadBoard;
