import {
  ClockFastForward,
  Edit05,
  Trash01,
  UserPlus01,
} from '@untitled-ui/icons-react/build/cjs';
import { Check } from 'lucide-react';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import { getShortName, onError, usePhone } from 'src/utils/CommonFunctions';

const ContactTabOrderRaw = ({
  data,
  handleDeleteContacts,
  handleEditContacts,
  setAction,
}: any) => {
  const { getFormatPhoneWithPlus } = usePhone();

  return (
    <>
      <td className={`px-5 py-4 w-[20%] min-w-[25%] max-w-72`}>
        <div className="flex items-center gap-3">
          <div className="table-profile-wrap notification-mark-wrap">
            {data.image ? (
              <img
                src={`${data.imageUrl}${data.image}`}
                className="table-profile-icn-circle-xl rounded-md"
                alt={data.contactFullName}
                title={data.contactFullName}
                onError={onError}
              />
            ) : (
              <div className="table-profile-customer-circle-xl rounded-md">
                {getShortName(`${data.contactFullName}`)}
              </div>
            )}
          </div>

          <div className="w-[calc(100%_-_58px)]">
            <h6 className="">{data.contactFullName}</h6>
          </div>
        </div>
      </td>
      {/* <td className="px-5 py-4 w-[20%] min-w-[25%] max-w-72">
        <h6 className="truncate">{data?.contactFullName}</h6>
      </td> */}
      <td className="px-5 py-4 w-[25%] min-w-[25%] max-w-64 ">
        <span>{data?.contactRole}</span>
      </td>
      <td className="px-5 py-4 w-[25%] min-w-[25%] max-w-64  ">
        <span>{getFormatPhoneWithPlus(data?.contactPhone)}</span>
      </td>
      <td className="px-5 py-4 w-[25%] min-w-[25%] max-w-64 ">
        {/* <p className="truncate">
          {data?.isActive === null
            ? 'Not Invited'
            : data?.isActive
            ? 'Active'
            : 'Pending'}
        </p> */}
        <div className="flex">
          {data?.isActive ? (
            <BadgeCmp style="pill" type="success">
              <Check className="text-green500 mr-1 h-3 w-3" /> Active
            </BadgeCmp>
          ) : (
            <BadgeCmp style="pill" type="warning">
              <ClockFastForward className="mr-1 h-3 w-3 text-lg text-warning500" />{' '}
              Pending
            </BadgeCmp>
          )}
        </div>
      </td>
      <td className="px-5 py-4 w-[25%] min-w-[25%] max-w-64 ">
        <p className="truncate">{data?.contactEmail}</p>
      </td>
      <td className="px-5 py-4 max-w-30 w-30 min-w-30">
        <div className="justify-end items-center gap-2 flex ">
          {!data?.isActive && (
            <UserPlus01
              className="w-4 h-4 relative cursor-pointer"
              onClick={() => setAction({ mode: 'invite-member', data: data })}
            />
          )}
          <Edit05
            className="w-4 h-3.5 relative cursor-pointer"
            onClick={() => handleEditContacts(data)}
          />
          <Trash01
            className="w-4 h-4 relative cursor-pointer"
            onClick={() => handleDeleteContacts(data)}
          />
        </div>
      </td>
    </>
  );
};

export default ContactTabOrderRaw;
