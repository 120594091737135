import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
import StepBar from 'src/components/StepBar/StepBar';
import { PATH } from 'src/constants/path';
import { usePhone } from 'src/hooks/usePhone';
import { updateLegOrder } from 'src/services/OrderService';
import { listShippers } from 'src/services/ShipperService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';
import * as Yup from 'yup';

import { OrderContext } from '..';
import { STEPS_FOR_LEG_FORM } from '../../order.constant';
import {
  createDateTimeValidation,
  defualtOrderValue,
  dimensionObj,
  initAction,
  initShipperAddress,
  setAppointmentValues,
} from '../../order.interface';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

export const step1Schema = Yup.object({
  shipper: Yup.object({
    companyName: Yup.string()
      .required('Company name is required')
      .min(2, 'Company name should be at least 2 characters'),
    contactName: Yup.string()
      .required('Contact name is required')
      .min(2, 'Contact name should be at least 2 characters'),
    phoneNumber: Yup.string()
      .test('isValidPhone', function (value) {
        const { phone_country_code: phoneCountryCode } = this.parent;

        const { isPhoneValid, isGetCountryCallingCode } = usePhone();

        if (
          value?.length === isGetCountryCallingCode(phoneCountryCode)?.length
        ) {
          return this.createError({
            message: 'Contact number is required.',
          });
        }

        const isValid = isPhoneValid(value, phoneCountryCode);

        if (!isValid) {
          return this.createError({
            message: 'Invalid phone number.',
          });
        }

        return true;
      })
      .required('Phone number is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),
    shipperAddress: Yup.object({
      fullAddress: Yup.string().required('Shipper address is required'),
    }),
  }),

  expectedPickupTime: Yup.string().required('Pickup date is required'),
});

const step2Schema = Yup.object().shape({
  consignee: Yup.object({
    companyName: Yup.string()
      .required('Company name is required')
      .min(2, 'Company name should be at least 2 characters'),
    contactName: Yup.string()
      .required('Contact name is required')
      .min(2, 'Contact name should be at least 2 characters'),
    phoneNumber: Yup.string()
      .test('isValidPhone', function (value) {
        const { phone_country_code: phoneCountryCode } = this.parent;

        const { isPhoneValid, isGetCountryCallingCode } = usePhone();

        if (
          value?.length === isGetCountryCallingCode(phoneCountryCode)?.length
        ) {
          return this.createError({
            message: 'Contact number is required.',
          });
        }

        const isValid = isPhoneValid(value, phoneCountryCode);

        if (!isValid) {
          return this.createError({
            message: 'Invalid phone number.',
          });
        }

        return true;
      })
      .required('Phone number is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),
    consigneeAddress: Yup.object({
      fullAddress: Yup.string().required('Consignee address is required'),
    }),
  }),

  expectedEstimatedDeliveryTime: Yup.string().required(
    'Delivery date is required'
  ),
});

const step3Schema = Yup.object().shape({
  ...createDateTimeValidation(
    'shipperAppointmentDate',
    'shipperAppointmentTime'
  ),
  ...createDateTimeValidation(
    'carrierPickupAppointmentDate',
    'carrierPickuAppointmentTime'
  ),
  ...createDateTimeValidation(
    'deliveryAppointmentDate',
    'deliveryAppointmentTime'
  ),
  ...createDateTimeValidation(
    'carrierDeliveryAppointmentDate',
    'carrierDeliveryAppointmentTime'
  ),
});

const step4Schema = Yup.object().shape({
  poNumber: Yup.string().nullable(),
  refNumber: Yup.string().nullable(),
  linearFootage: Yup.string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
  totalWeight: Yup.string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
  declaredValue: Yup.string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
  specialNotes: Yup.string()
    .max(500, 'Special notes cannot exceed 500 characters')
    .nullable(),

  order_dimensions: Yup.array().of(
    Yup.object().shape({
      handlingUnit: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable(),

      handlingUnitNo: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .test(
          'conditional-required',
          'If any field is entered, all fields are required',
          function (value) {
            const { length, width, height, totalWeight, refNumber } =
              this.parent;

            const { orderType } = this.options.context as any;

            if (
              orderType === 'ltl' ||
              length ||
              width ||
              height ||
              totalWeight ||
              refNumber
            ) {
              return value !== null && value !== undefined;
            }

            return true;
          }
        ),

      length: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .min(1, 'Length must be at least 1')
        .test(
          'conditional-required',
          'If any field is entered, all fields are required',
          function (value) {
            const { handlingUnitNo, width, height, totalWeight, refNumber } =
              this.parent;
            const { orderType } = this.options.context as any;

            if (
              orderType === 'ltl' ||
              handlingUnitNo ||
              width ||
              height ||
              totalWeight ||
              refNumber
            ) {
              return value !== null && value !== undefined;
            }

            return true;
          }
        ),

      width: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .min(1, 'Width must be at least 1')
        .test(
          'conditional-required',
          'If any field is entered, all fields are required',
          function (value) {
            const { handlingUnitNo, length, height, totalWeight, refNumber } =
              this.parent;

            const { orderType } = this.options.context as any;

            if (
              orderType === 'ltl' ||
              handlingUnitNo ||
              length ||
              height ||
              totalWeight ||
              refNumber
            ) {
              return value !== null && value !== undefined;
            }

            return true;
          }
        ),

      height: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .min(1, 'Height must be at least 1')
        .test(
          'conditional-required',
          'If any field is entered, all fields are required',
          function (value) {
            const { handlingUnitNo, length, width, totalWeight, refNumber } =
              this.parent;

            const { orderType } = this.options.context as any;

            if (
              orderType === 'ltl' ||
              handlingUnitNo ||
              length ||
              width ||
              totalWeight ||
              refNumber
            ) {
              return value !== null && value !== undefined;
            }

            return true;
          }
        ),

      totalWeight: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .min(1, 'Total weight must be at least 1')
        .test(
          'conditional-required',
          'If any field is entered, all fields are required',
          function (value) {
            const { handlingUnitNo, length, width, height, refNumber } =
              this.parent;
            const { orderType } = this.options.context as any;

            if (
              orderType === 'ltl' ||
              handlingUnitNo ||
              length ||
              width ||
              height ||
              refNumber
            ) {
              return value !== null && value !== undefined;
            }

            return true;
          }
        ),

      freightClass: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable(),
      refNumber: Yup.string()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable(),
      isStack: Yup.boolean().nullable(),
    })
  ),
});

const steps = [
  {
    title: 'Add Shipper',
    description: 'Please provide the shipper details for your order.',
  },
  {
    title: 'Add Consignee',
    description: 'Please provide the consignee details for your order.',
  },
  {
    title: 'Add Pickup and Delivery Dates',
    description: 'Pick dates for when the pickup and delivery should occur.',
  },
  {
    title: 'Add Order Details',
    description: 'Please add the details of your order before proceeding.',
  },
  {
    title: 'Add Carrier & Sale Price',
    description: 'Select the carrier and set the sale price for your order.',
  },
];

const LegForm = forwardRef((props, ref) => {
  const navigate = useNavigate();

  const savedStep = localStorage.getItem('orderLegFormCurrentStep');
  const initialStep = savedStep ? parseInt(savedStep, 10) : 1;
  const { title: titleForStepper, description: descriptionForStepper } =
    steps[initialStep - 1] || steps[0];

  const [action, setAction] = useState(initAction);
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [stepperTitle, setStepperTitle] = useState(titleForStepper);
  const [stepperDescription, setStepperDescription] = useState(
    descriptionForStepper
  );
  const [tempLegData, setTempLegData] = useState(null);

  const [isShipperContactBtnDisable, setIsShipperContactBtnDisable] =
    useState(false);
  const [isConsigneeContactBtnDisable, setIsConsigneeContactBtnDisable] =
    useState(false);
  const [shipperContact, setShipperContact] = useState<any>([]);
  const [shipperContactOptions, setShipperContactOptions] = useState<any>([]);
  const [consigneeContact, setConsigneeContact] = useState<any>([]);
  const [consigneeContactOptions, setConsigneeContactOptions] = useState<any>(
    []
  );
  const [shipperAddress, setShipperAddress] = useState<any>(initShipperAddress);
  const [consigneeAddress, setConsigneeAddress] =
    useState<any>(initShipperAddress);
  const [shippers, setShippers] = useState<any>([]);
  const [consignees, setConsignees] = useState<any>([]);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [consigneeOptions, setConsigneeOptions] = useState([]);
  const [isValidAddress, setIsValidAddress] = useState({
    shipperAddress: true,
    shipperId: true,
    consigneeAddress: true,
    consigneeId: true,
    shipperCompanyName: true,
    consigneeCompanyName: true,
    shipperContactName: true,
    consigneeContactName: true,
    shipperEmail: true,
    consigneeEmail: true,
    shipperPhoneNumber: true,
    consigneePhoneNumber: true,
  });
  const [isConsigneeBtnDisable, setIsConsigneeBtnDisable] = useState(false);
  const [isShipperBtnDisable, setIsShipperBtnDisable] = useState(false);

  const [dimensions, setDimensions] = useState([dimensionObj]);
  const hasRunRef = useRef(false);

  let orderSchema = step1Schema;

  const {
    currentLegData,
    setCurrentLegData,
    order,
    refreshPage,
    setIsMasterOrder,
    setSalesPrice,
    setCarrierPrice,
    setTabValues,
    activeLeg,
  } = useContext<any>(OrderContext);

  if (currentStep === 1) {
    orderSchema = step1Schema;
  } else if (currentStep === 2) {
    orderSchema = step2Schema as unknown as typeof step1Schema;
  } else if (currentStep === 3) {
    orderSchema = step3Schema as unknown as typeof step1Schema;
  } else if (currentStep === 4) {
    orderSchema = step4Schema as unknown as typeof step1Schema;
  }

  const methods = useForm({
    resolver: yupResolver(orderSchema) as Resolver<any>,
    defaultValues: defualtOrderValue,
    context: {
      orderType: order?.orderType,
    },
  });

  const { handleSubmit, trigger, reset, getValues } = methods;

  useEffect(() => {
    if (currentLegData && !hasRunRef.current) {
      hasRunRef.current = true;

      const { order_dimensions: orderDimensions, ...rest } = currentLegData;

      const dimensionsData =
        orderDimensions && orderDimensions?.length > 0
          ? orderDimensions
              ?.filter(
                (dimension: any) =>
                  dimension?.pickupLegId === currentLegData?.id
              )
              ?.map((dimension: any) => ({
                ...dimension,
              }))
              .filter(Boolean)
          : [];

      const {
        shipperAppointmentDate,
        shipperAppointmentTime,
        carrierPickupAppointmentDate,
        carrierPickuAppointmentTime,
        deliveryAppointmentDate,
        deliveryAppointmentTime,
        carrierDeliveryAppointmentDate,
        carrierDeliveryAppointmentTime,
      } = setAppointmentValues(currentLegData);

      reset({
        ...rest,
        expectedPickupTime: rest?.expectedPickupTime
          ? rest?.expectedPickupTime
          : moment().local().toDate(),
        expectedEstimatedDeliveryTime: rest?.expectedEstimatedDeliveryTime
          ? rest?.expectedEstimatedDeliveryTime
          : moment().local().toDate(),
        order_dimensions:
          dimensionsData?.length > 0 ? dimensionsData : [dimensionObj],
        declaredValue: order?.declaredValue,
        declaredValueUnit: order?.declaredValueUnit ?? 'CAD',
        shipperAppointmentDate: shipperAppointmentDate,
        shipperAppointmentTime: shipperAppointmentTime,
        carrierPickupAppointmentDate: carrierPickupAppointmentDate,
        carrierPickuAppointmentTime: carrierPickuAppointmentTime,
        deliveryAppointmentDate: deliveryAppointmentDate,
        deliveryAppointmentTime: deliveryAppointmentTime,
        carrierDeliveryAppointmentDate: carrierDeliveryAppointmentDate,
        carrierDeliveryAppointmentTime: carrierDeliveryAppointmentTime,
      });

      setSalesPrice(currentLegData?.salesPrice);
      setCarrierPrice(currentLegData?.carrierPrice);
      setDimensions(currentLegData?.order_dimensions);
      setTempLegData(currentLegData);
    }
  }, [currentLegData]);

  const getShipper = () => {
    listShippers()
      .then((response: any) => {
        if (response) {
          let shipperOptionsValue: any = [];
          let consigneeOptionsValue: any = [];
          response.data.forEach((result: any) => {
            if (result.type === 1) {
              shipperOptionsValue.push(result);
            } else {
              consigneeOptionsValue.push(result);
            }
          });
          setShippers(shipperOptionsValue);
          setConsignees(consigneeOptionsValue);
          shipperOptionsValue = getSelectBoxOptions(
            shipperOptionsValue,
            'id',
            'companyName'
          );
          consigneeOptionsValue = getSelectBoxOptions(
            consigneeOptionsValue,
            'id',
            'companyName'
          );
          setShipperOptions(shipperOptionsValue);
          setConsigneeOptions(consigneeOptionsValue);
        }
      })
      .finally(() => {
        // setIsShipperLoading(false)
      })
      .catch(console.error);
  };

  useEffect(() => {
    getShipper();

    return () => {
      setTabValues((prevTabValues: any) => ({
        ...prevTabValues,
        [activeLeg]: {
          ...getValues(),
          order_dimensions: getValues()?.order_dimensions?.map(
            (dimension: any) => ({
              ...dimension,
              pickupLegId: currentLegData?.id,
            })
          ),
        },
      }));
    };
  }, []);

  const onSubmit = (data: any) => {
    const currentData = { ...currentLegData, ...data };

    updateLegOrder({
      ...data,
      masterOrderId: order?.id,
      orderLegId: currentLegData?.id,
      shipperCompanyName: currentData?.shipper?.companyName,
      shipperContactName: currentData?.shipper?.contactName,
      shipperContactNumber: currentData?.shipper?.phoneNumber,
      shipperEmail: currentData?.shipper?.email,
      shipperId: currentData?.shipper?.id,
      shipperContactId: currentData?.shipper?.contactId,
      shipperFullAddress: currentData?.shipper?.shipperAddress?.fullAddress,
      shipperAddress1: currentData?.shipper?.shipperAddress?.address1,
      shipperCity: currentData?.shipper?.shipperAddress?.city,
      shipperState: currentData?.shipper?.shipperAddress?.state,
      shipperCountry: currentData?.shipper?.shipperAddress?.country,
      shipperPostal: currentData?.shipper?.shipperAddress?.postal,
      shipperLatitude: currentData?.shipper?.shipperAddress?.latitude,
      shipperLongitude: currentData?.shipper?.shipperAddress?.longitude,
      shipperStateCode: currentData?.shipper?.shipperAddress?.stateCode,
      shipperCountryCode: currentData?.shipper?.shipperAddress?.countryCode,
      consigneeId: currentData?.consignee?.id,
      consigneeContactId: currentData?.consignee?.contactId,
      consigneeCompanyName: currentData?.consignee?.companyName,
      consigneeContactName: currentData?.consignee?.contactName,
      consigneeContactNumber: currentData?.consignee?.phoneNumber,
      consigneeEmail: currentData?.consignee?.email,
      consigneeFullAddress:
        currentData?.consignee?.consigneeAddress?.fullAddress,
      consigneeAddress1: currentData?.consignee?.consigneeAddress?.address1,
      consigneeCity: currentData?.consignee?.consigneeAddress?.city,
      consigneeState: currentData?.consignee?.consigneeAddress?.state,
      consigneeCountry: currentData?.consignee?.consigneeAddress?.country,
      consigneePostal: currentData?.consignee?.consigneeAddress?.postal,
      consigneeLatitude: currentData?.consignee?.consigneeAddress?.latitude,
      consigneeLongitude: currentData?.consignee?.consigneeAddress?.longitude,
      consigneeStateCode: currentData?.consignee?.consigneeAddress?.stateCode,
      consigneeCountryCode:
        currentData?.consignee?.consigneeAddress?.countryCode,
      loadInsurance: currentData?.loadInsurance,
      expectedPickupTime: data?.expectedPickupTime
        ? moment(data?.expectedPickupTime).format('YYYY-MM-DD')
        : null,
      expectedEstimatedDeliveryTime: data?.expectedEstimatedDeliveryTime
        ? moment(data?.expectedEstimatedDeliveryTime).format('YYYY-MM-DD')
        : null,
      carrierPickupAppointmentDatetime: data?.carrierPickupAppointmentDatetime
        ? data?.carrierPickupAppointmentDatetime
        : null,
      carrierDeliveryAppointmentDatetime:
        data?.carrierDeliveryAppointmentDatetime
          ? data?.carrierDeliveryAppointmentDatetime
          : null,
      shipperAppointmentDatetime: data?.shipperAppointmentDatetime
        ? data?.shipperAppointmentDatetime
        : null,
      deliveryAppointmentDatetime: data?.deliveryAppointmentDatetime
        ? data?.deliveryAppointmentDatetime
        : null,
      carrierPickupAppointmentStatus: data?.carrierPickupAppointmentStatus
        ? data?.carrierPickupAppointmentStatus
        : 'Pending',
      carrierDeliveryAppointmentStatus: data?.carrierDeliveryAppointmentStatus
        ? data?.carrierDeliveryAppointmentStatus
        : 'Pending',
      shipperAppointmentStatus: data?.shipperAppointmentStatus
        ? data?.shipperAppointmentStatus
        : 'Pending',
      deliveryAppointmentStatus: data?.deliveryAppointmentStatus
        ? data?.deliveryAppointmentStatus
        : 'Pending',
    })
      .then((response) => {
        if (response?.data) {
          localStorage.removeItem('orderLegFormCurrentStep');

          refreshPage();
          setIsMasterOrder(true);

          navigate(`${PATH.ORDER_VIEW}/${response?.data?.masterOrderId}`, {
            replace: true,
          });
        }
      })
      .catch(console.error);
  };

  const handleCurrentStep = async (stepType: string) => {
    if (stepType !== 'back') {
      const isValid = await trigger();

      if (!isValid) {
        return;
      }

      if (currentStep === 1) {
        if (!isValidAddress?.shipperAddress) {
          return;
        }
      } else if (currentStep === 2) {
        if (!isValidAddress?.consigneeAddress) {
          return;
        }
      }
    }

    if (stepType === 'back') {
      if (currentStep === 2) {
        setIsValidAddress((old) => ({
          ...old,
          consigneeAddress: true,
        }));
      }
    }

    let newCurrentStepCount =
      stepType === 'back' ? currentStep - 1 : currentStep + 1;

    if (newCurrentStepCount === 6) {
      handleSubmit(onSubmit)();
    } else {
      setCurrentStep(newCurrentStepCount);

      localStorage.setItem(
        'orderLegFormCurrentStep',
        newCurrentStepCount.toString()
      );

      if (newCurrentStepCount > 0 && newCurrentStepCount <= steps.length) {
        const { title, description } = steps[newCurrentStepCount - 1];
        setStepperTitle(title);
        setStepperDescription(description);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => handleSubmit(onSubmit)(),
    getCurrentStep: () => currentStep,
  }));

  return (
    <FormProvider {...methods}>
      <div>
        <div className="flex items-center flex-wrap gap-x-2 mb-2">
          <div className="flex-1">
            <h6 className="text-grayLight900 text-sm font-medium">
              {stepperTitle}
            </h6>
            <p className="text-grayLight600 text-xs font-medium">
              {stepperDescription}
            </p>
          </div>
          <div className="flex flex-wrap gap-2">
            <ButtonCmp
              className="btn_secondary_black min-w-[65px] !py-1.5 !rounded-md"
              onClick={() => handleCurrentStep('back')}
              disabled={currentStep === 1}
            >
              Back
            </ButtonCmp>
            <ButtonCmp
              className="btn-outline-primary min-w-[65px] !py-1.5 !rounded-md"
              onClick={() => handleCurrentStep('next')}
            >
              {currentStep === 5 ? 'Save Order' : 'Next'}
            </ButtonCmp>
          </div>
        </div>
        <StepBar
          steps={STEPS_FOR_LEG_FORM}
          activeStep={currentStep}
          variant="progressOnly"
        ></StepBar>
      </div>

      {(currentStep === 1 || currentStep === 2) && (
        <Step1
          action={action}
          setAction={setAction}
          currentLegData={currentLegData}
          setCurrentLegData={setCurrentLegData}
          shippers={shippers}
          consignees={consignees}
          shipperOptions={shipperOptions}
          consigneeOptions={consigneeOptions}
          isValidAddress={isValidAddress}
          setIsValidAddress={setIsValidAddress}
          isConsigneeBtnDisable={isConsigneeBtnDisable}
          setIsConsigneeBtnDisable={setIsConsigneeBtnDisable}
          isShipperBtnDisable={isShipperBtnDisable}
          setIsShipperBtnDisable={setIsShipperBtnDisable}
          getShipper={getShipper}
          tempLegData={tempLegData}
          setTempLegData={setTempLegData}
          shipperAddress={shipperAddress}
          setShipperAddress={setShipperAddress}
          consigneeAddress={consigneeAddress}
          setConsigneeAddress={setConsigneeAddress}
          shipperContact={shipperContact}
          setShipperContact={setShipperContact}
          consigneeContact={consigneeContact}
          setConsigneeContact={setConsigneeContact}
          shipperContactOptions={shipperContactOptions}
          setShipperContactOptions={setShipperContactOptions}
          consigneeContactOptions={consigneeContactOptions}
          setConsigneeContactOptions={setConsigneeContactOptions}
          isShipperContactBtnDisable={isShipperContactBtnDisable}
          setIsShipperContactBtnDisable={setIsShipperContactBtnDisable}
          isConsigneeContactBtnDisable={isConsigneeContactBtnDisable}
          setIsConsigneeContactBtnDisable={setIsConsigneeContactBtnDisable}
          currentStep={currentStep}
        />
      )}

      {currentStep === 3 && <Step2 />}

      {currentStep === 4 && (
        <Step3
          tempLegData={tempLegData}
          setTempLegData={setTempLegData}
          dimensions={dimensions}
          setDimensions={setDimensions}
        />
      )}

      {currentStep === 5 && <Step4 />}
    </FormProvider>
  );
});

export default LegForm;
