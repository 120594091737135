import {
  // ArrowLeft,
  // ArrowRight,
  CurrencyDollar,
  InfoCircle,
  Percent01,
  Plus,
  SwitchHorizontal01,
  SwitchHorizontal02,
} from '@untitled-ui/icons-react/build/cjs';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
import { getPredictivePrice } from 'src/services/QuoteService';
import { fixedDecimal, getFormattedNumber } from 'src/utils/CommonFunctions';

import { predictivePriceOptions } from './PredictivePrice.interface';
// import ReviewRouteModal from './ReviewRouteModal';

const chartFilterTab: any = [
  {
    value: 'weekly',
    name: 'Weekly',
    tooltipValue: '',
  },
  {
    value: 'monthly',
    name: 'Monthly',
    tooltipValue: '',
  },
];

const initialSeries = [
  {
    name: 'Max',
    data: [
      1725, 1600, 1750, 1700, 1650, 1400, 1300, 1300, 1200, 1250, 1200, 1100,
      1150, 1250, 1350,
    ],
  },
];

const PredictivePriceChart = ({
  id,
  setPredictivePriceData,
  predictivePriceData,
  openAddQuickModal,
}: any) => {
  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;
  const [isActiveTab, setIsActiveTab] = useState<string>('weekly');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [series, setSeries] = useState<any>([]);
  // const [isPrevYearDisable, setIsPrevYearDisable] = useState<any>(false);
  // const [isNextYearDisable, setIsNextYearDisable] = useState<any>(false);
  const [isReverseAddress, setIsReverseAddress] = useState(false);
  const [totalCost, setTotalCost] = useState<any>('');
  const [marginPercentage, setMarginPercentage] = useState<any>('');
  const [totalSalePrice, setTotalSalePrice] = useState<any>('');
  const [routeDistance, setRouteDistance] = useState<any>(0);
  const abortControllerRef = useRef<AbortController | null>(null);
  // const [isDisplayReviewModal, setIsDisplayReviewModal] = useState(false);

  const generateQuarterSelections = () => {
    const startYear = 2000;
    const currentYear = new Date().getFullYear(); // This will dynamically get the current year
    const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
    const selections = [];

    for (let year = startYear; year <= currentYear; year++) {
      const maxQuarter = year === currentYear ? currentQuarter : 4;

      for (let quarter = 1; quarter <= maxQuarter; quarter++) {
        selections.push(`Q${quarter}-${year}`);
      }
    }

    return selections;
  };

  const quarterArr = generateQuarterSelections();
  const [dateRange, setDateRange] = useState<any>(
    moment().format(quarterArr[0])
  );
  const [chartOptions, setChartOptions] = useState<any>(predictivePriceOptions);

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  // const [startPoint, setStartPoint] = useState<any>(null);
  // const [endPoint, setEndPoint] = useState<any>(null);

  const getChartData = () => {
    cancelApiCall();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    const filter = {
      activeTab: isActiveTab,
      range: dateRange,
      isReverseAddress: isReverseAddress,
    };

    setIsLoading(true);
    setChartOptions((prevOptions: any) => ({
      ...prevOptions,
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.2,
          opacityTo: 0.5,
          stops: [0, 50, 90, 100],
        },
      },
    }));

    getPredictivePrice(id, filter, signal)
      .then((response: any) => {
        if (response && response.data) {
          const { predictivePricingData } = response.data;
          setPredictivePriceData(predictivePricingData ?? []);
          setSeries(predictivePricingData?.graphData?.series ?? []);
          setMarginPercentage(predictivePricingData.margin);
          setTotalCost(predictivePricingData.total);

          setTimeout(() => {
            setChartOptions((prevOptions: any) => ({
              ...prevOptions,
              fill: {
                type: 'solid',
                colors: ['transparent'],
              },
              xaxis: {
                ...prevOptions.xaxis,
                categories:
                  isActiveTab === 'weekly'
                    ? response.data.xValues.map(
                        (x: string) => x.split(' - ')[0]
                      )
                    : response.data.xValues,
              },
            }));

            setIsLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 100);
        }
      })
      .catch((e: any) => {
        if (e.code !== 'ERR_CANCELED') setIsLoading(false);
      });
  };

  useEffect(() => {
    const margin = (totalCost * marginPercentage) / 100;
    const salePrice = parseFloat(totalCost) + margin;

    setTotalSalePrice(salePrice);
  }, [totalCost, marginPercentage]);

  useEffect(() => {
    if (isActiveTab === 'weekly') {
      setDateRange(quarterArr[quarterArr.length - 1]);
    }

    if (isActiveTab === 'monthly') {
      setDateRange(moment().format('YYYY'));
    }
  }, [isActiveTab]);

  // const getFilterRange = (type: string) => {
  //   if (isActiveTab === 'weekly') {
  //     if (type === 'prev' && !isPrevYearDisable) {
  //       const valKey: any = _.findKey(quarterArr, (val) => val === dateRange);
  //       const newKey = parseInt(valKey, 10) - 1;
  //       const newDateRange = quarterArr[newKey];

  //       if (newDateRange) {
  //         setDateRange(newDateRange);

  //         if (hasRoleV2('user')) {
  //           const [q, year] = newDateRange.split('-');
  //           const quarterNumber = parseInt(q.replace('Q', ''), 10);
  //           const yearNumber = parseInt(year, 10);
  //           const currentYear = new Date().getFullYear();
  //           const boundaryYear = currentYear - 1;
  //           const boundaryQuarter = 1;

  //           if (
  //             yearNumber < boundaryYear ||
  //             (yearNumber === boundaryYear && quarterNumber <= boundaryQuarter)
  //           ) {
  //             setIsPrevYearDisable(true);
  //           }
  //         } else {
  //           setIsPrevYearDisable(!quarterArr[newKey - 1]);
  //         }
  //       } else {
  //         console.error('New date range not found.');
  //       }
  //       setIsNextYearDisable(false);
  //     }

  //     if (type === 'next' && !isNextYearDisable) {
  //       setIsPrevYearDisable(false);
  //       const valKey: any = _.findKey(quarterArr, (val) => val === dateRange);
  //       const valKeyNum = +valKey;
  //       setDateRange(quarterArr[valKeyNum + 1]);
  //       if (!quarterArr[valKeyNum + 2]) setIsNextYearDisable(true);
  //       setIsPrevYearDisable(false);
  //     }
  //   }

  //   if (isActiveTab === 'monthly') {
  //     const currentYear = moment().year();

  //     if (type === 'prev' && !isPrevYearDisable) {
  //       const prevMonth = moment(moment(dateRange, 'YYYY'))
  //         .subtract(1, 'year')
  //         .format('YYYY');
  //       setDateRange(prevMonth);

  //       if (hasRoleV2('user') && currentYear - parseInt(prevMonth) >= 1) {
  //         setIsPrevYearDisable(true);
  //       } else {
  //         setIsNextYearDisable(false);
  //       }
  //     }

  //     if (type === 'next' && !isNextYearDisable) {
  //       setIsPrevYearDisable(false);
  //       const nextMonth = moment(moment(dateRange, 'YYYY'))
  //         .add(1, 'year')
  //         .format('YYYY');
  //       setDateRange(nextMonth);

  //       if (nextMonth === moment().format('YYYY')) {
  //         setIsNextYearDisable(true);
  //       }
  //     }
  //   }
  // };

  const geocodeAddress = async (address: string): Promise<[number, number]> => {
    const query = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${mapboxgl.accessToken}`
    );
    const json = await query.json();

    if (!json.features || json.features.length === 0) {
      throw new Error(`Unable to geocode address: ${address}`);
    }

    const coordinates = json.features[0].center as [number, number];

    return [coordinates[0], coordinates[1]];
  };

  const getDistanceByAddress = async (
    fromAddress: string,
    toAddress: string
  ) => {
    try {
      // Geocode From Address
      const fromCoords = await geocodeAddress(fromAddress);

      // Geocode To Address
      const toCoords = await geocodeAddress(toAddress);
      console.log('fromCoords', fromCoords);

      try {
        const query = await fetch(
          `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${fromCoords.join(
            ','
          )};${toCoords.join(',')}?access_token=${
            mapboxgl.accessToken
          }&geometries=geojson`
          // Pass signal here
        );

        const json = await query.json();

        // Extract the distance (in meters) from the response
        const distance = json?.routes?.[0]?.distance; // Distance in meters

        // Convert the distance to kilometers
        return distance ? distance / 1609.344 : 0;
      } catch (error: any) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching distance data:', error);
        } else {
          console.log('Distance request aborted');
        }

        return 0;
      }
    } catch (error: any) {
      console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    getDistanceByAddress(
      predictivePriceData?.from,
      predictivePriceData?.to
    ).then((distance) => setRouteDistance(distance));
    // setStartPoint({
    //   lat: predictivePriceData.fromLatitude,
    //   lng: predictivePriceData.fromLongitude,
    //   name: null,
    // });

    // setEndPoint({
    //   lat: predictivePriceData.toLatitude,
    //   lng: predictivePriceData.toLongitude,
    //   name: null,
    // });
  }, [predictivePriceData]);

  useEffect(() => {
    getChartData();
  }, [dateRange, isReverseAddress]);

  return (
    <>
      <div id="chart" className="flex p-5">
        <div className="rounded-xl border border-utilityGray200 p-5 w-full flex-1 mr-5">
          <div className="flex sm:flex-row flex-col justify-between gap-2">
            <div className="min-w-[18%] px-3 py-2.5 items-center gap-1 justify-between flex">
              <h4>Predictive Index</h4>
            </div>
            {/* <div className="min-w-[18%] px-3 py-2.5 bg-white rounded-lg shadow border border-gray-300 items-center gap-1 justify-between flex">
              <ArrowLeft
                className={`w-4 h-4  ${
                  isPrevYearDisable
                    ? 'text-grayText cursor-not-allowed'
                    : 'cursor-pointer'
                }`}
                onClick={() => getFilterRange('prev')}
              />
              <div className="px-0.5 justify-center items-center flex">
                <div className="text-center text-textSecondary text-xs font-medium leading-tight">
                  {dateRange}
                </div>
              </div>
              <ArrowRight
                className={`w-4 h-4 ${
                  isNextYearDisable
                    ? 'text-grayText cursor-not-allowed'
                    : 'cursor-pointer'
                }`}
                onClick={() => getFilterRange('next')}
              />
            </div> */}
            <div className="flex gap-x-5">
              <div className="flex gap-x-5">
                <div className="flex items-center gap-x-1.5">
                  <p className="w-2 h-2 rounded-full bg-[#A4BCFD]"></p>
                  <div className="text-textSecondary text-xs font-normal leading-none">
                    Minimum
                  </div>
                </div>
                <div className="flex items-center gap-x-1.5">
                  <p className="w-2 h-2 rounded-full bg-[#3538CD]"></p>
                  <div className="text-textSecondary text-xs font-normal leading-none">
                    Maximum
                  </div>
                </div>
                <div className="flex items-center gap-x-1.5">
                  <p className="w-2 h-2 rounded-full bg-[#6172F3]"></p>
                  <div className="text-textSecondary text-xs font-normal leading-none">
                    Target
                  </div>
                </div>
              </div>

              <TabButton
                tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 "
                parentClassName="w-full currency-tab quoting-currency"
                activeClassName="text-grayLight900 bg-utilityGray100"
                className=""
                isTooltip={true}
                tabArray={chartFilterTab}
                handleOnClick={(e: any) => {
                  setIsActiveTab(e.target.dataset.value);
                }}
                isActive={isActiveTab}
              />
            </div>
          </div>
          <Chart
            options={chartOptions}
            series={isLoading ? initialSeries : series}
            type="area"
            height={350}
            className={`analytics_totalquotes_tooltip ${
              isLoading ? 'apex-line-chart custom-loading graph-loading' : ''
            }`}
          />
        </div>
        <div className="w-[496px] rounded-xl border border-utilityGray200 px-4 py-4 space-y-4">
          <div className="flex items-center gap-9 pb-4 border-b border-[#EAECF0]">
            <div className="w-2/5">
              <TooltipCmp
                message={`${predictivePriceData?.from}`}
                parentClassName="max-w-90"
              >
                <div
                  className={`text-grayLight600 text-sm font-normal truncate cursor-default ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {/* <span className="text-grayLight600">From: </span> */}
                  {predictivePriceData?.from}
                </div>
              </TooltipCmp>
            </div>
            <div
              className="p-1 border rounded-md border-borderPrimary shadow-lg cursor-pointer"
              onClick={() => setIsReverseAddress(!isReverseAddress)}
            >
              {isReverseAddress ? (
                <SwitchHorizontal01 className="w-3.5 h-3.5 text-grayLight900 " />
              ) : (
                <SwitchHorizontal02 className="w-3.5 h-3.5 text-grayLight900 " />
              )}
            </div>
            <div className="w-2/5">
              <TooltipCmp
                message={`${predictivePriceData?.to}`}
                parentClassName="max-w-90"
              >
                <div
                  className={`text-grayLight600 text-sm font-normal truncate cursor-default ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {/* <span className="text-grayLight600">To: </span> */}
                  {predictivePriceData?.to}
                </div>
              </TooltipCmp>
            </div>
          </div>
          {/* <span
            className="text-sm text-primary underline cursor-pointer"
            onClick={() => setIsDisplayReviewModal(true)}
          >
            Review Route
          </span> */}
          <ul className="flex flex-wrap gap-3">
            <li className="rounded-lg p-2 border border-utilityGray200 bg-gray50 flex-1 space-y-0.5">
              <h6 className={`text-grayLight600 text-sm font-normal `}>
                Distance
              </h6>
              <p
                className={`text-grayLight900 text-sm font-medium ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                {routeDistance ? fixedDecimal(routeDistance, 2) : 0} miles
              </p>
            </li>
            <li className="rounded-lg p-2 border border-utilityGray200 bg-gray50 flex-1 space-y-0.5">
              <h6 className="text-grayLight600 text-sm font-normal">
                Cost per Mile
              </h6>
              <p
                className={`text-grayLight900 text-sm font-medium ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                {routeDistance && predictivePriceData?.total
                  ? routeDistance / predictivePriceData?.total
                  : 0}
              </p>
            </li>
          </ul>

          <h6 className="text-grayLight600 text-sm font-normal">
            Predictive Cost
          </h6>
          <div className="flex gap-x-5 items-center pb-2">
            <h6
              className={`text-grayLight900 text-3xl font-semibold ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {predictivePriceData?.total
                ? getFormattedNumber(predictivePriceData?.total, true, true)
                : 'N/A'}
            </h6>
            <div className="flex-1">
              <div className="flex justify-between">
                <p className="text-grayLight600 text-sm font-normal mb-1">
                  Confidence
                </p>
                <span className="text-grayLight600 text-sm font-semibold mb-1">
                  {predictivePriceData?.confidenceLevel}%
                </span>
              </div>
              <div className="progress-range-container">
                <div
                  className="progress-range-bar !bg-[#17B26A]"
                  style={{
                    width: `${predictivePriceData?.confidenceLevel}%`,
                  }}
                >
                  <div className="progress-range-thumb progress-range-thumb-invisible left-[100%]">
                    <div className="progress-tooltip absolute bottom-full left-1/2 -translate-x-1/2 px-3 py-2 rounded-lg border border-utilityGray200 bg-white tooltip-custom-arrow text-xs font-semibold mb-3.5">
                      {predictivePriceData?.confidenceLevel}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="flex-1">
              <InputText
                label={'Total Cost'}
                labelClassName="block mb-1.5"
                inputType="number"
                inputName="totalCost"
                value={totalCost}
                className="pl-8"
                parentClassName="w-full"
                icon={<CurrencyDollar className="input-currency-sign" />}
                onChangeFunc={(e: any) => {
                  setTotalCost(e.target.value);
                }}
              />
            </div>
            <div className="flex-1">
              <InputText
                label={'Margin'}
                labelClassName="block mb-1.5"
                inputType="number"
                inputName="marginPercentage"
                value={marginPercentage}
                className="pl-8"
                parentClassName="w-full"
                icon={<Percent01 className="input-currency-sign" />}
                onChangeFunc={(e: any) => {
                  setMarginPercentage(e.target.value);
                }}
              />
            </div>
            <div className="flex-1">
              <InputText
                label={'Total Sale Price'}
                labelClassName="block mb-1.5"
                inputType="text"
                inputName="totalSalePrice"
                value={getFormattedNumber(totalSalePrice)}
                className="pl-8"
                icon={<CurrencyDollar className="input-currency-sign" />}
              />
            </div>
          </div>
          <div className="rounded-[10px] border border-borderPrimary px-4 py-3 text-xs text-grayLight600 font-normal flex gap-2 items-center">
            {/* <div className="w-5 h-5 relative rounded-full text-utilityPurple800">
              <div className="w-7 h-7 left-[-4px] top-[-4px] absolute opacity-30 rounded-3xl border-2 border-current" />
              <div className="w-[38px] h-[38px] left-[-9px] top-[-9px] absolute opacity-10 rounded-3xl border-2 border-current" />
              <InfoCircle className="w-5 h-5 " />
            </div> */}
            <InfoCircle className="w-4 h-4 text-utilityPurple800" />
            <p>
              Your average margin for this customer is
              <span className="font-semibold ml-1">
                {predictivePriceData?.margin}%
              </span>
            </p>
          </div>
          <ButtonCmp
            className="btn-outline-primary w-full"
            icon={<Plus className="w-4 h-4" />}
            onClick={openAddQuickModal}
          >
            Add Quote
          </ButtonCmp>
        </div>
      </div>
      {/* {isDisplayReviewModal ? (
        <ReviewRouteModal
          startPoint={startPoint}
          distance={routeDistance}
          endPoint={endPoint}
          handleClose={() => setIsDisplayReviewModal(false)}
        />
      ) : (
        ''
      )} */}
    </>
  );
};

export default PredictivePriceChart;
