import {
  ArrowRight,
  Container,
  Copy01,
  Plus,
  Share06,
  // XClose,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import { scriptId } from 'src/app/QuotingHub/QuotingDashboard/AddressDetails/Address.interface';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
// import InputText from 'src/components/InputText/InputText';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import Toggle from 'src/components/Toggle/Toggle';
import TooltipCmp from 'src/components/TooltipCmp';
import { BasicContext } from 'src/context/BasicContext';
import { getMapService } from 'src/services/IntegrationService';
import { updateOrderLocation } from 'src/services/OrderService';
import {
  calculateDistance,
  formatLastUpdate,
  getFormattedDate,
} from 'src/utils/CommonFunctions';

import LocationHistoryModal from '../LocationHistoryModal';

const initFormData = {
  fullAddress: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  latitude: '',
  longitude: '',
  stateCode: '',
  countryCode: '',
};

const RadarMapAboveComponent = ({
  order,
  isLoading,
  setOrder,
}: {
  order: any;
  isLoading: boolean;
  setOrder: any;
}) => {
  const { setMapService, googleKey, setGoogleKey, setTrimbleKey } =
    useContext(BasicContext);
  const { mapService } = useContext(BasicContext);
  const [isShowLocationHistoryModal, setIsShowLocationHistoryModal] =
    useState(false);
  const [isUpdateLocation, setIsUpdateLocation] = useState(false);
  const [isUpdateLocationLoading, setIsUpdateLocationLoading] = useState(false);
  const [activeLegData, setActiveLegData] = useState<any>(null);
  const [orderCurrentLocation, setOrderCurrentLocation] = useState<string>('');
  const [formData, setFormData] = useState<any>(initFormData);
  const [pushToCustomerValue, setPushToCustomerValue] = useState(false);

  useEffect(() => {
    if (order?.orderLegs && order?.orderLegs?.length > 0) {
      const index = order?.orderLegs?.findIndex(
        (item: any) => item.isActive === 1
      );

      if (index === -1) {
        setActiveLegData(null);
      } else {
        setActiveLegData(order?.orderLegs?.[index]);
      }
    }
  }, [order?.orderLegs]);

  useEffect(() => {
    getMapService().then((response: any) => {
      if (response?.data) {
        if (response.data?.slug) {
          setMapService(response.data?.slug);

          if (response.data.configurations?.length) {
            let configuration = response.data.configurations[0];

            if (configuration.value) {
              if (response.data.slug === 'trimble_map') {
                setTrimbleKey(configuration.value);
              } else {
                setGoogleKey(configuration.value);
              }
            }
          }
        }
      }
    });
  });

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  useEffect(() => {
    if (order) {
      setOrderCurrentLocation(
        order?.orderLocationUpdate
          ? order?.orderLocationUpdate?.fullAddress
          : activeLegData?.shipperFullAddress
      );

      if (order?.orderLocationUpdate) {
        setFormData({
          fullAddress: order?.orderLocationUpdate?.fullAddress,
          address1: order?.orderLocationUpdate?.address1,
          city: order?.orderLocationUpdate?.city,
          state: order?.orderLocationUpdate?.state,
          country: order?.orderLocationUpdate?.country,
          postal: order?.orderLocationUpdate?.postal,
          latitude: order?.orderLocationUpdate?.latitude,
          longitude: order?.orderLocationUpdate?.longitude,
          stateCode: order?.orderLocationUpdate?.stateCode,
          countryCode: order?.orderLocationUpdate?.countryCode,
        });
      }
    }
  }, [order, activeLegData]);

  const handleAutocompleteChange = (value: any) => {
    if (value.type === 'blur') {
      if (!formData.fullAddress) {
        setFormData(initFormData);
        // setOrderCurrentLocation('');
      }

      return;
    }

    setFormData(value);
    setOrderCurrentLocation(value.fullAddress);
  };

  const submitUpdateOrderLocation = () => {
    if (formData?.latitude) {
      setIsUpdateLocationLoading(true);
      updateOrderLocation({
        fullAddress: formData.fullAddress,
        address1: formData.address1,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        postal: formData.postal,
        latitude: formData.latitude,
        longitude: formData.longitude,
        stateCode: formData.stateCode,
        countryCode: formData.countryCode,
        orderLegId: activeLegData.id,
      })
        .then((response: any) => {
          if (response) {
            setOrder({ ...order, orderLocationUpdate: response.data });
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsUpdateLocationLoading(false);
          setIsUpdateLocation(false);
        });
    }
  };

  const generateUnitSummary = (items: Array<any>) => {
    if (!Array.isArray(items)) {
      return '';
    }
    const unitSums: any = {};

    items?.forEach((item: any) => {
      const unit = item.unit.toLowerCase();
      const handlingUnitNo = item.handlingUnitNo || 0;

      unitSums[unit] = (unitSums[unit] || 0) + handlingUnitNo;
    });

    const unitSummary = Object?.entries(unitSums)?.map(
      ([unit, sum]: any) => `${sum} ${unit}${sum > 1 ? 's' : ''}`
    );

    return unitSummary.join(' & ');
  };

  const getCompletePercentage = () => {
    if (order?.shipperAddress && order?.consigneeAddress) {
      const mainDistance = calculateDistance(
        order?.shipperAddress?.latitude,
        order?.shipperAddress?.longitude,
        order?.consigneeAddress?.latitude,
        order?.consigneeAddress?.longitude
      );

      if (order?.orderLocationUpdate) {
        const currentDistanceToConsignee = calculateDistance(
          order?.consigneeAddress?.latitude,
          order?.consigneeAddress?.longitude,
          order?.orderLocationUpdate?.latitude,
          order?.orderLocationUpdate?.longitude
        );

        if (mainDistance < currentDistanceToConsignee) {
          return 0;
        }
        const completedDistance = Math.round(
          (currentDistanceToConsignee * 100) / mainDistance
        );

        return 100 - completedDistance;
      }

      return 0;
    }
  };

  const pushToChange = (e: any) => {
    setPushToCustomerValue(e?.target?.checked);
  };

  const cancelUpdateLocation = () => {
    setIsUpdateLocation(false);

    if (order) {
      setOrderCurrentLocation(
        order?.orderLocationUpdate
          ? order?.orderLocationUpdate?.fullAddress
          : activeLegData?.shipperFullAddress
      );

      if (order?.orderLocationUpdate) {
        setFormData({
          fullAddress: order?.orderLocationUpdate?.fullAddress,
          address1: order?.orderLocationUpdate?.address1,
          city: order?.orderLocationUpdate?.city,
          state: order?.orderLocationUpdate?.state,
          country: order?.orderLocationUpdate?.country,
          postal: order?.orderLocationUpdate?.postal,
          latitude: order?.orderLocationUpdate?.latitude,
          longitude: order?.orderLocationUpdate?.longitude,
          stateCode: order?.orderLocationUpdate?.stateCode,
          countryCode: order?.orderLocationUpdate?.countryCode,
        });
      }
    }
  };

  return (
    <>
      <div className="z-[2] w-full absolute top-0 ">
        <div className="border-b border-utilityGray200 bg-white bg-opacity-70 backdrop-blur-[10px] flex">
          <div className="flex-1 truncate px-4 py-2 flex flex-col justify-center">
            <div className="flex items-center gap-x-1.5 cursor-pointer">
              <div className="p-[3px] rounded-full border border-dashed border-borderPrimary bg-white flex items-center justify-center">
                <Plus className="w-4 h-4 text-gray400" />
              </div>
              <p className="text-gray500 text-xs font-normal">
                Add a tracking link
              </p>
            </div>
            {/* <div className="flex gap-x-1.5">
              <InputText
                placeholder={'Enter a tracking link'}
                parentClassName="flex-1"
                className="border-0 h-auto bg-transparent !shadow-none !pl-0 !truncate peer"
                icon={
                  <XClose className="w-4 h-4 text-primary cursor-pointer peer-placeholder-shown:hidden" />
                }
              />
              <ButtonCmp
                className="btn-outline-primary !border-0 !p-0 !shadow-none self-center !bg-transparent"
                icon={<Plus className="w-4 h-4" />}
              >
                Add Link
              </ButtonCmp>
            </div> */}
          </div>
          <div className="py-4 pr-4">
            <TooltipCmp message="Copy Link">
              <Copy01 className="w-5 h-5 text-primary cursor-pointer" />
            </TooltipCmp>
          </div>
          <div className="p-4 border-l border-utilityGray200">
            <TooltipCmp message="Share Link">
              <Share06 className="w-5 h-5 text-primary cursor-pointer" />
            </TooltipCmp>
          </div>
        </div>
        <div className="p-4">
          <div className="rounded-[10px] border border-borderPrimary shadow-[0px_4px_8px_-2px_rgba(16,_24,_40,_0.10),_0px_2px_4px_-2px_rgba(16,_24,_40,_0.06)] bg-white bg-opacity-70 backdrop-blur-[15px]">
            <div className="p-3 border-b border-utilityGray200 flex gap-x-2">
              {(activeLegData?.legPosition || isLoading) && (
                <StatusDotProfileCmp
                  parentClassName={`flex-1 ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                  dotColor="green"
                  label={`Leg ${activeLegData?.legPosition} (#${activeLegData?.legId}) in progress`}
                />
              )}
              {(activeLegData?.order_dimensions?.length > 0 || isLoading) && (
                <div
                  className={`flex gap-x-1 ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  <Container className="w-4 h-4 text-gray400" />
                  <p className="text-grayLight600 text-xs font-normal">
                    <span className="text-grayLight900 font-medium">
                      Line Items&nbsp;
                    </span>
                    {`${
                      activeLegData?.order_dimensions?.length > 0
                        ? generateUnitSummary(activeLegData?.order_dimensions)
                        : ''
                    }`}
                  </p>
                </div>
              )}
            </div>
            <div className="p-3 flex flex-wrap gap-x-2">
              <div className="flex-1">
                <h6
                  className={`text-grayLight900 text-xs font-medium ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  Shipper:
                </h6>
                <p
                  className={`text-gray500 text-xs font-normal line-clamp-1 ${
                    isLoading ? 'custom-loading mt-1' : ''
                  }`}
                >
                  {activeLegData?.shipperCompanyName ?? '-'}
                </p>
              </div>
              <div
                className={`px-2 self-center ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                <ArrowRight className={`w-4 h-4 text-grayLight900`} />
              </div>
              <div className="flex-1">
                <h6
                  className={`text-grayLight900 text-xs font-medium ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  Consignee:
                </h6>
                <p
                  className={`text-gray500 text-xs font-normal line-clamp-1 ${
                    isLoading ? 'custom-loading mt-1' : ''
                  }`}
                >
                  {activeLegData?.consigneeCompanyName ?? '-'}
                </p>
              </div>

              <div className="flex-1">
                <h6
                  className={`text-grayLight900 text-xs font-medium ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  Mileage:
                </h6>
                <p
                  className={`text-gray500 text-xs font-normal ${
                    isLoading ? 'custom-loading mt-1' : ''
                  }`}
                >
                  {activeLegData?.shipperLatitude
                    ? `${calculateDistance(
                        activeLegData?.shipperLatitude,
                        activeLegData?.shipperLongitude,
                        activeLegData?.consigneeLatitude,
                        activeLegData?.consigneeLongitude
                      )} mi`
                    : '-'}
                </p>
              </div>
              <div className="flex-1">
                <h6
                  className={`text-grayLight900 text-xs font-medium ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  ETA:
                </h6>
                <p
                  className={`text-gray500 text-xs font-normal ${
                    isLoading ? 'custom-loading mt-1' : ''
                  }`}
                >
                  {getFormattedDate(
                    activeLegData?.expectedEstimatedDeliveryTime,
                    `Do MMM YYYY`
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul className="w-[418px] absolute bottom-4 right-4 rounded-md border border-utilityGray200 bg-white bg-opacity-70 backdrop-blur-[15px] p-3 space-y-3">
        {!isUpdateLocation ? (
          <>
            <li className="space-y-1">
              <div className="flex gap-x-2 justify-between">
                <StatusDotProfileCmp
                  parentClassName={`${isLoading ? 'custom-loading' : ''}`}
                  dotColor="green"
                  label={`Last Update: ${formatLastUpdate(
                    order?.orderLocationUpdate
                      ? order?.orderLocationUpdate?.updatedAt
                      : activeLegData?.createdAt
                  )}`}
                />
                <p
                  className={`text-grayLight600 text-xs font-normal ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  Predictive Location Index
                </p>
              </div>
              <div className="flex flex-wrap gap-x-2 justify-between">
                <h6
                  className={`text-grayLight900 text-sm font-medium mt-0.5 max-w-[310px] truncate ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {order?.orderLocationUpdate
                    ? order?.orderLocationUpdate?.fullAddress
                    : activeLegData?.shipperAddress1 &&
                      activeLegData?.shipperCity
                    ? `${activeLegData?.shipperAddress1}, ${activeLegData?.shipperCity}`
                    : ''}
                </h6>
                <BadgeCmp
                  type="success"
                  style="modern"
                  mainClassName={`${isLoading ? 'custom-loading' : ''}`}
                >
                  On Track
                </BadgeCmp>
              </div>
            </li>
            <li className="space-y-1.5">
              <div className="flex gap-x-2 justify-between">
                <p
                  className={`text-textSecondary text-xs font-medium ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {`${getCompletePercentage()}% Complete`}
                </p>
                <p
                  className={`text-gray500 text-xs font-normal ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {order?.shipperAddress?.latitude
                    ? `${calculateDistance(
                        order?.shipperAddress?.latitude,
                        order?.shipperAddress?.longitude,
                        order?.consigneeAddress?.latitude,
                        order?.consigneeAddress?.longitude
                      )} Miles to Destination`
                    : ''}
                </p>
              </div>
              {/* <p className="text-utilityBlack text-xs font-medium">10</p> */}
              <div className="relative flex">
                <progress
                  className={`progress bg-gray50 progress-success ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                  value={getCompletePercentage()}
                  max="100"
                ></progress>
                <p
                  className={`absolute left-[80%] top-0 w-0.5 h-full bg-grayLight600 z-[2] ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {/* Target-line */}
                </p>
              </div>
            </li>
            <li className="flex gap-x-2 justify-between items-center">
              <ButtonCmp
                className={`btn-outline-primary ${
                  isLoading ? 'custom-loading' : ''
                }`}
                icon={<Plus className="w-4 h-4" />}
                onClick={() => {
                  setIsUpdateLocation(true);
                }}
              >
                Update Location
              </ButtonCmp>
              <p
                className={`cursor-pointer text-primary700 text-xs font-semibold ${
                  isLoading ? 'custom-loading' : ''
                }`}
                onClick={() => setIsShowLocationHistoryModal(true)}
              >
                View Location History
              </p>
            </li>
          </>
        ) : (
          <div>
            <div className="flex items-end gap-x-2.5">
              {mapService === 'trimble_map' ? (
                <AutoCompleteTrimble
                  inputName="fullAddressNew"
                  placeholder="Enter address"
                  className="form_control truncate"
                  label="Update Order Location"
                  labelClassName="mb-1.5 block"
                  mapParentClass="flex-1"
                  dropdownClass="!max-h-[200px]"
                  onAddressInputChange={setOrderCurrentLocation}
                  onChangeFunc={handleAutocompleteChange}
                  onBlur={handleAutocompleteChange}
                  errorText={
                    !orderCurrentLocation ? 'Address is required' : null
                  }
                  value={orderCurrentLocation}
                />
              ) : (
                <AutoCompleteGoogle
                  inputName="fullAddressNew"
                  placeholder="Enter address"
                  className="form_control truncate"
                  label="Update Order Location"
                  mapParentClass="flex-1"
                  listClassName="showAddressOnTop"
                  labelClassName="mb-1.5 block"
                  onAddressInputChange={handleAutocompleteChange}
                  onChangeFunc={handleAutocompleteChange}
                  onBlur={handleAutocompleteChange}
                  errorText={
                    !orderCurrentLocation ? 'Address is required' : null
                  }
                  value={orderCurrentLocation}
                />
              )}
            </div>
            <div className="flex gap-2 mt-4">
              <div>
                <Toggle
                  isDisabled={isUpdateLocationLoading}
                  isChecked={pushToCustomerValue}
                  onChange={(e: any) => {
                    pushToChange(e);
                  }}
                />
              </div>
              <div>
                <p className="text-textSecondary font-medium text-sm">
                  Push Update to Customer Portal
                </p>
                <p className="text-grayLight600 font-normal text-xs">
                  On enable this location update appears in customer portal
                </p>
              </div>
            </div>
            <div className="mt-4 flex justify-end gap-3">
              <ButtonCmp
                className={`btn_secondary_black lg:!px-[9px] !px-2 h-fit 'bg-primary text-white'`}
                onClick={() => {
                  cancelUpdateLocation();
                }}
                disabled={isUpdateLocationLoading}
              >
                Cancel
              </ButtonCmp>
              <ButtonCmp
                className={`btn-outline-primary lg:!px-[9px] !px-2 h-fit 'bg-primary text-white'`}
                onClick={() => {
                  submitUpdateOrderLocation();
                }}
                disabled={isUpdateLocationLoading}
              >
                Update Location
              </ButtonCmp>
            </div>
          </div>
        )}
      </ul>
      {isShowLocationHistoryModal && (
        <LocationHistoryModal
          handleClose={() => setIsShowLocationHistoryModal(false)}
        />
      )}
    </>
  );
};

export default RadarMapAboveComponent;
