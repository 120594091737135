import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';

const CallCenterSettings = () => {
  console.log('CallCenterSettings component');

  return (
    <>
      <div className="w-full flex justify-between border-b border-utilityGray200 pb-4 px-2 py-1">
        <div>
          <h6 className="text-grayLight900 font-semibold text-sm">
            Call Center
          </h6>
          <p className="text-grayLight600 font-normal text-sm">
            Update your photo and personal details here.
          </p>
        </div>
      </div>

      <div className="border-b border-utilityGray200 pb-4 flex sm:gap-8 gap-2 sm:flex-row flex-col mt-4 px-2">
        <div className="flex flex-col gap-1 w-[320px]">
          <div className="font-inter font-semibold text-sm">
            Buy New Phone Number
          </div>
          <div className="text-grayLight600 font-normal text-sm">
            Purchase and activate a new phone number for your use.
          </div>
        </div>
        <div className="flex flex-col gap-1 w-[500px]">
          <div className="font-inter font-normal text-sm">Area Code</div>
          <div>
            <InputText
              inputName="areaCode"
              inputType="number"
              placeholder="Enter Area Code"
              value=""
              onChangeFunc={() => {}}
              errorText={null}
            />
          </div>
          <ButtonCmp
            className="btn-outline-primary self-end mt-2"
            type="submit"
            // loading={isTagCreateLoading}
            // disabled={isTagCreateLoading}
          >
            Buy
          </ButtonCmp>
        </div>
      </div>
    </>
  );
};

export default CallCenterSettings;
