import { yupResolver } from '@hookform/resolvers/yup';
import { UsersPlus } from '@untitled-ui/icons-react/build/cjs';
import parsePhoneNumberFromString from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import CropperModal from 'src/components/CropperModal/CropperModal';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import { MAX_FILE_SIZE } from 'src/constants/common';
import {
  createCustomerContact,
  updateCustomerContact,
} from 'src/services/CustomerService';
import {
  checkFileTypeValidation,
  convertBase64ToFile,
  getShortName,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import defaultImage from '../../../assets/img/default-image.jpg';

const schema = yup.object().shape({
  contactFullName: yup
    .string()
    .required('Contact full name is required.')
    .max(25, 'Contact full name cannot be longer than 25 characters.'),
  contactRole: yup
    .string()
    .required('Contact role is required.')
    .max(25, 'Contact role cannot be longer than 25 characters.'),
  contactPhone: yup.string().required('Contact phone number is required.'),
  contactPhoneCode: yup.string(),
  image: yup.mixed(),
  contactEmail: yup
    .string()
    .required('Contact email address is required.')
    .email('Contact email address is not valid.'),
});

interface IProps {
  customerId: string;
  handleClose: any;
  isEdit?: boolean;
  editContactData?: any;
}

const AddNewCustomerContactModal = ({
  customerId,
  handleClose,
  isEdit,
  editContactData,
}: IProps) => {
  const [isUpdateSalesRepHistoryLoading, setIsUpdateSalesRepHistoryLoading] =
    useState(false);
  const [displayImage, setDisplayImage] = useState<any>(defaultImage);
  const [upload, setUpload] = useState(false);
  const [imageError, setImageError] = useState('');
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isEdit && editContactData) {
      setValue('contactFullName', editContactData?.contactFullName);
      setValue('contactRole', editContactData?.contactRole);
      setValue('contactEmail', editContactData?.contactEmail);
      setValue(
        'image',
        editContactData?.imageUrl + editContactData?.image || null
      );
      const phoneNumber = parsePhoneNumberFromString(
        editContactData?.contactPhone || ''
      );

      if (phoneNumber?.nationalNumber && phoneNumber?.countryCallingCode) {
        setValue('contactPhone', phoneNumber?.nationalNumber);
        setValue('contactPhoneCode', `+${phoneNumber?.countryCallingCode}`);
      }

      if (editContactData?.image) {
        setDisplayImage(
          `${editContactData?.imageUrl}${editContactData?.image}`
        );
      } else {
        // setDisplayImage(defaultImage);
      }
    }
  }, []);

  const onSubmit = (formData: any) => {
    // formData.contactPhone = `+${formData.contactPhoneCode}${formData.contactPhone}`;
    delete formData.contactPhoneCode;

    setIsUpdateSalesRepHistoryLoading(true);
    const appendFormData: any = new FormData();
    Object.entries(formData).forEach((data) => {
      const [key, value] = data;

      if (key !== 'image') {
        appendFormData.append(key, value);
      }
    });

    appendFormData.append('image', formData.image);

    if (isEdit) {
      updateCustomerContact(editContactData?.id, appendFormData)
        .then((response: any) => {
          WalToast.success(response?.message);
          handleClose(true);
        })
        .finally(() => {
          setIsUpdateSalesRepHistoryLoading(false);
        })
        .catch(console.log);
    } else {
      createCustomerContact(customerId, appendFormData)
        .then((response: any) => {
          WalToast.success(response?.message);
          handleClose(true);
        })
        .finally(() => {
          setIsUpdateSalesRepHistoryLoading(false);
        })
        .catch(console.log);
    }
  };

  const chooseFile = (e: any) => {
    e.preventDefault();
    setImageError('');
    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const { result } = checkFileTypeValidation(files, MAX_FILE_SIZE);

    if (result) {
      const reader = new FileReader();

      reader.onloadstart = () => {
        setDisplayImage('');
        setUpload(false);
      };

      reader.onloadend = () => {
        setDisplayImage(reader.result as any);
        setUpload(true);
      };
      reader.readAsDataURL(files[0]);
      e.target.value = null;
    } else {
      setImageError('Image size should be less than 2MB.');
    }
  };

  const imageUpload = (data: any) => {
    const appLogoImage = data;
    const fileInfo = convertBase64ToFile(appLogoImage);
    setValue('image', fileInfo?.convertedFile);
  };

  return (
    <>
      <CommonModal
        title={!isEdit ? 'Add Contacts' : 'Edit Contacts'}
        titleDesc={
          !isEdit
            ? 'Add and manage your contacts'
            : 'Edit and manage your contacts'
        }
        handleClose={() => handleClose(false)}
        headerIcon={<UsersPlus />}
        size={'xl:max-w-[647px] max-w-[551px] overflow-unset'}
        isOverflow={false}
        modalClassName=""
        primaryBtnText="Save Changes"
        primaryBtnOnClick={handleSubmit(onSubmit)}
        primaryBtnLoading={isUpdateSalesRepHistoryLoading}
        primaryBtnDisabled={isUpdateSalesRepHistoryLoading}
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={() => handleClose(false)}
        secondaryBtnDisabled={isUpdateSalesRepHistoryLoading}
      >
        <div className="w-full p-5 flex flex-col gap-4">
          <div className="w-full md:flex pb-4 border-b border-utilityGray200">
            <p className="text-xs font-medium text-textSecondary mb-2 md:mb-0 w-40">
              Image Update
            </p>

            <div className="position-relative flex-1">
              <div className="flex md:gap-4 gap-2 ">
                {displayImage ? (
                  <img
                    className="rounded-lg border-utilityBlack border-[0.75px] border-opacity-[0.08] w-14 h-14 object-cover flex-none"
                    src={displayImage}
                  />
                ) : watch('image') ? (
                  <img
                    className="rounded-lg border-utilityBlack border-[0.75px] border-opacity-[0.08] w-14 h-14 object-cover flex-none"
                    src={watch('image')}
                  />
                ) : (
                  <label className="rounded-lg bg-utilityGray100 w-14 h-14 border-utilityBlack border-[0.75px] border-opacity-[0.08] text-primary text-xl font-semibold uppercase flex items-center justify-center flex-none">
                    {getShortName(watch('contactFullName'))}
                  </label>
                )}

                <div className="relative z-[1] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl border border-utilityGray200 py-3.5 px-5 text-center bg-white">
                  <label htmlFor="fileSelectInModal" className="cursor-pointer">
                    <progress
                      className="progress bg-gray50 absolute left-0 top-0 -z-10 rounded-xl h-full w-[70%] hidden"
                      value="70"
                      max="100"
                    ></progress>
                    <div className="text-grayLight600 text-xs font-normal">
                      <p>
                        <span className="text-[#2422DD] font-semibold pr-1">
                          Click to upload{' '}
                        </span>
                        or drag and drop&nbsp;
                        <br className="sms:block hidden"></br>
                        <span className="text-[11px] leading-[18px]">
                          SVG, PNG or JPG
                        </span>
                      </p>
                    </div>
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="fileSelectInModal"
                    accept="image/*"
                    onChange={chooseFile}
                    className="hidden"
                  />
                </div>
              </div>
              <span className="text-danger text-xs">{imageError}</span>
            </div>
          </div>
          <Controller
            name="contactFullName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="contactFullName"
                label="Name"
                labelClassName="block mb-1.5"
                placeholder="Enter Contact Full Name"
                value={value}
                onChangeFunc={onChange}
                errorText={
                  errors.contactFullName ? errors.contactFullName.message : ''
                }
              />
            )}
          />
          <Controller
            name="contactRole"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="contactRole"
                label="Role"
                labelClassName="block mb-1.5"
                placeholder="Enter Contact Role Name"
                value={value}
                onChangeFunc={onChange}
                errorText={errors.contactRole ? errors.contactRole.message : ''}
              />
            )}
          />
          <div className="flex max-sm:flex-col -mx-1.5">
            <div className="px-1.5 max-sm:mb-4 sm:w-1/2">
              <label className="form_label block mb-1.5">Phone number</label>
              <Controller
                name="contactPhone"
                control={control}
                render={() => (
                  <PhoneInput
                    name="contactPhone"
                    value={`${watch('contactPhoneCode')}${watch(
                      'contactPhone'
                    )}`}
                    onNumberChange={(
                      country: any,
                      phone: any,
                      code: any,
                      isValid: boolean
                    ) => {
                      const newValue = `+${code}${phone}`;
                      setValue('contactPhoneCode', `+${code}`);
                      setValue('contactPhone', newValue);

                      if (isValid || phone === '') {
                        clearErrors('contactPhone');
                      } else {
                        setError('contactPhone', {
                          type: 'manual',
                          message: 'Contact phone number is invalid.',
                        });
                      }
                    }}
                    errors={
                      errors.contactPhone ? errors.contactPhone.message : ''
                    }
                  />
                )}
              />
            </div>
            <Controller
              name="contactEmail"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="contactEmail"
                  label="Email"
                  labelClassName="block mb-1.5"
                  placeholder="Enter Contact Email Address"
                  parentClassName="sm:w-1/2 px-1.5"
                  value={value}
                  onChangeFunc={onChange}
                  errorText={
                    errors.contactEmail ? errors.contactEmail.message : ''
                  }
                />
              )}
            />
          </div>
        </div>
      </CommonModal>
      {upload && (
        <CropperModal
          modalTitle="Edit Customer Profile Image"
          modalDesc="Edit customer profile image as per your requirement"
          imageUpload={imageUpload}
          defaultImage={defaultImage || ''}
          imageUrl={displayImage}
          setUpload={setUpload}
          setCropData={setDisplayImage}
          isCropType={false}
          defaultCropType="Square"
        />
      )}
    </>
  );
};

export default AddNewCustomerContactModal;
