import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listShippers = async () => {
  const response = await axiosInterceptor.get(API.SHIPPER.LIST);

  return response;
};

export const createShipper = async (data: any) => {
  const response = await axiosInterceptor.post(API.SHIPPER.CREATE, data);

  return response;
};

export const updateShipper = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.SHIPPER.CREATE}/${id}`,
    data
  );

  return response;
};

export const deleteShippers = async (id: number) => {
  const response = await axiosInterceptor.delete(`${API.SHIPPER.DELETE}/${id}`);

  return response;
};

export const createShipperContact = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.SHIPPER.CONTACTS.CREATE,
    data
  );

  return response;
};

export const updateShipperContact = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.SHIPPER.CONTACTS.UPDATE}/${id}`,
    data
  );

  return response;
};

export const deleteShipperContact = async (id: number) => {
  const response = await axiosInterceptor.delete(
    `${API.SHIPPER.CONTACTS.DELETE}/${id}`
  );

  return response;
};
