import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ShipperAndConsigneeAddressForm from 'src/app/Orders/Common/ShipperAndConsigneeAddressForm';
import {
  handleAddressAutocompleteChange,
  handleSaveShipperDetails,
  handleShipperConsigneeAddressSelection,
  handleShipperContactSelectChange,
  handleShipperSelectChange,
} from 'src/app/Orders/Common/ShipperAndConsigneeAddressForm/ShipperAndConsigneeAddress.const';
import {
  IMapAddress,
  initAction,
  initShipperAddress,
  scriptId,
} from 'src/app/Orders/order.interface';
import Address from 'src/app/QuotingHub/QuotingDashboard/AddressDetails/Address';
import DeleteModal from 'src/components/DeleteModal';
import { BasicContext } from 'src/context/BasicContext';
import { deleteShippers } from 'src/services/ShipperService';

import { OrderContext } from '../../..';

interface IProps {
  action: any;
  setAction: any;
  currentStep: any;
  shippers: any;
  consignees: any;
  shipperOptions: any;
  consigneeOptions: any;
  isValidAddress: any;
  setIsValidAddress: any;
  isConsigneeBtnDisable: any;
  setIsConsigneeBtnDisable: any;
  isShipperBtnDisable: any;
  setIsShipperBtnDisable: any;
  getShipper: any;
  currentLegData: any;
  setCurrentLegData: any;
  tempLegData: any;
  setTempLegData: any;
  shipperAddress: any;
  setShipperAddress: any;
  consigneeAddress: any;
  setConsigneeAddress: any;
  shipperContact: any;
  setShipperContact: any;
  consigneeContact: any;
  setConsigneeContact: any;
  shipperContactOptions: any;
  setShipperContactOptions: any;
  consigneeContactOptions: any;
  setConsigneeContactOptions: any;
  isShipperContactBtnDisable: any;
  setIsShipperContactBtnDisable: any;
  isConsigneeContactBtnDisable: any;
  setIsConsigneeContactBtnDisable: any;
}

const AddressDetails = ({
  action,
  setAction,
  currentLegData,
  setCurrentLegData,
  shippers,
  consignees,
  shipperOptions,
  consigneeOptions,
  isValidAddress,
  setIsValidAddress,
  isConsigneeBtnDisable,
  setIsConsigneeBtnDisable,
  isShipperBtnDisable,
  setIsShipperBtnDisable,
  getShipper,
  tempLegData,
  setTempLegData,
  shipperAddress,
  setShipperAddress,
  consigneeAddress,
  setConsigneeAddress,
  shipperContact,
  setShipperContact,
  consigneeContact,
  setConsigneeContact,
  shipperContactOptions,
  setShipperContactOptions,
  consigneeContactOptions,
  setConsigneeContactOptions,
  isShipperContactBtnDisable,
  setIsShipperContactBtnDisable,
  isConsigneeContactBtnDisable,
  setIsConsigneeContactBtnDisable,
  currentStep,
}: IProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useFormContext();

  console.log('tempLegData', tempLegData);
  console.log('setTempLegData', setTempLegData);

  const { setIsTailgate, serviceList } = useContext<any>(OrderContext);

  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );

  const [addressData, setAddressData] = useState<IMapAddress>();

  const { googleKey } = useContext(BasicContext);

  useEffect(() => {
    if (currentLegData) {
      setShipperAddress(currentLegData?.shipper?.shipperAddress?.fullAddress);
      setConsigneeAddress(
        currentLegData?.consignee?.consigneeAddress?.fullAddress
      );
    }
  }, [currentLegData]);

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const handleAddressSelection = (value: any) => {
    handleShipperConsigneeAddressSelection(
      value,
      action,
      setShipperAddress,
      setValue,
      setIsValidAddress,
      setCurrentLegData,
      setConsigneeAddress
    );
  };

  const handleAutocompleteChange = (name: string) => (value: any) => {
    handleAddressAutocompleteChange(
      name,
      value,
      setShipperAddress,
      setIsValidAddress,
      setError,
      setValue,
      setConsigneeAddress,
      clearErrors,
      getValues,
      setCurrentLegData
    );
  };

  const handleActionType =
    (actionKey: any, mode: any = null) =>
    () => {
      if (mode === 'addressUpdate' && actionKey === 'shipper') {
        setAddressData(getValues('shipper.shipperAddress'));
      }

      if (mode === 'addressUpdate' && actionKey === 'consignee') {
        setAddressData(getValues('consignee.consigneeAddress'));
      }
      setAction((old: any) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleSelectChange = (name: any) => (event: any) => {
    handleShipperSelectChange(
      name,
      event,
      shippers,
      setValue,
      setShipperContact,
      setShipperContactOptions,
      setShipperAddress,
      getValues,
      setCurrentLegData,
      consignees,
      setConsigneeAddress,
      setConsigneeContact,
      setConsigneeContactOptions,
      setIsValidAddress,
      isValidAddress,
      clearErrors
    );
  };

  const handleContactSelectChange = (name: string) => (event: any) => {
    handleShipperContactSelectChange(
      name,
      shipperContact,
      consigneeContact,
      setValue,
      setCurrentLegData,
      clearErrors,
      event
    );
  };

  const saveShipperDetails = (type: string, mode: string) => () => {
    handleSaveShipperDetails(
      type,
      mode,
      getValues,
      setValue,
      setIsValidAddress,
      getShipper,
      setIsShipperBtnDisable,
      setIsConsigneeBtnDisable,
      setIsShipperContactBtnDisable,
      setIsConsigneeContactBtnDisable
    );
  };

  const handleModalClose = useCallback(
    (closeStatus: boolean) => () => {
      setAction(initAction);

      if (closeStatus) {
        const idToDelete = action.shipper
          ? getValues('shipper.id')
          : getValues('consignee.id');

        if (idToDelete) {
          deleteShippers(idToDelete)
            .then((result: any) => {
              if (result) {
                if (action.shipper) {
                  setShipperAddress('');
                } else if (action.consignee) {
                  setConsigneeAddress('');
                }

                getShipper();

                // const title = action.shipper ? 'Shipper' : 'Consignee';
                // WalToast.success(`${title} deleted successfully`, '');
              }
            })
            .catch(console.error);
        }
      }
    },
    [action]
  );

  useEffect(() => {
    clearErrors('shipper.shipperAddress.fullAddress');

    if (!shipperAddress) {
      setValue('shipper.shipperAddress', initShipperAddress);
      setValue('shipper.companyName', undefined);
      setValue('shipper.id', null);
    }
  }, [shipperAddress]);

  useEffect(() => {
    clearErrors('consignee.consigneeAddress.fullAddress');

    if (!consigneeAddress) {
      setValue('consignee.consigneeAddress', initShipperAddress);
      setValue('consignee.companyName', undefined);
      setValue('consignee.id', null);
    }
  }, [consigneeAddress]);

  return (
    <>
      {currentStep === 1 && (
        <ShipperAndConsigneeAddressForm
          control={control}
          errors={errors}
          data="shipper"
          title1="Shipper"
          title2="Shipper Details"
          title3="Shipper Contact"
          onAutocompleteChange={handleAutocompleteChange('shipperAddress')}
          onAddressInputChange={setShipperAddress}
          status={status}
          handleActionType={handleActionType}
          isAddressExits={shipperAddress}
          isValidAddress={isValidAddress}
          selectBoxOptionList={shipperOptions}
          handleSelectChange={handleSelectChange}
          setIsTailgate={setIsTailgate}
          currentLegData={currentLegData}
          setCurrentLegData={setCurrentLegData}
          setValue={setValue}
          serviceList={serviceList}
          saveShipperDetails={saveShipperDetails}
          shipperBtnDisable={isShipperBtnDisable}
          isShipperContactBtnDisable={isShipperContactBtnDisable}
          setIsValidAddress={setIsValidAddress}
          contactList={shipperContactOptions}
          handleContactSelectChange={handleContactSelectChange}
        />
      )}

      {currentStep === 2 && (
        <ShipperAndConsigneeAddressForm
          control={control}
          errors={errors}
          data="consignee"
          title1="Consignee"
          title2="Consignee Details"
          title3="Consignee Contact"
          onAutocompleteChange={handleAutocompleteChange('consigneeAddress')}
          onAddressInputChange={setConsigneeAddress}
          status={status}
          handleActionType={handleActionType}
          isAddressExits={consigneeAddress}
          isValidAddress={isValidAddress}
          selectBoxOptionList={consigneeOptions}
          handleSelectChange={handleSelectChange}
          setIsTailgate={setIsTailgate}
          currentLegData={currentLegData}
          setCurrentLegData={setCurrentLegData}
          setValue={setValue}
          serviceList={serviceList}
          saveShipperDetails={saveShipperDetails}
          shipperBtnDisable={isConsigneeBtnDisable}
          isShipperContactBtnDisable={isConsigneeContactBtnDisable}
          setIsValidAddress={setIsValidAddress}
          contactList={consigneeContactOptions}
          handleContactSelectChange={handleContactSelectChange}
        />
      )}

      <Address
        isOpen={
          action.mode === 'addressCreate' || action.mode === 'addressUpdate'
        }
        mode={action.mode}
        handleClose={handleDrawerClose}
        setAddressData={handleAddressSelection}
        addressType={action}
        addressData={addressData}
        status={status}
      />

      {action.mode === 'delete' && (
        <DeleteModal
          handleClose={handleModalClose}
          moduleTitle={`Remove ${
            action.consignee
              ? getValues('consignee.companyName')
              : getValues('shipper.companyName')
          } From Wal HQ `}
          moduleName={action.consignee ? 'consignee' : 'shipper'}
        />
      )}

      {action.mode === 'deleteContact' && (
        <DeleteModal
          handleClose={handleModalClose}
          moduleTitle={`Remove Contact From Wal HQ `}
          moduleTitleDesc={`Are you sure you want to delete this contact? This action can't be undone.`}
          moduleName={action.consignee ? 'consignee' : 'shipper'}
        />
      )}
    </>
  );
};

export default AddressDetails;
