import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ShipperAndConsigneeAddressForm from 'src/app/Orders/Common/ShipperAndConsigneeAddressForm';
import {
  handleAddressAutocompleteChange,
  handleShipperConsigneeAddressSelection,
  handleShipperSelectChange,
  handleShipperContactSelectChange,
  handleSaveShipperDetails,
} from 'src/app/Orders/Common/ShipperAndConsigneeAddressForm/ShipperAndConsigneeAddress.const';
import Address from 'src/app/QuotingHub/QuotingDashboard/AddressDetails/Address';
import DeleteModal from 'src/components/DeleteModal';
import { BasicContext } from 'src/context/BasicContext';
import {
  deleteShipperContact,
  deleteShippers,
} from 'src/services/ShipperService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import { OrderContext } from '../../';
import {
  IMapAddress,
  initAction,
  initShipperAddress,
  scriptId,
} from '../../../order.interface';

interface IProps {
  action: any;
  setAction: any;
  shipperAddress: any;
  setShipperAddress: any;
  consigneeAddress: any;
  setConsigneeAddress: any;
  currentStep: any;
}

const AddressDetails = ({
  action,
  setAction,
  shipperAddress,
  setShipperAddress,
  consigneeAddress,
  setConsigneeAddress,
  currentStep,
}: IProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useFormContext();

  const {
    isValidAddress,
    setIsValidAddress,
    shipperOptions,
    consigneeOptions,
    shippers,
    consignees,
    currentLegData,
    setCurrentLegData,
    setIsTailgate,
    serviceList,
    setShipperFullAddress,
    setConsigneeFullAddress,
    isConsigneeBtnDisable,
    setIsConsigneeBtnDisable,
    isShipperBtnDisable,
    setIsShipperBtnDisable,
    isShipperContactBtnDisable,
    setIsShipperContactBtnDisable,
    isConsigneeContactBtnDisable,
    setIsConsigneeContactBtnDisable,
    getShipper,
    shipperContact,
    setShipperContact,
    consigneeContact,
    setConsigneeContact,
    shipperContactOptions,
    setShipperContactOptions,
    consigneeContactOptions,
    setConsigneeContactOptions,
  } = useContext<any>(OrderContext);

  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [addressData, setAddressData] = useState<IMapAddress>();

  const { googleKey } = useContext(BasicContext);

  useEffect(() => {
    if (shipperAddress && getValues('shipper.id')) {
      const shipper = shippers.find(
        (shipperData: any) => shipperData.id === getValues('shipper.id')
      );

      const shipperContacts = getSelectBoxOptions(
        shipper?.contacts,
        'id',
        'name',
        true
      );

      setShipperContact(shipper?.contacts ?? []);
      setShipperContactOptions(shipperContacts);
    }

    clearErrors('shipper.shipperAddress.fullAddress');

    if (!shipperAddress) {
      setValue('shipper.shipperAddress', initShipperAddress);
      setValue('shipper.companyName', '');
      setValue('shipper.id', null);

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        shipper: {
          ...prevData?.shipper,
          shipperAddress: initShipperAddress,
          id: null,
        },
      }));

      setShipperFullAddress(initShipperAddress);
    }
  }, [shipperAddress, shippers]);

  useEffect(() => {
    if (consigneeAddress && getValues('consignee.id')) {
      const consignee = consignees.find(
        (consigneeData: any) => consigneeData.id === getValues('consignee.id')
      );

      const consigneeContacts = getSelectBoxOptions(
        consignee?.contacts,
        'id',
        'name',
        true
      );

      setConsigneeContact(consignee?.contacts ?? []);
      setConsigneeContactOptions(consigneeContacts);
    }

    clearErrors('consignee.consigneeAddress.fullAddress');

    if (!consigneeAddress) {
      setValue('consignee.consigneeAddress', initShipperAddress);
      setValue('consignee.companyName', '');
      setValue('consignee.id', null);

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        consignee: {
          ...prevData?.consignee,
          consigneeAddress: initShipperAddress,
          id: null,
        },
      }));

      setConsigneeFullAddress(initShipperAddress);
    }
  }, [consigneeAddress, consignees]);

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const handleAddressSelection = (value: any) => {
    handleShipperConsigneeAddressSelection(
      value,
      action,
      setShipperAddress,
      setValue,
      setIsValidAddress,
      setCurrentLegData,
      setConsigneeAddress,
      setShipperFullAddress,
      setConsigneeFullAddress
    );
  };

  const handleAutocompleteChange = (name: string) => (value: any) => {
    handleAddressAutocompleteChange(
      name,
      value,
      setShipperAddress,
      setIsValidAddress,
      setError,
      setValue,
      setConsigneeAddress,
      clearErrors,
      getValues,
      setCurrentLegData,
      setShipperFullAddress,
      setConsigneeFullAddress
    );
  };

  const handleActionType =
    (actionKey: any, mode: any = null) =>
    () => {
      if (mode === 'addressUpdate' && actionKey === 'shipper') {
        setAddressData(getValues('shipper.shipperAddress'));
      }

      if (mode === 'addressUpdate' && actionKey === 'consignee') {
        setAddressData(getValues('consignee.consigneeAddress'));
      }
      setAction((old: any) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleSelectChange = (name: any) => (event: any) => {
    handleShipperSelectChange(
      name,
      event,
      shippers,
      setValue,
      setShipperContact,
      setShipperContactOptions,
      setShipperAddress,
      getValues,
      setCurrentLegData,
      consignees,
      setConsigneeAddress,
      setConsigneeContact,
      setConsigneeContactOptions,
      setIsValidAddress,
      isValidAddress,
      clearErrors,
      setShipperFullAddress,
      setConsigneeFullAddress
    );
  };

  const handleContactSelectChange = (name: string) => (event: any) => {
    handleShipperContactSelectChange(
      name,
      shipperContact,
      consigneeContact,
      setValue,
      setCurrentLegData,
      clearErrors,
      event
    );
  };

  const saveShipperDetails = (type: string, mode: string) => () => {
    handleSaveShipperDetails(
      type,
      mode,
      getValues,
      setValue,
      setIsValidAddress,
      getShipper,
      setIsShipperBtnDisable,
      setIsConsigneeBtnDisable,
      setIsShipperContactBtnDisable,
      setIsConsigneeContactBtnDisable
    );
  };

  const handleModalClose = useCallback(
    (closeStatus: boolean) => () => {
      setAction(initAction);

      if (closeStatus) {
        const idToDelete = action.shipper
          ? getValues('shipper.id')
          : getValues('consignee.id');

        const contactId = action.shipper
          ? getValues('shipper.contactId')
          : getValues('consignee.contactId');

        const isDeleteContact = action.mode === 'deleteContact';

        if (idToDelete && !isDeleteContact) {
          deleteShippers(idToDelete)
            .then((result: any) => {
              if (result) {
                if (action.shipper) {
                  setShipperAddress('');
                } else if (action.consignee) {
                  setConsigneeAddress('');
                }

                getShipper();

                const title = action.shipper ? 'Shipper' : 'Consignee';
                WalToast.success(`${title} deleted successfully`, '');
              }
            })
            .catch(console.error);
        }

        if (isDeleteContact) {
          deleteShipperContact(contactId)
            .then((result: any) => {
              if (result) {
                if (action.shipper) {
                  setValue('shipper.contactId', null);
                  setValue('shipper.contactName', '');
                  setValue('shipper.email', '');
                  setValue('shipper.phoneNumber', '');
                } else if (action.consignee) {
                  setValue('consignee.contactId', null);
                  setValue('consignee.contactName', '');
                  setValue('consignee.email', '');
                  setValue('consignee.phoneNumber', '');
                }

                getShipper();

                WalToast.success(`Contact deleted successfully`, '');
              }
            })
            .catch(console.error);
        }
      }
    },
    [action]
  );

  return (
    <>
      {currentStep === 2 && (
        <ShipperAndConsigneeAddressForm
          control={control}
          errors={errors}
          data="shipper"
          title1="Shipper"
          title2="Shipper Details"
          title3="Shipper Contact"
          onAutocompleteChange={handleAutocompleteChange('shipperAddress')}
          onAddressInputChange={setShipperAddress}
          status={status}
          handleActionType={handleActionType}
          isAddressExits={shipperAddress}
          isValidAddress={isValidAddress}
          selectBoxOptionList={shipperOptions}
          handleSelectChange={handleSelectChange}
          setIsTailgate={setIsTailgate}
          currentLegData={currentLegData}
          setCurrentLegData={setCurrentLegData}
          setValue={setValue}
          serviceList={serviceList}
          saveShipperDetails={saveShipperDetails}
          shipperBtnDisable={isShipperBtnDisable}
          isShipperContactBtnDisable={isShipperContactBtnDisable}
          setIsValidAddress={setIsValidAddress}
          contactList={shipperContactOptions}
          handleContactSelectChange={handleContactSelectChange}
        />
      )}

      {currentStep === 3 && (
        <ShipperAndConsigneeAddressForm
          control={control}
          errors={errors}
          data="consignee"
          title1="Consignee"
          title2="Consignee Details"
          title3="Consignee Contact"
          onAutocompleteChange={handleAutocompleteChange('consigneeAddress')}
          onAddressInputChange={setConsigneeAddress}
          status={status}
          handleActionType={handleActionType}
          isAddressExits={consigneeAddress}
          isValidAddress={isValidAddress}
          selectBoxOptionList={consigneeOptions}
          handleSelectChange={handleSelectChange}
          setIsTailgate={setIsTailgate}
          currentLegData={currentLegData}
          setCurrentLegData={setCurrentLegData}
          setValue={setValue}
          serviceList={serviceList}
          saveShipperDetails={saveShipperDetails}
          shipperBtnDisable={isConsigneeBtnDisable}
          isShipperContactBtnDisable={isConsigneeContactBtnDisable}
          setIsValidAddress={setIsValidAddress}
          contactList={consigneeContactOptions}
          handleContactSelectChange={handleContactSelectChange}
        />
      )}

      <Address
        isOpen={
          action.mode === 'addressCreate' || action.mode === 'addressUpdate'
        }
        mode={action.mode}
        handleClose={handleDrawerClose}
        setAddressData={handleAddressSelection}
        addressType={action}
        addressData={addressData}
        status={status}
      />

      {action.mode === 'delete' && (
        <DeleteModal
          handleClose={handleModalClose}
          moduleTitle={`Remove ${
            action.consignee
              ? getValues('consignee.companyName')
              : getValues('shipper.companyName')
          } From Wal HQ `}
          moduleName={action.consignee ? 'consignee' : 'shipper'}
        />
      )}

      {action.mode === 'deleteContact' && (
        <DeleteModal
          handleClose={handleModalClose}
          moduleTitle={`Remove Contact From Wal HQ `}
          moduleTitleDesc={`Are you sure you want to delete this contact? This action can't be undone.`}
          moduleName={action.consignee ? 'consignee' : 'shipper'}
        />
      )}
    </>
  );
};

export default AddressDetails;
