import { Edit05, Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext } from 'react';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TooltipCmp from 'src/components/TooltipCmp';
import { BasicContext } from 'src/context/BasicContext';

interface IProps {
  formData: any;
  shipperOptions: any;
  validForm: any;
  handleSelectChange: any;
  selectFormData: any;
  handleActionType: any;
  saveShipperDetails: any;
  isShipperBtnDisable: any;
  handleAutocompleteChange: any;
  handleInputChange: any;
  shipperAddress: any;
  setShipperAddress: any;
  isShipperLoading: any;
  status: any;
  isShowAiStarFullAddress?: boolean;
}

const ShipperAddressSection = ({
  formData,
  shipperOptions,
  validForm,
  handleSelectChange,
  selectFormData,
  handleActionType,
  saveShipperDetails,
  isShipperBtnDisable,
  handleAutocompleteChange,
  handleInputChange,
  shipperAddress,
  setShipperAddress,
  isShipperLoading,
  status,
  isShowAiStarFullAddress,
}: IProps) => {
  const { mapService } = useContext(BasicContext);

  return (
    <>
      <SelectBox
        isClearable
        isSearchable
        isLoading={isShipperLoading}
        name="shipperId"
        label="Shipper"
        placeholder="Select a shipper"
        inlineSelect={true}
        labelWrapClassName="mb-1.5"
        labelRight={
          <div className="flex gap-x-2">
            {selectFormData.shipperId && (
              <TooltipCmp message="Delete Shipper">
                <div
                  onClick={handleActionType('shipper', 'delete')}
                  className="cursor-pointer"
                >
                  <Trash01 className="h-4 w-4" />
                </div>
              </TooltipCmp>
            )}
            <button
              onClick={saveShipperDetails('shipper')}
              type="button"
              className={`text-primary700 text-xs flex font-semibold gap-2.5  ${
                isShipperBtnDisable && 'text-opacity-50'
              }`}
              disabled={isShipperBtnDisable}
            >
              {selectFormData.shipperId ? (
                <Edit05 className="w-4 h-4" />
              ) : (
                <Plus className="w-4 h-4" />
              )}
              {selectFormData.shipperId ? 'Edit' : 'Add New'} Shipper
            </button>
          </div>
        }
        className={`form_control ${
          !validForm.shipperId
            ? 'border border-red-500 rounded-[10px] mb-1 border-solid '
            : ''
        }`}
        labelClassName="form_label mb-0 block"
        classComp=""
        options={shipperOptions}
        onChangeFunc={handleSelectChange('shipperId')}
        value={
          shipperOptions.find(
            (val: any) => val.value === selectFormData.shipperId
          ) || null
        }
        errorText={!validForm.shipperId ? 'Shipper is required' : null}
      />

      <InputText
        inputName="shipperCompanyName"
        placeholder="Enter shipper name"
        className="form_control"
        label="Shipper Name"
        labelClassName="mb-1.5 block"
        value={formData.shipperCompanyName}
        errorText={
          !validForm.shipperCompanyName ? 'Shipper Name is required' : null
        }
        onChangeFunc={handleInputChange}
      />

      {status &&
        (mapService === 'trimble_map' ? (
          <AutoCompleteTrimble
            inputName="shipperAddress"
            required
            placeholder="Enter shipper address"
            className={`form_control truncate ${
              !validForm.shipperAddress
                ? 'border border-red-500 border-solid'
                : ''
            }`}
            label="Select Address"
            labelRight={
              <button
                onClick={handleActionType(
                  'shipper',
                  formData.shipperAddress.fullAddress
                    ? 'addressUpdate'
                    : 'addressCreate'
                )}
                type="button"
                className={`text-primary700 text-xs flex font-semibold gap-2.5`}
                disabled={false}
              >
                {formData.shipperAddress.fullAddress ? 'Change' : 'Select'}{' '}
                Address
              </button>
            }
            labelClassName="mb-0 block"
            labelWrapClassName="mb-1.5"
            isClearable
            onChangeFunc={handleAutocompleteChange('shipperAddress')}
            onBlur={handleAutocompleteChange('shipperAddress')}
            onAddressInputChange={setShipperAddress}
            // errorText={!validForm.shipperAddress ? "Address is required" : null}
            value={shipperAddress}
            isShowAiStar={isShowAiStarFullAddress}
          />
        ) : (
          <AutoCompleteGoogle
            inputName="shipperAddress"
            required
            placeholder="Enter shipper address"
            className={`form_control truncate ${
              !validForm.shipperAddress
                ? 'border border-red-500 border-solid'
                : ''
            }`}
            label="Select Address"
            labelRight={
              <button
                onClick={handleActionType(
                  'shipper',
                  formData.shipperAddress.fullAddress
                    ? 'addressUpdate'
                    : 'addressCreate'
                )}
                type="button"
                className={`text-primary700 text-xs flex font-semibold gap-2.5`}
                disabled={false}
              >
                {formData.shipperAddress.fullAddress ? 'Change' : 'Select'}{' '}
                Address
              </button>
            }
            labelClassName="mb-0 block"
            labelWrapClassName="mb-1.5"
            isClearable
            onChangeFunc={handleAutocompleteChange('shipperAddress')}
            onBlur={handleAutocompleteChange('shipperAddress')}
            onAddressInputChange={setShipperAddress}
            // errorText={!validForm.shipperAddress ? "Address is required" : null}
            value={shipperAddress}
            isShowAiStar={isShowAiStarFullAddress}
          />
        ))}
    </>
  );
};

export default ShipperAddressSection;
