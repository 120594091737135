import { QUOTE_CLASSIFICATION } from 'src/constants/common';

export interface IMapAddress {
  fullAddress: string;
  address1: string;
  city: string;
  state: string;
  country: string;
  postal: string;
  latitude: string;
  longitude: string;
  stateCode: string;
  countryCode: string;
  companyName?: string;
}

export const initSelectQuoteFormData = {
  classification: 'ltl',
  urgency: 'live',
};

export const initShipperAddress: IMapAddress = {
  fullAddress: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  latitude: '',
  longitude: '',
  stateCode: '',
  countryCode: '',
  companyName: '',
};

export const initConsigneeAddress: IMapAddress = {
  fullAddress: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  latitude: '',
  longitude: '',
  stateCode: '',
  countryCode: '',
  companyName: '',
};

export interface IFormData {
  [key: string]: any;
  customerId: any;
  shipperId: number | null;
  shipperCompanyName: string;
  shipperAddress: IMapAddress;
  pickupDate: any;
  consigneeId: number | null;
  consigneeCompanyName: string;
  consigneeAddress: IMapAddress;
}

export const initFormData: IFormData = {
  customerId: '',
  shipperId: null,
  shipperCompanyName: '',
  shipperAddress: initShipperAddress,
  pickupDate: new Date().toISOString(),
  consigneeId: null,
  consigneeCompanyName: '',
  consigneeAddress: initConsigneeAddress,
};

export const initValidForm = {
  customerId: true,
  shipperId: true,
  shipperCompanyName: true,
  shipperAddress: true,
  pickupDate: {
    valid: true,
    nextDay: true,
  },
  consigneeId: true,
  consigneeCompanyName: true,
  consigneeAddress: true,
  classification: true,
  urgency: true,
  primaryEquipmentType: true,

  totalWeightMax: true,
  totalWeightMin: true,

  linearFootageMax: true,
  linearFootageMin: true,
  insuranceCommercialValue: true,
};

export const initAction = {
  mode: null,
  shipper: false,
  consignee: false,
};

export const classOptions = [
  {
    label: 'LTL',
    value: QUOTE_CLASSIFICATION.LTL,
  },
  {
    label: 'FTL',
    value: QUOTE_CLASSIFICATION.FTL,
  },
  {
    label: 'Volume LTL',
    value: QUOTE_CLASSIFICATION.VOLUME_LTL,
  },
];

export const urgencyOptions = [
  {
    label: 'Live',
    value: 'live',
  },
  {
    label: 'RFQ',
    value: 'rfq',
  },
];

export const scriptId = 'google-maps-api';
