import React from 'react';
import AccordionCmp from 'src/components/AccordionCmp';

import LaneHistory from './LaneHistory';
import PredictivePrice from './PredictivePrice';
import RateGather from './RateGather';
import TruckFind from './TrcukFind';

const PriceingTools = () => {
  console.log('PriceingTools');

  return (
    <div>
      <AccordionCmp
        title={
          <h5 className="text-base font-semibold text-grayLight900">
            Rate Gathering
          </h5>
        }
        contentParentClass="!border-0 !mt-0"
        className="[&:not(:last-child)]:mb-5 !px-5 !py-5 !bg-gray25 !rounded-xl"
      >
        <RateGather />
      </AccordionCmp>

      <AccordionCmp
        title={
          <h5 className="text-base font-semibold text-grayLight900">
            Truck Finding
          </h5>
        }
        contentParentClass="!border-0 !mt-0"
        className="[&:not(:last-child)]:mb-5 !px-5 !py-5 !bg-gray25 !rounded-xl"
      >
        <TruckFind />
      </AccordionCmp>

      <AccordionCmp
        title={
          <h5 className="text-base font-semibold text-grayLight900">
            Lane History
          </h5>
        }
        contentParentClass="!border-0 !mt-0"
        className="[&:not(:last-child)]:mb-5 !px-5 !py-5 !bg-gray25 !rounded-xl"
      >
        <LaneHistory />
      </AccordionCmp>

      <AccordionCmp
        title={
          <h5 className="text-base font-semibold text-grayLight900">
            Predictive Pricing
          </h5>
        }
        contentParentClass="!border-0 !mt-0"
        contentClassName="!bg-gray25"
        className="[&:not(:last-child)]:mb-5 !px-5 !py-5 !bg-gray25 !rounded-xl"
      >
        <PredictivePrice id={321} />
      </AccordionCmp>
    </div>
  );
};
export default PriceingTools;
