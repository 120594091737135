import {
  CurrencyDollar,
  InfoCircle,
  Percent01,
  // Plus,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import InputText from 'src/components/InputText/InputText';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
import { getPredictivePrice } from 'src/services/QuoteService';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

import { predictivePriceOptions } from './PredictivePrice.interface';

const chartFilterTab: any = [
  {
    value: 'weekly',
    name: 'Weekly',
    tooltipValue: '',
  },
  {
    value: 'monthly',
    name: 'Monthly',
    tooltipValue: '',
  },
];

const initialSeries = [
  {
    name: 'Max',
    data: [
      1725, 1600, 1750, 1700, 1650, 1400, 1300, 1300, 1200, 1250, 1200, 1100,
      1150, 1250, 1350,
    ],
  },
];

const PredictivePrice = ({ id }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isActiveTab, setIsActiveTab] = useState<string>('weekly');
  const [predictivePriceData, setPredictivePriceData] = useState<any>([]);
  const [series, setSeries] = useState<any>([]);
  const [marginPercentage, setMarginPercentage] = useState<any>('');
  const [totalCost, setTotalCost] = useState<any>('');
  const [totalSalePrice, setTotalSalePrice] = useState<any>('');
  const abortControllerRef = useRef<AbortController | null>(null);

  const generateQuarterSelections = () => {
    const startYear = 2000;
    const currentYear = new Date().getFullYear();
    const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
    const selections = [];

    for (let year = startYear; year <= currentYear; year++) {
      const maxQuarter = year === currentYear ? currentQuarter : 4;

      for (let quarter = 1; quarter <= maxQuarter; quarter++) {
        selections.push(`Q${quarter}-${year}`);
      }
    }

    return selections;
  };

  const quarterArr = generateQuarterSelections();
  const [dateRange, setDateRange] = useState<any>(
    moment().format(quarterArr[0])
  );
  const [chartOptions, setChartOptions] = useState<any>(predictivePriceOptions);

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  const getChartData = () => {
    cancelApiCall();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    const filter = {
      activeTab: isActiveTab,
      range: dateRange,
    };

    setIsLoading(true);
    setChartOptions((prevOptions: any) => ({
      ...prevOptions,
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.2,
          opacityTo: 0.5,
          stops: [0, 50, 90, 100],
        },
      },
    }));

    getPredictivePrice(id, filter, signal)
      .then((response: any) => {
        if (response && response.data) {
          const { predictivePricingData } = response.data;
          setPredictivePriceData(predictivePricingData ?? []);
          setSeries(predictivePricingData?.graphData?.series ?? []);
          setMarginPercentage(predictivePricingData.margin);
          setTotalCost(predictivePricingData.total);

          setTimeout(() => {
            setChartOptions((prevOptions: any) => ({
              ...prevOptions,
              fill: {
                type: 'solid',
                colors: ['transparent'],
              },
              xaxis: {
                ...prevOptions.xaxis,
                categories:
                  isActiveTab === 'weekly'
                    ? response.data.xValues.map(
                        (x: string) => x.split(' - ')[0]
                      )
                    : response.data.xValues,
              },
            }));

            setIsLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 100);
        }
      })
      .catch((e: any) => {
        if (e.code !== 'ERR_CANCELED') setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getPredictivePrice(id)
      .then((response: any) => {
        if (response && response.data) {
          setPredictivePriceData(response?.data ?? []);
          setSeries(response?.data?.graphData?.series ?? []);
        }
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.error(e);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const margin = (totalCost * marginPercentage) / 100;
    const salePrice = parseFloat(totalCost) + margin;

    setTotalSalePrice(salePrice);
  }, [totalCost, marginPercentage]);

  useEffect(() => {
    if (isActiveTab === 'weekly') {
      setDateRange(quarterArr[quarterArr.length - 1]);
    }

    if (isActiveTab === 'monthly') {
      setDateRange(moment().format('YYYY'));
    }
  }, [isActiveTab]);

  useEffect(() => {
    getChartData();
  }, [dateRange]);

  return (
    <>
      <div id="chart" className="">
        <div className="rounded-xl border border-utilityGray200 p-5 w-full flex-1 mr-5">
          <div className="flex sm:flex-row flex-col justify-between gap-2">
            <div className="min-w-[18%] px-3 py-2.5 items-center gap-1 justify-between flex">
              <h4>Predictive Index</h4>
            </div>
            <div className="flex gap-x-5">
              <div className="flex gap-x-5">
                <div className="flex items-center gap-x-1.5">
                  <p className="w-2 h-2 rounded-full bg-[#A4BCFD]"></p>
                  <div className="text-textSecondary text-xs font-normal leading-none">
                    Minimum
                  </div>
                </div>
                <div className="flex items-center gap-x-1.5">
                  <p className="w-2 h-2 rounded-full bg-[#3538CD]"></p>
                  <div className="text-textSecondary text-xs font-normal leading-none">
                    Maximum
                  </div>
                </div>
                <div className="flex items-center gap-x-1.5">
                  <p className="w-2 h-2 rounded-full bg-[#6172F3]"></p>
                  <div className="text-textSecondary text-xs font-normal leading-none">
                    Target
                  </div>
                </div>
              </div>

              <TabButton
                tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 "
                parentClassName="w-full currency-tab quoting-currency"
                activeClassName="text-grayLight900 bg-utilityGray100"
                className=""
                isTooltip={true}
                tabArray={chartFilterTab}
                handleOnClick={(e: any) => {
                  setIsActiveTab(e.target.dataset.value);
                }}
                isActive={isActiveTab}
              />
            </div>
          </div>
          <Chart
            options={chartOptions}
            series={isLoading ? initialSeries : series}
            type="area"
            height={350}
            className={`analytics_totalquotes_tooltip ${
              isLoading ? 'apex-line-chart custom-loading graph-loading' : ''
            }`}
          />
        </div>

        <div className="w-full rounded-xl border border-utilityGray200 px-4 py-4 mt-5 bg-white">
          <div className="flex items-center justify-between pb-4">
            <div className="flex items-center gap-3">
              <TooltipCmp
                message={`${predictivePriceData?.from}`}
                parentClassName="max-w-90"
              >
                <div
                  className={`text-sm truncate p-2 border border-utilityGray200 bg-gray50 rounded-lg ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  <span className="text-grayLight600 font-normal">From: </span>
                  <span className="text-grayLight900 font-medium">
                    {predictivePriceData?.from}
                  </span>
                </div>
              </TooltipCmp>

              <TooltipCmp
                message={`${predictivePriceData?.to}`}
                parentClassName="max-w-90"
              >
                <div
                  className={`text-sm truncate p-2 border border-utilityGray200 bg-gray50 rounded-lg${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  <span className="text-grayLight600 font-normal">To: </span>
                  <span className="text-grayLight900 font-medium">
                    {predictivePriceData?.to}
                  </span>
                </div>
              </TooltipCmp>
            </div>

            {/* <div>
              <ButtonCmp
                className="btn-outline-primary"
                icon={<Plus className="w-4 h-4" />}
              >
                Add Quick Quote
              </ButtonCmp>
            </div> */}
          </div>

          <div className="flex bg-gray25 p-3 rounded-xl gap-3">
            <div className="flex-1 bg-white border border-utilityGray200 p-3 rounded-xl">
              <h6 className="text-grayLight600 text-sm font-normal mb-4">
                Target Buy Rate
              </h6>
              <div className="flex gap-x-5 items-center">
                <h6
                  className={`text-grayLight900 text-3xl font-semibold ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  ${predictivePriceData?.total}
                </h6>
                <div className="flex-1">
                  <p className="text-grayLight600 text-sm font-normal mb-1">
                    Confidence
                  </p>
                  <div className="progress-range-container">
                    <div
                      className="progress-range-bar !bg-[#17B26A]"
                      style={{
                        width: `${predictivePriceData?.confidenceLevel}%`,
                      }}
                    >
                      <div className="progress-range-thumb progress-range-thumb-invisible left-[100%]">
                        <div className="progress-tooltip absolute bottom-full left-1/2 -translate-x-1/2 px-3 py-2 rounded-lg border border-utilityGray200 bg-white tooltip-custom-arrow text-xs font-semibold mb-3.5">
                          {predictivePriceData?.confidenceLevel}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 flex items-center gap-2 bg-white border border-utilityGray200 p-3 rounded-xl">
              <div className="w-1/3">
                <h6 className="text-textSecondary text-xs font-medium mb-1.5">
                  Total Cost
                </h6>

                <InputText
                  inputType="number"
                  inputName="totalCost"
                  value={totalCost}
                  className="pl-8"
                  parentClassName="w-full"
                  icon={<CurrencyDollar className="input-currency-sign" />}
                  onChangeFunc={(e: any) => {
                    setTotalCost(e.target.value);
                  }}
                />
              </div>
              <div className="w-1/3">
                <h6 className="text-textSecondary text-xs font-medium mb-1.5">
                  Margin
                </h6>

                <InputText
                  inputType="number"
                  inputName="marginPercentage"
                  value={marginPercentage}
                  className="pl-8"
                  parentClassName="w-full"
                  icon={<Percent01 className="input-currency-sign" />}
                  onChangeFunc={(e: any) => {
                    setMarginPercentage(e.target.value);
                  }}
                />
              </div>
              <div className="w-1/3">
                <h6 className="text-textSecondary text-xs font-medium mb-1.5">
                  Total Sale Price
                </h6>

                <InputText
                  inputType="text"
                  inputName="totalSalePrice"
                  value={getFormattedNumber(totalSalePrice)}
                  className="pl-8"
                  icon={<CurrencyDollar className="input-currency-sign" />}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 rounded-[10px] border border-utilityBrand200 bg-primary50 p-3 text-xs text-grayLight600 font-normal flex items-center">
            <InfoCircle className="w-4 h-4 mr-1" />
            Your average margin for this customer is
            <span className="font-semibold ml-1"> 17.45%</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PredictivePrice;
