import moment from 'moment';
import React, { useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import DateRangePicker from 'src/components/DateRangePicker';
import TabButton from 'src/components/TabButton';
import { useRolePermission } from 'src/utils/CommonFunctions';

export const FilterArray: any = [
  {
    value: 'daily',
    name: 'Today',
  },
  {
    value: 'weekly',
    name: 'This Week',
  },
  {
    value: 'monthly',
    name: 'This Month',
  },
];

const Activity = () => {
  const [startDate, setStartDate] = useState<any>(() => '');
  const [endDate, setEndDate] = useState<any>(() => '');
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>(
    () => 'custom'
  );
  const [allTimeStartDate, setAllTimeStartDate] = useState<any>('');
  const [activeFilter, setActiveFilter] = useState(() => 'daily');
  const [isPrevYearDisable, setIsPrevYearDisable] = useState<any>(false);
  const [filterAfterPrevNext, setFilterAfterPrevNext] = useState('daily');
  const { hasRoleV2 } = useRolePermission();

  console.log(
    '⭐️ ~ Activity ~ setStartDate:',
    setStartDate,
    setEndDate,
    setSelectedTimeRange,
    setAllTimeStartDate,
    setFilterAfterPrevNext
  );

  const handleDatePickerChange = (date: any, timeRange?: string) => {
    const [start, end] = date;

    if (
      timeRange === 'weekly' ||
      timeRange === 'monthly' ||
      timeRange === 'yearly'
    ) {
      setActiveFilter(timeRange);
    } else if (
      moment(start).isSame(moment(), 'days') &&
      moment(end).isSame(moment(), 'days')
    ) {
      setActiveFilter('daily');
    } else {
      setActiveFilter('');

      const twoYearsAgo = moment().subtract(1, 'years').startOf('year');
      const daysDiff = moment(end).diff(moment(start), 'days') + 1;
      const prevMonth = moment(start).subtract(daysDiff, 'day');

      if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'days')) {
        setIsPrevYearDisable(true);
      } else {
        setIsPrevYearDisable(false);
      }

      // setIsNextYearDisable(false);
      // if (moment(end).format("DD-MM-YYYY") >= moment().format("DD-MM-YYYY")) {
      //     setIsNextYearDisable(true);
      // }

      setStartDate(start);
      setEndDate(end);

      if (!end) {
        setEndDate(start);
      }
    }

    if (timeRange) {
      setSelectedTimeRange(timeRange);
    }
  };

  const tasks = [
    {
      time: '10:00',
      sql: '#SQL-L-206241',
      id: '#41836',
      type: 'LTL',
      rates: 20,
      calls: 10,
    },
    {
      time: '20:00',
      sql: '#SQL-L-206241',
      id: '#41836',
      type: 'LTL',
      rates: 20,
      calls: 40,
    },
    {
      time: '30:00',
      sql: '#SQL-L-206241',
      id: '#41836',
      type: 'LTL',
      rates: 10,
      calls: 78,
    },
    {
      time: '15:00',
      sql: '#SQL-L-206241',
      id: '#41836',
      type: 'LTL',
      rates: 20,
      calls: 100,
    },
    {
      time: '10:00',
      sql: '#SQL-L-206241',
      id: '#41836',
      type: 'LTL',
      rates: 20,
      calls: 10,
    },
    {
      time: '20:00',
      sql: '#SQL-L-206241',
      id: '#41836',
      type: 'LTL',
      rates: 20,
      calls: 40,
    },
    {
      time: '30:00',
      sql: '#SQL-L-206241',
      id: '#41836',
      type: 'LTL',
      rates: 10,
      calls: 78,
    },
    {
      time: '15:00',
      sql: '#SQL-L-206241',
      id: '#41836',
      type: 'LTL',
      rates: 20,
      calls: 100,
    },
  ];

  return (
    <div className="p-6 bg-white ">
      {/* Filter Section */}
      <div className="flex justify-between mb-8">
        <div className="flex space-x-2">
          <TabButton
            className="leading-4 sm:w-auto w-1/4 flex-grow !px-1 xxl:!text-xs xl:!text-[11px] xls:!text-[10px] !text-[11px]"
            activeClassName="!bg-utilityGray100"
            tabArray={FilterArray}
            parentClassName="w-full"
            tabParentClassName="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)] sm:min-w-[calc(50%_-_6px)] min-w-full"
            isActive={filterAfterPrevNext !== 'custom' ? activeFilter : ''}
            handleOnClick={(e: any) => {
              // if (activeFilter === e.target.dataset.value) getFilterRange();
              setActiveFilter(e.target.dataset.value);
            }}
          />
        </div>
        <div>
          <DateRangePicker
            handleDatePickerChange={(dates: any, type?: string) =>
              handleDatePickerChange(dates, type)
            }
            selectedTimeRange={selectedTimeRange}
            startDate={startDate}
            endDate={endDate}
            allTimeStartDate={allTimeStartDate}
            showYearDropdown={false}
            isToday={activeFilter === 'daily'}
            isShowDropDownIcon={false}
            containerClassName="xls:left-0 xls:right-[unset] lg:left-[unset] lg:right-0 left-0"
            parentClassName="sales-datepicker"
            isPrevDisabled={isPrevYearDisable}
            // onPrevNextClick={(type: string) => getFilterRange(type)}
            setTodayAsDefaultOnClear={true}
          />
        </div>
      </div>

      {/* Table Section */}
      <div className="mt-4">
        <h2 className="text-lg font-semibold mb-4">Completed tasks</h2>
        <div className="space-y-2">
          {tasks.map((task, index) => (
            <div
              key={index}
              className="flex items-center bg-white border-y border-gray-100 rounded-lg p-2"
            >
              {/* Time Indicator */}
              <div className="flex items-center space-x-1 min-w-[80px] border-r border-gray-200 pr-4">
                <div className="w-4 h-4 rounded-full bg-green-500"></div>
                <span className="text-sm text-gray-600">{task.time}</span>
              </div>

              {/* Task Details */}
              <div className="flex items-center space-x-4 ml-6 border-r border-gray-200 pr-6">
                <span className="text-sm text-primary font-normal border-r border-gray-200 ">
                  {task.sql}
                </span>
                <span className="text-sm text-primary font-normal">
                  {task.id}
                </span>
                <span className="text-gray-600 px-2 py-1 rounded text-xs font-normal">
                  <BadgeCmp style="modern" type="success">
                    {task.type}
                  </BadgeCmp>
                </span>
              </div>

              {/* Stats */}
              <div className="ml-auto flex items-center space-x-8">
                <div className="flex items-center space-x-2 border-r border-gray-200 pr-6">
                  <span className="text-lg font-semibold">{task.rates}</span>
                  <span className="text-gray-500 text-sm">Rates Gathered</span>
                </div>
                <div className="flex items-center space-x-2 min-w-[150px]">
                  <span className="text-lg font-semibold">{task.calls}</span>
                  <span className="text-gray-500 text-sm">Calls Processed</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Activity;
