import { Edit01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import { useRolePermission } from 'src/utils/CommonFunctions';

import TooltipCmp from '../../../components/TooltipCmp';

const ToQuoteListLoading = () => {
  const { hasRoleV2 } = useRolePermission();

  return (
    <>
      <td className="px-5 py-4 min-w-44 w-44 max-w-44">
        <div className="flex items-center custom-loading">
          <div className="table-profile-user-circle-xl">KD</div>
          <div className="ml-3 text-xs font-medium max-w-32">
            <div className="text-grayLight900 font-medium leading-tight truncate skeleton-loading">
              Kishan Dodiya
            </div>
            <div className="text-grayLight600 font-normal leading-tight truncate">
              #SQ-L-100502
            </div>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 min-w-[100px] max-w-[100px] w-[100px]">
        <BadgeCmp style="modern" type="success" mainClassName="custom-loading">
          open
        </BadgeCmp>
      </td>
      <td className="px-5 py-4 min-w-[100px] max-w-[100px] w-[100px]">
        <BadgeCmp style="modern" type="success" mainClassName="custom-loading">
          <span data-test-id="wal-QuotingDashboard-status" className="text-xs">
            Archieved
          </span>
        </BadgeCmp>
      </td>
      <td className="px-5 py-4 max-w-40 min-w-40 w-40">
        <span className="truncate custom-loading">Test co</span>
      </td>
      <td className="px-5 py-4 xxl:max-w-48 xxl:w-48 xxl:min-w-48 w-40 max-w-40 min-w-40">
        <p className="flex  custom-loading">
          <span className="text-gray500 font-normal">From: </span>
          <span className="font-medium truncate max-w-[180px]">
            10430 Harry Hines Bl...
          </span>
        </p>

        <p className="flex  custom-loading">
          <span className="text-gray500 font-normal">To: </span>
          <span className="font-medium truncate max-w-[180px]">
            500 Pearl St, New Yo...
          </span>
        </p>
      </td>
      <td className="px-5 py-4 min-w-40 w-40 max-w-40">
        <span className="custom-loading">
          Apr 18<sup>th</sup> , 10:44:55 AM
        </span>
      </td>
      <td className="px-3 py-4 min-w-40 w-40 max-w-40">
        <span className="custom-loading">
          Apr 18<sup>th</sup> - 2024
        </span>
      </td>
      <td className="pl-3 pr-1 py-4 min-w-28 w-28 max-w-28">
        <span className=" custom-loading">15</span>
      </td>
      <td className="px-3 py-4 min-w-28 w-28 max-w-28">
        <span className=" custom-loading">300 lbs</span>
      </td>
      <td className="px-5 py-4 min-w-28 w-28 max-w-28">
        <BadgeCmp
          style="modern"
          type="error"
          mainClassName="custom-loading rounded-md"
        >
          Volume LTL
        </BadgeCmp>
      </td>
      {hasRoleV2('admin') ? (
        <td className="px-5 py-4 pl-0 w-[64px] max-w-[64px] min-w-[64px]">
          <div className="justify-end items-center gap-2 flex custom-loading">
            <TooltipCmp message="Edit" type="dark">
              <Edit01 className="w-4 h-4 relative cursor-pointer" />
            </TooltipCmp>
            {/* 
            <TooltipCmp message="Delete" type="dark">
              <Trash01 className="w-4 h-4 relative cursor-pointer" />
            </TooltipCmp> */}
          </div>
        </td>
      ) : (
        ''
      )}
    </>
  );
};

export default ToQuoteListLoading;
