import {
  Calendar,
  CurrencyDollar,
  InfoCircle,
  PackageCheck,
  Percent02,
} from '@untitled-ui/icons-react/build/cjs';
import { CountryCode } from 'libphonenumber-js';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import { CURRENCY, MARGIN_TYPE } from 'src/constants/common';
import { getCarrierServicePrices } from 'src/services/CarrierService';
// import { CarrierList } from 'src/services/CarrierService';
import { getTopCarriers } from 'src/services/GeographyService';
import { quickQuote } from 'src/services/QuoteService';
import {
  fixedDecimal,
  getDateWithSuffixFormat,
  getFormattedPickupDate,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

const initRequiredFields = [
  // 'carrierId',
  // 'totalCharge',
  'finalCharge',
  // 'phone_coiintry_code',
  // 'contactPhone',
  // 'projectedDeliveryDate',
];

const tabArray: any = [
  {
    value: '$',
    name: '$',
  },
  {
    value: '%',
    name: '%',
  },
];

const initValidForm = {
  // carrierId: true,
  // projectedDeliveryDate: false,
  // totalCharge: false,
  finalCharge: false,
};

const getCarrierProvider = (carrier: any): string =>
  carrier.isFreightcom || carrier.isFreightcom === 1
    ? 'Freightcom'
    : carrier.isBanyan || carrier.isBanyan === 1
    ? 'Banyan'
    : 'Direct';

const AddQuickQuote = ({
  quoteId,
  currency,
  serviceList,
  handleClose,
  setIsRefresh,
  selectedCarrierId,
  selectedCarrierRates,
}: any) => {
  const carrierRates = Array.isArray(selectedCarrierRates)
    ? selectedCarrierRates
    : [selectedCarrierRates];
  const [isPrimaryBtnClicked, setIsPrimaryBtnClicked] =
    useState<boolean>(false);
  const filterRef: any = useRef(null);
  const [formData, setFormData] = useState<any>({});
  // const [requiredFields] = useState(initRequiredFields);

  const [validForm, setValidForm] = useState<any>(initValidForm);
  const [search, setSearch] = useState('');
  const [isValid, setIsValid] = useState<any>(false);
  const [activeMarginType, setActiveMarginType] = useState<any>('$');
  const [isShowCarrierList, setIsShowCarrierList] = useState(false);
  // const [searchValue, setSearchValue] = useState('');
  // const [carrierName, setCarrierName] = useState('');
  const [carriers, setCarriers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notRatedServices, setNotRatedServices] = useState<any>(0);
  // const [filteredCarrierList, setFilteredCarrierList] = useState<any>([]);
  useEffect(() => {
    setIsValid(Object.values(validForm).every((value) => value));
  }, [validForm]);

  useEffect(() => {
    const existingFormData: any = {};
    const existingNonQuoteRequiredFields: any = [];
    const existingRequiredFields = [...initRequiredFields];
    let initialNotRatedServicesCount = 0;

    if (selectedCarrierRates.length > 0) {
      carrierRates.forEach((selectedCarrierRate: any) => {
        const quotedServices: any = [];
        const nonQuotedServices: any = [];
        const priceData: any = {};

        if (
          selectedCarrierRate.services &&
          selectedCarrierRate.services.length
        ) {
          selectedCarrierRate.services.forEach((service: any) => {
            if (service.isQuoted) {
              quotedServices.push({
                [service.serviceId.slug]: {
                  name: service.serviceId?.name,
                  value: service.value ? fixedDecimal(service.value, 2) : 0,
                  serviceId: service.serviceId?.id,
                },
              });
            } else {
              initialNotRatedServicesCount++;
              nonQuotedServices.push({
                [service.serviceId.slug]: {
                  name: service.serviceId?.name,
                  value: service.value ? fixedDecimal(service.value, 2) : 0,
                  serviceId: service.serviceId?.id,
                },
              });
              existingNonQuoteRequiredFields.push(service.serviceId.slug);
            }
            existingRequiredFields.push(service.serviceId.slug);
          });
        }

        getCarrierServicePrices(selectedCarrierRate.carrierId)
          .then((response: any) => {
            if (response.data && response.data.length) {
              response.data.forEach(
                (data: any) =>
                  (priceData[data.serviceId] =
                    currency === CURRENCY.CAD ? data.priceCAD : data.priceUSD)
              );
            }
            selectedCarrierRate.carrierPriceData = priceData;
          })
          .catch(console.log);

        existingFormData[selectedCarrierRate.id] = {
          transitTime: selectedCarrierRate.transitTime,
          carrierId: selectedCarrierRate.carrierId,
          contactPhone: selectedCarrierRate.contactPhone,
          projectedDeliveryDate: selectedCarrierRate.projectedDeliveryDate,
          margin:
            selectedCarrierRate.margin > 0
              ? fixedDecimal(selectedCarrierRate.margin, 2)
              : selectedCarrierRate.margin,
          marginUnit:
            selectedCarrierRate.marginUnit > 0
              ? fixedDecimal(selectedCarrierRate.marginUnit, 2)
              : selectedCarrierRate.marginUnit,
          additionalCharges:
            selectedCarrierRate.additionalCharges > 0
              ? fixedDecimal(selectedCarrierRate.additionalCharges, 2)
              : 0,
          finalCharge:
            selectedCarrierRate.finalCharge > 0
              ? fixedDecimal(selectedCarrierRate.finalCharge, 2)
              : 0,
          totalCharge:
            selectedCarrierRate.totalCharge > 0
              ? fixedDecimal(selectedCarrierRate.totalCharge, 2)
              : 0,
          marginType: selectedCarrierRate.marginType,
          carrierPriceData: priceData,
          quotedServices,
          nonQuotedServices,
        };
      });
      setFormData(existingFormData[selectedCarrierId]);
      // setResetFormData(existingFormData);

      setNotRatedServices(initialNotRatedServicesCount);
    }
  }, [selectedCarrierRates]);

  // const getShowFilteredCarrierList = (search: string, originalList: any) => {
  //   const filteredList = originalList.filter((originalData: any) =>
  //     originalData?.name?.toLowerCase().includes(search.toLowerCase().trim())
  //   );

  //   return [...filteredList] as any;
  // };

  // useEffect(() => {
  //   if (searchValue !== '') {
  //     const filteredList = getShowFilteredCarrierList(searchValue, carriers);
  //     setFilteredCarrierList(filteredList);
  //   } else {
  //     setFilteredCarrierList(carriers);
  //   }
  // }, [searchValue]);

  useEffect(() => {
    if (!selectedCarrierId) {
      let services: any = [];

      const serviceListArr = Object.values(serviceList);

      if (serviceListArr && serviceListArr?.length) {
        serviceListArr.forEach((service: any) => {
          services.push({
            [service.slug]: {
              name: service.serviceName,
              value: '',
              serviceId: service.serviceId,
            },
          });
          // existingRequiredFields.push(service.serviceId.slug);
        });
      }

      setFormData({ ...formData, services: services, currency });
    }
  }, [serviceList]);

  const getCarriers = () => {
    setIsLoading(true);
    getTopCarriers({ search: search, limit: 50 })
      .then((response: any) => {
        if (response?.data) {
          const carrierListOptions = response?.data?.map((carr: any) => ({
            label: `${carr?.name}`,
            subLabel: `(${getCarrierProvider(carr)})`,
            value: carr?.id,
            image: carr?.image ? carr?.imageUrl + carr?.image : null,
            isImage: true,
            isPrivateCarrier: carr?.isPrivateCarrier,
            contactPhone: carr?.contactPhone,
            provider: getCarrierProvider(carr),
          }));

          setCarriers(carrierListOptions);
          setIsLoading(false);
          // setFilteredCarrierList(carrierListOptions);
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(console.log);
  };
  useEffect(() => {
    getCarriers();
    // setIsLoading(true);
    // CarrierList()
    //   .then((response: any) => {
    //     setIsLoading(true);
    //     setCarriers(response?.data);
    //     setFilteredCarrierList(response?.data);
    //   })
    //   .catch(console.error)
    //   .finally(() => setIsLoading(false));
  }, []);

  const handleSubmit = async () => {
    setIsPrimaryBtnClicked(true);

    try {
      formData.quoteId = quoteId;
      formData.selectedCarrierId = selectedCarrierId;
      formData.currency = currency;

      quickQuote(formData)
        .then(() => {
          setFormData({});
          handleClose();
          setIsRefresh(true);
          WalToast.success('Active quote added successfully', '');
        })
        .finally(() => {
          setFormData(null);
          setIsPrimaryBtnClicked(false);
        })
        .catch(console.error);
    } catch (error) {
      handleClose(false);
      setIsRefresh(true);
    }
  };

  useEffect(() => {
    Object.entries(formData).forEach(([key, value]) => {
      if (initRequiredFields.includes(key)) {
        setValidForm((prevValidForm: any) => ({
          ...prevValidForm,
          [key]: value ? true : false,
        }));
      }
    });
    console.log('formData', formData?.pickupDate);
  }, [formData]);

  const calculateMarginAndTotal = (event: any, isService = false) => {
    const { target } = event;
    const { name } = target;

    const existingFormData: any = { ...formData };

    if (
      ['totalCharge', 'marginUnit', 'margin', 'finalCharge'].includes(name) ||
      isService
    ) {
      let additionalCharges = existingFormData.additionalCharges || 0;

      if (isService) {
        let updatedAdditionCharge = 0;
        existingFormData?.services?.forEach((quoteService: any) => {
          Object.entries(quoteService).forEach(([, service]: any) => {
            service.value = service.value || 0;
            updatedAdditionCharge += parseFloat(service.value);
          });
        });
        existingFormData.additionalCharges = fixedDecimal(
          updatedAdditionCharge
        );
      }

      const total: number =
        parseFloat(existingFormData.totalCharge || 0) +
        parseFloat(existingFormData.additionalCharges || 0);

      existingFormData.marginType = MARGIN_TYPE.PERCENTAGE;

      let margin: number = existingFormData.margin
        ? parseFloat(existingFormData.margin)
        : 0;
      let marginUnit: number = existingFormData.marginUnit
        ? parseFloat(existingFormData.marginUnit)
        : 0;

      if (name === 'totalCharge' || isService) {
        if (!existingFormData.finalCharge) {
          existingFormData.finalCharge = fixedDecimal(total + margin, 2);
          existingFormData.marginUnit =
            total > 0 ? fixedDecimal((100 * margin) / total, 2) : 0;
        } else {
          existingFormData.margin = existingFormData.finalCharge - total;
          existingFormData.marginUnit =
            total > 0
              ? fixedDecimal((100 * existingFormData.margin) / total, 2)
              : 0;
        }
      } else if (name === 'finalCharge') {
        if (total > 0) {
          existingFormData.margin = existingFormData.finalCharge - total;
          existingFormData.marginUnit =
            total > 0
              ? fixedDecimal((100 * existingFormData.margin) / total, 2)
              : 0;
        } else {
          existingFormData.totalCharge = existingFormData.finalCharge;
        }
      } else if (name === 'marginUnit') {
        if (!existingFormData.finalCharge) {
          existingFormData.finalCharge =
            total > 0 ? fixedDecimal((total / 100) * marginUnit, 2) : 0;
          existingFormData.margin =
            total > 0 ? fixedDecimal((total / 100) * marginUnit, 2) : 0;
        } else {
          existingFormData.totalCharge = fixedDecimal(
            existingFormData.finalCharge / (1 + marginUnit / 100) -
              additionalCharges
          );
          existingFormData.margin = fixedDecimal(
            existingFormData.finalCharge -
              (existingFormData.totalCharge + additionalCharges)
          );
        }
      } else if (name === 'margin') {
        if (!existingFormData.finalCharge) {
          existingFormData.finalCharge = fixedDecimal(
            total + (existingFormData.margin || 0),
            2
          );
        } else {
          existingFormData.totalCharge =
            existingFormData.finalCharge -
            (existingFormData.margin || 0) -
            additionalCharges;
        }

        existingFormData.marginUnit =
          total > 0
            ? fixedDecimal(
                (100 * margin) /
                  ((existingFormData.totalCharge || 0) + additionalCharges),
                2
              )
            : 0;
      }
    }

    setFormData(existingFormData);
  };

  const handleInputChange = (event: any, isService = false) => {
    const { target } = event;
    const { name, value } = target;

    const regex = /^[0-9.]*$/;

    if (
      (['totalCharge', 'marginUnit', 'transitTime', 'margin'].includes(name) ||
        isService) &&
      !regex.test(value)
    ) {
      return;
    }

    if (initRequiredFields.includes(name)) {
      setIsValid({ ...isValid, [name]: value ? true : false });
    }
    setFormData((prevFormData: any) => {
      if (isService) {
        prevFormData?.services?.forEach((quoteService: any, index: number) => {
          Object.entries(quoteService).forEach(([slug, service]: any) => {
            service.value = service.value || '';
            prevFormData.services[index][slug] = {
              ...service,
              value: name === slug ? value ?? 0 : parseFloat(service.value),
            };
          });
        });
      }

      return {
        ...prevFormData,
        [name]: value,
      };
    });
  };

  const handleTabChange = () => {
    setActiveMarginType((prevType: any) => {
      const newType = prevType === '$' ? '%' : '$';
      // const updatedFormData = { ...formData };

      // const total =
      //   parseFloat(updatedFormData?.totalCharge || 0) +
      //   parseFloat(updatedFormData?.additionalCharges);

      // if (newType === '%') {
      //   const margin = parseFloat(updatedFormData?.margin || 0);
      //   updatedFormData.marginUnit =
      //     total > 0 && margin > 0 ? fixedDecimal((margin / total) * 100, 2) : 0;

      //   updatedFormData.finalCharge = fixedDecimal(total + margin, 2);
      // } else {
      //   const marginUnit = parseFloat(updatedFormData?.marginUnit || 0);

      //   updatedFormData.margin =
      //     total > 0 && marginUnit > 0
      //       ? fixedDecimal((total * marginUnit) / 100, 2)
      //       : 0;

      //   updatedFormData.finalCharge = fixedDecimal(
      //     total + parseFloat(updatedFormData?.margin),
      //     2
      //   );
      // }

      // setFormData(updatedFormData);

      return newType;
    });
  };

  const handleNumberChange = (
    country: CountryCode,
    phone: string,
    code: string,
    isPhoneValid: boolean
  ) => {
    var validFormData = {
      phone: true,
      isPhoneValid: true,
      inbondContactValue: true,
    };

    if (phone === '') {
      validFormData.phone = false;
      validFormData.isPhoneValid = false;
      validFormData.inbondContactValue = false;
    } else {
      validFormData.isPhoneValid = isPhoneValid;
    }
    if (phone !== '')
      setFormData({
        ...formData,
        phone_country_code: code,
        contactPhone: `+${code}${phone}`,
      });
    else
      setFormData({
        ...formData,
        phone_country_code: code,
        contactPhone: ``,
      });
  };

  useEffect(() => {
    if (search) {
      const delayDebounceFn = setTimeout(() => {
        getCarriers();
      }, 700);

      return () => clearTimeout(delayDebounceFn);
    } else {
      getCarriers();
    }
  }, [search]);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (
        isShowCarrierList &&
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        event.target.name !== 'searchOrder'
      ) {
        setIsShowCarrierList(false);
      }
    };

    const handleEscapePress = (event: any) => {
      if (isShowCarrierList && event.key === 'Escape') {
        setIsShowCarrierList(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscapePress);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [isShowCarrierList]);

  // const [isNotScrollable, setIsNotScrollable] = useState<undefined | boolean>(
  //   undefined
  // );

  // useEffect(() => {
  //   const checkScrollable = () => {
  //     const articleElement = document.getElementById('add_quick_quote');

  //     if (articleElement) {
  //       const isVerticallyScrollable =
  //         articleElement.scrollHeight > articleElement.clientHeight;

  //       const isHorizontallyScrollable =
  //         articleElement.scrollWidth > articleElement.clientWidth;

  //       setIsNotScrollable(
  //         !(isVerticallyScrollable || isHorizontallyScrollable)
  //       );
  //     }
  //   };

  //   checkScrollable();

  //   window.addEventListener('resize', checkScrollable);

  //   return () => {
  //     window.removeEventListener('resize', checkScrollable);
  //   };
  // }, []);

  // useEffect(() => {
  //   console.log('isNotScrollable ', isNotScrollable);
  // }, [isNotScrollable]);

  return (
    <CommonModal
      title={selectedCarrierId ? 'Edit Quote' : 'Add Quote'}
      titleDesc={'Review rate and margin details to create an active quote.'}
      handleClose={() => handleClose(false)}
      headerIcon={<PackageCheck />}
      size={`max-w-[480px]`}
      modalClassName=""
      primaryBtnText="Confirm"
      secondaryBtnText="Cancel"
      primaryBtnDisabled={!isValid || isPrimaryBtnClicked}
      primaryBtnOnClick={handleSubmit}
      secondaryBtnOnClick={() => handleClose(false)}
      childrenContainerId={'add_quick_quote'} // should be unique
    >
      <div className="w-full p-5 flex flex-col gap-y-4">
        {/* Warning message */}

        {/* Carrier Info */}
        {notRatedServices !== 0 && (
          <div className="rounded-md bg-utilityError50 border border-utilityError300 px-3 py-2 flex gap-x-1 font-normal text-xs text-grayLight600 text-wrap">
            <InfoCircle className="text-textSecondary w-4 h-4 flex-none" />
            <div>
              <span className="text-grayLight900 font-semibold">Warning:</span>{' '}
              {notRatedServices} Additional Services not rated. Please contact
              carrier to verify feasibility of the shipment.
            </div>
          </div>
        )}
        <div className="w-full relative">
          <SelectBox
            label="Select Carrier"
            labelClassName="form_label block mb-1.5"
            selectOptionSubLabelClass="!font-normal !text-[11px]"
            selectInputSubLabelClass="!font-normal !text-grayLight600 !text-[11px]"
            labelRight={
              <CheckBox
                classes=""
                labelClassName="!text-primary700"
                label="Unassigned Carrier"
                id="unassignedCarrier"
                labelHtmlFor="unassignedCarrier"
                onChangeFunc={() => {
                  setFormData({
                    ...formData,
                    carrierId: undefined,
                    contactPhone: '',
                    isUnAssignCarrier: !formData.isUnAssignCarrier,
                  });
                }}
                checked={formData.isUnAssignCarrier}
              />
            }
            name="customerId"
            id="customerId"
            size="sm"
            placeholder="All Carrier"
            noOptionMessage="No Carrier Found"
            isSearchable={true}
            className="form_control"
            isLoading={isLoading}
            // isClearable={true}
            options={carriers}
            onChangeFunc={(e: any) => {
              setFormData({
                ...formData,
                carrierId: e?.value,
                contactPhone: e?.contactPhone,
                isUnAssignCarrier: false,
                isPrivateCarrier: e?.isPrivateCarrier,
              });

              // setCarrierName(`${carrier?.name} ${provider}`);
            }}
            onInputChange={(e: any) => {
              setSearch(e);
            }}
            value={
              formData?.carrierId
                ? carriers?.find((e: any) => e?.value == formData?.carrierId)
                : null
            }
            // errorText={error ? error.message : null}
            required
            isClearable
          />
          {/* <InputText
            // label="Carrier name"
            // labelClassName="block mb-1.5"
            inputName="searchOrder"
            placeholder="Enter carrier name"
            className="placeholder:text-secondaryTxtColor"
            parentClassName=""
            searchIconClass={`!right-8`}
            icon={
              <ChevronDown className="absolute top-1/2 -translate-y-1/2 right-2 text-grayText h-5 w-5" />
            }
            value={carrierName}
            onChangeFunc={(e: any) => {
              setFormData({
                ...formData,
                carrierId: undefined,
                contactPhone: '',
                isUnAssignCarrier: true,
              });
              setSearchValue(e.target.value);
              setCarrierName(e?.target?.value);
            }}
            onFocus={() => {
              setIsShowCarrierList(true);
            }}
            // errorText={errors?.orderID?.message ?? null}
            isClearable={true}
          />
          {isShowCarrierList && (
            <div
              id="dropdownDelay"
              ref={filterRef}
              className={`min-w-[262px] z-10 absolute top-15 bg-white w-full border border-borderPrimary shadow-md my-1.5 rounded-[10px] left-0 top-full`}
            >
              <ul
                className="mt-1.5 mb-[9px] max-h-[180px] overflow-y-auto custom-scrollbar-v2"
                aria-labelledby="dropdownDelayButton"
              >
                {!isLoading && filteredCarrierList?.length > 0 ? (
                  filteredCarrierList
                    .slice(0, 50)
                    .map((carrier: any, index: number) => (
                      <>
                        <li
                          key={index}
                          className={`mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 hover:text-primary transition-all duration-[0.3s] ${
                            carrier.id === formData?.carrierId
                              ? 'bg-gray50'
                              : ''
                          }`}
                          onClick={() => {
                            setFormData({
                              ...formData,
                              carrierId: carrier?.id,
                              contactPhone: carrier?.contactPhone,
                              isUnAssignCarrier: false,
                            });
                            let provider = getCarrierProvider(carrier);

                            setCarrierName(`${carrier?.name} ${provider}`);

                            setSearchValue(carrier?.name);
                            // setShowOrdersSearchValue();
                            setIsShowCarrierList(false);
                          }}
                        >
                          <div className="flex justify-between items-center align-middle">
                            {' '}
                            <div className="flex justify-center items-center">
                              {carrier.image ? (
                                <img
                                  src={`${carrier.imageUrl}${carrier.image}`}
                                  className="table-profile-icn-circle-xl !w-6 !h-6 rounded-full"
                                  alt={carrier.name}
                                  title={carrier.name}
                                  onError={onError}
                                />
                              ) : (
                                <div className="table-profile-customer-circle-xl !w-6 !h-6 text-xs rounded-full">
                                  {getShortName(carrier.name)}
                                </div>
                              )}
                              <span className="font-medium mx-2 flex gap-2">
                                {carrier?.name}
                                <div className="flex items-center">
                                  <div className="font-medium">
                                    {getCarrierProvider(carrier)}
                                  </div>
                                </div>
                              </span>
                            </div>
                            {carrier.id === formData?.carrierId ? (
                              <Check className="w-4 h-4 ml-2 text-primary" />
                            ) : (
                              ''
                            )}
                          </div>
                        </li>
                      </>
                    ))
                ) : isLoading && filteredCarrierList?.length == 0 ? (
                  [...Array(5)].map((_, index) => (
                    <li
                      key={index}
                      className={`mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 hover:text-primary transition-all duration-[0.3s]`}
                    >
                      <div className="flex justify-between items-center align-middle">
                        <div className="flex justify-center items-center">
                          <div className="table-profile-customer-circle-xl !w-6 !h-6 text-xs rounded-full">
                            AB
                          </div>
                          <span className="font-medium mx-2 flex gap-2">
                            <div className="font-medium">(Freightcom)</div>
                          </span>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <li
                    className={`mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 transition-all duration-[0.3s] `}
                  >
                    {' '}
                    <span className="font-semibold mr-2 items-center justify-center flex">
                      No Carriers Found
                    </span>
                  </li>
                )}
              </ul>
            </div>
          )} */}
        </div>
        {/* <div className="p-1 w-1/2">
              <label className="form_label block mb-1.5">Carrier</label>
              <SelectBox
                name="carrierId"
                placeholder="Carrier Rates"
                parentClassName=" block w-full"
                labelClassName="form_label"
                value={carrierListArr.find(
                  (val: any) => val.value === formData?.carrierId
                )}
                onChangeFunc={(e: any) =>
                  setFormData({
                    ...formData,
                    carrierId: e?.value,
                  })
                }
                options={carrierListArr}
              />
            </div> */}

        {/* <InputText
          label="Carrier quote ID"
          labelClassName="block mb-1.5"
          inputType="text"
          placeholder="Enter carrier quote ID"
          inputName="carrierQuoteId"
          value={formData.carrierQuoteId}
          onChangeFunc={handleInputChange}
          onBlur={handleInputChange}
        /> */}
        {formData?.isPrivateCarrier ? (
          <div className="flex flex-col">
            <label className="form_label block mb-1.5"> Contact Number</label>
            <PhoneInput
              onNumberChange={handleNumberChange}
              errors={false}
              name="contactPhone"
              // disabled={action.mode === 'viewCarrier'}
              value={formData?.contactPhone ?? ''}
            />
          </div>
        ) : (
          ''
        )}

        <DateSelect
          inputName="projectedDeliveryDate"
          className={`form_control `}
          label="Projected delivery"
          labelClassName="block"
          // placeholder={DATE_FORMAT}
          placeholder={'Enter projected delivery'}
          dateFormat="dd/MM/yyyy"
          minDate={moment().toDate()}
          // selected={
          //   formData && formData.projectedDeliveryDate
          //     ? moment(formData.projectedDeliveryDate).toDate()
          //     : moment(new Date().toISOString()).toDate()
          // }
          isClearable
          isDisplayIcon={
            formData && formData.projectedDeliveryDate ? false : true
          }
          selected={
            formData && formData.projectedDeliveryDate
              ? moment(formData.projectedDeliveryDate).toDate()
              : null
          }
          errorText={''}
          value={
            formData.projectedDeliveryDate
              ? getDateWithSuffixFormat(
                  getFormattedPickupDate(formData.projectedDeliveryDate)
                )
              : ''
          }
          onChangeFunc={(date: any) => {
            setFormData({
              ...formData,
              projectedDeliveryDate: date?.value,
            });
          }}
          icon={<Calendar className="h-4 w-4 " />}
          calendarIconClass="!text-primary"
          parentClassName="fixed-width-datepicker right-side-datepicker one-month-datepicker border-b border-utilityGray200 pb-4 datepicker-right-cross-sign"
        />
        <InputText
          label="Cost"
          placeholder="Enter cost"
          labelClassName="block mb-1.5"
          inputType="text"
          inputName="totalCharge"
          className="pl-8"
          parentClassName="w-full"
          value={formData?.totalCharge}
          onChangeFunc={handleInputChange}
          onBlur={calculateMarginAndTotal}
          shouldFormatNumber={true}
          icon={<CurrencyDollar className="input-currency-sign" />}
        />

        {formData?.services?.map((nonQuoteService: any) =>
          Object.entries(nonQuoteService).map(([slug, service]: any) => (
            <>
              <InputText
                inputName={slug}
                className="pl-8"
                parentClassName="w-full"
                value={service?.value > 0 ? service?.value : ''}
                label={service.name}
                labelClassName="block mb-1.5"
                placeholder="0"
                icon={<CurrencyDollar className="input-currency-sign" />}
                onChangeFunc={(e) => handleInputChange(e, true)}
                onBlur={(e) => calculateMarginAndTotal(e, true)}
                shouldFormatNumber={true}
                // onFocus={(e) => handleInputChange(e, true)}
              />
            </>
          ))
        )}

        <div className="border-t border-utilityGray200 pt-4 w-ful">
          <label className="form_label block mb-1.5">Margin</label>
          <div className="form_control p-0 relative !h-auto">
            {activeMarginType === '$' ? (
              <InputText
                inputName="margin"
                inputType="text"
                className="!border-0 !h-auto !pl-8 !pr-20"
                labelClassName="block mb-1.5"
                value={formData?.margin}
                placeholder="0"
                onChangeFunc={handleInputChange}
                onBlur={calculateMarginAndTotal}
                shouldFormatNumber={true}
                onFocus={() => {
                  if (formData?.margin === 0) {
                    setFormData((prevFormData: any) => ({
                      ...prevFormData,
                      margin: '',
                    }));
                    setIsValid(false);
                  }
                }}
                icon={<CurrencyDollar className="input-currency-sign" />}
              />
            ) : (
              <InputText
                inputName="marginUnit"
                inputType="text"
                placeholder="Enter margin"
                className="!border-0 !h-auto !pl-8 !pr-20"
                labelClassName="block mb-1.5"
                value={formData?.marginUnit}
                onChangeFunc={handleInputChange}
                onBlur={calculateMarginAndTotal}
                shouldFormatNumber={true}
                onFocus={() => {}}
                icon={<Percent02 className="input-currency-sign" />}
              />
            )}

            <TabButton
              tabParentClassName="absolute top-1/2 -translate-y-1/2 right-1"
              parentClassName="!bg-gray50 !p-[3px]"
              className={`!rounded-md text-gray500 !border-0 !px-[8px] !py-0.5 !text-[11px] cursor-pointer first:font-normal [&>span]:text-gray500 !min-h-[auto]`}
              activeClassName=" !bg-white [&>span]:!text-grayLight900 !shadow-md "
              tabArray={tabArray}
              isActive={activeMarginType}
              isTab={true}
              handleOnClick={handleTabChange}
            />
          </div>
        </div>

        <InputText
          inputType="text"
          label="Sale Price"
          inputName="finalCharge"
          placeholder="Enter sale price"
          className="pl-8"
          labelClassName="block mb-1.5"
          parentClassName="w-full"
          value={formData?.finalCharge}
          onChangeFunc={handleInputChange}
          onBlur={calculateMarginAndTotal}
          shouldFormatNumber={true}
          onFocus={() => {
            if (formData?.finalCharge === 0) {
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                finalCharge: '',
              }));
              setIsValid(false);
            }
          }}
          icon={<CurrencyDollar className="input-currency-sign" />}
        />
      </div>
    </CommonModal>
  );
};
export default AddQuickQuote;
