import React, { useContext } from 'react';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';

import ToastBodyContainer from '../../components/ToastContainer';
import { AuthContext } from '../../context/AuthContext';
import CallCenterModals from '../Twilio/CallCenterModals';

import Sidebar from './Sidebar';

const Layout = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  const pathsToMatch = [
    ROUTES.CUSTOMER_ONBOARDING,
    ROUTES.SUBMISSION,
    ROUTES.THANK_YOU,
  ];
  const isCustomerOnboarding = pathsToMatch.some((path) =>
    matchPath({ path }, location.pathname)
  );

  return (
    <div id="app-layout">
      {isAuthenticated && !isCustomerOnboarding && <Sidebar />}
      <Outlet />
      <ToastBodyContainer />
      {isAuthenticated && !isCustomerOnboarding && <CallCenterModals />}
    </div>
  );
};

export default Layout;
