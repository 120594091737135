import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const ContactList = async (params: any, signal: any) => {
  const response = await axiosInterceptor.get(API.CALL_CENTER.GET, {
    params,
    signal,
  });

  return response;
};

export const addNewContact = async (data: any) => {
  const response = await axiosInterceptor.post(API.CALL_CENTER.CREATE, data);

  return response;
};

export const updateContact = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.CALL_CENTER.UPDATE}/${id}`,
    data
  );

  return response;
};

export const DeleteContact = async (id: number) => {
  const response = await axiosInterceptor.delete(
    `${API.CALL_CENTER.DELETE}/${id}`
  );

  return response;
};

export const CallLogList = async (params: any, signal: any) => {
  const response = await axiosInterceptor.get(API.CALL_CENTER.GET_CALL_LOG, {
    params,
    signal,
  });

  return response;
};

export const ThreadList = async (params: any, signal: any) => {
  const response = await axiosInterceptor.get(API.CALL_CENTER.GET_THREADS, {
    params,
    signal,
  });

  return response;
};

export const CallHistoryList = async (params: any, id: any, signal: any) => {
  const response = await axiosInterceptor.get(
    `${API.CALL_CENTER.GET_CALL_HISTORY}/${id}`,
    {
      params,
      signal,
    }
  );

  return response;
};

export const ThreadsCommunications = async (id: any, signal: any) => {
  const response = await axiosInterceptor.get(
    `${API.CALL_CENTER.GET_THREAD_COMMUNICATIONS}/${id}`,
    { signal }
  );

  return response;
};

export const RecentContactList = async (params: any) => {
  const response = await axiosInterceptor.get(
    API.CALL_CENTER.GET_RECENT_CONTACT,
    {
      params,
    }
  );

  return response;
};

export const getTranscriptAndSummary = async (data: any) => {
  const response = await axiosInterceptor.get(
    `${API.CALL_CENTER.GET_TRANSCRIPT}?recordingSid=${data.recordingSid}&type=${data.type}`,
    {
      data,
    }
  );

  return response;
};

export const getContactDetails = async (id: any, type: any, signal: any) => {
  const response = await axiosInterceptor.get(
    `${API.CALL_CENTER.GET_CONTACT_DETAILS}/${id}?type=${type}`,
    { signal }
  );

  return response;
};

export const addParticipantInConf = async (data: any) => {
  const response = await axiosInterceptor.post(
    `${window.API_URL}/twilio/add-participant`,
    data
  );

  return response;
};

export const bargeInConferenceCall = async (data: any) => {
  const response = await axiosInterceptor.post(
    `${window.API_URL}/conference/barge`,
    data
  );

  return response;
};

export const whisperInConferenceCall = async (data: any) => {
  const response = await axiosInterceptor.post(
    `${window.API_URL}/conference/whisper`,
    data
  );

  return response;
};

export const LeaveConferenceCall = async (data: any) => {
  const response = await axiosInterceptor.post(
    `${window.API_URL}/conference/leave`,
    data
  );

  return response;
};

export const ThreadSendEmail = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.CALL_CENTER.SEND_EMAIL,
    data
  );

  return response;
};

export const addBargeWhisper = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.CALL_CENTER.BARGE_WHISPER,
    data
  );

  return response;
};

export const disconnectCall = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.CALL_CENTER.DISCONNECT_CALL,
    data
  );

  return response;
};
