import React from 'react';
import { useFormContext } from 'react-hook-form';

import RenderCarrierPriceSection from './RenderCarrierPriceSection';
import RenderSalesPriceSection from './RenderSalesPriceSection';

const Step4 = () => {
  const { setValue, getValues, clearErrors } = useFormContext();

  return (
    <>
      <div className="flex">
        <div className="w-1/2 flex-1 p-4 bg-gray25">
          <RenderCarrierPriceSection
            setValue={setValue}
            clearErrors={clearErrors}
            getValues={getValues}
          />
        </div>
        <div className="w-1/2 flex-1 p-4 bg-gray25 border-l border-utilityGray200">
          <RenderSalesPriceSection
            setValue={setValue}
            clearErrors={clearErrors}
          />
        </div>
      </div>
    </>
  );
};

export default Step4;
