import {
  PresentationChart03,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CommonModal from 'src/components/CommonModal';
import CustomPagination from 'src/components/CustomPagination';
import TableCmp from 'src/components/TableCmp';
import { getLeaderBoardMyNewCustomerList } from 'src/services/SalesDashboard';

import MyNewCustomerLBLoaderRow from './MyNewCustomerLBLoaderRow';
import MyNewCustomerLBRow from './MyNewCustomerLBRow';

const initParams = {
  page: 1,
  limit: 10,
  sortType: 'asc',
  sortField: 'name',
};

interface IProps {
  leaderBoardEventDetail: any;
  handleClose: (value: boolean) => void;
}

const LeaderboardMyNewCustomers = ({
  leaderBoardEventDetail,
  handleClose,
}: IProps) => {
  const [params, setParams] = useState(initParams);
  const [isListLoading, setIsListLoading] = useState(false);
  const [customers, setCustomers] = useState([]);

  const getMyCustomerList = () => {
    setIsListLoading(true);
    getLeaderBoardMyNewCustomerList({
      ...params,
      event_id: leaderBoardEventDetail.id,
    })
      .then((result: any) => {
        setCustomers(result.data);
      })
      .finally(() => setIsListLoading(false))
      .catch(console.error);
  };

  useEffect(() => {
    getMyCustomerList();
  }, [params]);

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Customer Name',
        sortable: true,
      },
      {
        id: 'firstName',
        name: 'Sales Representative',
        sortable: true,
      },
    ],
    []
  );

  return (
    <CommonModal
      handleClose={() => (isListLoading ? false : handleClose(false))}
      headerIcon={<PresentationChart03 />}
      size={'max-w-[970px]'}
      isOverflow={true}
    >
      <div className="w-full p-5">
        <div
          className={`w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col`}
        >
          <div className="table-top-header relative">
            <div className="table-left-wrap max-smd:w-full max-sm:mb-2">
              <div className="table-title-wrap flex-wrap">
                <h5 className="table-title max-md:w-full">My New Customers</h5>
                <p className="table-subtitle mt-0 hidden max-md:block w-full">
                  List of your new customers.
                </p>
                <BadgeCmp style="modern" type="success" mainClassName="">
                  {customers.length} my new customers
                </BadgeCmp>
              </div>
              <p className="table-subtitle max-md:hidden">
                List of your new customers.
              </p>
            </div>
            <div className="gap-3 flex flex-wrap flex-none sm:w-auto w-full items-center">
              <XClose
                className="text-gray400 cursor-pointer max-sm:absolute max-sm:top-4 max-sm:right-4"
                onClick={() => (isListLoading ? false : handleClose(false))}
              />
            </div>
          </div>
          <div className="h-[calc(100vh_-_353px)] min-h-[200px] w-full flex">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
              <TableCmp
                headCells={headCells}
                params={params}
                setParams={setParams}
                tableDataArr={customers}
                TableRowCmp={MyNewCustomerLBRow}
                TableLoaderRowCmp={MyNewCustomerLBLoaderRow}
                tableHeaderClass="sticky top-0 z-[1] shadow-[inset_0_-1px_0_rgba(234,236,240,1)] [&>tr]:border-0"
                isTableDataLoading={isListLoading}
              />
            </div>
          </div>
          {customers?.length ? (
            <div className="w-full ">
              <CustomPagination
                recordsPerPage={params.limit}
                totalRecords={customers?.length}
                currentPage={params.page}
                handlePagination={(page: number) => {
                  setParams((old) => ({ ...old, page }));
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </CommonModal>
  );
};

export default LeaderboardMyNewCustomers;
