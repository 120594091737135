import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';

interface IProps {
  withHeaderFooterLoading?: boolean;
}

const CreateQuoteSectionLoading = ({ withHeaderFooterLoading }: IProps) => (
  <>
    {withHeaderFooterLoading ? (
      <div className="w-[393px] border-l border-utilityGray200 flex flex-col h-full">
        <div className="px-4 py-3.5 border-b border-utilityGray200">
          <h6 className="text-grayLight900 text-base font-medium custom-loading w-36">
            Create New Quote
          </h6>
          <p className="text-grayLight600 text-xs leading-[1.5] font-normal mt-0.5 custom-loading">
            Fill out the quote request details below to access quoting tools.
          </p>
        </div>

        <ul className="flex-1 px-4 pt-4 flex flex-col gap-y-4 overflow-y-auto custom-scrollbar-v2">
          <li>
            <h6 className="text-grayLight900 text-xs font-medium mb-1.5 custom-loading">
              Add Order Details
            </h6>
            <div className="rounded-lg border border-utilityGray200 px-4 py-5 flex flex-col gap-y-4">
              <InputText
                label="Service Type"
                labelClassName="block mb-1.5 custom-loading w-fit"
                className="custom-loading"
                isLoading
              />
              <InputText
                label="Service Type"
                labelClassName="block mb-1.5 custom-loading w-fit"
                className="custom-loading"
                isLoading
              />
              <InputText
                label="Service Type"
                labelClassName="block mb-1.5 custom-loading w-fit"
                className="custom-loading"
                isLoading
              />
              <InputText
                label="Service Type"
                labelClassName="block mb-1.5 custom-loading w-fit"
                className="custom-loading"
                isLoading
              />
            </div>
          </li>
        </ul>

        <div className="px-4 py-3 sm:sticky sm:bottom-0 bg-gray50 border-t border-utilityGray200">
          <ButtonCmp className="btn_primary w-full custom-loading">
            Create Quote
          </ButtonCmp>
        </div>
      </div>
    ) : (
      <ul className="flex-1 px-4 pt-4 flex flex-col gap-y-4 overflow-y-auto custom-scrollbar-v2">
        <li>
          <h6 className="text-grayLight900 text-xs font-medium mb-1.5 custom-loading">
            Add Order Details
          </h6>
          <div className="rounded-lg border border-utilityGray200 px-4 py-5 flex flex-col gap-y-4">
            <InputText
              label="Service Type"
              labelClassName="block mb-1.5 custom-loading w-fit"
              className="custom-loading"
              isLoading
            />
            <InputText
              label="Service Type"
              labelClassName="block mb-1.5 custom-loading w-fit"
              className="custom-loading"
              isLoading
            />
            <InputText
              label="Service Type"
              labelClassName="block mb-1.5 custom-loading w-fit"
              className="custom-loading"
              isLoading
            />
            <InputText
              label="Service Type"
              labelClassName="block mb-1.5 custom-loading w-fit"
              className="custom-loading"
              isLoading
            />
          </div>
        </li>
      </ul>
    )}
  </>
);

export default CreateQuoteSectionLoading;
