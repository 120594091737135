import React from 'react';
import { useFormContext } from 'react-hook-form';
import ErrorMsg from 'src/components/errorMsg';

import RenderCarrierPriceSection from './RenderCarrierPriceSection';
import RenderSalesPriceSection from './RenderSalesPriceSection';

const Step5 = () => {
  const {
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <h6 className="text-textSecondary text-sm font-medium mb-1 leading-[1.5]">
        Pricing
      </h6>
      <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs mb-4 last:mb-0 flex">
        <div className="w-1/2 flex-1 p-4">
          <h6 className="form_label block mb-1.5">Carrier Cost</h6>
          <RenderCarrierPriceSection
            setValue={setValue}
            clearErrors={clearErrors}
            getValues={getValues}
          />
        </div>
        <div className="w-1/2 flex-1 p-4 border-l border-utilityGray200">
          <h6 className="form_label block mb-1.5">Total Sale price</h6>
          <ul
            className={`rounded-lg border bg-white ${
              errors?.salesPrice ? 'border-red-500' : 'border-utilityGray200'
            }`}
          >
            <RenderSalesPriceSection
              setValue={setValue}
              clearErrors={clearErrors}
              getValues={getValues}
            />
          </ul>
          {errors?.salesPrice && (
            <ErrorMsg errorText={errors?.salesPrice?.message} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Step5;
