import { yupResolver } from '@hookform/resolvers/yup';
import {
  CoinsStacked02,
  CurrencyDollar,
  Percent02,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  Resolver,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import { listService } from 'src/services/CommonService';
import * as yup from 'yup';

import AdditionalCharge from '../AdditionalCharge';

const schema = yup.object().shape({
  customerId: yup.number().required('Customer is required'),
  baseSalePrice: yup
    .number()
    .required('Base sale price is required')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  fuel: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  fuelPercentage: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  totalSalePrice: yup
    .number()
    .required('Total sale price is required')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  additional_charge: yup.array().of(
    yup.object().shape({
      charge: yup
        .string()
        .nullable()
        .test(
          'charge-required-with-price',
          'Charge is required if price is provided',
          function (value) {
            const { price } = this.parent;

            return !price || (value !== null && value?.trim() !== '');
          }
        ),
      otherCharge: yup
        .string()
        .nullable()
        .test(
          'other-charge-required',
          'Other charge is required',
          function (value) {
            const { charge } = this.parent;

            return (
              charge !== 'other' || (value !== null && value?.trim() !== '')
            );
          }
        ),
      price: yup
        .number()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .test(
          'price-required-with-charge',
          'Price is required if charge is provided',
          function (value) {
            const { charge } = this.parent;

            return !charge || value !== null;
          }
        ),
    })
  ),
});

interface IProps {
  handleClose: () => void;
  orderSalesPrice: any;
  handleAddSalesPrice: any;
  masterOrderId: any;
  customerList: any;
  isCustomerLoading: any;
  customerId: any;
  additionalServices?: any;
}

interface IFormData {
  customerId: number;
  baseSalePrice: number;
  fuel: number;
  fuelPercentage: number;
  totalSalePrice: number;
  additional_charge: Array<{
    id?: number;
    charge: null | number | string;
    otherCharge: null | string;
    price: null | number;
  }>;
}

const tabArray: any = [
  {
    value: '%',
    name: '%',
  },
  {
    value: '$',
    name: '$',
  },
];

const SalesModal = ({
  handleClose,
  handleAddSalesPrice,
  orderSalesPrice,
  masterOrderId,
  customerList,
  isCustomerLoading,
  customerId,
  additionalServices,
}: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFieldsInitialized, setIsFieldsInitialized] = useState(false);
  const [activeMarginType, setActiveMarginType] = useState<any>('%');
  const [serviceList, setServiceList] = useState([]);
  const [services, setServices] = useState([]);

  const headerIcon = <CoinsStacked02 className="w-7 h-7" />;

  const methods = useForm<IFormData>({
    resolver: yupResolver(schema) as Resolver<any>,
    defaultValues: {
      customerId: undefined,
      baseSalePrice: undefined,
      fuel: undefined,
      fuelPercentage: undefined,
      totalSalePrice: undefined,
      additional_charge: [
        {
          id: 0,
          charge: null,
          otherCharge: null,
          price: null,
        },
      ],
    },
  });
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = methods;

  const { replace } = useFieldArray({
    control,
    name: 'additional_charge',
  });

  const watchAdditionalCharges: any = watch('additional_charge');
  const watchBaseSalesRate: any = watch('baseSalePrice');
  const watchFuel: any = watch('fuel');
  const watchFuelPercentage: any = watch('fuelPercentage');
  const watchTotalSalePrice: any = watch('totalSalePrice');

  useEffect(() => {
    if (orderSalesPrice) {
      const orderToEdit = orderSalesPrice;

      setValue('baseSalePrice', orderToEdit.baseSalePrice);

      if (orderToEdit?.fuelPercentage && orderToEdit.fuelPercentage > 0) {
        setValue('fuelPercentage', orderToEdit.fuelPercentage);
      }

      if (orderToEdit?.fuel && orderToEdit.fuel > 0) {
        setValue('fuel', orderToEdit.fuel);
      }

      setValue('totalSalePrice', orderToEdit.totalSalePrice);

      if (
        orderToEdit.additional_charge &&
        orderToEdit.additional_charge.length > 0
      ) {
        const transformedAdditionalCharges = orderToEdit.additional_charge.map(
          (charge: any) => ({
            id: charge.id,
            charge: charge?.isOtherCharge ? 'other' : charge.charge,
            otherCharge: charge?.isOtherCharge ? charge.charge : null,
            price: charge.price,
          })
        );

        replace(transformedAdditionalCharges);
        setIsFieldsInitialized(true);
      }
    } else {
      // setIsFieldsInitialized(true);
    }
  }, [orderSalesPrice]);

  useEffect(() => {
    if (customerList.length > 0) {
      setValue('customerId', customerId);
    }
  }, [customerList]);

  const onSubmit = (data: any) => {
    setIsLoading(true);

    const customerData = customerList?.find(
      (e: any) => e?.value === data?.customerId
    );

    const id = orderSalesPrice?.id ? orderSalesPrice?.id : null;
    const param = {
      ...data,
      id,
      masterOrderId,
      customerId,
      customer: {
        name: customerData?.label,
        image: customerData?.image,
      },
    };

    handleAddSalesPrice(param);
    handleClose();
    setIsLoading(false);
  };

  const handleTotalPrice = () => {
    const additionalPrice = watchAdditionalCharges
      .filter(
        (item: any) =>
          item.price !== null &&
          item.price !== undefined &&
          !isNaN(Number(item.price))
      )
      .reduce((acc: any, item: any) => acc + Number(item.price), 0);

    const baseSalesRate = watchBaseSalesRate || 0;
    const fuelPercentage = watchFuelPercentage || 0;

    if (
      additionalPrice !== null &&
      !isNaN(additionalPrice) &&
      baseSalesRate !== null &&
      !isNaN(baseSalesRate)
    ) {
      let totalPrice = additionalPrice + Number(baseSalesRate);

      if (fuelPercentage && fuelPercentage > 0) {
        const calculatedFuel = (totalPrice * fuelPercentage) / 100;
        setValue('fuel', parseFloat(calculatedFuel.toFixed(2)));

        totalPrice += calculatedFuel;
      }

      setValue('totalSalePrice', totalPrice);
    }
  };

  const handleTabChange = () => {
    setActiveMarginType((prevType: any) => {
      const newType = prevType === '$' ? '%' : '$';

      const additionalPrice = watchAdditionalCharges
        .filter(
          (item: any) =>
            item.price !== null &&
            item.price !== undefined &&
            !isNaN(Number(item.price))
        )
        .reduce((acc: any, item: any) => acc + Number(item.price), 0);

      const baseSalesRate = watchBaseSalesRate || 0;
      const currentFuel = Number(getValues('fuel')) || 0;
      const currentFuelPercentage = Number(getValues('fuelPercentage')) || 0;

      if (
        additionalPrice !== null &&
        !isNaN(additionalPrice) &&
        baseSalesRate !== null &&
        !isNaN(baseSalesRate)
      ) {
        let totalPrice = additionalPrice + Number(baseSalesRate);

        if (newType === '%') {
          const calculatedPercentage =
            totalPrice > 0 ? (currentFuel / totalPrice) * 100 : 0;
          setValue(
            'fuelPercentage',
            parseFloat(calculatedPercentage.toFixed(2))
          );
        } else {
          const calculatedFuel = (totalPrice * currentFuelPercentage) / 100;
          setValue('fuel', parseFloat(calculatedFuel.toFixed(2)));
        }
      }

      return newType;
    });
  };

  const getListService = () => {
    listService({})
      .then((response: any) => {
        if (response.data) {
          setServices(response.data);

          const formattedList = response.data.map((item: any) => ({
            label: item.name,
            value: item.slug,
          }));

          formattedList.push({
            label: 'Other',
            value: 'other',
          });

          setServiceList(formattedList);
        }
      })
      .finally(() => {})
      .catch(console.log);
  };

  useEffect(() => {
    getListService();
  }, []);

  useEffect(() => {
    if (!orderSalesPrice && services && services?.length > 0) {
      if (additionalServices?.length > 0) {
        const filteredServices = services.filter((service: any) =>
          additionalServices?.includes(service?.id)
        );

        const additionalCharge = filteredServices.map((service: any) => ({
          id: 0,
          charge: service.slug,
          otherCharge: null,
          price: 0,
        }));

        replace(additionalCharge);
      }

      setIsFieldsInitialized(true);
    }
  }, [serviceList]);

  const fuelInput = useMemo(
    () =>
      activeMarginType === '$' ? (
        <Controller
          name="fuel"
          control={control}
          render={({ field: { onChange } }) => (
            <InputText
              inputType="number"
              inputName="fuel"
              placeholder="Enter Fuel Value"
              parentClassName=""
              className="!border-0 !h-auto !pl-8 !pr-20"
              value={watchFuel}
              onChangeFunc={(val) => {
                onChange(val);

                if (val?.target?.value) {
                  const calculatedPercentage =
                    watchTotalSalePrice > 0
                      ? (val?.target?.value / watchTotalSalePrice) * 100
                      : 0;

                  setValue(
                    'fuelPercentage',
                    parseFloat(calculatedPercentage.toFixed(2))
                  );
                } else {
                  setValue('fuelPercentage', 0);
                }
              }}
              onBlur={handleTotalPrice}
              icon={<CurrencyDollar className="input-currency-sign" />}
              onFocus={() => {
                if (watchFuel === 0) {
                  onChange('');
                }
              }}
            />
          )}
        />
      ) : (
        <Controller
          name="fuelPercentage"
          control={control}
          render={({ field: { onChange } }) => (
            <InputText
              inputType="number"
              inputName="fuelPercentage"
              placeholder="Enter Fuel Percentage"
              parentClassName=""
              className="!border-0 !h-auto !pl-8 !pr-20"
              value={watchFuelPercentage}
              onChangeFunc={(val) => {
                onChange(val);

                if (val?.target?.value && val?.target?.value > 0) {
                  const calculatedFuel =
                    (watchTotalSalePrice * val?.target?.value) / 100;
                  setValue('fuel', parseFloat(calculatedFuel.toFixed(2)));
                } else {
                  setValue('fuel', 0);
                }
              }}
              icon={<Percent02 className="input-currency-sign" />}
              onBlur={handleTotalPrice}
              onFocus={() => {
                if (watchFuelPercentage === 0) {
                  onChange('');
                }
              }}
            />
          )}
        />
      ),
    [activeMarginType, control, handleTotalPrice]
  );

  return (
    <CommonModal
      title={
        orderSalesPrice && orderSalesPrice.length
          ? 'Edit Sale Price'
          : 'Add Sale Price'
      }
      titleDesc={
        orderSalesPrice && orderSalesPrice.length
          ? 'You can modify the existing sales price for this order.'
          : 'Attach a sales price to this order, if applicable at this time.'
      }
      handleClose={handleClose}
      headerIcon={headerIcon}
      size={'xl:max-w-[606px] md:max-w-[536px] max-w-[496px]'}
      primaryBtnText={
        orderSalesPrice && orderSalesPrice.length ? 'Save' : 'Assign'
      }
      secondaryBtnText="Cancel"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      secondaryBtnOnClick={handleClose}
    >
      <FormProvider {...methods}>
        <div className="p-5 flex flex-col gap-y-4">
          <Controller
            name="customerId"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SelectBox
                label="Customer"
                labelClassName="form_label block mb-1.5"
                name="customerId"
                id="customerId"
                size="sm"
                placeholder="All Customers"
                noOptionMessage="No Customers Found"
                isSearchable={true}
                className="form_control"
                isClearable={true}
                options={customerList}
                onChangeFunc={(e: any) => onChange(e?.value)}
                value={customerList?.find((e: any) => e?.value == value)}
                isLoading={isCustomerLoading}
                isDisabled
                errorText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="baseSalePrice"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputText
                label="Base Sale Price "
                inputName="base_sale_price"
                inputType="number"
                placeholder="Enter Base Sale Price"
                parentClassName=""
                className="pl-8"
                value={value}
                onChangeFunc={onChange}
                onBlur={handleTotalPrice}
                errorText={error ? error.message : null}
                labelClassName="block mb-1.5"
                shouldFormatNumber={true}
                icon={<CurrencyDollar className="input-currency-sign" />}
              />
            )}
          />
          {isFieldsInitialized && (
            <AdditionalCharge
              isSelectBox={true}
              serviceList={serviceList}
              onBlur={handleTotalPrice}
            />
          )}

          <div className="">
            <label className="form_label block mb-1.5">Fuel</label>
            <div className="form_control p-0 relative !h-auto">
              {fuelInput}

              <TabButton
                tabParentClassName="absolute top-1/2 -translate-y-1/2 right-1"
                parentClassName="!bg-gray50 !p-[3px]"
                className={`!rounded-md text-gray500 !border-0 !px-[8px] !py-0.5 !text-[11px] cursor-pointer first:font-normal [&>span]:text-gray500 !min-h-[auto]`}
                activeClassName=" !bg-white [&>span]:!text-grayLight900 !shadow-md "
                tabArray={tabArray}
                isActive={activeMarginType}
                isTab={true}
                handleOnClick={handleTabChange}
              />
            </div>
            {errors?.fuel?.message && (
              <ErrorMsg errorText={errors?.fuel?.message} />
            )}
          </div>

          <Controller
            name="totalSalePrice"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputText
                label="Total Sale Price"
                inputName="total_sale_price"
                inputType="number"
                placeholder="Enter Total Sale Price"
                parentClassName=""
                className="pl-8 w-full"
                value={value}
                onChangeFunc={onChange}
                errorText={error ? error.message : null}
                labelClassName="block mb-1.5"
                shouldFormatNumber={true}
                icon={<CurrencyDollar className="input-currency-sign" />}
                readOnly
              />
            )}
          />
        </div>
      </FormProvider>
    </CommonModal>
  );
};

export default SalesModal;
