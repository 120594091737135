import { Plus, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { scriptId } from 'src/app/QuotingHub/QuotingDashboard/AddressDetails/Address.interface';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { BasicContext } from 'src/context/BasicContext';
import { getWarehouseList } from 'src/services/WarehouseService';

import AddWareHouse from './AddWareHouse';
import WareHouseLoaderRow from './WareHouseLoaderRow';
import WareHouseRow from './WareHouseRow';

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 25,
};

const WareHouses = () => {
  const headCells = [
    {
      id: 'warehouseName',
      name: 'Facility Name',
      sortable: true,
      rowClassName: '',
    },
    {
      id: 'carrierName',
      name: 'Carrier',
      sortable: true,
      rowClassName: '',
    },
    {
      id: 'address1',
      name: 'Street',
      sortable: true,
      rowClassName: '',
    },
    {
      id: 'city',
      name: 'City',
      sortable: true,
      rowClassName: '',
    },
    {
      id: 'state',
      name: 'State',
      sortable: true,
      rowClassName: '',
    },
    {
      id: 'country',
      name: 'Country',
      sortable: true,
      visible: true,
      rowClassName: '',
    },
    { id: 'action', name: '', visible: true, rowClassName: '' },
  ];

  const { googleKey } = useContext(BasicContext);
  const [isRefresh, setIsRefresh] = useState(true);
  const [params, setParams] = useState(initParams);
  const [search, setSearch] = useState('');
  const [wareHouseList, setWareHouseList] = useState<any[]>([]);
  const [totals, setTotals] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAddWareHouse, setIsAddWareHouse] = useState(false);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [editWarehouseData, setEditWarehouseData] = useState(undefined);

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  useEffect(() => {
    setIsRefresh(true);
  }, [params]);

  const getWarehouseData = (signal: any) => {
    setWareHouseList([]);
    setIsLoading(true);

    getWarehouseList(params, signal)
      .then((response: any) => {
        if (response.data) {
          setWareHouseList(response.data);
          setTotals(response.total);
        }
        setIsLoading(false);
        setIsRefresh(false);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }

        setIsLoading(false);
        setIsRefresh(false);
      });
  };

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch, page: 1 } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { value } = event.target;

    setSearch(value);
    searchDebounce(value);
  };

  useEffect(() => {
    const warehouseApiController = new AbortController();
    const warehouseApiSignal = warehouseApiController.signal;

    if (isRefresh) {
      getWarehouseData(warehouseApiSignal);
    }

    return () => {
      warehouseApiController.abort();
    };
  }, [isRefresh]);

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  const handleWarehouseEdit = (warehouseObj: any) => {
    setIsAddWareHouse(true);
    setEditWarehouseData(warehouseObj);
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title={'Warehouses'}
            desc={`View and Manage Warehouses which support cross border shipments.`}
          />
        }
      >
        <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
          <div className="table-top-header">
            <div className="table-left-wrap sm:w-auto w-full">
              <div className="table-title-wrap">
                <h5 className="table-title">All Warehouses</h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={`${isLoading ? 'custom-loading' : ''}`}
                >
                  {totals} {totals > 1 ? 'Warehouses' : 'Warehouse'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                List of Warehouses which support cross border shipments.
              </p>
            </div>
            <ButtonCmp
              className={'btn-outline-primary'}
              onClick={() => {
                setIsAddWareHouse(true);
              }}
            >
              <Plus className="w-4 h-4" />
              Add Warehouses
            </ButtonCmp>
          </div>
          <div className="table-bottom-header">
            <div className="table-header-bottom-left">
              <InputText
                inputName="searchWarehouse"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={search}
                inputType="text"
                onChangeFunc={handleFilter}
                parentClassName="table-searchInput"
                isClearable={true}
              />
            </div>
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => params.limit === val.value
                )}
              />
            </div>
          </div>
          <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col ">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={headCells}
                params={params}
                setParams={setParams}
                tableDataArr={wareHouseList}
                TableRowCmp={WareHouseRow}
                TableLoaderRowCmp={WareHouseLoaderRow}
                isTableDataLoading={isLoading}
                tableRowCmpProps={{ handleWarehouseEdit }}
                numberOfSkeletonRows={15}
                tableHeaderClass=""
              />
            </div>
            {!isLoading && !wareHouseList.length && (
              <NotFoundUI
                title="No Warehouse Found"
                desc="There are no Warehouse found."
                containerClassName="!h-[unset] flex-1"
              />
            )}
          </div>
          <div className="full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={totals}
              currentPage={params.page}
              handlePagination={handlePagination}
            />
          </div>
        </div>
      </PageSectionLayout>
      {isAddWareHouse && (
        <AddWareHouse
          handleClose={(isRefreshList: boolean) => {
            if (isRefreshList) {
              setIsRefresh(true);
            }
            setIsAddWareHouse(false);
            setEditWarehouseData(undefined);
          }}
          status={status}
          isEdit={!!editWarehouseData}
          editWarehouseData={editWarehouseData}
        />
      )}
    </>
  );
};
export default WareHouses;
