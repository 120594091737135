import {
  Edit05,
  Plus,
  // Save01,
  Trash01,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import { groupBy } from 'lodash';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import DeleteModal from 'src/components/DeleteModal';
import PageSectionLayout from 'src/components/PageSectionLayout';
import TabButton from 'src/components/TabButton';
// import TrackTraceAiCmp from 'src/components/TrackTraceAiCmp';
import { MEASUREMENT } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { listHandlingUnit, listService } from 'src/services/CommonService';
import { CustomersAllList } from 'src/services/CustomerService';
import { deleteOrderLeg, getOrder } from 'src/services/OrderService';
import { getTagList } from 'src/services/TagService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';

import OrderHeader from '../Common/OrderHeader';
import OrderInfoSection from '../Common/OrderInfoSection';
import RadarAndInternalChat from '../Common/RadarAndInternalChat';
import { defualtOrderValue, processOrderData } from '../order.interface';

// import AddressDetails from './AddressDetails';
// import Appointment from './Appointment';
// import CarrierAndSalesPrice from './CarrierAndSalesPrice';
// import DocumentDetails from './DocumentDetails';
import EmailTreads from './EmailTreads';
// import LegDetails from './LegDetails';
import LegForm from './LegForm';
import OrderCostBreakDown from './OrderCostBreakDown';
// import OrderDetails from './OrderDetails';
import OrderStatus from './OrderStatus';
import AddressDetailsTab from './OrderTabs/AddressDetailsTab';
import DocumentDetailsTab from './OrderTabs/DocumentDetailsTab';
import LegOverviewTab from './OrderTabs/LegOverviewTab';
import OrderDetailsTab from './OrderTabs/OrderDetailsTab';
import PickupAndDeliveryTab from './OrderTabs/PickupAndDeliveryTab';
import PricingTab from './OrderTabs/PricingTab';
import PriceingTools from './PriceingTools';
import SplitLegDetails from './SplitLegDetails';

// eslint-disable-next-line max-lines-per-function
export const OrderContext = createContext({});

const dummyOrderLeg = [
  { value: 'leg1', name: 'Master (#Leg 1)' },
  { value: 'leg1', name: 'Master (#Leg 1)' },
  { value: 'leg1', name: 'Master (#Leg 1)' },
];

const tabs: any = [
  {
    value: 'overview',
    name: 'Overview',
    count: 0,
  },
  {
    value: 'pricingTools',
    name: 'Pricing Tools',
    count: 0,
  },
  {
    value: 'emailThreads',
    name: 'Email Threads',
    count: 0,
  },
];

const OrderView = () => {
  const { id } = useParams<{ id: any }>();
  const location = useLocation();
  const navigate = useNavigate();

  const legFormRef = useRef<any>(null);

  const [activeTab, setActiveTab] = useState('overview');

  const [order, setOrder] = useState<any>([]);
  const [tagList, setTagList] = useState<any>([]);
  const [isOrderEditTab, setIsOrderEditTab] = useState(false);
  const [orderLegIdState, setOrderLegIdState] = useState(
    location.state?.legId || null
  );

  const [activeOrderDetailTab, setActiveOrderDetailTab] =
    useState('Address Details');
  const [orderDetailTabTitle, setOrderDetailTabTitle] =
    useState('Address Details');

  const [salesPrice, setSalesPrice] = useState<any>([]);
  const [carrierPrice, setCarrierPrice] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);
  const [handlingUnitOptions, setHandlingUnitOptions] = useState<any>([]);
  const [isHandlingUnitLoading, setIsHandlingUnitLoading] =
    useState<any>(false);
  const [isTailgate, setIsTailgate] = useState<any>([]);

  const [serviceTypes, setServiceTypes] = useState<any>();
  const [serviceList, setServiceList] = useState([]);
  const [isServiceLoading, setIsServiceLoading] = useState<any>(false);

  const [isShowSplitLegModal, setIsShowSplitLegModal] = useState(false);

  const [orderLegs, setOrderLegs] = useState<any>([]);
  const [activeLeg, setActiveLeg] = useState<any>(null);
  const [currentLegData, setCurrentLegData] = useState<any>(null);
  const [tabValues, setTabValues] = useState<{ [key: string]: any }>({
    leg1: {},
  });
  const [legData, setLegData] = useState<any>([]);
  const [weightMeasurement, setWeightMeasurement] = useState(
    MEASUREMENT.WEIGHT1.value
  );
  const [lengthMeasurement, setLengthMeasurement] = useState(
    MEASUREMENT.LENGTH1.value
  );

  const [isMasterOrder, setIsMasterOrder] = useState(true);
  const [isOrderHasLeg, setIsOrderHasLeg] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [shipperFullAddress, setShipperFullAddress] = useState<any>(null);
  const [consigneeFullAddress, setConsigneeFullAddress] = useState<any>(null);
  const [isShowChatSideBar, setIsShowChatSideBar] = useState(true);
  const [isDeleteLeg, setIsDeleteLeg] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<any>(null);

  const refreshPage = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const getOrderData = () => {
    setIsLoading(true);
    const param = { id };

    getOrder(param)
      .then((response) => {
        if (response?.data) {
          const masterOrder = response?.data;

          let orderLegsData: any = [];

          if (masterOrder?.orderLegs) {
            orderLegsData = Array.isArray(masterOrder?.orderLegs)
              ? masterOrder?.orderLegs
              : JSON.parse(masterOrder?.orderLegs);
          }

          setOrder({ ...masterOrder, orderLegs: orderLegsData });

          setShipperFullAddress(masterOrder?.shipperAddress);
          setConsigneeFullAddress(masterOrder?.consigneeAddress);
          setCurrentLocation(masterOrder?.currentLocation);

          if (masterOrder?.orderLegs?.length > 1) {
            setIsOrderHasLeg(true);
            setActiveOrderDetailTab('Address Overview');
          }

          let orderLegItems = [];

          orderLegItems = [
            ...(masterOrder?.orderLegs?.length > 1
              ? [
                  {
                    value: masterOrder?.orderId,
                    name: `Master (#${masterOrder?.orderId})`,
                  },
                ]
              : []),
            ...orderLegsData.map((subOrder: any, index: number) => ({
              value: subOrder?.legId,
              name:
                !(masterOrder?.orderLegs?.length > 1) && index === 0
                  ? `Master (#${subOrder?.legId})`
                  : `Leg${index + 1} (#${subOrder?.legId})`,
            })),
          ];

          if (!location?.state?.legId) {
            setActiveLeg(masterOrder?.orderId);
            setOrderLegIdState(false);
            setIsOrderEditTab(false);
          }

          setOrderLegs(orderLegItems);

          const childOrderData = orderLegsData.reduce(
            (acc: any, subOrder: any, index: number) => {
              const resetOrderData = processOrderData(subOrder);

              if (orderLegsData?.length > 1) {
                setCurrentLegData({ ...masterOrder, orderLegs: orderLegsData });
              } else {
                if (
                  location?.state?.legId &&
                  location?.state?.legId === subOrder.legId
                ) {
                  setCurrentLegData(resetOrderData);
                  setWeightMeasurement(resetOrderData?.weightMeasurement);
                  setLengthMeasurement(resetOrderData?.lengthMeasurement);

                  setSalesPrice(subOrder?.salesPrice);
                  setCarrierPrice(resetOrderData?.carrierPrice);
                } else {
                  if (index === 0) {
                    setCurrentLegData(resetOrderData);
                    setWeightMeasurement(resetOrderData?.weightMeasurement);
                    setLengthMeasurement(resetOrderData?.lengthMeasurement);

                    setSalesPrice(subOrder?.salesPrice);
                    setCarrierPrice(resetOrderData?.carrierPrice);
                  }
                }
              }

              acc[subOrder.legId] = processOrderData(subOrder);

              return acc;
            },
            {}
          );

          const initialTabValues = {
            ...childOrderData,
          };

          setLegData(Object.values(childOrderData));
          setTabValues(initialTabValues);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          navigate(`${ROUTES.ORDERS}`);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCustomerList = () => {
    setCustomerList([]);
    setIsCustomerLoading(true);
    CustomersAllList()
      .then((response: any) => {
        if (response.data) {
          const customerSelectBoxOptions = getSelectBoxOptions(
            response.data,
            'id',
            'name',
            true
          );
          setCustomerList(customerSelectBoxOptions);
        }
        setIsCustomerLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsCustomerLoading(false);
      });
  };

  const getListService = () => {
    listService({})
      .then((response: any) => {
        setServiceList(response.data);

        if (response.data.length) {
          let serviceByGroup: any = groupBy(response.data, (val) => val.type);
          setServiceTypes(serviceByGroup);
        }
      })
      .finally(() => {
        setIsServiceLoading(false);
      })
      .catch(console.log);
  };

  const getTagListData = () => {
    getTagList({})
      .then((response) => {
        if (response.data) {
          const tagListData = response.data.map((tag: any) => ({
            value: tag.id,
            label: (
              <div className="flex items-center gap-2">
                <BadgeCmp
                  mainClassName="!border-0 !px-0"
                  type="success"
                  style="modern"
                  isHidePillDot
                  key={tag.id}
                >
                  <span className="mr-1">{tag.emoji}</span>
                  {tag.name}
                </BadgeCmp>
              </div>
            ),
          }));

          setTagList(tagListData);
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    // getOrderData();
    getCustomerList();
    getListService();
    getTagListData();
    listHandlingUnit()
      .then((result: any) => {
        if (result.data && result.data.length) {
          const handlingUnitSelectBoxOptions = getSelectBoxOptions(
            result.data,
            'id',
            'name'
          );
          setHandlingUnitOptions(handlingUnitSelectBoxOptions);
        }
      })
      .finally(() => setIsHandlingUnitLoading(false))
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (tabValues) {
      setLegData(Object.values(tabValues));
    }
  }, [tabValues]);

  useEffect(() => {
    if (location.state?.legId && orderLegs?.length > 0) {
      setOrderLegIdState(location.state.legId);
      setIsOrderEditTab(true);

      if (orderLegs.some((ord: any) => ord.value === location.state.legId)) {
        setActiveLeg(location.state.legId);
        setIsMasterOrder(false);
        setActiveOrderDetailTab('Address Details');
      } else {
        setActiveLeg(order?.orderId);
        setIsMasterOrder(true);
        setActiveOrderDetailTab('Address Overview');
      }

      setCurrentLegData(tabValues[location.state.legId]);
    } else {
      setActiveLeg(order?.orderId);
      setIsMasterOrder(true);
      setOrderLegIdState(null);
      setIsOrderEditTab(false);

      if (isOrderHasLeg) {
        setActiveOrderDetailTab('Address Overview');
      } else {
        setActiveOrderDetailTab('Address Details');
      }
    }
  }, [location.state?.legId, orderLegs]);

  useEffect(() => {
    getOrderData();
  }, [refreshKey]);

  const handleTabSwitch = async (tabValue: string) => {
    let currentTabValue = defualtOrderValue;

    if (order?.orderId === tabValue) {
      setIsMasterOrder(true);
      setActiveOrderDetailTab('Address Overview');
      currentTabValue = { ...order };
    } else {
      setIsMasterOrder(false);
      setActiveOrderDetailTab('Address Details');
      currentTabValue = tabValues[tabValue];
    }

    setActiveLeg(tabValue);
    setCurrentLegData(currentTabValue);
    setSalesPrice(currentTabValue?.salesPrice);
    setCarrierPrice(currentTabValue?.carrierPrice);
  };

  const handleTabChange = (event: any) => {
    setActiveTab(event?.target?.dataset?.value);
  };

  const handleDeleteModalClose = useCallback(
    (status: boolean) => () => {
      if (status) {
        deleteOrderLeg({
          masterOrderId: id,
          orderLegId: currentLegData?.id,
        })
          .then((response) => {
            if (response) {
              setIsDeleteLeg(false);
              refreshPage();
            }
          })
          .catch(console.error)
          .finally(() => {});
      } else {
        setIsDeleteLeg(false);
      }
    },
    [currentLegData, id]
  );

  const handleOrderDetailTabSwitch = async (e: any) => {
    const currentOrderDetailTab = e.target.dataset.value;

    setOrderDetailTabTitle(currentOrderDetailTab);
    setActiveOrderDetailTab(currentOrderDetailTab);
  };

  const TabArray = useMemo(
    () =>
      isMasterOrder && isOrderHasLeg
        ? [
            {
              value: 'Address Overview',
              name: 'Address Overview',
            },
            {
              value: 'Leg Overview',
              name: 'Leg Overview',
            },
            {
              value: 'Pickups & Dropoffs',
              name: 'Pickups & Dropoffs',
            },
            {
              value: 'Documents',
              name: 'Documents',
            },
          ]
        : [
            {
              value: 'Address Details',
              name: 'Address Details',
            },
            {
              value: 'Order Details',
              name: 'Order Details',
            },
            {
              value: 'Documents',
              name: 'Documents',
            },
            {
              value: 'Pricing',
              name: 'Pricing',
            },
          ],
    [isMasterOrder, isOrderHasLeg]
  );

  return (
    <OrderContext.Provider
      value={{
        currentLegData,
        id,
        customerList,
        isCustomerLoading,
        isMasterOrder,
        isOrderHasLeg,
        legData,
        tabValues,
        handlingUnitOptions,
        isHandlingUnitLoading,
        serviceTypes,
        serviceList,
        isServiceLoading,
        setCurrentLegData,
        setTabValues,
        isLoading,
        order,
        setOrder,
        refreshPage,
        isTailgate,
        setIsTailgate,
        salesPrice,
        setSalesPrice,
        carrierPrice,
        setCarrierPrice,
        weightMeasurement,
        lengthMeasurement,
        setWeightMeasurement,
        setLengthMeasurement,
        setIsMasterOrder,
        activeLeg,
        isOrderEditTab,
        currentLocation,
        setCurrentLocation,
        handleTabSwitch,
      }}
    >
      <PageSectionLayout
        header={
          <OrderHeader
            id={id}
            isLoading={isLoading}
            order={order}
            navigate={navigate}
            isDiscardButton={false}
            isShowActionButton={true}
            setIsShowChatSideBar={setIsShowChatSideBar}
          />
        }
        contentClassName="h-[calc(100%_-_88px)] overflow-x-hidden !pb-0 !pt-0 relative"
      >
        <div className="flex h-full w-full">
          <div className="flex-1 xl:pr-8 pb-5 overflow-auto custom-scrollbar-v2 pt-6 flex flex-col gap-y-6">
            <div>
              <TabButton
                parentClassName="!bg-gray50 !w-full "
                className="rounded-md text-gray500 !border-0 min-w-[182px] "
                tabParentClassName={`!w-full`}
                activeClassName="!bg-primary100 !text-primary700"
                tabArray={tabs}
                isActive={activeTab}
                isTab={true}
                handleOnClick={handleTabChange}
              />
            </div>

            {activeTab == tabs[0].value ? (
              <>
                <OrderInfoSection
                  order={order}
                  isLoading={isLoading}
                  currentLegData={currentLegData}
                  tagList={tagList}
                  shipperFullAddress={shipperFullAddress}
                  consigneeFullAddress={consigneeFullAddress}
                  isOrderView={true}
                  currentLocation={currentLocation}
                  isShowChatSideBar={isShowChatSideBar}
                />
                <OrderStatus />

                {/* BELOW COMPONENT ALSO USED IN ORDER DETAIL */}
                {/* <TrackTraceAiCmp /> */}
                <div>
                  <div className="flex items-center flex-wrap gap-x-2 mb-1.5">
                    <div className="flex-1">
                      <h6 className="text-grayLight900 text-base font-semibold">
                        Order Details
                      </h6>
                    </div>
                    <div className="flex flex-wrap gap-4">
                      {!isOrderEditTab && (
                        <ButtonCmp
                          className={`btn-outline-primary border-none !shadow-none !p-0 !bg-transparent ${
                            isLoading ? 'custom-loading' : ''
                          }`}
                          icon={<Plus className="w-4 h-4" />}
                          onClick={() => setIsShowSplitLegModal(true)}
                          //   disabled
                        >
                          Add Stop
                        </ButtonCmp>
                      )}

                      {!isMasterOrder && isOrderHasLeg && (
                        <ButtonCmp
                          className="btn-outline-primary border-none !shadow-none !p-0"
                          icon={
                            isOrderEditTab ? (
                              <XClose className="w-4 h-4" />
                            ) : (
                              <Edit05 className="w-4 h-4" />
                            )
                          }
                          onClick={() => {
                            if (isOrderEditTab) {
                              const savedStep = localStorage.getItem(
                                'orderLegFormCurrentStep'
                              );

                              if (savedStep) {
                                localStorage.removeItem(
                                  'orderLegFormCurrentStep'
                                );
                              }

                              navigate(`${PATH.ORDER_VIEW}/${order?.id}`, {
                                replace: true,
                              });
                            } else {
                              navigate(`${PATH.ORDER_VIEW}/${order?.id}`, {
                                state: { legId: currentLegData?.legId },
                              });
                            }
                          }}
                        >
                          {!isOrderEditTab ? 'Edit' : 'Close'}
                        </ButtonCmp>
                      )}

                      {/* {!isMasterOrder && isOrderHasLeg && orderLegIdState && (
                        <ButtonCmp
                          className={`btn-outline-primary border-none !shadow-none !p-0 ${
                            isLoading ? 'custom-loading' : ''
                          }`}
                          icon={<Save01 className="w-4 h-4" />}
                        >
                          Save
                        </ButtonCmp>
                      )} */}

                      {!isOrderEditTab && !isMasterOrder && isOrderHasLeg && (
                        <ButtonCmp
                          className={`btn-outline-primary border-none !shadow-none !p-0 ${
                            isLoading ? 'custom-loading' : ''
                          }`}
                          icon={<Trash01 className="w-4 h-4" />}
                          onClick={() => setIsDeleteLeg(true)}
                        >
                          Delete
                        </ButtonCmp>
                      )}
                    </div>
                  </div>
                  <TabButton
                    parentClassName="!bg-gray50 !w-full "
                    className={`!rounded-md !border-0 [&>span]:text-gray400 min-w-[200px] ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                    activeClassName={`!bg-white [&>span]:!text-textSecondary [&>span]:!font-semibold !shadow-md  ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                    tabArray={isLoading ? dummyOrderLeg : orderLegs}
                    tabParentClassName=""
                    handleOnClick={(e: any) => {
                      // if (!orderLegIdState) {
                      const tabValue = e.target.dataset.value;
                      handleTabSwitch(tabValue);

                      if (orderLegIdState && orderLegIdState === tabValue) {
                        setIsOrderEditTab(true);
                      } else {
                        if (isOrderEditTab) {
                          setIsOrderEditTab(false);
                        }
                      }

                      // }
                    }}
                    isActive={activeLeg}
                    isTab={true}
                  />
                </div>

                {isOrderEditTab ? (
                  <div className="flex flex-col gap-y-6">
                    <LegForm ref={legFormRef} />
                  </div>
                ) : (
                  <>
                    <div className="rounded-xl border border-utilityGray200 shadow-xs flex-none overflow-hidden">
                      <div className="p-4 border-b border-utilityGray200 flex items-center gap-x-2 justify-between">
                        <h6 className="text-grayLight900 text-base font-medium">
                          {orderDetailTabTitle}
                        </h6>
                        <TabButton
                          tabArray={TabArray}
                          isActive={activeOrderDetailTab}
                          handleOnClick={handleOrderDetailTabSwitch}
                          isTab={true}
                          tabParentClassName={`${
                            isLoading && 'custom-loading'
                          }`}
                        />
                      </div>

                      {activeOrderDetailTab === 'Address Details' && (
                        <AddressDetailsTab />
                      )}

                      {activeOrderDetailTab === 'Order Details' && (
                        <OrderDetailsTab />
                      )}

                      {activeOrderDetailTab === 'Documents' && (
                        <DocumentDetailsTab />
                      )}

                      {activeOrderDetailTab === 'Pricing' && <PricingTab />}

                      {activeOrderDetailTab === 'Address Overview' && (
                        <AddressDetailsTab />
                      )}

                      {activeOrderDetailTab === 'Leg Overview' && (
                        <LegOverviewTab />
                      )}

                      {activeOrderDetailTab === 'Pickups & Dropoffs' && (
                        <PickupAndDeliveryTab type={'pickup'} />
                      )}
                    </div>

                    {/* <ul className="flex flex-col gap-y-6">
                      {((!isMasterOrder && isOrderHasLeg) ||
                        (isMasterOrder && !isOrderHasLeg)) && (
                        <AddressDetails />
                      )}

                      {isMasterOrder && isOrderHasLeg && <LegDetails />}

                      <OrderDetails />

                      {((!isMasterOrder && isOrderHasLeg) ||
                        (isMasterOrder && !isOrderHasLeg)) && (
                        <CarrierAndSalesPrice />
                      )}

                      <DocumentDetails />

                      {isMasterOrder && <OrderCostBreakDown />}
                    </ul> */}

                    <ul className="flex flex-col gap-y-6">
                      {isMasterOrder && <OrderCostBreakDown />}
                    </ul>
                  </>
                )}
              </>
            ) : activeTab == tabs[1].value ? (
              <PriceingTools />
            ) : activeTab == tabs[2].value ? (
              <EmailTreads />
            ) : null}
          </div>
          <RadarAndInternalChat
            order={order}
            shipperFullAddress={shipperFullAddress}
            consigneeFullAddress={consigneeFullAddress}
            isOrderView={true}
            currentLocation={currentLocation}
            isShowChatSideBar={isShowChatSideBar}
            setIsShowChatSideBar={setIsShowChatSideBar}
          />
        </div>

        <SplitLegDetails
          isShowSplitLegModal={isShowSplitLegModal}
          setIsShowSplitLegModal={setIsShowSplitLegModal}
        />

        {isDeleteLeg && (
          <DeleteModal
            moduleTitle={'Delete Order Leg'}
            moduleTitleDesc={`Are you sure you want to delete this order leg?`}
            handleClose={handleDeleteModalClose}
            dangerBtnText={'Delete'}
          />
        )}
      </PageSectionLayout>
    </OrderContext.Provider>
  );
};

export default OrderView;
