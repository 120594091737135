import {
  CurrencyDollar,
  Plus,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';

const AdditionalCharge = ({ isSelectBox = true, serviceList, onBlur }: any) => {
  const {
    control,
    watch,
    // formState: { errors },
  } = useFormContext();

  const {
    fields: informationFields,
    append: appendOption,
    remove: removeOption,
  } = useFieldArray({
    name: 'additional_charge',
    keyName: 'uuid',
  });

  return (
    <>
      <div>
        <div className="flex flex-col gap-3">
          {informationFields?.map((item: any, index: any) => {
            const selectedCharge =
              watch(`additional_charge.${index}.charge`) || '';
            const otherSelectedCharge =
              watch(`additional_charge.${index}.otherCharge`) || '';

            return (
              <div className="flex w-full gap-x-2" key={item.uuid}>
                {isSelectBox && selectedCharge !== 'other' ? (
                  <Controller
                    name={`additional_charge.${index}.charge`}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <SelectBox
                        label={`${index === 0 ? 'Additional Charges' : ''}`}
                        labelClassName="form_label block mb-1.5"
                        name={`additional_charge.${index}.charge`}
                        id={`additional_charge-${index}-charge`}
                        size="sm"
                        placeholder="Select Additional Charge"
                        noOptionMessage="No Additional Charge Found"
                        isSearchable={true}
                        className="form_control"
                        parentClassName="flex-1"
                        isClearable={true}
                        options={serviceList}
                        onChangeFunc={(e: any) => {
                          onChange(e?.value || '');
                        }}
                        value={serviceList?.find(
                          (e: any) => e?.value === value
                        )}
                        errorText={error ? error.message : null}
                      />
                    )}
                  />
                ) : (
                  <Controller
                    name={`additional_charge.${index}.otherCharge`}
                    control={control}
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <InputText
                        label={`${index === 0 ? 'Additional Charges' : ''}`}
                        inputName={`additional_charge.${index}.otherCharge`}
                        placeholder="Enter Additional Charge"
                        className="w-full"
                        value={otherSelectedCharge}
                        onChangeFunc={(e) => {
                          onChange(e);
                        }}
                        errorText={error ? error.message : null}
                        labelClassName="block mb-1.5"
                        parentClassName="flex-1"
                        isClearable
                      />
                    )}
                  />
                )}

                <Controller
                  name={`additional_charge.${index}.price`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputText
                      label={`${index === 0 ? 'Price' : ''}`}
                      inputName={`additional_charge.${index}.price`}
                      placeholder="Enter Price"
                      inputType="number"
                      className="pl-8 w-full"
                      value={value || ''}
                      onChangeFunc={(e) => {
                        let newValue = e.target.value;

                        if (newValue?.length > 1) {
                          newValue = newValue.replace(/^0+/, '');
                        }

                        newValue = newValue === '' ? null : newValue;

                        onChange(newValue);
                      }}
                      onBlur={onBlur}
                      errorText={error ? error.message : null}
                      labelClassName="block mb-1.5"
                      parentClassName="flex-1"
                      icon={<CurrencyDollar className="input-currency-sign" />}
                      shouldFormatNumber={true}
                    />
                  )}
                />

                {informationFields.length > 1 && (
                  <div
                    className={`lg:h-9 h-[34px] flex items-center ${
                      index === 0 ? 'mt-[21px]' : ''
                    }`}
                  >
                    <div
                      className="p-[5px] bg-primary100 rounded-[4px] flex justify-center items-center text-primary cursor-pointer"
                      onClick={() => removeOption(index)}
                    >
                      <Trash01 className="w-4 h-4" />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div
          className="inline-flex items-center text-primary gap-x-1 text-xs font-medium cursor-pointer mt-2"
          onClick={() =>
            appendOption({
              id: 0,
              charge: '',
              otherCharge: '',
              price: null,
            })
          }
        >
          <Plus className="w-4 h-4" />
          Add line Item
        </div>
      </div>
    </>
  );
};

export default AdditionalCharge;
