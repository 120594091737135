import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import NotFoundUI from 'src/components/NotFoundUI';
// import TabButton from 'src/components/TabButton';

const transformedArray = (data: any, dataType = 'values') => {
  const transformedArr = data[dataType].map((value: any) => {
    if (dataType === 'values') {
      const percentage =
        data.totalValue > 0 ? (value / data.totalValue) * 100 : 0;

      return percentage;
    } else if (dataType === 'labels') {
      return value;
    }
  });

  return transformedArr?.length === 5 ? [...transformedArr] : transformedArr;
};

// const requestTabArray: any[] = [
//   {
//     name: 'Quotes',
//     value: 'quotes',
//     count: 0,
//   },
//   {
//     name: 'Quote Requests',
//     value: 'quoteRequests',
//     count: 0,
//   },
// ];

interface IProps {
  isLoading: boolean;
  data: {
    requestQuotePieChartData: any;
    totalQuotePieChartData: any;
  };
}

const QuoteRequestsGraph = ({ data, isLoading }: IProps) => {
  const [requestTableTabValue, setRequestTableTabValue] = useState('quotes');
  console.log(
    '⭐️ ~ QuoteRequestsGraph ~ setRequestTableTabValue:',
    setRequestTableTabValue
  );
  const allValuesAreZero = (arr: number[]) => arr.every((value) => value === 0);

  // Determine which data set to use based on the active tab
  const currentData =
    requestTableTabValue === 'quotes'
      ? transformedArray(data.requestQuotePieChartData)
      : transformedArray(data.totalQuotePieChartData);

  // Check if all values in the current dataset are 0
  const isAllZero = allValuesAreZero(currentData);

  const options: ApexOptions = {
    chart: {
      width: '100%',
      height: '100%',
      type: 'donut',
    },
    labels:
      requestTableTabValue === 'quotes'
        ? transformedArray(data.requestQuotePieChartData, 'labels')
        : transformedArray(data.totalQuotePieChartData, 'labels'),
    theme: {
      monochrome: { enabled: false },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 100,
        },
        donut: {
          size: '50%',
        },
      },
    },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    stroke: {
      show: false, // Removes the white border
    },
    dataLabels: {
      enabled: false,
    },
    colors:
      requestTableTabValue === 'quotes'
        ? ['#444CE6', '#6273F2', '#8098F9', '#A4BCFD', '#EBECF0']
        : ['#3538CD', '#444CE6', '#6172F3', '#8098F9', '#A4BCFD', '#EBECF0'],

    legend: {
      show: true,
      labels: {
        colors:
          requestTableTabValue === 'quotes'
            ? ['#3538CD']
            : ['#444CE7', '#6172F3', '#8098F9', '#A4BCFD', '#EAECF0'],
      },
      formatter: function (val: string, opts: any): string {
        return `${val}<br/><b>${
          data.requestQuotePieChartData.values[opts.seriesIndex]
        }<b>`;
      },
      markers: {
        width: 12,
        height: 12,
        radius: 12,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    tooltip: {
      enabled: true,
      theme: 'Light',
      y: {
        formatter: (value) => `${value.toFixed(2)}%`, // Format the value in the tooltip
      },
    },
  };

  const initialSeries = [20, 20, 20, 20, 20];

  return (
    <div className="w-full h-auto flex flex-col justify-between xxl:w-[30%] xlm:w-[32%] xl:w-[27%] xxl:gap-3 gap-3 min-w-[370px]">
      <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col flex-1 xl:h-[] xl:min-h-[] xl:max-h-[]">
        {/* <div className="table-top-header ">
          <div className="table-left-wrap ">
            <div className="table-title-wrap">
              <h5 className="table-title max-xlm:text-sm">
                Quote Distribution
              </h5>
            </div>
          </div>
          <TabButton
            className="table-tab max-xxl:!px-3"
            activeClassName="!bg-utilityGray100"
            tabArray={requestTabArray}
            parentClassName="table-tabs"
            isActive={requestTableTabValue}
            handleOnClick={(e: any) => {
              setRequestTableTabValue(e?.target?.dataset?.value);
            }}
          />
        </div> */}
        <div className="w-full flex-1 flex flex-col ">
          {isLoading || isAllZero ? (
            <NotFoundUI
              iconClassName="hidden"
              title="No Quote Distribution found"
              desc="There are no Quote Distribution."
              containerClassName="!h-auto flex-1"
            />
          ) : (
            <div className="xxl:px-6 px-2.5 xxl:pt-8 pt-6 xxl:pb-4 pb-1 analytics_tooltip flex flex-col flex-1 analytics-chart relative">
              <h5 className="absolute top-3 left-3 text-sm">
                Quote Distribution
              </h5>
              <ReactApexChart
                options={options}
                series={
                  isLoading
                    ? initialSeries
                    : requestTableTabValue === 'quotes'
                    ? transformedArray(data.requestQuotePieChartData)
                    : transformedArray(data.totalQuotePieChartData)
                }
                type="donut"
                height="100%"
                className="-translate-x-1/2 -translate-y-1/2 relative top-1/2 left-1/2 !min-h-[unset] flex-1"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuoteRequestsGraph;
