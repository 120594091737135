import { Check } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const RadarOrderStep = ({
  step,
  status,
  progressValue,
  iconParentClass = '',
  time,
  stepClass = '',
  isLoading = false,
}: {
  step: string;
  status: string;
  progressValue: number;
  iconParentClass?: string;
  time: string;
  stepClass?: string;
  isLoading: boolean;
}) => (
  <li className="flex-1">
    <h6
      className={`text-xs font-semibold leading-[1.5] uppercase mb-1.5 ${stepClass} ${
        progressValue === 100 ? 'text-grayLight900' : 'text-grayLight600'
      } ${isLoading ? 'custom-loading' : ''}`}
    >
      {step}
    </h6>
    <div
      className={`flex items-center gap-x-1 text-grayLight600 text-xs font-normal mb-2.5`}
    >
      {progressValue === 100 ? (
        <div
          className={`rounded-xl  bg-fgSuccessPrimary w-3.5 h-3.5 flex items-center justify-center ${iconParentClass} ${
            isLoading ? 'custom-loading' : ''
          }`}
        >
          <Check className="w-2.5 h-2.5 text-white" />
        </div>
      ) : (
        <div
          className={`rounded-xl border-2 border-utilityGray200 w-3.5 h-3.5 flex items-center justify-center ${
            isLoading ? 'custom-loading' : ''
          }`}
        ></div>
      )}
      <span className={`${isLoading ? 'custom-loading' : ''}`}>{status}</span>
    </div>
    <p
      className={`text-gray500 text-xs font-normal mb-1.5 ${
        isLoading ? 'custom-loading' : ''
      }`}
    >
      {time}
    </p>
    <progress
      className={`progress max-w-full h-[5px] flex bg-utilityGray200 progress-success ${
        isLoading ? 'custom-loading' : ''
      }`}
      value={progressValue}
      max="100"
    ></progress>
  </li>
);

export default RadarOrderStep;
