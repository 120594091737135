import {
  ChevronDown,
  ChevronLeftDouble,
  ChevronUp,
  SearchLg,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import { customFromNowWithAgo, getShortName } from 'src/utils/CommonFunctions';

import QuoteRequestTypeBadgeSelectBox from '../QuoteRequestTypeBadgeSelectBox';

import MessageListCloseLoading from './MessageListCloseLoading';
import MessageListLoading from './MessageListLoading';

interface IProps {
  isLoadingMore: boolean;
  loading: boolean;
  emailData: any[];
  selectedEmail: any;
  scrollContainerRef: any;
  userEmail: string;
  setParams: any;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  totalUnReadEmails: number;
  handleSelectedEmail: (emailDetails: any) => void;
  handleDismissEmail: (item: any) => Promise<void>;
}

const MessageListSection = ({
  isLoadingMore,
  loading,
  emailData,
  selectedEmail,
  scrollContainerRef,
  userEmail,
  setParams,
  search,
  setSearch,
  totalUnReadEmails,
  handleSelectedEmail,
  handleDismissEmail,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(window.innerWidth > 1549);
  const [openThreads, setOpenThreads] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [key, setKey] = useState(Date.now());

  useEffect(() => {
    const i = setInterval(() => {
      setKey(Date.now());
    }, 1000);

    return () => clearInterval(i);
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleThread = (emailId: string, e: React.MouseEvent) => {
    e.stopPropagation();

    // Opening single thread
    setOpenThreads((prev) => ({
      [emailId]: !prev[emailId],
    }));
  };

  const searchDebounce = useCallback(
    debounce((debSearch: string) => {
      setParams((old: any) => ({ ...old, ...{ page: 1, search: debSearch } }));
    }, 700),
    []
  );

  const handleFilter = (event: any) => {
    const { value } = event.target;
    setSearch(value);
    searchDebounce(value);
  };

  return (
    <>
      <div
        className={` border-r border-utilityGray200 flex-col justify-start items-start flex transition-all duration-[0.5s] max-xlm:absolute max-xlm:bg-white max-xlm:z-[1] max-sm:w-full h-full ${
          isOpen ? 'xlm:w-[23%] sm:w-[320px]' : 'w-[71px]'
        }`}
      >
        <div
          className={`px-3 py-3.5 border-b border-utilityGray200 w-full items-center flex `}
        >
          <div
            className={`flex items-center transition-all duration-[0.5s]  ${
              isOpen
                ? 'sm:w-[calc(100%_-_36px)] w-full'
                : 'w-0 invisible opacity-0'
            }`}
          >
            <div className={`flex flex-col mr-2 ml-1 truncate flex-1`}>
              <div className={`flex gap-2 items-center`}>
                <h5 className={`text-grayLight900 text-base font-semibold`}>
                  Inbox
                </h5>
                <BadgeCmp
                  type="success"
                  style="modern"
                  mainClassName={`${
                    loading && !isLoadingMore ? 'custom-loading' : ''
                  } `}
                >
                  {totalUnReadEmails}
                  {' Unread'}
                </BadgeCmp>
              </div>
              <p
                className={`text-grayLight600 text-xs leading-[1.5] font-normal mt-0.5 ${
                  loading && !isLoadingMore ? 'custom-loading' : ''
                }`}
              >
                {loading && !isLoadingMore
                  ? 'jdtestmail@loading.com'
                  : userEmail}
              </p>
            </div>
          </div>
          <ButtonCmp
            className="btn_secondary_black lg:px-[9px] px-2 max-sm:hidden"
            onClick={toggleSidebar}
            icon={
              <ChevronLeftDouble
                className={`w-4 h-4 ${isOpen ? '' : 'rotate-180'}`}
              />
            }
          >
            <></>
          </ButtonCmp>
        </div>

        <div
          onClick={() => !isOpen && toggleSidebar()}
          className={`w-full border-b border-utilityGray200 ${
            !isOpen ? 'cursor-pointer' : ''
          }`}
        >
          <InputText
            inputName="search"
            placeholder="Search"
            className={`cursor-pointer bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input ${
              isOpen ? '' : '!pr-0 text-[0] w-[36px]'
            }`}
            icon={
              <SearchLg className="cursor-pointer absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
            }
            value={search}
            isClearable
            inputType="text"
            parentClassName="w-full p-3"
            onChangeFunc={handleFilter}
            inputWrapClass={`!justify-start [&>svg:nth-child(3)]:transition-all [&>svg:nth-child(3)]:duration-[0.5s] ${
              isOpen ? '' : '[&>svg:nth-child(3)]:opacity-0'
            }`}
          />
        </div>

        <div
          className={`w-full flex-1 overflow-auto custom-scrollbar-v3 overflow-x-hidden ${
            isOpen ? '' : 'scrollbar-hide'
          }`}
          ref={scrollContainerRef}
        >
          {loading && !isLoadingMore
            ? false
            : emailData?.map((item: any, index: number) => (
                <div
                  key={index}
                  className={`[&>div]:border-b [&>div]:border-utilityGray200 `}
                >
                  <>
                    <div
                      className={` flex items-center cursor-pointer relative ${
                        isOpen ? 'py-4 pr-4 pl-2' : 'py-2 px-4'
                      } ${
                        item?.emailBody?.id === selectedEmail?.emailBody?.id ||
                        openThreads[item.id] === true
                          ? 'bg-utilityGray100'
                          : ''
                      }`}
                      key={index}
                      onClick={() => {
                        setOpenThreads({});
                        handleSelectedEmail(item);
                      }}
                    >
                      <div
                        key={index}
                        className={` h-9 rounded-full bg-utilityGray100 text-primary text-sm flex items-center justify-center uppercase flex-none relative shadow-lg ${
                          isOpen
                            ? 'w-0 invisible text-[0] '
                            : 'w-9 border-gray-200 border '
                        }`}
                      >
                        {getShortName(
                          `${item?.emailBody?.from?.emailAddress?.name}`
                        )}
                        <p
                          className={` ${
                            item?.isRead
                              ? ''
                              : 'w-1.5 h-1.5 bg-primary500 rounded-full -right-[0px] top-0.5 absolute'
                          }`}
                        ></p>
                      </div>
                      <p
                        className={`w-1.5 h-1.5 mr-1.5 mt-1.5 self-start ${
                          isOpen ? '' : 'w-0 invisible text-[0]'
                        } ${
                          item?.isRead
                            ? ''
                            : 'bg-primary500 rounded-full flex-none'
                        }`}
                      ></p>
                      <div
                        className={`flex-1 transition-all duration-[0.5s] ${
                          isOpen ? ' ' : 'text-[0] opacity-0 invisible'
                        } `}
                      >
                        <div className="flex items-center gap-x-1 pr-8">
                          <h6
                            className={`text-sm text-grayLight900 line-clamp-1 ${
                              item?.isRead ? 'font-normal' : 'font-semibold'
                            }`}
                          >
                            {item?.emailBody?.from?.emailAddress?.name}
                          </h6>
                          {/* EMAIL THREAD COUNT */}
                          {item?.thread?.length > 1 && (
                            <div
                              className="flex items-center gap-x-0.5 px-1 rounded border border-utilityGray200 bg-white text-[10px] leading-[1.6]"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSelectedEmail(item);
                                toggleThread(item.id, e);
                              }}
                            >
                              {item?.thread?.length}
                              {openThreads[item.id] ? (
                                <ChevronUp className="w-3 h-3" />
                              ) : (
                                <ChevronDown className="w-3 h-3" />
                              )}
                            </div>
                          )}
                          <QuoteRequestTypeBadgeSelectBox
                            loading={loading}
                            isLoadingMore={isLoadingMore}
                          />
                        </div>
                        <div className="flex justify-between mt-1">
                          <p
                            className={`text-xs leading-[18px] text-gray500 font-normal line-clamp-1 `}
                          >
                            {item?.emailBody?.subject
                              ? item.emailBody.subject
                              : '-'}
                          </p>
                          <div
                            className={`flex-none text-xs text-grayLight900 ml-2.5 transition-all duration-[0.5s] 
                            ${isOpen ? '' : 'text-[0] opacity-0 invisible'} 
                            ${item?.isRead ? 'font-normal' : 'font-semibold'}
                          `}
                            key={`main_mail_list_section_time_badge_${key}`}
                          >
                            {customFromNowWithAgo(item?.receivedDateTime)}
                          </div>
                        </div>
                      </div>
                      <ButtonCmp
                        className={`btn_secondary_black !p-1 !border rounded-[5px] !bg-transparent absolute top-2.5 right-3 !shadow-none hover:!text-grayLight900 ${
                          isOpen ? '' : 'text-[0] opacity-0 invisible'
                        }`}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setOpenThreads({});
                          handleDismissEmail(item?.messageId);
                        }}
                      >
                        <XClose className="w-3 h-3 " />
                      </ButtonCmp>
                    </div>
                    {/* THREAD EMAIL START */}
                    {item?.thread?.length > 1 && openThreads[item.id] && (
                      <div
                        className={` ${openThreads[item.id] ? '' : 'hidden'}`}
                      >
                        {item?.thread?.map(
                          (threadItem: any, threadIndex: number) => (
                            <div
                              key={threadIndex}
                              className={`last:border-b-0 border-b border-utilityGray200 ${
                                isOpen ? 'px-3' : ''
                              } ${
                                threadItem?.emailBody?.id ===
                                selectedEmail?.emailBody?.id
                                  ? 'bg-utilityGray200 bg-opacity-40'
                                  : ''
                              }`}
                            >
                              <div
                                className={` flex items-center cursor-pointer ${
                                  isOpen ? 'py-4 px-3' : 'py-2 px-4 '
                                } `}
                                onClick={() => handleSelectedEmail(threadItem)}
                              >
                                <div
                                  className={`h-7 rounded-full bg-utilityGray100 text-primary text-xs flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none relative shadow-lg ${
                                    isOpen
                                      ? 'w-0 invisible text-[0] '
                                      : 'w-7 border-gray-200 border mx-1'
                                  } `}
                                >
                                  {getShortName(
                                    `${threadItem?.emailBody?.from?.emailAddress?.name}`
                                  )}
                                  <p
                                    className={` ${
                                      threadItem?.isRead
                                        ? ''
                                        : 'w-1 h-1 bg-primary500 rounded-full -right-[0px] top-0.5 absolute'
                                    }`}
                                  ></p>
                                </div>

                                <p
                                  className={`w-1.5 h-1.5 mr-1 mt-1 ${
                                    isOpen ? '' : 'w-0 invisible text-[0]'
                                  } ${
                                    threadItem?.isRead
                                      ? ''
                                      : 'bg-primary500 rounded-full flex-none self-start'
                                  }`}
                                ></p>

                                <div
                                  className={`flex-1 transition-all duration-[0.5s] ${
                                    isOpen
                                      ? ' '
                                      : 'text-[0] opacity-0 invisible'
                                  } `}
                                >
                                  <h6
                                    className={`text-xs text-grayLight600 line-clamp-1 ${
                                      threadItem?.isRead
                                        ? 'font-medium'
                                        : 'font-semibold'
                                    }`}
                                  >
                                    {
                                      threadItem?.emailBody?.from?.emailAddress
                                        ?.name
                                    }
                                  </h6>
                                  <p
                                    className={`text-xs leading-[18px] text-gray500 font-normal line-clamp-1 `}
                                  >
                                    {`${threadItem?.emailBody?.bodyPreview?.substring(
                                      0,
                                      40
                                    )} ...`}
                                  </p>
                                </div>
                                <div
                                  className={`text-[11px] leading-[1] text-grayLight600 ml-1.5 transition-all duration-[0.5s] ${
                                    isOpen
                                      ? ' '
                                      : 'text-[0] opacity-0 invisible'
                                  }  ${
                                    threadItem?.isRead
                                      ? 'font-normal'
                                      : 'font-semibold'
                                  }`}
                                  key={`thread_mail_list_section_time_badge_${threadIndex}_${key}`}
                                >
                                  {customFromNowWithAgo(
                                    threadItem?.receivedDateTime
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                    {/* THREAD EMAIL END */}
                  </>
                </div>
              ))}

          {(loading || isLoadingMore) && isOpen && (
            <MessageListLoading skeletonCount={isLoadingMore ? 4 : 9} />
          )}

          {(loading || isLoadingMore) && !isOpen && (
            <MessageListCloseLoading skeletonCount={isLoadingMore ? 4 : 9} />
          )}
        </div>
      </div>
    </>
  );
};

export default MessageListSection;
