import React from 'react';

const formatString = (str: string) =>
  str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

const formatData = (data: any) => {
  const formattedData = [];

  if (data.baseSalePrice) {
    formattedData.push({
      name: 'Base Sale Price',
      cad: data.baseSalePrice || 0,
      usd: data.baseSalePriceUSD || 0,
    });
  }

  if (data.baseCarrierRate) {
    formattedData.push({
      name: 'Base Carrier Price',
      cad: data.baseCarrierRate || 0,
      usd: data.baseCarrierRateUSD || 0,
    });
  }

  if (data.fuel) {
    formattedData.push({
      name: 'Fuel',
      cad: data.fuel || 0,
      usd: data.fuelUSD || 0,
    });
  }

  if (data.additional_charge && Array.isArray(data.additional_charge)) {
    data.additional_charge.forEach((charge: any) => {
      if (charge.charge) {
        formattedData.push({
          name: formatString(charge.charge),
          cad: charge.price || 0,
          usd: charge.priceUSD || 0,
        });
      }
    });
  }

  if (data.totalSalePrice) {
    formattedData.push({
      name: 'Total Sales Price',
      cad: data.totalSalePrice || 0,
      usd: data.totalSalePriceUSD || 0,
      isHaveClass: true,
    });
  }

  if (data.totalPrice) {
    formattedData.push({
      name: 'Total Cost',
      cad: data.totalPrice || 0,
      usd: data.totalPriceUSD || 0,
      isHaveClass: true,
    });
  }

  return formattedData;
};

const SalesPriceCostBreakDownSubRow = ({
  data,
  activeCurrencyTab,
  totalCarrierPrice,
  totalCarrierPriceUSDForMargin,
}: any) => {
  const formattedData = formatData(data);

  return (
    <>
      {formattedData.map((item, index) => (
        <tr
          className="text-grayLight600 border-b border-utilityGray200 font-normal text-xs bg-gray25 transition-all duration-[0.5s]"
          key={index}
        >
          <td className="px-4 py-3 w-64 min-w-64 max-w-[1px]">
            <div className="flex items-center gap-3">
              <div className="table-profile-user-circle-sm invisible">WA</div>
              <h6
                className={`truncate ${
                  item.isHaveClass ? 'text-grayLight900 font-medium' : ''
                }`}
              >
                {item.name}
              </h6>
            </div>
          </td>
          <td className="px-4 py-3">
            <span
              className={`${
                item.isHaveClass
                  ? totalCarrierPriceUSDForMargin > item.usd
                    ? 'text-fgErrorPrimary font-semibold'
                    : 'text-fgSuccessPrimary font-semibold'
                  : ''
              }`}
            >
              ${item.cad} CAD
            </span>
          </td>
          {activeCurrencyTab === 'CAD' ? (
            <td className="px-4 py-3">
              <span
                className={`${
                  item.isHaveClass
                    ? `${
                        totalCarrierPrice > item.cad
                          ? 'text-fgErrorPrimary font-semibold'
                          : 'text-fgSuccessPrimary font-semibold'
                      }`
                    : ''
                }`}
              >
                ${item.cad} {item.isHaveClass ? activeCurrencyTab : ''}
              </span>
            </td>
          ) : (
            <td className="px-4 py-3">
              <span
                className={`${
                  item.isHaveClass
                    ? `${
                        totalCarrierPriceUSDForMargin > item.usd
                          ? 'text-fgErrorPrimary font-semibold'
                          : 'text-fgSuccessPrimary font-semibold'
                      }`
                    : ''
                }`}
              >
                ${item.usd} {item.isHaveClass ? activeCurrencyTab : ''}
              </span>
            </td>
          )}
          <td className="px-4 py-3"></td>
        </tr>
      ))}
    </>
  );
};

export default SalesPriceCostBreakDownSubRow;
