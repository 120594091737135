import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

const Overview = () => (
  <div className="px-6">
    <h3 className="text-sm font-medium mb-4">Todays Overview</h3>
    <div className="grid grid-cols-5 mb-6">
      <div className="text-center">
        <div className="text-gray-500 text-xs">Completed Tasks</div>
        <div className="text-gray-900 font-semibold mb-1">500</div>
      </div>
      <div className="text-center border-l border-gray-300">
        <div className="text-gray-500 text-xs">Time On Call</div>
        <div className="text-gray-900 font-semibold mb-1">4hr 21min</div>
      </div>
      <div className="text-center border-l border-gray-300">
        <div className="text-gray-500 text-xs">Emails Sent</div>
        <div className="text-gray-900 font-semibold mb-1">1600</div>
      </div>
      <div className="text-center border-l border-gray-300">
        <div className="text-gray-500 text-xs">Emails Processed</div>
        <div className="text-gray-900 font-semibold mb-1">2500</div>
      </div>
      <div className="text-center border-l border-gray-300">
        <div className="text-gray-500 text-xs">Time Savings</div>
        <div className="text-gray-900 font-semibold mb-1">6hr 43min</div>
      </div>
    </div>

    <div className="border-b border-gray-300"></div>

    {/* In Progress Section */}
    <div className="my-4">
      <h3 className="text-sm font-medium mb-4">In Progress</h3>
      <div className="bg-gray-50 rounded-lg p-12 text-center mb-4">
        <p className="text-gray-900 font-medium mb-1">
          No tasks currently in progress
        </p>
        <p className="text-gray-500 text-sm">
          Richard is not currently working on any tasks.
        </p>
      </div>
    </div>

    {/* Completed Tasks Section */}
    <div className="mb-6">
      <div className="bg-gray-50 rounded-lg p-12 text-center">
        <p className="text-gray-900 font-medium mb-1">
          No tasks have been completed yet today.
        </p>
        <p className="text-gray-500 text-sm">
          Richard is not currently working on any tasks.
        </p>
      </div>
    </div>

    <div className="flex items-center bg-white border-y border-gray-100 rounded-lg p-2">
      {/* Time Indicator */}
      <div className="flex items-center space-x-1 min-w-[80px] border-r border-gray-200 pr-4">
        <div className="w-4 h-4 rounded-full bg-green-500"></div>
        <span className="text-sm text-gray-600">{'10:00'}</span>
      </div>

      {/* Task Details */}
      <div className="flex items-center space-x-4 ml-6 border-r border-gray-200 pr-6">
        <span className="text-sm text-primary font-normal ">{'#142546'}</span>
      </div>
      <div className="flex items-center space-x-4 ml-6 border-r border-gray-200 pr-6">
        <span className="text-gray-600 px-2 py-1 rounded text-xs font-normal">
          <BadgeCmp style="modern" type="success">
            LTL
          </BadgeCmp>
        </span>
      </div>

      <div className="ml-auto flex items-center justify-between space-x-8">
        <div className="flex items-center space-x-2 border-r border-gray-200">
          <span className="text-sm">{`Ontario -> Toronto`}</span>
          <span className="text-gray-500 text-sm font-semibold">98 LBS</span>
        </div>
      </div>

      <div className="flex items-center space-x-4 ml-6 border-r border-gray-200 pr-6">
        <span className="text-sm font-semibold">52</span>
        <span className="text-gray-500 text-sm">Ft.</span>
      </div>
      <div className="flex items-center space-x-2 min-w-[100px]">
        <span className="text-gray-500 text-sm">Avg. Rate: </span>
        <span className="text-sm font-semibold">$240</span>
      </div>
    </div>
  </div>
);

export default Overview;
