import { CheckSquareBroken, XClose } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { changeLeaderBoardNewCustomerStatus } from 'src/services/SalesDashboard';
import { getShortName, onError } from 'src/utils/CommonFunctions';

const NewCustomerLBRow = ({
  data,
  leaderBoardEventDetail,
  setIsRefresh,
}: any) => {
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);

  const changeStatusCustomerReassign = (type: string) => {
    if (type == 'approve') {
      setIsLoadingApprove(true);
    } else {
      setIsLoadingReject(true);
    }
    changeLeaderBoardNewCustomerStatus({
      leaderboardEventId: leaderBoardEventDetail.id,
      userId: data.userId,
      customerId: data.cusId,
      isApproved: type == 'approve' ? true : false,
    })
      .then(() => {
        setIsRefresh(true);
      })
      .catch(console.error)
      .finally(() => {
        if (type == 'approve') {
          setIsLoadingApprove(false);
        } else {
          setIsLoadingReject(false);
        }
      });
  };

  return (
    <>
      <td className="px-5 py-4 max-w-[1px] w-[20%] min-w-[20%]">
        <div className="flex items-center gap-3">
          {data.customerImage ? (
            <img
              className="table-profile-icn-circle-xl"
              src={data.customerImageUrl + data.customerImage}
              alt=""
              title=""
              onError={onError}
            />
          ) : (
            <div className="table-profile-customer-circle-xl">
              {getShortName(`${data.name}`)}
            </div>
          )}
          <div className="truncate">
            {data.name.length > 25 ? (
              <TooltipCmp message={data.name} parentClassName="">
                <span className="text-grayLight900 font-medium leading-tight truncate">
                  {data.name}
                </span>
              </TooltipCmp>
            ) : (
              <span className="text-grayLight900 font-medium leading-tight truncate">
                {data.name}
              </span>
            )}
          </div>
        </div>
      </td>

      <td className="px-5 py-4 max-w-[1px] w-[20%] min-w-[20%]">
        <div className="flex items-center">
          {data.userImage ? (
            <img
              className="table-profile-icn-circle-sm"
              src={data.userImageUrl + data.userImage}
              alt={data.salesRepFullName}
              title={data.salesRepFullName}
              onError={onError}
            />
          ) : (
            <div className="table-profile-user-circle-sm">
              <span>{getShortName(`${data.salesRepFullName}`)}</span>
            </div>
          )}
          <div className="pl-2 truncate">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              {data.salesRepFullName}
            </h6>
          </div>
        </div>
      </td>

      <td className={`px-5 py-3 font-normal min-w-[182px] max-w-[15%] w-[15%]`}>
        <span>
          {data.customerCreatedAt &&
            moment(data.customerCreatedAt).format('MMM DD, YYYY')}
        </span>
      </td>

      <td className={`px-5 py-3 font-normal min-w-[150px] max-w-[15%] w-[15%]`}>
        <span>{data.firstOrderFullID}</span>
      </td>

      <td className={`px-5 py-3 font-normal min-w-[187px] max-w-[15%] w-[15%]`}>
        <span>
          {data.firstOrderCreatedAt &&
            moment(data.firstOrderCreatedAt).format('MMM DD, YYYY')}
        </span>
      </td>

      <td className="px-5 py-4 w-[84px] max-w-[84px] min-w-[84px]">
        <div className="justify-end items-center gap-2 flex ">
          {(!data?.customerStatus || data?.customerStatus === 'declined') && (
            <ButtonCmp
              onClick={() => changeStatusCustomerReassign('approve')}
              className={`btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent ${
                isLoadingApprove ? 'opacity-60' : ''
              }`}
              loading={isLoadingApprove}
              disabled={isLoadingApprove || isLoadingReject}
            >
              {!isLoadingApprove && (
                <CheckSquareBroken className="w-3.5 h-3.5 text-fgSuccessPrimary" />
              )}
            </ButtonCmp>
          )}

          {data?.customerStatus === 'approved' && (
            <ButtonCmp
              onClick={() => changeStatusCustomerReassign('reject')}
              className={`btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent ${
                isLoadingReject ? 'opacity-60' : ''
              }`}
              loading={isLoadingReject}
              disabled={isLoadingApprove || isLoadingReject}
            >
              {!isLoadingReject && (
                <XClose className="w-4 h-4 text-fgErrorPrimary" />
              )}
            </ButtonCmp>
          )}
        </div>
      </td>
    </>
  );
};

export default NewCustomerLBRow;
