import { parsePhoneNumber } from 'libphonenumber-js';
import _ from 'lodash';
import moment from 'moment';
import { useContext } from 'react';
import { TIMEZONE, USER_ROLE } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { BasicContext } from 'src/context/BasicContext';
import { BadgeType } from 'src/interface/common';

import Bronze from '../assets/img/customer-rank-diamonds/bronze.svg';
import Diamond from '../assets/img/customer-rank-diamonds/diamond.svg';
import Gold from '../assets/img/customer-rank-diamonds/gold.svg';
import Platinum from '../assets/img/customer-rank-diamonds/platinum.svg';
import Silver from '../assets/img/customer-rank-diamonds/silver.svg';
import defaultImage from '../assets/img/default-image.jpg';
import { MEASUREMENT, REGION_CODE } from '../constants/common';
import { AuthContext } from '../context/AuthContext';
import { downloadGCSPdf } from '../services/QuoteService';

export const GetAppearance = () => {
  let { appearance } = useContext(BasicContext);

  return appearance;
};

export const preventEnterSubmit = (keyEvent: any) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};

export const getSelectBoxOptions = (
  data: Array<any>,
  value: string = 'id',
  label: string = 'name',
  isImage: boolean = false,
  imageUrl: string = 'imageUrl',
  image: string = 'image',
  members: string = 'members',
  secondLabel: any = null,
  isSort: boolean = false,
  defaultImg: any = null
) => {
  if (!Array.isArray(data)) {
    data = [data];
  }
  if (isSort) data.sort((a, b) => a[label].localeCompare(b[label]));

  return data.map((dataItem: any) => ({
    value: dataItem[value],
    label: `${dataItem[label]} ${secondLabel ? dataItem[secondLabel] : ''}`,
    members: dataItem[members],
    image: isImage
      ? dataItem[image]
        ? dataItem[imageUrl] + dataItem[image]
        : defaultImg
      : defaultImg,
    isImage: isImage,
  }));
};

export const getShortName = (name: string = '') => {
  const shortName = name
    .split(/[\s-]/)
    .map((word) => word.charAt(0))
    .slice(0, 2)
    .join('');

  return shortName;
};

export const onError = (event: any) => {
  event.target.src = defaultImage;
};

export const useRolePermission = () => {
  const { currentUser } = useContext(AuthContext);

  const hasRoleV2 = (roleName: string) => {
    if (
      !currentUser?.role?.name ||
      !Object.values(USER_ROLE).includes(roleName)
    ) {
      return false;
    }

    if (roleName === USER_ROLE.USER) {
      return [
        USER_ROLE.SALES,
        USER_ROLE.OPERATIONS,
        USER_ROLE.CLAIMS,
        USER_ROLE.FINANCE,
      ].includes(currentUser?.role?.name);
    }

    return currentUser?.role?.name === roleName;
  };

  const hasPermissionV2 = (permission: string | string[]) => {
    const permissionsNameList = currentUser.permissions.map(
      (curPermission: any) => curPermission?.name
    );

    if (Array.isArray(permission)) {
      return permission.every((perm) => permissionsNameList.includes(perm));
    }

    return permissionsNameList.includes(permission);
  };

  // old one
  const hasRole = (roleName: string) => (roleName ? true : false);

  // const permissions = currentUser.role.permissions.split(',');
  // return permissions.includes(permission);
  // TODO : implement role and permission management
  const hasPermission = (permission: string) => (permission ? true : false);

  return { hasRoleV2, hasRole, hasPermission, hasPermissionV2 };
};

export const usePhone = () => {
  const setFormatPhone = (phone: string) => {
    try {
      if (phone) {
        const number = parsePhoneNumber(phone, REGION_CODE);

        return number.isValid() ? number.formatNational() : phone;
      }

      return phone;
    } catch {
      return phone;
    }
  };

  const getFormatPhone = (phone: string) => {
    phone = phone && phone.includes(',') ? phone.split(',')[0] : phone;

    try {
      const number = parsePhoneNumber(phone, REGION_CODE);
      let formattedNumber = phone;

      if (number.isValid()) {
        formattedNumber =
          number.getType() === 'TOLL_FREE'
            ? number.formatInternational()
            : number.formatNational();
      }

      return formattedNumber
        .replace('+', '')
        .replace('(', '')
        .replace(')', '')
        .split(' ')
        .join('-');
    } catch {
      return phone;
    }
  };

  const getPhone = (phone: string) => {
    phone = phone && phone.includes(',') ? phone.split(',')[0] : phone;

    try {
      const number = parsePhoneNumber(phone, REGION_CODE);

      return number.isValid() ? number.number : phone;
    } catch {
      return phone;
    }
  };

  const getFormatPhoneWithPlus = (phone: any) => {
    try {
      const phoneNumber = parsePhoneNumber(phone, 'US');

      if (phoneNumber.isValid()) {
        return `${
          phoneNumber.countryCallingCode
            ? `+${phoneNumber.countryCallingCode}`
            : ''
        } (${phoneNumber.nationalNumber.slice(
          0,
          3
        )}) ${phoneNumber.nationalNumber.slice(
          3,
          6
        )}-${phoneNumber.nationalNumber.slice(6)}`;
      }
    } catch {
      return phone;
    }

    return phone;
  };

  return { setFormatPhone, getFormatPhone, getPhone, getFormatPhoneWithPlus };
};

export const downloadPdf = (url: string, name: string = 'invoice.pdf') => {
  downloadGCSPdf(url)
    .then((response: any) => {
      const blob = new Blob([response.data]);
      const pdfUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = name;
      link.dispatchEvent(new MouseEvent('click'));
      link.remove();
      URL.revokeObjectURL(pdfUrl);
    })
    .catch(console.error);
};

export const stringWithDot = (stringToBeCut: string, length = 7) =>
  stringToBeCut.length > length
    ? `${stringToBeCut.substring(0, length - 3)}...`
    : stringToBeCut;

export const capitalizeFirstLetterAndDash = (str: string) => {
  const words = str?.split('-');
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const result = capitalizedWords.join('-');

  return result;
};

export const capitalizeFirstLetterSpace = (str: string) => {
  if (typeof str !== 'string') {
    return str;
  } else {
    const words = str?.split(' ');
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const result = capitalizedWords.join(' ');

    return result;
  }
};

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    if (!file) {
      reject(new Error('No file provided'));

      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64String = reader.result?.toString()?.split(',')[1];

      if (base64String) {
        resolve(base64String);
      } else {
        reject(new Error('Failed to convert file to base64'));
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });

export const getFormattedNumber = (
  number: any,
  isDecimal: boolean = true,
  isDisplayDollar = false,
  isParseInt = false
) => {
  var options: Intl.NumberFormatOptions = {};

  if (isDecimal) {
    options = Number.isInteger(number)
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      : { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  } else {
    number = Math.round(number);
  }

  if (isParseInt) {
    number = parseInt(number);
  }
  const formattedNumber = new Intl.NumberFormat('en-US', options).format(
    number
  );

  if (isDisplayDollar) {
    if (number >= 0) {
      return `$${formattedNumber}`;
    } else {
      return `-$${formattedNumber.replace('-', '')}`;
    }
  }

  return formattedNumber;
};

export const setNumberWithCommas = (number: any) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatBigNumberToAbbreviation = (num: number) => {
  if (Math.abs(num) >= 1.0e9) {
    return `${(num / 1.0e9).toFixed(2).replace(/\.?0+$/, '')}B`;
  } else if (Math.abs(num) >= 1.0e6) {
    return `${(num / 1.0e6).toFixed(2).replace(/\.?0+$/, '')}M`;
  } else if (Math.abs(num) >= 1.0e3) {
    return `${(num / 1.0e3).toFixed(1).replace(/\.?0+$/, '')}K`;
  } else {
    return num.toString();
  }
};

export const DateFormat = (val: any) => {
  let formattedDate = '';

  if (val) {
    const date = new Date(val);
    formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  }

  return formattedDate;
};

export const getDateRange = (range: any, allTimeStartDate?: any) => {
  switch (range) {
    case 'last_7_days':
      return {
        start: moment().subtract(6, 'days').toDate(),
        end: moment().toDate(),
      };
    case 'last_30_days':
      return {
        start: moment().subtract(29, 'days').toDate(),
        end: moment().toDate(),
      };
    case 'last_90_days':
      return {
        start: moment().subtract(89, 'days').toDate(),
        end: moment().toDate(),
      };
    case 'last_year':
      return {
        start: moment().subtract(1, 'year').toDate(),
        end: moment().toDate(),
      };

    case 'monthly': {
      const today = moment().local(); // Ensure consistency with local time

      return {
        start: today.clone().startOf('month').endOf('day').toDate(), // First day of the month at 23:59:59
        end: today.clone().endOf('month').endOf('day').toDate(), // Last day of the month at 23:59:59
      };
    }

    case 'weekly': {
      const today = moment().local(); // Ensure consistency with local time

      return {
        start: today.clone().isoWeekday(0).endOf('day').toDate(), // Sunday 23:59:59
        end: today.clone().isoWeekday(6).endOf('day').toDate(), // Saturday 23:59:59
      };
    }

    case 'quarterly': {
      const today = moment().local(); // Ensure consistency with local time

      return {
        start: today.clone().startOf('quarter').endOf('day').toDate(), // First day of the quarter at 23:59:59
        end: today.clone().endOf('quarter').endOf('day').toDate(), // Last day of the quarter at 23:59:59
      };
    }

    case 'yearly': {
      const today = moment().local(); // Ensure consistency with local time

      return {
        start: today.clone().startOf('year').endOf('day').toDate(), // First day of the year at 23:59:59
        end: today.clone().endOf('year').endOf('day').toDate(), // Last day of the year at 23:59:59
      };
    }
    case 'daily':
      return {
        start: moment().toDate(),
        end: moment().toDate(),
      };

    case 'all_time': {
      const today = moment().local(); // Ensure consistency with local time

      return {
        start: moment(allTimeStartDate, 'YYYY-MM-DD')
          .local() // Ensure local timezone
          .endOf('day') // Set time to 23:59:59
          .toDate(),

        end: today.clone().endOf('day').toDate(), // Today at 23:59:59
      };
    }
    // Add more cases for Weekly, Monthly, Quarterly, Yearly as needed
    default:
      return {};
  }
};

export const dateToDayAndTime = (dateString: any) => {
  const date = new Date(dateString);

  // const options = { weekday: "long", hour: "2-digit", minute: "2-digit" };
  return date.toLocaleString('en-US', {
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const calculateNiceMaximum = (maxDataValue: any, tickAmount: any) => {
  let rawStep = maxDataValue / tickAmount;
  let magnitude = Math.pow(10, Math.floor(Math.log10(rawStep)));
  let normalizedStep = rawStep / magnitude;

  let adjustedStep;

  if (normalizedStep <= 1) {
    adjustedStep = 1;
  } else if (normalizedStep <= 2) {
    adjustedStep = 2;
  } else if (normalizedStep <= 5) {
    adjustedStep = 5;
  } else {
    adjustedStep = 10;
  }

  let customMax =
    Math.ceil(maxDataValue / (magnitude * adjustedStep)) *
    (magnitude * adjustedStep);
  customMax = Math.max(customMax, maxDataValue);

  return customMax;
};

export const convertBase64ToFile = (appLogoImage: any) => {
  try {
    const imageExtension = appLogoImage.substring(
      appLogoImage.indexOf('/') + 1,
      appLogoImage.indexOf(';base64')
    );

    const currentTimestamp = Date.now();
    const randomNum = Math.floor(1000000000 + Math.random() * 9000000000);
    const filename = `${currentTimestamp}_${randomNum}.${imageExtension}`;

    const base64Data = appLogoImage.replace(/^data:[^;]+;base64,/, '');

    const uint8Array = Uint8Array.from(atob(base64Data), (c) =>
      c.charCodeAt(0)
    );

    const blob = new Blob([uint8Array], {
      type: 'application/octet-stream',
    });

    const convertedFile = new File([blob], filename, {
      type: 'application/octet-stream',
    });

    return {
      convertedFile: convertedFile,
      filename: filename,
    };
  } catch (error) {
    // console.error('Error converting base64 to file:', error);
  }
};

export const getTime = (totalMinutes: any) => {
  const hours = Math.floor(totalMinutes / (60 * 60));
  const minutesOfHour = hours * 60;
  const minutes = Math.round(totalMinutes / 60) - minutesOfHour;

  return `${hours} hr ${minutes} min`;
};

export const checkFileTypeValidation = (
  uploadedFile: any,
  size: any,
  type: string[] = ['image/svg+xml', 'image/png', 'image/jpeg']
) => {
  const validType = type;
  const validationResults = _.map(uploadedFile, (file: File) => {
    if (!validType.includes(file.type)) {
      return {
        result: false,
        message: 'Invalid file format. Please upload a valid image file.',
      };
    } else if (file.size > size) {
      return {
        result: false,
        message: `Image size must be less than ${Math.round(
          size / (1028 * 1028)
        )}MB`,
      };
    } else {
      return { result: true, message: '' };
    }
  });

  const validationResult = _.every(
    validationResults,
    (result: { result: boolean }) => result.result === true
  );

  return {
    result: validationResult,
    message: validationResult ? '' : validationResults[0].message,
  };
};

export const convertToGBMBKB = (val: any) => {
  const input = parseFloat(val);
  const gb = 1024 * 1024 * 1024; // Convert to gigabytes
  const mb = 1024 * 1024; // Convert to megabytes
  const kb = 1024; // Convert to kilobytes
  var convertedVal: any = 0;

  if (input > gb) {
    convertedVal = `${Math.round(val / gb)} GB`;
  } else if (input > mb && input < gb) {
    convertedVal = `${Math.round(val / mb)} MB`;
  } else if (input > kb && input < mb) {
    convertedVal = `${Math.round(val / kb)} KB`;
  } else if (input < kb) {
    convertedVal = `${Math.round(input)} Bytes`;
  }

  return convertedVal;
};

export const TimeConverter = (inputDateTime: any) => {
  let appearance = GetAppearance();
  const momentDateTime = moment
    .utc(inputDateTime)
    .tz(appearance?.timezone ?? TIMEZONE);

  return momentDateTime.format('dddd hh:mm A');
};

export const getDateWithSuffixFormat = (
  date: string,
  format = 'MMM Do YYYY'
) => {
  const day = moment.utc(date, format).date();

  const suffixRegex = /(\d+)(st|nd|rd|th)/;

  const formattedDate = date.replace(suffixRegex, (match, p1, p2) => {
    if (day >= 11 && day <= 13) {
      return `${p1}<sup>th</sup>`;
    } else {
      switch (p2) {
        case 'st':
          return `${p1}<sup>st</sup>`;
        case 'nd':
          return `${p1}<sup>nd</sup>`;
        case 'rd':
          return `${p1}<sup>rd</sup>`;
        default:
          return `${p1}<sup>th</sup>`;
      }
    }
  });

  return formattedDate;
};

export const getFormattedDate = (
  date: any,
  format = 'MMMM Do, YYYY',
  isTimeDisplay = false,
  convertTimezone = false,
  isAppearance: any = false
) => {
  if (!date) {
    return '-';
  }
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // const momentDate = moment.utc(date).tz(browserTimezone);
  let momentDate = moment(date);
  let appearance;

  if (isAppearance) {
    appearance = isAppearance;
  } else {
    appearance = GetAppearance();
  }

  if (convertTimezone) {
    momentDate = moment.utc(date).tz(appearance?.timezone ?? browserTimezone);
  }
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');
  const tomorrow = moment().add(1, 'days').startOf('day');

  if (momentDate.isSame(today, 'day')) {
    if (!isTimeDisplay) return `Today `;
    else return `Today, ${momentDate.format('hh:mm A')}`;
  } else if (momentDate.isSame(yesterday, 'day')) {
    if (!isTimeDisplay) return `Yesterday`;
    else return `Yesterday, ${momentDate.format('hh:mm A')}`;
  } else if (momentDate.isSame(tomorrow, 'day')) {
    if (!isTimeDisplay) return `Tomorrow`;
    else return `Tomorrow, ${momentDate.format('hh:mm A')}`;
  } else {
    return momentDate.format(format);
  }
};

export const getLabelByValue = (value: any, arr?: any) => {
  const obj: any = arr ?? MEASUREMENT;

  for (const key in obj) {
    if (obj[key].value === value) {
      return obj[key].label;
    }
  }

  return null;
};

export const formatAddress = (address: string | false) => {
  if (typeof address === 'string') {
    address = address.replace(/Ã©/g, 'é');
    address = address.replace(/Ã‰/g, 'É');
    address = address.replace(/%/g, '');
    address = address.replace(/Ã /g, 'à');
    address = address.replace(/Ã¨/g, 'è');
    address = address.replace(/Ã¬/g, 'ì');
    address = address.replace(/Ã²/g, 'ò');
    address = address.replace(/Ã¹/g, 'ù');
    address = address.replace(/Ã‚/g, 'Â');
    address = address.replace(/Ã‹/g, 'Ë');
    address = address.replace(/ÃŠ/g, 'Ê');
    address = address.replace(/Ã‹/g, 'Ë');
    address = address.replace(/Ã¯/g, 'ï');
    address = address.replace(/Ã´/g, 'ô');
    address = address.replace(/Ã¶/g, 'ö');
    address = address.replace(/Ã»/g, 'û');
    address = address.replace(/Ã¼/g, 'ü');
    address = address.replace(/Ã§Ã /g, 'ç');
    address = address.replace(/ÃŸ/g, 'ß');
    address = address.replace(/Ã¦/g, 'æ');
    address = address.replace(/Ã¸/g, 'ø');
    address = address.replace(/Ã¥/g, 'å');
    address = address.replace(/Ã¢/g, 'â');
    address = address.replace(/Ã‘/g, 'Ñ');
    address = address.replace(/Ã¡/g, 'á');
    address = address.replace(/Ã³/g, 'ó');

    return address;
  }

  return address;
};

export const capitalizeFirstLetter = (str: string) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return str;
};

export const fetchJsFromCDN = (src: any, externals: any = []) =>
  new Promise((resolve, reject) => {
    document.getElementById('ioScript')?.remove();
    const script = document.createElement('script');
    script.setAttribute('src', src);
    script.setAttribute('id', 'ioScript');
    script.addEventListener('load', () => {
      resolve(
        externals.map((key: any) => {
          const ext = window[key];

          if (typeof ext === 'undefined') {
            console.warn(`No external named '${key}' in window`);
          }

          return ext;
        })
      );
    });
    script.addEventListener('error', reject);
    document.body.appendChild(script);
  });

export const isValidJSON = (str: any) => {
  try {
    JSON.parse(str);

    return true;
  } catch (e) {
    return false;
  }
};

// export const getProgressClass = (value: any) => {
//   if (value >= 75) return 'progress-success';
//   if (value >= 50) return 'progress-yellow';

//   return 'progress-danger';
// };

const subtractWorkingDays = (date = moment(), days = 1) => {
  let result = moment(date); // Start from the given date

  while (days > 0) {
    // Check if it's a weekday
    if (result.isoWeekday() !== 6 && result.isoWeekday() !== 7) {
      days--; // Decrease the working days count
    }

    result.subtract(1, 'days'); // Subtract one day
  }

  return result;
};

export const calculateWorkingDays = (month = moment()) => {
  const startOfMonth = subtractWorkingDays(moment(month).startOf('month'), 5);
  const endOfMonth = subtractWorkingDays(moment(month).endOf('month'), 5);
  const today = moment().startOf('day'); // Start of today to exclude today

  let totalWorkingDays = 0;
  let passedWorkingDays = 0;

  for (
    let day = startOfMonth;
    day.isBefore(endOfMonth) || day.isSame(endOfMonth);
    day.add(1, 'day')
  ) {
    const dayOfWeek = day.day();

    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      // 0 is Sunday, 6 is Saturday
      totalWorkingDays++;

      if (day.isBefore(today)) {
        passedWorkingDays++;
      }
    }
  }

  return { totalWorkingDays, passedWorkingDays };
};

export const getProgressClass = (
  currentCompletionPercentage: any,
  forProgressBar = true,
  targetPercentage: any = 100
) => {
  const { totalWorkingDays, passedWorkingDays } = calculateWorkingDays();

  const requiredDailyProgress = targetPercentage / totalWorkingDays;
  const requiredCompletionToDate = requiredDailyProgress * passedWorkingDays;

  let assessment;

  if (currentCompletionPercentage >= requiredCompletionToDate) {
    assessment = 100;
  } else {
    assessment = (currentCompletionPercentage / requiredCompletionToDate) * 100;
  }

  if (assessment >= 80 && assessment <= 100)
    return forProgressBar ? 'progress-success' : 'text-fgSuccessPrimary';
  if (assessment >= 60 && assessment < 80)
    return forProgressBar ? 'progress-yellow' : 'text-yellow300';

  return forProgressBar ? 'progress-danger' : 'text-fgErrorPrimary';
};

export const goalTarget = (targetPercentage: any = 100) => {
  const { totalWorkingDays, passedWorkingDays } = calculateWorkingDays();

  const requiredDailyProgress = targetPercentage / totalWorkingDays;
  const requiredCompletionToDate = requiredDailyProgress * passedWorkingDays;

  return parseInt(requiredCompletionToDate.toString());
};

export const customFromNowWithAgo = (date: any) => {
  const dateTime = moment.utc(date);
  const now = moment();
  const duration = moment.duration(now.diff(dateTime));

  if (now.isBefore(dateTime)) {
    return 'in the future';
  }

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = Math.abs(duration.seconds());

  let result = '';

  if (years > 0) {
    result = `${years}y ago`;
  } else if (months > 0) {
    result = `${months}mo ago`;
  } else if (days > 0) {
    result = `${days}d ago`;
  } else if (hours > 0) {
    result = `${hours}h ago`;
  } else if (minutes > 0) {
    result = `${minutes}m ago`;
  } else {
    result = `${seconds}s ago`;
  }

  return result.trim();
};

export const customFromNow = (date: any) => {
  const dateTime = moment.utc(date);
  const now = moment();
  const duration = moment.duration(now.diff(dateTime));

  if (now.isBefore(dateTime)) {
    return 'in the future';
  }

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = Math.abs(duration.seconds());

  let result = '';

  if (years > 0) {
    result = `${years} ${years > 1 ? 'Yrs' : 'Yr'}`;
  } else if (months > 0) {
    result = `${months} Mon`;
  } else if (days > 0) {
    result = `${days} ${days > 1 ? 'Days' : 'Day'}`;
  } else if (hours > 0) {
    result = `${hours} ${hours > 1 ? 'Hrs' : 'Hr'}`;
  } else if (minutes > 0) {
    result = `${minutes} Min`;
  } else {
    result = `${seconds} Sec`;
  }

  return result.trim();
};

export const confettiAnimation = (count = 200) => {
  const defaults = {
    origin: { y: 0.7 },
  };

  function fire(particleRatio: any, opts: any) {
    window.confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio),
      })
    );
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });

  fire(0.2, {
    spread: 60,
  });

  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });

  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });

  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};

export const getTimeDifference = (givenDate: any) => {
  const now = new Date() as any;
  const pastDate = new Date(givenDate) as any;
  const differenceInMs = now - pastDate;

  const differenceInSeconds = Math.floor(differenceInMs / 1000);
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  const differenceInHours = Math.floor(differenceInMinutes / 60);

  const years = Math.floor(differenceInHours / (24 * 365));
  const months = Math.floor(differenceInHours / (24 * 30)) % 12;
  const days = Math.floor(differenceInHours / 24) % 30;
  const hours = differenceInHours % 24;
  const minutes = differenceInMinutes % 60;
  const seconds = differenceInSeconds % 60;

  return { years, months, days, hours, differenceInHours, minutes, seconds };
};

function formatTimeUnit(unit: any) {
  return unit < 10 ? `0${unit}` : unit;
}

export const displayTimeDifference = (givenDate: any): any => {
  const { years, months, days, differenceInHours, minutes, seconds } =
    getTimeDifference(givenDate);

  let displayTime;

  if (differenceInHours < 100) {
    displayTime = `${formatTimeUnit(differenceInHours)}:${formatTimeUnit(
      minutes
    )}:${formatTimeUnit(seconds)}`;
  } else if (days > 0) {
    displayTime = `${days}d ago`;
  } else if (months > 0) {
    displayTime = `${months}Mo ago`;
  } else if (years > 0) {
    displayTime = `${years}y ago`;
  }

  return displayTime;
};

export const getRankDiamond = (ordCount: number) => {
  if (ordCount <= 4) {
    return Bronze;
  } else if (ordCount <= 16) {
    return Silver;
  } else if (ordCount <= 32) {
    return Gold;
  } else if (ordCount <= 100) {
    return Platinum;
  } else {
    return Diamond;
  }
};

export const getTimeDifferenceWithUTC = (givenDate: any) => {
  const now = moment.utc();
  const pastDate = moment.utc(givenDate);
  const duration = moment.duration(now.diff(pastDate));

  const years = Math.floor(duration.asYears());
  const months = Math.floor(duration.asMonths());
  const days = Math.floor(duration.asDays());
  const totalHours = Math.floor(duration.asHours());

  return { years, months, days, totalHours };
};

export const getBadgeType = (
  createdAtDate: any
): { type: BadgeType; clockType: string } => {
  const { totalHours }: any = getTimeDifferenceWithUTC(createdAtDate);

  if (totalHours < 72) {
    return { type: 'success', clockType: 'text-success500' };
  } else if (totalHours >= 72 && totalHours < 168) {
    return { type: 'yellow', clockType: 'text-orange500' };
  } else {
    return { type: 'red', clockType: 'text-red500' };
  }
};

export const getBadgeTypeByMinutes = (
  date: string
): { type: BadgeType; clockType: string } => {
  const elapsedMinutes = moment().diff(moment.utc(date), 'minutes');

  if (elapsedMinutes <= 30) {
    return { type: 'success', clockType: 'text-success500' };
  } else if (elapsedMinutes > 30 && elapsedMinutes <= 120) {
    return { type: 'yellow', clockType: 'text-orange500' };
  } else {
    return { type: 'red', clockType: 'text-red500' };
  }
};

export const generateTargetUrl = (notification: any) => {
  let path = '/';

  switch (notification.type) {
    case 'claim':
      if (
        [
          'claim_status',
          'action_item_created',
          'action_item_updated',
          'action_item_deleted',
          'file_attachment_created',
          'file_attachment_deleted',
          'claim_internal_notes',
        ].includes(notification.subType)
      ) {
        path = `${PATH.CLAIM_DETAILS}/${notification.entityId}`;
      }
      break;

    case 'dispute':
      if (
        [
          'dispute_status',
          'action_item_created',
          'action_item_updated',
          'action_item_deleted',
          'file_attachment_created',
          'file_attachment_deleted',
          'dispute_internal_notes',
        ].includes(notification.subType)
      ) {
        path = `${PATH.DISPUTE_DETAILS}/${notification.entityId}`;
      }
      break;

    case 'order':
      if (['order_created', 'order_updated'].includes(notification.subType)) {
        path = `${ROUTES.ORDERS}`;
      }
      break;

    case 'customer':
      if (
        [
          'customer_group_deleted',
          'customer_sync',
          'customer_reassign',
          'customer_unassign',
          'customer_updated',
        ].includes(notification.subType)
      ) {
        path = `${PATH.CUSTOMER_DETAILS}/${notification.entityId}`;
      } else if (
        ['customer_group_created', 'customer_group_updated'].includes(
          notification.subType
        )
      ) {
        path = `${PATH.CUSTOMER_GROUP_DETAILS}/${notification.entityId}`;
      } else {
        path = `${ROUTES.CUSTOMERS}`;
      }
      break;

    case 'carrier':
      if (
        ['carrier_created', 'carrier_updated', 'carrier_deleted'].includes(
          notification.subType
        )
      ) {
        path = `${ROUTES.CARRIERS}`;
      }
      break;

    case 'feedback':
      if (
        ['feedback_create', 'feedback_status'].includes(notification.subType)
      ) {
        path = `${ROUTES.FEEDBACK_LIST}`;
      }
      break;

    case 'quoting_hub_request':
      if (['quote_request_arrived'].includes(notification.subType)) {
        path = `${PATH.REQ_MY_QUOTE}`;
      }
      break;

    case 'load_insurance':
      if (
        ['load_insurance_updated', 'load_insurance_status'].includes(
          notification.subType
        )
      ) {
        path = `${PATH.LOAD_INSURANCE}/${notification.entityId}`;
      }
      break;

    case 'customer_onboarding':
      if (['customer_onboarding_status'].includes(notification.subType)) {
        path = `${ROUTES.CREDIT_DASHBOARD}`;
      }
      break;

    case 'credit_increase_request':
      if (
        [
          'credit_increase_request_created',
          'credit_increase_request_status',
        ].includes(notification.subType)
      ) {
        path = `${ROUTES.CREDIT_DASHBOARD}`;
      }
      break;

    case 'team_member':
      if (
        ['team_member_updated', 'team_member_permission_updated'].includes(
          notification.subType
        )
      ) {
        path = `${ROUTES.TEAMS}`;
      } else if (['team_member_deleted'].includes(notification.subType)) {
        path = `${ROUTES.TEAMS}`;
      }

      break;

    case 'team_management':
      if (
        [
          'team_created',
          'team_edited',
          'team_member_updated',
          'team_member_deleted',
        ].includes(notification.subType)
      ) {
        path = `${ROUTES.TEAM_MANAGEMENT}/${notification.entityId}`;
      } else if (['team_deleted'].includes(notification.subType)) {
        path = `${ROUTES.TEAM_MANAGEMENT}`;
      }
      break;

    default:
      path = '/';
  }

  return path;
};

export const removeHtmlTags = (str: any) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = str;

  return tempDiv.textContent || tempDiv.innerText || '';
};

export const getFormattedPickupDate = (date: any) => {
  const momentDate = moment(date).local().startOf('day');
  const today = moment().local().startOf('day');
  const yesterday = moment().local().subtract(1, 'days').startOf('day');
  const tomorrow = moment().local().add(1, 'days').startOf('day');

  if (momentDate.isSame(today, 'day')) {
    return `Today, ${momentDate.format('MMMM Do, YYYY')}`;
  } else if (momentDate.isSame(yesterday, 'day')) {
    return `Yesterday, ${momentDate.format('MMMM Do, YYYY')}`;
  } else if (momentDate.isSame(tomorrow, 'day')) {
    return `Tomorrow, ${momentDate.format('MMMM Do, YYYY')}`;
  } else {
    return momentDate.format('dddd, MMMM Do, YYYY');
  }
};

export const getLocalFormattedPickupDate = (date: any) => {
  const momentDate = moment(date).local();
  const today = moment().local().startOf('day');
  const yesterday = moment().local().subtract(1, 'days').startOf('day');
  const tomorrow = moment().local().add(1, 'days').startOf('day');

  if (momentDate.isSame(today, 'day')) {
    return `Today, ${momentDate.format('MMMM Do, YYYY')}`;
  } else if (momentDate.isSame(yesterday, 'day')) {
    return `Yesterday, ${momentDate.format('MMMM Do, YYYY')}`;
  } else if (momentDate.isSame(tomorrow, 'day')) {
    return `Tomorrow, ${momentDate.format('MMMM Do, YYYY')}`;
  } else {
    return momentDate.format('dddd, MMMM Do, YYYY');
  }
};

export const getUtcToLocalFormattedPickupDate = (
  date: any,
  isTimeDisplay = false,
  convertTimezone = false,
  format = 'MMMM Do, YYYY',
  showFullDateForTodayTomorrow = false,
  isShowDayBeforeDate = false
) => {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let momentDate = moment(date);
  let appearance = GetAppearance();

  if (convertTimezone) {
    momentDate = moment.utc(date).tz(appearance?.timezone ?? browserTimezone);
  }
  const today = moment.utc().local().startOf('day');
  const yesterday = moment.utc().local().subtract(1, 'days').startOf('day');
  const tomorrow = moment.utc().local().add(1, 'days').startOf('day');

  if (momentDate.isSame(today, 'day')) {
    if (showFullDateForTodayTomorrow) {
      return `Today, ${momentDate.format(format)}`;
    }

    return isTimeDisplay ? `Today, ${momentDate.format('hh:mm A')}` : `Today`;
  } else if (momentDate.isSame(yesterday, 'day')) {
    if (showFullDateForTodayTomorrow) {
      return `Yesterday, ${momentDate.format(format)}`;
    }

    return isTimeDisplay
      ? `Yesterday, ${momentDate.format('hh:mm A')}`
      : `Yesterday`;
  } else if (momentDate.isSame(tomorrow, 'day')) {
    if (showFullDateForTodayTomorrow) {
      return `Tomorrow, ${momentDate.format(format)}`;
    }

    return isTimeDisplay
      ? `Tomorrow, ${momentDate.format('hh:mm A')}`
      : `Tomorrow`;
  } else {
    if (isShowDayBeforeDate) {
      return momentDate.format(`dddd, ${format}`);
    }

    return momentDate.format(format);
  }
};

export const convertSecondsToTime = (seconds: moment.DurationInputArg1) => {
  const duration = moment.duration(seconds, 'seconds');
  const days = Math.floor(duration.days());
  const hours = Math.floor(duration.hours());
  const minutes = Math.floor(duration.minutes());
  const secondsLeft = Math.floor(duration.seconds());

  if (days > 0) {
    return `${days}d ${hours}h ${minutes}m ${secondsLeft}s`;
  } else if (hours > 0) {
    return `${hours}h ${minutes}m ${secondsLeft}s`;
  }

  return `${minutes}m ${secondsLeft}s`;
};

export const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: 'miles' | 'kilometers' = 'miles'
): number => {
  const toRadians = (degree: number) => degree * (Math.PI / 180);

  const earthRadius = unit === 'miles' ? 3958.8 : 6371.0;

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadius * c;

  return Math.round(distance * 100) / 100;
};

export const fixedDecimal = (
  num: number | string = 0,
  decimalPlaces: number = 2
): number => {
  const equalConstant = decimalPlaces * 10;

  if (typeof num === 'string') {
    return Math.round(parseFloat(num) * equalConstant) / equalConstant;
  }

  return Math.round(num * equalConstant) / equalConstant;
};

export const getMinutesAndSeconds = (seconds: number) =>
  moment.utc(seconds * 1000).format('mm:ss');

export const formatLastUpdate = (date: any): string => {
  if (!date) {
    return '-';
  }
  const now = moment().utc();
  const lastUpdate = moment.utc(date);
  const differenceInSeconds = now.diff(lastUpdate, 'seconds');

  const differenceInMinutes = now.diff(lastUpdate, 'minutes');
  const differenceInHours = now.diff(lastUpdate, 'hours');
  const differenceInDays = now.diff(lastUpdate, 'days');

  if (differenceInSeconds < 60) {
    return `${differenceInSeconds} seconds ago`;
  } else if (differenceInMinutes < 60) {
    return `${differenceInMinutes} minutes ago`;
  } else if (differenceInHours <= 72) {
    return `${differenceInHours} hours ago`;
  } else if (differenceInDays <= 3) {
    return `${differenceInDays} days ago`;
  } else {
    return lastUpdate.format('Do MMM YYYY');
  }
};

export const getCountryName = (countryCode: any, locale = 'en') => {
  try {
    const regionNames = new Intl.DisplayNames([locale], { type: 'region' });

    return regionNames.of(countryCode) || 'Unknown Country';
  } catch (error) {
    return 'Invalid Country Code';
  }
};

export const getCountryFlag = (countryCode: any) => {
  if (!countryCode) return '';

  return String.fromCodePoint(
    ...[...countryCode.toUpperCase()].map((char) => 127397 + char.charCodeAt())
  );
};

export const checkAudioDevicesAndPermissions = async (): Promise<void> => {
  try {
    // Check for available audio input (microphone) and output (speaker) devices
    const devices = await navigator.mediaDevices.enumerateDevices();
    const hasMicrophone = devices.some(
      (device) => device.kind === 'audioinput'
    );
    const hasSpeaker = devices.some((device) => device.kind === 'audiooutput');

    if (!hasMicrophone || !hasSpeaker) {
      throw new Error(
        `No ${!hasMicrophone ? 'microphone' : ''}${
          !hasMicrophone && !hasSpeaker ? ' or ' : ''
        }${
          !hasSpeaker ? 'speaker' : ''
        } found on your system. Please connect the required devices.`
      );
    }

    // Check for microphone permissions
    await navigator.mediaDevices.getUserMedia({ audio: true });
  } catch (error: any) {
    throw new Error(
      error.message ||
        'An issue occurred while checking audio devices or permissions.'
    );
  }
};

export const lastLocationUpdateToShowDotColor = (date: any): string => {
  const now = moment().utc();
  const lastUpdate = moment.utc(date);

  const differenceInHours = now.diff(lastUpdate, 'hours');

  if (differenceInHours < 6) {
    return `green`;
  } else if (differenceInHours >= 6 && differenceInHours <= 12) {
    return `yellow`;
  } else if (differenceInHours > 12) {
    return `red`;
  } else {
    return `green`;
  }
};

export const shuffleArray = (array: any) =>
  array
    .map((a: any) => ({ sort: a.score, value: a }))
    .sort((a: any, b: any) => b.sort - a.sort)
    .map((a: any) => a.value);
