import {
  ChevronDown,
  ChevronUp,
  Edit01,
  InfoCircle,
} from '@untitled-ui/icons-react/build/cjs';
import { Calendar } from 'lucide-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
// import profile1 from 'src/assets/img/tick-icon.svg';
import TooltipCmp from 'src/components/TooltipCmp';
import { DATE_FORMAT, ROSE_ROCKET, STATUS } from 'src/constants/common';
import { QUOTE_CLASSIFICATION } from 'src/constants/common';
import { getLoadLinkVehicleType } from 'src/services/LoadLinkService';
import { updateQuickQuote } from 'src/services/QuoteService';
import {
  getDateWithSuffixFormat,
  getFormattedPickupDate,
  getLabelByValue,
  getShortName,
} from 'src/utils/CommonFunctions';

interface QuoteDetailTableRowProps {
  data: any; // You might want to replace 'any' with the specific data type
  totalWeight: number;
  setAction: any;
  handleRateGathering: any;
  dimensions: { weightMeasurement: string }[];
  totalUnit: number;
  isDisplayDimensionsDetail: boolean;
  setIsDisplayDimensionsDetail: any;
  addressDetails: any;
  handleEditQuoteDetail: any;
  action: any;
  setQuoteDetailData: any;
  handleAction: any;
  setIsGetRate: any;
  sortActiveCarrierRates?: any;
}

const initAction = {
  isEdit: false,
  mode: '',
  data: {
    name: '',
    value: '',
  },
  fullAddress: {},
};

const initError = {
  type: '',
  message: '',
};

const QuoteDetailTableRow: React.FC<QuoteDetailTableRowProps> = ({
  data,
  totalWeight,
  dimensions,
  setAction,
  action,
  // totalUnit,
  addressDetails,
  isDisplayDimensionsDetail,
  setIsDisplayDimensionsDetail,
  setQuoteDetailData,
  sortActiveCarrierRates,
  handleRateGathering,
}) => {
  // const [action, setAction] = useState(initAction);
  const inputRef = useRef<any>(null);
  const [error, setError] = useState(initError);
  const [updatedTotalWeigth, setUpdatedTotalWeigth] = useState(totalWeight);
  const [isEquipmentTypeLoading, setIsEquipmentTypeLoading] = useState(true);
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState<any[]>([]);
  const [consigneeAddress, setConsigneeAddress] = useState({});
  const [shipperAddress, setShipperAddress] = useState({});
  const [pickup1pmError, setPickup1pmError] = useState(false);
  const [tomorrowDayName, setTomorrowDayName] = useState('');
  const [equipmentType, setEquipmentType] = useState(
    data?.primaryEquipmentType
  );
  const [pickupDate, setPickupDate] = useState(data?.pickupDate);

  const handleAcion = (name: string, value: any) => {
    setAction((old: any) => ({
      ...old,
      ...{
        isEdit: true,
        mode: name,
        data: {
          name: name,
          value: value,
        },
      },
    }));
  };

  useEffect(() => {
    if (
      action.isEdit &&
      action.mode === '' &&
      (action.data?.name === 'shipperAddress' ||
        action.data?.name === 'consigneeAddress') &&
      action.fullAddress
    ) {
      let params;

      if (action.data?.name === 'shipperAddress') {
        params = {
          shipperFullAddress: action?.fullAddress.fullAddress,
          shipperAddress1: action?.fullAddress.address1,
          shipperCity: action?.fullAddress.city,
          shipperState: action?.fullAddress.state,
          shipperCountry: action?.fullAddress.country,
          shipperPostal: action?.fullAddress.postal,
          shipperLatitude: action?.fullAddress.latitude,
          shipperLongitude: action?.fullAddress.longitude,
          shipperStateCode: action?.fullAddress.stateCode,
          shipperCountryCode: action?.fullAddress.countryCode,
        };
        setShipperAddress(action?.fullAddress);
      }

      if (action.data?.name === 'consigneeAddress') {
        params = {
          consigneeFullAddress: action?.fullAddress.fullAddress,
          consigneeAddress1: action?.fullAddress.address1,
          consigneeCity: action?.fullAddress.city,
          consigneeState: action?.fullAddress.state,
          consigneeCountry: action?.fullAddress.country,
          consigneePostal: action?.fullAddress.postal,
          consigneeLatitude: action?.fullAddress.latitude,
          consigneeLongitude: action?.fullAddress.longitude,
          consigneeStateCode: action?.fullAddress.stateCode,
          consigneeCountryCode: action?.fullAddress.countryCode,
        };
        setConsigneeAddress(action?.fullAddress);
      }
      updateQuickQuote(data?.id, params).then((response: any) => {
        if (response) {
          setAction(initAction);
          setError(initError);
          setQuoteDetailData([response?.data]);
          // setIsGetRate(true);
          handleRateGathering();
        }
      });
    }
  }, [action]);

  useEffect(() => {
    let shipperFullAddress = {
      fullAddress: data.shipperFullAddress,
      address1: data.shipperAddress1,
      city: data.shipperCity,
      state: data.shipperState,
      country: data.shipperCountry,
      postal: data.shipperPostal,
      latitude: data.shipperLatitude,
      longitude: data.shipperLongitude,
      stateCode: data.shipperStateCode,
      countryCode: data.shipperCountryCode,
    };
    let consigneeFullAddress = {
      fullAddress: data.consigneeFullAddress,
      address1: data.consigneeAddress1,
      city: data.consigneeCity,
      state: data.consigneeState,
      country: data.consigneeCountry,
      postal: data.consigneePostal,
      latitude: data.consigneeLatitude,
      longitude: data.consigneeLongitude,
      stateCode: data.consigneeStateCode,
      countryCode: data.consigneeCountryCode,
    };

    setShipperAddress(shipperFullAddress);
    setConsigneeAddress(consigneeFullAddress);
  }, []);

  useEffect(() => {
    setIsEquipmentTypeLoading(true);
    getLoadLinkVehicleType()
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setEquipmentTypeOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        console.log('Equipment Type Error ', e);
      })
      .finally(() => {
        setIsEquipmentTypeLoading(false);
      });
  }, []);

  const handleEdit = (e: any) => {
    if (
      e?.key === 'Enter' ||
      action?.data?.name === 'primaryEquipmentType' ||
      action?.data?.name === 'pickupDate'
    ) {
      let params: any = {};

      if (
        e?.target?.value !== '' ||
        (action?.data?.name === 'primaryEquipmentType' && e?.value !== '')
      ) {
        if (action?.data?.name === 'primaryEquipmentType') {
          params = {
            [action?.data?.name]: e?.value,
            primaryEquipmentTypeFullName: e.label,
          };
        } else if (action?.data?.name === 'pickupDate') {
          params = {
            [action?.data?.name]: moment(e?.value)
              .utc()
              .format(`YYYY-MM-DD HH:mm:ss`),
          };
        } else {
          params = {
            [action?.data?.name]: action?.data?.value,
          };
        }

        updateQuickQuote(data?.id, params).then((response: any) => {
          if (response) {
            setAction(initAction);
            setError(initError);
            setQuoteDetailData([response?.data]);

            if (
              action.data.name === 'pickupDate' ||
              action.data.name === 'consigneeAddress' ||
              action.data.name === 'shipperAddress'
            ) {
              // setIsGetRate(true);

              handleRateGathering();
              sortActiveCarrierRates();
            }
            if (action.data.name === 'totalWeight')
              setUpdatedTotalWeigth(response?.data?.totalWeight);
          }
        });
      } else {
        setError({
          type: action?.data?.name,
          message: `${action?.data?.name} is required`,
        });
      }
    }
  };

  const handleInputChange = (e: any) => {
    setAction((old: any) => ({
      ...old,
      ...{
        data: {
          name: e?.target?.name,
          value: e?.target?.value,
        },
      },
    }));
  };

  const handleClickOutside = (event: any) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      !event?.target?.classList.contains('react-datepicker__day') &&
      action.name !== 'shipperAddress' &&
      action.name !== 'consigneeAddress'
    ) {
      console.log('setAction123');
      setAction(initAction);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [action]);

  const checkPickupDate = (value: any) => {
    const pickupDateObj = new Date(value);
    const currentDateTime = new Date();
    pickupDateObj.setHours(
      currentDateTime.getHours(),
      currentDateTime.getMinutes(),
      currentDateTime.getSeconds(),
      currentDateTime.getMilliseconds()
    );

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Start of the day

    const onePM = new Date();
    onePM.setHours(13, 0, 0, 0); // 1 PM today

    const isSameDay = pickupDateObj.toDateString() === today.toDateString();
    const isAfterOnePM = pickupDateObj.getTime() > onePM.getTime();

    if (isSameDay && isAfterOnePM) {
      setPickup1pmError(true);
      const tomorrow = moment().add(1, 'days');
      const dayName = tomorrow.format('dddd');
      setTomorrowDayName(dayName);
    } else {
      setPickup1pmError(false);
    }
  };

  const handleDateChange = (event: any) => {
    const { name, value } = event;

    setAction((old: any) => ({
      ...old,
      ...{
        data: {
          name: name,
          value: value,
        },
      },
    }));

    setPickupDate(new Date(value));
    setTimeout(() => {
      handleEdit(event);
    }, 1000);
    console.log('pickup1pmError', pickup1pmError);
    // setFormData((old: any) => ({ ...old, [name]: value }));
    // localStorage.removeItem('isPickupDateChanged');
    checkPickupDate(new Date(value));
  };

  const selectNextDay = (val: boolean) => {
    if (val) {
      let tomorrow: any;

      if (tomorrowDayName === 'Saturday') {
        tomorrow = moment().utc().add(3, 'days');
        setPickupDate(tomorrow);
      } else if (tomorrowDayName === 'Sunday') {
        tomorrow = moment().utc().add(2, 'days');
        setPickupDate(tomorrow);
      } else {
        tomorrow = moment().utc().add(1, 'days');
        setPickupDate(tomorrow);
      }
      setTimeout(() => {
        handleEdit({
          target: { name: 'pickupDate', value: tomorrow.toDate() },
          value: tomorrow.toDate(),
          key: 'Enter',
        });
        // handleEdit(event);
      }, 1000);
      localStorage.removeItem('isPickupDateChanged');
    }
    setPickup1pmError(false);
  };

  return (
    <>
      <td className="px-5 py-4 min-w-[18%] max-w-[1px] w-[18%]">
        <div className="flex items-center gap-3">
          {/* <img
          src={data.image ? data.imageUrl + data.image : profile1}
          className="table-profile-icn-circle-xl"
          alt="Profile"
        /> */}
          {data.image ? (
            <img
              src={`${data.imageUrl}${data.image}`}
              className="table-profile-icn-circle-xl"
              alt={data.name}
              title={data.name}
            />
          ) : (
            <div className="table-profile-user-circle-xl">
              {getShortName(`${data.firstName} ${data.lastName}`)}
            </div>
          )}
          <div className="truncate">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              {data.firstName} {data.lastName}
            </h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              #{data.code ?? ''}
            </p>
          </div>
        </div>
      </td>
      {/* <td className="px-5 py-4 min-w-[16%] max-w-[1px] w-[16%]">
      {data.classification === 'ltl' && (
        <BadgeCmp style="modern" type="success">
          LTL
        </BadgeCmp>
      )}
      {data.classification === 'ftl' && (
        <BadgeCmp style="modern" type="lightblue">
          FTL
        </BadgeCmp>
      )}
      {data.classification === 'volume_ltl' && (
        <BadgeCmp style="modern" type="pink">
          Volume LTL
        </BadgeCmp>
      )}
    </td> */}
      {data.status === STATUS.WON && (
        <td className="px-5 py-4 min-w-[16%] max-w-[1px] w-[16%]">
          <Link
            to={`${ROSE_ROCKET.QUOTE_URL}${data.rossRocketId}`}
            className="underline text-primary"
            target="_blank"
            // onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
            //   e.preventDefault()
            // }
          >
            #{data.rossRocketPublicId}
          </Link>
        </td>
      )}
      <td className="px-5 py-4 min-w-[16%] max-w-[1px] w-[16%]">
        <TooltipCmp
          message={data?.customerName?.length > 20 ? data.customerName : null}
          parentClassName="max-w-90"
        >
          <p className="truncate ">{data.customerName}</p>
        </TooltipCmp>
      </td>
      <td className="px-5 py-4 min-w-[20%] max-w-[1px] w-[20%] group">
        <TooltipCmp
          message={data.shipperFullAddress}
          parentClassName="max-w-90"
        >
          <div className="flex items-center gap-1">
            <p className="flex truncate max-w-[80%]">
              {/* <span className="text-gray500 font-normal">From:&nbsp;</span> */}
              <span className="font-medium truncate">
                {data.shipperFullAddress}
              </span>
            </p>
            <Edit01
              className="w-3.5 h-4 cursor-pointer group-hover:opacity-100 opacity-0"
              onClick={(e: any) => {
                e.stopPropagation();
                console.log('shipperAddress Edit', shipperAddress);
                setAction((old: any) => ({
                  ...old,
                  ...{
                    isEdit: true,
                    mode: 'shipperAddress',
                    data: {
                      name: 'shipperAddress',
                      value: data?.shipperAddress,
                    },
                    fullAddress: shipperAddress,
                  },
                }));
              }}
            />
          </div>
        </TooltipCmp>
      </td>
      <td className="px-5 py-4 min-w-[20%] max-w-[1px] w-[20%] group">
        <TooltipCmp
          message={data.consigneeFullAddress}
          parentClassName="max-w-90"
        >
          <div className="flex gap-1">
            <p className="flex truncate max-w-[80%]">
              {/* <span className="text-gray500 font-normal">To:&nbsp;</span> */}
              <span className="font-medium truncate">
                {data.consigneeFullAddress}
              </span>
            </p>
            <Edit01
              className="w-3.5 h-4 cursor-pointer group-hover:opacity-100 opacity-0"
              onClick={(e: any) => {
                e.stopPropagation();
                handleAcion('consigneeAddress', consigneeAddress);
                setAction((old: any) => ({
                  ...old,
                  ...{
                    isEdit: true,
                    mode: 'consigneeAddress',
                    data: {
                      name: 'consigneeAddress',
                      value: data?.consigneeAddress,
                    },
                    fullAddress: consigneeAddress,
                  },
                }));
              }}
            />
          </div>
        </TooltipCmp>
      </td>
      <td className="px-5 py-3 min-w-[268px] max-w-[268px] w-[268px] group">
        {action.isEdit && action.mode === 'pickupDate' ? (
          <div ref={inputRef}>
            <p className={`form_label mb-0 w-max flex items-center`}>
              {pickup1pmError && (
                <>
                  <span className="text-xs text-primary font-normal flex items-center">
                    {tomorrowDayName === 'Saturday'
                      ? `Set to Monday`
                      : 'Set to Tomorrow'}
                  </span>
                  <a
                    onClick={() => selectNextDay(true)}
                    className="text-xs cursor-pointer underline decoration-1 ml-1 mr-1 text-primary700 font-semibold"
                  >
                    Yes
                  </a>
                  &nbsp;
                  {'/'}
                  <a
                    onClick={() => selectNextDay(false)}
                    className="text-xs cursor-pointer underline decoration-1 ml-1 text-primary700 font-semibold"
                  >
                    No
                  </a>
                  <TooltipCmp message={'Pickup is unlikely after 1pm.'}>
                    <InfoCircle className="w-3.5 h-3.5 text-textSecondary ml-1 cursor-pointer" />
                  </TooltipCmp>
                </>
              )}
            </p>
            <DateSelect
              inputName="pickupDate"
              className={`form_control`}
              placeholder={DATE_FORMAT}
              dateFormat="dd/MM/yyyy"
              minDate={moment().toDate()}
              selected={pickupDate ? moment(pickupDate).toDate() : null}
              value={getDateWithSuffixFormat(
                getFormattedPickupDate(pickupDate)
              )}
              errorText={''}
              onChangeFunc={(e: any) => {
                handleDateChange(e);
              }}
              // handleKeyDown={handleEdit} // Listen for Enter key
              icon={<Calendar className="h-5 w-5" />}
              parentClassName="w-full one-month-datepicker"
            />
          </div>
        ) : (
          <div className="flex gap-1">
            <span
              id="pickupDate"
              dangerouslySetInnerHTML={{
                __html: data.pickupDate
                  ? getDateWithSuffixFormat(
                      moment.utc(data.pickupDate).format('MMM Do - YYYY')
                    )
                  : '-',
              }}
            />
            <Edit01
              className="w-4 h-4 cursor-pointer group-hover:opacity-100 opacity-0"
              onClick={(e: any) => {
                e.stopPropagation();
                handleAcion('pickupDate', data?.pickupDate);
              }}
            />
          </div>
        )}
      </td>
      <td className="px-5 py-4 min-w-[145px] max-w-[145px] w-[145px] group">
        {action.isEdit && action.mode === 'totalWeight' ? (
          <div ref={inputRef}>
            <InputText
              inputName="totalWeight"
              placeholder="Total Weight"
              onChangeFunc={handleInputChange}
              value={action?.data?.value}
              handleKeyDown={handleEdit}
              errorText={error?.type === 'totalWeight' ? error?.message : ''}
              className="placeholder:text-gray500 disabled:text-gray500 text-grayLight900 disabled:bg-gray50 pl-0 !p-0 m-0 !text-xs !font-medium !shadow-none border-0 bg-transparent !h-auto "
              labelClassName="block mb-1.5"
            />
          </div>
        ) : (
          <div className="flex gap-1">
            {updatedTotalWeigth > 0 ? updatedTotalWeigth : 0}{' '}
            {dimensions.length > 0
              ? getLabelByValue(dimensions[0].weightMeasurement)?.toLowerCase()
              : 'lbs'}
            {data?.classification !== 'ltl' ? (
              <Edit01
                className="w-3.5 h-4 cursor-pointer group-hover:opacity-100 opacity-0"
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleAcion('totalWeight', data?.totalWeight);
                }}
              />
            ) : (
              ''
            )}
          </div>
        )}
      </td>
      <td className="px-5 py-4 min-w-[140px] max-w-[140px] w-[140px] group">
        {action.isEdit && action.mode === 'linearFootage' ? (
          <div ref={inputRef}>
            <InputText
              inputName="linearFootage"
              placeholder="Linear Footage"
              onChangeFunc={handleInputChange}
              value={action?.data?.value}
              handleKeyDown={handleEdit}
              errorText={error?.type === 'linearFootage' ? error?.message : ''}
              className="placeholder:text-gray500 disabled:text-gray500 text-grayLight900 disabled:bg-gray50 pl-0 !p-0 m-0 !text-xs !font-medium !shadow-none border-0 bg-transparent !h-auto"
              labelClassName="block mb-1.5"
            />
          </div>
        ) : (
          <div className="flex gap-1">
            {`${data?.linearFootage > 0 ? `${data?.linearFootage}Ft.` : '-'}`}
            <Edit01
              className="w-3.5 h-4 cursor-pointer group-hover:opacity-100 opacity-0"
              onClick={(e: any) => {
                e.stopPropagation();
                handleAcion('linearFootage', data?.linearFootage);
              }}
            />
          </div>
        )}
      </td>
      <td className="px-5 py-3 min-w-[166px] max-w-[166px] w-[166px] group">
        {action.isEdit && action.mode === 'primaryEquipmentType' ? (
          <div ref={inputRef} onClick={(e: any) => e.stopPropagation()}>
            <SelectBox
              parentClassName="min-w-[166px]"
              className="!shadow-none border-0 placeholder:text-gray500 disabled:text-gray500 text-grayLight900 disabled:bg-gray50 pl-0 !pt-0 m-0 !text-sm !font-medium"
              isClearable
              required
              name="primaryEquipmentType"
              placeholder="Select eqpt. type"
              options={equipmentTypeOptions}
              value={equipmentTypeOptions.find(
                (item: any) => equipmentType === item.value
              )}
              isMultipleSelection={false}
              onChangeFunc={(e: any) => {
                setEquipmentType(e?.value);
                handleEdit(e);
              }}
              isLoading={isEquipmentTypeLoading}
              // onChangeFunc={(event: any) => {
              //   setValidForm((old) => ({
              //     ...old,
              //     primaryEquipmentType: true,
              //   }));
              //   const secondaryEquipmentTypesWithOutPrimary =
              //     formData?.secondaryEquipmentTypes?.filter(
              //       (set: any) => set !== event?.value
              //     );
              //   const secondaryEquipmentTypeFullNamesWithOutPrimary =
              //     formData?.secondaryEquipmentTypeFullNames?.filter(
              //       (setf: any) => setf !== event?.label
              //     );
              //   setFormData((old) => ({
              //     ...old,
              //     primaryEquipmentType: event?.value,
              //     primaryEquipmentTypeFullName: event?.label,
              //     secondaryEquipmentTypes:
              //       secondaryEquipmentTypesWithOutPrimary,
              //     secondaryEquipmentTypeFullNames:
              //       secondaryEquipmentTypeFullNamesWithOutPrimary,
              //   }));
              // }}
              errorText={
                error?.type === 'primaryEquipmentType' ? error?.message : ''
              }
            />
          </div>
        ) : (
          <div className="flex gap-1">
            {`${data?.primaryEquipmentTypeFullName}`}
            <Edit01
              className="w-3.5 h-4 cursor-pointer group-hover:opacity-100 opacity-0"
              onClick={(e: any) => {
                e.stopPropagation();
                handleAcion('primaryEquipmentType', data?.primaryEquipmentType);
              }}
            />
          </div>
        )}
      </td>
      {/* <td className="px-5 py-4 min-w-[14%] max-w-[14%] w-[14%]">
      {totalUnit ?? 0}
    </td> */}
      <td className="px-5 py-4 min-w-[65px] w-[65px] max-w-[65px]">
        {addressDetails?.classification !== QUOTE_CLASSIFICATION.FTL &&
          dimensions.length > 0 && (
            <div
              className="flex w-fit rounded border border-borderPrimary shadow-xs p-[3px]"
              onClick={() =>
                setIsDisplayDimensionsDetail(!isDisplayDimensionsDetail)
              }
            >
              {isDisplayDimensionsDetail ? (
                <ChevronUp className="w-4 h-4" />
              ) : (
                <ChevronDown className="w-4 h-4" />
              )}
            </div>
          )}
      </td>
    </>
  );
};
export default QuoteDetailTableRow;
