import { yupResolver } from '@hookform/resolvers/yup';
import {
  PhoneCall01,
  SearchLg,
  UserPlus01,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MakeACall from 'src/app/Twilio/CallLogs/MakeACall';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { PATH } from 'src/constants/path';
import { AuthContext } from 'src/context/AuthContext';
import { BasicContext } from 'src/context/BasicContext';
import {
  setCallDuration,
  setConferenceId,
  setConferenceName,
  setErrorMessage,
  setHoldStatus,
  setIsCallInProgress,
  setIsMuted,
  setIsRecording,
  setOnHold,
  setOutgoingCall,
  setOutgoingCallDetails,
  setParticipants,
} from 'src/redux/CallCenter.slice';
import {
  setIsSocketConnected,
  setSocketIo,
} from 'src/redux/SocketConnection.slice';
import { RootState } from 'src/redux/store';
import { ContactList } from 'src/services/CallCenterService';
import { listCarrier } from 'src/services/CarrierService';
import { CustomersAllList } from 'src/services/CustomerService';
import { addCallLog } from 'src/services/TwilioService';
import { fetchJsFromCDN } from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import ContactLoaderRow from './ContactLoaderRow';
import ContactModal from './ContactModal';
import ContactRow from './ContactRow';
import DeleteContactModal from './DeleteContactModal';

type CallParams = Record<string, string>;

type ActionType = {
  mode: string | null;
  data: any;
};

const initParams: any = {
  search: '',
  sortType: 'desc',
  page: 1,
  limit: 50,
  type: '',
  account: null,
};

const carrierParams: any = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 100,
  type: 'all',
  provider: '',
};

const initAction = {
  mode: '',
  data: {},
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const Contacts = () => {
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);
  const { isMakeCall, setIsMakeCall } = useContext(BasicContext);
  const { device, token, outgoingCall } = useSelector(
    (state: RootState) => state.CallCenter
  );

  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  const [contacts, setContacts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const [action, setAction] = useState<any>(initAction);
  const [contactParams, setContactParams] = useState<any>(initParams);
  const [accountOptions, setAccountOptions] = useState<any[]>([]);
  const [customerList, setCustomerList] = useState<any>([]);
  const [carrierList, setCarrierList] = useState<any>([]);
  const [selectedAccountType, setSelectedAccountType] = useState<string>('');
  const [newContactDialAction, setNewContactDialAction] = useState<ActionType>({
    mode: '',
    data: {},
  });
  // const [newContactData, setNewContactData] = useState<any>(null);
  const navigate = useNavigate();
  const accountType = [
    { label: 'Carrier', value: 'carrier' },
    { label: 'Customer', value: 'customer' },
  ];

  const validationSchema = yup.object().shape({
    accountType: yup.string().required('Account type is required'),
    account: yup.string().required('Account is required'),
  });

  const { control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      accountType: '',
      account: '',
    },
  });

  const getContactData = (signal?: any) => {
    setContacts([]);
    setIsLoading(true);

    ContactList(contactParams, signal)
      .then((response: any) => {
        if (response.data) {
          setContacts(response.data);
        }

        setTotal(response.total ?? 0);

        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }

        setContacts([]);
        setTotal(0);
        setIsLoading(false);
      });
  };

  const handleAccountTypeChange = (accountT: string) => {
    if (accountT === 'carrier') {
      setAccountOptions(
        carrierList.map((carrier: any) => ({
          label: carrier.name,
          value: carrier.id,
        }))
      );
    } else if (accountT === 'customer') {
      setAccountOptions(
        customerList.map((customer: any) => ({
          label: customer.name,
          value: customer.id,
        }))
      );
    } else {
      setAccountOptions([]);
    }
  };

  const handleModalClose = (resetAction?: any) => {
    if (resetAction?.mode === 'addNewContactDial') {
      setNewContactDialAction({
        mode: 'newContactDial',
        data: resetAction?.data,
      });
      setIsMakeCall(true);
    }

    if (resetAction) {
      setAction({ mode: null, data: null });
    } else {
      setAction({ mode: '', data: {} });
    }
  };

  const getCustomerData = () => {
    setCustomerList([]);
    setIsLoading(true);

    CustomersAllList()
      .then((response: any) => {
        if (response.data) {
          setCustomerList(response.data);
        }
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }

        setIsLoading(false);
      });
  };

  const getCarrierData = (signal?: any) => {
    setCarrierList([]);
    setIsLoading(true);

    listCarrier(carrierParams, signal)
      .then((response: any) => {
        if (response.data) {
          setCarrierList(response.data);
        }
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }

        setIsLoading(false);
      });
  };

  const handleOutsideClick = () => {
    setIsMakeCall(false);
  };

  useEffect(() => {
    getCustomerData();

    const carrierController = new AbortController();
    const carrierSignal = carrierController.signal;
    getCarrierData(carrierSignal);

    return () => {
      carrierController.abort();
    };
  }, []);

  useEffect(() => {
    setNewContactDialAction({ mode: '', data: {} });
  }, [isMakeCall]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getContactData(signal);

    return () => {
      controller.abort();
    };
  }, [contactParams]);

  useEffect(() => {
    if (action.mode === null && action.data === null) {
      const controller = new AbortController();
      const signal = controller.signal;
      getContactData(signal);

      return () => {
        controller.abort();
      };
    }
  }, [action]);

  useEffect(() => {
    if (!selectedAccountType) {
      setAccountOptions([]);
      setContactParams((prev: any) => ({
        ...prev,
        account: null,
        page: 1,
      }));
    }
  }, [selectedAccountType]);

  // handle outgoing call
  const handleCall = async (contactData: any, scheduleNumber: string) => {
    console.log('⭐️ ~ handleCall ~ scheduleNumber:', scheduleNumber);
    console.log('1. ⭐️ ~ handleCall ~ data:', contactData);

    try {
      console.log('2. in ountgoing call');
      console.log('3. ⭐️ ~ handleCall ~ token:', token);
      console.log('4. ⭐️ ~ handleCall ~ device:', device);

      // if (!token || (!phoneNumber && !scheduleNumber)) {
      //   throw new Error('Invalid token or phone number');
      // }

      if (!device) {
        throw new Error('Device is not initialized');
      }

      const params: CallParams = {
        // To: scheduleNumber ? scheduleNumber : countryCode + phoneNumber,
        // To: scheduleNumber ? scheduleNumber : '+919427420518',
        To: contactData?.number,
        From: currentUser?.phone,
        intent: 'call-out',
      };
      console.log('5. ⭐️ ~ handleCall ~ params:', params);

      const outBound = await device.connect({ params });
      console.log('6. ⭐️ ~ handleCall ~ outBound:', outBound);

      dispatch(
        setOutgoingCallDetails({
          name: contactData.name,
          companyName: contactData.customerName || contactData.carrierName,
          role: contactData.role,
          image: contactData.imageUrl + contactData.image,
          number: contactData.number,
          carrierId: contactData.carrierId,
          customerId: contactData.customerId,
        })
      );

      dispatch(setOutgoingCall(outBound));

      console.log(
        '7. ⭐️ outgoingCall?.parameters?.CallSid:',
        outgoingCall?.parameters?.CallSid
      );

      setTimeout(async () => {
        await addCallLog({
          contactId: contactData?.id,
          fromUserId: currentUser?.userId,
          fromPhoneNumber: currentUser?.phone,
          toPhoneNumber: contactData.number,
          callType: 'outbound',
          status: 'started',
        });
        console.log('7. ⭐️ ~ setTimeout ~ callInstance:', outgoingCall);
      }, 1000);

      // if (scheduleNumber) {
      //   setIsScheduleCall(false);
      //   setScheduleCallTime('');
      //   setIsScheduleCallNumber('');
      // }
      // dispatch(setIsCallInProgress(true));

      if (isSocketConnected && !!socketIO?.on) {
        console.log('socket is connected');

        socketIO.removeAllListeners();
        socketIO.on('conferenceCreated', function (conference: any) {
          console.log('⭐️ ~ conference:', conference);
          dispatch(setConferenceName(conference?.conferenceName));
          dispatch(setConferenceId(conference?.conferenceSid));
          dispatch(setParticipants(conference?.participants));
          dispatch(setIsCallInProgress(true));
        });
      } else {
        console.log('socket is not connected');
        fetchJsFromCDN(
          'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
          ['io']
        )
          .then(([io]: any) => {
            io.sails.url = window.SERVER_URL;

            io.socket.on('connect', function socketConnected() {
              dispatch(setIsSocketConnected(true));
              dispatch(setSocketIo(io.socket));

              io.socket.get(
                `/subscribe/globalRoom`,
                function (data: any, jwr: any) {
                  if (jwr.error) {
                    return;
                  }
                }
              );
            });

            // Listen for ConferenceCreated event
            io.socket.on('conferenceCreated', (conference: any) => {
              console.log('⭐️ ~ conference:', conference);

              dispatch(setConferenceName(conference?.conferenceName));
              dispatch(setConferenceId(conference?.conferenceSid));
              dispatch(setParticipants(conference?.participants));
              dispatch(setIsCallInProgress(true));
            });

            // Clean up on component unmount
            return () => {
              io.socket.off('conferenceCreated');
            };
          })
          .catch((error) => {
            console.error('Failed to load Sails socket library:', error);
          });
      }

      outgoingCall?.on('disconnect', (e: any) => {
        console.log('Call disconnected', e, outgoingCall);
        dispatch(setIsCallInProgress(false));
        dispatch(setCallDuration(0));
        dispatch(setOnHold(false));
        dispatch(setIsMuted(false));
        dispatch(setHoldStatus(false));
        dispatch(setIsRecording(true));
        dispatch(setOutgoingCall(null));
      });
    } catch (err) {
      setErrorMessage(
        err instanceof Error ? err.message : 'An unknown error occurred'
      );
      console.error('Error making call:', err);
    }
  };

  const headCells = [
    { id: 'person_name', name: 'Contact Name', sortable: true },
    { id: 'account_name', name: 'Account Name', sortable: true },
    { id: 'role', name: 'Role', sortable: true },
    { id: 'email', name: 'Email', sortable: true },
    { id: 'action', name: '', sortable: true },
  ];

  const handleEditContact = (contactData: any) => {
    setAction({
      mode: 'editContact',
      data: contactData,
    });
  };

  const handleDeleteContact = (contactData: any) => {
    setAction({
      mode: 'deleteContact',
      data: contactData,
    });
  };

  const handleRowClick = (rowData: any) => {
    const contactId = rowData.id;
    navigate(`${PATH.CALL_HISTORY}/${contactId}`);
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title={'Contacts'}
            desc={`View, add, and organize your customer contact information for seamless communication.`}
            rightClassName="sm:w-auto"
            rightSideContent={
              <div className="relative">
                <ButtonCmp
                  className="btn-outline-primary"
                  onClick={() => {
                    setIsMakeCall(true);
                  }}
                >
                  <PhoneCall01 className="text-primary w-4 h-4" />
                  Make a Call
                </ButtonCmp>
                {isMakeCall && (
                  <MakeACall
                    onOutsideClick={handleOutsideClick}
                    setIsMakeCall={setIsMakeCall}
                    setAction={setAction}
                    action={action}
                    newContactDial={newContactDialAction}
                  />
                )}
              </div>
            }
          />
        }
      >
        <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
          <div className="table-top-header">
            <div className="table-left-wrap">
              <div className="table-title-wrap">
                <h5 className="table-title">All Contacts</h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {total} {total <= 1 ? 'Contact' : 'Contacts'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">Complete list of saved contacts.</p>
            </div>
            <ButtonCmp
              className={'btn-outline-primary'}
              icon={<UserPlus01 className="w-4 h-4" />}
              onClick={() =>
                setAction((old: any) => ({
                  ...old,
                  mode: 'addNewContact',
                }))
              }
            >
              Add New Contact
            </ButtonCmp>
          </div>
          <div className="table-bottom-header">
            <div className="table-header-bottom-left">
              <InputText
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={contactParams.search}
                inputType="text"
                isClearable={true}
                onChangeFunc={(e) => {
                  setContactParams((old: any) => ({
                    ...old,
                    ...{ search: e.target.value, page: 1 },
                  }));
                }}
                parentClassName="table-searchInput"
              />
            </div>
            <div className="table-selectbox">
              <Controller
                name="accountType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectBox
                    name="accountType"
                    id="accountType"
                    className="form_control"
                    size="sm"
                    placeholder="Select account type"
                    isClearable={true}
                    options={accountType}
                    onChangeFunc={(e: any) => {
                      onChange(e?.value ?? '');
                      handleAccountTypeChange(e?.value ?? '');
                      setSelectedAccountType(e?.value ?? '');

                      if (!e?.value) {
                        setValue('account', '');
                      }
                    }}
                    value={accountType.find((val: any) => value == val?.value)}
                  />
                )}
              />
            </div>
            <div className="table-selectbox">
              <Controller
                name="account"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectBox
                    name="account"
                    id="account"
                    className="form_control"
                    size="sm"
                    placeholder="Select account"
                    isSearchable={true}
                    isClearable={true}
                    options={accountOptions}
                    onChangeFunc={(e: any) => {
                      onChange(e?.value ?? '');

                      setContactParams((prev: any) => ({
                        ...prev,
                        type: selectedAccountType,
                        account: e?.value ?? null,
                        page: 1,
                      }));
                    }}
                    value={
                      accountOptions.find((val: any) => value == val?.value) ||
                      ''
                    }
                  />
                )}
              />
            </div>
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setContactParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === contactParams.limit
                )}
              />
            </div>
          </div>
          <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col ">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={headCells}
                // params={params}
                // setParams={setParams}
                tableDataArr={contacts}
                TableRowCmp={ContactRow}
                tableRowCmpProps={{
                  handleCall: handleCall,
                  handleEditContact: handleEditContact,
                  handleDeleteContact: handleDeleteContact,
                  handleRowClick: handleRowClick,
                }}
                TableLoaderRowCmp={ContactLoaderRow}
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={15}
                tableHeaderClass=""
                isTableRowClickable={true}
              />
            </div>
            {!isLoading && !contacts.length && (
              <NotFoundUI
                title="No Contact Found"
                desc="There are no contact found."
                containerClassName="min-h-[unset] !h-[auto] flex-1"
              />
            )}
          </div>

          <CustomPagination
            recordsPerPage={contactParams.limit}
            totalRecords={total}
            currentPage={contactParams.page}
            handlePagination={(page: number) => {
              setContactParams((old: any) => ({ ...old, page }));
            }}
          />
        </div>

        {(action.mode === 'addNewContact' ||
          action.mode === 'editContact' ||
          action.mode === 'addNewContactDial') && (
          <ContactModal
            action={action}
            setAction={setAction}
            contactData={action?.data}
            handleClose={handleModalClose}
          />
        )}

        {action.mode === 'deleteContact' && (
          <DeleteContactModal
            setAction={setAction}
            contactData={action?.data}
            handleClose={(resetAction?: any) => {
              if (resetAction) {
                setAction({ mode: null, data: null });
              } else {
                setAction({ mode: '', data: {} });
              }
            }}
          />
        )}
      </PageSectionLayout>
    </>
  );
};

export default Contacts;
