import {
  Phone,
  PhoneCall02,
  PhonePause,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import ButtonCmp from 'src/components/ButtonCmp';
import {
  setCallDuration,
  setConferenceId,
  setConferenceName,
  setFromNumberContact,
  setHoldStatus,
  setIncomingCall,
  setIsCallInProgress,
  setIsMuted,
  setIsRecording,
  setOnHold,
  setParticipants,
} from 'src/redux/CallCenter.slice';
import {
  setIsSocketConnected,
  setSocketIo,
} from 'src/redux/SocketConnection.slice';
import { RootState } from 'src/redux/store';
import { fetchJsFromCDN, getShortName } from 'src/utils/CommonFunctions';

import phonePlay from '../../assets/img/phone-play.png';
import Profile from '../../assets/img/profile.png';
import starWhite from '../../assets/img/star-white.svg';

const IncomingCallModal = () => {
  const [isSingleCall, setIsSingleCall] = useState(true);
  console.log('⭐️ ~ IncomingCallModal ~ setIsSingleCall:', setIsSingleCall);
  console.log('4. Incoming call modal');

  const dispatch = useDispatch();
  const { incomingCall, fromNumberContact } = useSelector(
    (state: RootState) => state.CallCenter
  );

  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  // Socket Event listen
  useEffect(() => {
    console.log('5. socket event listen');

    if (isSocketConnected && !!socketIO?.on) {
      socketIO.removeAllListeners();

      // Classify Caller
      socketIO.on('classifyCaller', function (contact: any) {
        console.log('6. ~ classifyCaller:', contact);
        dispatch(setFromNumberContact(contact));
      });

      // Conference Created For Incoming Call
      socketIO.on('conferenceCreated', (conference: any) => {
        console.log('9.~ conference:', conference);
        dispatch(setConferenceName(conference?.conferenceName));
        dispatch(setConferenceId(conference?.conferenceSid));
        dispatch(setParticipants(conference?.participants));
      });

      // Disconnect Call ( caller cuts call )
      socketIO.on('disconnectCall', () => {
        dispatch(setIsCallInProgress(false));
        dispatch(setCallDuration(0));
        dispatch(setIncomingCall(null));
        dispatch(setOnHold(false));
        dispatch(setIsMuted(false));
        dispatch(setHoldStatus(false));
        dispatch(setIsRecording(false));
      });
    } else {
      fetchJsFromCDN(
        'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
        ['io']
      )
        .then(([io]: any) => {
          io.sails.url = window.SERVER_URL;

          io.socket.on('connect', function socketConnected() {
            dispatch(setIsSocketConnected(true));
            dispatch(setSocketIo(io.socket));

            io.socket.get(
              `/subscribe/globalRoom`,
              function (data: any, jwr: any) {
                if (jwr.error) {
                  return;
                }
              }
            );
          });

          // Classify Caller
          io.socket.on('classifyCaller', (contact: any) => {
            console.log('🚀 ~ contact:', contact);
            dispatch(setFromNumberContact(contact));
          });

          // Conference Created For Incoming Call
          io.socket.on('conferenceCreated', (conference: any) => {
            dispatch(setConferenceName(conference?.conferenceName));
            dispatch(setConferenceId(conference?.conferenceSid));
            dispatch(setParticipants(conference?.participants));
            dispatch(setIsCallInProgress(true));
          });

          // Disconnect Call ( caller cuts call )
          io.socket.on('disconnectCall', () => {
            dispatch(setIsCallInProgress(false));
            dispatch(setCallDuration(0));
            dispatch(setIncomingCall(null));
            dispatch(setOnHold(false));
            dispatch(setIsMuted(false));
            dispatch(setHoldStatus(false));
            dispatch(setIsRecording(false));
          });

          // Clean up on component unmount
          return () => {
            io.socket.off('classifyCaller');
          };
        })
        .catch((error: any) => {
          console.error('Failed to load Sails socket library:', error);
        });
    }
  }, []);

  const acceptIncomingCall = () => {
    if (incomingCall) {
      console.log('6. Accepting call');
      console.log('🚀 7. acceptIncomingCall ~ incomingCall:', incomingCall);

      incomingCall.accept();
      dispatch(setIsCallInProgress(true));
      console.log('🚀 8. Call Accepted successfully');
    }
  };

  const rejectIncomingCall = () => {
    if (incomingCall) {
      incomingCall.reject();
      dispatch(setIncomingCall(null));
    }
  };

  return (
    <>
      {isSingleCall ? (
        <Draggable>
          <div className="floating-modal">
            <div className="floating-modal-header">
              <h6 className="floating-modal-header-title">Incoming call</h6>
            </div>
            <div className="floating-modal-body flex items-center flex-col gap-y-4">
              <div className="mt-2.5 empty:hidden">
                {fromNumberContact?.image ? (
                  <img
                    className="w-[100px] h-[100px] rounded-full border border-gray500 flex-none "
                    src={fromNumberContact?.image}
                    alt="Profile"
                  />
                ) : fromNumberContact?.name ? (
                  <div className="w-[100px] h-[100px] rounded-full border border-gray500 flex-none flex items-center justify-center uppercase bg-primary text-white text-sm">
                    {getShortName(fromNumberContact?.name)}
                  </div>
                ) : (
                  <img
                    className="w-[100px] h-[100px] rounded-full border border-gray500 flex-none"
                    src={Profile}
                    alt="Profile"
                  />
                )}
              </div>

              <div className="text-center truncate space-y-1 w-full">
                <h6 className="text-lg font-semibold text-white truncate">
                  {fromNumberContact?.name || fromNumberContact?.from}
                </h6>
                <div className="flex items-center justify-center gap-x-2">
                  <p className="text-xs leading-[1.5] font-normal text-borderPrimary truncate">
                    {fromNumberContact?.companyName || 'unknown'}
                  </p>
                  <div className="flex items-center gap-x-1 text-white text-[10px] font-normal leading-3 rounded flex-none">
                    <span>3.5</span>
                    <img src={starWhite} />
                  </div>
                </div>

                {/* <p className="text-sm font-normal text-borderPrimary truncate">
              {fromNumberContact?.from || 'unknown'}
            </p> */}
                {/* <p className="text-xs leading-[1.5] font-medium text-borderPrimary truncate">
              {fromNumberContact?.role || 'unknown'}
            </p> */}
              </div>

              <div className="flex items-center justify-center gap-2 flex-none w-full mt-2.5">
                <ButtonCmp
                  type="button"
                  className="btn-xs btn-success"
                  onClick={() => {
                    acceptIncomingCall();
                  }}
                  icon={<Phone className="w-4 h-4" />}
                >
                  <></>
                </ButtonCmp>
                <ButtonCmp
                  type="button"
                  className="btn-xs btn-gray"
                  onClick={() => {
                    acceptIncomingCall();
                  }}
                  icon={<PhonePause className="w-4 h-4" />}
                >
                  <></>
                </ButtonCmp>
                <ButtonCmp
                  type="button"
                  className="btn-xs btn-danger"
                  onClick={() => {
                    rejectIncomingCall();
                  }}
                  icon={<PhoneCall02 className="w-4 h-4" />}
                >
                  <></>
                </ButtonCmp>
              </div>
            </div>
          </div>
        </Draggable>
      ) : (
        <div className="floating-modal">
          <div className="floating-modal-header">
            <h6 className="floating-modal-header-title">Outbound</h6>
            <p className="flex-none">15:26</p>
          </div>

          <div className="floating-modal-body flex items-center gap-x-2.5">
            {fromNumberContact?.image ? (
              <img
                className="w-10 h-10 rounded-full border border-gray500 flex-none "
                src={fromNumberContact?.image}
                alt="Profile"
              />
            ) : fromNumberContact?.name ? (
              <div className="w-10 h-10 rounded-full border border-gray500 flex-none flex items-center justify-center uppercase bg-primary text-white text-sm">
                {getShortName(fromNumberContact?.name)}
              </div>
            ) : (
              <img
                className="w-10 h-10 rounded-full border border-gray500 flex-none"
                src={Profile}
                alt="Profile"
              />
            )}
            {/* IF NO IMAGE FOUND SHOW THIS BELOW CODE */}
            {/* <div className="w-10 h-10 rounded-full border border-gray500 flex-none flex items-center justify-center uppercase bg-primary text-white text-sm">
              MD
              </div> */}
            <div className="flex-1 truncate">
              <h6 className="text-base font-medium text-white truncate">
                {fromNumberContact?.name || fromNumberContact?.from} Name
              </h6>
              <p className="text-xs text-gray400 font-normal">Customer</p>
              <div className="flex items-center gap-x-2">
                <p className="text-xs leading-[1.5] font-normal text-borderPrimary truncate">
                  {fromNumberContact?.companyName || 'unknown'}
                </p>
                <div className="flex items-center gap-x-1 text-white text-[10px] font-normal leading-3 rounded flex-none">
                  <span>3.5</span>
                  <img src={starWhite} />
                </div>
              </div>
              {/* <p className="text-xs font-normal text-borderPrimary truncate">
                {outgoingCallDetails?.number}
              </p> */}
            </div>

            <div className="flex gap-2 flex-none">
              <ButtonCmp
                type="button"
                className="btn-xs btn-gray"
                onClick={() => {
                  acceptIncomingCall();
                }}
                icon={<PhonePause className="w-4 h-4" />}
              >
                <></>
              </ButtonCmp>
              <ButtonCmp
                type="button"
                className="btn-xs btn-success"
                onClick={() => {
                  acceptIncomingCall();
                }}
                icon={
                  <img src={phonePlay} className="w-4 h-4 object-contain" />
                }
              >
                <></>
              </ButtonCmp>
              <ButtonCmp
                type="button"
                className="btn-xs btn-success"
                onClick={() => {
                  acceptIncomingCall();
                }}
                icon={<Phone className="w-4 h-4" />}
              >
                <></>
              </ButtonCmp>
              <ButtonCmp
                type="button"
                className="btn-xs btn-danger"
                onClick={() => {
                  rejectIncomingCall();
                }}
                icon={<PhoneCall02 className="w-4 h-4" />}
              >
                <></>
              </ButtonCmp>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default IncomingCallModal;
