import moment from 'moment';
import React, { useState } from 'react';
import DateRangePicker from 'src/components/DateRangePicker';
import TabButton from 'src/components/TabButton';
import { useRolePermission } from 'src/utils/CommonFunctions';

export const FilterArray: any = [
  {
    value: 'daily',
    name: 'Today',
  },
  {
    value: 'weekly',
    name: 'This Week',
  },
  {
    value: 'monthly',
    name: 'This Month',
  },
];

const Analytics = () => {
  const [startDate, setStartDate] = useState<any>(() => '');
  const [endDate, setEndDate] = useState<any>(() => '');
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>(
    () => 'custom'
  );
  const [allTimeStartDate, setAllTimeStartDate] = useState<any>('');
  const [activeFilter, setActiveFilter] = useState(() => 'daily');
  const [isPrevYearDisable, setIsPrevYearDisable] = useState<any>(false);
  const [filterAfterPrevNext, setFilterAfterPrevNext] = useState('daily');

  const { hasRoleV2 } = useRolePermission();

  console.log(
    '⭐️ ~ Activity ~ setStartDate:',
    setStartDate,
    setEndDate,
    setSelectedTimeRange,
    setAllTimeStartDate,
    setFilterAfterPrevNext
  );

  const handleDatePickerChange = (date: any, timeRange?: string) => {
    const [start, end] = date;

    if (
      timeRange === 'weekly' ||
      timeRange === 'monthly' ||
      timeRange === 'yearly'
    ) {
      setActiveFilter(timeRange);
    } else if (
      moment(start).isSame(moment(), 'days') &&
      moment(end).isSame(moment(), 'days')
    ) {
      setActiveFilter('daily');
    } else {
      setActiveFilter('');

      const twoYearsAgo = moment().subtract(1, 'years').startOf('year');
      const daysDiff = moment(end).diff(moment(start), 'days') + 1;
      const prevMonth = moment(start).subtract(daysDiff, 'day');

      if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'days')) {
        setIsPrevYearDisable(true);
      } else {
        setIsPrevYearDisable(false);
      }

      // setIsNextYearDisable(false);
      // if (moment(end).format("DD-MM-YYYY") >= moment().format("DD-MM-YYYY")) {
      //     setIsNextYearDisable(true);
      // }

      setStartDate(start);
      setEndDate(end);

      if (!end) {
        setEndDate(start);
      }
    }

    if (timeRange) {
      setSelectedTimeRange(timeRange);
    }
  };

  const metrics = [
    { title: 'Completed Tasks', value: '500' },
    { title: 'Time on Call', value: '4hr 21min' },
    { title: 'Email Sent', value: '1600' },
    { title: 'Time Savings', value: '6hr 43min' },
    { title: 'Total Calls', value: '1423' },
    { title: 'Positive Calls', value: '1200' },
    { title: 'Negative Calls', value: '223' },
    { title: 'Total Rate Gather', value: '1280' },
  ];

  return (
    <div className="p-6 bg-white">
      {/* Filter Section */}
      <div className="flex justify-between mb-8">
        <div className="flex space-x-2">
          <TabButton
            className="leading-4 sm:w-auto w-1/4 flex-grow !px-1 xxl:!text-xs xl:!text-[11px] xls:!text-[10px] !text-[11px]"
            activeClassName="!bg-utilityGray100"
            tabArray={FilterArray}
            parentClassName="w-full"
            tabParentClassName="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)] sm:min-w-[calc(50%_-_6px)] min-w-full"
            isActive={filterAfterPrevNext !== 'custom' ? activeFilter : ''}
            handleOnClick={(e: any) => {
              // if (activeFilter === e.target.dataset.value) getFilterRange();
              setActiveFilter(e.target.dataset.value);
            }}
          />
        </div>
        <div>
          <DateRangePicker
            handleDatePickerChange={(dates: any, type?: string) =>
              handleDatePickerChange(dates, type)
            }
            selectedTimeRange={selectedTimeRange}
            startDate={startDate}
            endDate={endDate}
            allTimeStartDate={allTimeStartDate}
            showYearDropdown={false}
            isToday={activeFilter === 'daily'}
            isShowDropDownIcon={false}
            containerClassName="xls:left-0 xls:right-[unset] lg:left-[unset] lg:right-0 left-0"
            parentClassName="sales-datepicker"
            isPrevDisabled={isPrevYearDisable}
            // onPrevNextClick={(type: string) => getFilterRange(type)}
            setTodayAsDefaultOnClear={true}
          />
        </div>
      </div>

      {/* Metrics Grid */}
      <div className="grid grid-cols-4 gap-4">
        {metrics.map((metric, index) => (
          <div
            key={index}
            className="bg-white rounded-lg border border-gray-100 p-4 flex flex-col"
          >
            <span className="text-sm text-gray-500 mb-2">{metric.title}</span>
            <span className="text-lg font-semibold">{metric.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Analytics;
