import {
  Calendar,
  ChevronLeft,
  ChevronLeftDouble,
  ChevronRight,
  ChevronRightDouble,
  Star06,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import DatePicker from 'react-datepicker';
import ReactDOM from 'react-dom';

import ActionTooltip from '../ActionTooltip';
import ButtonCmp from '../ButtonCmp';
import ErrorMsg from '../errorMsg';

import { IDateSelectProps } from './dateSelect.interface';

const CustomDateInput = React.forwardRef(
  (
    { value, onClick, dateSelectClassName, isShowAiStar, placeholder }: any,
    ref: any
  ) => (
    <div
      className={`flex form_control text-grayLight900 font-medium  [&.disabled]:border-borderPrimary [&.disabled]:bg-gray50 [&.disabled]:text-gray500 [&.disabled>svg]:hidden [&.disabled]:opacity-100 ${
        isShowAiStar ? 'pl-[28px]' : ''
      } ${dateSelectClassName || ''}`}
      onClick={onClick}
      ref={ref}
    >
      {isShowAiStar && (
        <div className="absolute top-1/2 -translate-y-1/2 left-2 w-4 h-4 mt-px">
          <ActionTooltip
            message={'Detected with AI'}
            isOpenChildTopPosOnTop={true}
            tooltipPosition="center"
            parentClassName="min-w-[120px]"
            openOnHover={true}
          >
            <Star06 className="w-4 h-4 text-primary " />
          </ActionTooltip>
        </div>
      )}
      {/* Show placeholder when no value is selected */}
      <div
        dangerouslySetInnerHTML={{ __html: value || placeholder }}
        className={`${!value ? 'text-gray-400' : ''}`}
      />
    </div>
  )
);

CustomDateInput.displayName = 'CustomDateInput';

const renderCustomHeader = ({
  date,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: any) => {
  const decreaseYear = () => changeYear(date.getFullYear() - 1);
  const increaseYear = () => changeYear(date.getFullYear() + 1);

  return (
    <div className="my-3 mx-[23px] flex justify-between items-center">
      <div className="flex gap-x-1">
        <ButtonCmp
          type="button"
          className="btn_secondary_black !py-1 !px-1"
          onClick={decreaseYear}
        >
          <ChevronLeftDouble className="w-3.5 h-3.5" />
        </ButtonCmp>
        <ButtonCmp
          type="button"
          className="btn_secondary_black !py-1 !px-1"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <ChevronLeft className="w-3.5 h-3.5" />
        </ButtonCmp>
      </div>
      <span>
        {date.toLocaleString('default', { month: 'long' })} {date.getFullYear()}
      </span>
      <div className="flex gap-x-1">
        <ButtonCmp
          type="button"
          className="btn_secondary_black !py-1 !px-1"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <ChevronRight className="w-3.5 h-3.5" />
        </ButtonCmp>
        <ButtonCmp
          type="button"
          className="btn_secondary_black lg:!py-1 lg:!px-1"
          onClick={increaseYear}
        >
          <ChevronRightDouble className="w-3.5 h-3.5" />
        </ButtonCmp>
      </div>
    </div>
  );
};

const DateSelect = React.forwardRef(
  (
    {
      inputName = '',
      placeholder,
      className = '',
      labelClassName = '',
      parentClassName,
      calendarIconClass,
      errorText = null,
      required,
      label = '',
      tabIndex,
      isDisplayIcon = true,
      isShowCustomMonthYearHeader,
      dateSelectClassName,
      onChangeFunc,
      isShowAiStar,
      ...props
    }: IDateSelectProps,
    ref: any
  ) => {
    const onChangeDate = (
      date: Date | null,
      event: React.SyntheticEvent<any, Event> | undefined
    ) => {
      if (date) {
        date = new Date(date.toISOString());
      }
      let customEvent: any = event;
      customEvent = {
        event,
        ...{ value: date, id: props.id, name: inputName },
      };
      onChangeFunc(customEvent);
    };

    return (
      <div
        className={`${parentClassName} ${
          isShowAiStar ? 'ai-gradient-border-wrap' : ''
        }`}
      >
        <div className="flex">
          {label && (
            <label className={`form_label mb-1.5 ${labelClassName}`}>
              {label}
              {required && (
                <span className="text-red-600 leading-4 text-xs ml-1">*</span>
              )}
            </label>
          )}
        </div>
        <div
          className={`relative w-full cursor-pointer wal-single-date-picker`}
        >
          <DatePicker
            ref={ref}
            placeholderText={placeholder}
            popperContainer={({ children }) =>
              ReactDOM.createPortal(
                <div className="one-month-datepicker wal-single-date-picker">
                  {children}
                </div>,
                document.body
              )
            }
            // popperContainer={({ children }) =>
            //   ReactDOM.createPortal(children, document.body)
            // }
            onChange={onChangeDate}
            className={`${className} cursor-pointer`}
            tabIndex={tabIndex}
            showFourColumnMonthYearPicker={true}
            renderCustomHeader={
              isShowCustomMonthYearHeader ? renderCustomHeader : undefined
            }
            id={props.id}
            onKeyDown={(e) => {
              if (e?.keyCode === 9 || e?.which === 9) {
                ref?.current.setOpen(false);
              }
            }}
            fixedHeight={true}
            autoComplete="off"
            name={inputName}
            dropdownMode="select"
            showIcon={isDisplayIcon}
            icon={
              isDisplayIcon ? (
                <Calendar className="h-5 w-5 text-textSecondary" />
              ) : null
            }
            toggleCalendarOnIconClick
            calendarIconClassname={`icon absolute right-2 top-1/2 -translate-y-1/2 text-lg text-textSecondary ${calendarIconClass}`}
            customInput={
              <CustomDateInput
                value={props.selected}
                placeholder={placeholder} // <-- Pass placeholder
                dateSelectClassName={dateSelectClassName}
                isShowAiStar={isShowAiStar}
              />
            }
            popperPlacement="top-end"
            {...props}
          />
        </div>
        {errorText && <ErrorMsg errorText={errorText} />}
      </div>
    );
  }
);

export default DateSelect;
