import React from 'react';

interface IProps {
  label?: any;
  parentClassName?: any;
  statusLabelClassName?: string;
  dotColor?: 'primary' | 'red' | 'yellow' | 'green';
}

const StatusDotProfileCmp = ({
  label,
  parentClassName,
  dotColor,
  statusLabelClassName,
}: IProps) => (
  <div className={`flex gap-1.5 items-center ${parentClassName}`}>
    <div
      className={`w-3 h-3 rounded-full border-[3px] 
        ${dotColor === 'primary' ? 'bg-primary border-primary100' : ''}
        ${dotColor === 'red' ? 'bg-utilityRed400 border-utilityRed100' : ''}
        ${dotColor === 'yellow' ? 'bg-warning300 border-warning100' : ''}
        ${
          dotColor === 'green'
            ? 'bg-successSecondary border-utilitySuccess100'
            : ''
        }
      `}
    />
    <p
      className={`text-xs text-grayLight900 empty:hidden ${statusLabelClassName}`}
    >
      {label}
    </p>
  </div>
);

export default StatusDotProfileCmp;
