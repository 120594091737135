import { yupResolver } from '@hookform/resolvers/yup';
import { Plus, Trash04 } from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import DeleteModal from 'src/components/DeleteModal';
import InputText from 'src/components/InputText/InputText';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import { AuthContext } from 'src/context/AuthContext';
import { addTag, deleteTag, getTagList } from 'src/services/TagService';
import WalToast from 'src/utils/WalToast';
import * as Yup from 'yup';

import EmojiPicker from './EmojiPicker';

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  emoji: Yup.string().required('Emoji is required'),
});

const initAction = {
  mode: null,
  type: null,
  id: null,
};

const OrderSettings = () => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      emoji: '',
      name: '',
    },
  });

  const [showPicker, setShowPicker] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);
  const [tagList, setTagList] = useState<any[]>([]);
  const [action, setAction] = useState<any>(initAction);
  const [isTagDeleteLoading, setIsTagDeleteLoading] = useState(false);
  const [isTagCreateLoading, setIsTagCreateLoading] = useState(false);

  const { currentUser } = useContext(AuthContext);

  const getTagListData = () => {
    getTagList({})
      .then((response: any) => {
        if (response.data) {
          setTagList(response.data);
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    getTagListData();
  }, []);

  const handleEmojiSelect = (emoji: string) => {
    setSelectedEmoji(emoji);
    setShowPicker(false);
    setValue('emoji', emoji);
    clearErrors('emoji');
  };

  const onSubmit = (data: any) => {
    setIsTagCreateLoading(true);

    addTag(data)
      .then((response) => {
        if (response.data) {
          reset();
          setSelectedEmoji(null);
          setTagList((old: any) => [...old, response.data]);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsTagCreateLoading(false);
      });
  };

  const handleModalClose = useCallback(
    (closeStatus: boolean) => () => {
      setAction(initAction);

      if (closeStatus) {
        const idToDelete = action.id;

        if (idToDelete) {
          setIsTagDeleteLoading(true);

          deleteTag(idToDelete)
            .then((response) => {
              if (response.data) {
                setTagList((old: any) =>
                  old.filter((tag: any) => tag.id !== idToDelete)
                );

                setAction(initAction);
              }
            })
            .catch((e) => {
              WalToast.error('Something went wrong while deleting tag.');
              console.error(e);
            })
            .finally(() => {
              setIsTagDeleteLoading(false);
            });
        }
      }
    },
    [action]
  );

  return (
    <>
      <form
        className="h-full w-full px-2 py-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-full flex justify-between border-b border-utilityGray200 pb-4">
          <div>
            <h6 className="text-grayLight900 font-semibold text-sm">
              Order settings
            </h6>
            <p className="text-grayLight600 font-normal text-sm">
              Update your order settings here.
            </p>
          </div>
        </div>
        {currentUser?.role?.name === 'admin' && (
          <>
            <div className="border-b border-utilityGray200 pb-4 flex sm:gap-8 gap-2 sm:flex-row flex-col mt-4">
              <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
                Add Tag
              </label>
              <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
                <OutsideClickHandler
                  onOutsideClick={() => setShowPicker(false)}
                  containerClassName="relative"
                >
                  <ButtonCmp
                    onClick={() => setShowPicker(!showPicker)}
                    type="button"
                    className={`btn_secondary_black lg:px-[9px] px-2 ${
                      errors.emoji ? 'border-red-500' : ''
                    }`}
                  >
                    {selectedEmoji || <Plus className="text-primary w-4 h-4" />}
                  </ButtonCmp>
                  {showPicker && <EmojiPicker onSelect={handleEmojiSelect} />}
                </OutsideClickHandler>

                <Controller
                  name="name"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputText
                      inputName="name"
                      inputType="text"
                      placeholder="Enter Tag Name"
                      parentClassName="flex-1"
                      value={value}
                      onChangeFunc={onChange}
                      errorText={error ? error?.message : null}
                    />
                  )}
                />

                <ButtonCmp
                  className="btn-outline-primary self-start"
                  type="submit"
                  loading={isTagCreateLoading}
                  disabled={isTagCreateLoading}
                >
                  Add
                </ButtonCmp>
              </div>
            </div>
            {tagList.length > 0 && (
              <div className="border-b border-utilityGray200 pb-4 flex sm:gap-8 gap-2 sm:flex-row flex-col mt-4">
                <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
                  Tag List
                </label>
                <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
                  {tagList.map((tag: any) => (
                    <BadgeCmp
                      type="success"
                      style="modern"
                      isHidePillDot
                      key={tag.id}
                    >
                      <span className="mr-1">{tag.emoji}</span>
                      {tag.name}

                      <Trash04
                        className="ml-1 w-4 h-4 cursor-pointer"
                        onClick={() => {
                          setAction({
                            mode: 'delete',
                            type: 'tag',
                            id: tag.id,
                          });
                        }}
                      />
                    </BadgeCmp>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </form>

      {action.mode === 'delete' && action.type === 'tag' && (
        <DeleteModal
          handleClose={handleModalClose}
          moduleTitle={`Remove Tag`}
          isDeleteLoading={isTagDeleteLoading}
        />
      )}
    </>
  );
};

export default OrderSettings;
