import {
  Calendar,
  XClose,
  SwitchVertical01,
  InfoCircle,
  CurrencyDollar,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import InputText from 'src/components/InputText/InputText';
import Toggle from 'src/components/Toggle/Toggle';
import TooltipCmp from 'src/components/TooltipCmp';
import { BasicContext } from 'src/context/BasicContext';
import { getMapService } from 'src/services/IntegrationService';
import { getLoadLinkVehicleType } from 'src/services/LoadLinkService';

import ButtonCmp from '../../../../components/ButtonCmp';
import DateSelect from '../../../../components/DateSelect/DateSelect';
import DeleteModal from '../../../../components/DeleteModal';
import PageSectionLayout from '../../../../components/PageSectionLayout';
import Header from '../../../../components/PageSectionLayout/Header/Header';
import SelectBox from '../../../../components/SelectBox/SelectBox';
import StepBar from '../../../../components/StepBar/StepBar';
import {
  DATE_FORMAT,
  QUOTE_CLASSIFICATION,
  STATUS,
  TIME_FORMAT,
} from '../../../../constants/common';
import { PATH } from '../../../../constants/path';
import { ROUTES } from '../../../../constants/routes';
import { CustomersAllList } from '../../../../services/CustomerService';
import {
  createQuote,
  getQuote,
  updateQuote,
} from '../../../../services/QuoteService';
import {
  createShipper,
  deleteShippers,
  listShippers,
  updateShipper,
} from '../../../../services/ShipperService';
import {
  getDateWithSuffixFormat,
  getSelectBoxOptions,
} from '../../../../utils/CommonFunctions';
import WalToast from '../../../../utils/WalToast';
import ConsigneeAddress from '../../../QuotingHub/Common/ConsigneeAddress';
import ShipperAddress from '../../../QuotingHub/Common/ShipperAddress';
import QuoteEmailDetailView from '../QuoteEmailDetails/QuoteEmailDetailView';
import { STEPS } from '../SmartQuote.interface';

import Address from './Address';
import {
  classOptions,
  IFormData,
  IMapAddress,
  initAction,
  initConsigneeAddress,
  initFormData,
  initSelectQuoteFormData,
  initShipperAddress,
  initValidForm,
  scriptId,
} from './Address.interface';

export const initSelectFormData = {
  customerId: null,
  shipperId: null,
  consigneeId: null,
};

const currencyTypeOptions = [
  {
    label: 'USD',
    value: 'usd',
  },
  {
    label: 'CAD',
    value: 'cad',
  },
];

// eslint-disable-next-line
const AddressDetailsForm = () => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const [isEquipmentTypeLoading, setIsEquipmentTypeLoading] = useState(true);

  const { setMapService, googleKey, setGoogleKey, setTrimbleKey } =
    useContext(BasicContext);
  const [customers, setCustomers] = useState<any>([]);
  const [formData, setFormData] = useState<IFormData>({
    ...initFormData,
    linearFootage: null,
    totalWeight: null,
  });
  const [isCustomerLoading, setIsCustomerLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isShipperLoading, setIsShipperLoading] = useState(true);
  const [selectFormData, setSelectFormData] = useState<any>(initSelectFormData);
  const [selectQuoteFormData, setSelectQuoteFormData] = useState<any>(
    initSelectQuoteFormData
  );
  const [customerOptions, setCustomerOptions] = useState<any>([]);
  const [shippers, setShippers] = useState<any>([]);
  const [consignees, setConsignees] = useState<any>([]);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [consigneeOptions, setConsigneeOptions] = useState([]);
  const [shipperAddress, setShipperAddress] = useState('');
  const [consigneeAddress, setConsigneeAddress] = useState('');
  const [action, setAction] = useState(initAction);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [isConsigneeBtnDisable, setIsConsigneeBtnDisable] = useState(false);
  const [isShipperBtnDisable, setIsShipperBtnDisable] = useState(false);
  const [isCustomerDeleted, setIsCustomerDeleted] = useState(false);
  const [isSwapAddress, setIsSwapAddress] = useState(true);
  const [pickup1pmError, setPickup1pmError] = useState(false);
  const [tomorrowDayName, setTomorrowDayName] = useState('');
  const [isValidAddress, setIsValidAddress] = useState({
    shipperAddress: true,
    consigneeAddress: true,
  });
  const [addressData, setAddressData] = useState<IMapAddress>();
  const [validForm, setValidForm] = useState(initValidForm);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState<any[]>([]);

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  const checkPickupDate = (value: any) => {
    const pickupDate = new Date(value);
    const currentDateTime = new Date();
    pickupDate.setHours(
      currentDateTime.getHours(),
      currentDateTime.getMinutes(),
      currentDateTime.getSeconds(),
      currentDateTime.getMilliseconds()
    );

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Start of the day

    const onePM = new Date();
    onePM.setHours(13, 0, 0, 0); // 1 PM today

    const isSameDay = pickupDate.toDateString() === today.toDateString();
    const isAfterOnePM = pickupDate.getTime() > onePM.getTime();

    if (isSameDay && isAfterOnePM) {
      setPickup1pmError(true);
      const tomorrow = moment().add(1, 'days');
      const dayName = tomorrow.format('dddd');
      setTomorrowDayName(dayName);
    } else {
      setPickup1pmError(false);
    }
  };

  const getVehicleTypeOptions = () => {
    setIsEquipmentTypeLoading(true);
    cancelApiCall();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    getLoadLinkVehicleType(signal)
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setEquipmentTypeOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        console.log('Equipment Type Error ', e);
      })
      .finally(() => {
        setIsEquipmentTypeLoading(false);
      });
  };

  useEffect(() => {
    getMapService().then((response: any) => {
      if (response?.data) {
        if (response.data?.slug) {
          setMapService(response.data?.slug);

          if (response.data.configurations?.length) {
            let configuration = response.data.configurations[0];

            if (configuration.value) {
              if (response.data.slug === 'trimble_map') {
                setTrimbleKey(configuration.value);
              } else {
                setGoogleKey(configuration.value);
              }
            }
          }
        }
      }
    });

    // if (formData.pickupDate !== '' || formData.pickupDate !== null) {
    //   if (
    //     !isCustomerLoading &&
    //     !isShipperLoading &&
    //     id &&
    //     formData.customerId
    //   ) {
    //     checkPickupDate(formData.pickupDate);
    //   } else if (!id) {
    //     checkPickupDate(formData.pickupDate);
    //   }
    // }

    if (localStorage.getItem('isModifyQuoteFromQuote')) {
      setPageTitle(`Modify Quote: #${localStorage.getItem('modifyQuoteCode')}`);
    } else {
      setPageTitle('New Quote');
    }
    getVehicleTypeOptions();
  }, []);

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  const getFormattedPickupDate = (date: any) => {
    const momentDate = moment(date);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const tomorrow = moment().add(1, 'days').startOf('day');

    if (momentDate.isSame(today, 'day')) {
      return `Today, ${momentDate.format('MMMM Do, YYYY')}`;
    } else if (momentDate.isSame(yesterday, 'day')) {
      return `Yesterday, ${momentDate.format('MMMM Do, YYYY')}`;
    } else if (momentDate.isSame(tomorrow, 'day')) {
      return `Tomorrow, ${momentDate.format('MMMM Do, YYYY')}`;
    } else {
      return momentDate.format('dddd, MMMM Do, YYYY');
    }
  };

  const handleSelectChange = (name: any) => (event: any) => {
    const newData: any = {};

    newData[name] = event ? event.value : null;

    if (name === 'isInsureLoad') {
      newData[name] = event?.checked;
    } else {
      newData[name] = event ? event.value : null;
    }

    if (name === 'shipperId') {
      if (event && event.value) {
        const shipper = shippers.find(
          (shipperData: any) => shipperData.id === event.value
        );
        newData.shipperCompanyName = shipper.companyName;
        newData.shipperAddress = {
          fullAddress: shipper.fullAddress,
          address1: shipper.address1,
          postal: shipper.postal,
          city: shipper.city,
          state: shipper.state,
          country: shipper.country,
          longitude: shipper.longitude,
          latitude: shipper.latitude,
          stateCode: shipper.stateCode,
          countryCode: shipper.countryCode,
        };
        setIsShipperBtnDisable(false);
        setShipperAddress(shipper.fullAddress);
      } else {
        newData.shipperCompanyName = '';
        newData.shipperAddress = initShipperAddress;
        setShipperAddress('');
        setIsSwapAddress(true);
      }
    } else if (name === 'consigneeId') {
      if (event && event.value) {
        const consignee = consignees.find(
          (consigneeData: any) => consigneeData.id === event.value
        );
        newData.consigneeCompanyName = consignee.companyName;
        newData.consigneeAddress = {
          fullAddress: consignee.fullAddress,
          address1: consignee.address1,
          postal: consignee.postal,
          city: consignee.city,
          state: consignee.state,
          country: consignee.country,
          longitude: consignee.longitude,
          latitude: consignee.latitude,
          stateCode: consignee.stateCode,
          countryCode: consignee.countryCode,
        };
        setIsConsigneeBtnDisable(false);
        setConsigneeAddress(consignee.fullAddress);
      } else {
        newData.consigneeCompanyName = '';
        newData.consigneeAddress = initConsigneeAddress;
        setConsigneeAddress('');
        setIsSwapAddress(true);
      }
    }
    setFormData((old) => ({ ...old, ...newData }));
    setSelectFormData((old: any) => ({ ...old, [name]: event }));

    if (event?.value) {
      setValidForm({ ...validForm, [name]: true });

      if (name === 'consigneeId') {
        setValidForm({
          ...validForm,
          consigneeAddress: true,
          consigneeCompanyName: true,
        });
      }

      if (name === 'shipperId') {
        setValidForm({
          ...validForm,
          shipperAddress: true,
          shipperCompanyName: true,
        });
      }
    }
  };

  const handleSelectQuoteClassChange = (name: any) => (event: any) => {
    setSelectQuoteFormData((old: any) => ({ ...old, [name]: event.value }));
  };

  const handleAutocompleteChange = (name: string) => (value: any) => {
    if (value.type === 'blur') {
      return;
    }

    if (name === 'shipperAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value.postal?.length < 4
      ) {
        setShipperAddress(value?.fullAddress);
        setIsValidAddress((old) => ({ ...old, [name]: false }));
        setFormData((old) => ({ ...old, [name]: value }));
        setIsSwapAddress(true);

        return;
      }
      setIsSwapAddress(false);
      setIsValidAddress((old) => ({ ...old, [name]: true }));
      setValidForm({ ...validForm, shipperAddress: true });
      setIsShipperBtnDisable(false);
    } else if (name === 'consigneeAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value.postal?.length < 4
      ) {
        setConsigneeAddress(value?.fullAddress);
        setIsValidAddress((old) => ({ ...old, [name]: false }));
        setFormData((old) => ({ ...old, [name]: value }));
        setIsSwapAddress(true);

        return;
      }
      setIsSwapAddress(false);
      setIsConsigneeBtnDisable(false);
      setIsValidAddress((old) => ({ ...old, [name]: true }));
      setValidForm({ ...validForm, consigneeAddress: true });
    }

    setFormData((old) => {
      const newObj = { ...old, [name]: value };

      if (name === 'shipperAddress') {
        if (!old?.shipperId) {
          newObj.shipperCompanyName =
            value.companyName ?? old.shipperCompanyName;
        }
      } else {
        if (!old?.consigneeId) {
          newObj.consigneeCompanyName =
            value.companyName ?? old.consigneeCompanyName;
        }
      }

      return newObj;
    });
  };

  const getCustomer = () => {
    CustomersAllList()
      .then((result: any) => {
        if (result.data && result.data.length) {
          if (formData.customerId) {
            result.data = result.data.filter(
              (data: any) => !data.isDeleted || data.id === formData.customerId
            );
          } else if (!id) {
            result.data = result.data.filter((data: any) => !data.isDeleted);
          }
          result.data = result.data.map((data: any) => {
            if (data.isDeleted) {
              data.name = `${data.name} (Deleted Customer)`;
              data.id = '';
            }

            return data;
          });

          setCustomers(result.data);
          const customerSelectBoxOptions = getSelectBoxOptions(
            result.data,
            'id',
            'name'
          );
          setCustomerOptions(customerSelectBoxOptions);
        }
      })
      .finally(() => setIsCustomerLoading(false))
      .catch(console.error);
  };

  const getShipper = (defaultId = null, defaultType = 1) => {
    listShippers()
      .then((response: any) => {
        if (response) {
          let shipperOptionsValue: any = [];
          let consigneeOptionsValue: any = [];
          response.data.forEach((result: any) => {
            if (result.type === 1) {
              shipperOptionsValue.push(result);
            } else {
              consigneeOptionsValue.push(result);
            }
          });
          setShippers(shipperOptionsValue);
          setConsignees(consigneeOptionsValue);
          shipperOptionsValue = getSelectBoxOptions(
            shipperOptionsValue,
            'id',
            'companyName'
          );
          consigneeOptionsValue = getSelectBoxOptions(
            consigneeOptionsValue,
            'id',
            'companyName'
          );
          setShipperOptions(shipperOptionsValue);
          setConsigneeOptions(consigneeOptionsValue);

          if (defaultId) {
            const newData: any = {};

            if (defaultType === 1) {
              newData.shipperId = shipperOptionsValue.find(
                (shipperOption: any) => shipperOption.value === defaultId
              );
            } else {
              newData.consigneeId = consigneeOptionsValue.find(
                (consigneeOption: any) => consigneeOption.value === defaultId
              );
            }
            setSelectFormData((old: any) => ({ ...old, ...newData }));
          }
        }
      })
      .finally(() => setIsShipperLoading(false))
      .catch(console.error);
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (value !== null) setIsSwapAddress(false);

    if (
      name === 'shipperCompanyName' &&
      formData.shipperCompanyName.trim() !== value?.trim()
    ) {
      setIsShipperBtnDisable(false);
    }

    if (
      name === 'consigneeCompanyName' &&
      formData.consigneeCompanyName?.trim() !== value?.trim()
    ) {
      setIsConsigneeBtnDisable(false);
    }
    setFormData((old: any) => ({ ...old, [name]: value }));
  };

  const handleActionType =
    (actionKey: any, mode: any = null) =>
    () => {
      if (mode === 'addressUpdate' && actionKey === 'shipper') {
        setAddressData(formData.shipperAddress);
      }

      if (mode === 'addressUpdate' && actionKey === 'consignee') {
        setAddressData(formData.consigneeAddress);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      customerId: true,
      shipperAddress: true,
      pickupDate: {
        valid: true,
        nextDay: true,
      },
      consigneeAddress: true,
      primaryEquipmentType: true,
      totalWeightMax: true,
      totalWeightMin: true,

      linearFootageMax: true,
      linearFootageMin: true,
    };

    if (selectQuoteFormData?.classification === QUOTE_CLASSIFICATION.FTL) {
      if (formData.totalWeight || formData.totalWeight === 0) {
        if (Number(formData.totalWeight) > 100000) {
          validFormData.totalWeightMax = false;
          valid = false;
        }

        if (Number(formData.totalWeight) <= 0) {
          validFormData.totalWeightMin = false;
          valid = false;
        }
      }
    } else {
      if (formData.totalWeight || formData.totalWeight === 0) {
        if (Number(formData.totalWeight) > 100000) {
          validFormData.totalWeightMax = false;
          valid = false;
        }

        if (Number(formData.totalWeight) <= 0) {
          validFormData.totalWeightMin = false;
          valid = false;
        }
      }
    }

    if (selectQuoteFormData?.classification !== QUOTE_CLASSIFICATION.FTL) {
      if (formData.linearFootage || formData.linearFootage === 0) {
        if (Number(formData.linearFootage) > 100) {
          validFormData.linearFootageMax = false;
          valid = false;
        }

        if (Number(formData.linearFootage) <= 0) {
          validFormData.linearFootageMin = false;
          valid = false;
        }
      }
    }

    if (formData.customerId === '' || formData.customerId === null) {
      validFormData.customerId = false;
      valid = false;
    }

    if (!formData.primaryEquipmentType) {
      validFormData.primaryEquipmentType = false;
      valid = false;
    }

    if (formData.shipperAddress.fullAddress === undefined) {
      validFormData.shipperAddress = false;
      valid = false;
    }

    if (
      formData.shipperAddress.fullAddress === '' ||
      formData.shipperAddress.fullAddress === null
    ) {
      validFormData.shipperAddress = false;
      valid = false;
    }

    if (formData.pickupDate === '' || formData.pickupDate === null) {
      validFormData.pickupDate.valid = false;
      valid = false;
    }

    if (formData.consigneeAddress.fullAddress === undefined) {
      validFormData.consigneeAddress = false;
      valid = false;
    }

    if (
      formData.consigneeAddress.fullAddress === '' ||
      formData.consigneeAddress.fullAddress === null
    ) {
      validFormData.consigneeAddress = false;
      valid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!isFormValid()) {
      return true;
    }
    const pickupDateTime = moment(new Date(formData.pickupDate)).format(
      `${DATE_FORMAT} ${TIME_FORMAT}`
    );

    const data: any = {
      customerId: formData.customerId,
      pickupDate: pickupDateTime,
      shipperId: formData.shipperId,
      shipperCompanyName: formData.shipperCompanyName,
      shipperFullAddress: formData.shipperAddress.fullAddress,
      shipperAddress1: formData.shipperAddress.address1,
      shipperCity: formData.shipperAddress.city,
      shipperState: formData.shipperAddress.state,
      shipperCountry: formData.shipperAddress.country,
      shipperPostal: formData.shipperAddress.postal,
      shipperLatitude: formData.shipperAddress.latitude,
      shipperLongitude: formData.shipperAddress.longitude,
      shipperStateCode: formData.shipperAddress.stateCode,
      shipperCountryCode: formData.shipperAddress.countryCode,
      consigneeId: formData.consigneeId,
      consigneeCompanyName: formData.consigneeCompanyName,
      consigneeFullAddress: formData.consigneeAddress.fullAddress,
      consigneeAddress1: formData.consigneeAddress.address1,
      consigneeCity: formData.consigneeAddress.city,
      consigneeState: formData.consigneeAddress.state,
      consigneeCountry: formData.consigneeAddress.country,
      consigneePostal: formData.consigneeAddress.postal,
      consigneeLatitude: formData.consigneeAddress.latitude,
      consigneeLongitude: formData.consigneeAddress.longitude,
      consigneeStateCode: formData.consigneeAddress.stateCode,
      consigneeCountryCode: formData.consigneeAddress.countryCode,
      primaryEquipmentType: formData.primaryEquipmentType?.toString(),
      secondaryEquipmentTypes: formData.secondaryEquipmentTypes?.toString(),
      primaryEquipmentTypeFullName:
        formData.primaryEquipmentTypeFullName?.toString(),
      secondaryEquipmentTypeFullNames:
        formData.secondaryEquipmentTypeFullNames?.toString(),
      linearFootage: formData.linearFootage ?? undefined,
      totalWeight: formData.totalWeight ?? undefined,
      classification: selectQuoteFormData?.classification,
      insuranceCommercialValue: formData.insuranceCommercialValue ?? 0,
      insuranceCurrencyType: formData.insuranceCurrencyType ?? 0,
      isFromQuoteRequest: id ? true : false,
    };

    if (window.MODE !== 'production' && selectQuoteFormData?.emailContentId) {
      data.emailContentId = selectQuoteFormData?.emailContentId;
    }

    if (window.MODE !== 'production' && selectQuoteFormData?.urgency) {
      data.urgency = selectQuoteFormData?.urgency;
    }

    if (id) {
      updateQuote(id, data)
        .then((result: any) => {
          if (result) {
            if (result?.data?.classification === QUOTE_CLASSIFICATION.FTL) {
              navigate(`${PATH.MY_QUOTE}/${result.data.id}`);
            } else {
              navigate(`${PATH.DIMENSIONS}/${result.data.id}`);
            }
          }
        })
        .catch((error: any) => {
          const errMsg =
            error?.response?.status === 409
              ? error?.response?.data?.message
              : 'Something went wrong! Please try again';
          WalToast.error(errMsg, '');
        });
    } else {
      createQuote(data)
        .then((result: any) => {
          if (result) {
            if (result?.data?.classification === QUOTE_CLASSIFICATION.LTL) {
              navigate(`${PATH.DIMENSIONS}/${result.data.id}`);
            } else {
              navigate(`${PATH.MY_QUOTE}/${result.data.id}`);
            }
          }
        })
        .catch((error: any) => {
          const errMsg =
            error?.response?.status === 409
              ? error?.response?.data?.message
              : 'Something went wrong! Please try again';
          WalToast.error(errMsg, '');
        });
    }
  };

  const saveShipperDetails = (type: string) => () => {
    let idToSave: any;
    let address: any;
    let companyName: any;
    let typeId: any;
    let insertMsg: any;
    let updateMsg: any;

    if (type === 'shipper') {
      typeId = 1;
      idToSave = formData.shipperId;
      companyName = formData.shipperCompanyName;
      address = formData.shipperAddress;
      insertMsg = 'Shipper details added successfully';
      updateMsg = 'Shipper details updated successfully';
    } else if (type === 'consignee') {
      typeId = 2;
      idToSave = formData.consigneeId;
      companyName = formData.consigneeCompanyName;
      address = formData.consigneeAddress;
      insertMsg = 'Consignee details added successfully';
      updateMsg = 'Consignee details updated successfully';
    }
    const data: any = {
      ...address,
      companyName,
      type: typeId,
    };

    // if (companyName === '') {
    //   WalToast.error(`Please enter ${type}'s company name`, '');

    //   return false;
    // }

    // if (address.fullAddress === '' || typeof address !== 'object') {
    //   WalToast.error(`Please enter ${type}'s address`, '');

    //   return false;
    // }

    if (
      companyName === '' ||
      address.fullAddress === '' ||
      typeof address !== 'object'
    ) {
      if (companyName === '') {
        if (type === 'shipper') {
          setValidForm((old) => ({ ...old, shipperCompanyName: false }));
        } else if (type === 'consignee') {
          setValidForm((old) => ({ ...old, consigneeCompanyName: false }));
        }
      }

      if (address.fullAddress === '' || typeof address !== 'object') {
        if (type === 'shipper') {
          setValidForm((old) => ({ ...old, shipperAddress: false }));
        } else if (type === 'consignee') {
          setValidForm((old) => ({ ...old, consigneeAddress: false }));
        }
      }

      return false;
    }

    if (type === 'shipper') {
      setIsShipperBtnDisable(true);
    } else {
      setIsConsigneeBtnDisable(true);
    }

    if (idToSave) {
      updateShipper(idToSave, data)
        .then((result: any) => {
          if (result) {
            getShipper(idToSave, typeId);

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }
            WalToast.success(updateMsg, '');
          }
        })
        .catch(console.error);
    } else {
      createShipper(data)
        .then((result: any) => {
          if (result) {
            getShipper(result?.data?.id, typeId);

            if (result?.data?.id) {
              setFormData((old: any) => ({
                ...old,
                [`${type}Id`]: result.data.id,
              }));
            }

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }
            WalToast.success(insertMsg, '');
          }
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    getCustomer();
    getShipper();
  }, [isCustomerDeleted]);

  useEffect(() => {
    if (formData?.shipperAddress?.fullAddress) {
      setShipperAddress(formData.shipperAddress.fullAddress);
    }

    if (formData?.consigneeAddress?.fullAddress) {
      setConsigneeAddress(formData.consigneeAddress.fullAddress);
    }
  }, [formData]);

  useEffect(() => {
    if (!isCustomerLoading && !isShipperLoading && id) {
      setIsLoading(true);
      getQuote(id)
        .then((response: any) => {
          if (response && response.data) {
            const { addressDetails } = response.data;
            const pickupDateInLocal = moment
              .utc(addressDetails.pickupDate)
              .local()
              .format('YYYY-MM-DD');

            if (addressDetails.status !== STATUS.PENDING) {
              navigate(ROUTES.QUOTING_DASHBOARD);
            }

            const currentFormData: any = {
              customerId: addressDetails.isCustomerDeleted
                ? ''
                : addressDetails.customerId,
              pickupDate: pickupDateInLocal,
              shipperId: addressDetails.shipperId,
              shipperCompanyName: addressDetails.shipperCompanyName,
              consigneeId: addressDetails.consigneeId,
              consigneeCompanyName: addressDetails.consigneeCompanyName,
              primaryEquipmentType: addressDetails?.primaryEquipmentType,
              secondaryEquipmentTypes: addressDetails?.secondaryEquipmentTypes,
              primaryEquipmentTypeFullName:
                addressDetails?.primaryEquipmentTypeFullName?.split(','),
              secondaryEquipmentTypeFullNames:
                addressDetails?.secondaryEquipmentTypeFullNames?.split(','),
              shipperAddress: {
                fullAddress: addressDetails.shipperFullAddress,
                address1: addressDetails.shipperAddress1,
                city: addressDetails.shipperCity,
                state: addressDetails.shipperState,
                country: addressDetails.shipperCountry,
                postal: addressDetails.shipperPostal,
                latitude: addressDetails.shipperLatitude,
                longitude: addressDetails.shipperLongitude,
                stateCode: addressDetails.shipperStateCode,
                countryCode: addressDetails.shipperCountryCode,
              },
              consigneeAddress: {
                fullAddress: addressDetails.consigneeFullAddress,
                address1: addressDetails.consigneeAddress1,
                city: addressDetails.consigneeCity,
                state: addressDetails.consigneeState,
                country: addressDetails.consigneeCountry,
                postal: addressDetails.consigneePostal,
                latitude: addressDetails.consigneeLatitude,
                longitude: addressDetails.consigneeLongitude,
                stateCode: addressDetails.consigneeStateCode,
                countryCode: addressDetails.consigneeCountryCode,
              },
              linearFootage: addressDetails.linearFootage,
              totalWeight: addressDetails.totalWeight,
              insuranceCommercialValue: addressDetails.insuranceCommercialValue,
              insuranceCurrencyType: addressDetails.insuranceCurrencyType,
            };
            setFormData(currentFormData);
            setShipperAddress(addressDetails.shipperFullAddress);
            setConsigneeAddress(addressDetails.consigneeFullAddress);
            setIsCustomerDeleted(addressDetails.isCustomerDeleted);

            setSelectQuoteFormData((old: any) => ({
              ...old,
              classification: addressDetails?.classification,
              urgency: addressDetails?.urgency,
              emailContentId: addressDetails?.emailContentId,
            }));

            const newData: any = {};

            if (currentFormData.customerId) {
              const customer = customers.find(
                (customerOption: any) =>
                  customerOption.id === currentFormData.customerId
              );
              const customerOption = getSelectBoxOptions(
                customer,
                'id',
                'name'
              );
              newData.customerId = customerOption;
            }

            if (currentFormData.shipperId) {
              newData.shipperId = shipperOptions.find(
                (shipperOption: any) =>
                  shipperOption.value === currentFormData.shipperId
              );
              setIsShipperBtnDisable(false);
            }

            if (currentFormData.consigneeId) {
              newData.consigneeId = consigneeOptions.find(
                (consigneeOption: any) =>
                  consigneeOption.value === currentFormData.consigneeId
              );
              setIsConsigneeBtnDisable(false);
            }

            if (addressDetails.primaryEquipmentType) {
              newData.primaryEquipmentType =
                addressDetails?.primaryEquipmentType?.split(',');
            }

            if (addressDetails.secondaryEquipmentTypes) {
              newData.secondaryEquipmentTypes =
                addressDetails?.secondaryEquipmentTypes?.split(',');
            }

            setSelectFormData((old: any) => ({ ...old, ...newData }));
            checkPickupDate(addressDetails.pickupDate);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            navigate(ROUTES.QUOTING_DASHBOARD);
          }
        })
        .catch((e: any) => {
          console.error(e);
          setIsLoading(false);
        });
    } else {
      if (!id) {
        setIsLoading(false);
      }
      checkPickupDate(moment().toISOString());
      setFormData((old) => ({ ...old, pickupDate: moment().toISOString() }));
    }
  }, [id, isCustomerLoading, isShipperLoading]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const handleAddressSelection = (value: any) => {
    if (action.shipper) {
      setShipperAddress(value.fullAddress);
      setFormData((old) => ({ ...old, shipperAddress: value }));
      setIsValidAddress((old) => ({ ...old, shipperAddress: true }));
    } else if (action.consignee) {
      setConsigneeAddress(value.fullAddress);
      setFormData((old) => ({ ...old, consigneeAddress: value }));
      setIsValidAddress((old) => ({ ...old, consigneeAddress: true }));
    }
  };

  const handleModalClose = useCallback(
    (closeStatus: boolean) => () => {
      setAction(initAction);

      if (closeStatus) {
        const idToDelete = action.shipper
          ? formData.shipperId
          : formData.consigneeId;

        if (idToDelete) {
          deleteShippers(idToDelete)
            .then((result: any) => {
              if (result) {
                const newData: any = {};
                let name = '';

                if (action.shipper) {
                  newData.shipperId = null;
                  newData.shipperCompanyName = '';
                  newData.shipperAddress = initShipperAddress;
                  setShipperAddress('');
                  name = 'shipperId';
                } else if (action.consignee) {
                  newData.consigneeId = null;
                  newData.consigneeCompanyName = '';
                  newData.consigneeAddress = initConsigneeAddress;
                  setConsigneeAddress('');
                  name = 'consigneeId';
                }
                setFormData((old) => ({ ...old, ...newData }));
                setSelectFormData((old: any) => ({ ...old, [name]: null }));
                getShipper();
                const title = action.shipper ? 'Shipper' : 'Consignee';
                WalToast.success(`${title} deleted successfully`, '');
              }
            })
            .catch(console.error);
        }
      }
    },
    [action]
  );

  const handleDateChange = (event: any) => {
    const { name, value } = event;
    setFormData((old: any) => ({ ...old, [name]: value }));
    localStorage.removeItem('isPickupDateChanged');
    checkPickupDate(value);
  };

  const swapAddress = () => {
    setConsigneeAddress(shipperAddress);
    setShipperAddress(consigneeAddress);
    setFormData((old) => ({
      ...old,
      consigneeAddress: formData.shipperAddress,
      shipperAddress: formData.consigneeAddress,
      shipperCompanyName: formData.consigneeCompanyName,
      consigneeCompanyName: formData.shipperCompanyName,
      shipperId: null,
      consigneeId: null,
    }));
    setSelectFormData((old: any) => ({
      ...old,
      shipperId: null,
      consigneeId: null,
    }));
  };

  const selectNextDay = (val: boolean) => {
    if (val) {
      if (tomorrowDayName === 'Saturday') {
        const tomorrow = moment().add(3, 'days');
        setFormData((old: any) => ({ ...old, pickupDate: tomorrow }));
      } else if (tomorrowDayName === 'Sunday') {
        const tomorrow = moment().add(2, 'days');
        setFormData((old: any) => ({ ...old, pickupDate: tomorrow }));
      } else {
        const tomorrow = moment().add(1, 'days');
        setFormData((old: any) => ({ ...old, pickupDate: tomorrow }));
      }

      localStorage.removeItem('isPickupDateChanged');
    }
    setPickup1pmError(false);
  };

  return (
    <>
      <PageSectionLayout
        contentClassName="pb-[20px] !gap-0"
        header={
          <Header
            title={pageTitle}
            desc="Create a new quote by filling out the shipment details below."
            rightSideContent={
              <div className="flex gap-5">
                <StepBar steps={STEPS} activeStep={1}></StepBar>
                <div className=" flex gap-x-3">
                  <ButtonCmp
                    onClick={() => navigate(-1)}
                    className="btn_secondary_black min-w-[75px]"
                  >
                    Back
                  </ButtonCmp>

                  <ButtonCmp
                    type="submit"
                    className="btn_primary min-w-[75px]"
                    onClick={(e: any) => handleSubmit(e)}
                    disabled={isLoading}
                  >
                    Next
                  </ButtonCmp>

                  {localStorage.getItem('isModifyQuoteFromQuote') && (
                    <Link
                      to={`${PATH.MY_QUOTE}/${localStorage.getItem(
                        'isModifyQuoteFromQuote'
                      )}`}
                      className="btn_secondary_black !p-2 flex flex-none justify-center items-center"
                    >
                      <XClose className="w-4 h-4" />
                    </Link>
                  )}
                </div>
              </div>
            }
          />
        }
      >
        <div className="w-full xl:p-5 sm:p-4 pt-2 sm:bg-white rounded-xl sm:border sm:border-utilityGray200 sm:shadow-sm mb-4 ">
          <div className="flex flex-wrap gap-y-3 -mx-1.5">
            {selectQuoteFormData?.classification && (
              <SelectBox
                parentClassName="single-select-x-scroll w-[132px] min-w-fit px-1.5"
                name="classification"
                required
                label="Service Type"
                className={`form_control`}
                labelClassName="form_label mb-1.5 block"
                options={classOptions}
                value={
                  selectQuoteFormData?.classification
                    ? classOptions.filter(
                        (val: any) =>
                          selectQuoteFormData?.classification === val.value
                      )
                    : 'ltl'
                }
                onChangeFunc={handleSelectQuoteClassChange('classification')}
              />
            )}

            <SelectBox
              parentClassName="single-select-x-scroll xlm:w-[16%] xlm:min-w-[16%] w-[14%] min-w-[14%] px-1.5"
              isClearable
              name="customerId"
              required
              placeholder="Select customer"
              label="Customer"
              className={`form_control`}
              labelClassName="form_label mb-1.5 block"
              isSearchable={true}
              isLoading={isCustomerLoading}
              options={customerOptions}
              value={selectFormData.customerId}
              onChangeFunc={handleSelectChange('customerId')}
              errorText={!validForm.customerId ? true : false}
            />

            <InputText
              parentClassName="w-[10%] min-w-[140px] px-1.5"
              label="Total Weight"
              inputName="totalWeight"
              inputType="number"
              placeholder={'Enter total weight'}
              labelClassName="mb-1.5 block"
              value={formData.totalWeight}
              shouldFormatNumber={true}
              inputValueUnitType="lbs"
              onChangeFunc={(e) => handleSelectChange('totalWeight')(e?.target)}
              errorText={
                !validForm.totalWeightMin
                  ? 'Total weight should not be 0'
                  : !validForm.totalWeightMax
                  ? 'Total weight should be less than 100,000 lbs'
                  : ''
              }
            />

            {selectQuoteFormData?.classification === QUOTE_CLASSIFICATION.LTL ||
            selectQuoteFormData?.classification ===
              QUOTE_CLASSIFICATION.VOLUME_LTL ? (
              <InputText
                parentClassName="w-[10%] min-w-[152px] px-1.5"
                label="Linear Footage"
                inputName="linearFootage"
                inputType="number"
                placeholder={'Enter linear footage'}
                labelClassName="mb-1.5 block"
                value={formData.linearFootage}
                required={
                  selectQuoteFormData?.classification ===
                  QUOTE_CLASSIFICATION.VOLUME_LTL
                }
                shouldFormatNumber={true}
                onChangeFunc={(e) =>
                  handleSelectChange('linearFootage')(e?.target)
                }
                inputValueUnitType="ft"
                errorText={
                  !validForm.linearFootageMin
                    ? 'Linear footage should not be 0'
                    : !validForm.linearFootageMax
                    ? 'Linear footage should be less than 100 Ft.'
                    : ''
                }
              />
            ) : (
              false
            )}

            {selectQuoteFormData?.classification === QUOTE_CLASSIFICATION.FTL ||
            selectQuoteFormData?.classification === QUOTE_CLASSIFICATION.LTL ||
            selectQuoteFormData?.classification ===
              QUOTE_CLASSIFICATION.VOLUME_LTL ? (
              <SelectBox
                parentClassName="w-[15%] min-w-[166px] px-1.5 flex-1"
                isClearable
                required
                name="primaryEquipmentType"
                placeholder="Select eqpt. type"
                label="Primary Equipment Type"
                labelClassName="form_label mb-1.5 block"
                options={equipmentTypeOptions}
                value={equipmentTypeOptions.find(
                  (item) => formData.primaryEquipmentType === item.value
                )}
                isLoading={isEquipmentTypeLoading}
                isMultipleSelection={false}
                onChangeFunc={(event: any) => {
                  setValidForm((old) => ({
                    ...old,
                    primaryEquipmentType: true,
                  }));
                  const secondaryEquipmentTypesWithOutPrimary =
                    formData?.secondaryEquipmentTypes?.filter(
                      (set: any) => set !== event?.value
                    );
                  const secondaryEquipmentTypeFullNamesWithOutPrimary =
                    formData?.secondaryEquipmentTypeFullNames?.filter(
                      (setf: any) => setf !== event?.label
                    );
                  setFormData((old) => ({
                    ...old,
                    primaryEquipmentType: event?.value,
                    primaryEquipmentTypeFullName: event?.label,
                    secondaryEquipmentTypes:
                      secondaryEquipmentTypesWithOutPrimary,
                    secondaryEquipmentTypeFullNames:
                      secondaryEquipmentTypeFullNamesWithOutPrimary,
                  }));
                }}
                errorText={!validForm.primaryEquipmentType ? true : false}
              />
            ) : (
              false
            )}

            {selectQuoteFormData?.classification === QUOTE_CLASSIFICATION.FTL ||
            selectQuoteFormData?.classification === QUOTE_CLASSIFICATION.LTL ||
            selectQuoteFormData?.classification ===
              QUOTE_CLASSIFICATION.VOLUME_LTL ? (
              <SelectBox
                parentClassName="w-[15%] min-w-[177px] px-1.5 flex-1"
                isClearable
                name="secondaryEquipmentTypes"
                placeholder="Select eqpt. types"
                label="Secondary Equipment Types"
                labelClassName="form_label mb-1.5 block"
                options={equipmentTypeOptions.filter(
                  (eto) => eto.value !== formData.primaryEquipmentType
                )}
                value={equipmentTypeOptions.filter((item) =>
                  formData.secondaryEquipmentTypes?.includes(item.value)
                )}
                isLoading={isEquipmentTypeLoading}
                isMultipleSelection={true}
                onChangeFunc={(event: any) => {
                  const newValue = event.map((e: any) => e.value);
                  const newLabel = event.map((e: any) => e.label);
                  setFormData((old) => ({
                    ...old,
                    secondaryEquipmentTypes: newValue,
                    secondaryEquipmentTypeFullNames: newLabel,
                  }));
                }}
              />
            ) : (
              false
            )}

            <div className="w-[19%] min-w-[264px] px-1.5">
              <div className="flex flex-wrap items-center gap-1 mb-1.5 relative">
                <div className="flex">
                  <label className="form_label mb-0">Pickup Date</label>
                  <span className="text-red-600 ms-[2px] leading-4 text-xs">
                    *
                  </span>
                </div>
                <p
                  className={`form_label mb-0 w-max mdm:absolute left-[82px] flex items-center`}
                >
                  {pickup1pmError && (
                    <>
                      <span className="text-xs text-primary font-normal flex items-center">
                        {tomorrowDayName === 'Saturday'
                          ? `Set to Monday`
                          : 'Set to Tomorrow'}
                      </span>
                      <a
                        onClick={() => selectNextDay(true)}
                        className="text-xs cursor-pointer underline decoration-1 ml-1 mr-1 text-primary700 font-semibold"
                      >
                        Yes
                      </a>
                      &nbsp;
                      {'/'}
                      <a
                        onClick={() => selectNextDay(false)}
                        className="text-xs cursor-pointer underline decoration-1 ml-1 text-primary700 font-semibold"
                      >
                        No
                      </a>
                      <TooltipCmp message={'Pickup is unlikely after 1pm.'}>
                        <InfoCircle className="w-3.5 h-3.5 text-textSecondary ml-1 cursor-pointer" />
                      </TooltipCmp>
                    </>
                  )}
                </p>
              </div>
              <DateSelect
                inputName="pickupDate"
                className={`form_control ${
                  !validForm.pickupDate.valid
                    ? 'border border-red-500 mb-1 border-solid'
                    : ''
                }`}
                placeholder={DATE_FORMAT}
                dateFormat="dd/MM/yyyy"
                minDate={moment().toDate()}
                selected={
                  formData.pickupDate
                    ? moment(formData.pickupDate).toDate()
                    : null
                }
                value={getDateWithSuffixFormat(
                  getFormattedPickupDate(formData.pickupDate)
                )}
                errorText={''}
                onChangeFunc={handleDateChange}
                icon={<Calendar className="h-5 w-5" />}
                parentClassName="w-full one-month-datepicker"
              />
            </div>
          </div>
        </div>

        <ShipperAddress
          formData={formData}
          shipperOptions={shipperOptions}
          validForm={validForm}
          setValidForm={setValidForm}
          handleSelectChange={handleSelectChange}
          selectFormData={selectFormData}
          handleActionType={handleActionType}
          saveShipperDetails={saveShipperDetails}
          isShipperBtnDisable={isShipperBtnDisable}
          handleAutocompleteChange={handleAutocompleteChange}
          setShipperAddress={setShipperAddress}
          handleInputChange={handleInputChange}
          shipperAddress={shipperAddress}
          isValidAddress={isValidAddress}
          isLoading={isLoading}
          isShipperLoading={isShipperLoading}
          status={status}
        />

        <div className="text-center my-4">
          <ButtonCmp
            className="btn_secondary_black !p-2"
            onClick={swapAddress}
            disabled={
              localStorage.getItem('isModifyQuoteFromQuote')
                ? false
                : isSwapAddress
            }
          >
            <SwitchVertical01 className="text-text-grayLight900 w-4 h-4" />
          </ButtonCmp>
        </div>

        <ConsigneeAddress
          formData={formData}
          consigneeOptions={consigneeOptions}
          validForm={validForm}
          setValidForm={setValidForm}
          handleSelectChange={handleSelectChange}
          selectFormData={selectFormData}
          handleActionType={handleActionType}
          saveShipperDetails={saveShipperDetails}
          isConsigneeBtnDisable={isConsigneeBtnDisable}
          handleAutocompleteChange={handleAutocompleteChange}
          handleInputChange={handleInputChange}
          consigneeAddress={consigneeAddress}
          setConsigneeAddress={setConsigneeAddress}
          isLoading={isLoading}
          isShipperLoading={isShipperLoading}
          isValidAddress={isValidAddress}
          status={status}
        />
        {selectQuoteFormData?.emailContentId && (
          <QuoteEmailDetailView
            emailContentId={selectQuoteFormData?.emailContentId}
          />
        )}
        {/* {formData?.classification === QUOTE_CLASSIFICATION.FTL ||
        formData?.classification === QUOTE_CLASSIFICATION.LTL ? ( */}

        <div className="w-full rounded-lg border border-utilityGray200 p-4 flex justify-between gap-y-4 bg-gray25 mt-4">
          <Toggle
            isChecked={
              formData?.isInsureLoad || formData.insuranceCommercialValue
            }
            labelPosition="left"
            label="Would you like to insure this load?"
            labelTextClassName="text-textSecondary text-sm"
            labelClassName="w-1/2"
            onChange={(e: any) => handleSelectChange('isInsureLoad')(e?.target)}
          />

          {(formData?.isInsureLoad || formData.insuranceCommercialValue) && (
            <div className="w-1/2 flex flex-col justify-end items-end">
              <div
                className={`w-1/2  form_control p-0 relative !h-auto ${
                  !validForm.insuranceCommercialValue ? 'border-[#f04438] ' : ''
                }`}
              >
                <InputText
                  inputName="insuranceCommercialValue"
                  inputType="number"
                  className={`!h-auto pl-8 !pr-20 border-0`}
                  placeholder={'Enter a commercial value'}
                  value={formData.insuranceCommercialValue}
                  required={true}
                  icon={<CurrencyDollar className="input-currency-sign" />}
                  onChangeFunc={(e) =>
                    handleSelectChange('insuranceCommercialValue')(e?.target)
                  }
                  shouldFormatNumber={true}
                />

                <SelectBox
                  name="insuranceCurrencyType"
                  labelClassName="form_label block mb-1.5"
                  parentClassName="absolute right-px top-px w-[80px] dropdown-within-input"
                  id="insuranceCurrencyType"
                  classComp="rounded-none rounded-r-lg"
                  size="sm"
                  options={currencyTypeOptions}
                  onChangeFunc={handleSelectChange('insuranceCurrencyType')}
                  value={currencyTypeOptions.filter(
                    (val: any) =>
                      (formData.insuranceCurrencyType ?? 'cad') === val.value
                  )}
                />
              </div>
            </div>
          )}
        </div>

        {/* ) : (
          false
        )} */}
      </PageSectionLayout>

      <Address
        isOpen={
          action.mode === 'addressCreate' || action.mode === 'addressUpdate'
        }
        mode={action.mode}
        handleClose={handleDrawerClose}
        setAddressData={handleAddressSelection}
        addressType={action}
        addressData={addressData}
        status={status}
      />

      {action.mode === 'delete' && (
        <DeleteModal
          handleClose={handleModalClose}
          moduleTitle={`Remove ${
            action.consignee
              ? formData.consigneeCompanyName
              : formData.shipperCompanyName
          } From Wal HQ `}
        />
      )}
    </>
  );
};

export default AddressDetailsForm;
