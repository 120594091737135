import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import { calculateDistance } from 'src/utils/CommonFunctions';

const LegDetailsRow = ({ data }: any) => {
  const carrierData =
    data?.carrierPrice?.length > 0 ? data?.carrierPrice[0] : [];

  return (
    <>
      <td className="px-5 py-5 w-[20%] min-w-[20%] max-w-[20%]">
        <span className="text-grayLight900 font-medium">#{data?.legId}</span>
      </td>
      <td className="px-5 py-5 w-[27%] min-w-[27%] max-w-px">
        {carrierData?.carrier ? (
          <div className="flex items-center gap-3">
            <div className="table-profile-wrap">
              {carrierData?.carrier?.image ? (
                <img
                  className="table-profile-icn-circle-xl"
                  src={carrierData?.carrier?.image}
                />
              ) : (
                <div className="table-profile-customer-circle-xl">WA</div>
              )}
            </div>
            <div className="truncate">
              <h6 className="text-grayLight900 font-medium leading-tight truncate">
                {carrierData?.carrier?.name}
              </h6>
              <p className="text-grayLight600 font-normal leading-tight truncate">
                #WQ085020145
              </p>
            </div>
          </div>
        ) : (
          '-'
        )}
      </td>

      <td className="px-5 py-5 w-[26%] min-w-[26%] max-w-px">
        <TooltipCmp
          type="dark"
          parentClassName=""
          message={data?.shipper?.shipperAddress?.address1}
        >
          <p className="flex mr-3">
            <span className="text-gray500 font-normal">From:&nbsp;</span>
            <span className="font-medium truncate ">
              {data?.shipper?.shipperAddress?.address1}
            </span>
          </p>
        </TooltipCmp>

        <TooltipCmp
          type="dark"
          parentClassName="max-w-90"
          message={data?.consignee?.consigneeAddress?.address1}
        >
          <p className="flex mr-3">
            <span className="text-gray500 font-normal">To:&nbsp;</span>
            <span className="font-medium truncate ">
              {data?.consignee?.consigneeAddress?.address1}
            </span>
          </p>
        </TooltipCmp>
      </td>
      <td className="px-5 py-5 w-[15%] min-w-[15%] max-w-[15%]">
        <p className="flex">
          <span className="text-gray500 font-normal">Mileage:&nbsp;</span>
          <span className="truncate font-medium">
            {calculateDistance(
              data?.shipper?.shipperAddress?.latitude,
              data?.shipper?.shipperAddress?.longitude,
              data?.consignee?.consigneeAddress?.latitude,
              data?.consignee?.consigneeAddress?.longitude
            )}{' '}
            mi
          </span>
        </p>
        <p className="flex">
          <span className="text-gray500 font-normal">ETA:&nbsp;</span>
          <span className="truncate font-medium">-</span>
        </p>
      </td>
    </>
  );
};

export default LegDetailsRow;
