import { yupResolver } from '@hookform/resolvers/yup';
import {
  Calendar,
  // Calendar,
  CurrencyDollar,
  PackagePlus,
  Percent02,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  Resolver,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import { listService } from 'src/services/CommonService';
import { getTopCarriers } from 'src/services/GeographyService';
import {
  getDateWithSuffixFormat,
  getFormattedPickupDate,
} from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import { handleInputChange } from '../../order.constant';
import AdditionalCharge from '../AdditionalCharge';

const schema = yup.object().shape({
  // quoteId: yup.string().nullable().required('Quote is required'),
  carrierId: yup
    .mixed()
    .test(
      'carrier-id-validation',
      'Carrier ID must be a number or "unassigned"',
      function (value) {
        return value === 'unassigned' || typeof value === 'number';
      }
    )
    .nullable()
    .required('Carrier is required'),
  budgetCost: yup
    .number()
    .nullable()
    .when('carrierId', {
      is: (carrierId: any) => carrierId === 'unassigned',
      then: (cost) =>
        cost
          .required('Budget cost is required')
          .min(1, 'Budget cost must be greater than 0')
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          ),
      otherwise: (cost) => cost.notRequired(),
    }),

  baseCarrierRate: yup
    .number()
    .nullable()
    .when('carrierId', {
      is: (carrierId: any) => carrierId !== 'unassigned',
      then: (rate) =>
        rate
          .required('Base carrier rate is required')
          // .min(1, 'Base carrier rate must be greater than 0')
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          ),
      otherwise: (rate) => rate.notRequired(),
    }),

  carrierBookingDate: yup.date().nullable(),
  fuel: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  fuelPercentage: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  totalPrice: yup
    .number()
    .required('Total price is required')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),

  additional_charge: yup.array().of(
    yup.object().shape({
      charge: yup
        .string()
        .nullable()
        .test(
          'charge-required-with-price',
          'Charge is required if price is provided',
          function (value) {
            const { price } = this.parent;

            return !price || (value !== null && value?.trim() !== '');
          }
        ),
      otherCharge: yup
        .string()
        .nullable()
        .test(
          'other-charge-required',
          'Other charge is required',
          function (value) {
            const { charge } = this.parent;

            return (
              charge !== 'other' || (value !== null && value?.trim() !== '')
            );
          }
        ),
      price: yup
        .number()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .test(
          'price-required-with-charge',
          'Price is required if charge is provided',
          function (value) {
            const { charge } = this.parent;

            return !charge || value !== null;
          }
        ),
    })
  ),
});

interface IProps {
  handleClose: () => void;
  handleAddCarrierPrice: any;
  carrierEditData: any;
  additionalServices?: any;
}

export interface IFormData {
  carrierId: any;
  budgetCost: any;
  baseCarrierRate: any;
  carrierBookingDate: any;
  fuel: any;
  fuelPercentage: any;
  totalPrice: any;
  additional_charge: Array<{
    id?: number;
    charge: null | number | string;
    otherCharge: null | string;
    price: null | number;
  }>;
}

const tabArray: any = [
  {
    value: '%',
    name: '%',
  },
  {
    value: '$',
    name: '$',
  },
];

const CarrierModal = ({
  handleClose,
  handleAddCarrierPrice,
  carrierEditData,
  additionalServices,
}: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [carrier, setCarrier] = useState<any>(null);
  const [carrierList, setCarrierList] = useState<any[]>([]);
  const [isFieldsInitialized, setIsFieldsInitialized] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [services, setServices] = useState([]);
  const [isUnassigned, setIsUnassigned] = useState(false);
  const [activeMarginType, setActiveMarginType] = useState<any>('%');
  const [search, setSearch] = useState<any>(null);

  const methods = useForm<IFormData>({
    resolver: yupResolver(schema) as Resolver<any>,
    defaultValues: {
      carrierId: undefined,
      budgetCost: undefined,
      baseCarrierRate: undefined,
      carrierBookingDate: carrierEditData?.carrierBookingDate
        ? moment(carrierEditData?.carrierBookingDate).toDate()
        : moment().toDate(),
      fuel: undefined,
      fuelPercentage: undefined,
      totalPrice: undefined,
      additional_charge: [
        {
          id: 0,
          charge: null,
          otherCharge: null,
          price: null,
        },
      ],
    },
  });

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = methods;

  const { replace } = useFieldArray({
    control,
    name: 'additional_charge',
  });

  const watchCarrierId: any = watch('carrierId');
  const watchAdditionalCharges: any = watch('additional_charge');
  const watchBaseCarrierRate: any = watch('baseCarrierRate');
  const watchFuel: any = watch('fuel');
  const watchFuelPercentage: any = watch('fuelPercentage');
  const watchTotalPrice: any = watch('totalPrice');

  const handleTotalPrice = () => {
    const additionalPrice = watchAdditionalCharges
      ?.filter(
        (item: any) =>
          item.price !== null &&
          item.price !== undefined &&
          !isNaN(Number(item.price))
      )
      .reduce((acc: any, item: any) => acc + Number(item.price), 0);

    const baseCarrierRate = watchBaseCarrierRate || 0;
    // const fuel = watchFuel || 0;
    const fuelPercentage = watchFuelPercentage || 0;

    if (
      additionalPrice !== null &&
      !isNaN(additionalPrice) &&
      baseCarrierRate !== null &&
      !isNaN(baseCarrierRate)
    ) {
      let totalPrice = additionalPrice + Number(baseCarrierRate);

      if (fuelPercentage && fuelPercentage > 0) {
        const calculatedFuel = (totalPrice * fuelPercentage) / 100;
        setValue('fuel', parseFloat(calculatedFuel.toFixed(2)));

        totalPrice += calculatedFuel;
      }

      setValue('totalPrice', totalPrice.toFixed(2));
    }
  };

  const getListService = () => {
    listService({})
      .then((response: any) => {
        if (response.data) {
          setServices(response.data);

          const formattedList = response.data.map((item: any) => ({
            label: item.name,
            value: item.slug,
          }));

          formattedList.push({
            label: 'Other',
            value: 'other',
          });

          setServiceList(formattedList);
        }
      })
      .finally(() => {})
      .catch(console.log);
  };

  const getCarriers = () => {
    getTopCarriers({ search: search, limit: 50 })
      .then((response) => {
        if (response?.data) {
          const carrierListOptions = response?.data?.map((carr: any) => ({
            label: carr?.name,
            value: carr?.id,
            image: carr?.image ? carr?.imageUrl + carr?.image : null,
            isImage: true,
          }));

          setCarrierList(carrierListOptions);
        }
      })
      .finally(() => {})
      .catch(console.log);
  };

  useEffect(() => {
    getListService();
  }, []);

  useEffect(() => {
    if (search) {
      const delayDebounceFn = setTimeout(() => {
        getCarriers();
      }, 700);

      return () => clearTimeout(delayDebounceFn);
    } else {
      getCarriers();
    }
  }, [search]);

  useEffect(() => {
    handleTotalPrice();
  }, [watchAdditionalCharges, watchCarrierId]);

  const onSubmit = (data: any) => {
    setIsLoading(true);

    const uniqueKey =
      carrierEditData?.uniqueKey || `${data.carrierId}-${Date.now()}`;

    let carrierData = {};

    if (data?.carrierId) {
      carrierData = carrier;
    }

    const formattedData = {
      ...data,
      carrierBookingDate: data.carrierBookingDate
        ? moment(data.carrierBookingDate).format('YYYY-MM-DD')
        : isUnassigned
        ? moment().format('YYYY-MM-DD')
        : null,
      budgetCost: isUnassigned ? data.budgetCost : 0,
      baseCarrierRate: !isUnassigned ? data.baseCarrierRate : 0,
      fuel: !isUnassigned ? data.fuel : 0,
      fuelPercentage: !isUnassigned ? data.fuelPercentage : 0,
      totalPrice: !isUnassigned ? data.totalPrice : 0,
    };

    handleAddCarrierPrice({
      ...formattedData,
      carrier: carrierData,
      uniqueKey,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (isUnassigned) {
      setValue('baseCarrierRate', undefined);
      setValue('totalPrice', undefined);
      setValue('fuel', undefined);
      setValue('fuelPercentage', undefined);
      replace([
        {
          id: 0,
          charge: null,
          otherCharge: null,
          price: null,
        },
      ]);

      clearErrors();
    } else {
      setValue('budgetCost', undefined);
      setValue('carrierBookingDate', undefined);
      clearErrors();
    }
  }, [isUnassigned]);

  const handleCarrierChange = () => {
    setIsUnassigned(false);
  };

  useEffect(() => {
    if (carrierEditData) {
      const updatedAdditionalCharge = carrierEditData.additional_charge.map(
        (item: any) => ({
          id: item.id,
          charge: item?.isOtherCharge ? 'other' : item.charge,
          otherCharge: item?.isOtherCharge ? item.charge : null,
          price: item.price,
        })
      );

      replace(updatedAdditionalCharge);
      setValue('baseCarrierRate', carrierEditData?.baseCarrierRate);
      setValue('totalPrice', carrierEditData?.totalPrice);
      setValue('budgetCost', carrierEditData?.budgetCost);

      if (carrierEditData?.fuel && carrierEditData?.fuel != 0) {
        setValue('fuel', carrierEditData?.fuel);
      }

      if (carrierEditData?.carrier) {
        if (carrierEditData?.carrierId === 'unassigned') {
          setCarrier(null);
          setIsUnassigned(true);
        } else {
          setCarrier(carrierEditData?.carrier);
        }
      }

      if (
        carrierEditData?.fuelPercentage &&
        carrierEditData?.fuelPercentage != 0
      ) {
        setValue('fuelPercentage', carrierEditData?.fuelPercentage);
      }

      setIsFieldsInitialized(true);
    } else {
      // setIsFieldsInitialized(true);
    }
  }, [carrierEditData]);

  useEffect(() => {
    if (!carrierEditData && serviceList && serviceList?.length > 0) {
      if (additionalServices?.length > 0) {
        const filteredServices = services.filter((service: any) =>
          additionalServices?.includes(service?.id)
        );

        const additionalCharge = filteredServices.map((service: any) => ({
          id: 0,
          charge: service.slug,
          otherCharge: null,
          price: 0,
        }));

        replace(additionalCharge);
      }

      setIsFieldsInitialized(true);
    }
  }, [serviceList, isUnassigned]);

  useEffect(() => {
    if (carrierEditData?.carrierId) {
      setValue('carrierId', carrierEditData?.carrierId);

      if (carrierEditData?.carrierId === 'unassigned') {
        setIsUnassigned(true);
      }
    }
  }, [carrierEditData?.carrierId]);

  useEffect(() => {
    if (
      carrierEditData?.carrierId !== 'unassigned' &&
      carrierEditData?.carrier
    ) {
      const selectedCarrier = {
        label: carrierEditData.carrier.name,
        value: carrierEditData.carrier.id,
        image: carrierEditData.carrier.image || null,
        isImage: true,
      };

      setCarrierList((prevList) => {
        const exists = prevList?.some(
          (item) => item.value === selectedCarrier.value
        );

        return exists ? prevList : [...prevList, selectedCarrier];
      });
    }
  }, [carrierEditData?.carrier, carrierList]);

  const handleTabChange = () => {
    setActiveMarginType((prevType: any) => {
      const newType = prevType === '$' ? '%' : '$';

      const totalPrice = Number(getValues('totalPrice')) || 0;
      const currentFuel = Number(getValues('fuel')) || 0;
      const currentFuelPercentage = Number(getValues('fuelPercentage')) || 0;

      if (newType === '%') {
        const calculatedPercentage =
          totalPrice > 0 ? (currentFuel / totalPrice) * 100 : 0;
        setValue('fuelPercentage', parseFloat(calculatedPercentage.toFixed(2)));
      } else {
        const calculatedFuel = (totalPrice * currentFuelPercentage) / 100;
        setValue('fuel', parseFloat(calculatedFuel.toFixed(2)));
      }

      return newType;
    });
  };

  const fuelInput = useMemo(
    () =>
      activeMarginType === '$' ? (
        <Controller
          name="fuel"
          control={control}
          render={({ field: { onChange } }) => (
            <InputText
              inputType="number"
              inputName="fuel"
              placeholder="Enter Fuel Value"
              parentClassName=""
              className="!border-0 !h-auto !pl-8 !pr-20"
              value={watchFuel}
              onChangeFunc={(val) => {
                onChange(val);

                if (val?.target?.value) {
                  const calculatedPercentage =
                    watchTotalPrice > 0
                      ? (val?.target?.value / watchTotalPrice) * 100
                      : 0;

                  setValue(
                    'fuelPercentage',
                    parseFloat(calculatedPercentage.toFixed(2))
                  );
                } else {
                  setValue('fuelPercentage', 0);
                }
              }}
              onBlur={handleTotalPrice}
              icon={<CurrencyDollar className="input-currency-sign" />}
            />
          )}
        />
      ) : (
        <Controller
          name="fuelPercentage"
          control={control}
          render={({ field: { onChange } }) => (
            <InputText
              inputType="number"
              inputName="fuelPercentage"
              placeholder="Enter Fuel Percentage"
              parentClassName=""
              className="!border-0 !h-auto !pl-8 !pr-20"
              value={watchFuelPercentage}
              onChangeFunc={(val) => {
                onChange(val);

                if (val?.target?.value && val?.target?.value > 0) {
                  const calculatedFuel =
                    (watchTotalPrice * val?.target?.value) / 100;
                  setValue('fuel', parseFloat(calculatedFuel.toFixed(2)));
                } else {
                  setValue('fuel', 0);
                }
              }}
              onBlur={handleTotalPrice}
              icon={<Percent02 className="input-currency-sign" />}
            />
          )}
        />
      ),
    [activeMarginType, control, handleTotalPrice]
  );

  return (
    <CommonModal
      title={carrierEditData ? 'Edit a Carrier' : 'Add a Carrier'}
      titleDesc="Attach a carrier to this order, if applicable at this time."
      handleClose={handleClose}
      headerIcon={<PackagePlus />}
      size={'xl:max-w-[606px] md:max-w-[536px] max-w-[496px]'}
      primaryBtnText={carrierEditData ? 'Save' : 'Assign'}
      secondaryBtnText="Cancel"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      secondaryBtnOnClick={handleClose}
      // isOverflow={false}
    >
      <>
        <FormProvider {...methods}>
          <div className="p-5 flex flex-col gap-y-4">
            <Controller
              name="carrierId"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SelectBox
                  label="Select Carrier"
                  selectOptionSubLabelClass="!font-normal !text-[11px]"
                  selectInputSubLabelClass="!font-normal !text-grayLight600 !text-[11px]"
                  labelRight={
                    <>
                      <CheckBox
                        id="unassignedCarrier"
                        labelHtmlFor="unassignedCarrier"
                        labelClassName="!text-primary700"
                        label="Unassigned Carrier"
                        checked={isUnassigned}
                        onChangeFunc={(e: any) => {
                          if (e?.target?.checked) {
                            setValue('carrierId', 'unassigned');
                            setIsUnassigned(true);
                            setCarrier(null);
                            clearErrors('carrierId');
                          } else {
                            setValue('carrierId', undefined);
                            setIsUnassigned(false);
                            setCarrier(null);
                          }
                        }}
                      />
                    </>
                  }
                  labelClassName="form_label block mb-1.5"
                  name="customerId"
                  id="customerId"
                  size="sm"
                  placeholder="All Carrier"
                  noOptionMessage="No Carrier Found"
                  isSearchable={true}
                  className="form_control"
                  options={carrierList}
                  onChangeFunc={(e: any) => {
                    onChange(e?.value);
                    handleCarrierChange();

                    if (e) {
                      setCarrier({
                        name: e?.label,
                        id: e?.value,
                        image: e?.image,
                      });
                    } else {
                      setCarrier(null);
                    }
                  }}
                  onInputChange={(e: any) => {
                    setSearch(e);
                  }}
                  value={
                    value && value !== 'unassigned'
                      ? carrierList?.find((e) => e?.value == value)
                      : null
                  }
                  errorText={error ? error.message : null}
                  required
                  isClearable
                />
              )}
            />

            {!isUnassigned && (
              <>
                <Controller
                  name="baseCarrierRate"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputText
                      label="Base Cost"
                      inputName="baseCarrierRate"
                      inputType="number"
                      placeholder="Enter Base Cost"
                      parentClassName=""
                      className="pl-8"
                      value={`${value}`}
                      onChangeFunc={(e) =>
                        handleInputChange(e, 'change', onChange)
                      }
                      onFocus={() => {
                        if (value === 0) {
                          onChange('');
                        }
                      }}
                      onBlur={handleTotalPrice}
                      errorText={error ? error.message : null}
                      labelClassName="block mb-1.5"
                      required
                      icon={<CurrencyDollar className="input-currency-sign" />}
                    />
                  )}
                />

                {isFieldsInitialized && (
                  <AdditionalCharge
                    isSelectBox={true}
                    serviceList={serviceList}
                    onBlur={handleTotalPrice}
                  />
                )}

                <div className="">
                  <label className="form_label block mb-1.5">Fuel</label>
                  <div className="form_control p-0 relative !h-auto">
                    {fuelInput}

                    <TabButton
                      tabParentClassName="absolute top-1/2 -translate-y-1/2 right-1"
                      parentClassName="!bg-gray50 !p-[3px]"
                      className={`!rounded-md text-gray500 !border-0 !px-[8px] !py-0.5 !text-[11px] cursor-pointer first:font-normal [&>span]:text-gray500 !min-h-[auto]`}
                      activeClassName=" !bg-white [&>span]:!text-grayLight900 !shadow-md "
                      tabArray={tabArray}
                      isActive={activeMarginType}
                      isTab={true}
                      handleOnClick={handleTabChange}
                    />
                  </div>

                  {errors?.fuel?.message && (
                    <ErrorMsg errorText={errors?.fuel?.message} />
                  )}
                </div>

                <Controller
                  name="totalPrice"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputText
                      label="Total Cost"
                      inputName="total_sale_price"
                      placeholder="Enter Total Cost"
                      parentClassName=""
                      className="pl-8 w-full"
                      value={`${value}`}
                      onChangeFunc={(e) =>
                        handleInputChange(e, 'change', onChange)
                      }
                      onFocus={() => {
                        if (value === 0) {
                          onChange('');
                        }
                      }}
                      errorText={error ? error.message : null}
                      labelClassName="block mb-1.5"
                      icon={<CurrencyDollar className="input-currency-sign" />}
                      readOnly
                    />
                  )}
                />
              </>
            )}

            {isUnassigned && (
              <>
                <Controller
                  name="budgetCost"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputText
                      label="Budget Cost"
                      inputType="number"
                      inputName="budgetCost"
                      placeholder="Enter Budget Cost"
                      parentClassName=""
                      className="pl-8"
                      value={`${value}`}
                      onChangeFunc={(e) =>
                        handleInputChange(e, 'change', onChange)
                      }
                      onFocus={() => {
                        if (value === 0) {
                          onChange('');
                        }
                      }}
                      errorText={error ? error.message : null}
                      labelClassName="block mb-1.5"
                      shouldFormatNumber={true}
                      icon={<CurrencyDollar className="input-currency-sign" />}
                    />
                  )}
                />

                <Controller
                  name="carrierBookingDate"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <DateSelect
                      inputName="carrierBookingDate"
                      className="form_control"
                      parentClassName="datepicker-w-auto one-month-datepicker w-full"
                      label="Carrier Booking Date"
                      selected={value}
                      onChangeFunc={(e: any) => onChange(e?.value)}
                      minDate={moment().toDate()}
                      labelClassName="block"
                      placeholder="Select Date"
                      dateFormat="dd/MM/yyyy"
                      errorText={error?.message}
                      icon={<Calendar className="h-5 w-5" />}
                      value={getDateWithSuffixFormat(
                        getFormattedPickupDate(value)
                      )}
                    />
                  )}
                />
              </>
            )}
          </div>
        </FormProvider>
      </>
    </CommonModal>
  );
};

export default CarrierModal;
