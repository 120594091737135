import React from 'react';

import AddressDetails from './AddressDetails';

interface IProps {
  action: any;
  setAction: any;
  shipperAddress: any;
  setShipperAddress: any;
  consigneeAddress: any;
  setConsigneeAddress: any;
  currentStep: any;
}

const Step2AndStep3 = ({
  action,
  setAction,
  shipperAddress,
  setShipperAddress,
  consigneeAddress,
  setConsigneeAddress,
  currentStep,
}: IProps) => (
  <AddressDetails
    action={action}
    setAction={setAction}
    shipperAddress={shipperAddress}
    setShipperAddress={setShipperAddress}
    consigneeAddress={consigneeAddress}
    setConsigneeAddress={setConsigneeAddress}
    currentStep={currentStep}
  />
);

export default Step2AndStep3;
