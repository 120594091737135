import {
  PresentationChart03,
  SearchLg,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CommonModal from 'src/components/CommonModal';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import TableCmp from 'src/components/TableCmp';
import { getLeaderBoardNewCustomerList } from 'src/services/SalesDashboard';

import NewCustomerLBRow from './NewCustomerLBRow';
import ReassignCustomerLoaderRow from './ReassignCustomerLoaderRow';

const initParams = {
  page: 1,
  limit: 10,
  sortType: 'asc',
  sortField: 'name',
  search: '',
};

interface IProps {
  leaderBoardEventDetail: any;
  handleClose: (value: boolean) => void;
}

const LeaderboardNewCustomersManagement = ({
  leaderBoardEventDetail,
  handleClose,
}: IProps) => {
  const [params, setParams] = useState(initParams);
  const [isListLoading, setIsListLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');

  const getCustomerList = () => {
    if (!isRefresh) {
      return;
    }

    setIsListLoading(true);
    setCustomers([]);

    getLeaderBoardNewCustomerList(params)
      .then((result: any) => {
        setCustomers(result.data);
        setTotal(result.total);
      })
      .finally(() => {
        setIsRefresh(false);
        setIsListLoading(false);
      })
      .catch(console.error);
  };

  useEffect(() => {
    getCustomerList();
  }, [isRefresh]);

  useEffect(() => {
    setIsRefresh(true);
  }, [params]);

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Customer Name',
        sortable: true,
      },
      {
        id: 'salesRepFullName',
        name: 'Sales Representative',
        sortable: true,
      },
      {
        id: 'customerCreatedAt',
        name: 'Customer Created At',
        sortable: true,
      },
      {
        id: 'firstOrderFullID',
        name: 'First Order Id',
        sortable: true,
      },
      {
        id: 'firstOrderCreatedAt',
        name: 'First Order Created At',
        sortable: true,
      },
      {
        id: 'action',
        name: '',
        sortable: false,
      },
    ],
    []
  );

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch, page: 1 } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { value } = event.target;

    setSearch(value);
    searchDebounce(value);
  };

  return (
    <CommonModal
      handleClose={() => (isListLoading ? false : handleClose(false))}
      headerIcon={<PresentationChart03 />}
      size={'max-w-[980px]'}
      isOverflow={true}
    >
      <div className="w-full p-5">
        <div
          className={`w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col`}
        >
          <div className="table-top-header relative">
            <div className="table-left-wrap max-smd:w-full max-sm:mb-2">
              <div className="table-title-wrap flex-wrap">
                <h5 className="table-title max-md:w-full">
                  Reassign Customers
                </h5>
                <p className="table-subtitle mt-0 hidden max-md:block w-full">
                  Manage your Unassigned customers.
                </p>
                <BadgeCmp style="modern" type="success" mainClassName="">
                  {total} unassigned customers
                </BadgeCmp>
              </div>
              <p className="table-subtitle max-md:hidden">
                Manage your Unassigned Orders.
              </p>
            </div>
            <div>
              <InputText
                inputName="search"
                placeholder="Search"
                className="pl-[26px] placeholder:text-secondaryTxtColor border-gray400"
                isClearable={true}
                value={search}
                inputType="text"
                onChangeFunc={handleFilter}
                icon={
                  <SearchLg className="absolute -translate-y-2/4 top-2/4 left-[7px] text-grayText h-4 w-4" />
                }
              />
            </div>
            <div className="gap-3 flex flex-wrap flex-none sm:w-auto w-full items-center">
              <XClose
                className="text-gray400 cursor-pointer max-sm:absolute max-sm:top-4 max-sm:right-4"
                onClick={() => (isListLoading ? false : handleClose(false))}
              />
            </div>
          </div>
          <div className="h-[calc(100vh_-_353px)] min-h-[200px] w-full flex">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
              <TableCmp
                headCells={headCells}
                params={params}
                setParams={setParams}
                tableDataArr={customers}
                TableRowCmp={NewCustomerLBRow}
                TableLoaderRowCmp={ReassignCustomerLoaderRow}
                tableHeaderClass="sticky top-0 z-[1] shadow-[inset_0_-1px_0_rgba(234,236,240,1)] [&>tr]:border-0"
                isTableDataLoading={isListLoading}
                tableRowCmpProps={{
                  leaderBoardEventDetail: leaderBoardEventDetail,
                  setIsRefresh: setIsRefresh,
                }}
                numberOfSkeletonRows={10}
              />
            </div>
          </div>

          <div className="w-full ">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={total}
              currentPage={params.page}
              handlePagination={(page: number) => {
                setParams((old) => ({ ...old, page }));
              }}
            />
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default LeaderboardNewCustomersManagement;
