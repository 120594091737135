import { ArrowUp, Move } from '@untitled-ui/icons-react/build/cjs';
import mapboxgl, { SourceSpecification } from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import ButtonCmp from 'src/components/ButtonCmp';
import SearchBox from 'src/components/SearchBox';
import MapFilterModal from 'src/components/SearchBox/MapFilterModal';
// import { getPopulationData } from 'src/services/GeographyService';
import { setPricingDetail } from 'src/services/GeographyService';
import { getTime } from 'src/utils/CommonFunctions';

import defaultImage from '../../../assets/img/default-image.jpg';
import defaultPackageImage from '../../../assets/img/packagebox.svg';
import defaultPackageImageWhite from '../../../assets/img/packageBoxWhite.svg';

import CustomiseMapModal from './CustomiseMapModal';
import LaneOverview from './LaneOverview';

const initFiltes = {
  max_height: null,
  max_width: null,
  max_weight: null,
  exclude: [],
};

const MapBox = ({
  setCustomerId,
  inTransitOrderId,
  setInTransitOrderId,
  searchResult,
  customers,
  inTransitOrders,
  setParams,
  mapParams,
  setHasSearched,
  setSearchResult,
  isDisplayDetail,
  setIsDisplayDetail,
  mapFilters,
  setRouteFilters,
  setAllRoutes,
  routeFilters,
  // allRoutes,
  hasSearched,
}: any) => {
  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;
  const filterRef: any = useRef(null);

  const [predictivePricingDetail, setPredictivePricingDetail] = useState(null);
  const [filters, setFilters] = useState(initFiltes);
  // const googleMapsApiKey = window?.GOOGLE_API_KEY;
  // const [fromLong, setFromLong] = useState(0);

  // const [fromLat, setFromLat] = useState(0);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  // const [customers, setCustomers] = useState<any>();

  const [customerMarkerPin, setCustomerMarkerPin] = useState<any>([]);
  const [orderMarkerPin, setOrderMarkerPin] = useState<any>();
  const [isShowMapFilterModal, setIsShowMapFilterModal] = useState(false);
  const [isShowCustomiseMapModal, setIsShowCustomiseMapModal] = useState(false);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  // const [isCustomerLoading, setIsCustomerLoading] = useState(false);

  const handleClear = () => {
    setIsDisplayDetail(false);
    setFilters(initFiltes);

    if (abortController) {
      abortController.abort(); // Abort the ongoing requests
      setAbortController(null); // Reset the controller if needed
    }
  };

  const clearMapSearchRoute = () => {
    handleClear();
    // setHasSearched(false);
    // setSearchResult(null);
    orderMarkerPin?.remove();

    if (mapRef.current?.getLayer('route')) {
      mapRef.current.removeLayer('route');
    }

    if (mapRef.current?.getSource('route')) {
      mapRef.current.removeSource('route');
    }
    orderMarkerPin?.remove();
  };

  useEffect(() => {
    if (!mapFilters?.isDisplayTransit && orderMarkerPin) {
      clearMapSearchRoute();
      orderMarkerPin.remove();
    }
  }, [mapFilters]);

  useEffect(() => {
    let timeOutId: any;
    let handleOutsideClick: any;
    let handleEscapePress: any;

    if (isShowMapFilterModal) {
      setTimeout(() => {
        handleOutsideClick = (event: any) => {
          if (
            isShowMapFilterModal &&
            filterRef.current &&
            !filterRef.current.contains(event.target)
          ) {
            setIsShowMapFilterModal(false);
          }
        };

        handleEscapePress = (event: any) => {
          if (isShowMapFilterModal && event.key === 'Escape') {
            setIsShowMapFilterModal(false);
          }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('keydown', handleEscapePress);
      }, 0);
    }

    return () => {
      clearTimeout(timeOutId);
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [isShowMapFilterModal]);

  const initializeMap = useCallback(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [-95, 55],
        zoom: 4,
        pitch: 0,
        bearing: 0,
        boxZoom: true,
        antialias: true,
        projection: 'mercator', // Change projection to flat
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl());

      mapRef.current.on('load', () => {
        setMapLoaded(true);
        const layers = mapRef.current?.getStyle()?.layers;
        const labelLayer = layers?.find(
          (layer: any) => layer.type === 'symbol' && layer.layout['text-field']
        );

        if (labelLayer && mapRef.current) {
          mapRef.current.addLayer(
            {
              id: 'add-3d-buildings',
              source: 'composite',
              'source-layer': 'building',
              filter: ['==', 'extrude', 'true'],
              type: 'fill-extrusion',
              minzoom: 15,
              paint: {
                'fill-extrusion-color': '#aaa',
                'fill-extrusion-height': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  15,
                  0,
                  15.05,
                  ['get', 'height'],
                ],
                'fill-extrusion-base': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  15,
                  0,
                  15.05,
                  ['get', 'min_height'],
                ],
                'fill-extrusion-opacity': 0.6,
              },
            },
            labelLayer.id
          );
        }

        if (mapRef.current) {
          mapRef.current.addSource('points', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [-77.03238901390978, 38.913188059745586],
                  },
                  properties: {
                    title: 'Mapbox DC',
                  },
                },
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [-122.414, 37.776],
                  },
                  properties: {
                    title: 'Mapbox SF',
                  },
                },
              ],
            },
          });
        }
      });
    }
  }, []);

  // Function to calculate distance between two points using Haversine formula
  // const getDistance = async (
  //   point1: [number, number],
  //   point2: [number, number],
  //   controller: any // Add the AbortController here
  // ): Promise<number> => {
  //   const [lon1, lat1] = point1;
  //   const [lon2, lat2] = point2;

  //   try {
  //     const query = await fetch(
  //       `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${lon1},${lat1};${lon2},${lat2}?access_token=${mapboxgl.accessToken}&geometries=geojson`,
  //       { signal: controller?.signal } // Pass signal here
  //     );

  //     const json = await query.json();

  //     // Extract the distance (in meters) from the response
  //     const distance = json?.routes?.[0]?.distance; // Distance in meters

  //     // Convert the distance to kilometers
  //     return distance ? distance / 1000 : 0;
  //   } catch (error: any) {
  //     if (error.name !== 'AbortError') {
  //       console.error('Error fetching distance data:', error);
  //     } else {
  //       console.log('Distance request aborted');
  //     }

  //     return 0;
  //   }
  // };

  // Function to get population from Google Places API using latitude and longitude
  // const getPopulation = async (lat: number, lng: number) => {
  //   try {
  //     const apiParams = { lat, lng };

  //     try {
  //       const population = await getPopulationData(apiParams)
  //         .then((response) => response?.data?.population || 0)
  //         .catch((error) => {
  //           console.error('Error fetching population data:', error);

  //           return 0; // Return 'Unknown' on error
  //         });

  //       return population;
  //     } catch (error) {
  //       console.error('Error in getPopulation function:', error);

  //       return 0;
  //     }
  //   } catch (error) {
  //     console.error('Error fetching population data:', error);

  //     return 'Unknown';
  //   }
  // };

  // Function to geocode an address to coordinates
  const geocodeAddress = async (
    address: string
  ): Promise<[number, number, string]> => {
    const query = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${mapboxgl.accessToken}`
    );
    const json = await query.json();

    if (!json.features || json.features.length === 0) {
      throw new Error(`Unable to geocode address: ${address}`);
    }

    const coordinates = json.features[0].center as [number, number];
    const placeId = json.features[0].id as string;

    return [coordinates[0], coordinates[1], placeId];
  };

  // function getCityPopulation(cityName: string) {
  //   const geoNamesUrl = `http://api.geonames.org/searchJSON?q=${encodeURIComponent(
  //     cityName
  //   )}&maxRows=1&username=bhuvabhavana`;

  //   return fetch(geoNamesUrl)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const cityData = data.geonames[0];

  //       if (cityData) {
  //         return cityData.population;

  //         // return {
  //         //   city: cityName,
  //         //   distance: distance.toFixed(2),
  //         //   population: population,
  //         // };
  //       }

  //       return null;
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching population data:', error);

  //       return null;
  //     });
  //   // return 100;
  // }

  // Function to find cities along the route within a specified radius
  // const getCitiesAlongRoute = async ({
  //   route,
  //   radius,
  //   controller,
  // }: {
  //   route: [number, number][];
  //   radius: number;
  //   controller?: any; // Pass the AbortController here
  // }) => {
  //   const cities: any = [];
  //   const checkedCities = new Set();

  //   // const numSamples = Math.min(2000, route.length);
  //   // const sampleInterval = Math.max(1, Math.floor(route.length / numSamples));
  //   let numSamples = 200;

  //   // if (route.length >= 500 && route.length <= 1000) {
  //   //   numSamples = 50;
  //   // } else if (route.length >= 1000 && route.length <= 10000) numSamples = 50;
  //   // else if (route.length >= 10000 && route.length <= 25000) numSamples = 150;
  //   // else if (route.length >= 25000 && route.length <= 50000) numSamples = 250;
  //   // else if (route.length >= 50000 && route.length <= 100000) numSamples = 500;
  //   // else if (route.length >= 100000 && route.length <= 1000000)
  //   //   numSamples = 1000;
  //   // else numSamples = 1500;

  //   const sampleInterval = numSamples;

  //   const addCity = (
  //     cityName: string,
  //     fullName: string,
  //     straightLineDistance: number,
  //     population: number,
  //     cityCoords: [number, number]
  //   ) => {
  //     const cityInfo = {
  //       name: cityName,
  //       fullName,
  //       distance: straightLineDistance,
  //       population: population !== null ? population : 'Unknown',
  //       coordinates: cityCoords,
  //     };
  //     cities.push(cityInfo);
  //     checkedCities.add(fullName);
  //   };

  //   for (let i = 0; i < route.length; i += sampleInterval) {
  //     const [lon, lat] = route[i];

  //     try {
  //       const query = await fetch(
  //         `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?types=place&limit=5&access_token=${mapboxgl.accessToken}`,
  //         { signal: controller?.signal } // Pass signal here
  //       );
  //       const json = await query.json();

  //       if (json.features && json.features.length > 0) {
  //         for (const feature of json.features) {
  //           const cityName = feature.text;
  //           const fullName = feature.place_name || 'Unknown Place';
  //           const cityCoords = feature.center;

  //           if (fullName && !checkedCities.has(fullName)) {
  //             const straightLineDistance = await getDistance(
  //               route[0],
  //               cityCoords,
  //               abortController // Pass controller to getDistance
  //             );

  //             // console.log('population:: ', cityName, '  ', population);

  //             if (radius > 0) {
  //               if (straightLineDistance <= radius) {
  //                 const population = await getPopulation(lat, lon);
  //                 console.log('population:: ', population);

  //                 if (routeFilters?.population > 0) {
  //                   if (population <= routeFilters?.population) {
  //                     addCity(
  //                       cityName,
  //                       fullName,
  //                       straightLineDistance,
  //                       population,
  //                       cityCoords
  //                     );
  //                   }
  //                 } else {
  //                   addCity(
  //                     cityName,
  //                     fullName,
  //                     straightLineDistance,
  //                     population,
  //                     cityCoords
  //                   );
  //                 }
  //               }
  //             } else if (routeFilters?.population > 0) {
  //               const population = await getPopulation(lat, lon);

  //               if (population <= routeFilters?.population) {
  //                 addCity(
  //                   cityName,
  //                   fullName,
  //                   straightLineDistance,
  //                   population,
  //                   cityCoords
  //                 );
  //               }
  //             } else {
  //               console.log('else');
  //               const population = await getPopulation(lat, lon);
  //               addCity(
  //                 cityName,
  //                 fullName,
  //                 straightLineDistance,
  //                 population,
  //                 cityCoords
  //               );
  //             }
  //           }
  //         }
  //       }
  //     } catch (error: any) {
  //       if (error.name !== 'AbortError') {
  //         console.error('Error fetching place data:', error);
  //       } else {
  //         console.log('Request aborted');
  //       }
  //     }
  //   }
  //   console.log('cities', cities);
  //   const sortedCities = cities
  //     .sort((a: any, b: any) => (b.population || 0) - (a.population || 0))
  //     .slice(0, 10);

  //   return sortedCities;
  // };

  const setCustomerMarker = async () => {
    let customerMarkers: mapboxgl.Marker[] = [];

    for (let i = 0; i < customers.length; i++) {
      const customer = customers[i];
      // console.log('customer', customer);
      // let custAddress = {
      //   address1: customer?.address1,
      //   city: customer?.city,
      //   postal: customer?.postal,
      //   state: customer?.state,
      //   countryCode: customer?.country,
      // };
      let fullAddress = [
        customer.address1,
        customer.city,
        customer.state,
        customer.postal,
        customer.countryCode,
      ]
        .filter(Boolean)
        .join(', ');

      // console.log('custAddress', fullAddress);

      const cityCoords = await geocodeAddress(fullAddress);
      const el = document.createElement('div');
      el.className = 'marker';

      const parentDiv = document.createElement('div');
      parentDiv.className = 'parent-marker'; // Assign a class for additional styling
      parentDiv.id = customer?.id;
      el.id = customer?.id;
      const csutomerImageDiv = document.createElement('div');
      csutomerImageDiv.className = 'customer-image'; // Assign a class for additional styling
      csutomerImageDiv.id = customer?.id;

      // Set marker image based on customer data
      if (customer.image) {
        el.style.backgroundImage = `url(${customer.imageUrl + customer.image})`;
      } else {
        el.style.backgroundImage = `url(${defaultImage})`;
      }
      csutomerImageDiv.appendChild(el); // Append the marker to the parent div
      parentDiv.appendChild(csutomerImageDiv); // Append the marker to the parent div

      // Create marker
      const marker = new mapboxgl.Marker(parentDiv)
        .setLngLat([cityCoords[0], cityCoords[1]])
        .addTo(mapRef.current!);

      // if (navigator.geolocation) {
      //   navigator.geolocation.watchPosition(
      //     function (position) {
      //       const lng = position.coords.longitude;
      //       const lat = position.coords.latitude;

      //       // Safely update marker's position
      //       marker.setLngLat([lng, lat]);

      //       // Center the map at the new location
      //       mapRef?.current?.setCenter([lng, lat]);
      //     },
      //     function (error) {
      //       console.error('Error watching position: ', error);
      //     },
      //     {
      //       enableHighAccuracy: true, // Enable high accuracy for better precision
      //     }
      //   );
      // } else {
      //   console.error('Geolocation is not supported by this browser.');
      // }

      // Add click event listener to the marker
      marker.getElement().addEventListener('click', (event: any) => {
        const elementId = event.target.id;

        setCustomerId(elementId);
        if (!isDisplayDetail) setIsDisplayDetail(true);
      });

      // Add zoom out functionality to the zoom out button
      document
        .getElementById(`zoomOutButton_${customer.id}`)
        ?.addEventListener('click', function (event) {
          event.preventDefault(); // Prevent default link behavior
          // Assuming you have a map object, apply zoom out
          if (mapRef?.current)
            mapRef?.current.setZoom(mapRef?.current.getZoom() + 2); // Adjust this method according to your map library
        });

      document
        .getElementById(`backButton_${customer.id}`)
        ?.addEventListener('click', function (event) {
          event.preventDefault(); // Prevent default link behavior
          let ele = document.getElementById(`customer_${customer.id}`);

          if (ele) {
            ele.classList.add('d-none'); // Toggle the 'd-none' class to show/hide the card box
          }
        });

      marker.getElement().addEventListener('click', (event: any) => {
        const elementId = event.target.id;
        setIsDisplayDetail(true);
        setCustomerId(elementId);
      });

      customerMarkers.push(marker);

      if (i >= customers.length - 1) {
        marker.setLngLat([cityCoords[0], cityCoords[1]]);
        mapRef?.current?.setCenter([cityCoords[0], cityCoords[1]]);
        mapRef?.current?.setZoom(5);
      }

      // Center the map at the new location
      // mapRef?.current?.flyTo({
      //   center: [cityCoords[0], cityCoords[1]],
      //   zoom: 14,
      // });
    }

    setCustomerMarkerPin(customerMarkers);
  };

  const setInTransitOrdersMarker = async () => {
    let customerMarkers: mapboxgl.Marker[] = [];
    console.log('setInTransitOrdersMarker');

    for (let i = 0; i < inTransitOrders.length; i++) {
      const order = inTransitOrders[i];

      const cityCoords = await geocodeAddress(order.shipperFullAddress);
      const el = document.createElement('div');
      el.className = 'marker';

      const parentDiv = document.createElement('div');
      parentDiv.className = 'parent-marker'; // Assign a class for additional styling
      parentDiv.id = order?.id;
      el.id = order?.id;
      const csutomerImageDiv = document.createElement('div');
      csutomerImageDiv.className = 'customer-image customer-package'; // Assign a class for additional styling
      csutomerImageDiv.id = order?.id;

      // Set marker image based on customer data

      el.style.backgroundImage = `url(${defaultPackageImage})`;

      csutomerImageDiv.appendChild(el); // Append the marker to the parent div
      parentDiv.appendChild(csutomerImageDiv); // Append the marker to the parent div

      // Create marker
      const marker = new mapboxgl.Marker(parentDiv)
        .setLngLat([cityCoords[0], cityCoords[1]])
        .addTo(mapRef.current!);

      // Add click event listener to the marker
      marker.getElement().addEventListener('click', (event: any) => {
        const elementId = event.target.id;

        setInTransitOrderId(elementId);
        if (!isDisplayDetail) setIsDisplayDetail(true);
      });

      // Add zoom out functionality to the zoom out button
      document
        .getElementById(`zoomOutButton_${order.id}`)
        ?.addEventListener('click', function (event) {
          event.preventDefault(); // Prevent default link behavior
          // Assuming you have a map object, apply zoom out
          if (mapRef?.current)
            mapRef?.current.setZoom(mapRef?.current.getZoom() + 2); // Adjust this method according to your map library
        });

      document
        .getElementById(`backButton_${order.id}`)
        ?.addEventListener('click', function (event) {
          event.preventDefault(); // Prevent default link behavior
          let ele = document.getElementById(`customer_${order.id}`);

          if (ele) {
            ele.classList.add('d-none'); // Toggle the 'd-none' class to show/hide the card box
          }
        });

      marker.getElement().addEventListener('click', (event: any) => {
        const elementId = event.target.id;
        setIsDisplayDetail(true);
        setInTransitOrderId(elementId);
      });

      customerMarkers.push(marker);

      if (i >= customers.length - 1) {
        marker.setLngLat([cityCoords[0], cityCoords[1]]);
        mapRef?.current?.setCenter([cityCoords[0], cityCoords[1]]);
        mapRef?.current?.setZoom(5);
      }
    }

    setCustomerMarkerPin(customerMarkers);
  };

  const removeCustomerMarkers = () => {
    orderMarkerPin?.remove();
    customerMarkerPin.forEach((marker: any) => {
      // console.log('marker pin', marker);
      marker.remove();
    });
    setCustomerMarkerPin([]);
  };

  useEffect(() => {
    removeCustomerMarkers();

    if (customers?.length > 0) {
      handleClear();
      setHasSearched(false);
      setSearchResult(null);

      if (mapRef.current?.getLayer('route')) {
        mapRef.current.removeLayer('route');
      }

      if (mapRef.current?.getSource('route')) {
        mapRef.current.removeSource('route');
      }
      setCustomerMarker();
    }
  }, [customers]);

  useEffect(() => {
    removeCustomerMarkers();

    if (inTransitOrders?.length > 0) {
      handleClear();
      console.log('inTransitOrders');
      setHasSearched(false);
      setSearchResult(null);

      if (mapRef.current?.getLayer('route')) {
        mapRef.current.removeLayer('route');
      }

      if (mapRef.current?.getSource('route')) {
        mapRef.current.removeSource('route');
      }
      setInTransitOrdersMarker();
    }
  }, [inTransitOrders]);

  const getOrderDetail = async () => {
    await removeCustomerMarkers();

    if (inTransitOrders) {
      let order = inTransitOrders.find(
        (val: any) => val.id == inTransitOrderId
      );

      if (!mapLoaded || !mapRef.current) {
        console.warn('Map not fully loaded yet');

        return;
      }

      try {
        const [fromLongitude, fromLatitude] = await geocodeAddress(
          order?.shipperFullAddress
        );
        const [toLongitude, toLatitude] = await geocodeAddress(
          order?.consigneeFullAddress
        );

        if (mapRef.current?.getLayer('route')) {
          mapRef.current.removeLayer('route');
        }

        if (mapRef.current?.getSource('route')) {
          mapRef.current.removeSource('route');
        }

        const query = await fetch(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${fromLongitude},${fromLatitude};${toLongitude},${toLatitude}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
          { method: 'GET' }
        );

        const json = await query.json();

        if (!json.routes || json.routes.length === 0) {
          throw new Error('No route found between the specified locations.');
        }

        const data = json.routes[0];
        const route = data.geometry.coordinates;

        const geojson: SourceSpecification = {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: route,
            },
          },
        };

        mapRef.current?.addSource('route', geojson);
        mapRef.current?.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#444CE7',
            'line-width': 3,
            'line-opacity': 0.75,
          },
        });

        const bounds = new mapboxgl.LngLatBounds(route[0], route[0]);

        for (const coord of route) {
          bounds.extend(coord);
        }

        const centerIndex = Math.floor(route.length / 2);
        const centerCoordinates = route[centerIndex];

        const el = document.createElement('div');
        el.className = 'marker';

        const parentDiv = document.createElement('div');
        parentDiv.className = 'parent-marker'; // Assign a class for additional styling
        parentDiv.id = order?.id;
        el.id = order?.id;
        const csutomerImageDiv = document.createElement('div');
        csutomerImageDiv.className = 'customer-image intransit-image'; // Assign a class for additional styling
        csutomerImageDiv.id = order?.id;

        // Set marker image based on customer data

        el.style.backgroundImage = `url(${defaultPackageImageWhite})`;

        csutomerImageDiv.appendChild(el); // Append the marker to the parent div
        parentDiv.appendChild(csutomerImageDiv); // Append the marker to the par

        // Add a marker at the center point
        const marker = new mapboxgl.Marker(parentDiv)
          .setLngLat(centerCoordinates)
          .addTo(mapRef.current);

        setOrderMarkerPin(marker);

        let routeDistance = Math.round(data.distance / 1000);

        if (routeDistance > 1000 && routeDistance < 2200) {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            zoom: 5,
            linear: true,
          });
        } else if (routeDistance >= 2200 && routeDistance < 3500) {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            zoom: 4,
            linear: true,
          });
        } else if (routeDistance >= 3500 && routeDistance < 4000) {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            zoom: 4,
            linear: true,
          });
        } else if (routeDistance >= 4000) {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            zoom: 3.5,
            linear: true,
          });
        } else {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            // zoom: routeDistance > 3000 ? 3.5 : 4,
            linear: true,
          });
        }

        setIsDisplayDetail(true);
        setSearchResult((old: any) => ({
          ...old,
          status: 'success',
          // cities: cities,
        }));
      } catch (error: any) {
        console.error('Error in handleSearch:', error);
        setSearchResult({
          status: 'error',
          error: `Failed to fetch route: ${error.message}`,
        });
      }
    }
  };

  useEffect(() => {
    if (
      (!isDisplayDetail && mapFilters?.isDisplayTransit) ||
      (!isDisplayDetail && mapFilters?.isDisplayCustomers)
    ) {
      clearMapSearchRoute();
      orderMarkerPin?.remove();

      // if (mapFilters?.isDisplayTransit) {
      //   setInTransitOrdersMarker();
      // }
    }
    if (isDisplayDetail && mapFilters?.isDisplayTransit && inTransitOrderId)
      getOrderDetail();
  }, [isDisplayDetail, mapFilters]);

  useEffect(() => {
    localStorage?.removeItem('customerId');
    localStorage?.removeItem('mapFilters');

    if (inTransitOrderId) {
      getOrderDetail();
    }

    if (inTransitOrderId == '' && inTransitOrders?.length > 0) {
      clearMapSearchRoute();
      setInTransitOrdersMarker();
      orderMarkerPin?.remove();
    }
  }, [inTransitOrderId]);

  const getPricingIndex = () => {
    const payload = {
      sourceCity: searchResult?.fromAddressObj?.city,
      sourceState: searchResult?.fromAddressObj?.stateCode,
      destinationCity: searchResult?.toAddressObj?.city,
      destinationState: searchResult?.toAddressObj?.stateCode,
      vehicleType: searchResult?.equipmentTypeId ?? 'V',
    };
    setPricingDetail(payload)
      .then((response: any) => {
        setPredictivePricingDetail(response?.data);
      })
      .catch((e: any) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        // setIsLoading(false);
        console.error(e?.response?.message);
      });
  };

  useEffect(() => {
    // console.log('searchREsult mapbox', searchResult);

    if (
      searchResult &&
      searchResult?.from !== undefined &&
      searchResult?.to !== undefined &&
      searchResult?.status !== 'success' &&
      hasSearched
    ) {
      setHasSearched(false);
    }

    if (searchResult?.from === '' && searchResult?.to === '') {
      if (mapRef.current) {
        setHasSearched(false);
        setIsDisplayDetail(false);
        // setSearchResult(null); // Clear search result

        // Remove route layer and source if they exist
        if (mapRef.current?.getLayer('route')) {
          mapRef.current.removeLayer('route');
        }

        if (mapRef.current?.getSource('route')) {
          mapRef.current.removeSource('route');
        }

        // Reset map to default view
        mapRef.current.jumpTo({
          center: [-95, 55],
          zoom: 4,
          pitch: 0,
          bearing: 0,
          padding: { left: 0, right: 0, top: 0, bottom: 0 },
        });
      }
    }
  }, [searchResult?.from, searchResult?.to]);

  useEffect(() => {
    if (hasSearched) getPricingIndex();
  }, [hasSearched]);

  // Handle the search functionality
  const handleSearch = useCallback(
    async (from: any, to: any) => {
      var filter = '';

      if (filters?.max_height && filters?.max_height > 0) {
        filter += `&max_height=${filters?.max_height}`;
      }

      if (filters?.max_weight && filters?.max_weight > 0) {
        filter += `&max_weight=${filters?.max_weight}`;
      }

      if (filters?.max_width && filters?.max_width > 0) {
        filter += `&max_width=${filters?.max_width}`;
      }

      if (filters?.exclude?.length > 0) {
        filter += `&exclude=${filters?.exclude?.join(',')}`;
      }

      localStorage?.removeItem('customerId');
      localStorage?.removeItem('mapFilters');

      if (!mapLoaded || !mapRef.current) {
        console.warn('Map not fully loaded yet');

        // setSearchResult((old: any) => ({
        //   ...old,
        //   status: 'error',
        //   error: 'Map not fully loaded yet. Please try again.',
        // }));

        return;
      }

      try {
        setHasSearched(true);
        setSearchResult((old: any) => ({
          ...old,
          isBasicDetail: false,
          status: 'loading',
          message: 'Fetching route...',
        }));

        const [fromLongitude, fromLatitude] = await geocodeAddress(
          from?.fullAddress
        );
        const [toLongitude, toLatitude] = await geocodeAddress(to?.fullAddress);
        // setFromLong(fromLongitude);
        // setFromLat(fromLatitude);

        if (mapRef.current?.getLayer('route')) {
          mapRef.current.removeLayer('route');
        }

        if (mapRef.current?.getSource('route')) {
          mapRef.current.removeSource('route');
        }

        const query = await fetch(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${fromLongitude},${fromLatitude};${toLongitude},${toLatitude}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}${filter}`,
          { method: 'GET' }
        );

        const json = await query.json();

        if (!json.routes || json.routes.length === 0) {
          throw new Error('No route found between the specified locations.');
        }

        const data = json.routes[0];
        const route = data.geometry.coordinates;

        setSearchResult((old: any) => ({
          ...old,
          status: 'loading',
          message: 'Fetching routes...',
          isBasicDetail: true,
          distance: Math.round(data.distance / 1609.344),
          duration: `${getTime(Math.round(data.duration))}`,
          cities: [],
          radius: routeFilters?.radius,
        }));

        setAllRoutes(route);
        const geojson: SourceSpecification = {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: route,
            },
          },
        };

        mapRef.current?.addSource('route', geojson);
        mapRef.current?.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#444CE7',
            'line-width': 3,
            'line-opacity': 0.75,
          },
        });

        const bounds = new mapboxgl.LngLatBounds(route[0], route[0]);

        for (const coord of route) {
          bounds.extend(coord);
        }
        let routeDistance = Math.round(data.distance / 1000);

        if (routeDistance > 1000 && routeDistance < 2200) {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            zoom: 5,
            linear: true,
          });
        } else if (routeDistance >= 2200 && routeDistance < 3500) {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            zoom: 4,
            linear: true,
          });
        } else if (routeDistance >= 3500 && routeDistance < 4000) {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            zoom: 4,
            linear: true,
          });
        } else if (routeDistance >= 4000) {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            zoom: 3.5,
            linear: true,
          });
        } else {
          mapRef.current?.fitBounds(bounds, {
            padding: { left: 10, right: 400, top: 150, bottom: 10 }, // Add more padding on the right to move the map left
            // zoom: routeDistance > 3000 ? 3.5 : 4,
            zoom: 6,
            linear: true,
          });
        }

        // const cities = await getCitiesAlongRoute({
        //   route,
        //   radius: routeFilters?.radius,
        // });

        setIsDisplayDetail(true);
        setSearchResult((old: any) => ({
          ...old,
          status: 'success',
          // cities: cities,
        }));
      } catch (error: any) {
        console.error('Error in handleSearch:', error);
        setSearchResult({
          status: 'error',
          error: `Failed to fetch route: ${error.message}`,
        });
      }
    },
    [mapLoaded, filters, mapRef]
  );

  useEffect(() => {
    if (
      mapLoaded &&
      searchResult?.fromAddressObj &&
      searchResult?.toAddressObj
    ) {
      handleSearch(searchResult?.fromAddressObj, searchResult?.toAddressObj);
    }
  }, [mapLoaded]);

  useEffect(() => {
    if (mapParams) {
      handleSearch(mapParams?.fromAddressObj, mapParams?.toAddressObj);
    }
  }, [mapParams]);
  // useEffect(() => {
  //   const controller = new AbortController();
  //   const signal = controller?.signal;
  //   let custId = localStorage?.getItem('customerId');

  //   if (!custId) {
  //     setIsDisplayDetail(false);
  //     // setCustomerId(null);
  //   }

  //   if (isDisplayCustomers) {
  //     setHasSearched(false);
  //     removeCustomerMarkers();

  //     if (!custId || !customerId) {
  //       getCustomer(signal);
  //     }
  //   } else {
  //     // console.log('customer Id null');
  //     setCustomerId(null);
  //     removeCustomerMarkers();
  //   }

  //   return () => {
  //     controller.abort();
  //   };
  // }, [isDisplayCustomers, params]);

  useEffect(() => {
    initializeMap();
  }, []);

  // const getFilteredCity = async () => {
  //   console.log('getFiltersCity');
  //   setSearchResult((old: any) => ({
  //     ...old,
  //     message: 'Fetching Cities as per filter',
  //     status: 'loading',
  //   }));
  //   const controller = new AbortController();
  //   setAbortController(controller);

  //   const cities = await getCitiesAlongRoute({
  //     route: allRoutes,
  //     // fromLatLong: [fromLong, fromLat],
  //     radius: routeFilters.radius,
  //     controller: controller, // Pass the controller
  //   });
  //   console.log('getFiltersCity cities', cities);
  //   setSearchResult((old: any) => ({
  //     ...old,
  //     status: 'success',
  //     cities: cities,
  //   }));
  // };

  // useEffect(() => {
  //   if (hasSearched) getFilteredCity();
  // }, [routeFilters]);

  const setDefaultMap = () => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        // center: [-95, 55],
        zoom: 4,
        pitch: 0,
        bearing: 0,
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
      });
    }
  };

  return (
    <div className="relative h-full w-full geography-map">
      {/* {searchResult?.status === 'loading' ? (
        <div className="absolute top-0 left-0 h-full w-full z-[3] flex items-center justify-center ">
          <div className="relative z-[3]">
            <div className="w-[50px] h-[50px] rounded-full absolute border-[7px] border-dotted border-black border-opacity-10"></div>
            <div className="w-[50px] h-[50px] rounded-full animate-spin absolute border-[7px] border-dotted border-black border-t-transparent"></div>
          </div>
          <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-[2px]"></div>
        </div>
      ) : ( */}
      <div
        id="map"
        ref={mapContainerRef}
        style={{ width: '100%', height: '100%' }}
      ></div>
      {/* )} */}
      <div className="absolute top-[50px] w-full px-4">
        <SearchBox
          onSearch={handleSearch}
          onClear={handleClear}
          routeFilters={filters}
          setParams={setParams}
          hasSearch={hasSearched}
          setHasSearched={setHasSearched}
          setIsShowMapFilterModal={setIsShowMapFilterModal}
          isShowMapFilterModal={isShowMapFilterModal}
          searchResult={searchResult}
          isDisplayCustomerSearch={mapFilters?.isDisplayCustomers}
          setSearchResult={setSearchResult}
          isSearchLoading={searchResult?.status === 'loading' ? true : false}
        />
        {isShowMapFilterModal ? (
          <MapFilterModal
            handleClose={() => setIsShowMapFilterModal(false)}
            routeFilters={filters}
            setRouteFilters={setFilters}
          />
        ) : (
          ''
        )}
      </div>

      {/* {mapFilters.filterType === 'in_transit' && (
        <FilterModal
          handleClose={() =>
            setMapFilters((old: any) => ({
              ...old,
              filterType: '',
            }))
          }
          params={inTransitparams}
          setParams={setInTransitParams}
          teamListOptions={teamListOptions}
          userList={userList}
        />
      )} */}
      <div className="fixed p-[7px] z-[2] cursor-pointer rounded-md border top-[115px] right-2 bg-white shadow-[0_0_0_1px_rgba(0,0,0,0.1)]">
        <Move
          className="w-4 h-4 text-gray500 cursor-pointer"
          onClick={setDefaultMap}
        />
      </div>
      {/* <div className="fixed p-[7px] z-[2] cursor-pointer rounded-md border top-[18px] right-[50px] bg-white shadow-[0_0_0_1px_rgba(0,0,0,0.1)]">
        <Map01
          className="w-4 h-4 text-gray500 cursor-pointer"
          onClick={() => setIsShowCustomiseMapModal(true)}
        />
      </div> */}
      {isDisplayDetail ? (
        hasSearched ? (
          <LaneOverview
            routeFilters={routeFilters}
            setRouteFilters={setRouteFilters}
            predictivePricingDetail={predictivePricingDetail}
            handleClose={() => {
              setIsDisplayDetail(false);
            }}
            searchResult={searchResult}
          />
        ) : (
          ''
        )
      ) : (
        hasSearched &&
        searchResult.status === 'success' && (
          <>
            <ButtonCmp
              onClick={() => setIsDisplayDetail(true)}
              icon={<ArrowUp className="w-4 h-4 text-primary cursor-pointer" />}
              iconSide="right"
              className="btn_secondary_black text-primary fixed bottom-10 right-10 z-[2]"
            >
              Lane Overview
            </ButtonCmp>
          </>
        )
      )}
      {isShowCustomiseMapModal && (
        <CustomiseMapModal
          handleClose={() => setIsShowCustomiseMapModal(false)}
        />
      )}
    </div>
  );
};

export default MapBox;
