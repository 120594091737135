import React, { useContext, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { classOptions } from 'src/app/Orders/order.interface';
import SelectBox from 'src/components/SelectBox/SelectBox';

import { OrderContext } from '..';

const Step1 = () => {
  const { control, setValue, clearErrors, watch } = useFormContext();

  const {
    equipmentTypeOptions,
    isEquipmentTypeLoading,
    customerList,
    isCustomerLoading,
  } = useContext<any>(OrderContext);

  const watchEquipmentType = watch('equipmentType');

  useEffect(() => {
    if (!isEquipmentTypeLoading && equipmentTypeOptions?.length > 0) {
      const currentEquipmentType = watchEquipmentType;

      if (currentEquipmentType) {
        const defaultEquipmentType =
          equipmentTypeOptions.find(
            (option: any) => option.value === currentEquipmentType
          ) || null;

        if (defaultEquipmentType) {
          setValue('equipmentType', defaultEquipmentType.value);
          setValue('equipmentTypeFullName', defaultEquipmentType.label);
        }
      } else {
        const defaultEquipmentType =
          equipmentTypeOptions.find((option: any) => option.value === 'V') ||
          null;

        if (defaultEquipmentType) {
          setValue('equipmentType', defaultEquipmentType.value);
          setValue('equipmentTypeFullName', defaultEquipmentType.label);
        }
      }
    }
  }, [isEquipmentTypeLoading, equipmentTypeOptions, watchEquipmentType]);

  const memorizedEquipmentTypeSelectBox = useMemo(
    () => (
      <Controller
        name={`equipmentType`}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <SelectBox
            label="Equipment Type"
            labelClassName={'form_label block mb-1.5'}
            parentClassName="sm:px-1.5 sm:py-2 w-1/2"
            name="equipmentType"
            id="equipmentType"
            className="form_control"
            size="sm"
            options={equipmentTypeOptions}
            onChangeFunc={(e: any) => {
              onChange(e?.value);
              setValue('equipmentTypeFullName', e?.label);

              if (error?.message) {
                clearErrors('equipmentType');
              }
            }}
            isSearchable={false}
            value={
              value
                ? equipmentTypeOptions.filter((val: any) => value === val.value)
                : equipmentTypeOptions.filter((val: any) => 'V' === val.value)
            }
            errorText={error ? error.message : null}
            isLoading={isEquipmentTypeLoading}
          />
        )}
      />
    ),
    [isEquipmentTypeLoading, equipmentTypeOptions, watchEquipmentType]
  );

  return (
    <div>
      <h6 className="text-textSecondary text-sm font-medium mb-1 leading-[1.5]">
        Order Details
      </h6>
      <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-5 mb-4 last:mb-0">
        <div className="flex flex-wrap sm:-mx-1.5 sm:-my-2">
          <Controller
            name={`orderCustomerId`}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SelectBox
                name="orderCustomerId"
                id="orderCustomerId"
                className="form_control"
                label="Select a customer"
                labelClassName={'form_label block mb-1.5'}
                parentClassName="sm:px-1.5 sm:py-2 w-full"
                size="sm"
                options={customerList}
                isLoading={isCustomerLoading}
                onChangeFunc={(e: any) => {
                  if (e?.value) {
                    onChange(e?.value);
                    clearErrors('orderCustomerId');
                  } else {
                    onChange(null);
                  }
                }}
                isSearchable={true}
                isClearable={true}
                value={
                  value
                    ? customerList.filter((val: any) => value === val.value)
                    : customerList.filter((val: any) => 'ltl' === val.value)
                }
                required
                errorText={error ? error.message : null}
              />
            )}
          />

          <Controller
            name={`orderType`}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SelectBox
                name="orderType"
                id="orderType"
                className="form_control"
                label="Order Type"
                labelClassName={'form_label block mb-1.5'}
                parentClassName="sm:px-1.5 sm:py-2 sm:w-1/2"
                size="sm"
                options={classOptions}
                onChangeFunc={(e: any) => {
                  onChange(e?.value);
                }}
                isSearchable={false}
                value={
                  value
                    ? classOptions.filter((val: any) => value === val.value)
                    : classOptions.filter((val: any) => 'ltl' === val.value)
                }
                required
                errorText={error ? error.message : null}
              />
            )}
          />
          {memorizedEquipmentTypeSelectBox}
        </div>
      </div>
    </div>
  );
};

export default Step1;
