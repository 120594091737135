import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import { getShortName, onError } from 'src/utils/CommonFunctions';

const MyNewCustomerLBRow = ({ data }: any) => (
  <>
    <td className="px-5 py-4 max-w-[1px] w-[50%] min-w-[50%]">
      <div className="flex items-center gap-3">
        {data.customerImage ? (
          <img
            className="table-profile-icn-circle-xl"
            src={data.customerImageUrl + data.customerImage}
            alt=""
            title=""
            onError={onError}
          />
        ) : (
          <div className="table-profile-customer-circle-xl">
            {getShortName(`${data.name}`)}
          </div>
        )}
        <div className="truncate">
          {data.name.length > 25 ? (
            <TooltipCmp message={data.name} parentClassName="">
              <span className="text-grayLight900 font-medium leading-tight truncate">
                {data.name}
              </span>
            </TooltipCmp>
          ) : (
            <span className="text-grayLight900 font-medium leading-tight truncate">
              {data.name}
            </span>
          )}
        </div>
      </div>
    </td>

    <td className="px-5 py-4 max-w-[1px] w-[50%] min-w-[50%]">
      <div className="flex items-center">
        {data.userImage ? (
          <img
            className="table-profile-icn-circle-sm"
            src={data.userImageUrl + data.userImage}
            alt={data.salesRepFullName}
            title={data.salesRepFullName}
            onError={onError}
          />
        ) : (
          <div className="table-profile-user-circle-sm">
            <span>{getShortName(`${data.salesRepFullName}`)}</span>
          </div>
        )}
        <div className="pl-2 truncate">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            {data.salesRepFullName}
          </h6>
        </div>
      </div>
    </td>
  </>
);

export default MyNewCustomerLBRow;
