import {
  Attachment01,
  // ChevronDown,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { sendOrderEmail } from 'src/services/OrderService';
import { convertToGBMBKB, fileToBase64 } from 'src/utils/CommonFunctions';

// import AiStarIcon from '../../assets/img/AiStarIcon.svg';
import pdf from '../../assets/img/pdf-icon.png';
import TailwindAdvancedEditor from '../AdvancedEditor';
// import AiInputText from '../AiInputText';
import ButtonCmp from '../ButtonCmp';
// import CheckBox from '../CheckBox';
import DynamicCreatableSelect from '../DynamicCreatableSelect';
import ErrorMsg from '../errorMsg';
import InputText from '../InputText/InputText';
import TooltipCmp from '../TooltipCmp';

interface EmailReplyCmpProps {
  subjectLine?: string;
  toEmailAddress?: string;
  emailDetails?: any;
  emailStaticBody?: any;
  handleClose?: any;
  onSubmit?: any;
  defaultAttachments?: {
    url: string;
    fileName: string;
  }[];
}

const fetchPdfAsFile = async (url: string, name: string) => {
  const response = await fetch(url);
  const blob = await response.blob();

  return new File([blob], `${name}.pdf`, { type: blob.type });
};

const EmailReplyCmp = ({
  subjectLine,
  toEmailAddress,
  emailDetails,
  emailStaticBody,
  handleClose,
  onSubmit,
  defaultAttachments,
}: EmailReplyCmpProps) => {
  const initFormData = {
    messageId: emailDetails?.id,
    to: toEmailAddress,
    emailType: '',
    cc: [],
    emailBody: `<p>Hello,</p><p>${emailDetails}</p>`,
    attachments: [],
  };

  const initValidForm = {
    to: true,
    emailBody: true,
    cc: true,
    subject: true,
  };

  const [formData, setFormData] = useState<any>(initFormData);
  const [validForm, setValidForm] = useState(initValidForm);
  const [errorMessages, setErrorMessages] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!formData?.attachments?.length) {
      defaultAttachments?.forEach((attachment: any) => {
        fetchPdfAsFile(attachment.url, attachment.fileName).then((pdfFile) => {
          setFormData((prev: any) => ({
            ...prev,
            attachments: [pdfFile],
          }));
        });
      });
    }
  }, [defaultAttachments]);

  const isValidEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  const handleOnSubmit = async () => {
    const errors: any = {};

    if (!formData.to || formData.to.trim().length === 0) {
      errors.to = 'Email address is required.';
      setValidForm((prev) => ({ ...prev, to: false }));
    } else if (!isValidEmail(formData.to)) {
      errors.to = 'Please enter a valid email address.';
      setValidForm((prev) => ({ ...prev, to: false }));
    } else {
      setValidForm((prev) => ({ ...prev, to: true }));
    }

    if (!formData.subject || formData.subject.trim().length === 0) {
      errors.subject = 'Subject is required.';
      setValidForm((prev) => ({ ...prev, subject: false }));
    } else {
      setValidForm((prev) => ({ ...prev, subject: true }));
    }

    // if (formData.cc && !isValidEmail(formData.cc)) {
    //   errors.cc = 'Please enter a valid CC email address.';
    //   setValidForm((prev) => ({ ...prev, cc: false }));
    // } else {
    //   setValidForm((prev) => ({ ...prev, cc: true }));
    // }

    if (!formData.emailBody || formData.emailBody.trim() === '') {
      errors.emailBody = 'Please enter the email body.';
      setValidForm((prev) => ({ ...prev, emailBody: false }));
    } else {
      setValidForm((prev) => ({ ...prev, emailBody: true }));
    }

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);

      return;
    }

    setIsLoading(true);

    const attachmentsArray = [];

    if (formData?.attachments?.length) {
      for (const file of formData.attachments) {
        try {
          const base64String = await fileToBase64(file);
          attachmentsArray.push({
            filename: file.name,
            content: base64String,
            contentType: file.type,
          });
        } catch (error) {
          console.error('Error converting file to base64:', error);
        }
      }
    }
    const attachmentsInString = JSON.stringify(attachmentsArray);

    if (onSubmit) {
      onSubmit({ ...formData, attachments: attachmentsInString });
    } else {
      const formDataToSend = new FormData();

      formDataToSend.append('subject', formData.subject);
      formDataToSend.append('to', formData.to);
      formDataToSend.append('emailBody', formData.emailBody);
      formDataToSend.append('cc', formData.cc);
      formDataToSend.append('attachments', attachmentsInString);

      sendOrderEmail(formDataToSend)
        .then((response) => {
          if (response) {
            handleClose();
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    setFormData((old: any) => ({ ...old, to: toEmailAddress }));
  }, [toEmailAddress]);

  useEffect(() => {
    setFormData((old: any) => ({ ...old, subject: subjectLine }));
  }, [subjectLine]);

  useEffect(() => {
    setFormData((old: any) => ({
      ...old,
      cc: selectedOptions.map((option: any) => option.value),
    }));
  }, [selectedOptions]);

  const defaultEditorContent = useMemo(
    () => ({
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [{ type: 'text', text: 'Hello, ' }],
        },
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: emailDetails,
            },
          ],
        },
      ],
    }),
    [emailDetails]
  );

  const handleSelectChange = (newValue: any) => {
    setSelectedOptions(newValue);
  };

  const handleInputChange = (newValue: any) => {
    setInputValue(newValue);
  };

  const handleKeyDown = (event: any) => {
    if (inputValue && (event.key === 'Enter' || event.key === 'Tab')) {
      if (isValidEmail(inputValue)) {
        const newOption = { label: inputValue, value: inputValue };
        setSelectedOptions((prev: any) => [...prev, newOption] as any);
        setInputValue('');
      } else {
        setInputValue('');
      }
      event.preventDefault();
    }
  };

  const openFileManager = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setFormData((prev: any) => ({
        ...prev,
        attachments: [...prev.attachments, ...filesArray],
      }));
    }
  };

  const handleRemoveAttachment = (index: number) => {
    setFormData((prev: any) => ({
      ...prev,
      attachments: prev.attachments.filter((_: any, i: number) => i !== index),
    }));
  };

  return (
    <>
      <form
        className="flex flex-col gap-y-4 p-5 flex-1 overflow-auto custom-scrollbar-v2"
        onSubmit={handleOnSubmit}
      >
        <div className="flex gap-x-2 w-full">
          <label className="text-gray400 text-[11px] leading-[1] font-medium form_label_center min-w-14">
            To
          </label>
          <div className="form_control p-0 relative !h-auto flex-1">
            <InputText
              value={formData?.to}
              parentClassName="flex-1"
              placeholder="Enter to address"
              className="!border-0 !h-auto !pr-24"
              onChangeFunc={(e) => {
                setErrorMessages((old: any) => ({ ...old, to: null }));
                setValidForm((old: any) => ({ ...old, to: true }));

                setFormData((prevData: any) => ({
                  ...prevData,
                  to: e.target.value,
                }));
              }}
              errorText={validForm.to === false ? errorMessages?.to : ''}
            />
            {/* <div className="flex gap-1 text-[11px] leading-[1] font-medium absolute top-1/2 -translate-y-1/2 right-2 text-gray500 h-full items-center cursor-pointer">
              <p>CC | BCC</p>
              <ChevronDown className="w-3.5 h-3.5 text-gray400" />
            </div> */}
          </div>
        </div>

        <div className="flex gap-x-2 w-full">
          <label className="text-gray400 font-medium form_label_center min-w-14 text-[11px] leading-[1]">
            CC
          </label>
          <div className="flex-1 relative">
            <DynamicCreatableSelect
              placeholder="Enter email address"
              isClearable={true}
              isMulti={true}
              menuIsOpen={false}
              onChange={handleSelectChange}
              onInputChange={handleInputChange}
              onKeyDown={handleKeyDown}
              value={selectedOptions}
              inputValue={inputValue}
              errorText={!validForm.cc ? errorMessages?.cc : null}
            />
          </div>
        </div>

        {/* <div className="flex gap-x-2 w-full">
          <label className="text-gray400 font-medium form_label_center min-w-14 text-[11px] leading-[1]">
            BCC
          </label>
          <div className="flex-1 relative">
            <DynamicCreatableSelect
              placeholder="Enter email address"
              isClearable={true}
              isMulti={true}
              menuIsOpen={false}
              onChange={handleSelectChange}
              onInputChange={handleInputChange}
              onKeyDown={handleKeyDown}
              value={selectedOptions}
              inputValue={inputValue}
              errorText={!validForm.cc ? errorMessages?.cc : null}
            />
          </div>
        </div> */}

        {subjectLine && (
          <div className="flex gap-x-2 w-full border-t  border-utilityGray200 pt-4">
            <label className="text-gray400 text-[11px] leading-[1] font-medium form_label_center min-w-14">
              Subject
            </label>
            <InputText
              inputName="Subject"
              placeholder="Enter subject"
              parentClassName="flex-1"
              value={formData?.subject}
              onChangeFunc={(e) => {
                setErrorMessages((old: any) => ({ ...old, subject: null }));
                setValidForm((old: any) => ({ ...old, subject: true }));

                setFormData((prevData: any) => ({
                  ...prevData,
                  subject: e.target.value,
                }));
              }}
              errorText={
                validForm.subject === false ? errorMessages?.subject : ''
              }
            />
          </div>
        )}

        <div className="border-t border-utilityGray200 overflow-auto custom-scrollbar-v2 min-h-28">
          <div className="novel-editor-wrap pt-4">
            <TailwindAdvancedEditor
              initialValue={defaultEditorContent}
              handleChange={(newContent: any) => {
                setFormData((prevData: any) => ({
                  ...prevData,
                  emailBody: newContent,
                }));
              }}
              editorWrapClassName="!border-0 !p-0 !min-h-[unset]"
              editorContentClassName="!px-0"
            />
            {emailStaticBody}
          </div>
        </div>
        {validForm.emailBody === false && (
          <ErrorMsg errorText={errorMessages?.emailBody} />
        )}

        <ul className="!mt-6 !mb-3 flex flex-wrap -m-1.5 empty:hidden">
          {Array.isArray(formData?.attachments) &&
            formData?.attachments?.length > 0 &&
            formData?.attachments?.map((file: File, index: number) => (
              <li className="p-1.5 w-1/2" key={index}>
                <div className="rounded-lg border border-utilityGray200 bg-white flex items-center gap-2 p-3 relative">
                  {file?.name?.endsWith('.pdf') ? (
                    <img src={pdf} className="h-8" alt="PDF Icon" />
                  ) : (
                    <img
                      src={URL.createObjectURL(file)}
                      className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none object-cover"
                      alt="File Preview"
                    />
                  )}
                  <div className="flex-1 w-[calc(100%_-_85px)] ">
                    <h6 className="text-textSecondary text-xs font-medium truncate">
                      <TooltipCmp
                        message={file?.name?.length > 20 ? file?.name : null}
                      >
                        {file.name}
                      </TooltipCmp>
                    </h6>
                    <p className="text-grayLight600 text-xs !text-[11px] font-normal">
                      {convertToGBMBKB(file?.size)}
                    </p>
                  </div>
                  <div
                    className="radial-progress text-primary"
                    style={
                      {
                        '--value': 40,
                        '--size': '26px',
                        '--thickness': '4px',
                      } as React.CSSProperties & { '--value': number }
                    }
                    role="progressbar"
                  ></div>
                  <div
                    className="cursor-pointer absolute -top-1.5 -right-1.5 bg-white w-4 h-4 border border-utilityGray200 rounded-full flex items-center justify-center"
                    onClick={() => handleRemoveAttachment(index)}
                  >
                    <XClose className="w-3 h-3 text-grayLight900" />
                  </div>
                </div>
              </li>
            ))}
          {/* <li className="p-1.5 w-1/2 ">
            <div className="rounded-lg border border-utilityGray200 bg-white flex items-center gap-2 p-3">
              <img src={pdf} className="h-8 " />
              <div className="flex-1 w-[calc(100%_-_85px)] ">
                <h6 className="text-textSecondary text-xs font-medium truncate">
                  Shipment_POD_[05/06/24].pdf
                </h6>
                <p className="text-grayLight600 text-xs !text-[11px] font-normal">
                  200 KB
                </p>
              </div>
              <CheckBox classes="" onChangeFunc={() => {}} />
            </div>
          </li> */}
        </ul>

        <input
          type="file"
          multiple
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />

        {/* <AiInputText /> */}
      </form>
      <footer className="px-5 py-[14px] border-t flex flex-wrap gap-3 md:flex-row flex-col-reverse sticky bottom-0 bg-white">
        <ButtonCmp
          className="btn_secondary_black min-w-24"
          onClick={() => {
            handleClose();
          }}
        >
          Dismiss
        </ButtonCmp>
        <div className="flex gap-2 flex-1 justify-end">
          <ButtonCmp
            className="btn_secondary_black lg:!px-[9px] !px-2"
            icon={<Attachment01 className="w-4 h-4" />}
            onClick={openFileManager}
          >
            <></>
          </ButtonCmp>
          {/* <ButtonCmp
            className="btn_secondary_black "
            icon={<img src={AiStarIcon} className="w-4 h-4 object-contain" />}
          >
            Ask HQ AI
          </ButtonCmp> */}
          <ButtonCmp
            className="btn_primary min-w-20"
            onClick={handleOnSubmit}
            loading={isLoading}
            disabled={isLoading}
          >
            Send
          </ButtonCmp>
        </div>
      </footer>
    </>
  );
};

export default EmailReplyCmp;
