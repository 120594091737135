import {
  createShipper,
  updateShipper,
  createShipperContact,
  updateShipperContact,
} from 'src/services/ShipperService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import { initShipperAddress } from '../../order.interface';

export const handleAddressAutocompleteChange = (
  name: string,
  value: any,
  setShipperAddress: any,
  setIsValidAddress: any,
  setError: any,
  setValue: any,
  setConsigneeAddress: any,
  clearErrors: any,
  getValues: any,
  setCurrentLegData: any,
  setShipperFullAddress?: any,
  setConsigneeFullAddress?: any
) => {
  if (value?.type === 'blur') {
    return;
  }

  if (name === 'shipperAddress') {
    if (
      value?.city === '' ||
      value?.postal === '' ||
      value?.postal?.length < 4
    ) {
      setShipperAddress(value?.fullAddress);
      setIsValidAddress((old: any) => ({ ...old, [name]: false }));
      setError('shipper.shipperAddress.fullAddress', {
        type: 'manual',
        message: 'Shipper address is invalid.',
      });
      setValue('shipper.shipperAddress', value);

      return;
    }
    setIsValidAddress((old: any) => ({ ...old, [name]: true }));
  } else if (name === 'consigneeAddress') {
    if (
      value?.city === '' ||
      value?.postal === '' ||
      value?.postal?.length < 4
    ) {
      setConsigneeAddress(value?.fullAddress);
      setIsValidAddress((old: any) => ({ ...old, [name]: false }));
      setError('consignee.consigneeAddress.fullAddress', {
        type: 'manual',
        message: 'Consignee address is invalid.',
      });
      setValue('consignee.consigneeAddress', value);

      return;
    }
    setIsValidAddress((old: any) => ({ ...old, [name]: true }));
  }

  if (name === 'consigneeAddress') {
    if (value?.city && value?.address1 && value?.city != value?.address1) {
      clearErrors('consignee.consigneeAddress.fullAddress');
      setIsValidAddress((old: any) => ({ ...old, [name]: true }));
      setConsigneeAddress(value?.fullAddress);

      if (setConsigneeFullAddress) {
        setConsigneeFullAddress(value);
      }
    } else {
      setIsValidAddress((old: any) => ({ ...old, [name]: false }));

      setError('consignee.consigneeAddress.fullAddress', {
        type: 'manual',
        message: 'Consignee address is invalid.',
      });
    }

    setValue('consignee.consigneeAddress', value);

    if (value?.companyName) {
      setValue('consignee.companyName', value.companyName);
    }

    if (
      value?.phoneNumber &&
      (!getValues('consignee.phoneNumber') ||
        getValues('consignee.phoneNumber') === '+1')
    ) {
      setValue('consignee.phoneNumber', value.phoneNumber);
    }

    setCurrentLegData((prevData: any) => ({
      ...prevData,
      consignee: {
        ...prevData.consignee,
        consigneeAddress: value,
      },
    }));
  }

  if (name === 'shipperAddress') {
    if (value?.city && value?.address1 && value?.city != value?.address1) {
      clearErrors('shipper.shipperAddress.fullAddress');
      setIsValidAddress((old: any) => ({ ...old, [name]: true }));
      setShipperAddress(value?.fullAddress);

      if (setShipperFullAddress) {
        setShipperFullAddress(value);
      }
    } else {
      setIsValidAddress((old: any) => ({ ...old, [name]: false }));

      setError('shipper.shipperAddress.fullAddress', {
        type: 'manual',
        message: 'Shipper address is invalid.',
      });
    }

    setValue('shipper.shipperAddress', value);

    if (value?.companyName) {
      setValue('shipper.companyName', value.companyName);
    }

    if (
      value?.phoneNumber &&
      (!getValues('shipper.phoneNumber') ||
        getValues('shipper.phoneNumber') === '+1')
    ) {
      setValue('shipper.phoneNumber', value.phoneNumber);
    }

    setCurrentLegData((prevData: any) => ({
      ...prevData,
      shipper: {
        ...prevData.shipper,
        shipperAddress: value,
      },
    }));
  }
};

export const handleShipperSelectChange = (
  name: any,
  event: any,
  shippers: any,
  setValue: any,
  setShipperContact: any,
  setShipperContactOptions: any,
  setShipperAddress: any,
  getValues: any,
  setCurrentLegData: any,
  consignees: any,
  setConsigneeAddress: any,
  setConsigneeContact: any,
  setConsigneeContactOptions: any,
  setIsValidAddress: any,
  isValidAddress: any,
  clearErrors: any,
  setShipperFullAddress?: any,
  setConsigneeFullAddress?: any
) => {
  const newData: any = {};

  newData[name] = event ? event.value : null;

  if (name === 'shipperId') {
    let shipperAddr = initShipperAddress;

    if (event && event.value) {
      const shipper = shippers.find(
        (shipperData: any) => shipperData.id === event.value
      );

      setValue('shipper.companyName', shipper.companyName);
      setValue('shipper.id', shipper.id);
      shipperAddr = {
        fullAddress: shipper.fullAddress,
        address1: shipper.address1,
        postal: shipper.postal,
        city: shipper.city,
        state: shipper.state,
        country: shipper.country,
        longitude: shipper.longitude,
        latitude: shipper.latitude,
        stateCode: shipper.stateCode,
        countryCode: shipper.countryCode,
      };

      const contactList = getSelectBoxOptions(shipper?.contacts, 'id', 'name');

      setShipperContact(shipper?.contacts ?? []);
      setShipperContactOptions(contactList);
      setShipperAddress(shipper.fullAddress);
    } else {
      setValue('shipper.companyName', '');
      setValue('shipper.id', undefined);
      setShipperAddress('');
      setShipperContact([]);

      if (getValues('shipper.contactId')) {
        setValue('shipper.contactId', undefined);
        setValue('shipper.contactName', undefined);
        setValue('shipper.email', undefined);
        setValue('shipper.phoneNumber', undefined);
      }
    }

    setValue('shipper.shipperAddress', shipperAddr);

    setCurrentLegData((prevData: any) => ({
      ...prevData,
      shipper: {
        ...prevData.shipper,
        shipperAddress: shipperAddr,
        contactId: getValues('shipper.contactId')
          ? prevData.shipper.contactId
          : null,
        contactName: getValues('shipper.contactId')
          ? prevData.shipper.contactName
          : null,
        email: getValues('shipper.contactId') ? prevData.shipper.email : null,
        phoneNumber: getValues('shipper.contactId')
          ? prevData.shipper.phoneNumber
          : null,
      },
    }));

    if (setShipperFullAddress) {
      setShipperFullAddress(shipperAddr);
    }
  } else if (name === 'consigneeId') {
    let consigneeAddr = initShipperAddress;

    if (event && event.value) {
      const consignee = consignees.find(
        (consigneeData: any) => consigneeData.id === event.value
      );
      setValue('consignee.companyName', consignee.companyName);
      setValue('consignee.id', consignee.id);

      consigneeAddr = {
        fullAddress: consignee.fullAddress,
        address1: consignee.address1,
        postal: consignee.postal,
        city: consignee.city,
        state: consignee.state,
        country: consignee.country,
        longitude: consignee.longitude,
        latitude: consignee.latitude,
        stateCode: consignee.stateCode,
        countryCode: consignee.countryCode,
      };

      const contactList = getSelectBoxOptions(
        consignee?.contacts,
        'id',
        'name'
      );

      setConsigneeAddress(consignee.fullAddress);
      setConsigneeContact(consignee?.contacts ?? []);
      setConsigneeContactOptions(contactList);
    } else {
      setValue('consignee.companyName', undefined);
      setConsigneeAddress('');
      setConsigneeContact([]);

      if (getValues('consignee.contactId')) {
        setValue('consignee.contactId', undefined);
        setValue('consignee.contactName', undefined);
        setValue('consignee.email', undefined);
        setValue('consignee.phoneNumber', undefined);
      }
    }

    setValue('consignee.consigneeAddress', consigneeAddr);

    setCurrentLegData((prevData: any) => ({
      ...prevData,
      consignee: {
        ...prevData.consignee,
        consigneeAddress: consigneeAddr,
        contactId: getValues('consignee.contactId')
          ? prevData.consignee.contactId
          : null,
        contactName: getValues('consignee.contactId')
          ? prevData.consignee.contactName
          : null,
        email: getValues('consignee.contactId')
          ? prevData.consignee.email
          : null,
        phoneNumber: getValues('consignee.contactId')
          ? prevData.consignee.phoneNumber
          : null,
      },
    }));

    if (setConsigneeFullAddress) {
      setConsigneeFullAddress(consigneeAddr);
    }
  }

  if (event?.value) {
    setIsValidAddress({ ...isValidAddress, [name]: true });

    if (name === 'consigneeId') {
      clearErrors('consignee.companyName');

      setIsValidAddress({
        ...isValidAddress,
        consigneeAddress: true,
        consigneeCompanyName: true,
        consigneeId: true,
      });
    }

    if (name === 'shipperId') {
      clearErrors('shipper.companyName');

      setIsValidAddress({
        ...isValidAddress,
        shipperAddress: true,
        shipperCompanyName: true,
        shipperId: true,
      });
    }
  }
};

export const handleShipperConsigneeAddressSelection = (
  value: any,
  action: any,
  setShipperAddress: any,
  setValue: any,
  setIsValidAddress: any,
  setCurrentLegData: any,
  setConsigneeAddress: any,
  setShipperFullAddress?: any,
  setConsigneeFullAddress?: any
) => {
  if (action.shipper) {
    setShipperAddress(value?.fullAddress);
    setValue('shipper.shipperAddress', value);
    setIsValidAddress((old: any) => ({ ...old, shipperAddress: true }));

    setCurrentLegData((prevData: any) => ({
      ...prevData,
      shipper: {
        ...prevData.shipper,
        shipperAddress: value,
      },
    }));

    if (setShipperFullAddress) {
      setShipperFullAddress(value);
    }
  } else if (action.consignee) {
    setConsigneeAddress(value?.fullAddress);
    setValue('consignee.consigneeAddress', value);
    setCurrentLegData((prevData: any) => ({
      ...prevData,
      consignee: {
        ...prevData.consignee,
        consigneeAddress: value,
      },
    }));

    if (setConsigneeFullAddress) {
      setConsigneeFullAddress(value);
    }

    setIsValidAddress((old: any) => ({ ...old, consigneeAddress: true }));
  }
};

export const handleShipperContactSelectChange = (
  name: string,
  shipperContact: any,
  consigneeContact: any,
  setValue: any,
  setCurrentLegData: any,
  clearErrors: any,
  event: any
) => {
  const isShipper = name === 'shipperContactId';
  const contactList = isShipper ? shipperContact : consigneeContact;
  const contactKey = isShipper ? 'shipper' : 'consignee';

  let contactData: any = {
    contactId: undefined,
    contactName: undefined,
    email: undefined,
    phoneNumber: undefined,
  };

  if (event?.value) {
    const contact = contactList.find((data: any) => data.id === event.value);

    if (contact) {
      clearErrors(`${contactKey}.contactName`);
      clearErrors(`${contactKey}.email`);
      clearErrors(`${contactKey}.phoneNumber`);

      contactData = {
        contactId: contact.id,
        contactName: contact.name,
        email: contact.email,
        phoneNumber: contact.number,
      };
    }
  }

  setValue(`${contactKey}.contactName`, contactData.contactName);
  setValue(`${contactKey}.email`, contactData.email);
  setValue(`${contactKey}.phoneNumber`, contactData.phoneNumber);
  setValue(`${contactKey}.contactId`, contactData.contactId);

  setCurrentLegData((prevData: any) => ({
    ...prevData,
    [contactKey]: {
      ...prevData[contactKey],
      ...contactData,
    },
  }));
};

export const handleSaveShipperDetails = (
  type: string,
  mode: string,
  getValues: any,
  setValue: any,
  setIsValidAddress: any,
  getShipper: any,
  setIsShipperBtnDisable: any,
  setIsConsigneeBtnDisable: any,
  setIsShipperContactBtnDisable: any,
  setIsConsigneeContactBtnDisable: any
) => {
  let idToSave: any;
  let address: any;
  let companyName;
  let insertMsg: any;
  let updateMsg: any;

  const isShipper = type === 'shipper';
  const typeId = isShipper ? 1 : 2;
  const getValuePath = (field: string) => `${type}.${field}`;

  if (mode === 'shipper') {
    if (type === 'shipper') {
      idToSave = getValues('shipper.id') ?? null;
      companyName = getValues('shipper.companyName');
      address = getValues('shipper.shipperAddress');
      insertMsg = 'Shipper details added successfully';
      updateMsg = 'Shipper details updated successfully';
    } else if (type === 'consignee') {
      idToSave = getValues('consignee.id') ?? null;
      companyName = getValues('consignee.companyName');
      address = getValues('consignee.consigneeAddress');
      insertMsg = 'Consignee details added successfully';
      updateMsg = 'Consignee details updated successfully';
    }
    const data: any = {
      ...address,
      companyName,
      type: typeId,
    };

    if (
      companyName === '' ||
      address.fullAddress === '' ||
      typeof address !== 'object'
    ) {
      if (companyName === '' || !companyName) {
        if (type === 'shipper') {
          setIsValidAddress((old: any) => ({
            ...old,
            shipperCompanyName: false,
          }));
        } else if (type === 'consignee') {
          setIsValidAddress((old: any) => ({
            ...old,
            consigneeCompanyName: false,
          }));
        }
      }

      if (address.fullAddress === '' || typeof address !== 'object') {
        if (type === 'shipper') {
          setIsValidAddress((old: any) => ({
            ...old,
            shipperAddress: false,
          }));
        } else if (type === 'consignee') {
          setIsValidAddress((old: any) => ({
            ...old,
            consigneeAddress: false,
          }));
        }
      }

      return false;
    }

    if (idToSave) {
      updateShipper(idToSave, data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }

            WalToast.success(updateMsg, '');
          }
        })
        .catch(console.error);
    } else {
      createShipper(data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }

            WalToast.success(insertMsg, '');
          }
        })
        .catch(console.error);
    }
  } else {
    idToSave = getValues(getValuePath('contactId')) ?? null;

    const data = {
      shipperId: isShipper
        ? getValues('shipper.id')
        : getValues('consignee.id'),
      contactName: getValues(getValuePath('contactName')),
      email: getValues(getValuePath('email')),
      phoneNumber: getValues(getValuePath('phoneNumber')),
      type: typeId,
    };

    insertMsg = `${
      type.charAt(0).toUpperCase() + type.slice(1)
    } contact added successfully`;

    updateMsg = `${
      type.charAt(0).toUpperCase() + type.slice(1)
    } contact updated successfully`;

    if (!data.contactName || !data.email || !data.phoneNumber) {
      setIsValidAddress((old: any) => ({
        ...old,
        [`${type}Id`]: !!data?.shipperId,
        [`${type}ContactName`]: !!data?.contactName,
        [`${type}Email`]: !!data?.email,
        [`${type}PhoneNumber`]:
          data?.phoneNumber && data.phoneNumber !== '+1' ? true : false,
      }));

      return false;
    }

    const saveShipperContact = idToSave
      ? () => updateShipperContact(idToSave, data)
      : () => createShipperContact(data);

    saveShipperContact()
      .then((result: any) => {
        if (result) {
          getShipper();

          if (type === 'shipper') {
            if (result?.data) {
              setValue('shipper.contactId', result?.data?.id);
            }

            setIsShipperContactBtnDisable(true);
          } else {
            if (result?.data) {
              setValue('consignee.contactId', result?.data?.id);
            }

            setIsConsigneeContactBtnDisable(true);
          }

          WalToast.success(idToSave ? updateMsg : insertMsg, '');
        }
      })
      .catch(console.error);
  }
};
