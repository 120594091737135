import {
  Phone,
  Mail02,
  Luggage02,
  Star01,
  User01,
  ArrowUpRight,
  MarkerPin01,
  SwitchVertical01,
  DotsVertical,
  Edit01,
  Trash01,
  Edit05,
  // Globe01,
  // Send01,
  // Users01,
  // Phone01,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import ButtonCmp from 'src/components/ButtonCmp';
// import InputText from 'src/components/InputText/InputText';
import SelectWithActionTooltip from 'src/components/SelectWithActionTooltip';
import {
  getContactDetails,
  ThreadsCommunications,
} from 'src/services/CallCenterService';
import { getShortName, usePhone } from 'src/utils/CommonFunctions';

// import AiStarIcon from '../../../assets/img/AiStarIcon.svg';
import Globe01 from '../../../assets/img/globe-01.svg';
import star from '../../../assets/img/Star.svg';

import ContactDetailLoader from './ThreadLoader/ContactDetailLoader';

// import CallTranScript from './CallTranScript';

const ContactDetails = ({
  // setIsShowRightPanel,
  isShowRightPanel,
  threads,
}: any = {}) => {
  const [contactDetails, setContactDetails] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [threadsCommunication, setThreadsCommunication] = useState<{
    contactDetails: any;
    customerName: any;
    carrierName: any;
    carrierPhone: any;
    customerPhone: any;
    carrierEmail: any;
    customerEmail: any;
    customerImage: any;
    carrierImage: any;
    customerAddress: any;
    carrierAddress: any;
    customerWebsite: any;
    carrierWebsite: any;
  }>({
    contactDetails: [],
    customerName: '',
    carrierName: '',
    carrierPhone: '',
    customerPhone: '',
    carrierEmail: '',
    customerEmail: '',
    customerImage: '',
    carrierImage: '',
    customerAddress: '',
    carrierAddress: '',
    customerWebsite: '',
    carrierWebsite: '',
  });
  console.log('isLoading', isLoading);

  const { getFormatPhoneWithPlus } = usePhone();

  const fetchContactDetails = (signal?: any) => {
    setIsLoading(true);
    const contactId = isShowRightPanel.id;
    const type = isShowRightPanel?.type;

    getContactDetails(contactId, type, signal)
      .then((response: any) => {
        if (response?.data) {
          setContactDetails(response.data);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }

        setContactDetails([]);
        setIsLoading(false);
      });
  };

  const getThreadsCommunicationData = (signal?: any) => {
    setIsLoading(true);
    const contactId = threads.contactId;

    ThreadsCommunications(contactId, signal)
      .then((response: any) => {
        console.log('⭐️ ~ .then ~ response:', response);

        if (response) {
          setThreadsCommunication({
            contactDetails: response?.contactDetails.contactDetails || [],
            customerName: response?.contactDetails.customerName || '',
            carrierName: response?.contactDetails.carrierName || '',
            carrierPhone: response?.contactDetails.carrierPhone || '',
            customerPhone: response?.contactDetails.customerPhone || '',
            carrierEmail: response?.contactDetails.carrierEmail || '',
            customerEmail: response?.contactDetails.customerEmail || '',
            customerImage: response?.contactDetails.customerImage || '',
            carrierImage: response?.contactDetails.carrierImage || '',
            customerAddress: response?.contactDetails.customerAddress || '',
            carrierAddress: response?.contactDetails.carrierAddress || '',
            customerWebsite: response?.contactDetails.customerWebsite || '',
            carrierWebsite: response?.contactDetails.carrierWebsite || '',
          });
        }

        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }

        setThreadsCommunication({
          contactDetails: [],
          customerName: '',
          carrierName: '',
          carrierPhone: '',
          customerPhone: '',
          carrierEmail: '',
          customerEmail: '',
          customerImage: '',
          carrierImage: '',
          customerAddress: '',
          carrierAddress: '',
          customerWebsite: '',
          carrierWebsite: '',
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setContactDetails([]);
    setThreadsCommunication({
      contactDetails: [],
      customerName: '',
      carrierName: '',
      carrierPhone: '',
      customerPhone: '',
      carrierEmail: '',
      customerEmail: '',
      customerImage: '',
      carrierImage: '',
      customerAddress: '',
      carrierAddress: '',
      customerWebsite: '',
      carrierWebsite: '',
    });

    if (isShowRightPanel.id !== '') {
      fetchContactDetails(signal);
    } else if (threads) {
      getThreadsCommunicationData(signal);
    }

    return () => {
      controller.abort();
    };
  }, [isShowRightPanel, threads]);

  const carrierNoteFilter = [
    { label: 'Created Time ASC', value: 'Created Time ASC' },
    { label: 'Created Time DSC', value: 'Created Time DSC' },
  ];

  const tooltipItems = (
    <ul className="action-tooltip-select min-w-[130px]">
      <li className="action-tooltip-select-item">
        <Edit01 className="w-4 h-4" />
        <p className="action-tooltip-select-item-title">Edit</p>
      </li>
      <li className="action-tooltip-select-item">
        <Trash01 className="w-4 h-4" />
        <p className="action-tooltip-select-item-title">Delete</p>
      </li>
    </ul>
  );

  return (
    <>
      {isLoading ? (
        <ContactDetailLoader />
      ) : (
        <>
          {/* <CallTranScript /> */}
          {contactDetails.length !== 0 ? (
            <>
              {/* <div className="p-3 flex justify-end gap-1">
                <ButtonCmp
                  icon={<Edit02 className="w-4 h-4 mt-0.5 ml-0.5" />}
                  className="btn_secondary_black lg:px-2 px-[7px] py-[7px] lg:py-2"
                >
                  <></>
                </ButtonCmp>
                <ButtonCmp
                  icon={<XClose className="w-4 h-4 mt-0.5 ml-0.5" />}
                  className="btn_secondary_black lg:px-2 px-[7px] py-[7px] lg:py-2"
                  onClick={() => {
                    setIsShowRightPanel({ name: '', id: '' });
                  }}
                >
                  <></>
                </ButtonCmp>
              </div> */}
              <div className="border-b border-utilityGray200 flex flex-col items-center space-y-3 pb-4 pt-8">
                <div className="flex-none">
                  {contactDetails.image || contactDetails.userImage ? (
                    <img
                      src={contactDetails.image || contactDetails.userImage}
                      alt=""
                      className="w-[100px] h-[100px] rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                    />
                  ) : (
                    <div className="w-[100px] h-[100px] rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-4xl">
                      {getShortName(
                        contactDetails.name || contactDetails.userName
                      )}
                    </div>
                  )}
                </div>

                <h6 className="text-lg text-grayLight900 font-semibold empty:hidden">
                  {contactDetails.name || contactDetails.userName}
                </h6>
                <div className="flex gap-3 justify-center">
                  <ButtonCmp
                    icon={<Phone className="w-4 h-4" />}
                    className="btn_secondary_black lg:px-[9px] px-2"
                  >
                    <></>
                  </ButtonCmp>
                  <ButtonCmp
                    icon={<Mail02 className="w-4 h-4" />}
                    className="btn_secondary_black lg:px-[9px] px-2"
                  >
                    <></>
                  </ButtonCmp>
                </div>
              </div>

              <div className="overflow-y-auto scrollbar-hide p-4 space-y-3">
                <div className="flex flex-col gap-y-3">
                  {isShowRightPanel?.type !== 'user' && (
                    <>
                      <div className="flex">
                        <div className="flex gap-1 w-28 flex-none">
                          <Luggage02 className="w-4 h-4 text-grayLight600" />
                          <p className="text-xs text-grayLight600">
                            {contactDetails.carrierName
                              ? 'Carrier'
                              : 'Customer'}
                          </p>
                        </div>
                        <div className="leading-4 cursor-pointer">
                          <p className="text-primary700 text-xs inline break-all">
                            {contactDetails.carrierName || 'NA'}
                          </p>
                          <ArrowUpRight className="w-4 h-4 ml-1 text-primary700 inline flex-none" />
                        </div>
                      </div>

                      <div className="flex items-center">
                        <div className="flex gap-1 w-28">
                          <Star01 className="w-4 h-4 text-grayLight600" />
                          <p className="mb-0 text-xs text-grayLight600">
                            Carrier Rating
                          </p>
                        </div>
                        <div className="flex items-center gap-x-0.5 rounded-md border border-utilityGray200 px-1 py-0.5  flex-none self-start">
                          <p className="text-gray500 text-xs font-normal mt-[1px]">
                            4.5
                          </p>
                          <img src={star} />
                        </div>
                      </div>
                    </>
                  )}

                  {isShowRightPanel?.type === 'user' ? (
                    <div className="flex items-center">
                      <div className="flex gap-1 w-28">
                        <User01 className="w-4 h-4 text-grayLight600" />
                        <p className="mb-0 text-xs text-grayLight600">
                          Username
                        </p>
                      </div>
                      <div className="text-xs text-gray500">
                        {contactDetails.userName}
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <div className="flex gap-1 w-28">
                        <User01 className="w-4 h-4 text-grayLight600" />
                        <p className="mb-0 text-xs text-grayLight600">Role</p>
                      </div>
                      <div className="text-xs text-gray500">
                        {contactDetails.role}
                      </div>
                    </div>
                  )}

                  <div className="flex items-center">
                    <div className="flex gap-1 w-28">
                      <Phone className="w-4 h-4 text-grayLight600" />
                      <p className="mb-0 text-xs text-grayLight600">Phone</p>
                    </div>
                    <div className="text-xs text-gray500">
                      {isShowRightPanel?.type === 'user'
                        ? contactDetails.userPhone
                        : contactDetails.number}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div className="flex gap-1 w-28">
                      <Mail02 className="w-4 h-4 text-grayLight600" />
                      <p className="mb-0 text-xs text-grayLight600">Email</p>
                    </div>
                    <div className="text-xs text-gray500">
                      {isShowRightPanel?.type === 'user'
                        ? contactDetails.userEmail
                        : contactDetails.carrierEmail ||
                          contactDetails.customerEmail}
                    </div>
                  </div>

                  {isShowRightPanel?.type === 'user' && (
                    <div className="flex items-center">
                      <div className="flex gap-1 w-28">
                        <User01 className="w-4 h-4 text-grayLight600" />
                        <p className="mb-0 text-xs text-grayLight600">Role</p>
                      </div>
                      <div className="text-xs text-gray500">
                        {contactDetails.roleName}
                      </div>
                    </div>
                  )}
                </div>

                {/* NOTES STATIC DESIGN START */}
                <div>
                  <div className="flex justify-between items-center">
                    <h6 className="text-xs font-medium text-gray500 py-3">
                      Notes 6
                    </h6>
                    <div className="w-4 h-4">
                      <SelectWithActionTooltip
                        label={
                          <SwitchVertical01 className="w-4 h-4 text-primary" />
                        }
                        options={carrierNoteFilter}
                      />
                    </div>
                  </div>
                  <div className="py-3 flex gap-3 mb-1 last:mb-0">
                    <div className="relative flex items-center justify-center w-8 h-8 rounded-full uppercase border-gray-200 border bg-utilityGray100 text-primary flex-none">
                      <p className="mb-0 text-sm">OR</p>
                      <img src="" alt="" />
                    </div>
                    <div className="flex-1">
                      <h6 className="text-sm text-grayLight900 font-medium">
                        Olivia Rhye
                        <span className="ml-1.5 text-[10px] font-normal text-grayLight600">
                          Today, 10:40 AM
                        </span>
                      </h6>
                      <p className="text-xs text-gray500 font-normal mt-1 leading-[1.5]">
                        <span className="mr-1 px-1.5 py-[2px] rounded-md text-xs text-primary700 font-normal bg-primary100">
                          @Orlando Diggs
                        </span>
                        Lorem Ipsum has been the industry&apos;s standard dummy
                        text ever since the 1500s, when an unknown printer.
                      </p>
                    </div>
                    <ActionTooltip
                      message={tooltipItems}
                      isOpenChildTopPosOnTop={true}
                      type="light"
                    >
                      <DotsVertical className="cursor-pointer w-4 h-4 text-gray500" />
                    </ActionTooltip>
                  </div>

                  <div className="py-3 flex gap-3 mb-1 last:mb-0">
                    <div className="relative flex items-center justify-center w-8 h-8 rounded-full uppercase border-gray-200 border bg-utilityGray100 text-primary flex-none">
                      <p className="mb-0 text-sm">OR</p>
                      <img src="" alt="" />
                    </div>
                    <div className="flex-1">
                      <h6 className="text-sm text-grayLight900 font-medium">
                        Olivia Rhye
                        <span className="ml-1.5 text-[10px] font-normal text-grayLight600">
                          Today, 10:40 AM
                        </span>
                      </h6>
                      <p className="text-xs text-gray500 font-normal mt-1 leading-[1.5]">
                        Lorem Ipsum has been the industry&apos;s standard dummy
                        text ever since the 1500s, when an unknown printer.
                      </p>
                    </div>
                    <ActionTooltip
                      message={tooltipItems}
                      isOpenChildTopPosOnTop={true}
                      type="light"
                    >
                      <DotsVertical className="cursor-pointer w-4 h-4 text-gray500" />
                    </ActionTooltip>
                  </div>
                </div>

                {/* NOTES STATIC DESIGN END */}
              </div>
            </>
          ) : (
            <>
              {/* <div className="p-3 flex justify-end gap-1">
                <ButtonCmp
                  icon={<Edit02 className="w-4 h-4 mt-0.5 ml-0.5" />}
                  className="btn_secondary_black lg:px-[8px] px-1.5 lg:py-[7px] py-1.5"
                >
                  <></>
                </ButtonCmp>
                <ButtonCmp
                  icon={<XClose className="w-4 h-4" />}
                  className="btn_secondary_black lg:px-[9px] px-2"
                  onClick={() => {
                    setIsShowRightPanel({ name: '', id: '' });
                  }}
                >
                  <></>
                </ButtonCmp>
              </div> */}

              <div className="border-b border-utilityGray200 flex flex-col items-center space-y-3 pb-4 pt-8">
                <div className="flex-none">
                  {/* WHEN IMAGE IS FOUND BELOW CODE WILL BE SHOWN */}
                  {/* <img
            src={defaultImage}
            alt=""
            className="w-[100px] h-[100px] rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
          /> */}

                  <div className="relative overflow-hidden w-[100px] h-[100px] rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-4xl group cursor-pointer">
                    {getShortName(
                      threadsCommunication.customerName ||
                        threadsCommunication.carrierName ||
                        ''
                    )}
                    <div className="absolute h-full w-full bg-utilityBlack bg-opacity-60 text-white flex items-center justify-center transition-all duration-[0.3s] group-hover:opacity-100 opacity-0">
                      <Edit05 className="mdm:w-6 mdm:h-6 sm:w-5 sm:h-5 w-4 h-4" />
                    </div>
                  </div>
                </div>
                <h6 className="text-lg text-grayLight900 font-semibold empty:hidden text-center">
                  {threadsCommunication.customerName ||
                    threadsCommunication.carrierName}
                </h6>
                <div className="flex gap-3 justify-center">
                  <ButtonCmp
                    icon={<Phone className="w-4 h-4" />}
                    className="btn_secondary_black lg:px-[9px] px-2"
                  >
                    <></>
                  </ButtonCmp>
                  <ButtonCmp
                    icon={<Mail02 className="w-4 h-4" />}
                    className="btn_secondary_black lg:px-[9px] px-2"
                  >
                    <></>
                  </ButtonCmp>
                </div>
              </div>

              <div className="overflow-y-auto scrollbar-hide p-4 space-y-3">
                <div className="flex flex-col gap-y-3">
                  <div className="flex items-center">
                    <div className="flex gap-1 w-28 flex-none">
                      <Star01 className="w-4 h-4 text-grayLight600" />
                      <p className="mb-0 text-xs text-grayLight600">
                        Carrier Rating
                      </p>
                    </div>
                    <div className="flex items-center gap-x-0.5 rounded-md flex-none self-start">
                      <p className="text-gray500 text-xs font-normal mt-[1px]">
                        4.5
                      </p>
                      <img src={star} />
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div className="flex gap-1 w-28 flex-none">
                      <Phone className="w-4 h-4 text-grayLight600" />
                      <p className="mb-0 text-xs text-grayLight600">Phone</p>
                    </div>
                    <div className="text-xs text-gray500">
                      {getFormatPhoneWithPlus(
                        threadsCommunication.carrierPhone ||
                          threadsCommunication.customerPhone
                      ) ?? '-'}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div className="flex gap-1 w-28 flex-none">
                      <Mail02 className="w-4 h-4 text-grayLight600" />
                      <p className="mb-0 text-xs text-grayLight600">Email</p>
                    </div>
                    <div className="text-xs text-gray500">
                      {(threadsCommunication.carrierEmail ||
                        threadsCommunication.customerEmail) ??
                        '-'}
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex gap-1 w-28 flex-none">
                      <MarkerPin01 className="w-4 h-4 text-grayLight600" />
                      <p className="mb-0 text-xs text-grayLight600">Address</p>
                    </div>
                    <div className="text-xs text-gray500">
                      {(threadsCommunication.carrierAddress ||
                        threadsCommunication.customerAddress) ??
                        '-'}
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex gap-1 w-28 flex-none">
                      {/* <Globe01 className="w-4 h-4 text-grayLight600" /> */}
                      <img src={Globe01} className="w-4 h-4" />
                      <p className="mb-0 text-xs text-grayLight600">Website</p>
                    </div>
                    <div className="flex text-xs text-gray500 truncate">
                      <a
                        href={
                          threadsCommunication.customerWebsite ||
                          threadsCommunication.carrierWebsite ||
                          false
                        }
                        className="text-primary700 font-semibold truncate"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {threadsCommunication.customerWebsite ||
                          threadsCommunication.carrierWebsite ||
                          '-'}
                      </a>
                      {(threadsCommunication.customerWebsite ||
                        threadsCommunication.carrierWebsite) && (
                        <ArrowUpRight className="w-3.5 h-4 ml-1 mt-0.5 cursor-pointer text-primary700 flex-none" />
                      )}
                    </div>
                  </div>
                </div>

                <div className="space-y-2">
                  <h6 className="text-xs font-medium text-gray500 py-3">
                    Contacts {threadsCommunication?.contactDetails?.length}
                  </h6>
                  <ul className="w-full overflow-auto custom-scrollbar flex p-1 gap-x-2.5">
                    {threadsCommunication?.contactDetails?.map(
                      (item: any, index: any) => (
                        <li
                          className="p-2 space-y-2 w-[74px] text-center min-w-[22%] rounded-lg"
                          key={index}
                        >
                          <figure className="w-10 h-10 rounded-full border border-utilityGray200 flex-none mx-auto">
                            <img
                              src={item.image}
                              className="w-full h-full object-cover rounded-full "
                            />
                          </figure>
                          {/* IF NO IMAGE FOUND BELOW CODE WILL BE USED */}
                          {/* <div className="w-10 h-10 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm mx-auto">
                            WA
                          </div> */}
                          <h6 className="text-grayLight900 text-xs font-normal line-clamp-1">
                            {item.name}
                          </h6>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </>
          )}

          {/* <form>
        <div className="xl:p-4 p-3 flex gap-2 border-t border-utilityGray200">
          <InputText
            inputName="email"
            placeholder="Message"
            id="chatInput"
            className=""
            value={''}
            onChangeFunc={() => {}}
            parentClassName="flex-1"
          />

          <ButtonCmp className="btn_primary lg:!px-[9px] !px-2">
            <Send01 className="w-4 h-4" />
          </ButtonCmp>
        </div>
      </form> */}

          {/* Contact Suggestion design */}
          {/* <div className="p-4">
        <div className="p-5 pt-10 rounded-xl border border-utilityGray200 relative">
          <div className="absolute top-[-10px] left-1/2 -translate-x-1/2 gap-1 bg-white flex items-center rounded-full border border-utilityGray200 px-1.5 py-[2px]">
            <img src={AiStarIcon} alt="" className="w-[14px]" />
            <p className="text-xs font-medium text-textSecondary">
              Contact Suggestion
            </p>
          </div>

          <div className="flex flex-col items-center">
            <div className="w-[100px] h-[100px] bg-utilityGray100 rounded-full flex items-center justify-center mb-2">
              <img src="" alt="" className="" />
              <span className="text-5xl font-semibold text-primary">AC</span>
            </div>
            <h6 className="text-lg text-grayLight900 font-semibold leading-6 mb-2">
              Angela Campbell
            </h6>
            <div className="flex flex-1 justify-end gap-3 mb-6">
              <ButtonCmp
                icon={<Phone className="w-4 h-4" />}
                className="btn_secondary_black lg:px-[9px] px-2"
              >
                <></>
              </ButtonCmp>
              <ButtonCmp
                icon={<Mail02 className="w-4 h-4" />}
                className="btn_secondary_black lg:px-[9px] px-2"
              >
                <></>
              </ButtonCmp>
            </div>
          </div>

          <div className="flex flex-col gap-y-3 mb-4">
            <div className="flex items-center">
              <div className="flex gap-1 w-28">
                <Users01 className="w-4 h-4 text-grayLight600" />
                <p className="mb-0 text-xs text-grayLight600">Customer</p>
              </div>
              <div className="text-xs text-gray500">Customer</div>
            </div>
            <div className="flex items-center">
              <div className="flex gap-1 w-28">
                <User01 className="w-4 h-4 text-grayLight600" />
                <p className="mb-0 text-xs text-grayLight600">Role</p>
              </div>
              <div className="text-xs text-gray500">Operation</div>
            </div>
            <div className="flex items-center">
              <div className="flex gap-1 w-28">
                <Phone01 className="w-4 h-4 text-grayLight600" />
                <p className="mb-0 text-xs text-grayLight600">Phone</p>
              </div>
              <div className="text-xs text-gray500">+1 (315) 623-3438</div>
            </div>
          </div>

          <ButtonCmp className="btn btn-outline-primary w-full">
            Save to contacts
          </ButtonCmp>
        </div>
      </div> */}
        </>
      )}
    </>
  );
};

export default ContactDetails;
