import {
  Calendar,
  CheckCircle,
  Clock,
  Edit05,
  Mail01,
  SwitchHorizontal01,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import ConfirmationModalCmp from 'src/components/ConfirmModal/ConfirmationModalCmp';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getDateWithSuffixFormat,
  getUtcToLocalFormattedPickupDate,
} from 'src/utils/CommonFunctions';

import { OrderContext } from '..';
import DraftMailModal from '../../Common/DraftMailModal';
import {
  appointmentDetails,
  deliveryAppointments,
  fieldMapping,
  getEmailMessageForAppointMent,
  pickupAppointments,
} from '../../order.constant';
import { initAction } from '../../order.interface';

const Appointment = () => {
  const {
    setValue,
    getValues,
    control,
    trigger,
    // formState: { errors },
  } = useFormContext();

  const { currentLegData, setCurrentLegData, isLoading } =
    useContext<any>(OrderContext);

  const [action, setAction] = useState<any>(initAction);

  const [editMode, setEditMode] = useState<{
    type: string | null;
    details: any;
  }>({ type: null, details: null });

  const handleDeleteAppointment = (type: any) => {
    const updatedLegData = { ...currentLegData };

    const fields = fieldMapping[type];

    if (fields) {
      updatedLegData[fields.datetimeField] = null;
      updatedLegData[fields.dateFieldName] = null;
      updatedLegData[fields.timeFieldName] = null;

      setValue(fields.datetimeField, null);
      setValue(fields.dateFieldName, null);
      setValue(fields.timeFieldName, null);

      if (fields.statusField) {
        updatedLegData[fields.statusField] = 'Pending';
        setValue(fields.statusField, 'Pending');
      }
    }

    setCurrentLegData((old: any) => ({ ...old, ...updatedLegData }));
  };

  const handleEdit = (type: string, details: any) => {
    setEditMode({ type, details });
    setAction((old: any) => ({
      ...old,
      mode: type,
    }));
  };

  const handleUpdateAppointmentStatus = () => {
    const fields = fieldMapping[action?.type];

    if (fields) {
      const updateValue = (key: any, value: any) => {
        if (setValue) {
          setValue(key, value);
        }
      };

      updateValue(fields.statusField, action?.status);

      let updatedLegData = {
        ...currentLegData,
        [`${fields.statusField}`]: action?.status,
      };

      if (setCurrentLegData) {
        setCurrentLegData(updatedLegData);
      }
    }

    setAction(initAction);
  };

  const handleAppointment = async (dateField: string, timeField: string) => {
    if (getValues(timeField) && getValues(dateField)) {
      const validationResults = await trigger([dateField, timeField]);

      if (!validationResults) {
        return;
      }

      const cobmineDate = `${moment
        .tz(
          `${moment
            .utc(getValues(dateField))
            .local()
            .format('YYYY-MM-DD')} ${getValues(timeField)}`,
          'YYYY-MM-DD HH:mm',
          moment.tz.guess()
        )
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')}`;

      if (dateField === 'shipperAppointmentDate') {
        setValue('shipperAppointmentDatetime', cobmineDate);
        setCurrentLegData((old: any) => ({
          ...old,
          shipperAppointmentDatetime: cobmineDate,
        }));
      } else if (dateField === 'carrierPickupAppointmentDate') {
        setValue('carrierPickupAppointmentDatetime', cobmineDate);
        setCurrentLegData((old: any) => ({
          ...old,
          carrierPickupAppointmentDatetime: cobmineDate,
        }));
      } else if (dateField === 'carrierDeliveryAppointmentDate') {
        setValue('deliveryAppointmentDatetime', cobmineDate);
        setCurrentLegData((old: any) => ({
          ...old,
          deliveryAppointmentDatetime: cobmineDate,
        }));
      } else if (dateField === 'deliveryAppointmentDate') {
        setValue('carrierDeliveryAppointmentDatetime', cobmineDate);
        setCurrentLegData((old: any) => ({
          ...old,
          carrierDeliveryAppointmentDatetime: cobmineDate,
        }));
      }

      setEditMode({ type: null, details: null });
    }
  };

  const AppointmentCard = ({
    title,
    mode,
    field,
    status,
    isLoadingAppointment,
    dateFieldName,
    timeFieldName,
  }: {
    title: string;
    mode: string;
    field: string;
    status?: string;
    isLoadingAppointment: boolean;
    dateFieldName: string;
    timeFieldName: string;
  }) => {
    const appointmentDate = currentLegData?.[field];
    const hasAppointment =
      editMode.type === mode ? false : appointmentDate != null;
    const hasAppointmentConfirmed =
      status && currentLegData?.[status] === 'Confirmed' ? true : false;
    const formatDate = (date: string) => ({
      month: moment.utc(date).local().format('MMM'),
      day: moment.utc(date).local().format('DD'),
      fullDate: getUtcToLocalFormattedPickupDate(date, false, true),
      time: moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local().format('HH:mm'),
    });

    return (
      <>
        <div className="sm:p-1.5 sm:flex-1 sm:min-w-[50%]">
          <div className="flex justify-between items-center">
            <h6 className="form_label mb-1.5">{title}</h6>

            {editMode.type === mode && (
              <ButtonCmp
                type="button"
                onClick={() => {
                  handleAppointment(dateFieldName, timeFieldName);
                }}
                className={`btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent !gap-0.5 `}
              >
                Update
              </ButtonCmp>
            )}
          </div>

          {hasAppointment ? (
            <div className="rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs flex items-center gap-x-3 cursor-pointer px-4 py-3 min-h-[64px]">
              <div
                className="leading-[1] text-sm font-medium rounded p-1 border border-utilityGray200 h-[38px] w-[38px] text-primary700  flex flex-col items-center justify-center"
                onClick={() => {
                  handleEdit(mode, {
                    date: appointmentDate,
                    time: appointmentDate,
                  });
                }}
              >
                <p>{formatDate(appointmentDate).month}</p>
                <p>{formatDate(appointmentDate).day}</p>
              </div>
              <div
                className="flex-1 text-xs"
                onClick={() => {
                  handleEdit(mode, {
                    date: appointmentDate,
                    time: appointmentDate,
                  });
                }}
              >
                <h6 className="text-grayLight900 font-medium">
                  {formatDate(appointmentDate).fullDate}
                </h6>
                <p className="text-textSecondary font-normal">
                  {status && <>{formatDate(appointmentDate).time}</>}
                </p>
              </div>
              <div className="flex items-center gap-2">
                {status && (
                  <BadgeCmp
                    style="modern"
                    mainClassName="!mr-3"
                    type={hasAppointmentConfirmed ? 'success' : 'error'}
                  >
                    {hasAppointmentConfirmed ? 'Confimred' : 'Pending'}
                  </BadgeCmp>
                )}

                <TooltipCmp message="Edit">
                  <Edit05
                    className={`w-4 h-4 cursor-pointer text-textSecondary flex-none`}
                    onClick={() => {
                      handleEdit(mode, {
                        date: appointmentDate,
                        time: appointmentDate,
                      });
                    }}
                  />
                </TooltipCmp>

                {status && (
                  <TooltipCmp
                    message={
                      hasAppointmentConfirmed
                        ? 'Revert to Pending'
                        : 'Confirm Appointment'
                    }
                  >
                    <SwitchHorizontal01
                      className={`w-4 h-4 cursor-pointer text-textSecondary flex-none`}
                      onClick={() => {
                        if (hasAppointmentConfirmed) {
                          setAction((old: any) => ({
                            ...old,
                            mode: 'appointment-confirm-modal',
                            type: mode,
                            status: 'Pending',
                          }));
                        } else {
                          setAction((old: any) => ({
                            ...old,
                            mode: 'appointment-confirm-modal',
                            type: mode,
                            status: 'Confirmed',
                          }));
                        }
                      }}
                    />
                  </TooltipCmp>
                )}

                {status && (
                  <TooltipCmp message="Draft Mail">
                    <Mail01
                      className={`w-4 h-4 cursor-pointer text-textSecondary flex-none`}
                      onClick={() => {
                        setAction((old: any) => ({
                          ...old,
                          mode: 'appointment-darft-modal',
                          type: mode,
                        }));
                      }}
                    />
                  </TooltipCmp>
                )}

                <TooltipCmp message="Delete">
                  <Trash01
                    className={`w-4 h-4 cursor-pointer text-textSecondary flex-none`}
                    onClick={() => {
                      handleDeleteAppointment(mode);
                    }}
                  />
                </TooltipCmp>
              </div>
            </div>
          ) : (
            <div
              className={`rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs flex items-center gap-x-3 cursor-pointer px-4 py-3 min-h-[64px] ${
                isLoadingAppointment ? 'custom-loading' : ''
              }`}
            >
              <Controller
                name={dateFieldName}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DateSelect
                    inputName={dateFieldName}
                    className="form_control"
                    parentClassName="datepicker-w-auto one-month-datepicker w-1/2 max-sm:mt-4"
                    selected={value}
                    onChangeFunc={(e: any) => {
                      onChange(e?.value);

                      if (!editMode?.type || editMode?.type !== mode) {
                        handleAppointment(dateFieldName, timeFieldName);
                      }
                    }}
                    minDate={moment().toDate()}
                    placeholder="Select Date"
                    dateFormat="dd/MM/yyyy"
                    icon={<Calendar className="h-5 w-5" />}
                    value={
                      value
                        ? getDateWithSuffixFormat(
                            getUtcToLocalFormattedPickupDate(value, false, true)
                          )
                        : undefined
                    }
                    onInputClick={() => {
                      if (editMode?.type && editMode?.type !== mode) {
                        setEditMode({ type: null, details: null });
                      }
                    }}
                    dateSelectClassName={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    autoFocus
                    isClearable
                  />
                )}
              />
              <Controller
                name={timeFieldName}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName={timeFieldName}
                    placeholder="HH:mm"
                    parentClassName={`w-1/2`}
                    value={value as string}
                    onChangeFunc={(e) => {
                      let timeValue = e.target.value;

                      const [firstDigit, secondDigit] = timeValue
                        .split('')
                        .map(Number);

                      if (firstDigit > 2) {
                        return false;
                      }

                      if (firstDigit === 2 && secondDigit > 3) {
                        return false;
                      }

                      const minuteFirstDigit = parseInt(timeValue[3]);

                      if (minuteFirstDigit > 5) {
                        return false;
                      }

                      if (timeValue.includes(':')) {
                        const [hours] = timeValue.split(':');

                        if (!hours || hours.length !== 2) {
                          return false;
                        }
                      }

                      onChange(timeValue);
                    }}
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    mask="99:99"
                    icon={
                      <div className="absolute top-1/2 -translate-y-1/2 right-4">
                        <Clock className="h-4 w-4" />
                      </div>
                    }
                    onBlur={() => {
                      if (!editMode?.type || editMode?.type !== mode) {
                        handleAppointment(dateFieldName, timeFieldName);
                      }
                    }}
                    // onFocus={() => {
                    //   if (editMode?.type && editMode?.type !== mode) {
                    //     setEditMode({ type: null, details: null });
                    //   }
                    // }}
                  />
                )}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  const memorizedPickupAppointment = useMemo(
    () =>
      pickupAppointments.map((appointment, index) => (
        <AppointmentCard
          key={index}
          title={appointment.title}
          mode={appointment.mode}
          field={appointment.field}
          status={appointment.status}
          isLoadingAppointment={isLoading}
          dateFieldName={appointment?.dateFieldName}
          timeFieldName={appointment?.timeFieldName}
        />
      )),
    [pickupAppointments, currentLegData, editMode]
  );

  const memorizedDeliveryAppointment = useMemo(
    () =>
      deliveryAppointments.map((appointment, index) => (
        <AppointmentCard
          key={index}
          title={appointment.title}
          mode={appointment.mode}
          field={appointment.field}
          status={appointment.status}
          isLoadingAppointment={isLoading}
          dateFieldName={appointment?.dateFieldName}
          timeFieldName={appointment?.timeFieldName}
        />
      )),
    [deliveryAppointments, currentLegData, editMode]
  );

  return (
    <>
      <div>
        <h6 className="text-textSecondary text-sm font-medium mb-1 leading-[1.5]">
          Pickup Appointments
        </h6>
        <div className="rounded-xl border border-utilityGray200 bg-gray25 p-5">
          <div className="flex flex-wrap -m-1.5">
            {memorizedPickupAppointment}
          </div>
        </div>
      </div>

      <div>
        <h6 className="text-textSecondary text-sm font-medium mb-1 leading-[1.5]">
          Delivery Appointments
        </h6>
        <div className="rounded-xl border border-utilityGray200 bg-gray25 p-5">
          <div className="flex flex-wrap -m-1.5">
            {memorizedDeliveryAppointment}
          </div>
        </div>
      </div>

      {action.mode === 'appointment-confirm-modal' && (
        <ConfirmationModalCmp
          title={
            action.status === 'Pending'
              ? 'Revert to Pending'
              : 'Confirm Appointment'
          }
          description={appointmentDetails[action.type]?.messages[action.status]}
          Icon={<CheckCircle className="w-7 h-7" />}
          handleClose={() => setAction(initAction)}
          isSubmitting={isLoading}
          handleSubmit={handleUpdateAppointmentStatus}
          submitBtnText={
            action.status === 'pending' ? 'Mark Pending' : 'Confirm'
          }
        />
      )}

      {action.mode === 'appointment-darft-modal' &&
        appointmentDetails[action.type]?.title && (
          <DraftMailModal
            handleClose={() => setAction(initAction)}
            title={appointmentDetails[action.type].emailDraftModalTitle}
            DraftDetails={{
              subjectLine: appointmentDetails[action.type].draftTitle,
              toEmailAddress:
                appointmentDetails[action.type].sendMailTo === 'shipper'
                  ? currentLegData?.shipper?.email
                  : appointmentDetails[action.type].sendMailTo === 'consignee'
                  ? currentLegData?.consignee?.email
                  : appointmentDetails[action.type].sendMailTo === 'carrier'
                  ? currentLegData?.carrier?.length > 0
                    ? currentLegData?.carrier[0]?.email
                    : ''
                  : '',
              emailDetails: getEmailMessageForAppointMent(
                action.type,
                currentLegData
              ),
            }}
          />
        )}
    </>
  );
};

export default Appointment;
