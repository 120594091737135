import { Mail02, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { getSpamEmails } from 'src/services/SettingService';
import WalToast from 'src/utils/WalToast';

import DeleteSpamEmailModal from './DeleteSpamEmailModal';

interface SpamEmail {
  id: number;
  email: string;
  non_classified_count: number;
  isSpam: boolean;
  created_at?: string;
}

const SpamEmailSettings = () => {
  const [spamEmails, setSpamEmails] = useState<SpamEmail[]>([]);
  const [emailToDelete, setEmailToDelete] = useState<SpamEmail | null>(null);

  const fetchSpamEmails = async () => {
    try {
      const response = await getSpamEmails();

      if (response.data) {
        setSpamEmails(response.data);
      }
    } catch (error) {
      console.error('Error fetching spam emails:', error);
      WalToast.error('Failed to fetch spam emails');
    }
  };

  const handleRemoveSpamEmail = (email: SpamEmail) => {
    setEmailToDelete(email);
  };

  const handleDeleteModalClose = (success?: boolean) => {
    if (success) {
      fetchSpamEmails(); // Refresh the list
    }
    setEmailToDelete(null);
  };

  useEffect(() => {
    fetchSpamEmails();
  }, []);

  const spamEmailsList = spamEmails.filter((email) => email.isSpam);
  const nonSpamEmailsList = spamEmails.filter((email) => !email.isSpam);

  const headCells = [
    {
      id: 'email',
      name: 'Email',
    },
    {
      id: 'non_classified_count',
      name: 'Non Classified Count',
    },
    {
      id: 'actions',
      name: '',
    },
  ];

  const SpamEmailRow = ({ data }: { data: SpamEmail }) => (
    <>
      <td className="px-5 py-4">{data.email}</td>
      <td className="px-5 py-4 w-[30%] min-w-[30%] max-w-[30%]">
        {data.non_classified_count}
      </td>
      <td className="px-5 py-4 w-[65px] min-w-[65px] max-w-[65px]">
        <TooltipCmp message="Remove Email" childrenClassName="cursor-pointer">
          <Trash01
            className="w-4 h-4 cursor-pointer"
            onClick={() => handleRemoveSpamEmail(data)}
          />
        </TooltipCmp>
      </td>
    </>
  );

  const SpamEmailLoaderRow = () => (
    <>
      <td className="px-5 py-4">
        <span className="custom-loading">noreply@xyz.com</span>
      </td>
      <td className="px-5 py-4 w-[30%] min-w-[30%] max-w-[30%]">
        <span className="custom-loading">44</span>
      </td>
      <td className="px-5 py-4 w-[65px] min-w-[65px] max-w-[65px]">
        <div className="w-4 h-4 custom-loading">
          <Trash01 className="w-4 h-4" />
        </div>
      </td>
    </>
  );

  return (
    <>
      <div className="flex-1 overflow-y-hidden w-full px-2 py-1 space-y-4 flex flex-col">
        <div className="w-full flex justify-between border-b border-utilityGray200 pb-4">
          <div>
            <h6 className="text-grayLight900 font-semibold text-sm">
              Email Settings
            </h6>
            <p className="text-grayLight600 font-normal text-sm">
              Manage and review your email details here.
            </p>
          </div>
        </div>
        <div className="flex gap-x-5 flex-1 overflow-y-hidden">
          <div className="flex-1 w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  overflow-hidden">
            <div className="table-top-header">
              <div className="table-left-wrap">
                <div className={`table-title-wrap`}>
                  <h5 className="table-title">Spam Email</h5>
                </div>
                <p className="table-subtitle">List of emails marked as spam</p>
              </div>
            </div>
            <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col">
              <div
                className={`overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination ${
                  spamEmailsList.length !== 0 && 'flex-1 '
                }`}
              >
                <TableCmp
                  headCells={headCells}
                  tableDataArr={spamEmailsList}
                  TableRowCmp={SpamEmailRow}
                  TableLoaderRowCmp={SpamEmailLoaderRow}
                />
              </div>
              {spamEmailsList.length === 0 && (
                <NotFoundUI
                  title="No spam emails found"
                  desc="No spam alerts – No unwanted messages detected"
                  containerClassName="!my-4 flex-1"
                  iconClassName="!hidden"
                  iconSecondaryClassName="!flex"
                  iconSecondary={<Mail02 className="w-5 h-5" />}
                />
              )}
            </div>
          </div>

          <div className="flex-1 w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  overflow-hidden">
            <div className="table-top-header">
              <div className="table-left-wrap">
                <div className={`table-title-wrap`}>
                  <h5 className="table-title">Non-spam Emails</h5>
                  <BadgeCmp
                    style="modern"
                    type="success"
                    mainClassName="flex-none"
                  >
                    {nonSpamEmailsList.length}{' '}
                    {nonSpamEmailsList.length > 1 ? 'Emails' : 'Email'}
                  </BadgeCmp>
                </div>
                <p className="table-subtitle">
                  List of emails marked as non-spam
                </p>
              </div>
            </div>
            <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col">
              <div
                className={`overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination ${
                  nonSpamEmailsList.length !== 0 && 'flex-1 '
                }`}
              >
                <TableCmp
                  headCells={headCells}
                  tableDataArr={nonSpamEmailsList}
                  TableRowCmp={SpamEmailRow}
                  TableLoaderRowCmp={SpamEmailLoaderRow}
                />
              </div>
              {nonSpamEmailsList.length === 0 && (
                <NotFoundUI
                  title="No new emails found"
                  desc="Your inbox is clean – No new messages detected."
                  containerClassName="!my-4 flex-1"
                  iconClassName="!hidden"
                  iconSecondaryClassName="!flex"
                  iconSecondary={<Mail02 className="w-5 h-5" />}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {emailToDelete && (
        <DeleteSpamEmailModal
          email={emailToDelete.email}
          id={emailToDelete.id}
          handleClose={handleDeleteModalClose}
        />
      )}
    </>
  );
};

export default SpamEmailSettings;
