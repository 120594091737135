import { Check } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

import ActionTooltip from '../ActionTooltip';

interface Option {
  value: string | number;
  label: string;
}

interface IProps {
  label: any;
  isOpenChildTopPosOnTopFalse?: boolean;
  actionTooltipSelectWrap?: string;
  options?: Option[];
}

const SelectWithActionTooltip = ({
  label,
  isOpenChildTopPosOnTopFalse,
  actionTooltipSelectWrap,
  options = [],
}: IProps) => {
  // This function used to render floating tooltip on bottom instead of top
  const tooltipItems = (
    <ul className={`action-tooltip-select ${actionTooltipSelectWrap}`}>
      {options.map(
        (
          option,
          index // Iterate over the options array
        ) => (
          <li key={index} className="action-tooltip-select-item">
            <p className="action-tooltip-select-item-title">{option.label}</p>
            {/* Use option.label here */}
            <Check className="w-3 h-3 text-primary flex-none" />
          </li>
        )
      )}
    </ul>
  );

  return (
    <>
      <ActionTooltip
        message={tooltipItems}
        isOpenChildTopPosOnTop={isOpenChildTopPosOnTopFalse ? false : true}
        type="light"
        parentClassName="action-tooltip-select-wrap"
      >
        {label}
      </ActionTooltip>
    </>
  );
};

export default SelectWithActionTooltip;
