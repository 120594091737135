import {
  Edit05,
  Trash01,
  Plus,
  // UserPlus01,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import SalesModal from 'src/app/Orders/Common/Sales/SalesModal';
import ButtonCmp from 'src/components/ButtonCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { getShortName } from 'src/utils/CommonFunctions';

import { OrderContext } from '..';

interface IProps {
  setValue: any;
  clearErrors: any;
  getValues: any;
}

const RenderSalesPriceSection = ({
  setValue,
  clearErrors,
  getValues,
}: IProps) => {
  const {
    id,
    customerList,
    isCustomerLoading,
    order,
    carrierPrice,
    salesPrice,
    setSalesPrice,
  } = useContext<any>(OrderContext);

  const customerData = customerList?.find(
    (item: any) => item.value === getValues('orderCustomerId')
  );

  const [isSaleFormOpen, setIsSaleFormOpen] = useState<boolean>(false);
  const [salesEditData, setSalesEditData] = useState<any>(null);
  const [salePrice, setSalePrice] = useState<any>(null);
  const [isSalesPriceAdded, setIsSalesPriceAdded] = useState(false);
  const [additionalCharge, setAdditionalCharge] = useState<any>(null);
  const [margin, setMargin] = useState<any>(null);
  const [marginPercentage, setMarginPercentage] = useState<any>(null);

  useEffect(() => {
    if (salesPrice && salesPrice?.length > 0) {
      setSalePrice(salesPrice[0]);

      const additionalChargesTotal = Array.isArray(
        salesPrice[0]?.additional_charge
      )
        ? salesPrice[0]?.additional_charge.reduce(
            (total: number, item: any) => total + (item.price || 0),
            0
          )
        : 0;

      const combinedMargin =
        salesPrice[0]?.totalSalePrice -
        (carrierPrice?.length > 0 && carrierPrice[0]?.totalPrice > 0
          ? carrierPrice[0]?.totalPrice
          : salesPrice[0]?.totalSalePrice * 0.9);

      const combinedMarginPercentage =
        salesPrice[0]?.totalSalePrice > 0
          ? (combinedMargin / salesPrice[0]?.totalSalePrice) * 100
          : 0;

      setMargin(combinedMargin?.toFixed(2));
      setMarginPercentage(combinedMarginPercentage?.toFixed(2));
      setAdditionalCharge(additionalChargesTotal);
      setIsSalesPriceAdded(true);
    } else {
      setSalePrice(null);
      setMargin(null);
      setMarginPercentage(null);
      setAdditionalCharge(null);
      setIsSalesPriceAdded(false);
    }
  }, [salesPrice, carrierPrice]);

  const handleSalesClose = () => {
    setIsSaleFormOpen(false);
  };

  const handleSalesEdit = (data: any) => {
    setSalesEditData(data);
    setIsSaleFormOpen(true);
  };

  const handleAddSalesPrice = (newSalePrice: any) => {
    console.log('newSalePrice', newSalePrice);

    setSalesPrice(() => [newSalePrice]);
    setValue('salesPrice', [newSalePrice]);
    clearErrors('salesPrice');
  };

  const handleSalesDelete = () => {
    setSalesPrice([]);
    setValue('salesPrice', []);
    setSalesEditData(null);
  };

  return (
    <>
      <li className="last:border-b-0 border-b border-utilityGray200 p-3 space-y-1.5">
        <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
          Customer
        </h6>
        <div className="flex items-center gap-x-1.5">
          {isSalesPriceAdded ? (
            <>
              {salePrice?.customer?.image ? (
                <img
                  className="w-10 h-10 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none"
                  src={`${salePrice?.customer?.image}`}
                />
              ) : (
                <div className="w-10 h-10 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
                  {getShortName(salePrice?.customer?.name ?? 'Unassigned')}
                </div>
              )}

              <div className="truncate flex-1">
                <h6 className="text-grayLight900 text-sm font-medium truncate">
                  <TooltipCmp
                    message={
                      salePrice?.customer?.name &&
                      salePrice?.customer?.name?.length > 18 &&
                      salePrice?.customer?.name
                    }
                  >
                    {salePrice?.customer?.name}
                  </TooltipCmp>
                </h6>
                <p className="flex items-center gap-1 text-grayLight600 text-xs font-normal">
                  USD Billing Account
                </p>
              </div>

              <div className="items-center gap-2 flex ">
                <Edit05
                  className="w-4 h-4 cursor-pointer text-textSecondary"
                  onClick={() => {
                    handleSalesEdit(salePrice);
                  }}
                />
                <Trash01
                  className="w-4 h-4 cursor-pointer text-textSecondary"
                  onClick={() => {
                    handleSalesDelete();
                  }}
                />
              </div>
            </>
          ) : (
            <>
              {/* <div
                className={`w-10 h-10 rounded-md border border-black/[0.08] flex-none flex items-center justify-center bg-utilityGray100 text-gray500 `}
              >
                <UserPlus01 className="w-6 h-6" />
              </div> */}
              {customerData?.image ? (
                <img
                  className="w-10 h-10 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none"
                  src={`${customerData?.image}`}
                />
              ) : (
                <div className="w-10 h-10 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
                  {getShortName(customerData?.label ?? 'Unassigned')}
                </div>
              )}

              <div className="truncate flex-1">
                <h6 className="text-grayLight900 text-sm font-medium truncate">
                  {customerData?.label ?? 'Unassigned Customer'}
                </h6>
                <p className="flex items-center gap-1 text-grayLight600 text-xs font-normal">
                  USD Billing Account
                </p>
              </div>

              <ButtonCmp
                className="btn-outline-primary !shadow-none !bg-transparent !border-0 !p-0"
                onClick={() => {
                  setSalesEditData(null);
                  setIsSaleFormOpen(true);
                }}
                icon={<Plus className="w-4 h-4 text-primary700" />}
              >
                Add sale price
              </ButtonCmp>
            </>
          )}
        </div>
      </li>

      <li className="last:border-b-0 border-b border-utilityGray200 p-3 flex">
        <div className="w-1/3 space-y-1">
          <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
            Base Sale Price
          </h6>
          <p className={`text-textSecondary text-sm font-medium`}>
            {salePrice?.baseSalePrice ? `$${salePrice?.baseSalePrice}` : '-'}
          </p>
        </div>
        <div className="w-1/3 space-y-1">
          <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
            Total Sale Price
          </h6>
          <p className={`text-textSecondary text-sm font-medium `}>
            {salePrice?.totalSalePrice ? `$${salePrice?.totalSalePrice}` : '-'}
          </p>
        </div>
        <div className="w-1/3 space-y-1">
          <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
            Additionals
          </h6>
          <p className={`text-textSecondary text-sm font-medium `}>
            {additionalCharge ? `$${additionalCharge}` : '-'}
          </p>
        </div>
      </li>
      <li className="last:border-b-0 border-b border-utilityGray200 p-3 flex">
        <div className="w-1/3 space-y-1">
          <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
            Margin
          </h6>
          <p className={`text-textSecondary text-sm font-medium `}>
            {margin ? `$${margin}` : '-'}
          </p>
        </div>
        <div className="w-1/3 space-y-1">
          <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
            Margin %
          </h6>
          <p className={`text-textSecondary text-sm font-medium `}>
            {marginPercentage ? `${marginPercentage}%` : '-'}
          </p>
        </div>
      </li>

      {isSaleFormOpen && (
        <SalesModal
          handleClose={handleSalesClose}
          handleAddSalesPrice={handleAddSalesPrice}
          orderSalesPrice={salesEditData}
          masterOrderId={id}
          customerList={customerList}
          isCustomerLoading={isCustomerLoading}
          customerId={order?.customerId ?? getValues('orderCustomerId')}
          additionalServices={getValues('additionalServices')?.serviceId ?? []}
        />
      )}
    </>
  );
};

export default RenderSalesPriceSection;
