import {
  CurrencyDollar,
  InfoCircle,
  PackageCheck,
  Percent02,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
import { MARGIN_TYPE, CURRENCY } from 'src/constants/common';
import { activeQuote, updatePDF } from 'src/services/QuoteService';
import { fixedDecimal, getShortName } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import avatar from '../../../../../assets/img/Avatar1.png';
import { getCarrierServicePrices } from '../../../../../services/CarrierService';

interface IProps {
  action: any;
  quoteID: any;
  handleClose: any;
  selectedCarrierRates: any[];
  currency: any | null;
  setIsRefresh: any;
}

const initRequiredFields = [
  'margin',
  'totalCharge',
  'finalCharge',
  // 'transitTime',
  // 'projectedDeliveryDate',
];

const tabArray: any = [
  {
    value: '$',
    name: '$',
  },
  {
    value: '%',
    name: '%',
  },
];

const MoveToActive = ({
  quoteID,
  action,
  handleClose,
  selectedCarrierRates,
  currency,
  setIsRefresh,
}: IProps) => {
  const carrierRates = Array.isArray(selectedCarrierRates)
    ? selectedCarrierRates
    : [selectedCarrierRates];

  const [formData, setFormData] = useState<any>({});
  const [requiredFields, setRequiredFields] = useState(initRequiredFields);
  const [isValid, setIsValid] = useState<any>(false);
  const [nonQuoteRequiredFields, setNonQuoteRequiredFields] = useState([]);
  const [activeMarginType, setActiveMarginType] = useState<any>('$');
  const [notRatedServices, setNotRatedServices] = useState<any>(0);
  const [isPrimaryBtnClicked, setIsPrimaryBtnClicked] =
    useState<boolean>(false);

  useEffect(() => {
    const existingFormData: any = {};
    const existingNonQuoteRequiredFields: any = [];
    const existingRequiredFields = [...initRequiredFields];
    let initialNotRatedServicesCount = 0;
    carrierRates.forEach((selectedCarrierRate: any) => {
      const quotedServices: any = [];
      const nonQuotedServices: any = [];
      const priceData: any = {};

      if (selectedCarrierRate.services && selectedCarrierRate.services.length) {
        selectedCarrierRate.services.forEach((service: any) => {
          if (service.isQuoted) {
            quotedServices.push({
              [service.serviceId.slug]: {
                name: service.serviceId?.name,
                value: service.value ? fixedDecimal(service.value, 2) : 0,
                serviceId: service.serviceId?.id,
              },
            });
          } else {
            initialNotRatedServicesCount++;
            nonQuotedServices.push({
              [service.serviceId.slug]: {
                name: service.serviceId?.name,
                value: service.value ? fixedDecimal(service.value, 2) : 0,
                serviceId: service.serviceId?.id,
              },
            });
            existingNonQuoteRequiredFields.push(service.serviceId.slug);
          }
          existingRequiredFields.push(service.serviceId.slug);
        });
      }

      getCarrierServicePrices(selectedCarrierRate.carrierId)
        .then((response: any) => {
          if (response.data && response.data.length) {
            response.data.forEach(
              (data: any) =>
                (priceData[data.serviceId] =
                  currency === CURRENCY.CAD ? data.priceCAD : data.priceUSD)
            );
          }
          selectedCarrierRate.carrierPriceData = priceData;
        })
        .catch(console.log);

      existingFormData[selectedCarrierRate.id] = {
        transitTime: selectedCarrierRate.transitTime,
        projectedDeliveryDate: selectedCarrierRate.projectedDeliveryDate,
        margin:
          selectedCarrierRate.margin > 0
            ? fixedDecimal(selectedCarrierRate.margin, 2)
            : selectedCarrierRate.margin,
        marginUnit:
          selectedCarrierRate.marginUnit > 0
            ? fixedDecimal(selectedCarrierRate.marginUnit, 2)
            : selectedCarrierRate.marginUnit,
        additionalCharges:
          selectedCarrierRate.additionalCharges > 0
            ? fixedDecimal(selectedCarrierRate.additionalCharges, 2)
            : 0,
        finalCharge:
          selectedCarrierRate.finalCharge > 0
            ? fixedDecimal(selectedCarrierRate.finalCharge, 2)
            : 0,
        totalCharge:
          selectedCarrierRate.totalCharge > 0
            ? fixedDecimal(selectedCarrierRate.totalCharge, 2)
            : 0,
        marginType: selectedCarrierRate.marginType,
        carrierPriceData: priceData,
        quotedServices,
        nonQuotedServices,
      };
    });
    setRequiredFields(existingRequiredFields);
    setNonQuoteRequiredFields(existingNonQuoteRequiredFields);
    setFormData(existingFormData);
    // setResetFormData(existingFormData);

    setNotRatedServices(initialNotRatedServicesCount);
  }, [selectedCarrierRates]);

  useEffect(() => {
    const existingValid: any = {};
    const existingValidNonQuote: any = {};
    let hasZeroValueInNonQuoted = false;
    // let count = 0;

    Object.entries(formData).forEach(([key, value]: any) => {
      const isValid1 = requiredFields.every(
        (requiredField) =>
          value[requiredField] !== '' && value[requiredField] !== null
      );
      let isValid2 = true;

      if (value.nonQuotedServices && value.nonQuotedServices.length) {
        isValid2 = value.nonQuotedServices.every((nonQuotedService: any) => {
          const isValidService = nonQuoteRequiredFields.every(
            (requiredField) => {
              if (nonQuotedService[requiredField]) {
                if (
                  nonQuotedService[requiredField].value === 0 ||
                  nonQuotedService[requiredField].value === '0.00'
                ) {
                  hasZeroValueInNonQuoted = true;
                  // count++;
                }

                return (
                  nonQuotedService[requiredField].value !== '' &&
                  nonQuotedService[requiredField].value !== null
                );
              }

              return true;
            }
          );

          return isValidService;
        });
      }
      existingValidNonQuote[key] = isValid2;
      existingValid[key] = isValid1 && isValid2;
    });

    let allCarrierValid: boolean = true;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    Object.entries(existingValid).forEach(([_, value]: any) => {
      if (!value) {
        allCarrierValid = false;
      }
    });

    // setNotRatedServices(count);
    setIsValid(allCarrierValid && !hasZeroValueInNonQuoted);
    // setIsValidNonQuote(existingValidNonQuote);
  }, [formData]);

  const handleInputChange =
    (id: number, isService = false) =>
    (event: any) => {
      const { target, type } = event;
      const { name, value } = target;
      const regex = /^[0-9.]*$/;

      if (!regex.test(value)) {
        return;
      }

      const existingFormData: any = {
        ...formData[id],
      };

      if (name === 'transitTime') {
        existingFormData.projectedDeliveryDate = moment()
          .clone()
          .add(value, 'days')
          .toDate();
      }

      const newValue =
        type === 'blur' && !value
          ? 0
          : type === 'focus' && value === '0'
          ? ''
          : value;

      if (!isService) {
        existingFormData[name] = newValue >= 0 ? newValue : 0;
      }

      const parseValue =
        type === 'blur' && !value
          ? 0
          : value && value >= 0
          ? parseFloat(value)
          : 0;

      let additionalCharges = 0;
      existingFormData.quotedServices.forEach(
        (quoteService: any, index: number) => {
          Object.entries(quoteService).forEach(([slug, service]: any) => {
            additionalCharges +=
              name === slug ? parseValue : parseFloat(service.value);
            existingFormData.quotedServices[index][slug] = {
              ...service,
              value:
                name === slug
                  ? newValue
                  : service.value > 0
                  ? fixedDecimal(service.value, 2)
                  : 0,
            };
          });
        }
      );
      existingFormData.nonQuotedServices.forEach(
        (nonQuoteService: any, index: number) => {
          Object.entries(nonQuoteService).forEach(([slug, service]: any) => {
            additionalCharges +=
              name === slug
                ? parseValue
                : service.value
                ? parseFloat(service.value)
                : 0;
            existingFormData.nonQuotedServices[index][slug] = {
              ...service,
              value:
                name === slug
                  ? newValue
                  : service.value !== null
                  ? service.value > 0
                    ? fixedDecimal(service.value, 2)
                    : 0
                  : 0,
            };
          });
        }
      );
      existingFormData.additionalCharges = fixedDecimal(additionalCharges, 2);

      const total: any =
        parseFloat(existingFormData.totalCharge || 0) +
        parseFloat(existingFormData.additionalCharges);

      existingFormData.marginType = MARGIN_TYPE.PERCENTAGE;

      let margin: any =
        existingFormData.margin >= 0 ? existingFormData.margin : 0;
      let marginUnit: any =
        existingFormData.marginUnit >= 0 ? existingFormData.marginUnit : 0;

      if (name === 'marginUnit') {
        margin =
          total > 0 && marginUnit > 0
            ? fixedDecimal((total / 100) * parseFloat(marginUnit.toString()), 2)
            : 0;
      } else {
        marginUnit =
          total > 0 && margin > 0
            ? fixedDecimal((100 * parseFloat(margin.toString())) / total, 2)
            : 0;
      }

      existingFormData.margin = margin;
      existingFormData.marginUnit = marginUnit;

      if (name === 'totalCharge') {
        if (type === 'blur') {
          existingFormData[name] = parseValue ? fixedDecimal(parseValue, 2) : 0;
        }
      }

      if (name === 'finalCharge') {
        if (type === 'blur') {
          existingFormData[name] =
            parseValue < total
              ? fixedDecimal(total, 2)
              : fixedDecimal(parseValue, 2);
        }
        let newMargin = parseValue - total;

        existingFormData.margin =
          parseValue < total
            ? 0
            : newMargin
            ? fixedDecimal(newMargin, 2)
            : newMargin;
      } else {
        if (parseFloat(existingFormData.margin) >= 0) {
          existingFormData.finalCharge = fixedDecimal(
            parseFloat(total) + parseFloat(existingFormData.margin),
            2
          );
        }
      }

      if (name === 'margin' && parseValue === 0) {
        existingFormData.finalCharge = fixedDecimal(total, 2);
      }

      setFormData((old: any) => ({ ...old, [id]: existingFormData }));
    };

  const handleTabChange = () => {
    setActiveMarginType((prevType: any) => (prevType === '$' ? '%' : '$'));
  };

  const handleSubmit = async () => {
    setIsPrimaryBtnClicked(true);

    try {
      if (action.mode === 'create') {
        await activeQuote(quoteID, {
          carrierPrices: formData,
          currency,
        });
        setIsRefresh(true);
        handleClose(false);
      } else if (action.mode === 'update') {
        let carrierId = selectedCarrierRates?.[0]?.id;

        updatePDF(carrierId, { ...formData[carrierId], currency })
          .then(() => {
            handleClose();
            setIsRefresh(true);
            WalToast.success('Price updated successfully');
            // if (response?.data) {
            //     const url = pdfLanguage === "English" ? `${response.data.url}${response.data.file}` : `${response.data.frenchUrl}${response.data.frenchFile}`;
            //     setTimeout(() => {
            //         downloadPdf(url, `${response.data.name} - ${response.data.file}`);
            //     }, 2000);
            // }
          })
          .finally(() => {
            setIsRefresh(true);
            handleClose(false);
          })
          .catch(console.error);
      }
    } catch (error) {
      handleClose(false);
      setIsRefresh(true);
    }
  };

  const carrierRate = carrierRates[0] || {};

  return (
    <CommonModal
      title={
        action.mode === 'update' ? 'Modify Pricing' : 'Move to Active Quote'
      }
      titleDesc={'Review rate and margin details to create an active quote.'}
      handleClose={() => handleClose(false)}
      headerIcon={<PackageCheck />}
      size={'max-w-[540px]'}
      isOverflow={true}
      modalClassName=""
      primaryBtnText="Confirm"
      secondaryBtnText="Cancel"
      primaryBtnDisabled={!isValid || isPrimaryBtnClicked}
      primaryBtnOnClick={handleSubmit}
      secondaryBtnOnClick={() => handleClose(false)}
    >
      <div className="w-full p-5 flex flex-col gap-y-4">
        {/* Warning message */}
        {notRatedServices !== 0 && (
          <div className="rounded-md bg-utilityError50 border border-utilityError300 px-3 py-2 flex gap-x-1 font-normal text-xs text-grayLight600 text-wrap">
            <InfoCircle className="text-textSecondary w-4 h-4 flex-none" />
            <div>
              <span className="text-grayLight900 font-semibold">Warning:</span>{' '}
              {notRatedServices} Additional Services not rated. Please contact
              carrier to verify feasibility of the shipment.
            </div>
          </div>
        )}

        {/* Carrier Info */}

        <div>
          <label className="form_label block mb-1.5">Carrier</label>
          <div className="rounded-lg border border-borderPrimary bg-white shadow-xs px-3 lg:py-[7px] py-2 flex gap-x-1.5 text-grayLight900 font-medium text-xs pointer-events-none items-center">
            {carrierRate.imageUrl && carrierRate.image ? (
              <div>
                <img
                  src={`${carrierRate.imageUrl}${carrierRate.image}`}
                  alt={carrierRate.name || 'Carrier Avatar'}
                  className="rounded-full w-5 h-5 border border-borderPrimary"
                  onError={(e) => {
                    e.currentTarget.src = avatar;
                  }}
                />
              </div>
            ) : (
              <div className="w-[18px] h-[18px] leading-[18px] bg-primary text-[8px] rounded-full font-bold text-white flex items-center justify-center uppercase flex-none">
                {getShortName(`${carrierRate.name}`)}
              </div>
            )}
            <p className="">{carrierRate.name || 'Unknown Carrier'}</p>
          </div>
        </div>
        <InputText
          label="Projected delivery"
          inputName="projectdelivery"
          placeholder="Projected delivery"
          className=""
          labelClassName="block mb-1.5"
          value={
            carrierRate.projectedDeliveryDate
              ? (() => {
                  const date = new Date(carrierRate.projectedDeliveryDate);
                  const day = date.getDate();
                  const month = date.toLocaleString('default', {
                    month: 'long',
                  });
                  const year = date.getFullYear();

                  return `${day} ${month}, ${year} (${carrierRate.transitTime} Business Days)`;
                })()
              : 'N/A'
          }
          parentClassName="pointer-events-none pb-4 border-b pb-4 border-utilityGray200"
        />

        {/* Base Quote */}
        {carrierRates.length > 0 && (
          <>
            {carrierRates.map((selectedCarrierRate: any) => (
              <div key={selectedCarrierRate.name} className="w-full">
                <label className="form_label block mb-1.5">Base Quote</label>
                <InputText
                  inputType="text"
                  inputName="totalCharge"
                  className="pl-8"
                  parentClassName=""
                  value={
                    formData[selectedCarrierRate.id]
                      ? formData[selectedCarrierRate.id].totalCharge
                      : 0
                  }
                  onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                  onFocus={() => {
                    if (formData[selectedCarrierRate.id]?.totalCharge === 0) {
                      setFormData((prevFormData: any) => ({
                        ...prevFormData,
                        [selectedCarrierRate.id]: {
                          ...prevFormData[selectedCarrierRate.id],
                          totalCharge: '',
                        },
                      }));
                      setIsValid(false);
                    }
                  }}
                  shouldFormatNumber={true}
                  icon={
                    <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                      <CurrencyDollar className="w-4 h-4" />
                    </div>
                  }
                />
              </div>
            ))}
          </>
        )}

        {/* Additional Services */}
        {carrierRates.map((selectedCarrierRate: any) => (
          <>
            {(formData[selectedCarrierRate.id]?.nonQuotedServices?.length > 0 ||
              formData[selectedCarrierRate.id]?.quotedServices?.length > 0) && (
              <>
                {/* Non-Quoted Services */}
                {formData[selectedCarrierRate.id]?.nonQuotedServices?.length >
                  0 &&
                  formData[selectedCarrierRate.id].nonQuotedServices.map(
                    (nonQuoteService: any) =>
                      Object.entries(nonQuoteService).map(
                        ([slug, service]: any) => (
                          <div key={slug} className="w-full">
                            <label className="form_label mb-1.5 flex gap-1">
                              {service.name}
                            </label>
                            <TooltipCmp
                              message={
                                !service.value ||
                                service.value === 0 ||
                                service.value === '0' ||
                                service.value === '0.00'
                                  ? `Carrier did not provide a rate for ${service.name}, for this additional service. Please validate feasibility and custom pricing.`
                                  : null
                              }
                            >
                              <InputText
                                inputName={slug}
                                className="pl-8"
                                parentClassName="[&:not(:last-child)]:mb-4"
                                value={service.value}
                                isError={
                                  !service.value ||
                                  service.value === 0 ||
                                  service.value === '0' ||
                                  service.value === '0.00'
                                }
                                icon={
                                  <CurrencyDollar className="input-currency-sign" />
                                }
                                onChangeFunc={handleInputChange(
                                  selectedCarrierRate.id,
                                  true
                                )}
                                shouldFormatNumber={true}
                                onFocus={handleInputChange(
                                  selectedCarrierRate.id,
                                  true
                                )}
                              />
                            </TooltipCmp>
                          </div>
                        )
                      )
                  )}

                {/* Quoted Services */}
                {formData[selectedCarrierRate.id]?.quotedServices?.length > 0 &&
                  formData[selectedCarrierRate.id].quotedServices.map(
                    (quotedService: any) =>
                      Object.entries(quotedService).map(
                        ([slug, service]: any) => (
                          <div key={slug} className="w-full">
                            <label className="form_label flex items-center gap-1">
                              {service.name}
                              <TooltipCmp message="Additional Service Pricing Returned from API.">
                                <InfoCircle className="text-primary w-3.5 h-3.5" />
                              </TooltipCmp>
                            </label>
                            <InputText
                              inputName={slug}
                              className="pl-8"
                              parentClassName="[&:not(:last-child)]:mb-4 pointer-events-none"
                              value={service.value}
                              onChangeFunc={handleInputChange(
                                selectedCarrierRate.id,
                                true
                              )}
                              shouldFormatNumber={true}
                              onFocus={handleInputChange(
                                selectedCarrierRate.id,
                                true
                              )}
                              icon={
                                <CurrencyDollar className="input-currency-sign" />
                              }
                            />
                          </div>
                        )
                      )
                  )}
              </>
            )}
          </>
        ))}

        {/* Additional Charges */}
        {/* {carrierRates.length > 0 && (
          <>
            {carrierRates.map((selectedCarrierRate: any) => (
              <InputText
                inputType="text"
                label="Additional Charges"
                inputName="additionalCharges"
                placeholder="Additional Charges"
                key={selectedCarrierRate.id}
                className="pl-8"
                labelClassName="block mb-1.5"
                value={
                  formData[selectedCarrierRate.id]
                    ? formData[selectedCarrierRate.id].additionalCharges
                    : 0
                }
                disabled
                icon={
                  <CurrencyDollar className="input-currency-sign" />
                }
              />
            ))}
          </>
        )} */}

        {/* Margin */}
        {carrierRates.length > 0 && (
          <>
            {carrierRates.map((selectedCarrierRate: any) => (
              <div
                key={selectedCarrierRate.name}
                className="border-t border-utilityGray200 pt-4"
              >
                <label className="form_label block mb-1.5">Margin</label>
                <div className="form_control p-0 relative !h-auto">
                  {activeMarginType === '$' ? (
                    <InputText
                      inputName="margin"
                      inputType="text"
                      placeholder="0"
                      className="!border-0 !h-auto !pl-8 !pr-20"
                      labelClassName="block mb-1.5"
                      value={formData[selectedCarrierRate.id]?.margin ?? 0}
                      onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                      shouldFormatNumber={true}
                      onFocus={() => {
                        if (formData[selectedCarrierRate.id]?.margin === 0) {
                          setFormData((prevFormData: any) => ({
                            ...prevFormData,
                            [selectedCarrierRate.id]: {
                              ...prevFormData[selectedCarrierRate.id],
                              margin: '',
                            },
                          }));
                          setIsValid(false);
                        }
                      }}
                      icon={<CurrencyDollar className="input-currency-sign" />}
                    />
                  ) : (
                    <InputText
                      inputName="marginUnit"
                      inputType="text"
                      placeholder="0"
                      className="!border-0 !h-auto !pl-8 !pr-20"
                      labelClassName="block mb-1.5"
                      value={formData[selectedCarrierRate.id]?.marginUnit ?? 0}
                      onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                      shouldFormatNumber={true}
                      onFocus={() => {
                        if (
                          formData[selectedCarrierRate.id]?.marginUnit === 0
                        ) {
                          setFormData((prevFormData: any) => ({
                            ...prevFormData,
                            [selectedCarrierRate.id]: {
                              ...prevFormData[selectedCarrierRate.id],
                              marginUnit: '',
                            },
                          }));
                          setIsValid(false);
                        }
                      }}
                      icon={
                        <Percent02 className="absolute -translate-y-2/4 top-2/4 left-3 text-gray500 h-3.5 w-3.5" />
                      }
                    />
                  )}

                  <TabButton
                    tabParentClassName="absolute top-1/2 -translate-y-1/2 right-1"
                    parentClassName="!bg-gray50 !p-[3px]"
                    className={`!rounded-md text-gray500 !border-0 !px-[8px] !py-0.5 !text-[11px] cursor-pointer first:font-normal [&>span]:text-gray500 !min-h-[auto]`}
                    activeClassName=" !bg-white [&>span]:!text-grayLight900 !shadow-md "
                    tabArray={tabArray}
                    isActive={activeMarginType}
                    isTab={true}
                    handleOnClick={handleTabChange}
                  />
                </div>
              </div>
            ))}
          </>
        )}

        {/* Total Price */}
        {carrierRates.length > 0 && (
          <>
            {carrierRates.map((selectedCarrierRate: any) => (
              <InputText
                inputType="text"
                label="Total Price"
                inputName="finalCharge"
                placeholder="Final Price"
                key={selectedCarrierRate.id}
                className="pl-8"
                labelClassName="block mb-1.5"
                parentClassName=""
                value={
                  formData[selectedCarrierRate.id]
                    ? formData[selectedCarrierRate.id].finalCharge
                    : 0
                }
                onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                shouldFormatNumber={true}
                icon={<CurrencyDollar className="input-currency-sign" />}
              />
            ))}
          </>
        )}
      </div>
    </CommonModal>
  );
};

export default MoveToActive;
