import { XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';

import Activity from './Activity';
import Analytics from './Analytics';
import Overview from './Overview';

interface IProps {
  handleClose: () => void;
}

const OverviewRateGathering = ({ handleClose }: IProps) => {
  const [section, setSection] = useState('overview');
  console.log('Overview Rate Gathering');

  return (
    <CommonModal
      handleClose={handleClose}
      size={'max-w-[750px] overflow-unset'}
      secondaryBtnOnClick={() => handleClose()}
      isOverflow={false}
    >
      <div className="relative">
        {/* Green background header */}
        <div className="relative h-32 bg-lime-50 rounded-t-lg"></div>
        {/* <div className="absolute top-0 left-0 right-0 h-32 bg-green-50 rounded-t-lg"></div> */}

        {/* Close button */}
        <div className="absolute top-4 right-4">
          <button
            onClick={handleClose}
            className="p-1 hover:bg-gray-100/50 rounded-full transition-colors"
          >
            <XClose className="w-4 h-4 text-gray-500" onClick={handleClose} />
          </button>
        </div>

        {/* Main content */}
        <div>
          {/* Profile Section */}
          <div className="px-6 relative">
            <div className="flex justify-between items-center">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center">
                <div className="w-16 h-16 bg-gray-200 rounded-full"></div>
              </div>
              {/* Tabs */}
              <div className="flex gap-2">
                <ButtonCmp
                  className={`btn-outline-primary ${
                    section === 'overview' && 'bg-primary text-white'
                  }`}
                  onClick={() => setSection('overview')}
                >
                  Overview
                </ButtonCmp>
                <ButtonCmp
                  className={`btn-outline-primary ${
                    section === 'activity' && 'bg-primary text-white'
                  }`}
                  onClick={() => setSection('activity')}
                >
                  Activity
                </ButtonCmp>
                <ButtonCmp
                  className={`btn-outline-primary ${
                    section === 'analytics' && 'bg-primary text-white'
                  }`}
                  onClick={() => setSection('analytics')}
                >
                  Analytics
                </ButtonCmp>
              </div>
            </div>

            <div className="border-b border-gray-300 my-4 left-0">
              <div className="flex items-center gap-2 mb-1 ">
                <h2 className="text-lg font-semibold">Richard</h2>
                <span className="text-gray-500 text-sm flex items-center">
                  <BadgeCmp style="modern" type="success">
                    LTL
                  </BadgeCmp>
                </span>
              </div>
              <p className="text-gray-600 text-sm mb-2">
                I can help you gather and classify rates for your loads
              </p>
            </div>
          </div>

          {/* Stats Section */}
          {section === 'overview' && <Overview />}
          {section === 'activity' && <Activity />}
          {section === 'analytics' && <Analytics />}
        </div>
      </div>
    </CommonModal>
  );
};

export default OverviewRateGathering;
