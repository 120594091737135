import React, { useContext } from 'react';
import TableCmp from 'src/components/TableCmp';

import { OrderContext } from '../..';
import LegDetailsRow from '../../LegDetails/LegDetailsRow';

const LegOverviewTab = () => {
  const { legData } = useContext<any>(OrderContext);

  // const legHeadCells = useMemo(
  //   () => [
  //     {
  //       id: 'lefId',
  //       name: 'Leg ID',
  //     },
  //     {
  //       id: 'carrier',
  //       name: 'Carrier',
  //     },
  //     {
  //       id: 'addresses',
  //       name: 'Addresses',
  //     },
  //     {
  //       id: 'mileage',
  //       name: 'Mileage',
  //     },
  //     {
  //       id: 'estTransitTime',
  //       name: 'Est. Transit Time',
  //     },
  //   ],
  //   []
  // );

  return (
    <div className="p-4 border-b border-utilityGray200 last:border-b-0">
      <div className="overflow-x-auto custom-scrollbar scrollbar-hide -my-3">
        <TableCmp
          tableWrapClass="table-with-spacing table-box table-gray-box"
          // headCells={legHeadCells}
          tableHeaderClass="hidden"
          tableDataArr={legData?.length > 0 ? legData : []}
          TableRowCmp={LegDetailsRow}
          tableFooterRowCmpProps={{}}
          numberOfSkeletonRows={1}
        />
      </div>
    </div>
  );
};

export default LegOverviewTab;
