import {
  ArrowDown,
  MarkerPin01,
  Plus,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';

interface IProps {
  handleClose: any;
}

const LocationHistoryModal = ({ handleClose }: IProps) => (
  <CommonModal
    title={'Location History'}
    titleDesc={'Lorem Ipsum es simplemente el texto de relleno '}
    handleClose={() => handleClose(false)}
    headerIcon={<MarkerPin01 />}
    size={'max-w-[688px]'}
    isOverflow={true}
    modalClassName=""
  >
    <div className="w-full p-5 flex flex-col gap-4">
      <ul>
        <li className="relative flex items-start gap-x-3 pb-4 last:pb-0 overflow-hidden">
          <div className="text-right min-w-[90px] space-y-0.5">
            <p className="text-grayLight600 text-xs font-medium">4 Nov 2023</p>
            <p className="text-gray500 text-[10px] leading-3 font-normal">
              9:42 AM
            </p>
          </div>
          <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[18px] after:absolute flex items-center justify-center after:bg-fgSuccessPrimary">
            <div className="w-6 h-6 rounded-full bg-fgSuccessPrimary flex justify-center items-center">
              <Plus className="w-4 h-4 text-white" />
            </div>
          </div>

          <div className="flex-1 rounded-lg border border-utilityGray200 bg-white shadow-sm px-3 py-2 space-y-1">
            <h6 className="text-grayLight900 text-sm font-medium">
              Bowden{' '}
              <span className="text-gray500 text-[10px]">#WAL-NSBB-34-C</span>
            </h6>
            <p className="text-utilityGray800 text-xs font-normal">
              Confirmation of pick up at noon
            </p>
          </div>
        </li>
        <li className="relative flex items-start gap-x-3 pb-4 last:pb-0 overflow-hidden">
          <div className="text-right min-w-[90px] space-y-0.5">
            <p className="text-grayLight600 text-xs font-medium">15 Feb 2023</p>
            <p className="text-gray500 text-[10px] leading-3 font-normal">
              6:36 PM
            </p>
          </div>
          <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[18px] after:absolute flex items-center justify-center after:bg-fgSuccessPrimary">
            <div className="w-6 h-6 rounded-full bg-fgSuccessPrimary flex justify-center items-center">
              <ArrowDown className="w-4 h-4 text-white" />
            </div>
          </div>

          <div className="flex-1 rounded-lg border border-utilityGray200 bg-white shadow-sm px-3 py-2 space-y-1">
            <h6 className="text-grayLight900 text-sm font-medium">
              Bowden{' '}
              <span className="text-gray500 text-[10px]">#WAL-NSBB-34-C</span>
            </h6>
            <p className="text-utilityGray800 text-xs font-normal">
              Confirmation of pick up at noon
            </p>
          </div>
        </li>
        <li className="relative flex items-start gap-x-3 pb-4 last:pb-0 overflow-hidden">
          <div className="text-right min-w-[90px] space-y-0.5">
            <p className="text-grayLight600 text-xs font-medium">15 Feb 2023</p>
            <p className="text-gray500 text-[10px] leading-3 font-normal">
              6:36 PM
            </p>
          </div>
          <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[18px] after:absolute flex items-center justify-center after:bg-fgSuccessPrimary">
            <div className="w-6 h-6 rounded-full bg-fgSuccessPrimary flex justify-center items-center">
              <ArrowDown className="w-4 h-4 text-white" />
            </div>
          </div>

          <div className="flex-1 rounded-lg border border-utilityGray200 bg-white shadow-sm px-3 py-2 space-y-1">
            <h6 className="text-grayLight900 text-sm font-medium">
              Bowden{' '}
              <span className="text-gray500 text-[10px]">#WAL-NSBB-34-C</span>
            </h6>
            <p className="text-utilityGray800 text-xs font-normal">
              Confirmation of pick up at noon
            </p>
          </div>
        </li>
        <li className="relative flex items-start gap-x-3 pb-4 last:pb-0 overflow-hidden">
          <div className="text-right min-w-[90px] space-y-0.5">
            <p className="text-grayLight600 text-xs font-medium">15 Feb 2023</p>
            <p className="text-gray500 text-[10px] leading-3 font-normal">
              6:36 PM
            </p>
          </div>
          <div className="after:content-[''] after:h-0 after:w-[2px] after:rounded-sm after:top-[18px] after:absolute flex items-center justify-center after:bg-gray400">
            <div className="w-6 h-6 rounded-full bg-gray400 flex justify-center items-center">
              <MarkerPin01 className="w-4 h-4 text-white" />
            </div>
          </div>

          <div className="flex-1 rounded-lg border border-utilityGray200 bg-white shadow-sm px-3 py-2 space-y-1">
            <h6 className="text-grayLight900 text-sm font-medium">
              Bowden{' '}
              <span className="text-gray500 text-[10px]">#WAL-NSBB-34-C</span>
            </h6>
            <p className="text-utilityGray800 text-xs font-normal">
              Confirmation of pick up at noon
            </p>
          </div>
        </li>
      </ul>
    </div>
  </CommonModal>
);

export default LocationHistoryModal;
