/* eslint-disable max-lines-per-function */
import {
  XClose,
  Copy05,
  Edit05,
  DotsVertical,
  RefreshCcw05,
  HelpCircle,
  RefreshCcw04,
  Plus,
  FileDownload03,
  RefreshCcw01,
  DownloadCloud02,
  Mail01,
  Route,
} from '@untitled-ui/icons-react/build/cjs';
import { orderBy, minBy, debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PredictivePriceChart from 'src/app/QuotingHub/PredictivePricing/PredictivePriceChart';
import ReplyRequestModal from 'src/app/QuotingHub/QuotingRequest/MessageDetailSection/ReplyRequestModal';
import outlook from 'src/assets/img/outlook.svg';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import LiveTimer from 'src/components/LiveTimer';
import NotFoundUI from 'src/components/NotFoundUI';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  CARRIERS,
  CURRENCY,
  getBadgeLabelByQuoteStatus,
  getBadgeTypeByQuoteStatus,
  QUOTE_CLASSIFICATION,
  ROSE_ROCKET,
  STATUS,
} from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { AuthContext } from 'src/context/AuthContext';
import { BasicContext } from 'src/context/BasicContext';
import { listCarrier } from 'src/services/CarrierService';
import { getPricingRate } from 'src/services/CommonService';
import { getLoadLinkVehicleSize } from 'src/services/LoadLinkService';
import { getQuoteEmailDetails } from 'src/services/QuoteEmailService';
import {
  createRate,
  getQuote,
  modifyQuote,
  spotQuoteRequest,
  createPDF,
  checkBol,
  cancelQuote,
  sendPDF,
} from 'src/services/QuoteService';
import {
  fetchJsFromCDN,
  getBadgeTypeByMinutes,
} from 'src/utils/CommonFunctions';
import { downloadPdf } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import { scriptId } from '../../AddressDetails/Address.interface';
import AddQuickQuote from '../AddQuickQuote';
import AddQuoteDetail from '../AddQuoteDetail';
import CloseQuoteConfirm from '../CloseQuoteConfirm';
import FinalizeOrderModal from '../FinalizeOrderModal';
import GeneratePdfModal from '../GeneratePdfModal';
import LaneHistory from '../LaneHistory';
import OverviewRateGathering from '../OverviewRateGathering';
import PDF from '../PDF';
import PostToLoadBoard from '../PostToLoadBoard';
import { QuoteType } from '../PostToLoadBoard/LoadBoard.interface';
import MoveToActive from '../PostToLoadBoard/MoveToActive';
import PricingDetails from '../PricingDetails';
import QuotedLoading from '../QuotedLoading';
import QuotedRow from '../QuotedRow';
import ReqQuotedRow from '../ReqQuotedRow';
import ReqQuoteLoading from '../ReqQuoteLoading';
import SendQuoteToCustomerModal from '../SendQuoteToCustomerModal';
import SpotQuoteConfirmation from '../SpotQuoteConfirmation';
import WithQuoteLoading from '../WithQuoteLoading';
import WithRateQuote from '../WithRateQuote';

import ConvertOrderModal from './ConvertOrderModal';
import QuoteDetailTable from './QuoteDetailTable';
import QuoteEmailModal from './QuoteEmailModal';
import RefreshRatesModal from './RefreshRatesModal';

const initAction = {
  mode: '',
  pricing: false,
  model: false,
  pdf: false,
  closedReason: true,
  quoteDetail: false,
  addQuoteDetail: false,
  orderSuccessModel: false,
  pricingDetails: false,
  spotQuoteConfirmation: false,
  carrierOrderModel: false,
};

function containsWholeMatchForEquipmentType(str: string) {
  const strParts = str
    ? str.split(',').map((item) => item.trim().toLowerCase())
    : [];

  return ['van', 'dry van', 'reefer', 'straight truck'].some((item) =>
    strParts.includes(item.toLowerCase())
  );
}

const QuoteDetailPage = () => {
  const { id } = useParams<{ id: any }>();
  const { googleKey } = useContext(BasicContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(true);
  const [predictivePriceData, setPredictivePriceData] = useState<any>([]);
  const [currentTab, setCurrentTab] = useState<QuoteType | boolean>(false);
  const [isRateLoading, setIsRateLoading] = useState(false);
  const [addressDetails, setAddressDetails] = useState<any>({});
  const [dimensions, setDimensions] = useState<any[]>([]);
  const [totalUnit, setTotalUnit] = useState<number | string>();
  const [totalWeight, setTotalWeight] = useState<number | string>();
  const [services, setServices] = useState<any[]>([]);
  const [rates, setRates] = useState<any>({});
  let [carrierRates, setCarrierRates] = useState<any[]>([]);
  const [action, setAction] = useState(initAction);
  const [selectedCarrierRates, setSelectedCarrierRates] = useState<any>([]);
  const [selectedCarrierRatesModel, setSelectedCarrierRatesModel] =
    useState<any>([]);
  const carrierRatesRef = useRef(carrierRates); // Ref to store latest carrier rates
  const [isModalOpen, setModalOpen] = useState(false);
  const [isQuoteModalOpen, setQuoteModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('english');
  const [isExpiredRefresh, setIsExpiredRefresh] = useState(false);
  const [selectedDisplayOptions, setSelectedDisplayOptions] = useState({
    displayCarrierNames: false,
    displayDeliveryTime: false,
  });
  const [orderBookingSuccessToast, setOrderBookingSuccessToast] =
    useState(false);
  const [orderBookingErrorToast, setOrderBookingErrorToast] = useState(false);
  const [quoteEmailDetail, setQuoteEmailDetail] = useState<any>(null);

  const handleLanguageSelect = (language: string) => {
    setSelectedLanguage(language); // Set only one language at a time
  };

  const updateCarrierRates = (newCarrierRates: any) => {
    carrierRatesRef.current = newCarrierRates;
  };

  // const handleNavigation = () => {
  //   navigate(ROUTES.HOME, {
  //     state: { lat: 40.7128, lng: -74.006, zoom: 12 }, // Hidden Params
  //   });
  // };

  const [sortData, setSortData] = useState<{
    sortField: string;
    sortType: 'desc' | 'asc';
  }>({ sortField: 'finalCharge', sortType: 'asc' });

  const [activeQuoteParams, setActiveQuoteParams] = useState<{
    sortField: string;
    sortType: 'desc' | 'asc';
  }>({ sortField: 'finalCharge', sortType: 'asc' });

  const [colors, setColors] = useState<any>({});
  const [isGetRate, setIsGetRate] = useState(false);
  const [quote, setQuote] = useState<any>({});
  const [isFinish, setIsFinish] = useState(false);
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  // const [isCustomerDeleted, setIsCustomerDeleted] = useState(false);
  const [quoteType, setQuoteType] = useState<QuoteType | boolean>(false);
  const [withRateList, setWithRateList] = useState<any[]>([]);
  const [quotedList, setQuotedList] = useState<any[]>([]);
  const [nonQuotedList, setNonQuotedList] = useState<any[]>([]);
  // const [carrierListArr, setCarrierListArr] = useState<any[]>([]);
  const [isAllCarrierRateFetched, setIsAllCarrierRateFetched] =
    useState<boolean>(true);
  const [carrierRateFetchStatus, setCarrierRateFetchStatus] = useState<any>({});
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false);
  const [carrierList, setCarrierList] = useState<any>([]);
  const [allCarriers, setAllCarriers] = useState([]);
  const { currency, setCurrency } = useContext(BasicContext);
  // const [search, setSearch] = useState('');
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [isPdfGenerating, setIsPdfGenerating] = useState<boolean>(false);
  const [isShowActionButtons, setIsShowActionButtons] = useState(false);
  const [selectedQuoteToGeneratePdfId, setSelectedQuoteToGeneratePdfId] =
    useState<any[]>([]);
  // load link
  const [loadLinkPosts, setLoadLinkPosts] = useState<any[]>([]);
  const [loadLinkPostingDetail, setLoadLinkPostingDetail] = useState<
    any | undefined
  >(undefined);
  const [rateGatheringWithAi, setRateGatheringWithAi] = useState<
    any | undefined
  >(undefined);
  const [syncLiveData, setSyncLiveData] = useState<boolean>(false);
  const [isUpdateLoadPost, setIsUpdateLoadPost] = useState<boolean>(false);
  const [isCreateLoadPost, setIsCreateLoadPost] = useState<boolean>(false);
  const [activePostIndex, setActivePostIndex] = useState<number | any>(null);

  const [nonExpiredList, setNonExpiredList] = useState([]);
  const [isShowRefreshRateModal, setIsShowRefreshRateModal] = useState(false);
  const [isShowConvertOrderModal, setIsShowConvertOrderModal] = useState(false);
  const [intervalId, setIntervalId] = useState<any>();
  const [isShowGeneratePdfModal, setIsShowGeneratePdfModal] = useState(false);
  const [isShowCloseReasonModal, setIsShowCloseReasonModal] = useState(false);
  const [isQuoteCloseLoading, setIsQuoteCloseLoading] = useState(false);
  const [isExpiredRateExist, setIsExpiredRateExist] = useState(false);
  const [isAllRateExpired, setIsAllRateExpired] = useState(false);
  const [isShowSendPdfModal, setIsShowSendPdfModal] = useState(false);
  const [isShowQuoteEmailsThreads, setIsShowQuoteEmailsThreads] =
    useState(false);
  const [generatedPdfId, setGeneratedPdfId] = useState<number | any>(null);
  const [generatedPdfName, setGeneratedPdfName] = useState<string | any>('');
  const [generatedPdfUrl, setGeneratedPdfUrl] = useState<string | any>('');
  const [vehicleSizeOptions, setVehicleSizeOptions] = useState<any[]>([]);
  const [openOverviewOfRateGathering, setOpenOverviewOfRateGathering] =
    useState<boolean>(false);
  const sortDataByIsQuoted = (data: any) =>
    data.sort((a: any, b: any) => {
      const aIsQuotedFalse = a?.services.some(
        (service: any) => service.isQuoted === false
      );
      const bIsQuotedFalse = b?.services.some(
        (service: any) => service.isQuoted === false
      );

      return aIsQuotedFalse === bIsQuotedFalse ? 0 : aIsQuotedFalse ? 1 : -1;
    });

  const sortActiveCarrierRates = (
    carrierRatesValue: any,
    isSortTrue = false
  ) => {
    const quotedCarrierRates: any = [];
    const nonExpiredQuotedList: any = [];

    if (carrierRatesValue?.length) {
      carrierRatesValue.forEach((carrierRate: any) => {
        carrierRate.provider = carrierRate.isBanyan
          ? 'Banyan'
          : carrierRate.isFreightcom
          ? 'FrightCom'
          : 'Direct';

        if (
          [STATUS.ACTIVE, STATUS.REQUESTED, STATUS.WON].includes(
            carrierRate.carrierStatus
          ) &&
          carrierRate.carrierStatus === addressDetails.status
        ) {
          quotedCarrierRates.push(carrierRate);
          if (
            moment(carrierRate.expirationDate)
              .add(1, 'days')
              .isSameOrAfter(moment().startOf('day')) &&
            !carrierRate.isBeforeUpdateCarrier
          )
            nonExpiredQuotedList.push(carrierRate);
        }
      });
    }

    if (!isSortTrue) {
      setQuotedList(
        sortDataByIsQuoted(
          orderBy(
            quotedCarrierRates,
            activeQuoteParams.sortField,
            activeQuoteParams.sortType
          )
        )
      );
      setNonExpiredList(
        sortDataByIsQuoted(
          orderBy(
            nonExpiredQuotedList,
            activeQuoteParams.sortField,
            activeQuoteParams.sortType
          )
        )
      );
    } else {
      setQuotedList(
        sortDataByIsQuoted(
          orderBy(
            quotedCarrierRates,
            activeQuoteParams.sortField,
            activeQuoteParams.sortType
          )
        )
      );
      setNonExpiredList(
        sortDataByIsQuoted(
          orderBy(
            nonExpiredQuotedList,
            activeQuoteParams.sortField,
            activeQuoteParams.sortType
          )
        )
      );
    }
  };

  const sortCarrierRates = (carrierRatesValue: any, isSortTrue = false) => {
    const validCarrierRates: any = [];
    const invalidCarrierRates: any = [];
    let openedCarrierRates: any = [];

    if (carrierRatesValue?.length) {
      carrierRatesValue.forEach((carrierRate: any) => {
        carrierRate.provider = carrierRate.isBanyan
          ? 'Banyan'
          : carrierRate.isFreightcom
          ? 'FrightCom'
          : 'Direct';

        if (carrierRate.carrierQuoteId) {
          validCarrierRates.push(carrierRate);

          if (carrierRate.carrierStatus === STATUS.OPENED) {
            openedCarrierRates.push(carrierRate);
          }
        } else {
          invalidCarrierRates.push(carrierRate);
        }
      });
    }
    const lowestCharge: any = minBy(validCarrierRates, 'finalCharge');
    const fastestDelivery: any = minBy(
      validCarrierRates,
      'projectedDeliveryDate'
    );
    const appendCarrierRates = validCarrierRates.map((carrierRate: any) => {
      const tags = [];

      if (carrierRate.finalCharge === lowestCharge.finalCharge) {
        tags.push('Cheapest');
      }

      if (
        carrierRate.projectedDeliveryDate ===
        fastestDelivery.projectedDeliveryDate
      ) {
        tags.push('Fastest');
      }
      carrierRate.tags = tags;

      return carrierRate;
    });
    const sortedCarrierRates = orderBy(
      appendCarrierRates,
      sortData.sortField,
      sortData.sortType
    );
    const allCarrierRates = sortedCarrierRates.concat(invalidCarrierRates);

    updateCarrierRates(allCarrierRates);
    setCarrierRates(allCarrierRates);
    setNonQuotedList(invalidCarrierRates);

    if (!isSortTrue) {
      setWithRateList(
        sortDataByIsQuoted(
          orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
        )
      );
    } else {
      setTimeout(() => {
        setWithRateList(
          sortDataByIsQuoted(
            orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
          )
        );
      }, 400);
    }
  };

  const filterArr: any = [
    {
      value: CURRENCY.CAD,
      name: 'CAD',
      tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
    },
    {
      value: CURRENCY.USD,
      name: 'USD',
      tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
    },
  ];

  const quoteClassificationLTLToolsArr = [
    {
      value: QuoteType.WITH_RATE,
      name: 'Direct Carrier Rates',
    },
    {
      value: QuoteType.NON_QUOTED,
      name: 'Request a Spot Quote',
    },
  ];

  const quoteClassificationFTLToolsArr = [
    {
      value: QuoteType.LOAD_BOARD,
      name: 'Post to Load Board',
    },
    {
      value: QuoteType.PREDICTIVE_PRICING,
      name: 'Predictive Index',
    },
  ];

  const handleAllColors = (quoteStatus: any) => {
    const status = quoteStatus;
    const colorsObj = {
      badgeColor: ' bg-gray-100 ',
      badgeText: ' text-grayText ',
      textColor: ' text-black ',
      bgColor: '',
      borderColor: '',
    };

    switch (status) {
      case STATUS.OPENED:
        colorsObj.badgeColor = ' bg-primary50 ';
        colorsObj.badgeText = ' text-primary ';
        colorsObj.borderColor = ' border-utilityBlue200 ';
        break;
      case STATUS.ACTIVE:
        colorsObj.badgeColor = ' bg-primary50 ';
        colorsObj.badgeText = ' text-primary ';
        colorsObj.borderColor = ' border-utilityBlue200 ';
        break;
      case STATUS.WON:
        colorsObj.badgeColor = ' bg-green100 ';
        colorsObj.badgeText = ' text-green ';
        colorsObj.textColor = ' text-green ';
        colorsObj.bgColor = ' bg-green100 ';
        colorsObj.borderColor = ' border-green ';
        break;
      case STATUS.LOST:
      case STATUS.EXPIRED:
        colorsObj.badgeColor = ' bg-red-100 ';
        colorsObj.badgeText = ' text-danger ';
        colorsObj.borderColor = ' border-danger ';
        break;
      default:
        break;
    }
    setColors(colorsObj);
  };

  const getRate = () => {
    getPricingRate()
      .then((response: any) => {
        setRates(response?.data);
      })
      .catch(() => {
        WalToast.error('Something went wrong while fetching rate.');
        console.error('Pricing rates error');
      });
  };

  useEffect(() => {
    getRate();
  }, []);

  // const handleSearchFilter = () => {
  //   const searchTermLower = search.toLowerCase();
  //   const matchedVal = carrierRates.filter(
  //     (item) =>
  //       item.name.toLowerCase().includes(searchTermLower) ||
  //       (item.provider &&
  //         item.provider.toLowerCase().includes(searchTermLower)) ||
  //       (item.tags &&
  //         item.tags.some((tag: any) =>
  //           tag.toLowerCase().includes(searchTermLower)
  //         )) ||
  //       (item.carrierQuoteId &&
  //         item.carrierQuoteId.toLowerCase().includes(searchTermLower))
  //   );

  //   const validCarrierRates: any = [];
  //   const invalidCarrierRates: any = [];
  //   const quotedCarrierRates: any = [];
  //   const openedCarrierRates: any = [];

  //   if (matchedVal?.length) {
  //     matchedVal.forEach((carrierRate: any) => {
  //       if (carrierRate.carrierQuoteId) {
  //         validCarrierRates.push(carrierRate);

  //         if (carrierRate.carrierStatus === STATUS.OPENED) {
  //           openedCarrierRates.push(carrierRate);
  //         } else if (carrierRate.carrierStatus === addressDetails.status) {
  //           quotedCarrierRates.push(carrierRate);
  //         }
  //       } else {
  //         invalidCarrierRates.push(carrierRate);
  //       }
  //     });
  //   }

  //   setNonQuotedList(invalidCarrierRates);
  //   setWithRateList(
  //     orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
  //   );
  // };

  // useEffect(() => {
  //   handleSearchFilter();
  // }, [search]);

  useEffect(() => {
    if (!loadLinkPostingDetail) {
      setLoadLinkPostingDetail(loadLinkPosts[activePostIndex]);
    }
  }, [isUpdateLoadPost]);

  const fetchQuoteEmailDetails = async (messageId: string) => {
    try {
      const quoteEmails = (await getQuoteEmailDetails({
        messageId: messageId,
      })) as any;
      setQuoteEmailDetail({
        ...quoteEmails?.data,
        emailBody: JSON.parse(quoteEmails?.data?.emailBody),
      });
    } catch (e: any) {
      // WalToast.error(
      //   e?.message ?? e ?? 'Something went wrong while fetching quote emails.'
      // );
    }
  };

  useEffect(() => {
    if (id) {
      localStorage.removeItem('isModifyQuoteFromQuote');
      localStorage.removeItem('isPickupDateChanged');
      localStorage.removeItem('modifyQuoteCode');

      if (isRefresh) {
        getQuote(id)
          .then((response: any) => {
            if (
              response &&
              response.data &&
              response.data.addressDetails &&
              response.data.addressDetails.status !== STATUS.PENDING
            ) {
              const responseData = response.data;

              // setIsCustomerDeleted(
              //   responseData?.addressDetails.isCustomerDeleted
              // );
              setQuote(responseData);

              if (responseData?.addressDetails) {
                setAddressDetails(responseData?.addressDetails);

                if (responseData?.addressDetails?.emailContentId) {
                  fetchQuoteEmailDetails(
                    responseData.addressDetails.emailContentId
                  );
                }
              }

              if (responseData?.rateGatheringWithAi) {
                setRateGatheringWithAi(responseData?.rateGatheringWithAi);
              }

              setLoadLinkPosts(responseData.loadLinkPosts);

              if (responseData.loadLinkPosts.length > 0) {
                setLoadLinkPostingDetail(responseData.loadLinkPosts[0]);
                setActivePostIndex(0);
              } else {
                setIsCreateLoadPost(true);
              }

              if (responseData?.dimensions?.length) {
                setDimensions(responseData.dimensions);
                let unit: number | string = 0;
                let weight = 0;

                responseData.dimensions.forEach((val: any) => {
                  unit += val.handlingUnitNo || 0;
                  weight += val.totalWeight;
                });

                if (
                  responseData.dimensions?.length &&
                  responseData.dimensions[0].handlingUnitName
                ) {
                  const handlingUnitName =
                    responseData.dimensions[0].handlingUnitName;

                  unit = `${unit} ${handlingUnitName}${unit > 1 ? 's' : ''}`;
                }
                setTotalWeight(weight);
                setTotalUnit(unit);
              } else {
                setTotalWeight(responseData?.addressDetails?.totalWeight);
                setTotalUnit(
                  responseData?.addressDetails?.primaryEquipmentTypeFullName ||
                    ''
                );
              }

              if (responseData?.additionalService) {
                setServices(responseData?.additionalService);
              }
              setIsLoading(false);

              if (
                responseData?.carrierRates &&
                responseData?.carrierRates.length
              ) {
                sortCarrierRates(responseData?.carrierRates);
                sortActiveCarrierRates(responseData?.carrierRates);
                setIsLoading(false);
                // responseData?.carrierRates?.forEach((val: any) => {
                //   setCarrierListArr((old) => [
                //     ...old,
                //     {
                //       ...val,
                //       id: val?.carrierId,
                //     },
                //   ]);
                // });
              } else if (
                responseData?.addressDetails?.classification ===
                  QUOTE_CLASSIFICATION.LTL &&
                responseData?.dimensions?.length > 0 &&
                (containsWholeMatchForEquipmentType(
                  responseData?.addressDetails?.primaryEquipmentTypeFullName
                ) ||
                  containsWholeMatchForEquipmentType(
                    responseData?.addressDetails
                      ?.secondaryEquipmentTypeFullNames
                  ))
              ) {
                setIsGetRate(true);
              }

              if (currentTab) {
                setQuoteType(currentTab);
              } else if (
                responseData?.addressDetails?.classification ===
                  QUOTE_CLASSIFICATION.LTL &&
                responseData?.dimensions?.length > 0 &&
                (containsWholeMatchForEquipmentType(
                  responseData?.addressDetails?.primaryEquipmentTypeFullName
                ) ||
                  containsWholeMatchForEquipmentType(
                    responseData?.addressDetails
                      ?.secondaryEquipmentTypeFullNames
                  ))
              ) {
                setQuoteType(QuoteType.WITH_RATE);
              } else {
                if (
                  responseData?.addressDetails?.classification ===
                    QUOTE_CLASSIFICATION.FTL ||
                  responseData?.addressDetails?.classification ===
                    QUOTE_CLASSIFICATION.VOLUME_LTL
                ) {
                  setQuoteType(QuoteType.PREDICTIVE_PRICING);
                } else {
                  setQuoteType(QuoteType.LOAD_BOARD);
                }
              }

              if (
                responseData?.addressDetails?.classification ===
                  QUOTE_CLASSIFICATION.FTL ||
                responseData?.addressDetails?.classification ===
                  QUOTE_CLASSIFICATION.LTL
              ) {
                handleAllColors(responseData?.addressDetails?.status);
              }
            } else {
              navigate(ROUTES.QUOTING_DASHBOARD);
            }
          })
          .finally(() => setIsRefresh(false))
          .catch(console.error);
      }
    } else {
      navigate(ROUTES.QUOTING_DASHBOARD);
    }
  }, [isRefresh]);

  const connectSocket = () => {
    if (!isSocketConnected) {
      fetchJsFromCDN(
        'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
        ['io']
      ).then(([io]: any) => {
        io.sails.url = window.SERVER_URL;
        io.socket.on('connect', function socketConnected() {
          io.socket.get(
            `/subscribe/${currentUser.id}`,
            function (data: any, jwr: any) {
              if (jwr.error) {
                return;
              }
              setIsSocketConnected(true);
            }
          );
        });

        io.socket.on('disconnect', () => {
          setIsSocketConnected(false);
        });

        io.socket.on('user', function (data: any) {
          if (data.type === 'NEW_RATE') {
            if (data?.data && data?.data?.quoteId === parseInt(id)) {
              let newCarrierRates = [...carrierRatesRef.current, data.data];

              sortCarrierRates(newCarrierRates);
              setIsLoading(false);
            }
          } else if (data?.type === 'FINISH_LOADING') {
            carrierRateFetchStatus[data.carrier] = true;
            setCarrierRateFetchStatus((old: any) => ({
              ...old,
              [data.carrier]: true,
            }));

            // setIsFinish(true);
          } else if (data.type === 'ORDER_BOOKING_SUCCESS') {
            let carrierName = 'Carrier';

            if (data?.data && data?.data?.carrierDetails?.name) {
              carrierName = data?.data?.carrierDetails?.name;
            }

            if (!orderBookingSuccessToast) {
              WalToast.success(
                `Your Order with ${carrierName} was booked successfully.`
              );
              setOrderBookingSuccessToast(true);
            }

            setAction((old) => ({ ...old, carrierOrderModel: false }));

            if (data?.data?.quoteId == id) {
              setIsRefresh(true);
            }
          } else if (data.type === 'ORDER_BOOKING_ERROR') {
            if (!orderBookingErrorToast) {
              setAction((old) => ({ ...old, carrierOrderModel: false }));
              WalToast.error(
                data.insufficientBalance
                  ? "Can't book order due to insufficient balance"
                  : "Can't book order, some details missing"
              );
              setOrderBookingErrorToast(true);
            }
          }
        });
      });
    }
  };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
    setSelectedCarrierRates([]);
    setSelectedCarrierRatesModel([]);
  }, []);

  const handleActionType =
    (actionKey: string, mode: string = '', carrierRate: any = null) =>
    () => {
      setModalOpen(true);

      let newCarrierRate: any = {};

      if (carrierRate) {
        if (currency === CURRENCY.USD) {
          newCarrierRate = { ...carrierRate };
          newCarrierRate.additionalCharges = carrierRate.additionalChargesUSD;
          newCarrierRate.finalCharge = carrierRate.finalChargeUSD;
          newCarrierRate.margin = carrierRate.marginUSD;
          newCarrierRate.totalCharge = carrierRate.totalChargeUSD;

          if (newCarrierRate?.services?.length) {
            newCarrierRate.services = newCarrierRate.services.map(
              (item: any) => ({
                ...item,
                value: item.valueUSD,
              })
            );
          }
        } else {
          newCarrierRate = { ...carrierRate };
        }
      }

      if (actionKey === 'model' && mode === 'create') {
        // Set the quote modal to open only when the conditions are met

        setQuoteModalOpen(true);
        setSelectedCarrierRatesModel([newCarrierRate]);
        setAction((old) => ({ ...old, [actionKey]: true, mode }));

        return;
      }

      if (actionKey === 'model' && mode === 'update') {
        // setQuoteModalOpen(true);
        setSelectedCarrierRatesModel([newCarrierRate]);
        setAction((old) => ({ ...old, [actionKey]: true, mode }));

        return;
      }

      if (carrierRate) {
        setSelectedCarrierRates([newCarrierRate]);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleSubmit = () => {
    navigate(ROUTES.QUOTING_DASHBOARD);
  };

  useEffect(() => {
    setSelectedCarrierRates([]);
  }, [currency]);

  useEffect(() => {
    if (isGetRate) {
      listCarrier({
        search: '',
        sortDirection: 'DESC',
        sortBy: 'quoteCount',
        page: 1,
        limit: 100,
        type: 'connection',
      })
        .then((response: any) => {
          let carriers = response?.data || [];

          if (carriers?.length) {
            connectSocket();
            setIsFinish(false);
            setIsAllCarrierRateFetched(false);
            updateCarrierRates([]);
            setCarrierRates([]);
            setWithRateList([]);
            setNonQuotedList([]);
            setAllCarriers(carriers);

            if (isExpiredRefresh) {
              setWithRateList((old) =>
                old.filter((data) => {
                  if (data.expirationDate) {
                    return new Date(data.expirationDate) > new Date(); // Keep if not expired
                  } else {
                    return new Date(data.pickupDate) > new Date(); // Keep if not expired
                  }
                })
              );
              let expiredCarrierArr: any = [];

              withRateList.map((data) => {
                let slug = data.isBanyan
                  ? CARRIERS.BANYAN.SLUG
                  : data.isFreightcom
                  ? CARRIERS.FREIGHTCOM.SLUG
                  : data.slug;

                if (data.expirationDate) {
                  if (new Date(data.expirationDate) <= new Date())
                    expiredCarrierArr.push(slug);
                } else {
                  if (new Date(data.pickupDate) <= new Date())
                    expiredCarrierArr.push(slug);
                }
              });
              let expiredCarrierList = carriers.filter((data: any) =>
                expiredCarrierArr.includes(data.slug)
              );

              setCarrierList(expiredCarrierList || []);
            } else {
              setWithRateList([]);
              setCarrierList(carriers || []);
            }
          }
        })
        .finally(() => {
          // setIsLoading(false);
          // setIsRefresh(false);
        })
        .catch(console.log);
    } else {
      setIsFinish(true);
    }
  }, [isGetRate]);

  useEffect(() => {
    if (
      addressDetails?.classification === QUOTE_CLASSIFICATION.FTL ||
      addressDetails?.classification === QUOTE_CLASSIFICATION.VOLUME_LTL
    ) {
      setQuoteType(QuoteType.PREDICTIVE_PRICING);
    }

    if (carrierRates?.length && !isExpiredRefresh) {
      sortCarrierRates(carrierRates, true);
    }
  }, [sortData, addressDetails]);

  useEffect(() => {
    if (carrierRates?.length) {
      sortActiveCarrierRates(carrierRates, true);
    }
  }, [activeQuoteParams, addressDetails]);

  const modifyQuoteStatus = (quoteStatusId: number) => () => {
    modifyQuote({ quoteId: quoteStatusId })
      .then((response) => {
        if (response && response.data) {
          const newQuoteId = response.data?.id;
          const isModifyQuoteFromQuote: any = quoteStatusId;
          localStorage.setItem(
            'isModifyQuoteFromQuote',
            isModifyQuoteFromQuote
          );
          localStorage.setItem('modifyQuoteCode', addressDetails?.code);

          if (response.data?.isDateChange) {
            localStorage.setItem(
              'isPickupDateChanged',
              response.data?.isDateChange
            );
          }

          navigate(`${PATH.ADDRESS_DETAILS}/${newQuoteId}`);
          // if (
          //   isCustomerDeleted ||
          //   response.data?.classification === QUOTE_CLASSIFICATION.FTL
          // ) {
          //   navigate(`${PATH.ADDRESS_DETAILS}/${newQuoteId}`);
          // } else {
          //   navigate(`${PATH.DIMENSIONS}/${newQuoteId}`);
          // }
        }
      })
      .catch(() => WalToast.error('Quote has not been modified', ''));
  };

  const handleQuoteClose = (closeReasonPayload: any) => {
    setIsShowCloseReasonModal(false);
    setIsQuoteCloseLoading(true);
    cancelQuote(closeReasonPayload, id)
      .then((response: any) => {
        if (response && response.data) {
          WalToast.success('Order Closed Successfully');
          setIsShowCloseReasonModal(false);
          setIsRefresh(true);
        }
      })
      .finally(() => {
        setIsQuoteCloseLoading(false);
      })
      .catch((error: any) => {
        WalToast.error(
          error?.response?.data?.message || 'Something went wrong',
          ''
        );
      });
  };

  const ReqQuoteHeadCells = useMemo(
    () => [
      {
        id: 'Name',
        name: 'Carrier',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'DeliveryTime',
        name: 'Delivery Time',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'ProjectedDelivery',
        name: 'Projected Delivery',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'BaseQuote',
        name: 'Base Quote',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'AdditionalServices',
        name: 'Additional Services',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'Total',
        name: 'Total',
        sortable: false,
        rowClassName: '',
      },
      ...([STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status)
        ? [
            {
              id: 'action',
              name: '',
            },
          ]
        : []),
    ],
    [allChecked, selectedCarrierRates, addressDetails]
  );

  useEffect(() => {
    setSelectedCarrierRates([]);
  }, [quoteType]);

  useEffect(() => {
    // if (search === '') {
    let isAnyExpired = false;
    let isAllExpired = true; // Assume all are expired until proven otherwise

    for (const data of withRateList) {
      const expirationDate = data.expirationDate
        ? moment(data.expirationDate).add(1, 'days')
        : moment(data.pickupDate).add(1, 'days');

      const isExpired = expirationDate.isBefore(moment().startOf('day'));

      if (!isExpired) {
        isAllExpired = false; // Found a non-expired rate
      } else {
        isAnyExpired = true; // Mark that at least one expired rate exists
      }

      if (!isAllExpired && isAnyExpired) {
        break; // No need to continue once both conditions are met
      }
    }

    setIsExpiredRateExist(isAnyExpired);
    setIsAllRateExpired(isAllExpired);
    // }
  }, [withRateList]);

  const handleExpiredRefreshRates = () => {
    let isAnyExpired = withRateList.some((data) => {
      if (data.expirationDate) {
        return moment(data.expirationDate)
          .add(1, 'days')
          .isBefore(moment().startOf('day')); // Expired
      } else {
        return moment(data.pickupDate)
          .add(1, 'days')
          .isBefore(moment().startOf('day')); // Expired
      }
    });

    if (isAnyExpired) {
      if (new Date(addressDetails.pickupDate) < new Date()) {
        setIsShowRefreshRateModal(true);
      } else {
        setIsGetRate(true);
      }
      setIsExpiredRefresh(true);
    } else {
      WalToast.error('No Expired rates found');
    }
  };

  const tooltipItems = (
    <div className="">
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <Edit05 className="w-4 h-4 text-gray500" />
        Modify quote
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <RefreshCcw05 className="w-4 h-4 text-gray500" />
        Rollover
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <XClose className="w-4 h-4 text-gray500" />
        No quote
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center">
        <HelpCircle className="w-4 h-4 text-gray500" />
        Request help
      </div>
    </div>
  );

  const WithRatesHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Carrier',
        sortable: true,
      },
      {
        id: 'provider',
        name: 'Provider',
        sortable: true,
      },
      {
        id: 'expiry',
        name: 'Expiry',
      },
      {
        id: 'transitTime',
        name: 'Transit Time',
      },
      {
        id: 'projectedDeliveryDate',
        name: 'Est. Delivery Date',
        sortable: true,
      },
      {
        id: 'totalCharge',
        name: 'Base Quote',
        sortable: true,
      },
      {
        id: 'additionalChargesUSD',
        name: 'Additional Services',
        sortable: true,
      },
      {
        id: 'finalCharge',
        name: 'Total Cost',
        sortable: true,
      },
      {
        id: 'action',
        visible:
          isLoading ||
          [STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status),
      },
    ],
    [addressDetails, isLoading]
  );

  const QuotedHeadCells = useMemo(() => {
    const baseHeadCells = [
      {
        id: 'name',
        name: 'Carrier',
        sortable: true,
        rowClassName: '',
        visible: true,
      },
      {
        id: 'provider',
        name: 'Provider',
        sortable: true,
        rowClassName: '',
        visible: true,
      },
      {
        id: 'expiry',
        name: 'Expiry',
        visible: true,
      },
      {
        id: 'transittime',
        name: 'Transit Time',
        visible: true,
      },
      {
        id: 'projectedDeliveryDate',
        name: 'Est. Delivery Date',
        sortable: true,
        visible: true,
      },
      {
        id: `${currency === CURRENCY.CAD ? 'totalCostCAD' : 'totalCostUSD'}`,
        name: 'Total Cost',
        sortable: true,
        visible: true,
      },
      {
        id: 'margin',
        name: 'Total Margin',
        sortable: true,
        visible: true,
      },
      {
        id: 'finalCharge',
        name: 'Total Sell Price',
        sortable: true,
        visible: true,
      },
      {
        id: 'action',
        visible:
          isLoading ||
          [STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status),
      },
    ];

    return baseHeadCells;
  }, [quotedList, isLoading, addressDetails]);

  const tabOptionsArr = [
    {
      value: QuoteType.QUOTED,
      label: 'Quoted',
    },
    {
      value: QuoteType.WITH_RATE,
      label: 'Direct Carrier Rates',
    },
    {
      value: QuoteType.NON_QUOTED,
      label: 'Request a Spot Quote',
    },
  ];

  const requestSpotQuote = (reqData: any) => {
    const quoteIds = selectedCarrierRates.map((item: any) => item.id);
    const data: any = {
      ...reqData,
      ids: quoteIds,
    };
    setSubmitLoading(true);
    spotQuoteRequest(data)
      .then(() => {
        setSubmitLoading(false);
        setSelectedCarrierRates([]);
        setAllChecked(false);
        setAction(initAction);

        if (reqData?.isDoNotAskChecked) {
          setCurrentUser({
            ...currentUser,
            doNotAskSpotQuote: reqData?.isDoNotAskChecked,
          });
        }
        setIsRefresh(true);
        WalToast.success('Spot quote request sent successfully', '');
      })
      .finally(() => setIsLoading(false))
      .catch(() => {
        setSelectedCarrierRates([]);
        setSubmitLoading(false);
        setAction(initAction);
      });
  };

  const handleModalClose = useCallback(
    (status: boolean, modalName?: any, reqData?: any) => () => {
      if (modalName) {
        setAction({ ...initAction, [modalName]: status });
      }

      if (!status) {
        setAction(initAction);
      }

      if (action.spotQuoteConfirmation) {
        setSelectedCarrierRates([]);

        if (status) {
          requestSpotQuote(reqData);
        }
      }

      if (action.orderSuccessModel) {
        setIsRefresh(true);
      } else if (action.addQuoteDetail) {
        setSelectedCarrierRates([]);
      }
    },
    [action]
  );

  const handleQuoteClick = (carrierRate: any) => {
    if (
      (carrierRate?.isSpotRequestSent || !carrierRate?.spotQuoteEmail) &&
      quoteType === QuoteType.NON_QUOTED
    ) {
      return;
    }

    if (![STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status)) {
      return;
    }

    if (quoteType === QuoteType.NON_QUOTED) {
      handleActionType('spotQuoteConfirmation', 'view')();
    }

    if (quoteType === QuoteType.WITH_RATE) {
      const expDate = new Date(carrierRate.expirationDate);
      const today = new Date();
      expDate.setDate(expDate.getDate() + 1);

      const diffTime = expDate.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays < 0) {
        return;
      }

      handleActionType('model', 'create', carrierRate)();
    }

    let newCarrierRate: any = {};

    if (currency === CURRENCY.USD) {
      newCarrierRate = { ...carrierRate };
      newCarrierRate.additionalCharges = carrierRate.additionalChargesUSD;
      newCarrierRate.finalCharge = carrierRate.finalChargeUSD;
      newCarrierRate.margin = carrierRate.marginUSD;
      newCarrierRate.totalCharge = carrierRate.totalChargeUSD;

      if (newCarrierRate?.services?.length) {
        newCarrierRate.services = newCarrierRate.services.map((item: any) => ({
          ...item,
          value: item.valueUSD,
        }));
      }
    } else {
      newCarrierRate = { ...carrierRate };
    }

    if (
      selectedCarrierRates.filter((rate: any) => rate.id === carrierRate.id)
        .length
    ) {
      newCarrierRate = {};
      setSelectedCarrierRates((old: any) =>
        old.filter((item: any) => item.id !== carrierRate.id)
      );
    } else {
      setSelectedCarrierRates((old: any) => [...old, newCarrierRate]);
    }
  };

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  useEffect(() => {
    if (isSocketConnected && carrierList?.length && isGetRate) {
      setIsRateLoading(true);

      const ratePromises = carrierList.map((element: any) => {
        setCarrierRateFetchStatus((old: any) => ({
          ...old,
          [element.slug]: false,
        }));
        let formData = quote;
        formData.carrierId = element.id;
        formData.pickupDate = addressDetails.pickupDate;

        return createRate(formData).catch((error: any) => {
          console.error(error);
          WalToast.error(
            error?.response?.data?.message ||
              'Carrier rates have not been created',
            ''
          );
        });
      });

      Promise.all(ratePromises)
        .then(() => {
          setIsRateLoading(false);
        })
        .catch((error) => {
          console.error('Error processing rates:', error);
          setIsRateLoading(false);
        });
    }
  }, [isSocketConnected, carrierList]);

  useEffect(() => {
    let allFetched: boolean = true;

    for (const key in carrierRateFetchStatus) {
      if (!carrierRateFetchStatus[key]) {
        allFetched = false;
      }
    }
    setIsAllCarrierRateFetched(allFetched);
  }, [carrierRateFetchStatus]);

  useEffect(() => {
    if (isAllCarrierRateFetched) {
      setCarrierList([]);
      setIsFinish(true);
      setIsExpiredRefresh(false);
      setIsGetRate(false);
      setCarrierRateFetchStatus({});
    }
  }, [isAllCarrierRateFetched]);

  const fetchBol = () => {
    if (addressDetails.status === STATUS.WON && !addressDetails.bolUrl) {
      checkBol(id)
        .then((response: any) => {
          setAddressDetails({ ...addressDetails, ...response?.data });

          // Clear the interval if the condition is met
          if (response?.data?.bolUrl) {
            clearInterval(intervalId);
            setIntervalId(null);
          }
        })
        .catch((error) => {
          console.error('Error processing rates:', error);
        });
    }
  };

  const getVehicleSizeOptions = () => {
    getLoadLinkVehicleSize()
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleSizeOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        console.log('Vehicle Size Error', e);
      });
  };

  useEffect(() => {
    getVehicleSizeOptions();
  }, []);

  // Start the interval in a useEffect hook and clear it when component unmounts
  useEffect(() => {
    let intervalIdd: any = '';

    if (addressDetails.status === STATUS.WON && !addressDetails.bolUrl) {
      intervalIdd = setInterval(() => {
        if (addressDetails.status === STATUS.WON && !addressDetails.bolUrl) {
          fetchBol();
        }
      }, 180000);
      setIntervalId(intervalIdd);
    }

    // Clear interval on component unmount or when addressDetails change
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    };
  }, [addressDetails.status, addressDetails.bolUrl]);

  const rightSideContent = (
    <div className="flex sms:flex-nowrap flex-wrap sm:justify-end justify-start items-center lg:gap-x-4 sm:gap-x-3 gap-x-2 mdm:w-auto md:w-full whitespace-nowrap">
      {/* <TabButton
        tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 "
        parentClassName="w-full currency-tab quoting-currency"
        childrenClassName="test"
        activeClassName="text-grayLight900 bg-utilityGray100"
        className=""
        isTooltip={true}
        tabArray={filterArr}
        handleOnClick={(e: any) => {
          setCurrency(e.target.dataset.value);
        }}
        isActive={currency}
      /> */}
      <div className="!hidden">
        {!isLoading && (
          <>
            <TooltipCmp
              message={tooltipItems}
              type="light"
              openOnClick
              isArrow={false}
            >
              <ButtonCmp
                type="submit"
                className="btn_secondary_black lg:!p-[9px] !p-2"
              >
                <DotsVertical className="w-4 h-4" />
              </ButtonCmp>
            </TooltipCmp>
          </>
        )}
      </div>
      {addressDetails?.emailContentId ? (
        <ButtonCmp
          className="btn-outline-primary"
          onClick={() => setIsShowQuoteEmailsThreads(!isShowActionButtons)}
          icon={<Mail01 className="w-4 h-4" />}
        >
          Open Thread
        </ButtonCmp>
      ) : (
        ''
      )}

      <OutsideClickHandler
        onOutsideClick={() => setIsShowActionButtons(false)}
        containerClassName="relative"
      >
        {[STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status) && (
          <ButtonCmp
            className="btn-outline-primary"
            onClick={() => setIsShowActionButtons(!isShowActionButtons)}
          >
            Quote Actions
          </ButtonCmp>
        )}
        {[STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status) && (
          <ul
            className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[140px] right-0 absolute ${
              isShowActionButtons ? '' : 'hidden'
            } `}
          >
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
              onClick={modifyQuoteStatus(addressDetails.id)}
            >
              Modify Quote
            </li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
              onClick={() => setIsShowCloseReasonModal(true)}
            >
              No Quote
            </li>
          </ul>
        )}
      </OutsideClickHandler>
      {addressDetails.status === STATUS.REQUESTED &&
        addressDetails?.isRequestedByCustomer && (
          <ButtonCmp
            className="btn_secondary_black lg:!p-[9px] !p-2"
            onClick={() => setIsShowConvertOrderModal(true)}
          >
            Move To Booked
          </ButtonCmp>
        )}

      {!!addressDetails.isOrderBookingInProgress && (
        <ButtonCmp
          className="btn-outline-primary text-grayLight600"
          btnSpinnerClass="text-primary"
          loading
        >
          Processing Order Booking
        </ButtonCmp>
      )}
      {addressDetails.isBookOrderEnable &&
        [STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status) && (
          <TooltipCmp
            parentClassName="max-w-[200px]"
            message={
              addressDetails.status !== STATUS.ACTIVE
                ? 'Add at least 1 active quote to convert this quote to an order'
                : ''
            }
          >
            <ButtonCmp
              className="btn-outline-primary"
              disabled={
                addressDetails?.status !== STATUS.ACTIVE ||
                addressDetails?.isOrderBookingInProgress ||
                ![STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails?.status)
              }
              onClick={() => setIsShowConvertOrderModal(true)}
            >
              Convert to Order
            </ButtonCmp>
          </TooltipCmp>
        )}

      {addressDetails.status === STATUS.WON && (
        <ButtonCmp
          className="btn_secondary_black"
          onClick={() =>
            window.open(ROSE_ROCKET.QUOTE_URL + addressDetails.rossRocketId)
          }
        >
          View Order
        </ButtonCmp>
      )}

      {addressDetails.status === STATUS.WON && (
        <ButtonCmp
          className="btn_secondary_black"
          onClick={() => {
            if (addressDetails.bolUrl) {
              downloadPdf(
                addressDetails.bolUrl,
                `${addressDetails.code}-BOL.${
                  addressDetails.bolUrl.split('.').reverse()[0]
                }`
              );
            }
          }}
          disabled={!addressDetails.bolUrl}
          loading={!addressDetails.bolUrl}
        >
          {addressDetails.bolUrl ? (
            <>
              <DownloadCloud02 className="w-4 h-4" />
              Download BOL
            </>
          ) : (
            'Fetching BOL'
          )}
        </ButtonCmp>
      )}

      <ButtonCmp
        className="btn_secondary_black lg:!px-[9px] !px-2"
        onClick={handleSubmit}
      >
        <XClose className="w-4 h-4" />
      </ButtonCmp>
    </div>
  );

  const ActiveArray: any = [
    {
      value: 'active',
      name: 'Active Quotes',
    },
  ];

  const handleQuoteModalClose = () => {
    setQuoteModalOpen(false);
  };

  const handleGeneratePdf = () => {
    setIsPdfGenerating(true);
    createPDF(id, {
      selectedOption: selectedDisplayOptions,
      quoteCarrierId: selectedQuoteToGeneratePdfId,
      pdfLanguage: selectedLanguage,
      isPdf: false,
      currency: currency,
    })
      .then((response: any) => {
        // setIsRefresh(true);
        const { data } = response;
        setIsPdfGenerating(false);
        setIsShowGeneratePdfModal(false);
        setIsShowSendPdfModal(true);
        setGeneratedPdfId(data?.id);
        setGeneratedPdfName(data.name);
        setGeneratedPdfUrl(`${data?.url}${data?.file}`);
        setAddressDetails((prevState: any) => ({
          ...prevState,
          quotePdf: (addressDetails.quotePdf || 0) + 1,
        }));
        setSelectedLanguage('english');
        setSelectedDisplayOptions({
          displayCarrierNames: false,
          displayDeliveryTime: false,
        });
        setSelectedQuoteToGeneratePdfId([]);

        // if (data) {
        // const url = `${data.url}${data.file}`;

        // setTimeout(() => {
        //   downloadPdf(url, `${data.name} - ${data.file}`);
        // }, 500);
        // }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsPdfGenerating(false);
        WalToast.error('Failed to generate quote pdf', '');
      });
  };

  const [isPostCreateLoading, setIsPostCreateLoading] = useState(false);
  const [validatePostToLoadBoardForm, setValidatePostToLoadBoardForm] =
    useState(false);

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap&loading=async`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  const handleNavigation = () => {
    const newTab = window.open(ROUTES.HOME, '_blank');

    if (newTab) {
      newTab.onload = () => {
        newTab.history.replaceState(
          {
            from: predictivePriceData?.from,
            to: predictivePriceData?.to,
            fromAddressObj: predictivePriceData?.fromFullAddress,
            toAddressObj: predictivePriceData?.toFullAddress,
          },
          ''
        );
      };
    }
  };

  const handleSendQuoteToCustomer = (data: any) => {
    if (generatedPdfId) {
      sendPDF(generatedPdfId, {
        sentEmailTo: data.to,
        emailContent: data.emailBody,
        attachments: data.attachments,
        emailCc: data?.cc ?? [],
      })
        .then(() => {
          setIsShowSendPdfModal(false);
          WalToast.success('Quote sent to customer successfully', '');
        })
        .catch(() => {
          WalToast.error('Failed to send quote to customer', '');
        });
    }
  };

  const handleRateGathering = () => {
    setIsGetRate(true);
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title={`Quote: #${addressDetails.code ?? ''}`}
            leftContent={
              <Copy05
                className="w-5 h-5 text-primary cursor-pointer font-bold"
                onClick={() => copyDebounce(addressDetails.code ?? '')}
              />
            }
            countBadge={
              <>
                {isLoading ? (
                  <div className="flex h-6 w-20 border justify-center items-center border-gray300 rounded-lg gap-1 custom-loading">
                    <span className="rounded-full p-1 bg-warning500"></span>
                    <span className="text-xs">To Quote </span>
                  </div>
                ) : (
                  <BadgeCmp
                    style="modern"
                    type={getBadgeTypeByQuoteStatus(addressDetails.status)}
                  >
                    {getBadgeLabelByQuoteStatus(addressDetails.status)}
                  </BadgeCmp>
                )}
                {addressDetails.classification === 'ltl' && (
                  <BadgeCmp style="modern" type="success">
                    LTL
                  </BadgeCmp>
                )}
                {addressDetails.classification === 'ftl' && (
                  <BadgeCmp style="modern" type="lightblue">
                    FTL
                  </BadgeCmp>
                )}
                {addressDetails.classification === 'volume_ltl' && (
                  <BadgeCmp style="modern" type="pink">
                    Volume LTL
                  </BadgeCmp>
                )}
                {quotedList.length <= 0 && !isLoading && (
                  <BadgeCmp
                    style="modern"
                    type={
                      getBadgeTypeByMinutes(addressDetails?.createdAt)?.type
                    }
                    mainClassName="rounded-md"
                  >
                    <LiveTimer startDate={addressDetails?.createdAt} />
                  </BadgeCmp>
                )}
              </>
            }
            desc="View carrier rates and create a quote for your shipment."
            rightSideContent={rightSideContent}
            labelClassName={`${colors.badgeColor} ${colors.badgeText} ${colors.borderColor}`}
            labelTextColor={`${colors.badgeText}`}
            mainContainerClassName="xls:!flex-nowrap !flex-wrap mdm:!flex-row md:!flex-col sm:!flex-row flex-col mdm:!items-center !items-start"
            rightClassName="mdm:min-w-fit md:min-w-full sm:min-w-fit min-w-full !w-fit"
          />
        }
      >
        <QuoteDetailTable
          dimensions={dimensions}
          sortActiveCarrierRates={sortActiveCarrierRates}
          services={services}
          isLoading={isLoading}
          setIsGetRate={setIsGetRate}
          handleActionType={handleActionType}
          handleRateGathering={handleRateGathering}
          addressDetails={addressDetails}
          setAddressDetails={setAddressDetails}
          totalWeight={totalWeight}
          totalUnit={totalUnit}
        />

        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="w-full py-3 px-5 flex mdm:flex-row flex-col flex-wrap mdm:justify-between mdm:items-center gap-3">
            <TabButton
              className=""
              tabArray={ActiveArray}
              isActive={'active'}
              activeClassName="!bg-utilityGray100"
            />

            <div className="gap-3 flex flex-wrap whitespace-nowrap">
              {addressDetails?.quotePdf > 0 && (
                <TooltipCmp message={'View PDF History'}>
                  <ButtonCmp
                    type="submit"
                    disabled={selectedCarrierRates.length <= -1}
                    className="btn_secondary_black lg:px-[9px] px-2"
                    onClick={handleActionType('pdf')}
                  >
                    <FileDownload03 className="w-4 h-4" />
                  </ButtonCmp>
                </TooltipCmp>
              )}
              <TooltipCmp
                message={
                  quotedList?.length <= 0
                    ? 'Add at least 1 active quote to quote customer'
                    : ''
                }
              >
                <ButtonCmp
                  className="btn-outline-primary"
                  onClick={() => setIsShowGeneratePdfModal(true)}
                  disabled={quotedList?.length <= 0}
                >
                  <img src={outlook} className="w-4 h-4 object-contain" />
                  Quote Customer
                </ButtonCmp>
              </TooltipCmp>

              <ButtonCmp
                type="button"
                className="btn-outline-primary"
                onClick={handleActionType(
                  'model',
                  'Quick_quote',
                  addressDetails
                )}
                icon={<Plus className="w-4 h-4" />}
                disabled={
                  ![STATUS.ACTIVE, STATUS.OPENED].includes(
                    addressDetails?.status
                  )
                }
              >
                Add Quote
              </ButtonCmp>
            </div>
          </div>

          <div className="w-full border-t border-gray100 flex-1 flex flex-col">
            {(quotedList.length > 0 || isLoading) && (
              <div className="overflow-x-auto custom-scrollbar custom-scrollbar-v2 custom-vertical-scrollbar-hide flex flex-col justify-between table-without-pagination">
                <TableCmp
                  headCells={QuotedHeadCells}
                  tableDataArr={quotedList}
                  TableLoaderRowCmp={QuotedLoading}
                  TableRowCmp={QuotedRow}
                  params={activeQuoteParams}
                  setParams={setActiveQuoteParams}
                  tableRowCmpProps={{
                    handleActionType: handleActionType,
                    currency: currency,
                    addressDetails: addressDetails,
                    isFinish: isFinish,
                    setIsRefresh: setIsRefresh,
                    setQuotedList: setQuotedList,
                  }}
                  getRowClass={(data: any) => {
                    let isExpiredRate = false;
                    const expDate = new Date(data.expirationDate);
                    const today = new Date();
                    expDate.setDate(expDate.getDate() + 1);

                    const diffTime = expDate.getTime() - today.getTime();
                    const diffDays = Math.ceil(
                      diffTime / (1000 * 60 * 60 * 24)
                    );

                    if (diffDays < 0) {
                      isExpiredRate = true;
                    }

                    let rowClass =
                      data.carrierStatus === STATUS.WON ||
                      data.carrierStatus === STATUS.REQUESTED
                        ? '!bg-green20'
                        : '';

                    return isExpiredRate
                      ? `bg-utilityGray100 hover:bg-utilityGray200 ${rowClass}`
                      : rowClass;
                  }}
                  isTableDataLoading={isLoading}
                  numberOfSkeletonRows={3}
                  isTableRowClickable={false}
                />
              </div>
            )}
            {quotedList.length <= 0 && !isLoading && (
              <NotFoundUI
                iconClassName="hidden"
                contentClassName="before:!bg-none"
                title=""
                // title={
                //   <>
                //     <BadgeCmp
                //       style="pill"
                //       type={
                //         getBadgeTypeByMinutes(addressDetails?.createdAt)?.type
                //       }
                //       mainClassName="rounded-md"
                //     >
                //       <div
                //         className={`mx-1 h-1.5 w-1.5 rounded-full bg-current`}
                //       ></div>
                //       <LiveTimer startDate={addressDetails?.createdAt} />
                //     </BadgeCmp>
                //     {/* <div>No Active Quotes</div> */}
                //   </>
                // }
                desc="Push a carrier rate below or add a quick quote to quote your customer and get a proof of quote."
                containerClassName=" !h-auto !my-[30px]"
              />
            )}
          </div>
        </div>
        {action.model &&
          (action.mode === 'Quick_quote' || action.mode === 'update') && (
            <AddQuickQuote
              quoteId={id}
              action={action}
              currency={currency}
              selectedCarrierId={selectedCarrierRatesModel?.[0]?.id}
              serviceList={services}
              selectedCarrierRates={selectedCarrierRatesModel}
              handleClose={handleDrawerClose}
              carrierListArr={allCarriers}
              setIsRefresh={setIsRefresh}
            />
          )}
        {isShowQuoteEmailsThreads ? (
          <QuoteEmailModal
            handleClose={() => setIsShowQuoteEmailsThreads(false)}
            setQuoteEmailDetail={setQuoteEmailDetail}
            data={quoteEmailDetail}
            quoteStatus={addressDetails?.status}
          />
        ) : (
          ''
        )}
        <div
          className={`w-full bg-white rounded-xl shadow border border-utilityGray200 flex flex-col`}
        >
          <div className="table-bottom-header table-tab-wrap border-b border-utilityGray200 items-center">
            <div className="table-header-bottom-left">
              <TabButton
                className="table-tab max-xxl:!px-3"
                activeClassName="!bg-utilityGray100"
                tabArray={
                  addressDetails?.classification === QUOTE_CLASSIFICATION.LTL &&
                  dimensions?.length > 0 &&
                  (containsWholeMatchForEquipmentType(
                    addressDetails?.primaryEquipmentTypeFullName
                  ) ||
                    containsWholeMatchForEquipmentType(
                      addressDetails?.secondaryEquipmentTypeFullNames
                    ))
                    ? [
                        ...quoteClassificationLTLToolsArr,
                        ...quoteClassificationFTLToolsArr,
                      ]
                    : quoteClassificationFTLToolsArr
                }
                parentClassName={`table-tabs ${
                  isLoading && !quoteType ? 'custom-loading' : ''
                }`}
                isActive={quoteType}
                handleOnClick={(e: any) => {
                  setQuoteType(e?.target?.dataset?.value);
                }}
              />
            </div>

            {quoteType === QuoteType.LOAD_BOARD &&
              ((isCreateLoadPost && !loadLinkPostingDetail) ||
                (isUpdateLoadPost && loadLinkPostingDetail)) && (
                <>
                  <ButtonCmp
                    className={`btn-outline-primary hidden ${
                      vehicleSizeOptions.length == 0 ? 'custom-loading' : ''
                    }`}
                    loading={isPostCreateLoading}
                    disabled={
                      isPostCreateLoading || vehicleSizeOptions.length == 0
                    }
                    onClick={() => setOpenOverviewOfRateGathering(true)}
                  >
                    Overview Modal
                  </ButtonCmp>
                  <ButtonCmp
                    className={`btn-outline-primary ${
                      vehicleSizeOptions.length == 0 ? 'custom-loading' : ''
                    }`}
                    loading={isPostCreateLoading}
                    disabled={
                      isPostCreateLoading || vehicleSizeOptions.length == 0
                    }
                    onClick={() => setValidatePostToLoadBoardForm(true)}
                  >
                    Post to Load Board
                  </ButtonCmp>
                  {(isCreateLoadPost || isUpdateLoadPost) &&
                    loadLinkPosts.length > 0 && (
                      <ButtonCmp
                        className="btn_secondary_black"
                        disabled={isPostCreateLoading}
                        onClick={() => {
                          if (isCreateLoadPost) {
                            setIsCreateLoadPost(false);
                          } else {
                            setIsUpdateLoadPost(false);
                          }

                          if (loadLinkPosts.length > 0) {
                            setLoadLinkPostingDetail(loadLinkPosts[0]);
                            setActivePostIndex(0);
                          }
                        }}
                      >
                        Cancel
                      </ButtonCmp>
                    )}
                </>
              )}
            {quoteType === QuoteType.PREDICTIVE_PRICING && (
              <>
                <ButtonCmp
                  onClick={handleNavigation}
                  className="btn-outline-primary !bg-transparent"
                >
                  <span className="flex text-xs font-semibold gap-1">
                    <Route className="w-4 h-4" /> Open in Map-It
                  </span>
                </ButtonCmp>
              </>
            )}
            {quoteType !== QuoteType.LOAD_BOARD &&
              quoteType !== QuoteType.PREDICTIVE_PRICING && (
                <>
                  {/* <InputText
                    inputName="searchQuote"
                    placeholder="Search"
                    className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                    icon={
                      <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                    }
                    value={search}
                    inputType="text"
                    isClearable
                    onChangeFunc={(e: any) => {
                      setSearch(e.target.value);
                    }}
                    parentClassName="table-searchInput"
                    isLoading={isLoading && !quoteType}
                  /> */}
                  <TabButton
                    tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 "
                    parentClassName="w-full currency-tab quoting-currency"
                    activeClassName="text-grayLight900 bg-utilityGray100"
                    className=""
                    isTooltip={true}
                    tabArray={filterArr}
                    handleOnClick={(e: any) => {
                      setCurrency(e.target.dataset.value);
                    }}
                    isActive={currency}
                  />
                </>
              )}

            <SelectBox
              name="role"
              placeholder="Carrier Rates"
              parentClassName="sm:hidden block w-full"
              labelClassName="form_label"
              value={tabOptionsArr.find((val: any) => val.value === quoteType)}
              onChangeFunc={(event: any) => setQuoteType(event.value)}
              options={tabOptionsArr}
            />

            {quoteType === QuoteType.WITH_RATE &&
              (isAllCarrierRateFetched ? (
                <>
                  {isExpiredRateExist && (
                    <ButtonCmp
                      className="btn_secondary_black lg:!px-[9px] !px-2"
                      onClick={handleExpiredRefreshRates}
                      disabled={[
                        STATUS.EXPIRED,
                        STATUS.LOST,
                        STATUS.WON,
                      ].includes(addressDetails?.status)}
                    >
                      <RefreshCcw04 className="w-4 h-4 text-primary" />
                      Refresh Expired Rates
                    </ButtonCmp>
                  )}
                  {!isAllRateExpired && (
                    <ButtonCmp
                      className="btn_secondary_black lg:!px-[9px] !px-2"
                      onClick={() => {
                        setIsExpiredRefresh(false);

                        if (new Date(addressDetails.pickupDate) < new Date()) {
                          setIsShowRefreshRateModal(true);
                        } else {
                          setIsGetRate(true);
                        }
                      }}
                      disabled={[
                        STATUS.EXPIRED,
                        STATUS.LOST,
                        STATUS.WON,
                      ].includes(addressDetails?.status)}
                    >
                      <RefreshCcw01 className="w-4 h-4 text-primary" />
                      Refresh Rates
                    </ButtonCmp>
                  )}
                </>
              ) : (
                <>
                  <ButtonCmp
                    className="btn_secondary_black lg:!px-[9px] !px-2"
                    disabled={true}
                    loading={true}
                  >
                    Fetching Rates
                  </ButtonCmp>
                </>
              ))}

            {quoteType === QuoteType.LOAD_BOARD &&
              !isCreateLoadPost &&
              !isUpdateLoadPost && (
                // <>
                //   <div className="text-grayLight900 text-xs font-normal flex items-center">
                //     Posted on :&nbsp;
                //     <img src={doubleCheck} className="mr-0.5" />
                //     <span className="font-semibold text-fgSuccessPrimary">
                //       {moment(loadLinkPostingDetail?.createdAt).format(
                //         'MMM D, YYYY h:mm A'
                //       )}
                //     </span>
                //   </div>
                //   <ButtonCmp
                //     className="btn_secondary_black"
                //     onClick={() => setSyncLiveData(true)}
                //     disabled={syncLiveData}
                //     loading={syncLiveData}
                //   >
                //     {!syncLiveData && <RefreshCcw04 className="w-4 h-4" />}
                //   </ButtonCmp>
                // </>
                <ButtonCmp
                  className="btn-outline-primary"
                  onClick={() => {
                    setLoadLinkPostingDetail(undefined);
                    setIsCreateLoadPost(true);
                  }}
                >
                  <Plus className="w-4 h-4" /> New Load Posting
                </ButtonCmp>
              )}
          </div>

          {quoteType === QuoteType.NON_QUOTED && (
            <div
              className={`w-full ${
                nonQuotedList.length <= 0 ? 'flex flex-col flex-1' : ''
              }`}
            >
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
                <TableCmp
                  headCells={ReqQuoteHeadCells}
                  tableDataArr={nonQuotedList}
                  TableLoaderRowCmp={ReqQuoteLoading}
                  TableRowCmp={ReqQuotedRow}
                  tableRowCmpProps={{
                    handleActionType: handleActionType,
                    currency: currency,
                    currentUser: currentUser,
                    requestSpotQuote: requestSpotQuote,
                    selectedCarrierRates: selectedCarrierRates,
                    allChecked: allChecked,
                    setAllChecked: setAllChecked,
                    addressDetails: addressDetails,
                  }}
                  isTableDataLoading={isLoading || !isFinish}
                  isTableDataLoadingWithData={!isAllCarrierRateFetched}
                  numberOfSkeletonRows={8}
                  isTableRowClickable={true}
                  onRowClick={handleQuoteClick}
                />
              </div>
              {nonQuotedList.length <= 0 && isFinish && !isLoading && (
                <NotFoundUI
                  title="No Quote Found"
                  desc="There are no rates for current quote"
                  containerClassName="flex-1 !h-auto"
                />
              )}
            </div>
          )}

          {!quoteType && (
            <div className=" w-full flex flex-col flex-1">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
                <TableCmp
                  headCells={WithRatesHeadCells}
                  tableDataArr={[]}
                  TableLoaderRowCmp={WithQuoteLoading}
                  TableRowCmp={<></>}
                  isTableDataLoading={true}
                  numberOfSkeletonRows={5}
                  isTableRowClickable={true}
                />
              </div>
            </div>
          )}

          {quoteType === QuoteType.WITH_RATE && (
            <div
              className={`w-full ${
                withRateList.length <= 0 ? 'flex flex-col flex-1 ' : ''
              }`}
            >
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
                <TableCmp
                  headCells={WithRatesHeadCells}
                  tableDataArr={withRateList}
                  TableLoaderRowCmp={WithQuoteLoading}
                  TableRowCmp={WithRateQuote}
                  params={sortData}
                  setParams={setSortData}
                  tableRowCmpProps={{
                    handleActionType: handleActionType,
                    currency: currency,
                    addressDetails: addressDetails,
                    isFinish: isFinish,
                    selectedCarrierRates: selectedCarrierRates,
                  }}
                  onRowClick={handleQuoteClick}
                  isTableDataLoading={!isFinish || isLoading || isRateLoading}
                  isTableDataLoadingWithData={!isAllCarrierRateFetched}
                  numberOfSkeletonRows={5}
                  isTableRowClickable={true}
                  isTableRowClickableCondition={(data: any) => {
                    const expDate = new Date(data.expirationDate);
                    const today = new Date();
                    expDate.setDate(expDate.getDate() + 1);

                    const diffTime = expDate.getTime() - today.getTime();
                    const diffDays = Math.ceil(
                      diffTime / (1000 * 60 * 60 * 24)
                    );

                    if (diffDays < 0) {
                      return false;
                    }

                    return true;
                  }}
                  tableBodyRowClass={``}
                  getRowClass={(data: any) => {
                    let isNonQuotedServicesExist = false;

                    if (data?.services.some((form: any) => !form.isQuoted)) {
                      isNonQuotedServicesExist = true;
                    }

                    let isExpiredRate = false;
                    const expDate = new Date(data.expirationDate);
                    const today = new Date();
                    expDate.setDate(expDate.getDate() + 1);

                    const diffTime = expDate.getTime() - today.getTime();
                    const diffDays = Math.ceil(
                      diffTime / (1000 * 60 * 60 * 24)
                    );

                    if (diffDays < 0) {
                      isExpiredRate = true;
                    }

                    return isNonQuotedServicesExist || isExpiredRate
                      ? 'bg-utilityGray100 hover:bg-utilityGray200'
                      : '';
                  }}
                />
              </div>
              {withRateList.length <= 0 &&
                isFinish &&
                !isLoading &&
                !isRateLoading && (
                  <NotFoundUI
                    title="No Quote Found"
                    desc="There are no direct carrier rates for current quote"
                    containerClassName="flex-1 !h-auto"
                  />
                )}
            </div>
          )}

          {quoteType === QuoteType.LOAD_BOARD && (
            <>
              <PostToLoadBoard
                loadLinkPosts={loadLinkPosts}
                setLoadLinkPosts={setLoadLinkPosts}
                activePostIndex={activePostIndex}
                setActivePostIndex={setActivePostIndex}
                loadLinkPostingDetail={loadLinkPostingDetail}
                addressDetails={addressDetails}
                syncLiveData={syncLiveData}
                validatePostToLoadBoardForm={validatePostToLoadBoardForm}
                setValidatePostToLoadBoardForm={setValidatePostToLoadBoardForm}
                setIsPostCreateLoading={setIsPostCreateLoading}
                setSyncLiveData={setSyncLiveData}
                setLoadLinkPostingDetail={setLoadLinkPostingDetail}
                isUpdateLoadPost={isUpdateLoadPost}
                setIsUpdateLoadPost={setIsUpdateLoadPost}
                isCreateLoadPost={isCreateLoadPost}
                setIsCreateLoadPost={setIsCreateLoadPost}
                setIsRefresh={setIsRefresh}
                isRefresh={isRefresh}
                rateGatheringWithAi={rateGatheringWithAi}
                vehicleSizeOptions={vehicleSizeOptions}
                setCurrentTab={setCurrentTab}
              />
            </>
          )}

          {quoteType === QuoteType.LANE_HISTORY && <LaneHistory />}

          {quoteType === QuoteType.PREDICTIVE_PRICING && (
            <PredictivePriceChart
              id={id}
              predictivePriceData={predictivePriceData}
              setPredictivePriceData={setPredictivePriceData}
              openAddQuickModal={handleActionType(
                'model',
                'Quick_quote',
                addressDetails
              )}
            />
          )}
        </div>
      </PageSectionLayout>

      {isModalOpen && (
        <PDF
          isOpen={action.pdf}
          id={id}
          handleClose={handleDrawerClose}
          addressDetails={addressDetails}
        />
      )}

      <PricingDetails
        isOpen={action.pricingDetails}
        handleClose={handleDrawerClose}
        quote={{ addressDetails, dimensions, services }}
        carrierRate={selectedCarrierRates[0]}
      />

      {isQuoteModalOpen && (
        <MoveToActive
          quoteID={id}
          action={action}
          handleClose={handleQuoteModalClose}
          selectedCarrierRates={selectedCarrierRatesModel}
          currency={currency}
          setIsRefresh={setIsRefresh}
        />
      )}
      {action.addQuoteDetail && (
        <AddQuoteDetail
          handleClose={handleDrawerClose}
          carrierRate={selectedCarrierRates[0]}
          selectedCarrierId={selectedCarrierRatesModel?.[0]?.id}
          currency={currency}
          setIsRefresh={setIsRefresh}
        />
      )}

      {action.spotQuoteConfirmation && (
        <SpotQuoteConfirmation
          handleClose={handleModalClose}
          services={services}
          selectedCarrierRate={selectedCarrierRates[0]}
          dimensions={dimensions}
          addressDetails={addressDetails}
          loading={submitLoading}
        />
      )}

      {action.carrierOrderModel && (
        <FinalizeOrderModal
          handleClose={handleModalClose}
          carrierRate={selectedCarrierRates[0]}
          addressDetails={addressDetails}
          setAddressDetails={setAddressDetails}
          dimensions={dimensions}
          currency={currency}
          connectSocket={connectSocket}
        />
      )}
      {isShowRefreshRateModal && (
        <RefreshRatesModal
          handleClose={() => setIsShowRefreshRateModal(false)}
          isExpiredRefresh={isExpiredRefresh}
          handleSubmit={(date: any) => {
            setIsShowRefreshRateModal(false);
            setAddressDetails((prevState: any) => ({
              ...prevState,
              pickupDate: moment(date).format('YYYY-MM-DD HH:mm:ss'),
            }));
            setIsGetRate(true);
          }}
        />
      )}
      {isShowConvertOrderModal && (
        <ConvertOrderModal
          handleClose={() => setIsShowConvertOrderModal(false)}
          quotedList={nonExpiredList}
          currency={currency}
          handleActionType={handleActionType}
        />
      )}
      {isShowGeneratePdfModal && (
        <GeneratePdfModal
          handleClose={() => setIsShowGeneratePdfModal(false)}
          quotedList={quotedList}
          currency={currency}
          selectedDisplayOptions={selectedDisplayOptions}
          setSelectedDisplayOptions={setSelectedDisplayOptions}
          selectedLanguage={selectedLanguage}
          handleLanguageSelect={handleLanguageSelect}
          selectedQuoteToGeneratePdfId={selectedQuoteToGeneratePdfId}
          setSelectedQuoteToGeneratePdfId={setSelectedQuoteToGeneratePdfId}
          handleGeneratePdf={handleGeneratePdf}
          isPdfGenerating={isPdfGenerating}
        />
      )}
      {isShowSendPdfModal &&
        (addressDetails?.emailContentId && quoteEmailDetail?.emailBody ? (
          <ReplyRequestModal
            emailDetails={quoteEmailDetail.emailBody}
            draftEmail={
              'Please find the detailed quote with this attached document provides a comprehensive breakdown of our offerings, pricing, and any relevant terms.'
            }
            draftEmailLoading={false}
            handleClose={() => setIsShowSendPdfModal(false)}
            defaultAttachments={[
              { url: generatedPdfUrl, fileName: generatedPdfName },
            ]}
          />
        ) : (
          <SendQuoteToCustomerModal
            handleClose={() => setIsShowSendPdfModal(false)}
            customerName={addressDetails?.customerName || ''}
            customerEmail={addressDetails?.customerEmail || ''}
            onSubmit={handleSendQuoteToCustomer}
            generatedPdfName={generatedPdfName}
            defaultAttachments={[
              { url: generatedPdfUrl, fileName: generatedPdfName },
            ]}
          />
        ))}

      {isShowCloseReasonModal && (
        <CloseQuoteConfirm
          id={id}
          handleSuccess={handleQuoteClose}
          isLoading={isQuoteCloseLoading}
          handleClose={() => {
            setIsShowCloseReasonModal(false);
          }}
        />
      )}

      {openOverviewOfRateGathering && (
        <OverviewRateGathering
          handleClose={() => setOpenOverviewOfRateGathering(false)}
        />
      )}
    </>
  );
};

export default QuoteDetailPage;
