import { Mail01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import MailDetailView from 'src/app/QuotingHub/QuotingRequest/MessageDetailSection/MailDetailView';
import BadgeCmp from 'src/components/BadgeCmp';
import CommonModal from 'src/components/CommonModal';
import NotFoundUI from 'src/components/NotFoundUI';
// import TooltipCmp from 'src/components/TooltipCmp';
import {
  getBadgeLabelByQuoteStatus,
  getBadgeTypeByQuoteStatus,
} from 'src/constants/common';
import { getShortName, onError } from 'src/utils/CommonFunctions';

const QuoteEmailModal = ({ data, quoteStatus, handleClose }: any) => {
  console.log(quoteStatus);
  const [selectedEmail, setSelectedEmail] = useState<any>();
  const [isEmailClicked, setIsEmailClicked] = useState(false);
  const [emailThread, setEmailThread] = useState<any>();

  const mergeThreadToArray = (dataObj: any) => {
    const filteredThread =
      dataObj?.thread
        ?.filter((email: any) => email.id !== data.id)
        .map((item: any) => ({
          ...item,
          emailBody: item?.emailBody ? JSON.parse(item.emailBody) : {},
        })) ?? [];

    // Return the final array
    return [data, ...filteredThread];
  };

  useEffect(() => {
    // setEmailThread({
    //   ...data,
    //   data?.thread?.map((item: any) => ({
    //     ...item,
    //     emailBody: item?.emailBody ? JSON.parse(item.emailBody) : {},
    //   })) || []
    // }
    // );

    setEmailThread(mergeThreadToArray(data));
  }, [data]);
  useEffect(() => {
    if (!isEmailClicked) {
      setSelectedEmail(null);
    }
  }, [isEmailClicked]);

  // const handleDismissEmail = async (mailMessageId: any) => {
  //   try {
  //     removeFromQuote({ id: mailMessageId });

  //     setEmailThread((prevEmails: any) => {
  //       console.log('prevEmails:: ', prevEmails);

  //       if (
  //         prevEmails?.length === 1 &&
  //         prevEmails?.[0].messageId === mailMessageId
  //       ) {
  //         return [];
  //       }

  //       return prevEmails?.filter(
  //         (email: any) => email.messageId !== mailMessageId
  //       );
  //     });

  //     setQuoteEmailDetail((prevEmails: any) => {
  //       console.log('prevEmails', prevEmails);

  //       // Check if `thread` exists and has only one item with matching `messageId`
  //       if (
  //         prevEmails?.thread?.length === 1 &&
  //         prevEmails?.thread?.[0]?.messageId === mailMessageId
  //       ) {
  //         return { ...prevEmails, thread: [] };
  //       }

  //       return {
  //         ...prevEmails,
  //         thread:
  //           prevEmails?.thread?.filter(
  //             (email: any) => email.messageId !== mailMessageId
  //           ) ?? [],
  //       };
  //     });

  //     // if (selectedEmail?.messageId === mailMessageId) {
  //     //   const nextEmail = emailData[deletedIndex + 1];
  //     //   const prevEmail = emailData[deletedIndex - 1];

  //     //   if (
  //     //     deletedIndex >= emailData.length - 10 &&
  //     //     totalEmails > emailData.length &&
  //     //     !isLoadingMore
  //     //   ) {
  //     //   }
  //     // }
  //   } catch (error) {
  //     WalToast.error('Failed to discard email.');
  //   }
  // };

  return (
    <CommonModal
      title={'Customer Thread'}
      titleDesc={'Review rate and margin details to create an active quote.'}
      handleClose={() => handleClose()}
      size={'max-w-[688px]'}
      modalClassName=""
      // primaryBtnText="Confirm"
      // secondaryBtnText="Cancel"
      // secondaryBtnOnClick={() => {
      //   handleClose();
      // }}
      // primaryBtnOnClick={() => {}}
      headerIcon={<Mail01 />}
    >
      <>
        <ul className="space-y-4 p-5">
          {emailThread?.map((item: any, index: number) => {
            // let emailBody = JSON.parse(item?.emailBody);
            let emailBody = item?.emailBody;

            return (
              <li
                key={index}
                className="rounded-lg bg-white shadow-md"
                onClick={() => {
                  setSelectedEmail(item);
                  setIsEmailClicked(!isEmailClicked);
                  // setIsEmailClicked(true);
                }}
              >
                <>
                  <div className="px-4 py-3 flex items-center gap-2 border-b border-utilityGray200 cursor-pointer">
                    {item?.image ? (
                      <img
                        className="w-10 h-10 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                        src={item?.imageUrl + item?.image}
                        alt=""
                        title=""
                        onError={onError}
                      />
                    ) : (
                      <div className="w-10 h-10 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
                        {getShortName(`${emailBody?.from?.emailAddress?.name}`)}
                      </div>
                    )}
                    <div className="flex-1 space-y-0.5">
                      <h4 className="text-grayLight900 text-sm font-medium truncate">
                        {emailBody?.from?.emailAddress?.name}
                      </h4>
                      <p className="text-grayLight600 text-xs font-normal truncate">
                        {emailBody?.from?.emailAddress?.address}
                      </p>
                    </div>
                    <div className="space-y-1 text-right">
                      <div className="text-grayLight600 text-xs font-normal">
                        {item?.createdAt
                          ? moment(item.createdAt).format(
                              'hh:mm A ddd, MMM D, YYYY'
                            )
                          : ''}
                      </div>
                      <BadgeCmp
                        style="modern"
                        type={getBadgeTypeByQuoteStatus(quoteStatus)}
                      >
                        {getBadgeLabelByQuoteStatus(quoteStatus)}
                      </BadgeCmp>
                    </div>
                  </div>
                  {isEmailClicked &&
                  selectedEmail?.messageId === item?.messageId ? (
                    <MailDetailView
                      emailDetails={selectedEmail}
                      setIsEmailClicked={setIsEmailClicked}
                      handleDismissEmail={() => {}}
                      isModalDetailView={true}
                    />
                  ) : (
                    ''
                  )}
                </>
              </li>
            );
          })}
          {emailThread?.length === 0 ? (
            <NotFoundUI
              title="Not Found customer thread"
              desc="There are no customer thread found for this quote."
              containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
            />
          ) : (
            ''
          )}
        </ul>
      </>
    </CommonModal>
  );
};
export default QuoteEmailModal;
