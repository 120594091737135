import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const CustomerOrdersTableRow = ({ data }: any) => {
  console.log('🚀 ~ CustomerOrdersTableRow ~ data:', data);

  return (
    <>
      <td className="px-5 py-4 first:w-80 first:min-w-80 first:max-w-80 pl-[107px]">
        <a
          href={`https://wal.roserocket.com/#/ops/orders/${data.roserocketOrderId}`}
          className="text-primary underline font-medium "
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          #{data.orderFullId}
        </a>
      </td>
      <td className="px-5 py-4 w-64 min-w-64 max-w-64">
        {data.orderCommissionees ? (
          <div className="flex items-center gap-2 min-w-92 min-h-8 ">
            {data.orderCommissioneesImage ? (
              <img
                src={`${data.orderCommissioneesImageUrl}${data.orderCommissioneesImage}`}
                className="table-profile-icn-circle-sm"
                alt={data.orderCommissionees}
                title={data.orderCommissionees}
                onError={onError}
              />
            ) : (
              <div className="table-profile-user-circle-sm">
                {getShortName(data.orderCommissionees)}
              </div>
            )}
            {data?.orderCommissionees?.length > 35 ? (
              <TooltipCmp
                message={data.orderCommissionees}
                parentClassName="xs:w-80 w-64"
              >
                <p className="text-grayLight900 font-medium leading-tight max-w-52 truncate">
                  {data.orderCommissionees}
                </p>
              </TooltipCmp>
            ) : (
              <h6 className="text-grayLight900 font-medium leading-tight max-w-52 truncate">
                {data.orderCommissionees}
              </h6>
            )}
          </div>
        ) : (
          '-'
        )}
      </td>

      <td className="px-5 py-4">${getFormattedNumber(data.combinedRevenue)}</td>
      {/* <td className="px-5 py-4 w-28 min-w-28 max-w-28">
        ${getFormattedNumber(data.combinedCost)}
      </td> */}
      <td className="px-5 py-4">
        {getFormattedNumber(data.combinedMargin, true, true)}
      </td>
      <td className="px-5 py-4">
        {data.combinedMarginPercentage > 0
          ? `${parseFloat(data?.combinedMarginPercentage).toFixed(2)}%`
          : `${0}%`}
      </td>
      <td className="px-5 py-4">-</td>
      <td className="px-5 py-4">-</td>
    </>
  );
};

export default CustomerOrdersTableRow;
