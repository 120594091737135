import React from 'react';

const TotalMarginRow = ({ data, activeCurrencyTab }: any) => (
  <>
    <td className="px-4 py-3 w-1/3 min-w-1/3 max-w-[1px]">
      <div className="flex items-center gap-3">
        <h6
          className={`truncate ${
            data.isHaveClass ? 'text-grayLight900 font-medium' : ''
          }`}
        >
          {data.name}
        </h6>
      </div>
    </td>
    <td className="px-4 py-3 w-1/3 min-w-1/3 max-w-[1px]"></td>
    <td className="px-4 py-3">
      <span
        className={`truncate ${
          data.isHaveClass ? 'text-grayLight900 font-medium' : ''
        }`}
      >
        {activeCurrencyTab === 'CAD'
          ? `$${data?.cad?.toFixed(2)} CAD`
          : `$${data?.usd?.toFixed(2)} USD`}
      </span>
    </td>
  </>
);

export default TotalMarginRow;
