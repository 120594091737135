import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const getLoadLinkVehicleType = async (signal?: any) => {
  const response = await axiosInterceptor.get(
    API.QUOTE.LOAD_LINK.GET_VEHICLE_TYPE,
    {
      signal,
    }
  );

  return response;
};

export const getLoadLinkVehicleSize = async () => {
  const response = await axiosInterceptor.get(
    API.QUOTE.LOAD_LINK.GET_VEHICLE_SIZE
  );

  return response;
};

export const getLoadLinkVehicleAttribute = async () => {
  const response = await axiosInterceptor.get(
    API.QUOTE.LOAD_LINK.GET_VEHICLE_ATTRIBUTE
  );

  return response;
};

export const createdLoadLinkPosting = async (payload: any) => {
  const response = await axiosInterceptor.post(
    API.QUOTE.LOAD_LINK.CREATE_POSTING,
    payload
  );

  return response;
};

export const deleteLoadLinkPosting = async (id: number) => {
  const response = await axiosInterceptor.delete(
    `${API.QUOTE.LOAD_LINK.DELETE_POSTING}/${id}`
  );

  return response;
};

export const getLoadLinkPostLeads = async (
  params: any,
  id: number,
  signal: any
) => {
  const response = await axiosInterceptor.get(
    `${API.QUOTE.LOAD_LINK.GET_LEADS}/${id}`,
    { params, signal }
  );

  return response;
};

export const updateRateGathering = async (payload: any) => {
  const response = await axiosInterceptor.post(
    API.QUOTE.LOAD_LINK.UPDATE_RATE_GATHERING,
    payload
  );

  return response;
};
