import React, { useMemo } from 'react';
import AccordionCmp from 'src/components/AccordionCmp';
import TableCmp from 'src/components/TableCmp';

import dimensionsOrderDetailRow from './dimensionsOrderDetailRow';

interface IProps {
  isLoading: boolean;
  currentLegData: Record<string, any>;
  handlingUnitOptions: any;
}

const RenderOrderDimensionSection = ({
  isLoading,
  currentLegData,
  handlingUnitOptions,
}: IProps) => {
  console.log('isLoading', isLoading);

  const headCells = useMemo(
    () => [
      {
        id: 'quantity',
        name: 'Quantity',
        rowClassName: '',
      },
      {
        id: 'handlingUnit',
        name: 'Handling Unit',
        rowClassName: '',
      },
      {
        id: 'dimensions',
        name: 'Dimensions',
        rowClassName: '',
      },
      {
        id: 'class',
        name: 'Class',
        rowClassName: '',
      },
      {
        id: 'totalWeight',
        name: 'Total Weight',
        rowClassName: '',
      },
      {
        id: 'refNumber',
        name: 'REF Number',
        rowClassName: '',
      },
      {
        id: 'description',
        name: 'Description',
        rowClassName: '',
      },
    ],
    []
  );

  return (
    <AccordionCmp
      className="!p-0 !border-0 !bg-transparent"
      contentParentClass="border-t-0 !pt-0"
      contentClassName="space-y-3 !bg-transparent"
      title={
        <div className="flex items-center justify-between gap-x-1.5 flex-1">
          <h6 className="text-grayLight900 text-sm font-medium uppercase">
            Dimensions
          </h6>
          <p className="text-primary text-xs leading-[1.5] font-semibold">
            View Details
          </p>
        </div>
      }
      AccordionIconClass="text-primary"
      isOpen={true}
    >
      <>
        <div className="overflow-x-auto custom-scrollbar scrollbar-hide -my-3">
          <TableCmp
            tableWrapClass="table-with-spacing table-box"
            headCells={headCells}
            tableDataArr={currentLegData?.order_dimensions ?? []}
            TableRowCmp={dimensionsOrderDetailRow}
            tableRowCmpProps={{
              handlingUnitOptions,
            }}
          />
        </div>
      </>
    </AccordionCmp>
  );
};

export default RenderOrderDimensionSection;
