import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
} from 'src/utils/CommonFunctions';

import RadarOrderStatus from './RadarOrderStatus';

const RadarOrderDetail = ({
  setOrder,
  order,
  isLoading,
}: {
  setOrder: any;
  order: any;
  isLoading: boolean;
}) => (
  <>
    <div className="space-y-5">
      <ul className="radar-detail-box flex flex-wrap">
        <li className="px-4 py-3 space-y-1 flex-1 w-1/4">
          <h6
            className={`text-textSecondary text-xs font-semibold ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            Order ID
          </h6>
          <p
            className={`text-gray500 text-xs leading-[1.5] font-normal ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            {order?.orderId ? `#${order?.orderId}` : '-'}
          </p>
        </li>
        <li className="px-4 py-3 space-y-1 flex-1 w-1/4">
          <h6
            className={`text-textSecondary text-xs font-semibold ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            Order Created
          </h6>
          <p
            className={`text-gray500 text-xs leading-[1.5] font-normal ${
              isLoading ? 'custom-loading' : ''
            }`}
            dangerouslySetInnerHTML={{
              __html: order?.createdAt
                ? getDateWithSuffixFormat(
                    getFormattedDate(
                      order?.createdAt,
                      `Do MMMM YYYY`,
                      true,
                      true
                    )
                  )
                : '-',
            }}
          />
        </li>
        <li className="px-4 py-3 space-y-1 flex-1 w-1/4">
          <h6
            className={`text-textSecondary text-xs font-semibold ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            Sales Rep.
          </h6>
          <div className="flex items-center gap-x-1">
            {isLoading ? (
              <div
                className={`w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                mark zukerberg
              </div>
            ) : (
              order?.salesRepFirstName && (
                <>
                  {order?.salesImage ? (
                    <img
                      src={order?.salesImageUrl + order?.salesImage}
                      className="w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                    />
                  ) : (
                    <div className="w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[7px]">
                      {getShortName(
                        `${order?.salesRepFirstName} ${order?.salesRepLastName}`
                      )}
                    </div>
                  )}
                </>
              )
            )}
            <p
              className={`text-gray500 text-xs leading-[1.5] font-normal truncate ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {order?.salesRepFirstName
                ? `${order?.salesRepFirstName} ${order?.salesRepLastName}`
                : '-'}
            </p>
          </div>
        </li>
        <li className="px-4 py-3 space-y-1 flex-1 w-1/4">
          <h6
            className={`text-textSecondary text-xs font-semibold ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            Customer
          </h6>
          <p
            className={`text-gray500 text-xs leading-[1.5] font-normal truncate ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            {order?.customerName ?? '-'}
          </p>
        </li>
      </ul>
      <RadarOrderStatus
        orderLegList={order?.orderLegs ? order?.orderLegs : []}
        isLoading={isLoading}
        setOrder={setOrder}
        order={order}
      />
    </div>
  </>
);

export default RadarOrderDetail;
