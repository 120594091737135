import {
  Copy06,
  LinkExternal02,
  Container,
  ReverseLeft,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ClaimResolve from 'src/app/Claims&Disputes/Claims/ClaimResolve';
import DisputeResolve from 'src/app/Claims&Disputes/Disputes/DisputeResolve';
import ButtonCmp from 'src/components/ButtonCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { getActiveOrder, updateOrderStatus } from 'src/services/OrderService';
import { getShortName } from 'src/utils/CommonFunctions';

import { OrderContext } from '..';
import defaultimage from '../../../../assets/img/default-image.jpg';
import AppointmentModal from '../../Common/Appointment/AppointmentModal';
import AppointmentSuccessModal from '../../Common/Appointment/AppointmentSuccessModal';
import DraftMailModal from '../../Common/DraftMailModal';
import UpdateLocation from '../../Common/UpdateLocation';
import UploadOrderPodModal from '../../Common/UploadOrderPodModal';
import { appointmentDetails, OrderStatusAction } from '../../order.constant';
import {
  initAction,
  initShipperAddress,
  processOrderData,
} from '../../order.interface';
import Appointment from '../Appointment';
import ViewOrderPodModal from '../ViewOrderPodModal';

import ActionButtons from './ActionButtons';
import AssignCarrier from './AssignCarrier';
import ProgressList from './ProgressList';
import ReportInterruptionModal from './ReportInterruptionModal';

const OrderStatus = () => {
  const {
    isLoading,
    setOrder,
    setTabValues,
    id,
    order,
    carrierPrice,
    currentLegData,
    currentLocation,
    setCurrentLocation,
    setCarrierPrice,
  } = useContext<any>(OrderContext);

  const [action, setAction] = useState<any>(initAction);
  const [activeLegData, setActiveLegData] = useState<any>([]);
  const [activeLegCarrier, setActiveLegCarrier] = useState<any[]>([]);

  const [progressData, setProgressData] = useState<any>([]);
  const [isCarrierAssigned, setIsCarrierAssigned] = useState<boolean>(false);
  const [isOrderInReview, setIsOrderInReview] = useState<boolean>(false);
  const [isInvoiced, setIsInvoiced] = useState(false);
  const [isOrderCompleted, setIsOrderCompleted] = useState(false);
  const [claim, setClaim] = useState(null);
  const [dispute, setDispute] = useState(null);
  const [isProgressLoading, setIsProgressLoading] = useState(true);
  const [orderStatusButtonText, setOrderStatusButtonText] = useState<any>(
    OrderStatusAction.MARK_AS_PICKED_UP
  );

  useEffect(() => {
    if (order?.id) {
      getActiveOrder({ masterOrderId: order?.id })
        .then((response) => {
          if (response?.data) {
            setActiveLegData(processOrderData(response?.data?.activeOrder));
            setProgressData(response?.data?.statusObject);
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsProgressLoading(false);
        });
    }
  }, [order]);

  const [editMode, setEditMode] = useState<{
    type: string | null;
    details: any;
  }>({ type: null, details: null });
  const [documents, setDocuments] = useState<any>([]);

  const [orderNextActionTitle, setOrderNextActionTitle] = useState(
    'Order Created - Assign a Carrier'
  );

  const [orderNextActionDescription, setOrderNextActionDescription] = useState(
    'Please assign to assign a carrier to move this to dispatched.'
  );

  useEffect(() => {
    const checkCarrierAssigned =
      activeLegData?.carrierPrice?.length > 0 &&
      activeLegData?.carrierPrice?.[0]?.carrierId;

    setIsCarrierAssigned(checkCarrierAssigned);
    setDocuments(activeLegData?.documents);

    setActiveLegCarrier(activeLegData?.carrierPrice);

    if (activeLegData?.orderStatus === 'booked') {
      if (!checkCarrierAssigned) {
        setOrderNextActionTitle('Order Created - Assign a Carrier');
        setOrderNextActionDescription(
          'Please assign a carrier to move this to dispatched.'
        );
      }

      if (checkCarrierAssigned) {
        setOrderNextActionTitle(
          'Order Dispatched. Carrier & Shipper Appointments Required.'
        );
        setOrderNextActionDescription(
          'Ensure both carrier and shipper appointments are set.'
        );
      }

      if (activeLegData?.shipperAppointmentDate) {
        setOrderNextActionTitle(
          `Pickup Appointment Scheduled Today at ${moment(
            activeLegData?.shipperAppointmentTime,
            'HH:mm:ss'
          ).format('hh:mm A')}`
        );
        setOrderNextActionDescription(
          'Pick the exact time between carrier and shipper'
        );
      }
    }

    if (activeLegData?.orderStatus === 'dispatched') {
      setOrderNextActionTitle('Shipment In Transit');

      if (currentLocation) {
        setOrderNextActionDescription(
          `Last updated on ${moment
            .utc(currentLocation?.updatedAt)
            .local()
            .format('hh:mm A')} at ${currentLocation?.state} ${
            currentLocation?.stateCode
          }, ${currentLocation?.country}.`
        );
      } else {
        setOrderNextActionDescription(
          'The location status has not been updated.'
        );
      }
    }

    if (activeLegData?.orderStatus === 'in-transit') {
      if (activeLegData?.documents) {
        setDocuments(activeLegData?.documents);

        if (
          activeLegData?.documents &&
          activeLegData?.documents?.filter(
            (doc: any) => doc.documentType === 'POD'
          )?.length > 0
        ) {
          setOrderNextActionTitle(
            'Shipment Delivered. Please Request And Review POD'
          );
          setOrderNextActionDescription(
            'Waiting for the carrier to provide POD and invoice'
          );
        } else {
          setOrderNextActionTitle(
            'Shipment Delivered. Please Request and Upload POD'
          );
          setOrderNextActionDescription('Confirm load with the carrier');
        }
      } else {
        setOrderNextActionTitle(
          'Shipment Delivered. Please Request and Upload POD'
        );
        setOrderNextActionDescription('Confirm load with the carrier');
      }
    }

    if (
      activeLegData?.orderStatus === 'delivered' &&
      (activeLegData?.claimId || activeLegData?.disputeId) &&
      (activeLegData?.claim?.status !== 'Resolved' ||
        activeLegData?.dispute?.status !== 'Resolved')
    ) {
      setIsOrderInReview(true);
      setOrderNextActionTitle('Order On Hold. Claim in Progress');
      setOrderNextActionDescription('Pending resolution of the claim');
    } else {
      setIsOrderInReview(false);
    }

    if (activeLegData?.claim) {
      setClaim(activeLegData?.claim);
    }

    if (activeLegData?.dispute) {
      setDispute(activeLegData?.dispute);
    }

    if (
      activeLegData?.orderStatus === 'delivered' &&
      activeLegData?.claim?.status === 'Resolved' &&
      activeLegData?.dispute?.status === 'Resolved'
    ) {
      setIsOrderInReview(false);
      setIsInvoiced(true);

      if (activeLegData?.isLastLeg) {
        setOrderNextActionTitle('Customer Invoiced, Awaiting Payment');
        setOrderNextActionDescription(
          'Payment will be processed via QuickBooks'
        );
      } else {
        setOrderNextActionTitle('Preparing Next Delivery');
        setOrderNextActionDescription(
          'The order is being prepared for the next delivery leg'
        );
      }
    }

    if (
      activeLegData?.orderStatus === 'delivered' &&
      !activeLegData?.claimId &&
      !activeLegData?.disputeId
    ) {
      setIsOrderInReview(false);
      setIsInvoiced(true);

      if (activeLegData?.isLastLeg) {
        setOrderNextActionTitle('Customer Invoiced, Awaiting Payment');
        setOrderNextActionDescription(
          'Payment will be processed via QuickBooks'
        );
      } else {
        setOrderNextActionTitle('Preparing Next Delivery');
        setOrderNextActionDescription(
          'The order is being prepared for the next delivery leg'
        );
      }
    } else {
      if (isInvoiced) {
        setIsInvoiced(false);
      }
    }

    if (activeLegData?.orderStatus === 'completed') {
      setIsOrderCompleted(true);
      setOrderNextActionTitle('Payment Received, Order Completed');
      setOrderNextActionDescription('All processes finalized');
    } else {
      if (isOrderCompleted) {
        setIsOrderCompleted(false);
      }
    }
  }, [activeLegData]);

  useEffect(() => {
    if (
      activeLegCarrier &&
      activeLegCarrier?.length > 0 &&
      activeLegCarrier?.[0]?.carrierId
    ) {
      setIsCarrierAssigned(true);
    } else {
      setIsCarrierAssigned(false);
    }
  }, [activeLegCarrier]);

  const carrierDetail = useMemo(() => {
    if (activeLegCarrier?.length > 0 && activeLegCarrier?.[0]?.carrierId) {
      return (
        <div className="flex items-center gap-x-2.5">
          {activeLegCarrier[0]?.carrier?.image ? (
            <img
              src={defaultimage}
              className="w-10 h-10 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none"
            />
          ) : (
            <div className="w-10 h-10 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
              {getShortName(activeLegCarrier[0]?.carrier?.name)}
            </div>
          )}
          <div>
            <h6 className="text-grayLight900 text-sm font-medium">
              {activeLegCarrier[0]?.carrier?.name}
            </h6>
            <p className="flex items-center gap-1 text-grayLight600 text-xs font-normal">
              #6526581
              <Copy06 className="w-3 h-3 text-primary" />
              <LinkExternal02 className="w-3 h-3 text-primary" />
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="flex items-center gap-x-2.5">
        <div
          className={`w-10 h-10 rounded-md border border-black/[0.08] flex-none flex items-center justify-center bg-utilityGray100 text-gray500 ${
            isLoading ? 'custom-loading' : ''
          }`}
        >
          <Container className="w-6 h-6" />
        </div>
        <div>
          <h6
            className={`text-grayLight900 text-sm font-medium ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            Unassigned Carrier
          </h6>
          <p
            className={`flex items-center gap-1 text-grayLight600 text-xs font-normal ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            -
          </p>
        </div>
      </div>
    );
  }, [activeLegCarrier]);

  useEffect(() => {
    if (
      currentLegData &&
      activeLegData &&
      currentLegData?.id === activeLegData?.id
    ) {
      setActiveLegCarrier(carrierPrice);
    }
  }, [carrierPrice]);

  const handleEditAppointMent = (type: string, details: any) => {
    setEditMode({ type, details });
    setAction((old: any) => ({
      ...old,
      mode: type,
    }));
  };

  useEffect(() => {
    if (activeLegData) {
      if (activeLegData?.orderStatus === 'booked') {
        setOrderStatusButtonText(OrderStatusAction.MARK_AS_PICKED_UP);
      }

      if (activeLegData?.orderStatus === 'dispatched') {
        setOrderStatusButtonText(OrderStatusAction.MARK_AS_DELIVERED);
      }

      if (activeLegData?.orderStatus === 'in-transit') {
        setOrderStatusButtonText(OrderStatusAction.MARK_AS_COMPLETED);
      }

      if (activeLegData?.orderStatus === 'delivered') {
        setOrderStatusButtonText(OrderStatusAction.MARK_AS_COMPLETED);
      }

      if (activeLegData?.orderStatus === 'completed') {
        setOrderStatusButtonText(OrderStatusAction.MARK_AS_COMPLETED);
      }
    }
  }, [activeLegData]);

  const handleOrderStatus = (isUndo: boolean) => {
    setIsProgressLoading(true);

    updateOrderStatus({
      orderLegId: activeLegData?.id,
      isUndo,
    })
      .then((response) => {
        if (response?.data) {
          const orderStatus = response?.data?.orderLegStatus;
          const masterOrderStatus = response?.data?.masterOrderStatus;

          if (orderStatus === 'booked') {
            setOrderStatusButtonText(OrderStatusAction.MARK_AS_PICKED_UP);
          }

          if (orderStatus === 'dispatched') {
            setOrderStatusButtonText(OrderStatusAction.MARK_AS_DELIVERED);
          }

          if (orderStatus === 'in-transit') {
            setOrderStatusButtonText(OrderStatusAction.MARK_AS_COMPLETED);
          }

          if (orderStatus === 'deliverd') {
            setOrderStatusButtonText(OrderStatusAction.MARK_AS_COMPLETED);
          }

          if (orderStatus === 'completed') {
            setOrderStatusButtonText(OrderStatusAction.MARK_AS_COMPLETED);
          }

          setActiveLegData((curr: any) => ({
            ...curr,
            orderStatus: orderStatus,
          }));

          setOrder((ord: any) => ({
            ...ord,
            orderStatus: masterOrderStatus,
          }));

          setIsProgressLoading(false);
        }
      })
      .catch(console.error);
  };

  const handleButtonClick = () => {
    // if (
    //   orderStatusButtonText?.type === 'pickup' &&
    //   activeLegData?.carrierPrice?.length === 0
    // ) {
    //   WalToast.error(
    //     'Please assign carrier before marking the order as pickup'
    //   );

    //   return;
    // }

    handleOrderStatus(false);
  };

  return (
    <>
      <div className="rounded-xl border border-utilityGray200 shadow-xs overflow-hidden flex-none">
        <h6 className="text-grayLight900 text-base font-medium p-4 border-b border-utilityGray200">
          Tracking Status
        </h6>
        <div className="p-4 border-b border-utilityGray200 flex items-center justify-between gap-x-2 bg-gray25">
          {carrierDetail}
          <div className="flex gap-x-2">
            {!isOrderCompleted && (
              <ActionButtons
                activeLegData={activeLegData}
                isCarrierAssigned={isCarrierAssigned}
                setAction={setAction}
                handleEditAppointMent={handleEditAppointMent}
                documents={documents}
                isOrderInReview={isOrderInReview}
                dispute={dispute}
                claim={claim}
                isInvoiced={isInvoiced}
                isProgressLoading={isProgressLoading}
              />
            )}
          </div>
        </div>
        <div className="p-4 space-y-5 bg-gray25">
          <ProgressList
            isProgressLoading={isProgressLoading}
            progressData={progressData}
          />
          <div className="flex justify-between items-center gap-x-2">
            <div>
              <h6
                className={`text-grayLight900 text-lg font-semibold ${
                  isLoading || isProgressLoading ? 'custom-loading' : ''
                }`}
              >
                {orderNextActionTitle}
                {/* {!isLoading && !isProgressLoading ? (
                  <span className="text-xs font-medium ml-1">
                    (#{activeLegData?.legId})
                  </span>
                ) : (
                  <p className="text-xs font-medium ml-1">(#WAL-TC3B-383)</p>
                )} */}
              </h6>
              <p
                className={`text-grayLight900 text-xs font-normal ${
                  isLoading || isProgressLoading ? 'custom-loading' : ''
                }`}
              >
                {orderNextActionDescription}
              </p>
            </div>
            {!isOrderCompleted && (
              <div className="flex gap-x-2">
                <TooltipCmp message={orderStatusButtonText?.toolTip}>
                  <ButtonCmp
                    className={`btn-outline-primary lg:!px-[9px] !px-2 ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                    icon={<ReverseLeft className="w-4 h-4" />}
                    onClick={() => {
                      handleOrderStatus(true);
                    }}
                    disabled={!orderStatusButtonText?.toolTip}
                  >
                    Undo
                  </ButtonCmp>
                </TooltipCmp>
                <ButtonCmp
                  className={`btn-outline-primary ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                  onClick={handleButtonClick}
                >
                  {orderStatusButtonText?.title}
                </ButtonCmp>
              </div>
            )}
          </div>
        </div>
      </div>

      {activeLegData?.carrierPrice?.length > 0 && (
        <Appointment
          activeLegData={activeLegData}
          setActiveLegData={setActiveLegData}
        />
      )}

      {action.mode === 'update-location' && (
        <UpdateLocation
          action={action}
          setAction={setAction}
          addressData={initShipperAddress}
          orderLegId={activeLegData?.id}
          setCurrentLocation={setCurrentLocation}
        />
      )}

      {action.mode === 'report-interruption' && (
        <ReportInterruptionModal
          handleClose={() => {
            setAction((old: any) => ({ ...old, mode: null }));
          }}
          handleOnSubmit={(data: any) => {
            setAction((old: any) => ({ ...old, mode: null }));
            setActiveLegData((old: any) => ({
              ...old,
              claimId: data?.claim?.id,
              claim: data?.claim,
              disputeId: data?.dispute?.id,
              dispute: data?.dispute,
            }));

            if (setTabValues) {
              setTabValues((prevTabValues: any) => ({
                ...prevTabValues,
                [activeLegData?.legId]: {
                  ...prevTabValues[activeLegData?.legId],
                  claimId: data?.claim?.id,
                  claim: data?.claim,
                  disputeId: data?.dispute?.id,
                  dispute: data?.dispute,
                },
              }));
            }
          }}
          orderLegId={activeLegData?.id}
        />
      )}

      {action.mode === 'upload-pod' && (
        <UploadOrderPodModal
          handleClose={() => setAction(initAction)}
          orderLegId={activeLegData?.id}
          title={'Upload POD'}
          viewPDFTitle={'View POD'}
          fileStorePath={'POD_document'}
          documentType={'POD'}
          documents={
            documents?.length > 0 &&
            documents?.filter((doc: any) => doc.documentType === 'POD')
          }
          handleOnSubmit={(documentData: any) => {
            if (documentData) {
              setDocuments((prevDocs: any[]) => [
                ...prevDocs,
                { ...documentData },
              ]);
            }
            setAction(initAction);
          }}
          isShowTitle={true}
        />
      )}

      {action.mode === 'review-pod' && (
        <ViewOrderPodModal
          handleClose={() => setAction(initAction)}
          title={'Review POD'}
          titleDesc={'Verify and Approve Proof of Delivery'}
          isShowButtons={true}
          contentTitle={'Review PODs'}
          documents={
            documents?.length > 0 &&
            documents?.filter((doc: any) => doc.documentType === 'POD')
          }
          orderLegId={activeLegData?.id}
          setDocuments={setDocuments}
        />
      )}

      {action.mode === 'view-pod' && (
        <ViewOrderPodModal
          handleClose={() => setAction(initAction)}
          title={'View POD'}
          titleDesc={'Verify and Approve Proof of Delivery'}
          isShowButtons={false}
          contentTitle={'View PODs'}
          documents={
            documents?.length > 0 &&
            documents?.filter((doc: any) => doc.documentType === 'POD')
          }
          orderLegId={activeLegData?.id}
        />
      )}

      {action.mode === 'upload-customs-documents' && (
        <UploadOrderPodModal
          handleClose={() => setAction(initAction)}
          orderLegId={activeLegData?.id}
          title={'Upload Customs Document'}
          viewPDFTitle={'View Document'}
          fileStorePath={'customs_document'}
          documentType={'customs_document'}
          documents={
            documents?.length > 0 &&
            documents?.filter(
              (doc: any) => doc.documentType === 'customs_document'
            )
          }
          handleOnSubmit={(documentData: any) => {
            if (documentData) {
              setDocuments((prevDocs: any[]) => [
                ...prevDocs,
                { ...documentData },
              ]);
            }
            setAction(initAction);
          }}
          isShowTitle={true}
        />
      )}

      {action.mode === 'assign-carrier' && (
        <AssignCarrier
          activeLegData={activeLegData}
          action={action}
          setAction={setAction}
          setActiveLegCarrier={setActiveLegCarrier}
          activeLegCarrier={activeLegCarrier}
          masterOrderId={id}
          order={order}
          setActiveLegData={setActiveLegData}
          setTabValues={setTabValues}
          setCarrierPrice={setCarrierPrice}
          currentLegData={currentLegData}
        />
      )}

      {action.mode && appointmentDetails[action.mode] && (
        <AppointmentModal
          modalTitle={
            editMode.type
              ? `Edit ${appointmentDetails[editMode.type].title}`
              : `Set ${appointmentDetails[action.mode].title}`
          }
          modalDesc={appointmentDetails[action.mode].description}
          primaryBtnText="Set Appointment"
          type={action.mode}
          orderLegId={activeLegData?.id}
          editData={editMode.details}
          handleClose={() => {
            setAction(initAction);
            setEditMode({ type: null, details: null });
          }}
          handleOnSubmit={(formattedData: any) => {
            let date = '';
            let type = action.mode;

            if (type === 'carrier-pickup-appointment') {
              date = 'carrierPickupAppointmentDatetime';
            } else if (type === 'carrier-delivery-appointment') {
              date = 'carrierDeliveryAppointmentDatetime';
            } else if (type === 'shipper-appointment') {
              date = 'shipperAppointmentDatetime';
            } else if (type === 'delivery-appointment') {
              date = 'deliveryAppointmentDatetime';
            }

            const updatedLegData = {
              ...activeLegData,
              [`${date}`]: formattedData.combinedDatetime,
            };

            if (setActiveLegData) {
              setActiveLegData(updatedLegData);
            }

            if (setTabValues) {
              setTabValues((prevTabValues: any) => ({
                ...prevTabValues,
                [activeLegData?.legId]: {
                  ...prevTabValues[activeLegData?.legId],
                  [`${type}Datetime`]: formattedData.combinedDatetime,
                },
              }));
            }

            setAction((old: any) => ({
              ...old,
              mode: 'appointment-success-modal',
              type: action.mode,
            }));

            setEditMode({ type: null, details: null });
          }}
        />
      )}

      {action.mode === 'appointment-success-modal' && (
        <AppointmentSuccessModal
          handleClose={() => {
            setAction(initAction);
          }}
          setAction={setAction}
        />
      )}

      {action.mode === 'appointment-darft-modal' && (
        <DraftMailModal
          handleClose={() => setAction(initAction)}
          title={
            appointmentDetails[action?.type]?.emailDraftModalTitle ??
            'Set Appointment'
          }
          DraftDetails={{
            subjectLine: appointmentDetails[action.type].draftTitle,
            toEmailAddress:
              appointmentDetails[action.type].sendMailTo === 'shipper'
                ? activeLegData?.shipper?.email
                : appointmentDetails[action.type].sendMailTo === 'consignee'
                ? activeLegData?.consignee?.email
                : '',
            emailDetails: `I hope this message finds you well. We would like to arrange a carrier appointment for pick-up of your order: #${activeLegData?.legId}. Please let us know when you are available.`,
          }}
        />
      )}

      {action.mode === 'send-pick-up-reminder' && (
        <DraftMailModal
          handleClose={() => {
            setAction(initAction);
          }}
          title={`${
            order?.customerName
              ? `Send a Pickup Reminder to ${order?.customerName}`
              : 'Send a Pickup Reminder'
          }`}
          DraftDetails={{
            subjectLine: 'RE: Send Pickup Reminder',
            toEmailAddress: order?.customerEmail ? order?.customerEmail : null,
            emailDetails: `This is a friendly reminder regarding the upcoming pickup scheduled with XPO Logistics Freight, Inc. for your order: #${activeLegData?.legId}.`,
          }}
        />
      )}

      {action.mode === 'request-pod' && (
        <DraftMailModal
          handleClose={() => {
            setAction(initAction);
          }}
          title={`${
            activeLegCarrier && activeLegCarrier?.length > 0
              ? `Send To ${activeLegCarrier[0]?.carrier?.name}`
              : 'Send a POD request'
          }`}
          DraftDetails={{
            subjectLine: 'RE: Request POD',
            toEmailAddress: activeLegCarrier[0]?.carrier?.email
              ? activeLegCarrier[0]?.carrier?.email
              : null,
            emailDetails: `I hope this message finds you well.

Kindly upload or attach the Proof of Delivery (POD) for the shipment associated for order: #${activeLegData?.legId}.`,
          }}
        />
      )}

      {action.mode === 'invoice-customer' && (
        <DraftMailModal
          handleClose={() => {
            setAction(initAction);
          }}
          title={`Send Invoice To Customer`}
          DraftDetails={{
            subjectLine: 'RE: Invoice Customer',
            toEmailAddress: order?.customerEmail ? order?.customerEmail : null,
            emailDetails: `Please find attached the Proof of Delivery (POD) and the corresponding customer invoice for your order: #${order?.orderId} delivered on November 4th 2024.`,
          }}
        />
      )}

      {action.mode === 'resolve-claim' && (
        <ClaimResolve
          handleClose={() => setAction(initAction)}
          claim={activeLegData?.claim}
          isClaimListPage={false}
          handleOnSubmit={(claimData: any) => {
            setActiveLegData((curr: any) => ({ ...curr, claim: claimData }));

            setTabValues((prevTabValues: any) => ({
              ...prevTabValues,
              [activeLegData?.legId]: {
                ...prevTabValues[activeLegData?.legId],
                claim: claimData,
              },
            }));
          }}
        />
      )}

      {action.mode === 'resolve-dispute' && (
        <DisputeResolve
          handleClose={() => setAction(initAction)}
          dispute={activeLegData?.dispute}
          isDisputeListPage={false}
          handleOnSubmit={(disputeData: any) => {
            setActiveLegData((curr: any) => ({
              ...curr,
              dispute: disputeData,
            }));

            setTabValues((prevTabValues: any) => ({
              ...prevTabValues,
              [activeLegData?.legId]: {
                ...prevTabValues[activeLegData?.legId],
                dispute: disputeData,
              },
            }));
          }}
        />
      )}
    </>
  );
};

export default OrderStatus;
