import { ChevronUp, ChevronDown } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { getShortName } from 'src/utils/CommonFunctions';

const CarrierBreakdownRow = ({
  data,
  subDataVisibilityForCarrierPrice,
  isCarrierPriceDropDownShown,
}: any) => (
  <>
    <td className="px-4 py-3 w-96 min-w-96 max-w-[1px]">
      <div className="flex items-center gap-3">
        {data?.image && data?.imageUrl ? (
          <img
            src={`${data?.imageUrl}${data?.image}`}
            className="table-profile-icn-circle-sm "
          />
        ) : data?.image ? (
          <img
            src={`${data?.image}`}
            className="table-profile-icn-circle-sm "
          />
        ) : (
          <div className="table-profile-user-circle-sm ">
            {getShortName(data?.name)}
          </div>
        )}

        <h6 className="text-grayLight900 font-medium truncate ">
          {data?.name} (#{data?.legId})
        </h6>
      </div>
    </td>
    <td className="px-4 py-3">
      <span className="">Provided Rate</span>
    </td>
    <td className="px-4 py-3">
      <span className="">Conversion</span>
    </td>
    <td className="px-4 py-3 w-[54px] max-w-[54px] min-w-[54px]">
      {isCarrierPriceDropDownShown &&
        (subDataVisibilityForCarrierPrice[data.id] ? (
          <ChevronUp className="w-4 h-4 flex-none" />
        ) : (
          <ChevronDown className="w-4 h-4 flex-none" />
        ))}
    </td>
  </>
);

export default CarrierBreakdownRow;
