import { Play } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';

const LeaderRankLoaderRow = () => (
  <>
    <td className="px-5 py-4 w-[8%] min-w-[100px] max-w-[8%]">
      <span className="custom-loading">4</span>
    </td>

    <td className="px-5 py-4 w-[8%] min-w-[200px] max-w-[8%]">
      <div className="flex items-center gap-2">
        <div className="table-profile-user-circle-sm custom-loading">J D</div>
        <div className="truncate">
          <TooltipCmp>
            <h6 className="text-grayLight900 font-medium leading-tight truncate custom-loading">
              Kate Morrison
            </h6>
          </TooltipCmp>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 w-[14%] min-w-[150px] max-w-[14%]">
      <span className="custom-loading">1 Customer</span>
    </td>
    <td className="px-5 py-4 w-[12%] min-w-[120px] max-w-[12%]">
      <span className="custom-loading">5 days</span>
    </td>
    <td className="px-5 py-4 w-[12%] min-w-[120px] max-w-[12%]">
      <span className="custom-loading">521</span>
    </td>

    <td className="px-5 py-4 w-[12%] min-w-[120px] max-w-[12%]">
      <span className="custom-loading">521</span>
    </td>

    <td className="px-5 py-4 w-[12%] min-w-[120px] max-w-[12%]">
      <span className="custom-loading">$12.00 </span>
    </td>

    <td className="px-5 py-4 w-[12%] min-w-[120px] max-w-[12%]">
      <div className="flex gap-x-1.5 text-fgSuccessPrimary custom-loading w-fit">
        <Play className="w-4 h-4 -rotate-90 icon-filled-current" />
        +5
      </div>
    </td>
  </>
);

export default LeaderRankLoaderRow;
