// import { ArrowUp } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  capitalizeFirstLetterSpace,
  getShortName,
  usePhone,
} from 'src/utils/CommonFunctions';

import { ILeadRowProps } from './LoadBoard.interface';

const LoadBoardRow = ({ data }: ILeadRowProps) => {
  const { getFormatPhoneWithPlus } = usePhone();
  // const [isShowTypeInfoModal, setIsShowTypeInfoModal] = useState(false);
  // const selectedCarrierRates = null;
  // const currency = null;

  return (
    <>
      <td className="px-5 py-4 max-w-[1px] w-[18%] min-w-[200px]">
        <div className="flex items-center">
          <div className="table-profile-customer-circle-xl rounded-md">
            {getShortName(data.company ? data.company : 'Unknown')}
          </div>
          <div className="ml-3 truncate">
            <TooltipCmp message={data.company ? data.company : 'Unknown'}>
              <h6 className="text-grayLight900 font-medium leading-tight truncate">
                {data.company ? data.company : 'Unknown'}
              </h6>
            </TooltipCmp>

            <TooltipCmp>
              <span className="text-grayLight600 font-normal leading-tight truncate">
                {getFormatPhoneWithPlus(data.companyPhone)}
              </span>
            </TooltipCmp>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 min-w-[13.04%] w-[13.04%] max-w-[1px]">
        <p className="truncate">
          {capitalizeFirstLetterSpace(data.sourceCity)}
        </p>
      </td>
      <td className="px-5 py-4 min-w-[12.04%] w-[12.04%] max-w-[1px]">
        <span>{data.dfo ? `${data.dfo} mi` : ''} </span>
      </td>
      <td className="px-5 py-4 min-w-[13.04%] w-[13.04%] max-w-[1px]">
        <p className="truncate">
          {capitalizeFirstLetterSpace(data.destinationCity)}
        </p>
      </td>
      <td className="px-5 py-4 min-w-[12.04%] w-[12.04%] max-w-[1px]">
        <span>{data.dfd ? `${data.dfd} mi` : ''}</span>
      </td>
      <td className="px-5 py-4 min-w-[14.04%] w-[14.04%] max-w-[14.04%]">
        <div className="truncate w-fit max-w-full">
          {data.vehicleType.length > 30 ? (
            <ActionTooltip
              openOnHover={true}
              tooltipPosition="center"
              parentClassName="max-w-[320px] w-max"
              isOpenChildTopPosOnTop={true}
              type="dark"
              message={data.vehicleType}
            >
              <p>{`${data.vehicleType.slice(0, 30)}...`}</p>
            </ActionTooltip>
          ) : (
            <p className="truncate ">{data.vehicleType}</p>
          )}
        </div>
      </td>
      <td className="px-5 py-4 min-w-[14.04%] w-[14.04%] max-w-[1px]">
        <div className="truncate">
          {data.comment && data.comment?.length > 25 ? (
            <TooltipCmp message={data.comment} parentClassName="">
              <p className="truncate">{data.comment}</p>
            </TooltipCmp>
          ) : (
            <p className="truncate">{data.comment || '-'}</p>
          )}
        </div>
      </td>
      {/* <td
        className="px-5 py-4 max-w-[60px] w-[60px] min-w-[60px]"
        onClick={() => setIsShowTypeInfoModal(true)}
      >
        <ArrowUp className="w-4 h-4 text-primary cursor-pointer" />
      </td>
      {isShowTypeInfoModal &&
        // <MoveToActive
        //   handleClose={() => setIsShowTypeInfoModal(false)}
        //   selectedCarrierRates={selectedCarrierRates}
        //   currency={currency}
        // />
        null} */}
    </>
  );
};

export default LoadBoardRow;
