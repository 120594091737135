import { CheckSquareBroken, XClose } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const ReassignCustomerLoaderRow = () => (
  <>
    <td className="px-5 py-4  max-w-[1px] w-[20%] min-w-[20%]">
      <div className="flex items-center gap-3">
        <div className="table-profile-customer-circle-xl custom-loading">
          WA
        </div>
        <div className="truncate custom-loading">
          <span className="text-grayLight900 font-medium leading-tight truncate">
            Olivia Rhye
          </span>
        </div>
      </div>
    </td>

    <td className="px-5 py-4 max-w-[1px] w-[20%] min-w-[20%]">
      <div className="flex items-center">
        <div className="table-profile-customer-circle-sm custom-loading">
          WA
        </div>
        <div className="pl-2 truncate custom-loading">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            Urban Customer
          </h6>
        </div>
      </div>
    </td>

    <td className={`px-5 py-3 font-normal min-w-[182px] max-w-[15%] w-[15%]`}>
      <span className="custom-loading">Nov 24, 2021</span>
    </td>

    <td className={`px-5 py-3 font-normal min-w-[150px] max-w-[15%] w-[15%]`}>
      <span className="custom-loading">WAL-ASDF-12</span>
    </td>

    <td className={`px-5 py-3 font-normal min-w-[187px] max-w-[15%] w-[15%]`}>
      <span className="custom-loading">Aug 29, 2024</span>
    </td>

    <td className="px-5 py-4 w-[84px] max-w-[84px] min-w-[84px]">
      <div className="justify-end items-center gap-2 flex ">
        <div className="w-4 h-4 custom-loading">
          <CheckSquareBroken className="w-3.5 h-3.5 text-fgSuccessPrimary" />
        </div>
        <div className="w-4 h-4 custom-loading">
          <XClose className="w-4 h-4 text-fgErrorPrimary" />
        </div>
      </div>
    </td>
  </>
);

export default ReassignCustomerLoaderRow;
