import React, { useEffect, useState } from 'react';

import CreateQuoteFromMailSection from '../CreateQuoteFromMailSection';
import CreateQuoteSectionLoading from '../CreateQuoteFromMailSection/CreateQuoteSectionLoading';

interface IProps {
  selectedEmail: any;
  params: any;
  setParams: any;
  mapApiStatus: boolean;
  isLoadingMore: boolean;
  loading: boolean;
}

const MailActionSection = ({
  selectedEmail,
  params,
  setParams,
  mapApiStatus,
  isLoadingMore,
  loading,
}: IProps) => {
  const [actionType, setActionType] = useState<'CREATE' | 'EDIT'>('CREATE');

  useEffect(() => {
    if (selectedEmail?.quoteCreatedId) {
      setActionType('EDIT');
    }
  }, [selectedEmail]);

  return (
    <>
      {!isLoadingMore && loading ? (
        <CreateQuoteSectionLoading withHeaderFooterLoading={true} />
      ) : (
        <>
          {actionType === 'CREATE' || actionType === 'EDIT' ? (
            <CreateQuoteFromMailSection
              key={selectedEmail}
              selectedEmail={selectedEmail}
              params={params}
              setParams={setParams}
              mapApiStatus={mapApiStatus}
              isEdit={!!selectedEmail?.quoteCreatedId}
            />
          ) : (
            false
          )}
        </>
      )}
    </>
  );
};

export default MailActionSection;
