import { MarkerPin04 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import Radio from 'src/components/Radio/Radio';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { PATH } from 'src/constants/path';
import { createLegOrder } from 'src/services/OrderService';
import { getWarehouseList } from 'src/services/WarehouseService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';

import LineItemsRow from './LineItemsRow';

const radioOptions = [
  { id: 'dropoff', value: 'dropoff', label: 'Drop-offs' },
  { id: 'pickup', value: 'pickup', label: 'Pickups' },
  { id: 'both', value: 'both', label: 'Both' },
  { id: 'cross-dock', value: 'cross-dock', label: 'Cross-Dock' },
];

interface IProps {
  handleClose: any;
  combinedOrderDimensions: any;
  handlingUnitOptions: any;
  serviceTypes: any;
  setLegFormData: any;
  legFormData: any;
  masterOrderId: number;
  refreshPage: any;
  setIsMasterOrder: any;
}

const initSelectFormData = {
  additionalService: null,
};

const LegOrderDetailsModal = ({
  handleClose,
  combinedOrderDimensions,
  handlingUnitOptions,
  serviceTypes,
  setLegFormData,
  legFormData,
  masterOrderId,
  refreshPage,
  setIsMasterOrder,
}: IProps) => {
  const navigate = useNavigate();

  const [orderDeliveryType, setOrderDeliveryType] = useState('dropoff');
  const [selectFormData, setSelectFormData] = useState<any>(initSelectFormData);
  const [selectedLineItemId, setSelectedLineItemId] = useState<any>([]);
  const [additionalServiceOptions, setAdditionalServiceOptions] = useState<any>(
    []
  );

  const [additionalServiceLabel, setAdditionalServiceLabel] = useState<string>(
    'Delivery Additional Services'
  );
  const [wareHouseList, setWareHouseList] = useState<any[]>([]);
  const [error, setError] = useState<string>('');
  const [wareHouseError, setWareHouseError] = useState<string>('');
  const [wareHouseChargeError, setWareHouseChargeError] = useState<string>('');

  useEffect(() => {
    if (serviceTypes) {
      setAdditionalServiceOptions(getSelectBoxOptions(serviceTypes.DELIVERY));
    }
  }, [serviceTypes]);

  const getOptionArr = (condition: string) => {
    let optionArr;
    const deliveryOptions = getSelectBoxOptions(serviceTypes.DELIVERY);
    const pickupOptions = getSelectBoxOptions(serviceTypes.PICKUP);
    const otherOptions = getSelectBoxOptions(serviceTypes.OTHER);

    switch (condition) {
      case 'both':
        optionArr = [...deliveryOptions, ...pickupOptions];
        setAdditionalServiceLabel('Pickup And Delivery Additional Services');
        break;

      case 'pickup':
        optionArr = pickupOptions;
        setAdditionalServiceLabel('Pickup Additional Services');
        break;

      case 'dropoff':
        optionArr = deliveryOptions;
        setAdditionalServiceLabel('Delivery Additional Services');
        break;

      case 'cross-dock':
        optionArr = otherOptions;
        setAdditionalServiceLabel('Cross-Dock Additional Services');
        break;

      default:
        break;
    }

    return optionArr;
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setOrderDeliveryType(value);

    const optionArr = getOptionArr(value);
    setAdditionalServiceOptions(optionArr);
    setSelectFormData({ ...selectFormData, additionalService: null });
    setError('');
  };

  const handleCheckBoxChange = (data: any) => {
    setSelectedLineItemId((prevSelectedIds: any) =>
      prevSelectedIds?.includes(data?.id)
        ? prevSelectedIds.filter((item: any) => item !== data?.id)
        : [...prevSelectedIds, data?.id]
    );

    setError('');
  };

  const orderDimensions = combinedOrderDimensions
    .filter((dimension: any) => dimension)
    .map((dimension: any) => {
      if (dimension && dimension?.id && dimension?.dropLegId == null) {
        return {
          ...dimension,
        };
      }

      return null;
    })
    .filter((item: any) => item !== null);

  const handleSelectAll = () => {
    if (selectedLineItemId.length === orderDimensions.length) {
      setSelectedLineItemId([]);
    } else {
      setSelectedLineItemId(
        orderDimensions.map((dimension: any) => dimension.id)
      );
    }
  };

  const lineItemsHeadCells = useMemo(
    () => [
      {
        id: 'quantity',
        name: 'Quantity',
      },
      {
        id: 'handlingUnit',
        name: 'Handling Unit',
      },
      {
        id: 'dimensions',
        name: 'Dimensions (Inches)',
      },
      {
        id: 'totalWeight',
        name: 'Total weight (lbs)',
      },
      {
        id: 'refNumber',
        name: 'REF Number',
      },
      {
        id: 'action',
        name: (
          <>
            <CheckBox
              classes="mr-2"
              onChangeFunc={handleSelectAll}
              checked={
                selectedLineItemId?.length === orderDimensions?.length
                  ? true
                  : false
              }
            />
          </>
        ),
      },
    ],
    [selectedLineItemId, orderDimensions]
  );

  const closeAddStop = () => {
    handleClose();
  };

  const handleSelectChange = (name: string) => (event: any) => {
    if (name === 'additionalService') {
      setSelectFormData({ ...selectFormData, additionalService: event });

      const value = Array.isArray(event)
        ? event.map((e: any) => e.value)
        : event?.value;

      setLegFormData((old: any) => ({ ...old, additionalServices: value }));
    }

    if (name === 'wareHouse') {
      setWareHouseError('');

      setSelectFormData({ ...selectFormData, wareHouse: event });

      const value = Array.isArray(event)
        ? event.map((e: any) => e.value)
        : event?.value;

      setLegFormData((old: any) => ({ ...old, wareHouse: value }));
    }
  };

  const handleSubmit = () => {
    if (
      (orderDeliveryType === 'dropoff' || orderDeliveryType === 'both') &&
      selectedLineItemId.length === 0
    ) {
      setError('Please select at least one line item for drop-off');

      return;
    }

    if (orderDeliveryType === 'cross-dock') {
      if (!legFormData?.wareHouse) {
        setWareHouseError('Warehoue is required');
      }

      if (!legFormData?.wareHouseCharge) {
        setWareHouseChargeError('Warehoue charge is required');
      }

      return;
    }

    createLegOrder({
      ...legFormData,
      lineItems: selectedLineItemId,
      pickupOrDropOff: orderDeliveryType,
      masterOrderId: masterOrderId,
    })
      .then((response) => {
        if (response.data) {
          handleClose();
          refreshPage();

          if (orderDeliveryType === 'dropoff') {
            setIsMasterOrder(true);

            navigate(`${PATH.ORDER_VIEW}/${response?.data?.masterOrderId}`, {
              replace: true,
            });
          } else {
            navigate(`${PATH.ORDER_VIEW}/${response?.data?.masterOrderId}`, {
              state: { legId: response?.data?.legId },
            });
          }
        }
      })
      .catch(console.error)
      .finally(() => {});
  };

  const getWarehouseData = (signal: any) => {
    setWareHouseList([]);

    getWarehouseList({ isGetAllWareHouse: true }, signal)
      .then((response: any) => {
        if (response.data) {
          const wareHouseListOptions = getSelectBoxOptions(
            response.data,
            'id',
            'warehouseName'
          );

          setWareHouseList(wareHouseListOptions);
        }
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
      });
  };

  useEffect(() => {
    const warehouseApiController = new AbortController();
    const warehouseApiSignal = warehouseApiController.signal;

    getWarehouseData(warehouseApiSignal);
  }, []);

  return (
    <CommonModal
      title="Select a Midpoint"
      titleDesc="Select a midpoint to split your leg into 2."
      handleClose={handleClose}
      headerIcon={<MarkerPin04 />}
      size={'max-w-[720px] overflow-unset'}
      primaryBtnText="Confirm"
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={closeAddStop}
      isOverflow={false}
      primaryBtnOnClick={handleSubmit}
      // primaryBtnDisabled={!isValid}
    >
      <div className="p-5 flex flex-col gap-y-5">
        <div>
          <h6 className="text-textSecondary text-xs font-medium mb-1.5">
            Are there any pickups or drop-offs at this location?
          </h6>
          <div className="rounded-lg border border-borderPrimary bg-white px-3.5 py-2.5 flex gap-4">
            {radioOptions.map((option: any) => (
              <Radio
                key={option.id}
                id={option.id}
                inputName="transportType"
                value={option.value}
                checked={orderDeliveryType === option.value}
                labelName={option.label}
                labelHtmlFor={option.id}
                onChangeFunc={handleRadioChange}
                radioLabelClassName="!normal-case"
              />
            ))}
          </div>
        </div>

        <SelectBox
          name="additiona_service"
          id="additiona_service"
          className="form_control"
          size="sm"
          placeholder="Search services"
          isClearable={true}
          isSearchable={true}
          options={additionalServiceOptions}
          onChangeFunc={handleSelectChange('additionalService')}
          value={
            selectFormData.additionalServices
              ? selectFormData.additionalServices
              : undefined
          }
          label={additionalServiceLabel}
          labelClassName="form_label mb-1.5 block"
          isMultipleSelection
        />

        {orderDeliveryType &&
          (orderDeliveryType === 'dropoff' || orderDeliveryType === 'both') &&
          orderDimensions?.length > 0 && (
            <div>
              <h6 className="text-textSecondary text-xs font-medium mb-1.5">
                Select the line items being dropped off at this stop
              </h6>
              <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                  <TableCmp
                    headCells={lineItemsHeadCells}
                    tableDataArr={orderDimensions}
                    TableRowCmp={LineItemsRow}
                    tableRowCmpProps={{
                      isShowCheckBox: true,
                      selectedLineItemId,
                      handlingUnitOptions,
                    }}
                    numberOfSkeletonRows={1}
                    isTableRowClickable
                    onRowClick={(data: any) => {
                      handleCheckBoxChange(data);
                    }}
                  />
                </div>
              </div>
              {error && <ErrorMsg errorText={error} />}
            </div>
          )}

        {orderDeliveryType && orderDeliveryType === 'cross-dock' && (
          <div className="flex gap-3">
            <SelectBox
              name="warehouse"
              id="warehouse"
              className="form_control"
              size="sm"
              placeholder="Select Warehouse"
              isClearable={true}
              isSearchable={false}
              options={wareHouseList}
              onChangeFunc={handleSelectChange('wareHouse')}
              value={
                selectFormData.wareHouse ? selectFormData.wareHouse : undefined
              }
              label="Warehouse"
              labelClassName="form_label mb-1.5 block"
              parentClassName="flex-1"
              errorText={wareHouseError ?? ''}
            />

            <InputText
              label="Warehouse Charge"
              labelClassName=" mb-1.5 block"
              placeholder="Enter charge"
              parentClassName="flex-1"
              inputType="number"
              id="wareHouseCharge"
              inputName="wareHouseCharge"
              onChangeFunc={(e) => {
                setWareHouseChargeError('');

                setSelectFormData({
                  ...selectFormData,
                  wareHouseCharge: e?.target?.value,
                });
                setLegFormData((old: any) => ({
                  ...old,
                  wareHouseCharge: e?.target?.value,
                }));
              }}
              errorText={wareHouseChargeError ?? ''}
            />
          </div>
        )}
      </div>
    </CommonModal>
  );
};
export default LegOrderDetailsModal;
