import React from 'react';

const MyNewCustomerLBLoaderRow = () => (
  <>
    <td className="px-5 py-4 max-w-[1px] w-[50%] min-w-[50%]">
      <div className="flex items-center gap-3">
        <div className="table-profile-customer-circle-xl custom-loading">
          WA
        </div>
        <div className="truncate custom-loading">
          <span className="text-grayLight900 font-medium leading-tight truncate">
            Olivia Rhye
          </span>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 max-w-[1px] w-[50%] min-w-[50%]">
      <div className="flex items-center">
        <div className="table-profile-user-circle-sm custom-loading">
          <span>WA</span>
        </div>
        <div className="pl-2 truncate custom-loading">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            test Sales Representative
          </h6>
        </div>
      </div>
    </td>
  </>
);

export default MyNewCustomerLBLoaderRow;
