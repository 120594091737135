import { CheckCircle } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import ConfirmationModalCmp from 'src/components/ConfirmModal/ConfirmationModalCmp';
import TabButton from 'src/components/TabButton';
import {
  deleteAppointment,
  UpdateAppointmentStatus,
} from 'src/services/OrderService';

import { OrderContext } from '..';
import AppointmentModal from '../../Common/Appointment/AppointmentModal';
import AppointmentSuccessModal from '../../Common/Appointment/AppointmentSuccessModal';
import CommonAppointmentDetails from '../../Common/Appointment/CommonAppointmentDetails';
import DraftMailModal from '../../Common/DraftMailModal';
import {
  appointmentDetails,
  getEmailMessageForAppointMent,
} from '../../order.constant';
import { initAction } from '../../order.interface';

interface IProps {
  activeLegData: any;
  setActiveLegData: any;
}

const appointmentTypeArr = [
  { value: 'pickup', name: 'Pickup' },
  { value: 'delivery', name: 'Delivery' },
];

const fieldMapping: any = {
  'shipper-appointment': {
    datetimeField: 'shipperAppointmentDatetime',
    statusField: 'shipperAppointmentStatus',
  },
  'carrier-pickup-appointment': {
    datetimeField: 'carrierPickupAppointmentDatetime',
    statusField: 'carrierPickupAppointmentStatus',
  },
  'carrier-delivery-appointment': {
    datetimeField: 'carrierDeliveryAppointmentDatetime',
    statusField: 'carrierDeliveryAppointmentStatus',
  },
  'delivery-appointment': {
    datetimeField: 'deliveryAppointmentDatetime',
    statusField: 'deliveryAppointmentStatus',
  },
  'delivery-date': {
    datetimeField: 'expectedEstimatedDeliveryTime',
  },
  'pickup-date': {
    datetimeField: 'expectedPickupTime',
  },
};

const Appointment = ({ activeLegData, setActiveLegData }: IProps) => {
  const { isLoading } = useContext<any>(OrderContext);

  const [localLegData, setLocalLegData] = useState(activeLegData || {});
  const [activeAppointmentTab, setActiveAppointmentTab] = useState('pickup');
  const [action, setAction] = useState<any>(initAction);
  const [editMode, setEditMode] = useState<{
    type: string | null;
    details: any;
  }>({ type: null, details: null });

  useEffect(() => {
    setLocalLegData(activeLegData);
  }, [activeLegData]);

  const handleDeleteAppointment = (type: any) => {
    deleteAppointment({ orderLegId: activeLegData?.id, type: type })
      .then((response) => {
        if (response?.data) {
          const updatedLegData = { ...localLegData };

          if (type === 'shipper-appointment') {
            updatedLegData.shipperAppointmentDatetime = null;
          } else if (type === 'carrier-pickup-appointment') {
            updatedLegData.carrierPickupAppointmentDatetime = null;
          } else if (type === 'carrier-delivery-appointment') {
            updatedLegData.carrierDeliveryAppointmentDatetime = null;
          } else if (type === 'delivery-appointment') {
            updatedLegData.deliveryAppointmentDatetime = null;
          }

          setLocalLegData(updatedLegData);
          setActiveLegData((old: any) => ({ ...old, ...updatedLegData }));
        }
      })
      .catch(console.error);
  };

  const handleUpdateAppointmentStatus = () => {
    const payloads = {
      type: action?.type,
      orderLegId: activeLegData?.id,
    };

    UpdateAppointmentStatus(payloads)
      .then((response) => {
        if (response?.data) {
          const fields = fieldMapping[action?.type];

          if (fields) {
            let updatedLegData = {
              ...activeLegData,
              [`${fields.statusField}`]: action?.status,
            };

            if (setActiveLegData) {
              setActiveLegData(updatedLegData);
            }
          }

          setAction(initAction);
        }
      })
      .catch(console.error);
  };

  return (
    <div className="rounded-xl border border-utilityGray200 shadow-xs overflow-hidden flex-none">
      <div className="p-4 border-b border-utilityGray200 flex items-center gap-x-2 justify-between">
        <h6 className="text-grayLight900 text-base font-medium">
          Appointments&nbsp;
          {activeLegData?.legId && `#${activeLegData?.legId}`}
        </h6>
        <TabButton
          parentClassName={`${isLoading ? 'custom-loading' : ''}`}
          tabArray={appointmentTypeArr}
          handleOnClick={(e: any) => {
            const tabValue = e.target.dataset.value;
            setActiveAppointmentTab(tabValue);
          }}
          isActive={activeAppointmentTab}
          isTab={true}
        />
      </div>
      <CommonAppointmentDetails
        localLegData={localLegData}
        setAction={setAction}
        handleDeleteAppointment={handleDeleteAppointment}
        setEditMode={setEditMode}
        isShowDraftEmail={true}
        isLoading={isLoading}
        activeAppointmentTab={activeAppointmentTab}
      />

      {appointmentDetails[action.mode] && (
        <AppointmentModal
          modalTitle={
            editMode.type
              ? `Edit ${appointmentDetails[editMode.type].title}`
              : `Set ${appointmentDetails[action.mode].title}`
          }
          modalDesc={appointmentDetails[action.mode].description}
          primaryBtnText="Set Appointment"
          type={action.mode}
          orderLegId={activeLegData?.id}
          editData={editMode.details}
          handleClose={() => {
            setAction(initAction);
            setEditMode({ type: null, details: null });
          }}
          handleOnSubmit={(formattedData: any) => {
            let date = '';
            let type = action.mode;

            if (type === 'carrier-pickup-appointment') {
              date = 'carrierPickupAppointmentDatetime';
            } else if (type === 'carrier-delivery-appointment') {
              date = 'carrierDeliveryAppointmentDatetime';
            } else if (type === 'shipper-appointment') {
              date = 'shipperAppointmentDatetime';
            } else if (type === 'delivery-appointment') {
              date = 'deliveryAppointmentDatetime';
            }

            const updatedLegData = {
              ...activeLegData,
              [`${date}`]: formattedData.combinedDatetime,
            };

            if (setActiveLegData) {
              setActiveLegData(updatedLegData);
            }

            setAction((old: any) => ({
              ...old,
              mode: 'appointment-success-modal',
              type: action.mode,
            }));

            setEditMode({ type: null, details: null });
          }}
        />
      )}

      {action.mode === 'appointment-success-modal' && (
        <AppointmentSuccessModal
          handleClose={() => {
            setAction(initAction);
          }}
          setAction={setAction}
        />
      )}

      {action.mode === 'appointment-darft-modal' && (
        <DraftMailModal
          handleClose={() => setAction(initAction)}
          title={
            appointmentDetails[action?.type]?.emailDraftModalTitle ??
            'Set Appointment'
          }
          DraftDetails={{
            subjectLine: appointmentDetails[action.type].draftTitle,
            toEmailAddress:
              appointmentDetails[action.type].sendMailTo === 'shipper'
                ? activeLegData?.shipper?.email
                : appointmentDetails[action.type].sendMailTo === 'consignee'
                ? activeLegData?.consignee?.email
                : appointmentDetails[action.type].sendMailTo === 'carrier'
                ? activeLegData?.carrier?.length > 0
                  ? activeLegData?.carrier[0]?.email
                  : ''
                : '',
            emailDetails: getEmailMessageForAppointMent(
              action.type,
              activeLegData
            ),
          }}
        />
      )}

      {action.mode === 'appointment-confirm-modal' && (
        <ConfirmationModalCmp
          title={
            action.status === 'Pending'
              ? 'Revert to Pending'
              : 'Confirm Appointment'
          }
          description={appointmentDetails[action.type]?.messages[action.status]}
          Icon={<CheckCircle className="w-7 h-7" />}
          handleClose={() => setAction(initAction)}
          isSubmitting={isLoading}
          handleSubmit={handleUpdateAppointmentStatus}
          submitBtnText={
            action.status === 'pending' ? 'Mark Pending' : 'Confirm'
          }
        />
      )}
    </div>
  );
};

export default Appointment;
