import {
  ChevronLeft,
  DotsVertical,
  PhoneOutgoing01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getTranscriptAndSummary } from 'src/services/CallCenterService';
import { getMinutesAndSeconds } from 'src/utils/CommonFunctions';

import CallTranscriptLoader from './ThreadLoader/CallTranscriptLoader';

const formatDuration = (durationInSeconds: number) => {
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = durationInSeconds % 60;

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const CallTranScript = ({
  setIsShowRightPanel,
  isShowRightPanel,
  transcriptThread,
}: any) => {
  const [transcript, setTranscript] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTranscript = async (data: any) => {
    setTranscript([]);
    setIsLoading(true);

    await getTranscriptAndSummary(data)
      .then((response: any) => {
        if (response?.data) {
          setTranscript(response.data);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setTranscript([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const data = {
      recordingSid: isShowRightPanel.id,
      type: isShowRightPanel.name,
    };
    fetchTranscript(data);
  }, [isShowRightPanel]);

  const formattedDate = moment(transcriptThread.createdAt).calendar(null, {
    sameDay: '[Today], h:mm A',
    nextDay: '[Tomorrow], h:mm A',
    nextWeek: 'dddd, h:mm A',
    lastDay: '[Yesterday], h:mm A',
    lastWeek: 'dddd, h:mm A',
    sameElse: 'MM/DD/YYYY, h:mm A',
  });

  const formattedDuration = formatDuration(transcriptThread.duration);

  return (
    <>
      <div className="px-4 py-[26px] flex items-center gap-x-2 border-b border-utilityGray200">
        <ChevronLeft
          onClick={() => {
            setIsShowRightPanel({ name: '', id: '' });
          }}
          className="w-4 h-4 cursor-pointer"
        />
        <h6 className="text-grayLight900 text-base font-medium flex-1">
          Call Transcript
        </h6>
        <DotsVertical className="w-4 h-4" />
      </div>
      <div className="p-4">
        <div className="p-3 rounded-lg border border-utilityGray200 flex gap-x-2">
          <div className="rounded-full bg-gray500 w-9 h-9 flex items-center justify-center">
            <PhoneOutgoing01 className="text-white w-5 h-5" />
          </div>
          <div className="flex-1">
            <h6 className="text-grayLight900 text-xs font-medium leading-[1.5] line-clamp-1">
              Call with {transcriptThread.contactName}
            </h6>
            <div className="text-textSecondary text-xs font-normal leading-[1.5] flex gap-x-1">
              {/* <p className="line-clamp-1">Kate Morrison</p> */}
              {formattedDuration}
            </div>
          </div>
          <p className="text-gray500 text-xs font-normal leading-[1.5]">
            {formattedDate}
          </p>
        </div>
      </div>
      <ul className="flex-1 custom-scrollbar-v2 overflow-auto mb-4">
        {isLoading ? (
          <CallTranscriptLoader />
        ) : (
          transcript?.map((item: any, index: number) => (
            <li className="px-6 py-2 flex gap-x-3" key={index}>
              <p className="text-gray500 text-xs font-medium mt-0.5">
                {getMinutesAndSeconds(item.start_time)}
              </p>
              <p className="text-gray500 text-xs font-normal">
                <span className="text-grayLight900 text-sm font-medium">
                  {transcriptThread?.callType === 'outbound'
                    ? item?.media_channel === 1
                      ? `${transcriptThread?.userFirstName} ${transcriptThread?.userLastName}: `
                      : `${transcriptThread?.contactName}: `
                    : transcriptThread?.contactName}
                </span>
                {item.transcript}
              </p>
            </li>
          ))
        )}
      </ul>
    </>
  );
};

export default CallTranScript;
