import {
  ArrowLeft,
  ArrowRight,
  Copy06,
  LinkExternal02,
  ReverseLeft,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import { PATH } from 'src/constants/path';
import { updateOrderStatus } from 'src/services/OrderService';
import { getFormattedDate, getShortName } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import AiStar from '../../../assets/img/AiStarIcon.svg';

import RadarOrderStep from './RadarOrderStep';

const RadarOrderStatus = ({
  orderLegList,
  isLoading,
  setOrder,
  order,
}: {
  orderLegList: Array<any>;
  isLoading: boolean;
  setOrder: any;
  order: any;
}) => {
  const [currentLegIndex, setCurrentLegIndex] = useState(0);
  const [currentLegData, setCurrentLegData] = useState<any>(null);
  const [isProgressLoading, setIsProgressLoading] = useState(false);
  const statusArray = ['dispatched', 'in-transit', 'delivered', 'completed'];

  useEffect(() => {
    if (orderLegList?.length > 0) {
      const index = orderLegList?.findIndex((item) => item.isActive === 1);

      if (index === -1) {
        setCurrentLegIndex(0);
      } else {
        setCurrentLegIndex(index);
      }
    }
  }, [orderLegList]);

  useEffect(() => {
    if (orderLegList?.length > 0) {
      setCurrentLegData(orderLegList?.[currentLegIndex]);
    }
  }, [currentLegIndex, orderLegList]);

  const handleOrderStatus = (isUndo: boolean = false) => {
    setIsProgressLoading(true);
    updateOrderStatus({
      orderLegId: currentLegData?.id,
      isUndo,
    })
      .then((response) => {
        if (response?.data) {
          setOrder((prevOrder: any) => {
            if (prevOrder && prevOrder.orderLegs) {
              return {
                ...prevOrder, // Spread the existing order data
                orderLegs: prevOrder.orderLegs.map(
                  (leg: any, legIndex: number) =>
                    legIndex === currentLegIndex
                      ? { ...leg, orderStatus: response?.data?.orderLegStatus }
                      : leg
                ),
              };
            }

            return prevOrder; // Return the original state if `orderLegs` is missing
          });
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsProgressLoading(false);
      });
  };

  const getMarkAsTitle = () => {
    if (currentLegData?.orderStatus === 'booked') {
      return 'Mark as Pickup';
    } else if (currentLegData?.orderStatus === 'dispatched') {
      return 'Mark as In-Transit';
    } else if (currentLegData?.orderStatus === 'in-transit') {
      return 'Mark as Delivered';
    } else if (currentLegData?.orderStatus === 'delivered') {
      return 'Mark as Delivered';
    } else {
      return 'Mark as Pickup';
    }
  };

  const calculateDateDifference = (startDate: any, endDate: any) => {
    let startMoment = moment.utc(startDate).local();
    const endMoment = moment.utc(endDate).local();

    const currentDate = moment().local();

    const isStartDatePassed = startMoment.isBefore(currentDate, 'day');

    if (isStartDatePassed) {
      startMoment = currentDate;
    }

    let difference = endMoment.diff(startMoment, 'days');
    let isTimeDiff;

    if (difference < 0) {
      isTimeDiff = currentLegData?.expectedEstimatedDeliveryTime
        ? `Approx. ${getFormattedDate(
            currentLegData?.expectedEstimatedDeliveryTime,
            `Do MMM YYYY`
          )}`
        : '';
    } else if (difference === 0) {
      isTimeDiff = `Approx. ${1} days in transit`;
    } else {
      isTimeDiff = `Approx. ${difference ? difference : ''} days in transit`;
    }

    return isTimeDiff;
  };

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  return (
    <>
      <div className="radar-detail-box overflow-hidden">
        <div className="p-4 flex items-center justify-between gap-2 border-b border-utilityGray200">
          <div className="space-y-0.5">
            <h6
              className={`text-grayLight900 text-lg font-semibold ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              Order Picked Up - Assign a Carrier
            </h6>
            <p
              className={`text-gray500 text-xs font-normal ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {currentLegData?.expectedPickupTime
                ? `Order was Picked Up on ${getFormattedDate(
                    currentLegData?.expectedPickupTime,
                    `Do MMM YYYY`
                  )}`
                : ''}
            </p>
          </div>
          <ButtonCmp
            icon={<img src={AiStar} className="w-4 h-4" />}
            className={`ai-gradient-border btn-outline-primary ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            Track and Trace AI
          </ButtonCmp>
        </div>

        <div className="p-4 flex justify-between items-center gap-x-2.5 bg-gray25 border-b border-utilityGray200">
          <div className="flex items-center gap-x-2.5">
            {isLoading ? (
              <div
                className={`w-10 h-10 rounded-md border border-black/[0.08] flex-none flex items-center justify-center bg-utilityGray100 text-gray500 ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                mark zukerberg
              </div>
            ) : (
              <>
                {currentLegData?.carrierImage ? (
                  <img
                    src={
                      currentLegData?.carrierImageUrl +
                      currentLegData?.carrierImage
                    }
                    className="w-10 h-10 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none"
                  />
                ) : (
                  <div
                    className={`w-10 h-10 rounded-md border border-black/[0.08] flex-none flex items-center justify-center bg-utilityGray100 text-gray500 `}
                  >
                    {getShortName(
                      `${
                        currentLegData?.carrierName
                          ? currentLegData?.carrierName
                          : 'Unassigned Carrier'
                      }`
                    )}
                  </div>
                )}
              </>
            )}
            <div>
              <h6
                className={`text-grayLight900 text-sm font-medium ${
                  isLoading ? 'custom-loading' : ''
                } `}
              >
                {currentLegData?.carrierName
                  ? currentLegData?.carrierName
                  : 'Unassigned Carrier'}
              </h6>
              <p
                className={`flex items-center gap-1 text-grayLight600 text-xs font-normal ${
                  isLoading ? 'custom-loading mt-1' : ''
                }`}
              >
                {currentLegData?.legId ? (
                  <>
                    {`#${currentLegData?.legId}`}
                    <Copy06
                      className="w-3 h-3 text-primary cursor-pointer"
                      onClick={() => copyDebounce(`${currentLegData?.legId}`)}
                    />
                    <LinkExternal02
                      className="w-3 h-3 text-primary cursor-pointer"
                      onClick={() => {
                        if (order?.id) {
                          window.open(
                            `${PATH.ORDER_VIEW}/${order?.id}`,
                            '_blank'
                          );
                        }
                      }}
                    />
                  </>
                ) : (
                  ''
                )}
              </p>
            </div>
          </div>
          {orderLegList?.length > 1 && (
            <div className="flex gap-x-2.5">
              <ButtonCmp
                className={`btn_secondary_black lg:!px-[9px] !px-2 ${
                  isLoading ? 'custom-loading' : ''
                }`}
                icon={<ArrowLeft className="w-4 h-4" />}
                onClick={() => {
                  setCurrentLegIndex(currentLegIndex - 1);
                }}
                disabled={currentLegIndex === 0}
              >
                <></>
              </ButtonCmp>
              <ButtonCmp
                className={`btn_secondary_black lg:!px-[9px] !px-2 ${
                  isLoading ? 'custom-loading' : ''
                }`}
                icon={<ArrowRight className="w-4 h-4" />}
                onClick={() => {
                  setCurrentLegIndex(currentLegIndex + 1);
                }}
                disabled={currentLegIndex === orderLegList?.length - 1}
              >
                <></>
              </ButtonCmp>
            </div>
          )}
        </div>

        <div className="p-4 bg-gray25 space-y-5">
          <ul className="flex flex-wrap flex-1 xxl:gap-x-8 gap-x-5">
            <RadarOrderStep
              step={'Step 1'}
              status={'Picked Up'}
              progressValue={
                statusArray?.slice(0, 4)?.includes(currentLegData?.orderStatus)
                  ? 100
                  : 0
              }
              iconParentClass={''}
              time={
                currentLegData?.expectedPickupTime
                  ? `${getFormattedDate(
                      currentLegData?.expectedPickupTime,
                      `Do MMM YYYY`
                    )}`
                  : ''
              }
              isLoading={isLoading}
            />
            <RadarOrderStep
              step={'Step 2'}
              status={'In - Transit'}
              progressValue={
                statusArray?.slice(1, 4)?.includes(currentLegData?.orderStatus)
                  ? 100
                  : 0
              }
              iconParentClass={''}
              time={
                currentLegData?.expectedEstimatedDeliveryTime &&
                currentLegData?.expectedPickupTime
                  ? `${calculateDateDifference(
                      currentLegData?.expectedPickupTime,
                      currentLegData?.expectedEstimatedDeliveryTime
                    )}`
                  : ''
              }
              isLoading={isLoading}
            />
            <RadarOrderStep
              step={'Step 3'}
              status={'Delivered'}
              progressValue={
                statusArray?.slice(2, 4)?.includes(currentLegData?.orderStatus)
                  ? 100
                  : 0
              }
              iconParentClass={''}
              time={
                currentLegData?.expectedEstimatedDeliveryTime
                  ? `Approx. ${getFormattedDate(
                      currentLegData?.expectedEstimatedDeliveryTime,
                      `Do MMM YYYY`
                    )}`
                  : ''
              }
              isLoading={isLoading}
            />
          </ul>
          {currentLegData?.isActive === 1 && (
            <div className="flex gap-x-2 justify-end">
              <ButtonCmp
                className={`btn-outline-primary ${
                  isLoading ? 'custom-loading' : ''
                }`}
                icon={<ReverseLeft className="w-4 h-4" />}
                onClick={() => {
                  handleOrderStatus(true);
                }}
                disabled={
                  currentLegData?.orderStatus === 'booked' || isProgressLoading
                }
              >
                Undo
              </ButtonCmp>
              <ButtonCmp
                className={`btn-outline-primary ${
                  isLoading ? 'custom-loading' : ''
                }`}
                onClick={() => {
                  handleOrderStatus();
                }}
                disabled={
                  currentLegData?.orderStatus === 'delivered' ||
                  isProgressLoading
                }
              >
                {getMarkAsTitle()}
              </ButtonCmp>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default RadarOrderStatus;
