import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import EmailReplyCmp from 'src/components/EmailReplyCmp';

interface IProps {
  handleClose: any;
  DraftDetails: any;
  title: any;
}

const DraftMailModal = ({ handleClose, DraftDetails, title }: IProps) => (
  <CommonModal
    title={title}
    titleDesc=""
    handleClose={handleClose}
    headerIcon={''}
    size={'max-w-[600px]'}
    isOverflow={true}
  >
    <EmailReplyCmp
      subjectLine={DraftDetails?.subjectLine}
      toEmailAddress={DraftDetails?.toEmailAddress}
      emailDetails={DraftDetails?.emailDetails}
      handleClose={handleClose}
    />
  </CommonModal>
);

export default DraftMailModal;
