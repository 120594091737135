import React, { useContext, useEffect, useState } from 'react';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getFormattedNumber,
} from 'src/utils/CommonFunctions';

import { OrderContext } from '..';

import CarrierBreakdownRow from './CarrierBreakdownRow';
import CarrierBreakdownSubRow from './CarrierBreakdownSubRow';
import SalesPriceCostBreakDownRow from './SalesPriceCostBreakDownRow';
import SalesPriceCostBreakDownSubRow from './SalesPriceCostBreakDownSubRow';
import TotalMarginRow from './TotalMarginRow';

const TabArray = [
  { value: 'CAD', name: 'CAD' },
  { value: 'USD', name: 'USD' },
];

const OrderCostBreakDown = () => {
  const [subDataVisibilityForSalesPrice, setSubDataVisibilityForSalesPrice] =
    useState<any>({});

  const [isSalesPriceDropDownShown, setIsSalesPriceDropDownShown] =
    useState<boolean>(true);

  const [
    subDataVisibilityForCarrierPrice,
    setSubDataVisibilityForCarrierPrice,
  ] = useState<any>({});

  const [isCarrierPriceDropDownShown, setIsCarrierPriceDropDownShown] =
    useState<boolean>(true);
  const [totalSalesPrice, setTotalSalesPrice] = useState<any>(0);
  const [totalSalesPriceUSDForMargin, setTotalSalesPriceUSDForMargin] =
    useState<any>(0);
  const [totalCarrierPrice, setTotalCarrierPrice] = useState<any>(0);
  const [totalCarrierPriceUSDForMargin, setTotalCarrierPriceUSDForMargin] =
    useState<any>(0);
  const [totalMargin, setTotalMargin] = useState<any>({});
  const [totalMarginData, setTotalMarginData] = useState<any>({});

  const [activeCurrencyTab, setActiveCurrencyTab] = useState<string>('CAD');
  const [salesPriceData, setSalesPriceData] = useState<any[]>([]);
  const [carrierPriceData, setCarrierPriceData] = useState<any[]>([]);

  const { order, legData, isLoading } = useContext<any>(OrderContext);

  const handleOnRowClickForSalesPrice = (currentCustomer: any) => {
    setSubDataVisibilityForSalesPrice((prevState: any) => ({
      ...prevState,
      [currentCustomer.id]: !prevState[currentCustomer.id],
    }));
  };

  const handleOnRowClickForCarrierPrice = (currentCustomer: any) => {
    setSubDataVisibilityForCarrierPrice((prevState: any) => ({
      ...prevState,
      [currentCustomer.id]: !prevState[currentCustomer.id],
    }));
  };

  const handleCurrencyTabSwitch = (e: any) => {
    const currentCurrencyTab = e.target.dataset.value;

    setActiveCurrencyTab(currentCurrencyTab);
  };

  useEffect(() => {
    const salesPriceDetails = legData.flatMap((leg: any) =>
      Array.isArray(leg?.salesPrice)
        ? leg.salesPrice.map((item: any) => ({
            ...item,
            name: item?.customer ? item?.customer?.name : '',
            image: item?.customer ? item?.customer?.image : '',
            imageUrl: item?.customer ? item?.customer?.imageUrl : '',
            isSalesPrice: true,
            legId: leg?.legId,
          }))
        : []
    );

    const carrierPriceDetails = legData.flatMap((leg: any) =>
      Array.isArray(leg?.carrierPrice)
        ? leg?.carrierPrice
            ?.filter((item: any) => item?.carrierId)
            ?.map((item: any) => ({
              ...item,
              name: item?.carrier ? item?.carrier?.name : '',
              image: item?.carrier ? item?.carrier?.image : '',
              imageUrl: item?.carrier ? item?.carrier?.imageUrl : '',
              isSalesPrice: false,
              legId: leg?.legId,
            }))
        : []
    );

    const uniqueCombinedData: any = [];
    let totalSalePriceCAD = 0;
    let totalSalePriceUSD = 0;
    let totalCarrierPriceCAD = 0;
    let totalCarrierPriceUSD = 0;
    let totalMarginInCAD = 0;
    let totalMarginInUSD = 0;

    salesPriceDetails.forEach((item: any, index: any) => {
      item.id = index;
      totalSalePriceCAD += item.totalSalePrice || 0;
      totalSalePriceUSD += item.totalSalePriceUSD || 0;

      uniqueCombinedData.push({
        ...item,
        totalSalePriceCAD,
      });
    });

    setTotalSalesPrice(totalSalePriceCAD);
    setTotalSalesPriceUSDForMargin(totalSalePriceUSD);

    carrierPriceDetails.forEach((item: any, index: any) => {
      item.id = salesPriceDetails.length + index;
      const { totalPrice = 0, totalPriceUSD = 0 } = item;

      totalCarrierPriceCAD += item.totalPrice || 0;
      totalCarrierPriceUSD += item.totalPriceUSD || 0;

      const carrierRatePercentageInCAD =
        totalSalePriceCAD > 0
          ? ((totalSalePriceCAD - totalPrice) / totalSalePriceCAD) * 100
          : 0;
      const carrierRatePercentageInUSD =
        totalSalePriceUSD > 0
          ? ((totalSalePriceUSD - totalPriceUSD) / totalSalePriceUSD) * 100
          : 0;
      const carrierDiffCAD = Math.abs(totalSalePriceCAD - totalPrice);
      const carrierDiffUSD = Math.abs(totalSalePriceUSD - totalPriceUSD);

      totalMarginInCAD += totalSalePriceCAD - totalPrice;
      totalMarginInUSD += totalSalePriceUSD - totalPriceUSD;

      uniqueCombinedData.push({
        ...item,
        carrierRatePercentageInCAD: carrierRatePercentageInCAD.toFixed(2),
        carrierRatePercentageInUSD: carrierRatePercentageInUSD.toFixed(2),
        carrierDiffCAD: carrierDiffCAD.toFixed(2),
        carrierDiffUSD: carrierDiffUSD.toFixed(2),
      });
    });

    if (salesPriceData.length === 1) {
      setSubDataVisibilityForSalesPrice({ [salesPriceData[0]?.id]: true });
    } else {
      setIsSalesPriceDropDownShown(true);
    }

    if (carrierPriceData.length === 1) {
      setSubDataVisibilityForCarrierPrice({
        [carrierPriceData[0]?.id]: true,
      });
    } else {
      setIsCarrierPriceDropDownShown(true);
    }

    setTotalCarrierPrice(totalCarrierPriceCAD);
    setTotalCarrierPriceUSDForMargin(totalCarrierPriceUSD);
    setTotalMargin({ totalMarginInCAD, totalMarginInUSD });
    setSalesPriceData(salesPriceDetails);
    setCarrierPriceData(carrierPriceDetails);
  }, [legData]);

  useEffect(() => {
    const marginData = [];

    if (totalSalesPrice) {
      marginData.push({
        name: 'Total Customer Sell Price',
        providedRate: totalSalesPrice,
        cad: totalSalesPrice,
        usd: totalSalesPriceUSDForMargin,
        isHaveClass: false,
      });
    }

    if (totalCarrierPrice) {
      marginData.push({
        name: 'Total Carrier Cost',
        providedRate: totalCarrierPrice,
        cad: totalCarrierPrice,
        usd: totalCarrierPriceUSDForMargin,
        isHaveClass: false,
      });
    }

    if (totalSalesPrice && totalCarrierPrice) {
      const totalMarginCAD = totalSalesPrice - totalCarrierPrice;
      const totalMarginUSD = totalMargin.totalMarginInUSD;

      marginData.push({
        name: 'Total Margin',
        providedRate: totalMarginCAD,
        cad: totalMarginCAD,
        usd: totalMarginUSD,
        isHaveClass: true,
      });
    }

    setTotalMarginData(marginData);
  }, [totalMargin, totalCarrierPrice, totalSalesPrice]);

  return (
    <>
      <div className="rounded-xl border border-utilityGray200 shadow-xs flex-none overflow-hidden">
        <div className="p-4 border-b border-utilityGray200 flex items-center gap-x-2 justify-between">
          <h6 className="text-grayLight900 text-base font-medium">
            Pricing Breakdown
          </h6>
          <div className="flex gap-x-4 items-center">
            <div className="text-sm font-normal text-grayLight600">
              Date:&nbsp;
              <span
                className="text-grayLight900"
                dangerouslySetInnerHTML={{
                  __html: getDateWithSuffixFormat(
                    getFormattedDate(
                      order?.createdAt,
                      `MMMM Do, YYYY`,
                      true,
                      true
                    )
                  ),
                }}
              ></span>
            </div>
            <div className="text-sm font-normal text-grayLight600">
              USD:&nbsp;
              <span className="text-grayLight900">
                ${getFormattedNumber(order?.usdRate)}
              </span>
            </div>
            <div className="text-sm font-normal text-grayLight600">
              CAD:&nbsp;
              <span className="text-grayLight900">
                ${getFormattedNumber(order?.cadRate)}
              </span>
            </div>
            <div className="text-grayLight900 text-sm font-medium">
              <TabButton
                tabArray={TabArray}
                isActive={activeCurrencyTab}
                handleOnClick={handleCurrencyTabSwitch}
                isTab={true}
                tabParentClassName={`${isLoading ? 'custom-loading' : ''}`}
              />
            </div>
          </div>
        </div>
        {salesPriceData.length > 0 && (
          <div className="p-4 bg-gray25">
            <h6 className="text-grayLight900 text-sm font-medium uppercase mb-3">
              Sales Price
            </h6>
            <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 overflow-hidden">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination table-without-thead">
                <TableCmp
                  headCells={[]}
                  tableDataArr={salesPriceData}
                  TableRowCmp={SalesPriceCostBreakDownRow}
                  tableRowCmpProps={{
                    subDataVisibilityForSalesPrice,
                    activeCurrencyTab,
                    isSalesPriceDropDownShown,
                  }}
                  isTableRowClickable={isSalesPriceDropDownShown}
                  onRowClick={(currentCustomer: any) => {
                    if (isSalesPriceDropDownShown) {
                      handleOnRowClickForSalesPrice(currentCustomer);
                    }
                  }}
                  SubDataCmp={SalesPriceCostBreakDownSubRow}
                  subDataRowCmpProps={{
                    activeCurrencyTab,
                    totalCarrierPrice,
                    totalCarrierPriceUSDForMargin,
                  }}
                  isDisplaySubData={true}
                  selectedRows={subDataVisibilityForSalesPrice}
                  tableHeaderClass="hidden"
                />
              </div>
            </div>
          </div>
        )}

        {carrierPriceData.length > 0 && (
          <div className="p-4 bg-gray25">
            <h6 className="text-grayLight900 text-sm font-medium uppercase mb-3">
              Carrier Price
            </h6>
            <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 overflow-hidden">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination table-without-thead">
                <TableCmp
                  headCells={[]}
                  tableDataArr={carrierPriceData}
                  TableRowCmp={CarrierBreakdownRow}
                  tableRowCmpProps={{
                    subDataVisibilityForCarrierPrice,
                    activeCurrencyTab,
                    isCarrierPriceDropDownShown,
                  }}
                  isTableRowClickable={isCarrierPriceDropDownShown}
                  onRowClick={(currentCustomer: any) => {
                    if (isCarrierPriceDropDownShown) {
                      handleOnRowClickForCarrierPrice(currentCustomer);
                    }
                  }}
                  SubDataCmp={CarrierBreakdownSubRow}
                  subDataRowCmpProps={{
                    activeCurrencyTab,
                    totalSalesPrice,
                    totalSalesPriceUSDForMargin,
                  }}
                  isDisplaySubData={true}
                  selectedRows={subDataVisibilityForCarrierPrice}
                  tableHeaderClass="hidden"
                />
              </div>
            </div>
          </div>
        )}

        {totalMarginData.length > 0 && (
          <div className="p-4 bg-gray25">
            <h6 className="text-grayLight900 text-sm font-medium uppercase mb-3">
              Margin
            </h6>
            <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 overflow-hidden">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination table-without-thead">
                <TableCmp
                  headCells={[]}
                  tableDataArr={totalMarginData}
                  TableRowCmp={TotalMarginRow}
                  tableRowCmpProps={{
                    activeCurrencyTab,
                  }}
                  isTableRowClickable={false}
                  // tableHeaderClass="hidden"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderCostBreakDown;
